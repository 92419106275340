import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import TextArea from "../../../../Ui/Fields/TextArea";

import { BufiSwitch } from "../../../../Utils/Tools";

import grayCheckbox from "../../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../../Images/Checked.svg";

const PREFIX = "EditRole";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  inputContainer: `${PREFIX}-inputContainer`,
  switchAndButtonContainer: `${PREFIX}-switchAndButtonContainer`,
  formLabel: `${PREFIX}-formLabel`,
  submitButton: `${PREFIX}-submitButton`,
  formControl: `${PREFIX}-formControl`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: 10,
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.switchAndButtonContainer}`]: {
    marginTop: 10,
    width: "100%",
    height: 42,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.formLabel}`]: {
    color: "#B1AEAE",
    fontWeight: 400,
    fontSize: 12,
    "&[aktif='true']": {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`& .${classes.formControl}`]: {
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
      marginLeft: 0,
    },
    "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      marginLeft: 5,
    },
  },
}));

const EditRole = ({ open, onClose }) => {
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    role: "",
    isManager: true,
    status: 1,
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        setValues({
          ...values,
          role: "",
          isManager: true,
          status: 1,
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleCheck = () => {
    setValues({ ...values, isManager: !values.isManager });
  };

  const handleSwitch = (e) => {
    e.target.checked === true
      ? setValues({ ...values, status: 1 })
      : setValues({ ...values, status: 0 });
  };

  const handleError = () => {
    if (values.role === "") {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = () => {
    let isError = handleError();
    console.log(
      "🚀 ~ file: AddRole.js ~ line 167 ~ handleSubmit ~ isError",
      isError
    );
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Yetki Bilgi Değiştir</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.inputContainer}>
          <TextArea
            label="Açıklama"
            handleChange={handleChange}
            values={values}
            prop="role"
            error={error}
          />
          <div className={classes.switchAndButtonContainer}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                style={{ marginBottom: 4 }}
                control={
                  <Checkbox
                    disableRipple
                    checked={values.isManager}
                    onChange={handleCheck}
                    id={"manager"}
                    name={"manager"}
                    value={values.isManager}
                    icon={<img src={grayCheckbox} width="20px" alt="" />}
                    checkedIcon={<img src={Checked} width="20" alt="" />}
                  />
                }
                label="Yönetici"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                classes={{ label: classes.formLabel }}
                control={
                  <BufiSwitch
                    checked={values.status === 1}
                    value={values.status}
                    onChange={handleSwitch}
                  />
                }
                label={
                  values.status === 1 ? (
                    <Typography className={classes.formLabel} aktif="true">
                      Aktif
                    </Typography>
                  ) : (
                    <Typography className={classes.formLabel}>Pasif</Typography>
                  )
                }
              />
            </FormControl>
          </div>
          <Button
            variant="contained"
            disableRipple
            className={classes.submitButton}
            onClick={handleSubmit}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

EditRole.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditRole;
