import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import closeIcon from "../../../../Images/ProductPage/RatingClose.svg";

import RatingTextField from "./RatingTextField";
import RatingField from "./RatingField";
import RatingCheckBox from "./RatingCheckBox";
import RatingSubmitButton from "./RatingSubmitButton";

const PREFIX = "RatingModal";

const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  header: `${PREFIX}-header`,
  close: `${PREFIX}-close`,
  content: `${PREFIX}-content`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.paper}`]: {
    width: 416,
    height: 482,
    padding: 0,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.title}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 37,
    padding: "14.77px 0px 0px 0px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    width: "content-fit",
    textAlign: "center",
  },

  [`& .${classes.close}`]: {
    position: "absolute",
    right: 20,
    top: 12,
    width: 25,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "auto",
    padding: "0px 20px",
    display: "flex",
    flexDirection: "column",
  },
}));

const RatingModal = ({ handleClose, open }) => {
  const [text, setText] = useState("");
  const [rating, setRating] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => setText(e.target.value);
  const handleCheck = () => setChecked(!checked);
  const clearFields = () => {
    setText("");
    setRating(0);
    setChecked(false);
  };
  const handleSubmit = () => {
    const ratingQuery = {
      text,
      rating,
      checked,
    };
    console.log(ratingQuery);
    handleClose();
    clearFields();
  };

  return (
    <StyledDialog
      open={open}
      // onClose={handleClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.header}>Ürünü Değerlendir</Typography>
        <IconButton
          className={classes.close}
          onClick={handleClose}
          size="large">
          <img src={closeIcon} width="14px" height="12.93px" alt="Close Icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <RatingTextField handleChange={handleChange} text={text} />
        <RatingField rating={rating} setRating={setRating} />
        <RatingCheckBox checked={checked} handleCheck={handleCheck} />
        <RatingSubmitButton handleSubmit={handleSubmit} />
      </DialogContent>
    </StyledDialog>
  );
};

RatingModal.propTypes = {
  handleChange: PropTypes.func,
  open: PropTypes.bool,
};

export default RatingModal;
