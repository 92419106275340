import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import AddressAndNote from "./AddressAndNote";

const Root = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));

const BankTransferMob = ({ text, toggleAddressDialog, handleNote }) => {
  return (
    <Root>
      <AddressAndNote
        text={text}
        toggleAddressDialog={toggleAddressDialog}
        handleChange={handleNote}
      />
    </Root>
  );
};

BankTransferMob.propTypes = {
  text: PropTypes.string,
  toggleAddressDialog: PropTypes.func,
  handleNote: PropTypes.func,
};

export default BankTransferMob;
