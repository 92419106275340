import React from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import TextField from "@mui/material/TextField";

import { errorTexts } from "../../Utils/Constants";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  width: "100%",
  height: 30.48,
  "& .MuiInputBase-root": {
    height: 30.48,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 8,
    height: 20,
    padding: "5px 10px 5px 39px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      // "-webkit-appearance": "none",
      WebkitAppearance: "none",
    },
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 8,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[700],
  marginTop: 10,
  marginBottom: 5,
  marginLeft: 1,
  userSelect: "none",
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  width: 29.12,
  left: 5,
}));

const StyledAdornmentText = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})(({ disabled, theme }) => ({
  fontWeight: 300,
  fontSize: 14,
  color: disabled ? "rgba(0, 0, 0, 0.38)" : theme.palette.darkGray.main,
  userSelect: "none",
  "& > span": {
    fontWeight: 300,
  },
}));

const HelperText = styled(FormHelperText)(() => ({
  fontSize: 8,
  color: "red",
  userSelect: "none",
  "&.MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

const PhoneArea = ({
  label,
  handleChange,
  values,
  prop,
  component,
  error,
  disabled,
}) => {
  let errorStatus = values[prop].length !== 12;
  return (
    <>
      <StyledLabel add-user={component === "add user" ? "true" : "false"}>
        {label}
      </StyledLabel>
      <FormControl style={{ width: "100%" }}>
        <InputMask
          mask="99 999 99 99"
          disabled={disabled}
          maskChar={0}
          value={values[prop] || ""}
          onChange={handleChange(prop)}>
          {() => (
            <StyledTextField
              fullWidth
              // onChange={handleChange(prop)}
              error={error && values[prop].length !== 12}
              // inputProps={{
              //   autoComplete: "new-password",
              // }}
              // type="number"
              variant="standard"
              disabled={disabled}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <StyledAdornment position="start">
                    <StyledAdornmentText disabled={disabled}>
                      +90 <span>5</span>
                    </StyledAdornmentText>
                  </StyledAdornment>
                ),
              }}
            />
          )}
        </InputMask>
        <HelperText>{error && errorStatus ? errorTexts[prop] : ""}</HelperText>
      </FormControl>
    </>
  );
};

PhoneArea.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  prop: PropTypes.string,
  component: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.any,
};

export default PhoneArea;
