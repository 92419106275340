import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import QuestionOrange from "../../../../Images/Addresses/QuestionOrange.svg";

import { BufiSwitch } from "../../../../Utils/Tools";

const Root = styled("div")(({ theme }) => ({
  flex: 1,
  height: 40,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: theme.spacing(2),
}));

const TextContainer = styled("div")(() => ({
  flex: 1,
  height: 40,
  display: "flex",
  alignItems: "center",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 400,
  color: theme.palette.darkGray.mid,
  marginLeft: theme.spacing(2),
}));

const StyledFormLabel = styled(Typography)(({ theme }) => ({
  color: "#B1AEAE",
  fontWeight: 400,
  fontSize: 12,
  "&[aktif='true']": {
    color: theme.palette.primary.main,
  },
}));

const ButtonAndText = ({ all, handleSwitchMain, customer }) => {
  let text = customer
    ? "Daha önce ürün gönderilen perakende müşteri adresleriniz"
    : "Tanımlı adresinizi buradan değiştirebilirsiniz.";
  return (
    <Root>
      <TextContainer>
        <img src={QuestionOrange} alt="" />
        <Text>{text}</Text>
      </TextContainer>
      {customer && (
        <FormControl>
          <FormControlLabel
            control={
              <BufiSwitch
                checked={all}
                value={all}
                onChange={handleSwitchMain}
              />
            }
            label={
              all ? (
                <StyledFormLabel aktif="true">Tümü</StyledFormLabel>
              ) : (
                <StyledFormLabel>Aktif</StyledFormLabel>
              )
            }
          />
        </FormControl>
      )}
    </Root>
  );
};

ButtonAndText.propTypes = {
  all: PropTypes.bool,
  handleSwitchMain: PropTypes.func,
  customer: PropTypes.bool,
};

export default ButtonAndText;
