import React from "react";
import Slide from "@mui/material/Slide";

import { styled } from "@mui/material/styles";

import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

export const BufiSwitch = styled(Switch)(({ theme }) => ({
  width: 49,
  height: 22,
  padding: 0,
  margin: theme.spacing(1),
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(27px)",
      color: "#fff",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(27px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: theme.palette.darkGray.light,
    boxSizing: "border-box",
  },
}));

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MobileDialogButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "noMargin",
})(({ theme, noMargin }) => ({
  width: "100%",
  height: 30,
  marginTop: noMargin ? theme.spacing() : theme.spacing(3),
  textTransform: "none",
  backgroundColor: theme.palette.darkGray.main,
  color: "#fff",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));
