import {
  bottomNavbarConstants,
  bottomNavbarInitialState,
} from "../../Utils/Constants";

const initialState = {
  menuValue: "home",
};
const bottomNavbarValue = (state = initialState, action) => {
  switch (action.type) {
    case bottomNavbarConstants.SET_MENU:
      return {
        ...state,
        menuValue: action.menu,
      };
    case bottomNavbarConstants.CLEAR_BOTTOM_NAVBAR_REDUCER:
      return bottomNavbarInitialState;
    default:
      return state;
  }
};

export { bottomNavbarValue };
