import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SectionContainer from "../Common/SectionContainer";
import ChangePassword from "../NewDialogs/ChangePassword";
import UpdateEmail from "../NewDialogs/UpdateEmail";
import UpdatePhone from "../NewDialogs/UpdatePhone";

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const Header = styled(Typography)(({ theme }) => ({
  height: "auto",
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "30px",
  color: theme.palette.darkGray.main,
}));

const StyledButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 6,
  height: 30,
  width: 150,
  color: "#fff",
  backgroundColor: "#6A6868",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6A6868",
  },
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "auto",
  marginBottom: theme.spacing(),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "value",
})(({ theme, value }) => ({
  width: value ? "fit-content" : 300,
  height: "auto",
  fontSize: 24,
  lineHeight: "30px",
  color: value ? theme.palette.darkGray.main : "#6A6868",
  marginRight: value && theme.spacing(2),
  fontWeight: 400,
}));

const Grow = styled("div")(() => ({
  flex: 1,
}));

const SigninDetails = ({ person }) => {
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);

  const togglePhone = () => setPhone(!phone);
  const toggleEmail = () => setEmail(!email);
  const togglePassword = () => setPassword(!password);

  return (
    <SectionContainer>
      <HeaderContainer>
        <Header>Giriş Bilgileri</Header>
      </HeaderContainer>
      <Row>
        <Text>Email Adresi</Text>
        <Text value>{person?.email}</Text>
        <Grow />
        <StyledButton onClick={toggleEmail}>Eposta Değiştir</StyledButton>
      </Row>
      <Row>
        <Text>İletişim Numarası</Text>
        <Text value>{person?.gsm}</Text>
        <Grow />
        <StyledButton onClick={togglePhone}>Numara Değiştir</StyledButton>
      </Row>
      <Row>
        <Text>Şifre Değiştir</Text>
        <Text value>******</Text>
        <Grow />
        <StyledButton onClick={togglePassword}>Şifre Değiştir</StyledButton>
      </Row>
      <UpdatePhone open={phone} onClose={togglePhone} />
      <UpdateEmail open={email} onClose={toggleEmail} />
      <ChangePassword open={password} onClose={togglePassword} />
    </SectionContainer>
  );
};

SigninDetails.propTypes = {
  person: PropTypes.object,
};

export default SigninDetails;
