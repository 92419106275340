import React from "react";

import { styled } from "@mui/material/styles";

const PREFIX = "ResendNotice";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    marginTop: 36.37,
    width: 288,
    height: 37.5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    userSelect: "none",
    marginBottom: 36.46,
  },

  [`& .${classes.text}`]: {
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
}));

const ResendNotice = () => {
  return (
    <Root className={classes.root}>
      <div className={classes.text}>SMS olarak kodu almadıysanız</div>
      <div className={classes.text}>
        süre dolduktan sonra tekrar kod isteyin.
      </div>
    </Root>
  );
};

export default ResendNotice;
