import * as React from "react";
import trLocale from "date-fns/locale/tr";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Grid from "@mui/material/Grid";

import TitleComponent from "./Common/TitleComponent";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(),
}));

const DateSelection = ({ date, setDate }) => {
  const deleteSelections = () =>
    setDate({ startDate: new Date(), endDate: new Date() });
  return (
    <Root>
      <TitleComponent title="Tarih Aralığı" func={deleteSelections} />
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              value={date.startDate}
              mask="__.__.____"
              onChange={(newValue) => {
                setDate({ ...date, startDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
              InputProps={{
                style: { borderRadius: 6, height: 30 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={date.endDate}
              mask="__.__.____"
              onChange={(newValue) => {
                setDate({ ...date, endDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
              minDate={date.startDate}
              InputProps={{
                style: { borderRadius: 6, height: 30 },
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Root>
  );
};

DateSelection.propTypes = {
  date: PropTypes.object,
  setDate: PropTypes.func,
};

export default DateSelection;
