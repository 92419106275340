import React from "react";

import { styled } from "@mui/material/styles";

import Column from "./FooterMain/Column";
import {
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  FourthColumn,
} from "../../Utils/Constants";

const PREFIX = "FooterMain";

const classes = {
  root: `${PREFIX}-root`,
  gridContainer: `${PREFIX}-gridContainer`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    maxWidth: "100vw",
    height: 210,
    paddingTop: 20,
    backgroundColor: "#000000",
    userSelect: "none",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.gridContainer}`]: {
    height: "100%",
    width: 1232,
    display: "flex",
    borderBottom: "1px solid #fff",
  },
}));

const FooterMain = () => {
  return (
    <Root className={classes.root}>
      <div className={classes.gridContainer}>
        <div style={{ width: "28%" }}>
          <Column header="ÖzSafir Halıcılık" data={FirstColumn} />
        </div>
        <div style={{ width: "30.4%" }}>
          <Column header="Ürün ve Markalar" data={SecondColumn} />
        </div>
        <div style={{ width: "26.3%" }}>
          <Column header="Sipariş ve Ödeme" data={ThirdColumn} />
        </div>
        <div style={{ width: "15.3%" }}>
          <Column header="Ayarlar" data={FourthColumn} />
        </div>
      </div>
    </Root>
  );
};

export default React.memo(FooterMain);
