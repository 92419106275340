import React, { useState, useEffect } from "react";
import { Transition } from "../../../Utils/Tools";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

import MobileFilterRows from "./MobileOrderFilters/MobileFilterRows";
import Header from "./MobileOrderFilters/Header";
import FilterDialog from "./MobileOrderFilters/FilterDialog";
import DateRow from "./MobileOrderFilters/DateRow";
import DateDialog from "./MobileOrderFilters/DateDialog";

import { orderStatus, SourceValues } from "../../../Utils/Constants";
import { dateFormatter } from "../../../Utils/Helpers";

const ApplyButton = styled(Button)(({ theme }) => ({
  width: "100%",
  textTransform: "none",
  height: 40,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#fff",
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 8,
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
}));

const MobileOrderFilters = ({
  open,
  handleClose,
  setQuery,
  query,
  users,
  userAddresses,
}) => {
  const [openDialog, setOpenDialog] = useState({
    order_key: false,
    order_status: false,
    source: false,
    user_id: false,
    shipping_address: false,
  });
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [filters, setFilters] = useState({
    order_key: "",
    order_status: [],
    source: [],
    user_id: [],
    shipping_address: [],
    created_at: [],
    status: 1,
  });

  const [filterVals, setFilterVals] = useState({
    order_key: "",
    order_status: [],
    source: [],
    user_id: [],
    shipping_address: [],
  });

  useEffect(() => {
    let active = true;
    if (active) {
      setFilterVals({
        order_status: orderStatus,
        source: SourceValues,
        user_id: users,
        shipping_address: userAddresses,
      });
    }
    return () => {
      active = false;
    };
  }, [users, userAddresses]);

  const toggleDateDialog = () => setOpenDateDialog((prev) => !prev);
  const goBackDateDialog = () => {
    clearDates();
    toggleDateDialog();
  };
  const clearDates = () => {
    setDate({
      startDate: new Date(),
      endDate: new Date(),
    });
  };

  const filterSections = [
    {
      sectionName: "Sipariş Durumu",
      sectionValue: "order_status",
    },
    {
      sectionName: "Kaynak",
      sectionValue: "source",
    },
    {
      sectionName: "Kullanıcılar",
      sectionValue: "user_id",
    },
    {
      sectionName: "Sevk Adresi",
      sectionValue: "shipping_address",
    },
  ];

  const openRelatedDialog = (prop) => (e) => {
    setOpenDialog({ ...openDialog, [prop]: true });
  };

  const closeRelatedDialog = (prop) =>
    setOpenDialog({ ...openDialog, [prop]: false });

  const onDelete = () => {
    clearFilters();
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        status: 1,
        order_key: "",
        order_status: [],
        source: [],
        user_id: [],
        shipping_address: [],
        created_at: [],
      },
    });
    handleClose();
  };

  const clearFilters = () => {
    setFilters({
      order_key: "",
      order_status: [],
      source: [],
      user_id: [],
      shipping_address: [],
      created_at: [],
    });
    setDate({
      startDate: new Date(),
      endDate: new Date(),
    });
  };

  const handleSubmit = () => {
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        ...filters,
        created_at: [
          dateFormatter(date.startDate),
          dateFormatter(date.endDate),
        ],
      },
    });
    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <Header
        handleClose={handleClose}
        headerText="Filtre"
        onDelete={onDelete}
      />
      <DateRow name="Tarih Aralığı" date={date} openDialog={toggleDateDialog} />
      {filterSections.map((item, index) => (
        <MobileFilterRows
          key={index}
          name={item.sectionName}
          items={filters[item.sectionValue]}
          value={item.sectionValue}
          openDialog={openRelatedDialog}
        />
      ))}
      {filterSections.map((item, index) => (
        <FilterDialog
          key={index}
          open={openDialog[item.sectionValue]}
          handleClose={closeRelatedDialog}
          items={filterVals[item.sectionValue]}
          value={item.sectionValue}
          filter={item.sectionName}
          filters={filters}
          setFilters={setFilters}
        />
      ))}
      <DateDialog
        open={openDateDialog}
        handleClose={toggleDateDialog}
        date={date}
        setDate={setDate}
        goBackDateDialog={goBackDateDialog}
        clearDates={clearDates}
      />
      <div style={{ flex: 1 }}></div>
      <ButtonContainer>
        <ApplyButton onClick={handleSubmit} disableRipple>
          Uygula
        </ApplyButton>
      </ButtonContainer>
    </Dialog>
  );
};

MobileOrderFilters.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  users: PropTypes.array,
  userAddresses: PropTypes.array,
};

export default MobileOrderFilters;
