import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

import { Transition } from "../../../Utils/Tools";
import { orderService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";
import Loading from "../../../Utils/Loading";

import Header from "./MobileOrderDetails/Header";
import MainCard from "./MobileOrderDetails/MainCard";
import OrderProductCard from "./MobileOrderDetails/OrderProductCard";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: "100vh",
  backgroundColor: "#fff",
  padding: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  position: "relative",
  userSelect: "none",
  overflow: "hidden",
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  padding: 0,
  paddingBottom: theme.spacing(),
}));

const MobileOrderDetails = ({ open, handleClose, id }) => {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && id && !currentOrder) {
        getRelatedProducts(id);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const getRelatedProducts = async (id) => {
    setLoading(true);
    await orderService
      .getOrder(id)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setCurrentOrder(res.data.order);
          setLoading(false);
        } else {
          toast.error(res.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <StyledContainer>
        <Loading open={loading} />
        <Header handleClose={handleClose} />
        <ContentContainer>
          <MainCard data={currentOrder} />
          {currentOrder?.products.map((product, index) => (
            <OrderProductCard product={product} key={index} />
          ))}
        </ContentContainer>
      </StyledContainer>
    </Dialog>
  );
};

MobileOrderDetails.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  id: PropTypes.number,
};

export default MobileOrderDetails;
