import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeader from "../../../Ui/Dialog/Tools/MobileDialogHeader";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    paddingTop: theme.spacing(),
    margin: 0,
    overflow: "hidden",
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(),
  paddingLeft: theme.spacing(1.5),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  fontSize: 10,
  lineHeight: "16px",
  fontWeight: "normal",
  color: color,
  marginRight: theme.spacing(),
}));

// const DateText = styled(Typography)(({ theme }) => ({
//   fontSize: 9,
//   lineHeight: "16px",
//   fontWeight: 300,
//   color: theme.palette.secondary.main,
//   paddingLeft: theme.spacing(),
// }));

const ProductStocks = ({ handleClose, open, stock }) => {
  let stockColor =
    stock?.quantity > 3
      ? "#5ABD8C"
      : stock?.quantity === 0
      ? "#FB0505"
      : "orange";

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeader header="Depo Miktarları" callback={handleClose} />
      <Row>
        <Text color="#434141">Merkez Depo:</Text>
        <Text color={stockColor}>{stock?.quantity} Adet</Text>
      </Row>
      {/* <Row>
        <Text color="#434141">Konya Depo:</Text>
        <Text color="#5ABD8C">10 Adet</Text>
      </Row>
      <Row>
        <Text color="#434141">Fabrika Depo:</Text>
        <Text color="#FB0505">Stok Sorunuz</Text>
        <IconButton style={{ width: 15, height: 15 }}>
          <img src={Bell} width="10.5" height="12.25" alt="" />
        </IconButton>
      </Row> */}
      {/* <DateText>Tahmini Temin Tarihi :23.08.2021</DateText> */}
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleClose}
        noMargin>
        Kapat
      </MobileDialogButton>
    </StyledDialog>
  );
};

ProductStocks.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  stock: PropTypes.object,
};

export default ProductStocks;
