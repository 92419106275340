const getUsersArr = (users) => {
  let arr = [];
  for (let item of users) {
    let obj = {};
    obj.name = item.full_name;
    obj.id = item.id;
    arr.push(obj);
  }
  return arr;
};

export { getUsersArr };
