import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AutoCompleteArea from "../../../../Ui/Fields/AutocompleteArea";
import AutoCompleteMultiple from "../../../../Ui/Fields/AutoCompleteMultiple";

import {
  generalConstants,
  roleNameConverter,
} from "../../../../Utils/Constants";
import {
  addressService,
  companyService,
  userService,
} from "../../../../Services";
import Loading from "../../../../Utils/Loading";

const PREFIX = "EditUserFirm";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  inputContainer: `${PREFIX}-inputContainer`,
  submitButton: `${PREFIX}-submitButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: theme.spacing(),
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const EditUserFirm = ({
  open,
  onClose,
  roles,
  user,
  newGetUsers,
  companyId,
  isAdmin,
  query_,
}) => {
  const query = {
    pageNumber: 1,
    pageSize: 1000,
    sortField: "id",
    sortOrder: "asc",
    filter: {},
  };

  const companyArr = ["Firma Kullanıcı", "Firma Yetkili"];

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firm: { name: "Lütfen firma seçiniz", id: 0.5 },
    branch: [],
    roles: {
      name: "Lütfen yetki seçiniz",
      id: 0.5,
    },
    defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
  });
  const [error, setError] = useState(false);
  const [firms, setFirms] = useState([
    { name: "Lütfen firma seçinizi", id: 0.5 },
  ]);
  const [addresses, setAddresses] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        let userQuery = { ...query, filter: { user_id: user?.id } };
        getUserAddresses(userQuery);
        getCompanyList(query);
        setError(false);
        if (user) {
          setValues({
            ...values,
            firm: { name: user?.company?.name, id: user?.company?.id },
            roles: {
              name: roleNameConverter[
                user?.role_names?.length > 0 && user?.role_names[0]
              ],
              id: user?.role_ids?.length && user?.role_ids[0],
            },
          });
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, user]);

  useEffect(() => {
    let active = true;
    if (active && values.roles.id !== 0.5) {
      if (values?.branch?.length === 0) {
        setValues({
          ...values,
          defaultAddress: { name: "Lütfen yetkili adres seçiniz", id: 0.5 },
        });
      } else {
        let defId = user?.address?.id;
        let filteredAddr = values.branch.filter((item) => item.id === defId);
        if (filteredAddr.length > 0) {
          setValues({
            ...values,
            defaultAddress: filteredAddr[0],
          });
        } else {
          setValues({
            ...values,
            defaultAddress: values.branch[0],
          });
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.branch]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.firm) {
        let id = values.firm.id;
        if (id !== 0.5) {
          let adminQuery = { ...query, filter: { company_id: id } };
          getCompanyAddresses(adminQuery);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.firm.id]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (userAddresses.length > 0) {
        setValues({
          ...values,
          branch: userAddresses
        })
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddresses]);

  const getCompanyList = async (query) => {
    setLoading(true);
    const response = await companyService.getCompanies(query);
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies;
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ["name"].every((k) => t[k] === v[k])) === i
      );
      let finalData = notDuplicatedData.filter((item) => item.name !== "None");
      setFirms(finalData);
      setLoading(false);
    } else {
      setFirms([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const getCompanyAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.addresses;
      let newAddresses = data.filter((item) => item.type === 0);
      setAddresses(newAddresses);
      setLoading(false);
    } else {
      setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const getUserAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.addresses;
      let userAddresses = data.filter((item) => item.type === 0);
      let customerAddresses = data.filter((item) => item.type === 1);
      setCustomerAddresses(customerAddresses);
      setUserAddresses(userAddresses);
      setLoading(false);
    } else {
      setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const handleCheckUser = () => {
    if (
      !values.firm ||
      !values.branch ||
      !values.roles ||
      !values.defaultAddress
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleCheck = () => {
    if (!values.firm || !values.roles) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isUser = values?.roles?.name === "Firma Kullanıcı";
    let isCompanyAdmin = values?.roles?.name === "Firma Yetkili";
    let isError = isUser ? handleCheckUser() : handleCheck();
    let initialQuery = {
      pageNumber: 1,
      pageSize: 10,
      sortField: "id",
      sortOrder: "desc",
      filter: {},
    };
    if (!isError) {
      setLoading(true);
      let allAddrresses = customerAddresses.concat(values.branch);
      let addrIds = allAddrresses.map((item) => item.id.toString());
      let userQuery = !companyArr.includes(values?.roles?.name)
      ? { roles: [`${values.roles.id}`] }
      : values?.roles?.name === "Firma Kullanıcı"
      ? {
        roles: [`${values.roles.id}`],
        addresses: isUser ? addrIds : [],
        company_id: +values.firm.id
      }
      : {
        roles: [`${values.roles.id}`],
        company_id: +values.firm.id,
      };
      const response = await userService.editRole(user?.id, userQuery);
      if (response?.data?.status === generalConstants.STATUS_TRUE) {
        if (isUser || isCompanyAdmin) {
          let query = {
            is_default: true,
            address_id: +values.defaultAddress.id,
          };
          const res = await addressService.toggleDefaultAddress(
            query,
            +user?.id
          );
          if (res.data.status === generalConstants.STATUS_TRUE) {
            handleSuccess(initialQuery);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        } else {
          handleSuccess(initialQuery);
        }
      } else {
        toast.error(response?.data?.message);
        setLoading(false);
      }
    }
  };

  const handleSuccess = (query) => {
    toast.success("Firma bilgileri başarılı bir şekilde güncellendi");
    setLoading(false);
    onClose();
    newGetUsers(query_, companyId, isAdmin, true);
  };
  // const isActive = status && status === "Aktif";
  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Firma Bilgileri</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Loading open={loading} />
        <div className={classes.inputContainer}>
          <AutoCompleteArea
            data={roles}
            label="Yetki Grubu"
            setValues={setValues}
            prop="roles"
            values={values}
            error={error}
            // disabled={isActive}
          />
          {companyArr.includes(values?.roles?.name) && (
            <AutoCompleteArea
              data={firms}
              label="Firma"
              setValues={setValues}
              prop="firm"
              values={values}
              error={error}
              // disabled={isActive}
            />
          )}
          {values?.roles?.name === "Firma Kullanıcı" && (
            <AutoCompleteMultiple
              data={addresses}
              label="Yetkili Adresler"
              setValues={setValues}
              prop="branch"
              values={values}
              error={error}
              // disabled={isActive}
              defVal={values?.branch}
            />
          )}
          {(values?.roles?.name === "Firma Kullanıcı" ||
            values?.roles?.name === "Firma Yetkili") && (
            <AutoCompleteArea
              data={values?.branch}
              label="Tanımlı Adres"
              setValues={setValues}
              prop="defaultAddress"
              values={values}
              error={error}
              // disabled={isActive}
            />
          )}
          <Button
            variant="contained"
            disableRipple
            onClick={handleSubmit}
            className={classes.submitButton}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

EditUserFirm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roles: PropTypes.array,
  user: PropTypes.object,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
  companyId: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default EditUserFirm;
