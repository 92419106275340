import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import TextArea from "../../../Ui/Fields/TextArea";
import PhoneArea from "../../../Ui/Fields/PhoneArea";

import { generalConstants } from "../../../Utils/Constants";
import { userService } from "../../../Services";
import { authActions } from "../../../Redux/Actions";

const PREFIX = "EditUser";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  logoContainer: `${PREFIX}-logoContainer`,
  circle: `${PREFIX}-circle`,
  circleText: `${PREFIX}-circleText`,
  inputContainer: `${PREFIX}-inputContainer`,
  switchAndButtonContainer: `${PREFIX}-switchAndButtonContainer`,
  linkButton: `${PREFIX}-linkButton`,
  formLabel: `${PREFIX}-formLabel`,
  submitButton: `${PREFIX}-submitButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: 10,
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.logoContainer}`]: {
    width: "100%",
    height: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    marginTop: 20,
    marginBottom: 10,
  },

  [`& .${classes.circle}`]: {
    width: 90,
    height: 90,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.circleText}`]: {
    fontSize: 34,
    fontWeight: 400,
    color: "#FFFFFF",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.switchAndButtonContainer}`]: {
    marginTop: 10,
    width: "100%",
    height: 42,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.linkButton}`]: {
    height: 19,
    width: 117,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#F5B041",
    borderRadius: 6,
    fontSize: 10,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#F5B041",
    },
  },

  [`& .${classes.formLabel}`]: {
    color: "#B1AEAE",
    fontWeight: 400,
    fontSize: 12,
    "&[aktif='true']": {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const EditProfileDetails = ({ open, onClose, user }) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const dispatch = useDispatch();

  const signOut = () => dispatch(authActions.signout());

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gsm: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && user) {
        setValues({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          gsm: user.gsm.substring(4),
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, user]);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleCheck = () => {
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");

    if (
      values.first_name.length === 0 ||
      values.last_name.length === 0 ||
      values.email.length === 0 ||
      !emailRegex.test(values.email) ||
      newPhone.length !== 13
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      let phoneCode = "+905";
      let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
      let userQuery = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        gsm: newPhone,
      };
      const response = await userService.updateUser(userQuery);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Kullanıcı bilgileri başarılı bir şekilde güncellendi.");
        setTimeout(() => {
          onClose();
          signOut();
        }, 1000);
      } else {
        toast.error(response?.data?.message);
      }
    }
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Kullanıcı Bilgileri</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.inputContainer}>
          <TextArea
            label="Ad"
            handleChange={handleChange}
            values={values}
            prop="first_name"
            error={error}
          />
          <TextArea
            label="Soyad"
            handleChange={handleChange}
            values={values}
            prop="last_name"
            error={error}
          />
          <TextArea
            label="Eposta"
            handleChange={handleChange}
            values={values}
            prop="email"
            component="add user"
            error={error}
          />
          <PhoneArea
            label="Telefon"
            handleChange={handleChange}
            values={values}
            prop="gsm"
            component="add user"
            error={error}
          />
          <Button
            variant="contained"
            disableRipple
            onClick={handleSubmit}
            className={classes.submitButton}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

EditProfileDetails.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default EditProfileDetails;
