import { customerConstants } from "../../Utils/Constants";

const setFilters = (query) => {
  return {
    type: customerConstants.ADD_FILTER,
    query,
  };
};

const setMobileFilters = (query, prop) => {
  return {
    type: customerConstants.ADD_MOBILE_FILTER,
    payload: { query, prop },
  };
};

const setSearchText = (text) => {
  return {
    type: customerConstants.SET_SEARCH_TEXT,
    text,
  };
};

const clearSearchText = () => {
  return {
    type: customerConstants.CLEAR_SEARCH_TEXT,
  };
};

const clearFilters = () => {
  return {
    type: customerConstants.CLEAR_FILTERS,
  };
};

const setDealerTabIndex = (index) => {
  return {
    type: customerConstants.SET_TAB_INDEX,
    index,
  };
};

const setFilterTabIndex = (value) => {
  return {
    type: customerConstants.SET_FILTER_TAB_INDEX,
    value,
  };
};

const updateFilters = (filters) => {
  return {
    type: customerConstants.UPDATE_FILTERS,
    filters,
  };
};

const setAdmin = (bool) => {
  return { type: customerConstants.SET_ADMIN, bool };
};

const setUserCurrent = (query) => {
  return { type: customerConstants.SET_USER_CURRENT, query };
};

const selectedUser = (user) => {
  return { type: customerConstants.SELECTED_USER, user };
};

const clearCustomerReducer = () => {
  return { type: customerConstants.CLEAR_CUSTOMER_REDUCER };
};

const updateCustomerAddressByToggle = (address) => {
  return { type: customerConstants.UPDATE_CUSTOMER_ADDRESS_BY_TOGGLE, address };
};

const restoreDefaultAddress = () => {
  return { type: customerConstants.RESTORE_DEFAULT_ADDRESS };
};

export const customerActions = {
  setFilters,
  clearFilters,
  setDealerTabIndex,
  setSearchText,
  clearSearchText,
  setFilterTabIndex,
  updateFilters,
  setAdmin,
  setUserCurrent,
  selectedUser,
  setMobileFilters,
  clearCustomerReducer,
  updateCustomerAddressByToggle,
  restoreDefaultAddress,
};
