import React from "react";
// import PropTypes from "prop-types";

const BankTransfer = () => {
  return <div>Bank Transfer</div>;
};

// BankTransfer.propTypes = {};

export default BankTransfer;
