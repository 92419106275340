import { uiHeader, authCustomerHeader } from "./Config";
import { customerConstants } from "../Utils/Constants/CustomerConstants";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const signin = async (query) => {
  return await axios
    .post(`${API_URL}/auth/signIn`, query, uiHeader())
    .then((res) => {
      return res;
    });
};

const signinPhone = async (query) => {
  return await axios
    .post(`${API_URL}/auth/createCode`, query, uiHeader())
    .then((res) => {
      return res;
    });
};

const validateAndSignin = async (query) => {
  return await axios
    .post(`${API_URL}/auth/validateAndSignIn`, query, uiHeader())
    .then((res) => {
      return res;
    });
};

const signout = () => {
  localStorage.removeItem(customerConstants.CUSTOMER);
};

const changePassword = async (query) => {
  return await axios
    .put(`${API_URL}/auth/change_password`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const userUpdate = async (query) => {
  return await axios
    .put(`${API_URL}/user/update`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const userActivation = async (query) => {
  return await axios
    .post(`${API_URL}/user/activation`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const userActivationLink = async (id) => {
  return await axios
    .put(`${API_URL}/user/admin/activation/${id}`, null, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const userResetPassword = async (query) => {
  return await axios
    .post(`${API_URL}/auth/update-password`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const userRenewPassword = async (query) => {
  return await axios
    .post(`${API_URL}/auth/reset-password`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const createConfirmCode = async (query) => {
  return await axios
    .post(`${API_URL}/auth/createConfirmCode`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const validateConfirmCode = async (query) => {
  return await axios
    .post(`${API_URL}/auth/validateConfirmCode`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const authService = {
  signin,
  signout,
  signinPhone,
  validateAndSignin,
  changePassword,
  userUpdate,
  userActivation,
  userResetPassword,
  userRenewPassword,
  userActivationLink,
  createConfirmCode,
  validateConfirmCode,
};
