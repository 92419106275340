import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextArea from "../../../../../Ui/Fields/TextArea";
import PhoneArea from "../../../../../Ui/Fields/PhoneArea";

import { generalConstants } from "../../../../../Utils/Constants";
import { getFirstLetters } from "../../../../../Utils/Helpers";

import { userService } from "../../../../../Services";
import { MobileDialogButton } from "../../../../../Utils/Tools";
import { BufiSwitch } from "../../../../../Utils/Tools";

const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .css-1pzulcg-MuiTypography-root": {
    color: theme.palette.secondary.main,
    fontWeight: 300,
    fontSize: 12,
    "&[aktif='true']": {
      color: theme.palette.primary.main,
    },
  },
}));

const NameAndLetterContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
}));

const Letters = styled("div")(({ theme }) => ({
  width: 110,
  height: 110,
  minWidth: 110,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LetterText = styled(Typography)(() => ({
  fontSize: 34,
  fontWeight: 400,
  color: "#fff",
  textTransform: "uppercase",
}));

const NameContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(2),
}));

const SwitchAndButton = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
}));

const UserEdit = ({
  onClose,
  newGetUsers,
  query,
  userInfo,
  status,
}) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gsm: "",
  });

  const [letters, setLetters] = useState(null);
  const [status_, setStatus_] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setLetters(getFirstLetters(userInfo?.first_name, userInfo?.last_name));
      setValues({
        ...values,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email,
        gsm: userInfo.gsm.substring(4),
      });
    } else setLetters("B", "B");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleCheck = () => {
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
    if (
      values.first_name.length === 0 ||
      values.last_name.length === 0 ||
      values.email.length === 0 ||
      !emailRegex.test(values.email) ||
      newPhone.length !== 13
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSwitch = async () => {
    const response = await userService.deactivateUser(userInfo?.id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setStatus_(false);
      newGetUsers(query, true);
      onClose();
      toast.success("Kullanıcı başarılı bir şekilde pasif hale getirildi");
    } else {
      toast.error(response?.data?.message);
    }
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      let phoneCode = "+905";
      let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
      let userQuery = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        gsm: newPhone,
      };
      const response = await userService.editUser(userQuery, userInfo?.id);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Kullanıcı bilgileri başarılı bir şekilde güncellendi.");
        handleClose();
        newGetUsers(query, true);
      } else {
        toast.error(response?.data?.message);
      }
    }
  };

  const handleClose = () => {
    setError(false);
    onClose();
  };

  const isActive = status && status === "Aktif";

  return (
    <div>
      <NameAndLetterContainer>
        <Letters>
          <LetterText>{letters && letters}</LetterText>
        </Letters>
        <NameContainer>
          <TextArea
            label="Ad"
            handleChange={handleChange}
            values={values}
            prop="first_name"
            component="add user"
            error={error}
            // disabled={isActive}
          />
          <TextArea
            label="Soyad"
            handleChange={handleChange}
            values={values}
            prop="last_name"
            component="add user"
            error={error}
            // disabled={isActive}
          />
        </NameContainer>
      </NameAndLetterContainer>
      <TextArea
        label="Eposta"
        handleChange={handleChange}
        values={values}
        prop="email"
        component="add user"
        error={error}
        // disabled={isActive}
      />
      <PhoneArea
        label="Telefon"
        handleChange={handleChange}
        values={values}
        prop="gsm"
        component="add user"
        error={error}
        // disabled={isActive}
      />
      {isActive && (
        <SwitchAndButton>
          <FormControl>
            <StyledFormLabel
              control={
                <BufiSwitch
                  checked={status_}
                  value={status_}
                  onChange={() => handleSwitch()}
                />
              }
              label={
                status_ ? (
                  <Typography aktif="true">Aktif</Typography>
                ) : (
                  <Typography>Pasif</Typography>
                )
              }
            />
          </FormControl>
        </SwitchAndButton>
      )}
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}>
        Kaydet
      </MobileDialogButton>
    </div>
  );
};
UserEdit.propTypes = {
  onClose: PropTypes.func,
  userInfo: PropTypes.object,
  status: PropTypes.string,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
};

export default UserEdit;
