import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import { errorTexts } from "../../Utils/Constants";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  width: "100%",
  height: 30.48,
  "& .MuiInputBase-root": {
    height: 30.48,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 8,
    height: 20,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 8,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[700],
  marginTop: 10,
  marginBottom: 5,
  marginLeft: 1,
  userSelect: "none",
}));

const StyledHelperText = styled(FormHelperText)(() => ({
  fontSize: 8,
  color: "red",
  userSelect: "none",
  "&.MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: 20,
  height: 20,
}));

const PasswordArea = ({
  label,
  handleChange,
  values,
  prop,
  error,
  handleClickShowPassword,
  handleMouseDownPassword,
  visibilityProp,
}) => {
  let errorStatus = values[prop] === "";

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
      <StyledLabel>{label}</StyledLabel>
      <FormControl style={{ width: "100%" }}>
        <StyledTextField
          type={values[visibilityProp] ? "text" : "password"}
          fullWidth
          value={values[prop] || ""}
          onChange={handleChange(prop)}
          error={
            error
              ? prop === "confirmPassword"
                ? values[prop] === values["newPassword"]
                  ? false
                  : true
                : errorStatus
              : false
          }
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: (theme) => theme.spacing(),
                }}>
                <StyledIconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword(visibilityProp)}
                  onMouseDown={handleMouseDownPassword}
                  size="large">
                  {values[visibilityProp] ? <Visibility /> : <VisibilityOff />}
                </StyledIconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
        <StyledHelperText>
          {error && errorStatus ? errorTexts[prop] : ""}
        </StyledHelperText>
      </FormControl>
    </div>
  );
};

PasswordArea.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  prop: PropTypes.string,
  error: PropTypes.bool,
  handleClickShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  visibilityProp: PropTypes.string,
};

export default PasswordArea;
