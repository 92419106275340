import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import OrderInfo from "./OrderProductCard/OrderInfo";
import HorizontalStepper from "./OrderProductCard/HorizontalStepper";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(),
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  borderRadius: 6,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  marginTop: theme.spacing(2),
}));

const OrderProductCard = ({ product }) => {
  return (
    <Root>
      <OrderInfo product={product} />
      <HorizontalStepper status={product?.status} />
    </Root>
  );
};

OrderProductCard.propTypes = {
  product: PropTypes.object,
};

export default OrderProductCard;
