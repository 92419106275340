import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import Typography from "@mui/material/Typography";

const PREFIX = "PaymentArea";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  row: `${PREFIX}-row`,
  value: `${PREFIX}-value`,
  sum: `${PREFIX}-sum`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 'auto',
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: "7px 22px 21px 10px",
    marginBottom: 18,
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.darkGray.main,
    marginBottom: 15,
  },

  [`& .${classes.row}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 3,
    borderBottom: "1px solid #B1AEAE",
    marginBottom: 6,
    "&[is-sum='true']": {
      borderBottom: "none",
      paddingBottom: 0,
      marginBottom: 0,
      marginTop: 5,
    },
  },

  [`& .${classes.value}`]: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.darkGray.main,
    "&[is-black='true']": {
      color: "#000000",
    },
  },

  [`& .${classes.sum}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
  },
}));

const PaymentArea = () => {
  const cart = useSelector(({ product }) => product?.cart?.order);

  return (
    <Root className={classes.root}>
      <Typography className={classes.header}>Ödenecek Tutar</Typography>
      <div className={classes.row}>
        <Typography className={classes.value}>Ürün Toplamı</Typography>
        <Typography className={classes.value}>{cart?.total_cost}₺</Typography>
      </div>
      <div className={classes.row}>
        <Typography className={classes.value}>İndirim</Typography>
        <Typography className={classes.value} is-black="true">
          0.00₺
        </Typography>
      </div>
      <div className={classes.row}>
        <Typography className={classes.value}>Kargo / Nakliye</Typography>
        <Typography className={classes.value} is-black="true">
          0.00₺
        </Typography>
      </div>
      <div className={classes.row} is-sum="true">
        <Typography className={classes.sum}>Toplam Tutar</Typography>
        <Typography className={classes.sum} is-black="true">
          {cart?.total_cost}₺
        </Typography>
      </div>
    </Root>
  );
};

export default PaymentArea;
