import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import purpleRadio from "../../../../Images/DealerProfile/PurpleRadio.svg";
import RadioUnselected from "../../../../Images/ProductPage/RadioUnselected.svg";

import MobileDialogHeader from "../../../../Ui/Dialog/Tools/MobileDialogHeader";

import { MobileDialogButton, Transition } from "../../../../Utils/Tools";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    paddingTop: theme.spacing(),
    margin: 0,
    overflow: "hidden",
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(2),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "100%",
  height: "auto",
  marginBottom: theme.spacing(),
  "& .MuiFormControlLabel-label": {
    marginLeft: theme.spacing(),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",
    color: theme.palette.darkGray.main,
  },
}));

const ChangeRoleMob = ({ open, onClose, roles, userRole }) => {
  const [role, setRole] = useState(null);
  const [filteredRoles, setFilteredRoles] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      if (userRole) {
        setRole(userRole);
      }
      if (roles && userRole) {
        if (+userRole === 3) {
          let newRoles = roles.filter((role) => role.id !== 1 && role.id !== 2);
          setFilteredRoles(newRoles);
        } else {
          setFilteredRoles(roles);
        }
      }
    }
    return () => {
      active = false;
    };
  }, [roles, userRole]);

  const selectRole = (e) => setRole(e.target.value);

  const handleSubmit = () => onClose();

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeader header="Yetki Listesi" callback={onClose} />
      <StyledFormControl component="fieldset">
        <RadioGroup style={{ width: "100%" }}>
          {filteredRoles?.map((item, index) => {
            return (
              <StyledFormControlLabel
                key={index}
                value={item.id}
                label={item.name}
                control={
                  <Radio
                    icon={<img src={RadioUnselected} width="20px" alt="" />}
                    checkedIcon={<img src={purpleRadio} width="20px" alt="" />}
                    onChange={selectRole}
                    style={{ height: 20, width: 20 }}
                    value={item.id}
                    checked={item.id === +role}
                  />
                }
              />
            );
          })}
        </RadioGroup>
      </StyledFormControl>
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}
        noMargin>
        Uygula
      </MobileDialogButton>
    </StyledDialog>
  );
};

ChangeRoleMob.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roles: PropTypes.array,
  userRole: PropTypes.number,
};

export default ChangeRoleMob;
