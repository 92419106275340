export const productConstants = {
  SET_PRODUCT: "SET_PRODUCT",
  GET_CART: "GET_CART",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  CLEAR_CART: "CLEAR_CART",

  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  GET_FEATURE_VALUES: "GET_FEATURE_VALUES",

  SET_NEW_ARRIVALS_DATA: "SET_NEW_ARRIVALS_DATA",

  CLEAR_PRODUCT_REDUCER: "CLEAR_PRODUCT_REDUCER",
};
