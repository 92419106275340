import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";

import Ozsafir from "../../Images/TopNavbar/Ozsafir.svg";
import SearchArea from "./SearchArea";

const innerWidth = window.innerWidth;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 2,
    top: 1,
    padding: 2,
    backgroundColor: theme.palette.primary.main,
    fontSize: 9,
    height: 13,
    minWidth: 13,
    color: "#fff",
    display: "flex",
    alignItems: "center",
  },
}));

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== "web" })(
  ({ theme, web }) => ({
    width: innerWidth,
    maxWidth: innerWidth,
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    height: web ? 130 : 55,
    marginBottom: theme.spacing(),
    marginTop: web && theme.spacing(3),
  })
);

const LogoContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 20,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const Logos = styled("div")(() => ({
  width: "fit-content",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const SearchButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 300,
  fontSize: 12,
  color: "#B1AEAE",
  padding: "3.5px 8px",
  width: "100%",
  height: 25,
  backgroundColor: "#fff",
  border: "1px solid rgba(127, 91, 120, 0.5)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  "&:hover, &:focus, &:active": {
    border: "1px solid rgba(127, 91, 120, 0.5)",
    backgroundColor: "#fff",
  },
}));

const MobileTopBar = ({ searchPage, web }) => {
  const history = useHistory();
  const cartInfo = useSelector(({ product }) => product.cart);
  const notificationsData = useSelector(
    ({ notifications }) => notifications?.data
  );

  const [cartBadge, setCartBadge] = useState(0);

  useEffect(() => {
    if (cartInfo) {
      if (cartInfo.order.total_quantity) {
        setCartBadge(parseInt(cartInfo.order.total_quantity));
      } else {
        setCartBadge(0);
      }
    } else {
      setCartBadge(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartInfo]);

  const goToSearch = () => history.push("./mobile-search");
  const goToHome = () => history.push("./home");
  const goToCartPage = () => history.push("./cart-page");
  const goToNotifications = () => history.push("./notifications");

  const iconStyle = { fontSize: 20, marginRight: -3, fill: "black" };

  const unReadNotifications = notificationsData
    ? notificationsData.filter((n) => n.is_read === 0)
    : [];

  return (
    <StyledBox web={web}>
      <LogoContainer>
        <img src={Ozsafir} alt="" width="96.6" height="20" onClick={goToHome} />
        <Logos>
          <IconButton onClick={goToNotifications}>
            <StyledBadge
              badgeContent={unReadNotifications?.length}
              color="primary">
              <NotificationsIcon style={iconStyle} />
            </StyledBadge>
          </IconButton>
          <IconButton onClick={goToCartPage}>
            <StyledBadge badgeContent={cartBadge} color="primary">
              <ShoppingCartOutlinedIcon style={iconStyle} />
            </StyledBadge>
          </IconButton>
        </Logos>
      </LogoContainer>
      {!searchPage ? (
        <SearchButton onClick={goToSearch} variant="outlined" disableRipple>
          Binlerce ürün arsından arama yapabilirsiniz
        </SearchButton>
      ) : (
        <SearchArea />
      )}
    </StyledBox>
  );
};

MobileTopBar.propTypes = {
  searchPage: PropTypes.bool,
};

export default MobileTopBar;
