import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Pagination from "@mui/material/Pagination";

import TabPanel from "./TabPanel";
import FiltersBox from "./FiltersBox";
import AppBarButtons from "./AppBarButtons";
import AppBarTabs from "./AppBarTabs";
import TabContent from "./TabContent";
import { concatArray, searchQueryMaker } from "../../../Utils/Helpers";

import { productService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";
import { customerActions } from "../../../Redux/Actions";
import Loading from "../../../Utils/Loading";

const PREFIX = "FilterTabs";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  tab: `${PREFIX}-tab`,
  indicator: `${PREFIX}-indicator`,
  tabs: `${PREFIX}-tabs`,
  appBarButtons: `${PREFIX}-appBarButtons`,
};

const Root = styled("div", { shouldForwardProp: (prop) => prop !== "loading" })(
  ({ loading, theme }) => ({
    [`&.${classes.root}`]: {
      backgroundColor: "#fff",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      overflow: loading && "hidden",
    },

    [`& .${classes.appBar}`]: {
      backgroundColor: "#fff",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      "&.MuiAppBar-root": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        boxShadow: "none",
        borderBottom: `1px solid ${theme.palette.darkGray.light}`,
      },
    },

    [`& .${classes.tab}`]: {
      fontSize: 10,
      fontWeight: 700,
      textTransform: "capitalize",
      color: "rgba(106, 104, 104, 1)",
      maxWidth: 160,
      width: 160,
      "&.MuiTab-root": {
        minWidth: 110,
      },
    },

    [`& .${classes.indicator}`]: {
      backgroundColor: "black",
    },

    [`& .${classes.tabs}`]: {
      maxWidth: 600,
      "& .Mui-selected": {
        fontSize: 12,
        fontWeight: 700,
        color: "rgba(67, 65, 65, 1)",
      },
    },

    [`& .${classes.appBarButtons}`]: {
      width: 54,
      height: 48,
      marginRight: theme.spacing(),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

const FilterTabs = ({ handleClearSearchText, setChildLoading }) => {
  const dispatch = useDispatch();

  const setFilterTab = (value) =>
    dispatch(customerActions.setFilterTabIndex(value));

  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState(null);
  const [view, setView] = useState("bars");
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const customer = useSelector(({ customer }) => customer);
  const results = customer && concatArray(customer?.filters);

  let tabValue = useMemo(() => customer?.filters.tabValue, [customer]);
  let filters = useMemo(() => customer.filters, [customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      setPageNumber(1);
      handleQuery(filters, customer, 1);
      setLoading(true);
      setChildLoading(true);
      if (tabValue !== "") {
        setProducts([]);
      } else {
        setFilterTab(0);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    let active = true;
    if (active) {
      handleQuery(filters, customer, pageNumber);
      setLoading(true);
      setChildLoading(true);
      if (tabValue !== "") {
        setProducts([]);
      } else {
        setFilterTab(0);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (products?.length === 0 && query) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const handleChange = (event, newValue) => {
    setFilterTab(newValue);
    setPageNumber(1);
  };

  const fetchData = () => {
    if (tabValue === 0) {
      getProducts(query);
    } else if (tabValue === 1) {
      handleBestSellers(query);
    } else if (tabValue === 2) {
      handleBestSellersArea(query);
    } else if (tabValue === 3) {
      handleBestSellersUser(query);
    } else if (tabValue === 4) {
      let newQuery = {
        ...query,
        filter: { ...query.filter, tag: "yeni" },
      };
      getNewArrivals(newQuery);
    } else {
      setLoading(false);
      setChildLoading(false);
    }
  };

  const getProducts = (query) => {
    productService
      .productSkuList(query)
      .then((res) => {
        commonFunc(res);
      })
      .catch((err) => {
        setLoading(false);
        setChildLoading(false);
        console.log(err);
      });
  };

  const getNewArrivals = (query) => {
    productService
      .productSkuList(query)
      .then((res) => {
        commonFunc(res);
      })
      .catch((err) => {
        setLoading(false);
        setChildLoading(false);

        console.log(err);
      });
  };

  const handleBestSellers = (query) => {
    productService
      .getBestSellers(query)
      .then((res) => {
        commonFunc(res);
      })
      .catch((err) => {
        setLoading(false);
        setChildLoading(false);

        console.log(err);
      });
  };

  const handleBestSellersArea = (query) => {
    productService
      .getBestSellersArea(query)
      .then((res) => {
        commonFunc(res);
      })
      .catch((err) => {
        setLoading(false);
        setChildLoading(false);

        console.log(err);
      });
  };

  const handleBestSellersUser = (query) => {
    productService
      .getBestSellersUser(query)
      .then((res) => {
        commonFunc(res);
      })
      .catch((err) => {
        setLoading(false);
        setChildLoading(false);

        console.log(err);
      });
  };

  const commonFunc = (res) => {
    if (res.data.status === generalConstants.STATUS_TRUE) {
      let data =
        res.data.product_skus.length === 0 ? null : res.data.product_skus;
      let count_ = Math.round(res.data.total_count / 16);
      setPageCount(count_);
      setProducts(data);
      setLoading(false);
      setChildLoading(false);
    } else {
      setLoading(false);
      setChildLoading(false);
    }
  };

  const handleQuery = (filters, customer, pageNumber) => {
    let query = searchQueryMaker(filters);
    const initialQuery = {
      pageNumber: pageNumber,
      pageSize: 16,
      sortField: "id",
      sortOrder: "desc",
      filter: {
        brands: query.brands,
        subcategories: query.categories,
        sizes: query.sizes,
        colors: query.colors,
        shapes: query.shapes,
        rating: query.rating,
        on_sale: 1,
        // type: query.type,
        // tag: query.tag
      },
    };
    if (customer.isAdmin) {
      let query_ = {
        ...query,
        filter: {
          ...query.filter,
          company_id: customer.currentCompany.id,
        },
      };
      let initialQuery_ = {
        ...initialQuery,
        filter: {
          ...initialQuery.filter,
          company_id: customer.currentCompany.id,
        },
      };
      return querySetter(query_, initialQuery_);
    } else {
      return querySetter(query, initialQuery);
    }
  };

  const querySetter = (query, initialQuery) => {
    if (!query.searchText || query.searchText === "") {
      setQuery(initialQuery);
      return initialQuery;
    } else {
      let newQuery = {
        ...initialQuery,
        keyword: query.searchText,
      };
      setQuery(newQuery);
      return newQuery;
    }
  };

  const onPageNumberChange = (event, value) => {
    setPageNumber(value);
  };

  const tabContents = [
    { index: 0, func: getProducts },
    { index: 1, func: handleBestSellers },
    { index: 2, func: handleBestSellersArea },
    { index: 3, func: handleBestSellersUser },
    { index: 4, func: getNewArrivals },
  ];

  return (
    <Root className={classes.root} loading={loading}>
      <Loading open={loading} />
      <AppBar position="static" className={classes.appBar}>
        <AppBarTabs
          value={customer?.filters?.tabValue}
          handleChange={handleChange}
        />
        <div className={classes.appBarButtons}>
          <AppBarButtons view={view} setView={setView} />
        </div>
      </AppBar>
      {results.length > 0 && (
        <FiltersBox
          handleClearSearchText={handleClearSearchText}
          setPageNumber={setPageNumber}
        />
      )}
      {tabContents.map((item, i) => (
        <TabPanel
          value={customer?.filters?.tabValue}
          index={item.index}
          key={i}>
          <TabContent products={products} loading={loading} view={view} />
        </TabPanel>
      ))}
      {!loading && <div
        style={{
          width: "100%",
          height: 100,
          display: "flex",
          justifyContent: "center",
        }}>
        <Pagination
          page={pageNumber || 1}
          count={pageCount}
          onChange={onPageNumberChange}
        />
      </div>}
    </Root>
  );
};

FilterTabs.propTypes = {
  handleClearSearchText: PropTypes.func,
  setChildLoading: PropTypes.func,
};

export default FilterTabs;
