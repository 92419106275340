import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PREFIX = "ShowStockButton";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "type",
})(({ theme, type }) => ({
  [`&.${classes.root}`]: {
    minWidth: 100,
    width: 'fit-content',
    height: 40,
    textTransform: "capitalize",
    backgroundColor: type ? theme.palette.darkGray.light : '#fff',
    border: type ? `1px solid ${theme.palette.colors.gray}`: `1px solid ${theme.palette.darkGray.main}`,
    boxShadow: "none",
    marginLeft: theme.spacing(3),
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    borderRadius: 8,
    "&:hover, &:focus, &:active": {
      backgroundColor: type ? theme.palette.darkGray.light : '#fff',
      boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    
  },
}));

const ShowStockButton = ({ openModal, type, text, disabled }) => {
  return (
    <StyledButton
      variant="contained"
      disableRipple
      className={classes.root}
      onClick={openModal}
      type={type}
      disabled={disabled}>
      {text}
    </StyledButton>
  );
};

ShowStockButton.propTypes = {
  openModal: PropTypes.func,
  type: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ShowStockButton;
