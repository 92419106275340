import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'

import { styled } from '@mui/material/styles'

import SearchAndButtons from './SearchAndButtons'
import UserCard from './UserCard'
import MobileAddUser from '../MobileDialogs/MobileAddUser'
import MobileAddUserCa from '../MobileDialogs/MobileAddUserCa'
import MobileUserFilters from '../MobileDialogs/MobileUserFilters'

import { rolesService, userService } from '../../../../Services'
import { generalConstants } from '../../../../Utils/Constants'
import { convertRoles, userQueryMaker } from '../../../../Utils/Helpers'

import Loading from '../../../../Utils/Loading'

const Root = styled('div')(() => ({
  width: '100%',
  height: "'100%",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}))

const MobileUsers = () => {
  const authInfo = useSelector(({ auth }) => auth.customerInfo)
  const isAdmin = useSelector(({ customer }) => customer.isAdmin)
  const companyId = useSelector(({ customer }) => customer?.currentCompany?.id)

  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([{ name: 'Sonuç bulunamadı', id: 1 }])
  const [addUser, setAddUser] = useState(false)
  const [addUserCA, setAddUserCA] = useState(false)
  const [users, setUsers] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [openFilters, setOpenFilters] = useState(false)
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortField: 'id',
    sortOrder: 'desc',
    filter: {
      full_name: '',
      status: [],
      roles: [],
      company_id: [],
    },
  })

  useEffect(() => {
    let active = true
    if (active) {
      getRoles()
      if (companyId) {
        newGetUsers(query, true)
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, companyId, isAdmin, JSON.stringify(query.filter)])

  const newGetUsers = async (query, initial) => {
    setLoading(true)
    let query_ = initial ? { ...query, pageNumber: 1 } : query
    let finalQ = userQueryMaker(query_)
    let page = true
    const response = await userService.getAllUsers_(
      finalQ,
      companyId,
      isAdmin,
      page
    )
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let users_ = response.data.users
      let data = initial ? users_ : [...users, ...users_]
      setUsers(data)
      if (users_.length < 10) {
        setHasMore(false)
        setLoading(false)
      } else {
        setQuery({
          ...query,
          pageNumber: initial ? 2 : query.pageNumber + 1,
        })
        !hasMore && setHasMore(true)
        setLoading(false)
      }
    } else {
      console.log(response)
      setLoading(false)
    }
  }

  const getRoles = () => {
    rolesService
      .getRoles()
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let data = res.data.roles
          if (data.length > 0) {
            if (authInfo?.role_names[0] === 'companyadmin') {
              let newData = data.filter(
                (item) => item.id === 3 || item.id === 4
              )
              let rolesArr = convertRoles(newData)
              setRoles(rolesArr)
            } else {
              let rolesArr = convertRoles(data)
              setRoles(rolesArr)
            }
          } else {
            setRoles([{ name: 'Sonuç bulunamadı', id: 1 }])
          }
        } else {
          setRoles([{ name: 'Sonuç bulunamadı', id: 1 }])
        }
      })
      .catch((err) => {
        setRoles([{ name: 'Sonuç bulunamadı', id: 1 }])
      })
  }

  const openAddUser = () => {
    if (isAdmin) {
      setAddUser(true)
    } else {
      setAddUserCA(true)
    }
  }

  const closeAddUser = () => {
    if (isAdmin) {
      setAddUser(false)
    } else {
      setAddUserCA(false)
    }
  }

  const handleChange = (e) =>
    setQuery({
      ...query,
      filter: { ...query.filter, full_name: e.target.value },
    })

  const handleKeyword = () => setKeyword(query.filter.full_name)

  const clearKeyword = () => {
    setQuery({ ...query, filter: { ...query.filter, full_name: '' } })
    setKeyword('')
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleKeyword()
    }
  }

  const toggleFilters = () => setOpenFilters((prev) => !prev)

  return (
    <Root>
      <Loading open={loading} />
      <SearchAndButtons
        handleChange={handleChange}
        openAddUser={openAddUser}
        searchText={query.filter.full_name}
        handleKeyword={handleKeyword}
        clearKeyword={clearKeyword}
        keyPress={keyPress}
        toggleFilters={toggleFilters}
      />
      <div
        id='scrollableDiv'
        style={{
          maxHeight: 'calc(100vh - 195px)',
          overflow: 'auto',
          scrollBehavior: 'smooth',
        }}>
        <div>
          <InfiniteScroll
            style={{ paddingLeft: 8, paddingRight: 8 }}
            dataLength={users?.length}
            scrollableTarget='scrollableDiv'
            next={() => newGetUsers(query)}
            hasMore={hasMore}
            loader={<></>}>
            {users.length > 0 &&
              users?.map((user, index) => (
                <UserCard
                  key={index}
                  user={user}
                  roles={roles}
                  newGetUsers={newGetUsers}
                  isAdmin={isAdmin}
                  companyId={companyId}
                  query={query}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>
      <MobileAddUser
        open={addUser}
        onClose={closeAddUser}
        roles={roles}
        newGetUsers={newGetUsers}
        query_={query}
        clearKeyword={clearKeyword}
        keyPress={keyPress}
      />
      <MobileAddUserCa
        open={addUserCA}
        onClose={closeAddUser}
        newGetUsers={newGetUsers}
        query_={query}
        clearKeyword={clearKeyword}
        keyPress={keyPress}
      />
      <MobileUserFilters
        open={openFilters}
        handleClose={toggleFilters}
        setQuery={setQuery}
        query={query}
      />
    </Root>
  )
}

export default MobileUsers
