import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

const ResultsContainer = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 1,
      }}>
      {children}
    </Box>
  );
};

ResultsContainer.propTypes = {
  children: PropTypes.any,
};

export default ResultsContainer;
