import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { getFirstLetters } from "../../../../../Utils/Helpers";

import dotsIcon from "../../../../../Images/UsersAndRoles/Dots.svg";

import RoleUserPopover from "../../../../../Ui/Popovers/RoleUserPopover";

import { roles } from "../../../../../Utils/Constants";

const sectionTextClass = {
  width: "100%",
  fontSize: 14,
  fontWeight: 400,
  color: "#434141",
  textAlign: "left",
};

const SubHeader = styled("div")(() => ({
  flex: 1,
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledSection = styled("div")(() => ({
  "&[initial-header='true']": {
    width: 30,
  },
  "&[field='initial-text']": {
    width: 122,
  },
  "&[field='register-date']": {
    width: 147,
  },
  "&[field='gsm-field']": {
    width: 120,
  },
  "&[field='email-field']": {
    width: 200,
  },
  "&[field='auth-field']": {
    width: 100,
  },
  "&[field='firm-name']": {
    width: 120,
  },
  "&[field='address-field']": {
    width: 120,
  },
  "&[field='status-field']": {
    width: 140,
  },
  "&[field='three-dots']": {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Letters = styled("div")(({ theme }) => ({
  width: 20,
  height: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
}));

const LettersText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 700,
  color: "#fff",
}));

const StyledIconButton = styled(IconButton)(() => ({
  height: 30,
  width: 30,
}));

const UserRow = ({ user, isLast }) => {
  const [letters, setLetters] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [role, setRole] = useState(roles[0].id);

  useEffect(() => {
    let active = true;
    if (active) {
      if (user) {
        let letters = getFirstLetters(user.first_name, user.last_name);
        setLetters(letters);
      }
    }
    return () => {
      active = false;
    };
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRole = (e) => {
    handleClose();
    setRole(e.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const rowValues = [
    {
      field: "initial-text",
      value: user?.full_name,
    },
    {
      field: "register-date",
      value: user?.create_date,
    },
    {
      field: "gsm-field",
      value: user?.gsm,
    },
    {
      field: "email-field",
      value: user?.email,
    },
    {
      field: "auth-field",
      value: user?.role,
    },
    {
      field: "firm-name",
      value: user?.firm,
    },
    {
      field: "address-field",
      value: user?.branch,
    },
    {
      field: "status-field",
      value: user?.state,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: 51,
        borderBottom: theme => isLast ? "none" : `1px solid ${theme.palette.darkGray.mid}`,
        display: "flex",
        alignItems: "center",
        paddingLeft: 1.2,
        "&:hover": {
          "& .sectionText": {
            color: (theme) => theme.palette.primary.main,
            fontWeight: 500,
          },
        },
      }}>
      <StyledSection initial-header="true">
        <Letters>
          <LettersText align="center">{letters && letters}</LettersText>
        </Letters>
      </StyledSection>
      <SubHeader>
        {rowValues.map((item, index) => (
          <StyledSection field={item.field}>
            <Typography
              className="sectionText"
              sx={{ ...sectionTextClass }}
              noWrap>
              {item.value}
            </Typography>
          </StyledSection>
        ))}
        <StyledSection field="three-dots">
          <StyledIconButton onClick={handleClick} size="large">
            <img src={dotsIcon} width="24px" height="24px" alt="" />
          </StyledIconButton>
        </StyledSection>
      </SubHeader>
      <RoleUserPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleRole={handleRole}
        role={role}
        roles={roles}
      />
    </Box>
  );
};

UserRow.propTypes = {
  user: PropTypes.object,
  isLast: PropTypes.bool,
};

export default UserRow;
