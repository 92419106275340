import { filterConstants, generalConstants } from "../../Utils/Constants";
import { filterService } from "../../Services";

const getCategories = (query) => {
  return (dispatch) => {
    filterService.categorySubList(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: filterConstants.GET_CATEGORIES,
            payload: res.data.sub_categories,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const getBrands = (query) => {
  return (dispatch) => {
    filterService.brandsList(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: filterConstants.GET_BRANDS,
            payload: res.data.brands,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const getOtherFilterValues = (query) => {
  return (dispatch) => {
    filterService.productValueList(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: filterConstants.GET_PRODUCT_VALUES,
            payload: res?.data?.product_attribute_values,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const clearFilterReducer = () => {
  return { type: filterConstants.CLEAR_FILTER_REDUCER };
};

export const filterActions = {
  getCategories,
  getBrands,
  getOtherFilterValues,
  clearFilterReducer,
};
