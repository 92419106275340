import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { isMobileOnly } from "react-device-detect";

import {
  filterActions,
  productActions,
  addressActions,
  authActions,
  customerActions,
  favoriteActions,
  notificationActions,
} from "./Redux/Actions";

import { Router, Route, Redirect, Switch } from "react-router-dom";
import { history } from "./Utils/Helpers/History";
import ScrollRestoration from "react-scroll-restoration";

import { PrivateRoute } from "./Utils/PrivateRoute";
import Home from "./Views/MainPage/Home";
import HomeMob from "./Views/MainPage/HomeMob";
import SigninPhone from "./Views/AuthPages/SigninPhone";
import PhoneVerification from "./Views/AuthPages/PhoneVerication";
import PhoneVerificationMob from "./Views/AuthPages/PhoneVerificationMob";

import SigninEmail from "./Views/AuthPages/SigninEmail";
import ForgotPassword from "./Views/AuthPages/ForgotPassword";
import ForgotPasswordMob from "./Views/AuthPages/ForgotPasswordMob";
import PasswordSent from "./Views/AuthPages/PasswordSent";
import UpdatePassword from "./Views/AuthPages/UpdatePassword";
import PasswordUpdated from "./Views/AuthPages/PasswordUpdated";
import DemoPage from "./Ui/Footer/DemoPage";
import FiltersPage from "./Views/FiltersPage/FiltersPage";
import DealerProfile from "./Views/DealerProfile/DealerProfile";
import ProductPage from "./Views/ProductPage/ProductPage";
import ProductPageMob from "./Views/ProductPage/ProductPageMob";
import FastOrder from "./Views/FastOrder/FastOrder";
import CartPage from "./Views/CartPage/CartPage";
import OrderPage from "./Views/OrderPage/OrderPage";
import UsersAndRoles from "./Views/UsersAndRoles/UsersAndRoles";
import ServicesAndReturns from "./Views/ServicesAndReturns/ServicesAndReturns";
import AboutUs from "./Views/AboutUs/AboutUs";
import AboutUsMob from "./Views/AboutUs/AboutUsMob";
import Addresses from "./Views/Addresses/Addresses";
import AddressesWeb from "./Views/Addresses/AddressesWeb";
import MobileSearch from "./Views/MobileSearch/MobileSearch";
import CartPageMob from "./Views/CartPage/CartPageMob";
import MobileOrderPage from "./Views/OrderPage/MobileOrderPage";
import MenuPage from "./Views/MenuPage/MenuPage";
import MobileFiltersPage from "./Views/FiltersPage/MobileFiltersPage";
import SigningOut from "./Views/AuthPages/SigningOut";
import UsersAndRolesMob from "./Views/UsersAndRoles/UsersAndRolesMob";
import TurkeyMap from "./Views/TurkeyMap/TurkeyMap";
import CategoriesMob from "./Views/Categories/CategoriesMob";
import Checkout from "./Views/Checkout/Checkout";
import CheckoutMob from "./Views/Checkout/CheckoutMob";
import ErrorBoundary from "./Views/Error/ErrorBoundary";
import ServerError from "./Views/Error/ServerError";
import NotFound from "./Views/Error/NotFound";
import Notifications from "./Views/Notifications/Notifications";
import Campaigns from "./Views/Campaigns/Campaigns";
import CampaignsMob from "./Views/Campaigns/CampaignsMob";

import {
  customerConstants,
  attributesQuery,
  addressQuery,
  brandsAndCategoriesQuery,
  generalConstants,
  generalReqQuery,
  notificationsQuery,
} from "./Utils/Constants";

import { filterService } from "./Services";
import { handleAttrList } from "./Utils/Helpers";

const Routes = () => {
  const dispatch = useDispatch();

  const customer = localStorage.getItem(customerConstants.CUSTOMER);

  // console.log(JSON.parse(customer));

  const authInfo = useSelector(({ auth }) => auth);
  const addresses = useSelector(({ addresses }) => addresses.addresses);
  const isAdmin = useSelector(({ customer }) => customer.isAdmin);

  const getCategories = (query) => dispatch(filterActions.getCategories(query));
  const getBrands = (query) => dispatch(filterActions.getBrands(query));
  const getCart = () => dispatch(productActions.getCart());
  const getOtherFilterValues = (query) =>
    dispatch(filterActions.getOtherFilterValues(query));
  const getAddresses = (query) => dispatch(addressActions.getAddresses(query));
  const signOut = () => dispatch(authActions.signout());
  const getFeatureValues = () => dispatch(productActions.getFeatureValues());
  const setAdmin = (bool) => dispatch(customerActions.setAdmin(bool));
  const setUserCurrent = (query) =>
    dispatch(customerActions.setUserCurrent(query));
  const setSelectedUser = (user) =>
    dispatch(customerActions.selectedUser(user));
  const getFavorites = (query, id) =>
    dispatch(favoriteActions.getFavorites(query, id));
  const setSignin = (data) => dispatch(authActions.setSignin(data));
  const getNotificationsData = (query) =>
    dispatch(notificationActions.getNotifications(query));

  // const [openSignoutDialog, setOpenSignoutDialog] = useState(false);
  const [attributes, setAttributes] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      let customer_ = JSON.parse(customer);
      if (customer_?.status === true && !authInfo.signedIn) {
        setSignin(customer_?.user);
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.parse(customer), authInfo]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (authInfo?.signedIn) {
        getAddresses(addressQuery);
        getCategories(brandsAndCategoriesQuery);
        getBrands(brandsAndCategoriesQuery);
        getProductAttributeList(attributesQuery);
        getFeatureValues();
        getNotificationsData(notificationsQuery);
        let role = authInfo.customerInfo.role_names;
        let isAdmin_ = role.includes("admin") || role.includes("seller");
        if (isAdmin_) {
          !isAdmin && setAdmin(true);
        } else {
          setAdmin(false);
          getCart();
          getFavorites(addressQuery, authInfo?.customerInfo?.id);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo?.signedIn]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (attributes) {
        let query = {
          ...generalReqQuery,
          filter: {
            ...generalReqQuery.filter,
            attribute_id: attributes,
          },
        };
        getOtherFilterValues(query);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (addresses.length > 0) {
        if (!isAdmin) {
          let query = {
            address: authInfo.customerInfo.address,
            company: authInfo.customerInfo.company,
          };
          setUserCurrent(query);
          setSelectedUser(authInfo?.customerInfo);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, isAdmin]);

  const getProductAttributeList = (attributesQuery) => {
    filterService
      .productAttributeList(attributesQuery)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let data = res.data.product_attributes;
          let filterArr = handleAttrList(data);
          setAttributes(filterArr);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOnIdle = (event) => {
    // console.log('user is idle', event)
    console.log("last active", getLastActiveTime(), getRemainingTime());
    signOut();
    // handleDialogOpen();
  };

  const handleOnActive = (event) => {
    // console.log('user is active', event)
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event, getLastActiveTime(), getRemainingTime())
  };

  // const handleDialogOpen = () => setOpenSignoutDialog(true);
  // const handleDialogClose = () => setOpenSignoutDialog(false);

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    // crossTab: {
    //   emitOnAllTabs: true,
    // },
  });

  return (
    <ErrorBoundary history={history}>
      <Router history={history}>
        <ScrollRestoration />
        <Switch>
          <Redirect exact from="/" to="/home" />
          <PrivateRoute
            exact
            path="/home"
            component={isMobileOnly ? HomeMob : Home}
          />
          <Route exact path="/signin-phone" component={SigninPhone} />
          <Route
            exact
            path="/phone-verification"
            component={isMobileOnly ? PhoneVerificationMob : PhoneVerification}
          />
          <Route exact path="/signin-email" component={SigninEmail} />
          <PrivateRoute exact path="/map" component={TurkeyMap} />

          <Route
            exact
            path="/forgot-password"
            component={isMobileOnly ? ForgotPasswordMob : ForgotPassword}
          />
          <Route exact path="/password-sent" component={PasswordSent} />
          <Route exact path="/activation/:token" component={UpdatePassword} />
          <Route
            exact
            path="/reset-password/:token"
            component={UpdatePassword}
          />
          <Route exact path="/password-updated" component={PasswordUpdated} />
          <Route exact path="/signing-out" component={SigningOut} />
          <PrivateRoute exact path="/demo-page" component={DemoPage} />
          <PrivateRoute
            exact
            path="/filters-page"
            component={isMobileOnly ? MobileFiltersPage : FiltersPage}
          />
          <PrivateRoute
            exact
            path="/dealer-profile"
            component={DealerProfile}
          />
          <PrivateRoute exact path="/fast-order" component={FastOrder} />
          <PrivateRoute
            exact
            path="/cart-page"
            component={isMobileOnly ? CartPageMob : CartPage}
          />
          <PrivateRoute
            exact
            path="/order-page"
            component={isMobileOnly ? MobileOrderPage : OrderPage}
          />
          <PrivateRoute
            exact
            path="/users-and-roles"
            component={isMobileOnly ? UsersAndRolesMob : UsersAndRoles}
          />
          <PrivateRoute
            exact
            path="/services-and-returns"
            component={ServicesAndReturns}
          />
          <PrivateRoute exact path="/categories" component={CategoriesMob} />

          <PrivateRoute
            exact
            path="/product/:id"
            component={isMobileOnly ? ProductPageMob : ProductPage}
          />
          <PrivateRoute
            exact
            path="/campaign/:id"
            component={isMobileOnly ? CampaignsMob : Campaigns}
          />
          <PrivateRoute
            exact
            path="/about-us"
            component={isMobileOnly ? AboutUsMob : AboutUs}
          />
          <PrivateRoute
            exact
            path="/checkout"
            component={isMobileOnly ? CheckoutMob : Checkout}
          />
          <PrivateRoute
            exact
            path="/addresses"
            component={isMobileOnly ? Addresses : AddressesWeb}
          />
          <PrivateRoute exact path="/mobile-search" component={MobileSearch} />
          <PrivateRoute exact path="/notifications" component={Notifications} />
          <PrivateRoute exact path="/menu" component={MenuPage} />
          <Route exact path="/500" component={ServerError} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default Routes;
