import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";

import MobileProductCard from "../../../Ui/ProductCard/MobileProductCard";
import NoResult from "../Results/NoResult";

import Grid from "@mui/material/Grid";

const MobileTabContent = ({
  products,
  query,
  getProducts,
  hasMore,
  loading,
}) => {
  return (
    <>
      {products?.length > 0 ? (
        <InfiniteScroll
          style={{
            // padding: "0px 5px 10px",
            overflowX: "hidden",
          }}
          dataLength={products.length}
          next={() => getProducts(query)}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          loader={<></>}>
          <Grid
            container
            spacing={1}
            style={{ paddingTop: 10, paddingBottom: 40 }}>
            {products?.map((item, index) => (
              <Grid
                item
                key={index}
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}>
                <MobileProductCard item={item} noMargin />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        !loading && <NoResult />
      )}
    </>
  );
};

MobileTabContent.propTypes = {
  products: PropTypes.array,
  query: PropTypes.object,
  getProducts: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
};

export default MobileTabContent;
