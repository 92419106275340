import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

const PREFIX = "Links";

const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
};

const StyledLink = styled(Link)(() => ({
  [`& .${classes.root}`]: {
    width: "100%",
    height: 20,
    fontSize: 14,
    fontWeight: 700,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
    cursor: "pointer",
  },

  [`&.${classes.link}`]: {
    textDecoration: "none",
  },
}));

const Links = ({ text, path }) => {
  return (
    <StyledLink to={`/${path}`} className={classes.link}>
      <Typography className={classes.root}>{text}</Typography>
    </StyledLink>
  );
};

Links.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
};

export default Links;
