import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";

const StyledTextField = styled(TextField)(() => ({
  width: "88%",
  height: 25,
  margin: "-6px 0px 13px 14px",
  "& .MuiInputBase-input": {
    borderRadius: 8,
    padding: "3.5px 8px",
    border: "1px solid rgba(177, 174, 174, 1)",
    fontSize: 14,
    fontWeight: 300,
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "#fff",
      border: "1px solid rgba(177, 174, 174, 1)",
    },
  },
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 30,
  cursor: "pointer",
  zIndex: 2,
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 5,
  color: "rgba(189, 189, 189, 1)",
  cursor: "pointer",
}));

const SearchContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 60,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  display: "flex",
  flexDirection: "column",
}));

const GroupTitle = styled(Typography)(({theme}) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.darkGray.main,
  margin: "0px 0px 12px 14px",
}));

const WordSearch = ({
  keyword,
  handleKeyword,
  keyPress,
  clearSearchText,
  handleSearchText,
  disabled,
}) => {
  return (
    <SearchContainer>
      <GroupTitle variant="subtitle1">Sözcük ile Arama</GroupTitle>
      <StyledTextField
        fullWidth
        variant="standard"
        value={keyword}
        onChange={handleKeyword}
        onKeyDown={keyPress}
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {keyword.length > 0 && (
                <CloseAdornment position="end" onClick={clearSearchText}>
                  <Fade in={keyword.length > 0}>
                    <CloseIcon style={{ fill: "#8E8E8E" }} />
                  </Fade>
                </CloseAdornment>
              )}
              <StyledAdornment
                position="end"
                onClick={() => handleSearchText(keyword)}>
                <SearchIcon />
              </StyledAdornment>
            </>
          ),
        }}
      />
    </SearchContainer>
  );
};

WordSearch.propTypes = {
  keyword: PropTypes.string,
  handleKeyword: PropTypes.func,
  keyPress: PropTypes.func,
  clearSearchText: PropTypes.func,
  handleSearchText: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WordSearch;
