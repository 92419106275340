import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";

// import { customerActions } from "../../Redux/Actions";

import { searchService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";

import ResultsContainer from "./ResultsContainer/ResultsContainer";
import Header from "./ResultsContainer/Header";
import History from "./ResultsContainer/History";
import Results from "./ResultsContainer/Results";
import NoResult from "./ResultsContainer/NoResult";
import Trends from "./ResultsContainer/Trends";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  // width: "100%",
  flex: 1,
  height: 26,
  "& .MuiInputBase-root": {
    height: 26,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 4,
    height: 17,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 4,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
    "&::placeholder": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      opacity: 1,
      paddingTop: "2px !important",
    },
  },
}));

const StyledText = styled(Typography)(({ theme }) => ({
  width: "100%",
  paddingLeft: theme.spacing(),
  height: "auto",
  fontSize: 12,
  fontWeight: 400,
  color: "#B1AEAE",
  userSelect: "none",
}));

const CloseAdornment = styled(InputAdornment)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(),
  cursor: "pointer",
  zIndex: 9999,
}));

const HeaderPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 45,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(),
  borderBottom: "1px solid #B1AEAE",
  paddingBottom: theme.spacing(2),
}));

const SearchArea = () => {
  const history = useHistory();
  // const dispatch = useDispatch();

  // const setText = (text) => dispatch(customerActions.setSearchText(text));

  const [searching, setSearching] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);
  const [historyResults, setHistoryResults] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      getSearchHistory();
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;
    if (active) {
      if (keyword.length === 3) {
        getSearchHistory();
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const handleHistoryClear = () => {
    if (historyResults.length === 0) return;
    setDisabled(true);
    searchService.clearHistory().then((res) => {
      if (res.data.status === generalConstants.STATUS_TRUE) {
        getSearchHistory();
      }
    });
  };

  const getSearchHistory = () => {
    searchService.getHistory().then((res) => {
      if (res.data.status === generalConstants.STATUS_TRUE) {
        setHistoryResults(res.data.search_history);
        setDisabled(false);
      }
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setText(keyword);
  //   history.push("/filters-page");
  // };

  const handleResultClick = (id) => history.push(`/product/${id}`);

  // const handleSearchClick = (e) => {
  //   if (keyword.length !== 0) {
  //     handleSubmit(e);
  //   }
  // };

  const handleChange = (e) => {
    let keyword = e.target.value;
    setKeyword(keyword);
    setSearching(true);
    if (keyword.length >= 3) {
      searchService.getSuggestions(keyword).then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setResults(res.data.keywords);
          setSearching(false);
        }
      });
    } else {
      setResults([]);
    }
  };

  const onCloseClick = () => {
    getSearchHistory();
    setKeyword("");
    setResults([]);
  };

  const showResults = () => {
    if (results.length === 0 && !searching) {
      return <NoResult />;
    } else {
      return <Results data={results} handleResultClick={handleResultClick} />;
    }
  };

  const onBackClick = () => history.goBack();
  const goToFiltersPage = () => history.push("./filters-page");

  return (
    <>
      <HeaderPart>
        <ArrowBackIcon
          onClick={onBackClick}
          style={{ fill: "#434141", height: 24, width: 24, marginRight: 16 }}
        />
        <StyledTextField
          fullWidth
          value={keyword}
          onChange={handleChange}
          placeholder="Ara"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {keyword.length > 0 && (
                  <CloseAdornment position="end" onMouseDown={onCloseClick}>
                    <Fade in={keyword.length > 0}>
                      <CloseIcon style={{ fill: "#8E8E8E" }} />
                    </Fade>
                  </CloseAdornment>
                )}
              </>
            ),
          }}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
        <FilterAltRoundedIcon
          onClick={goToFiltersPage}
          style={{ fill: "#434141", height: 24, width: 24, marginLeft: 16 }}
        />
      </HeaderPart>
      <ResultsContainer>
        {keyword.length < 3 && (
          <div
            style={{
              minHeight: "200px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
            <StyledText>
              Aramaya başlamak için en az 3 karakter yazmalısınız
            </StyledText>
            <Header
              handleHistoryClear={handleHistoryClear}
              disabled={disabled}
            />
            <History
              data={historyResults}
              setKeyword={setKeyword}
              keyword={keyword}
            />
          </div>
        )}
        {keyword.length >= 3 && showResults()}
        {(keyword.length < 3 ||
          (keyword.length >= 3 && results.length === 0)) && <Trends />}
      </ResultsContainer>
    </>
  );
};

export default SearchArea;

// const StyledTextField = styled(TextField)(({ theme, error }) => ({
//   width: "100%",
//   height: 25,
//   "& .MuiInputBase-root": {
//     height: 25,
//   },
//   "& .MuiInputBase-input": {
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     borderRadius: 8,
//     height: 16,
//     padding: "3.5px 8px",
//     border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
//     fontWeight: 300,
//     fontSize: 12,
//     color: "#434141",
//     backgroundColor: "#fff",
//     "&:not(:focus)": {
//       cursor: "pointer",
//     },
//     "&:hover": {
//       backgroundColor: "#fff",
//       border: error ? "1px solid red" : "1px solid #6E3564",
//       borderRadius: 8,
//     },
//     "&:focus, &:active": {
//       backgroundColor: "#fff",
//       borderRadius: 8,
//       boxShadow: error
//         ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
//         : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
//       borderColor: "#fff",
//     },
//   },
// }));
