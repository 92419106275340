import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { a11yProps } from "../../../Utils/Helpers";

const PREFIX = "AppBarTabs";

const classes = {
  tab: `${PREFIX}-tab`,
  indicator: `${PREFIX}-indicator`,
  tabs: `${PREFIX}-tabs`,
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .${classes.tab}`]: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "rgba(106, 104, 104, 1)",
    width: "fit-content",
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    "&.MuiTab-root": {
      minWidth: 80,
    },
  },

  [`& .${classes.indicator}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`&.${classes.tabs}`]: {
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
    "& .Mui-selected": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    "& .css-jpln7h-MuiTabs-scroller": {
      overflowX: "scroll !important",
      // WebkitOverflowScrolling: "touch",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root": {
      width: 30,
    },
  },
}));

const MobileTabs = ({ value, handleChange }) => {
  return (
    <StyledTabs
      value={value}
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={handleChange}
      aria-label="filter-tabs"
      style={{ overflow: "auto", overflowX: "scroll !important" }}
      classes={{
        indicator: classes.indicator,
      }}
      className={classes.tabs}>
      <Tab className={classes.tab} label="Tümü" {...a11yProps(0)} />
      <Tab className={classes.tab} label="Çok Satanlar" {...a11yProps(1)} />
      <Tab
        className={classes.tab}
        label="Bölgende Çok Satanlar"
        {...a11yProps(2)}
      />
      <Tab
        className={classes.tab}
        label="Senin Çok Sattıkların"
        {...a11yProps(3)}
      />
      <Tab className={classes.tab} label="Yeni Gelenler" {...a11yProps(4)} />
    </StyledTabs>
  );
};

MobileTabs.propTypes = {
  value: PropTypes.number,
  handleChange: PropTypes.func,
};

export default MobileTabs;
