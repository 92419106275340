import React from "react";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const CardSkeleton = () => {
  return (
    <Box
      sx={{
        width: 220,
        height: 400,
        pt: (theme) => theme.spacing(),
        pr: (theme) => theme.spacing(3),
        pl: (theme) => theme.spacing(3),
        pb: (theme) => theme.spacing(2),
        position: "relative",
        // border: "1px solid #B1AEAE",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",

        transition: (theme) => theme.transitions.create(["box-shadow"]),
        "&:hover": {
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.25)",
          outline: "none",
          border: "none",
        },
      }}>
      <Stack spacing={1}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" width={172} height={268.75} />
        <Grid
          container
          spacing={2}
          style={{ paddingLeft: 0, width: 172 }}>
          <Grid item xs={4} style={{ paddingLeft: 0 }}>
            <Skeleton variant="h4" />
          </Grid>
          <Grid item xs={2} style={{ paddingLeft: 10 }}>
            <Skeleton variant="h4" />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <Skeleton variant="h4" />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default CardSkeleton;
