import { favoriteConstants } from "../../Utils/Constants";

const initialState = {
  favorites: [],
  loading: false,
};

const favorites = (state = initialState, action) => {
  switch (action.type) {
    case favoriteConstants.GET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };
    case favoriteConstants.TOGGLE_FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case favoriteConstants.TOGGLE_FAVORITE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case favoriteConstants.TOGGLE_FAVORITE_SUCCESS:
      let isIncluded = state.favorites.some(
        (fav) => fav.id === action.product.id
      );
      let newFavorites = state.favorites;
      if (isIncluded) {
        newFavorites = state.favorites.filter(
          (item) => item.id !== action.product.id
        );
      } else {
        newFavorites = [...newFavorites, action.product];
      }
      return {
        ...state,
        loading: false,
        favorites: newFavorites,
      };
    case favoriteConstants.CLEAR_FAVORITES:
      return {
        ...state,
        favorites: [],
      };
    default:
      return state;
  }
};

export { favorites };
