import React from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import UserDarkGray from "../../../Images/Home/UserDarkGray.svg";
import HomeWhite from "../../../Images/Home/HomeWhite.svg";
import MapPinWhite from "../../../Images/Home/MapPinWhite.svg";
import TrendingUp from "../../../Images/Home/TrendingUp.svg";

const innerWidth = window.innerWidth;

const StyledBox = styled(Box)(({ theme }) => ({
  width: innerWidth - 16,
  minHeight: 120,
  margin: theme.spacing(),
  backgroundColor: "rgba(110, 53, 100, 0.24)",
  boxShadow: "0px 0px 4px #712853",
  padding: theme.spacing(2),
  borderRadius: 6,
  display: "flex",
  alignItems: "center",
}));

const StyledImg = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: 70,
  height: 70,
  borderRadius: "50%",
}));

const InfoBox = styled(Box)(({ theme }) => ({
  flex: 1,
  minHeight: 88,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

const SpaceBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "address",
})(({ theme, address }) => ({
  width: address ? "fit-content" : "100%",
  height: "auto",
  display: "flex",
  justifyContent: address ? "flex-start" : "space-between",
}));

const Wellcome = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(),
  marginBottom: theme.spacing(),
  color: theme.palette.darkGray.main,
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: 400,
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: text ? 18 : 24,
  lineHeight: text ? "18px" : "24px",
  color: text ? "#fff" : theme.palette.primary.main,
  fontWeight: 400,
}));

const TopBoxMob = () => {
  const userInfo = useSelector(({ auth }) => auth.customerInfo);

  return (
    <>
      <Wellcome>Hoşgeldin,</Wellcome>
      <StyledBox>
        <StyledImg src={userInfo?.image} alt="" />
        <InfoBox>
          <SpaceBox>
            <Text>{userInfo?.full_name}</Text>
            <img src={UserDarkGray} width="24" height="24" alt="" />
          </SpaceBox>
          <SpaceBox address>
            <img
              src={HomeWhite}
              width="20"
              height="20"
              alt=""
              style={{ marginRight: 8 }}
            />
            <Text text>{userInfo?.company?.name}</Text>
          </SpaceBox>
          <SpaceBox>
            <SpaceBox address>
              <img
                src={MapPinWhite}
                width="20"
                height="20"
                alt=""
                style={{ marginRight: 8 }}
              />
              <Text text>{userInfo?.address?.name}</Text>
            </SpaceBox>
            <img src={TrendingUp} width="24" height="24" alt="" />
          </SpaceBox>
        </InfoBox>
      </StyledBox>
    </>
  );
};

export default React.memo(TopBoxMob);
