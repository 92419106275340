import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { dateFormatter } from '../../../../Utils/Helpers'

const Row = styled('div')(({ theme }) => ({
  width: '100%',
  height: 60,
  padding: theme.spacing(),
  paddingLeft: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  alignItems: 'center',
}))

const NameAndValues = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}))

const Name = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '20px',
  color: theme.palette.darkGray.main,
  marginBottom: theme.spacing(0.5),
}))

const ItemContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'scroll',
  width: '100%',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

const Item = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '16px',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(),
}))

const DateRow = ({ name, date, openDialog }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    let active = true
    if (active) {
      let initialStart = new Date()
      let initialEnd = new Date()
      if (
        dateFormatter(initialStart) !== dateFormatter(date.startDate) ||
        dateFormatter(initialEnd) !== dateFormatter(date.endDate)
      ) {
        setData(
          `(${dateFormatter(date.startDate, true)}) - (${dateFormatter(
            date.endDate,
            true
          )})`
        )
      } else {
        data && setData(null)
      }
    }

    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  return (
    <Row>
      <NameAndValues>
        <Name>{name}</Name>
        <ItemContainer>{data && <Item>{`${data}`}</Item>}</ItemContainer>
      </NameAndValues>
      <ArrowForwardIosIcon
        onClick={openDialog}
        style={{ fill: '#434141', marginLeft: 8 }}
      />
    </Row>
  )
}

DateRow.propTypes = {
  name: PropTypes.string,
  items: PropTypes.any,
  value: PropTypes.string,
  openDialog: PropTypes.func,
}

export default DateRow
