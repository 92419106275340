import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(),
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  lineHeight: "28px",
  color: theme.palette.darkGray.main,
  marginBottom: theme.spacing(),
}));

const ItemContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "scroll",
  width: "100%",
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const Value = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  textTransform: "none",
  width: "fit-content",
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: selected ? "1px solid #6E3564" : `1px solid ${theme.palette.darkGray.light}`,
  borderRadius: 3,
  color: selected ? "#fff" : theme.palette.darkGray.main,
  backgroundColor: selected ? theme.palette.primary.main : "#fff",
  fontWeight: 400,
  fontSize: 12,
  marginRight: theme.spacing(),
  marginBottom: theme.spacing(),
  cursor: "pointer",
  userSelect: "none",
  padding: 4,
  //   touchAction: "none",
  WebkitTapHighlightColor: "transparent",
  "&.MuiGrid-item": {
    paddingTop: 0,
    paddingLeft: 0,
  },
  //   "&:hover": {
  //     border: "1px solid #6E3564",
  //   },
}));

const VariantValuesMob = ({
  data,
  param,
  handleChange,
  header,
  selectedValue,
}) => {
  return (
    <Root>
      <Header>{header}</Header>
      <ItemContainer>
        {data?.length > 0 &&
          data?.map((elem, index) => (
            <Value
              variant="contained"
              key={index}
              selected={selectedValue === elem}
              value={elem}
              onClick={handleChange(param)}>
              {elem}
            </Value>
          ))}
      </ItemContainer>
    </Root>
  );
};

VariantValuesMob.propTypes = {
  data: PropTypes.any,
  param: PropTypes.string,
  handleChange: PropTypes.func,
  header: PropTypes.string,
  selectedValue: PropTypes.string,
};

export default VariantValuesMob;
