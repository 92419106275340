import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import { Typography } from "@mui/material";

import arrowIcon from "../../../Images/DraggableCard/Arrow.svg";

const PREFIX = "PopoverRow";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const Root = styled("div", { shouldForwardProp: (prop) => prop !== "mobile" })(
  ({ theme, mobile }) => ({
    [`&.${classes.root}`]: {
      width: "100%",
      height: mobile ? 46 : 36,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(),
      paddingRight: theme.spacing(2),
      borderBottom: `0.5px solid ${theme.palette.darkGray.light}`,
      cursor: "pointer",
      "&[is-last='true']": {
        borderBottom: "none",
      },
      "&[is-first='true']": {
        borderTop: `0.5px solid ${theme.palette.darkGray.light}`,
      },
      "&:hover": {
        backgroundColor: theme.palette.darkGray.light,
      },
    },

    [`& .${classes.text}`]: {
      width: 231,
      marginLeft: 10,
      fontSize: 14,
      fontWeight: 400,
      color:theme.palette.darkGray.main,
      marginRight: 14,
    },
  })
);

const PopoverRow = ({ icon, text, func, isLast, mobile, isFirst }) => {
  return (
    <Root
      className={classes.root}
      is-last={isLast}
      onClick={func}
      mobile={mobile}
      is-first={isFirst}>
      <div style={{ display: "flex" }}>
        <img src={icon} alt="" width="20px" height="20px" />
        <Typography className={classes.text}>{text}</Typography>
      </div>
      <img src={arrowIcon} width="10px" height="15px" alt="" />
    </Root>
  );
};

PopoverRow.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  func: PropTypes.func,
  isLast: PropTypes.string,
  mobile: PropTypes.bool,
  isFirst: PropTypes.string,
};

export default PopoverRow;
