import {
  orderConstants,
  generalConstants,
  productConstants,
} from "../../Utils/Constants";
import { orderService, productService } from "../../Services";

import { toast } from "react-toastify";

import { addressActions } from "./AddressActions";

const getAllOrders = (query) => {
  return (dispatch) => {
    dispatch(request());
    orderService.getAllOrders(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch(success(res?.data?.orders, res.data.total_count));
        }
      },
      (error) => {
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: orderConstants.GET_ORDERS_REQUEST };
  }
  function success(data, totalCount) {
    return { type: orderConstants.GET_ORDERS, payload: { data, totalCount } };
  }
  function failure() {
    return { type: orderConstants.GET_ORDERS_FAILURE };
  }
};

const getAdminOrders = (query) => {
  return (dispatch) => {
    dispatch(request());
    orderService.getAdminOrders(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch(success(res?.data?.orders, res.data.total_count));
        }
      },
      (error) => {
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: orderConstants.GET_ORDERS_REQUEST };
  }
  function success(data, totalCount) {
    return { type: orderConstants.GET_ORDERS, payload: { data, totalCount } };
  }
  function failure() {
    return { type: orderConstants.GET_ORDERS_FAILURE };
  }
};

const initializeOrder = (query) => {
  return (dispatch) => {
    dispatch(request());
    orderService
      .initializeOrder(query)
      .then(
        (res) => {
          if (res?.data?.status === generalConstants.STATUS_TRUE) {
            dispatch(addressActions.setNewAddressId());
            productService
              .clearCart()
              .then(
                (res) => {
                  if (res?.data?.status === generalConstants.STATUS_TRUE) {
                    dispatch({
                      type: productConstants.CLEAR_CART,
                    });
                    dispatch(success());
                  } else {
                    dispatch(failure());
                    toast.error(res?.data?.message);
                  }
                },
                (error) => {
                  dispatch(failure());
                  toast.error(error?.response?.data?.message);
                }
              )
              .catch((error) => {
                dispatch(failure());
                toast.error(error?.response?.data?.message);
              });
          } else {
            dispatch(failure());
            toast.error(res?.data?.message);
          }
        },
        (error) => {
          dispatch(failure());
          toast.error(error?.response?.data?.message);
        }
      )
      .catch((error) => {
        dispatch(failure());
        toast.error(error?.response?.data?.message);
      });
  };

  function request() {
    return { type: orderConstants.INITIALIZE_REQUEST };
  }
  function success() {
    return { type: orderConstants.INITIALIZE_SUCCESS };
  }
  function failure() {
    return { type: orderConstants.INITIALIZE_FAILURE };
  }
};

const initializeAdminOrder = (query, userId) => {
  return (dispatch) => {
    dispatch(request());
    orderService
      .initializeAdminOrder(query)
      .then(
        (res) => {
          if (res?.data?.status === generalConstants.STATUS_TRUE) {
            dispatch(addressActions.setNewAddressId());
            productService
              .clearAdminCart(userId)
              .then(
                (res) => {
                  if (res?.data?.status === generalConstants.STATUS_TRUE) {
                    dispatch({
                      type: productConstants.CLEAR_CART,
                    });
                    dispatch(success());
                  } else {
                    dispatch(failure());
                    toast.error(res?.data?.message);
                  }
                },
                (error) => {
                  dispatch(failure());
                  toast.error(error?.response?.data?.message);
                }
              )
              .catch((error) => {
                dispatch(failure());
                toast.error(error?.response?.data?.message);
              });
          } else {
            dispatch(failure());
            toast.error(res?.data?.message);
          }
        },
        (error) => {
          dispatch(failure());
          toast.error(error?.response?.data?.message);
        }
      )
      .catch((error) => {
        dispatch(failure());
        toast.error(error?.response?.data?.message);
      });
  };

  function request() {
    return { type: orderConstants.INITIALIZE_REQUEST };
  }
  function success() {
    return { type: orderConstants.INITIALIZE_SUCCESS };
  }
  function failure() {
    return { type: orderConstants.INITIALIZE_FAILURE };
  }
};

const clearStatus = () => {
  return {
    type: orderConstants.INITIALIZE_CLEAR,
  };
};

const clearOrderReducer = () => {
  return {
    type: orderConstants.CLEAR_ORDERS,
  };
};

const saveNote = (note) => {
  return { type: orderConstants.SAVE_NOTE, note };
};

const initializeOrderOpenAccount = (query) => {
  return (dispatch) => {
    dispatch(request());
    orderService
      .initializeOrderOpenAccount(query)
      .then(
        (res) => {
          if (res?.data?.status === generalConstants.STATUS_TRUE) {
            dispatch(addressActions.setNewAddressId());
            productService
              .clearCart()
              .then(
                (res) => {
                  if (res?.data?.status === generalConstants.STATUS_TRUE) {
                    dispatch({
                      type: productConstants.CLEAR_CART,
                    });
                    dispatch(success());
                  } else {
                    dispatch(failure());
                    toast.error(res?.data?.message);
                  }
                },
                (error) => {
                  dispatch(failure());
                  toast.error(error?.response?.data?.message);
                }
              )
              .catch((error) => {
                dispatch(failure());
                toast.error(error?.response?.data?.message);
              });
          } else {
            dispatch(failure());
            toast.error(res?.data?.message);
          }
        },
        (error) => {
          dispatch(failure());
          toast.error(error?.response?.data?.message);
        }
      )
      .catch((error) => {
        dispatch(failure());
        toast.error(error?.response?.data?.message);
      });
  };

  function request() {
    return { type: orderConstants.INITIALIZE_REQUEST };
  }
  function success() {
    return { type: orderConstants.INITIALIZE_SUCCESS };
  }
  function failure() {
    return { type: orderConstants.INITIALIZE_FAILURE };
  }
};

const initializeOrderOpenAccountAdmin = (query, userId) => {
  return (dispatch) => {
    dispatch(request());
    orderService
      .initializeOrderOpenAccountAdmin(query)
      .then(
        (res) => {
          if (res?.data?.status === generalConstants.STATUS_TRUE) {
            dispatch(addressActions.setNewAddressId());
            productService
              .clearAdminCart(userId)
              .then(
                (res) => {
                  if (res?.data?.status === generalConstants.STATUS_TRUE) {
                    dispatch({
                      type: productConstants.CLEAR_CART,
                    });
                    dispatch(success());
                  } else {
                    dispatch(failure());
                    toast.error(res?.data?.message);
                  }
                },
                (error) => {
                  dispatch(failure());
                  toast.error(error?.response?.data?.message);
                }
              )
              .catch((error) => {
                dispatch(failure());
                toast.error(error?.response?.data?.message);
              });
          } else {
            dispatch(failure());
            toast.error(res?.data?.message);
          }
        },
        (error) => {
          dispatch(failure());
          toast.error(error?.response?.data?.message);
        }
      )
      .catch((error) => {
        dispatch(failure());
        toast.error(error?.response?.data?.message);
      });
  };

  function request() {
    return { type: orderConstants.INITIALIZE_REQUEST };
  }
  function success() {
    return { type: orderConstants.INITIALIZE_SUCCESS };
  }
  function failure() {
    return { type: orderConstants.INITIALIZE_FAILURE };
  }
};

export const orderActions = {
  getAllOrders,
  initializeOrder,
  clearStatus,
  getAdminOrders,
  initializeAdminOrder,
  clearOrderReducer,
  saveNote,
  initializeOrderOpenAccount,
  initializeOrderOpenAccountAdmin,
};
