import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "ItemCardProduct";

const classes = {
  product: `${PREFIX}-product`,
  productName: `${PREFIX}-productName`,
  quantityContainer: `${PREFIX}-quantityContainer`,
  quantity: `${PREFIX}-quantity`,
  quantityText: `${PREFIX}-quantityText`,
  productInfo: `${PREFIX}-productInfo`,
  featuresHeader: `${PREFIX}-featuresHeader`,
  featuresContainer: `${PREFIX}-featuresContainer`,
  row: `${PREFIX}-row`,
  rowpart: `${PREFIX}-rowpart`,
  name: `${PREFIX}-name`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.product}`]: {
    ...verticalFlex,
  },

  [`& .${classes.productName}`]: {
    fontSize: 16,
    fontWeight: "normal",
    color: theme.palette.darkGray.main,
    textAlign: "left",
    marginBottom: 5,
  },

  [`& .${classes.quantityContainer}`]: {
    display: "flex",
    alignItems: "center",
    width: 103,
    height: 28,
    borderRadius: 6,
    backgroundColor: "#F3F5F5",
    paddingLeft: 5,
    marginBottom: 5,
  },

  [`& .${classes.quantity}`]: {
    fontSize: 20,
    fontWeight: "bold",
    color: theme.palette.darkGray.main,
    marginRight: 10,
  },

  [`& .${classes.quantityText}`]: {
    fontSize: 16,
    fontWeight: "normal",
    color: theme.palette.darkGray.main,
  },

  [`& .${classes.productInfo}`]: {
    ...verticalFlex,
    width: 292,
    height: 92,
  },

  [`& .${classes.featuresHeader}`]: {
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "normal",
    color: theme.palette.secondary.main,
    marginBottom: 10,
  },

  [`& .${classes.featuresContainer}`]: {
    ...verticalFlex,
    width: "100%",
    height: "auto",
  },

  [`& .${classes.row}`]: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
    paddingBottom: 2,
  },

  [`& .${classes.rowpart}`]: {
    display: "flex",
    width: 115,
  },

  [`& .${classes.name}`]: {
    width: 75,
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    "&[is-value='true']": {
      fontWeight: 700,
      width: 40,
    },
  },
}));

const verticalFlex = {
  display: "flex",
  flexDirection: "column",
};

const ItemCardProduct = ({ product }) => {
  return (
    <Root className={classes.product}>
      <Typography className={classes.productName}>{product?.name}</Typography>
      <div className={classes.quantityContainer}>
        <Typography className={classes.quantity}>{product.quantity}</Typography>
        <Typography className={classes.quantityText}>
          {product.stock.type === "Adet" ? "Adet" : "Metre"}
        </Typography>
      </div>
      {product?.features && (
        <div className={classes.productInfo}>
          <Typography className={classes.featuresHeader}>
            Özel İşlem Bilgileri
          </Typography>
          <div className={classes.featuresContainer}>
            <div className={classes.row}>
              <div className={classes.rowpart}>
                <Typography className={classes.name}>En:</Typography>
                <Typography className={classes.name} is-value="true">
                  {product.features.width}
                </Typography>
              </div>
              <div className={classes.rowpart}>
                <Typography className={classes.name}>Boy:</Typography>
                <Typography className={classes.name} is-value="true">
                  {product.features.height}
                </Typography>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowpart}>
                <Typography className={classes.name}>Şekil:</Typography>
                <Typography className={classes.name} is-value="true">
                  {product.features.shape}
                </Typography>
              </div>
              <div className={classes.rowpart}>
                <Typography className={classes.name}>Kenar:</Typography>
                <Typography className={classes.name} is-value="true">
                  {product.features.side}
                </Typography>
              </div>
            </div>
            {product.features.side === "Saçak" && (
              <div className={classes.row}>
                <div className={classes.rowpart}>
                  <Typography className={classes.name}>Saçak Tipi:</Typography>
                  <Typography className={classes.name} is-value="true">
                    {product.features.webType}
                  </Typography>
                </div>
                {/* <div className={classes.rowpart}>
                  <Typography className={classes.name}>Saçak Rengi:</Typography>
                  <Typography className={classes.name} is-value="true">
                    {product.features.webColor}
                  </Typography>
                </div> */}
              </div>
            )}
          </div>
        </div>
      )}
    </Root>
  );
};

ItemCardProduct.propTypes = {
  product: PropTypes.object,
};

export default ItemCardProduct;
