import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { userStatus, rolesArr } from "../../../../Utils/Constants";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
}));

const FabContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  marginRight: theme.spacing(),
}));

const ButtonContainer = styled(Box)(() => ({
  width: 100,
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Fab = styled(Box)(({ theme }) => ({
  width: "auto",
  height: 30,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 6,
  padding: theme.spacing(),
  paddingRight: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  width: 25,
  height: 25,
  marginLeft: theme.spacing(),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: 30,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  lineHeight: "24px",
  fontSize: 16,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const FabText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineStyle: "24px",
  color: theme.palette.darkGray.main,
}));

const UsersFilterBox = ({
  statusArr,
  rolesArr_,
  firmsArr,
  firms,
  deleteFab,
  clearFilters,
  query,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let active = true;
    if (active) {
      let statusRaw =
        statusArr.length > 0 ? getRawData(statusArr, userStatus, "status") : [];
      let rolesRaw =
        rolesArr_.length > 0 ? getRawData(rolesArr_, rolesArr, "roles") : [];
      let firmsRaw =
      firmsArr.length > 0 ? getRawData(firmsArr, firms, "firms") : [];
      let wholeData = [...statusRaw, ...rolesRaw, ...firmsRaw];
      setData(wholeData);
    }
    return () => {
      active = false;
    };
  }, [firms, firmsArr, statusArr, rolesArr_]);

  const getRawData = (selection, root, name) => {
    let arr = [];
    for (let i = 0; i < selection.length; i++) {
      let newArr = root.filter((status) => status.id === selection[i]);
      if (name === "status" || name === "roles") {
        newArr.length > 0 && arr.push({ name: newArr[0].status, id: newArr[0].id, source: name }) 
      } else if (name === "firms") {
        newArr.length > 0 && arr.push({ name: newArr[0].name, id: newArr[0].id, source: name });
      }
    }
    return arr;
  };

  let dataToShow = () => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].source === "status") {
        query.filter.status === data[i].id && arr.push(data[i]);
      } else if(data[i].source === "firms") {
        let filterArr = query.filter["company_id"];
        if (filterArr.includes(data[i].id)) {
          arr.push(data[i]);
        }
      } else  {
        let filterArr = query.filter[data[i].source];
        if (filterArr.includes(data[i].id)) {
          arr.push(data[i]);
        }
      }
    }
    return arr;
  };

  let vals = dataToShow();
  return (
    <Root>
      <FabContainer>
        <Grid container spacing={1}>
          {vals.map((item, index) => (
            <Grid item key={index}>
              <Fab>
                <FabText>{item.name}</FabText>
                <CloseButton onClick={() => deleteFab(item)}>
                  <CloseRoundedIcon />
                </CloseButton>
              </Fab>
            </Grid>
          ))}
        </Grid>
      </FabContainer>
      {data.length > 0 && (
        <ButtonContainer>
          <StyledButton onClick={clearFilters}>Temizle</StyledButton>
        </ButtonContainer>
      )}
    </Root>
  );
};

UsersFilterBox.propTypes = {
  statusArr: PropTypes.array,
  rolesArr_: PropTypes.array,
  firmsArr: PropTypes.array,
  firms: PropTypes.array,
  deleteFab: PropTypes.func,
  clearFilters: PropTypes.func,
  query: PropTypes.object,
};

export default UsersFilterBox;
