import React, { useState, useEffect, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import ProductHeader from "./ProductHeader";
import PriceBoxes from "../../../../Ui/PriceBoxes/PriceBoxes";
import Stocks from "../../../../Ui/Stocks/Stocks";
import ProductQuantity from "./ProductQuantity";
import IconButtons from "./IconButtons";
import VariantValues from "../../../../Ui/VariantValues/VariantValues";
import NewSpecialDemandsModal from "../../../../Ui/Dialog/SpecialDemands/NewSpecialDemandsModal";

import {
  selectionsInitialQuery,
  generalConstants,
} from "../../../../Utils/Constants";
import { productService } from "../../../../Services";
import { productActions } from "../../../../Redux/Actions";

import {
  handleDataArray,
  handleActiveItems,
  handleVariants,
} from "../../../../Utils/Helpers";

const PREFIX = "ProductDetails";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "42%",
    minWidth: 505,
    height: "100%",
    paddingRight: 60,
  },

  [`& .${classes.button}`]: {
    width: "100%",
    height: 40,
    textTransform: "capitalize",
    backgroundColor: theme.palette.darkGray.main,
    borderRadius: 8,
    color: "#fff",
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.darkGray.main,
    },
  },
}));

const ProductDetails = ({ data, urlId }) => {
  const toastId = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const specialFeatureValues = useSelector(
    ({ product }) => product.featureValues
  );
  const customer = useSelector(({ customer }) => customer);

  const getCart = () => dispatch(productActions.getCart());
  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id));

  const [productType, setProductType] = useState(null);
  const [newSizes, setNewSizes] = useState({ height: "", width: "" });
  const [customHeight, setCustomHeight] = useState(false);
  const [initialSelection, setInitialSelection] = useState(false);
  const [activeArray1, setActiveArray1] = useState([]);
  const [activeArray2, setActiveArray2] = useState([]);
  const [activeArray3, setActiveArray3] = useState([]);
  const [colorSelected, setColorSelected] = useState(false);
  const [sizeSelected, setSizeSelected] = useState(false);
  const [showSpecialDemandsModal, setShowSpecialDemandsModal] = useState(false);
  const [price, setPrice] = useState("");
  const [values, setValues] = useState({
    color: "",
    size: "",
    shape: "",
  });
  const [selections, setSelections] = useState({
    id: "",
    name: "",
    color: "",
    size: "",
    shape: "",
    quantity: 1,
    features: {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    },
  });

  useEffect(() => {
    const variants = data && handleVariants(data);
    if (data) {
      setPrice(data.price);
    }
    if (data && variants) {
      const productType = data.type ? data.type.id : null;
      setProductType(productType);
      setValues({
        ...values,
        size: handleDataArray(variants, "Ebat"),
        shape: handleDataArray(variants, "Şekil"),
        color: handleDataArray(variants, "Renk"),
      });
      const defaultVariant = variants.filter(
        (variant) => variant.product_id === data.id
      );
      const initialSize = defaultVariant[0].Ebat;
      const initialShape = defaultVariant[0].Şekil;
      const initialColor = defaultVariant[0].Renk;
      const newWidth = +initialSize.split("x")[0];
      const newHeight = +initialSize.split("x")[1];
      const heightIsNan = _.isNaN(newHeight) ? 0 : newHeight;
      setNewSizes({ height: heightIsNan, width: newWidth });
      productType && productType === 3
        ? setCustomHeight(true)
        : setCustomHeight(false);
      setSelections({
        ...selectionsInitialQuery,
        size: initialSize,
        shape: initialShape,
        color: initialColor,
        features: {
          ...selectionsInitialQuery.features,
          width: newWidth,
          height: productType && productType === 3 ? 0 : heightIsNan,
        },
      });
      setActiveArray1(defaultVariant);
      setActiveArray2(defaultVariant);
      setActiveArray3(defaultVariant);
      setInitialSelection(true);
      // setSizeSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selections.size?.length > 0) {
      setSizeSelected(true);
    } else {
      setSizeSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.size]);

  useEffect(() => {
    if (values.color.length === 1) {
      setColorSelected(true);
      const variants = handleVariants(data);
      const result = handleActiveItems(variants, "Renk", values.color[0]);
      setInitialSelection(false);
      setActiveArray1(result);
    } else if (selections.color?.length > 0 && !initialSelection) {
      setColorSelected(true);
    } else {
      setColorSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.color, values]);

  useEffect(() => {
    const id = activeArray3.length > 0 ? activeArray3[0].product_id : null;
    id && +urlId !== id && history.push(`/product/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeArray3]);

  const handleChange = (prop) => (event) => {
    const evt = event.target;
    const activeSizes = handleDataArray(activeArray1, "Ebat");
    const activeShapes = handleDataArray(activeArray2, "Şekil");
    const selectedValue = prop === "quantity" ? evt.value : evt.innerText;
    if (prop === "color") {
      const variants = handleVariants(data);
      const result = handleActiveItems(variants, "Renk", evt.innerText);
      setInitialSelection(false);
      setActiveArray1(result);
      setCustomHeight(false);
      activeArray2.length > 0 && setActiveArray2([]);
      activeArray3.length > 0 && setActiveArray3([]);
      setSelections({
        ...selections,
        [prop]: selectedValue,
        size: "",
        shape: "",
        quantity: 1,
        features: {
          ...selections.features,
          shape: "",
          side: "",
          webType: "",
          webColor: "",
          width: newSizes.width,
          height: newSizes.height,
        },
      });
    } else if (prop === "size") {
      if (initialSelection && !colorSelected) return;
      if (!colorSelected) return;
      if (!activeSizes.includes(evt.innerText)) return;
      const result = handleActiveItems(activeArray1, "Ebat", evt.innerText);
      setActiveArray2(result);
      const result2 = handleDataArray(result, "Şekil");
      if (result2.length === 1) {
        let result_ = handleActiveItems(result, "Şekil", result2[0]);
        setActiveArray3(result_);
        setPrice(result[0].price);
        setSelections({
          ...selections,
          [prop]: selectedValue,
          shape: result2[0],
        });
      } else {
        activeArray3.length > 0 && setActiveArray3([]);
        setSelections({
          ...selections,
          [prop]: selectedValue,
          shape: "",
        });
      }
    } else if (prop === "shape") {
      if (initialSelection) return;
      if (!sizeSelected) return;
      if (!activeShapes.includes(evt.innerText)) return;
      const result = handleActiveItems(activeArray2, "Şekil", evt.innerText);
      setActiveArray3(result);
      setPrice(result[0].price);
      setSelections({
        ...selections,
        [prop]: selectedValue,
      });
    } else if (prop === "quantity") {
      setSelections({
        ...selections,
        [prop]: selectedValue,
      });
    }
  };

  const increaseQuantityFromModal = () => {
    setSelections({
      ...selections,
      quantity: selections.quantity + 1,
    });
  };

  const decreaseQuantityFromModal = () => {
    if (selections.quantity === 1) return;
    setSelections({
      ...selections,
      quantity: selections.quantity - 1,
    });
  };

  const closeSpecialDemandsModal = () => setShowSpecialDemandsModal(false);
  const openSpecialDemandsModal = () => setShowSpecialDemandsModal(true);

  const handleFeatures = (prop) => (e) => {
    const val = e.target.value;
    const { shape } = selections.features;
    const initialWidth = newSizes.width;
    const { type } = data;
    const isCircle = shape === "Daire";
    const maxWidth = val > initialWidth ? +initialWidth : +val;
    const dimensions = data?.attributes_map.Ebat;
    const height = parseInt(dimensions.split("x")[1]);
    if (selections.features[prop] === val) {
      // setSelections({
      //   ...selections,
      //   features: { ...selections.features, [prop]: "" },
      // });
    } else {
      if (
        prop === "side" &&
        val !== "Saçak" &&
        selections.features.webType !== ""
      ) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: val,
            webType: "",
          },
        });
      } else if (prop === "shape") {
        if (val === "Daire") {
          setSelections({
            ...selections,
            features: {
              ...selections.features,
              [prop]: val,
              height: selections.features.width,
            },
          });
        } else {
          setSelections({
            ...selections,
            features: {
              ...selections.features,
              [prop]: val,
              width: newSizes.width,
              height: 0,
            },
          });
        }
      } else if (prop === "height") {
        if (type.id === 2 && +val > +height) {
          setSelections({
            ...selections,
            features: {
              ...selections.features,
              [prop]: isCircle ? maxWidth : +height,
              width: isCircle ? +height : selections.features.width,
            },
          });
        } else {
          const height_ =
            val > 2500
              ? isCircle
                ? initialWidth
                : 2500
              : isCircle && +val > initialWidth
              ? initialWidth
              : +val;
          setSelections({
            ...selections,
            features: {
              ...selections.features,
              [prop]: +height_,
              width: isCircle ? +height_ : selections.features.width,
            },
          });
        }
      } else if (prop === "width") {
        const currentHeight = isCircle
          ? val > initialWidth
            ? initialWidth
            : val
          : selections.features.height;
        const currentWidth = val > initialWidth ? initialWidth : val;
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: currentWidth,
            height: currentHeight,
          },
        });
      } else {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: val },
        });
      }
    }
  };

  const notify = () => (toastId.current = toast("Ürün Sepete Eklendi"));

  const handleSubmit = (prop) => () => {
    if (productType === 3 && !showSpecialDemandsModal) {
      setShowSpecialDemandsModal(true);
    } else {
      const productId = data.has_variants
        ? activeArray3[0]?.product_id || data?.id
        : data?.id;
      const query = {
        product_id: productId,
        quantity: selections.quantity,
        features: prop === "special" ? selections.features : null,
      };

      if (customer?.isAdmin) {
        const id = customer?.selectedUser?.id;
        productService
          .addToAdminCart(query, id)
          .then((res) => {
            if (res.data.status === generalConstants.STATUS_TRUE) {
              getAdminCart(id);
              notify();
              prop === "special" && closeSpecialDemandsModal();
              setSelections({
                ...selections,
                quantity: 1,
              });
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err?.response?.data?.message);
          });
      } else {
        productService
          .addToCart(query)
          .then((res) => {
            if (res.data.status === generalConstants.STATUS_TRUE) {
              getCart();
              notify();
              prop === "special" && closeSpecialDemandsModal();
              setSelections({
                ...selections,
                quantity: 1,
              });
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err?.response?.data?.message);
          });
      }
    }
  };

  const renderProductHeader = useMemo(() => {
    return <ProductHeader data={data} />;
  }, [data]);

  const renderPriceBoxes = useMemo(() => {
    return <PriceBoxes price={price} productType={productType} />;
  }, [price, productType]);

  return (
    <Root className={classes.root}>
      {renderProductHeader}
      {renderPriceBoxes}
      {(data?.has_variants || data?.variants.length > 0) && (
        <>
          <VariantValues
            data={values.color}
            param="color"
            handleChange={handleChange}
            header="Renkler"
            selectedValue={selections.color}
            activeArray={[]}
            selections={selections}
          />
          <VariantValues
            data={values.size}
            param="size"
            handleChange={handleChange}
            header="Ebatlar"
            selectedValue={selections.size}
            activeArray={handleDataArray(activeArray1, "Ebat")}
            selections={selections}
          />
          <VariantValues
            data={values.shape}
            param="shape"
            handleChange={handleChange}
            header="Şekil"
            selectedValue={selections.shape}
            activeArray={
              activeArray2.length > 0
                ? handleDataArray(activeArray2, "Şekil")
                : handleDataArray(activeArray1, "Şekil")
            }
            selections={selections}
          />
        </>
      )}

      <>
        <Stocks stock={data?.stock} productType={productType} />
        <ProductQuantity
          selectedValue={selections.quantity}
          param="quantity"
          handleChange={handleChange}
          features={selections.features}
          handleFeatures={handleFeatures}
          customHeight={customHeight}
          stockInfo={parseInt(data?.stock.quantity)}
          openSpecialDemandsModal={openSpecialDemandsModal}
          type={productType}
          data={data}
          disabled={activeArray3.length === 0}
        />
        <Button
          variant="contained"
          disableRipple
          className={classes.button}
          disabled={activeArray3.length === 0}
          onClick={handleSubmit("standard")}
        >
          Sepete Ekle
        </Button>
        <IconButtons product={data && data} />
      </>
      <NewSpecialDemandsModal
        open={showSpecialDemandsModal}
        product={data}
        features={selections.features}
        specialFeatureValues={specialFeatureValues}
        handleFeatures={handleFeatures}
        increase={increaseQuantityFromModal}
        decrease={decreaseQuantityFromModal}
        selections={selections}
        handleSubmit={handleSubmit}
        closeDialogFromButton={closeSpecialDemandsModal}
        setSelections={setSelections}
      />
    </Root>
  );
};

ProductDetails.propTypes = {
  data: PropTypes.object,
  urlId: PropTypes.string,
};

export default ProductDetails;
