import React from "react";
import PropTypes from "prop-types";

import NewProductCard from "../../../Ui/ProductCard/NewProductCard";
import NewProductListViewItem from "../../../Ui/ProductCard/NewProductListViewItem";

import NoResult from "./NoResult";

import Grid from "@mui/material/Grid";

const TabContent = ({
  products,
  loading,
  view,
}) => {
  let gridVal = view === "bars" ? 3 : 6;
  return (
    <>
      {products?.length > 0 ? (
        <Grid container spacing={2}>
          {products?.map((item, index) => (
            <Grid
              item
              key={index}
              xs={gridVal}
              sm={gridVal}
              md={gridVal}
              lg={gridVal}
              xl={gridVal}>
              {view === "bars" ? (
                <NewProductCard item={item} />
              ) : (
                <NewProductListViewItem item={item} />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        !loading && <NoResult />
      )}
    </>
  );
};

TabContent.propTypes = {
  products: PropTypes.array,
  loading: PropTypes.bool,
  view: PropTypes.string,
};

export default TabContent;
