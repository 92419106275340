import {
  notificationReducerInitialState,
  notificationConstants,
} from "../../Utils/Constants";

const initialState = {
  data: null,
};
const notifications = (state = initialState, action) => {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS:
      return {
        ...state,
        data: action.payload,
      };
    case notificationConstants.CLEAR_NOTIFICATION_REDUCER:
      return notificationReducerInitialState;
    default:
      return state;
  }
};

export { notifications };
