import React, { useState, useEffect } from "react";
import TurkeyMap from "turkey-map-react";
import { isMobileOnly } from "react-device-detect";
import { useDispatch } from "react-redux";

import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import PageContainer from "../Common/PageContainer";

import { addressService } from "../../Services";

import { cities, generalConstants } from "../../Utils/Constants";
import { bottomNavbarActions } from "../../Redux/Actions";

import AutoCompleteArea from "../../Ui/Fields/AutocompleteArea";
import Content from "./Main/Content";

const Map = () => {
  const dispatch = useDispatch();

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  const [counties, setCounties] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [firstRender, setFirstRender] = useState(true)
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 20,
    sortField: "title",
    sortOrder: "asc",
  });

  const [values, setValues] = useState({
    city: { name: "Ankara", id: 7 },
    county: { name: "Lütfen İlçe Seçiniz", id: 0.5 },
  });

  useEffect(() => {
    let active = true;
    if (active && isMobileOnly) {
      setMenu("other");
      setPageNumber(1);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.city?.id !== 0.5 && values?.city?.id) {
        getCounties(values.city?.id);
        getCompanies(values.city?.id, null);
        setCompanies([]);
      } else if (!values?.city?.id) {
        setCompanies([]);
        setValues({
          ...values,
          county: null,
        });
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.city)]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.county?.id !== 0.5 && values.city?.id) {
        getCompanies(values.city?.id, values.county?.id);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.county)]);

  // useEffect(() => {
  //   setQuery({ ...query, pageNumber });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageNumber]);

  useEffect(() => {
    !firstRender && getCompanies(values.city?.id, values.county?.id);
    setFirstRender(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const renderCity = (cityComponent, cityData) => {
    return (
      <Tooltip title={cityData.name} key={cityData.id}>
        {cityComponent}
      </Tooltip>
    );
  };

  const clickHandler = async (e) => {
    const { id, name } = e;
    let newName = id === "hakkari" ? "Hakkari" : name;
    let currentId = cities.filter((city) => city.name === newName)[0].id;
    setValues({
      ...values,
      city: { name: newName, id: currentId },
      county: { name: "Lütfen İlçe Seçiniz", id: 0.5 },
    });
  };

  const getCounties = async (id) => {
    const response = await addressService.getCounties(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setCounties(response.data.counties);
    }
  };

  const getCompanies = async (cityId, countyId) => {
    let queryCity = { ...query, filter: { city_id: cityId } };
    let query_ = countyId && countyId !== 0.5
      ? { ...queryCity, filter: { ...queryCity.filter, county_id: countyId } }
      : queryCity;

    const response = await addressService.getCompaniesInCities(query_);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      const res = response.data;
      console.log("file: TurkeyMap.js ~ line 132 ~ res", res)
      // const arr = [22, 392, 331]
      // const data = response?.data?.companies?.filter(company => !arr.includes(company.id) )
      setCompanies(res.companies);
      let count_ = Math.ceil(res.total_count / 20);
      setPageCount(count_);
    }
  };

  const onPageNumberChange = (event, value) => {
    setPageNumber(value);
    setQuery({ ...query, pageNumber: value })
  } 

  return (
    <PageContainer>
      <TurkeyMap
        cityWrapper={renderCity}
        customStyle={{ idleColor: "#C74BA3", hoverColor: "#760054" }}
        onClick={clickHandler}
        // data={
        //   {
        //     id: data?.id,
        //     plateNumber: data?.plateNumber,
        //     name: data?.name,
        //     path: data?.path,
        //   }[0]
        // }
      />
      <Container maxWidth="sm" style={{ marginBottom: 32 }}>
        <Grid container spacing={2}>
          <Grid item style={{ width: "100%" }} xs={12} sm={6} md={6} lg={6}>
            <AutoCompleteArea
              data={cities}
              label="Şehir"
              setValues={setValues}
              prop="city"
              values={values}
            />
          </Grid>
          <Grid item style={{ width: "100%" }} xs={12} sm={6} md={6} lg={6}>
            <AutoCompleteArea
              data={counties}
              label="İlçe"
              setValues={setValues}
              prop="county"
              values={values}
              disabled={values.city?.id === 0.5 || !values?.city?.id}
            />
          </Grid>
        </Grid>
      </Container>
      <Content
        companies={companies}
        pageNumber={pageNumber}
        pageCount={pageCount}
        onPageNumberChange={onPageNumberChange}
      />
    </PageContainer>
  );
};

export default Map;
