import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const PREFIX = "ReturnItemPopover";

const classes = {
  popoverRoot: `${PREFIX}-popoverRoot`,
  paper: `${PREFIX}-paper`,
  row: `${PREFIX}-row`,
  rowText: `${PREFIX}-rowText`,
};

const StyledPopover = styled(Popover)(({theme}) => ({
  [`&.${classes.popoverRoot}`]: {
    padding: 0,
  },

  [`& .${classes.paper}`]: {
    width: 280,
    height: 132,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.row}`]: {
    width: "100%",
    height: 44,
    borderBottom:`1px solid ${theme.palette.darkGray.light}`,
    paddingLeft: 16,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    userSelect: "none",
    cursor: "pointer",
    "&[last-row='true']": {
      borderBottom: "none",
    },
  },

  [`& .${classes.rowText}`]: {
    fontSize: 14,
    fontWeight: 14,
    color: "#333333",
  },
}));

const ReturnItemPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  showAddReturn,
}) => {
  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={classes.popoverRoot}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={classes.paper}>
        <div className={classes.row}>
          <Typography className={classes.rowText}>Bilgi Düzenle</Typography>
        </div>
        <div className={classes.row} onClick={showAddReturn}>
          <Typography className={classes.rowText}>
            Yeni İade Kaydı Ekle
          </Typography>
        </div>
        <div className={classes.row} last-row="true">
          <Typography className={classes.rowText}>Kaydı Sil</Typography>
        </div>
      </div>
    </StyledPopover>
  );
};

ReturnItemPopover.propTypes = {
  id: PropTypes.any,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  showAddReturn: PropTypes.func,
};

export default ReturnItemPopover;
