import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";

import AuthContainerMob from "./AuthContainerMob";
import Info from "./PasswordUpdated/Info";
import passwordIcon from "../../Images/Auth/password.svg";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledImg = styled("img")(() => ({
  marginTop: 71.5,
  marginBottom: 53.85,
}));

const StyledCard = styled("div")(() => ({
  marginTop: 30,
  width: "95%",
  background: "rgba(133, 89, 122, 0.1)",
  borderRadius: 8,
  height: 320,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PasswordUpdated = () => {
  let history = useHistory();

  const returnSignIn = () =>
    setTimeout(() => {
      history.push("/signin-email");
    }, 3000);

  returnSignIn();

  return (
    <AuthContainerMob>
      <Root>
        <StyledCard>
          <StyledImg
            src={passwordIcon}
            alt="passwordIcon"
            width="133.40px"
            height="102.28px"
          />
          <Info />
        </StyledCard>
      </Root>
    </AuthContainerMob>
  );
};

export default PasswordUpdated;
