import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 28,
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 28,
  backgroundColor: theme.palette.primary.main,
  textTransform: "none",
  color: "#fff",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "20px",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StockButton = ({ openDialog, disabled }) => {
  return (
    <Root>
      <StyledButton disabled={disabled} onClick={openDialog} disableRipple>
        Stok Gör
      </StyledButton>
    </Root>
  );
};

StockButton.propTypes = {
  openDialog: PropTypes.func,
  disabled: PropTypes.bool,
};

export default StockButton;
