import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import CartProduct from "../../Ui/CartProduct/CartProduct";
import trashIcon from "../../Images/Cart/Trash.svg";
import Loading from "../../Utils/Loading";

const PREFIX = "MyCart";

const classes = {
  productsContainerMain: `${PREFIX}-productsContainerMain`,
  headerContainer: `${PREFIX}-headerContainer`,
  header: `${PREFIX}-header`,
  clearCartButton: `${PREFIX}-clearCartButton`,
  gridContainer: `${PREFIX}-gridContainer`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.productsContainerMain}`]: {
    flex: 1,
    height: "auto",
    minHeight: 200,
    // minWidth:980,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    marginRight: 10,
    paddingRight: 10,
  },

  [`& .${classes.headerContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 40,
    marginTop: theme.spacing(2)
  },

  [`& .${classes.header}`]: {
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    paddingLeft: theme.spacing(),
  },

  [`& .${classes.clearCartButton}`]: {
    width: "content-fit",
    height: "auto",
    backgroundColor: "#fff",
    color: "#FB0505",
    textTransform: "none",
    fontSize: 12,
    fontWeight: 500,
    // marginRight: 40,
    border: "1px solid #FB0505",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  [`& .${classes.gridContainer}`]: {
    overflowY: "scroll",
    height: "90vh",
    paddingBottom: 100,
  },
}));

const MyCart = ({ data, deleteProduct, clearCart }) => {
  const loading = useSelector(({ product }) => product.loading);

  return (
    <Root className={classes.productsContainerMain}>
      <Loading open={loading} />
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>Sepetim</Typography>
        <Button
          className={classes.clearCartButton}
          disableRipple
          onClick={clearCart}
          variant="outlined"
          startIcon={<img src={trashIcon} alt="" />}>
          Sepeti Temizle
        </Button>
      </div>
      <div className={classes.gridContainer}>
        <Grid container spacing={2} style={{ padding: 10 }}>
          {data && data?.length > 0
            ? data.map((item, index) => (
                <Grid key={index} item xs={6} lg={6} sm={6} md={6}>
                  <CartProduct product={item} deleteProduct={deleteProduct} />
                </Grid>
              ))
            : null}
        </Grid>
      </div>
    </Root>
  );
};

MyCart.propTypes = {
  data: PropTypes.array,
  deleteProduct: PropTypes.func,
  clearCart: PropTypes.func,
};

export default MyCart;
