import React, { useState, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomField from "./CreditCart/CustomField";
import TextArea from "../../../Ui/Fields/TextArea";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { orderActions } from "../../../Redux/Actions";

import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  paddingTop: theme.spacing(3),
  display: "flex",
  paddingLeft: theme.spacing(),
  paddingBottom: theme.spacing(),
}));

const InputContainer = styled(Card)(({ theme }) => ({
  width: 415,
  height: "auto",
  minHeight: 350,
  borderRadius: 8,
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
}));

const CreditCard = (props, ref) => {
  const dispatch = useDispatch();

  const customer = useSelector(({ customer }) => customer);

  const initializeOrder = (query) =>
    dispatch(orderActions.initializeOrder(query));

  const initializeAdminOrder = (query, id) =>
    dispatch(orderActions.initializeAdminOrder(query, id));

  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    cardHolderName: "",
    cardNumber: "**** **** **** ****",
    expiryDate: "  /  ",
    cvc: "   ",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeName = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const handleCheck = () => {
    const { cardHolderName, cardNumber, expiryDate, cvc } = values;
    if (
      cardHolderName.length === 0 ||
      cardNumber.length !== 19 ||
      expiryDate.length !== 5 ||
      cvc.length !== 3 ||
      cvc === "   " ||
      expiryDate === "  /  " ||
      cardHolderName === "" ||
      cardNumber === "**** **** **** ****"
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };
  const placeOrder = (text) => {
    let query = {
      method_id: 1,
      address_id: customer.currentAddress.id,
      card_owner: values.cardHolderName,
      card_number: values.cardNumber.replace(/\s+/g, "").toString(),
      expire_month: values.expiryDate.substring(0, 2),
      expire_year: values.expiryDate.substring(3, 5),
      cvc: +values.cvc,
      installment: 1,
      extra_args: {},
      note: text,
      source: 1,
    };
    let isError = handleCheck();
    if (!isError) {
      if (customer?.isAdmin) {
        let adminQuery = {
          ...query,
          user_id: customer?.selectedUser?.id,
          company_id: customer?.currentCompany?.id,
        };
        initializeAdminOrder(adminQuery, customer?.selectedUser?.id);
      } else {
        initializeOrder(query);
      }
    } else {
      console.log("error");
    }
  };

  useImperativeHandle(ref, (text) => ({
    placeOrder: (text) => {
      placeOrder(text);
    },
  }));

  return (
    <Root>
      <InputContainer>
        <TextArea
          label="Kart Üzerindeki Ad-Soyad"
          handleChange={handleChangeName}
          values={values}
          prop="cardHolderName"
          error={error}
          placeholder="Kart sahibinin adı soyadı"
          creditCard
        />
        <CustomField
          label="Kart Numarası"
          handleChange={handleChange}
          values={values}
          prop="cardNumber"
          error={error}
          placeholder="* * * *    * * * *    * * * *    * * * *"
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomField
              label="Tarih"
              handleChange={handleChange}
              values={values}
              prop="expiryDate"
              error={error}
              placeholder="_ _ / _ _"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              label="CVC"
              handleChange={handleChange}
              values={values}
              prop="cvc"
              error={error}
              placeholder="* * *"
            />
          </Grid>
        </Grid>
      </InputContainer>
    </Root>
  );
};

export default React.forwardRef(CreditCard);

// let query = {
//   address_id: customer.currentAddress.id,
//   card_owner: "John Doe",
//   card_number: "5528790000000008",
//   expire_month: "12",
//   expire_year: "30",
//   cvc: 123,
//   installment: 1,
//   extra_args: {},
//   note: text,
//   source: 1,
// };
