import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const PREFIX = "AppBarTabs";

const classes = {
  tab: `${PREFIX}-tab`,
  indicator: `${PREFIX}-indicator`,
  tabs: `${PREFIX}-tabs`,
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .${classes.tab}`]: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "rgba(106, 104, 104, 1)",
    width: "fit-content",
    paddingLeft: 0,
    paddingRight: 0,
    "&.MuiTab-root": {
      minWidth: 110,
    },
  },

  [`& .${classes.indicator}`]: {
    backgroundColor: theme.palette.primary.main,
  },

  [`&.${classes.tabs}`]: {
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
    "& .Mui-selected": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    "& .css-jpln7h-MuiTabs-scroller": {
      overflowX: "scroll !important",
      // WebkitOverflowScrolling: "touch",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root": {
      width: 30,
    },
  },
}));

const TabButtonsMob = ({ index, handleChange }) => {
  const paymentMethods = useSelector(
    ({ customer }) => customer?.currentCompany?.payment_methods
  );

  return (
    <StyledTabs
      value={index}
      variant="scrollable"
      allowScrollButtonsMobile
      aria-label="filter-tabs"
      style={{ overflow: "auto", overflowX: "scroll !important" }}
      classes={{
        indicator: classes.indicator,
      }}
      className={classes.tabs}>
      {paymentMethods?.length > 0 &&
        paymentMethods.map((item, i) => (
          <Tab
            key={i}
            className={classes.tab}
            label={item.name}
            onClick={() => handleChange(i, item.id, item.name)}
          />
        ))}
    </StyledTabs>
  );
};

TabButtonsMob.propTypes = {
  index: PropTypes.number,
  handleChange: PropTypes.func,
};

export default TabButtonsMob;
