export const generalConstants = {
  STATUS_FALSE: false,
  STATUS_TRUE: true,
  B2B: "B2B",
};

export const slotData = {
  day: {
    text: "Bugün",
    m2: "255.25",
    percentage: "15%",
    date: "01.Tem",
    income: "2,505.25₺",
  },
  week: {
    text: "Bu Hafta",
    m2: "2,545.25",
    percentage: "8%",
    date: "01.Tem/08.Tem",
    income: "20,505.25₺",
  },
  month: {
    text: "Bu Ay",
    m2: "25,215.25",
    percentage: "23%",
    date: "Temmuz",
    income: "222,505.25₺",
  },
};

export const status = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

export const stockData = [
  {
    rulo: "1. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "2. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "3. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "4. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "5. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "6. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "7. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "8. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "9. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "10. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "11. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
  {
    rulo: "12. Rulo",
    miktar: "5.28",
    birim: "MT",
  },
];

export const mockFeatures = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
];

export const mockRatings = [
  {
    date: "12 Eyl 2019",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet sapien venenatis, scelerisque ni magnis.",
    point: 3,
  },
  {
    date: "12 Eyl 2019",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet sapien venenatis, scelerisque ni magnis.",
    point: 2.5,
  },
  {
    date: "12 Eyl 2019",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet sapien venenatis, scelerisque ni magnis.",
    point: 3.5,
  },
  {
    date: "12 Eyl 2019",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet sapien venenatis, scelerisque ni magnis.",
    point: 4.5,
  },
  {
    date: "12 Eyl 2019",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet sapien venenatis, scelerisque ni magnis.",
    point: 4,
  },
  {
    date: "12 Eyl 2019",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet sapien venenatis, scelerisque ni magnis.",
    point: 2,
  },
];

export const homePageSections = [
  "Çok Satanlar",
  "Bölgende Çok Satanlar",
  "Senin Çok Sattıkların",
  "Günün Fırsatları",
  "Son Gezilen Ürünler",
  "Yeni Ürünler",
];

export const productPageSections = ["Benzer Ürünler", "Son Gezilen Ürünler"];

export const rating = [
  {
    id: 1,
    status: 1,
    name: "4 ve 4+ puanlı ürünler",
  },
  {
    id: 2,
    status: 1,
    name: "3 ve 3.9 puanlı ürünler",
  },
  {
    id: 3,
    status: 1,
    name: "2 ve 2.9 puanlı ürünler",
  },
  {
    id: 4,
    status: 1,
    name: "1 ve 1.p puanlı ürünler",
  },
];

export const selectionsInitialQuery = {
  id: "",
  name: "",
  color: "",
  size: "",
  shape: "",
  quantity: 1,
  features: {
    width: "",
    height: "",
    shape: "Dikdörtgen",
    side: "Overlok",
    webType: "",
    webColor: "",
  },
};

export const featuresEmptyQuery = {
  width: "",
  height: "",
  shape: "",
  side: "",
  webType: "",
  webColor: "",
};

export const errorTexts = {
  first_name: "Lütfen geçerli bir ad giriniz",
  last_name: "Lütfen geçerli bir soyad giriniz",
  email: "Lütfen geçerli bir e-posta adresi giriniz",
  newEmail: "E-posta adresleri eşleşmiyor",
  gsm: "Lütfen geçerli bir telefon numarası giriniz",
  phone: "Lütfen geçerli bir telefon numarası giriniz",
  phone1: "Lütfen geçerli bir telefon numarası giriniz",
  phone2: "Lütfen geçerli bir telefon numarası giriniz",
  firm: "Lütfen firma seçiniz",
  branch: "Lütfen adres veya şube seçiniz",
  roles: "Lütfen yetki seçiniz",
  reason: "Lütfen servis nedeni ekleyiniz",
  name: "Lütfen ürün veya hizmet adı ekleyiniz",
  explanation: "Lütfen açıklama ekleyiniz",
  role: "Lütfen geçerli bir yetki adı giriniz",
  title: "Lütfen adres başlığı giriniz",
  address: "Lütfen geçerli bir adres giriniz",
  address1: "Lütfen geçerli bir adres giriniz",
  address2: "Lütfen geçerli bir adres giriniz",
  city_id: "Lütfen şehir seçiniz",
  county_id: "Lütfen ilçe seçiniz",
  district_id: "Lütfen mahalle seçiniz",
  confirmPassword: "Şifreler eşleşmiyor.",
  oldPassword: "Lütfen şifrenizi giriniz",
  newPassword: "Lütfen yeni şifrenizi giriniz",
  gsmNew: "Lütfen geçerli bir telefon numarası giriniz.",
  defaultAddress: "Lütfen geçerli bir adres seçiniz",
  cardHolderName: "Lütfen geçerli ad soyad bilgisi giriniz",
  cardNumber: "Lütfen kartınızın ön yüzünde bulunan 16 haneli numarayı giriniz",
  expiryDate: "Lütfen kartınızın son kullanım tarihini giriniz",
  cvc: "Lütfen kartınızın arka tarafında yer alan güvenlik numarasını giriniz",
};

export const checkBoxSections = {
  mainLabel: "Main Label",
  subSection1: {
    subSectionLabel: "Sub Label1",
    subSectionOptions: [
      "Sub1_label1",
      "Sub1_label2",
      "Sub1_label3",
      "Sub1_label4",
      "Sub1_label5",
      "Sub1_label6",
      "Sub1_label7",
      "Sub1_label8",
    ],
  },
  subSection2: {
    subSectionLabel: "Sub Label2",
    subSectionOptions: [
      "Sub2_label1",
      "Sub2_label2",
      "Sub2_label3",
      "Sub2_label4",
    ],
  },
};

export const indeterminate = "indeterminate";

export const firms = [
  { name: "HepsiBurada", id: 1 },
  { name: "TrendYol", id: 2 },
  { name: "Akakçe", id: 3 },
  { name: "Cimri", id: 4 },
  { name: "Vault", id: 5 },
  { name: "qaz", id: 7 },
  { name: "wsx", id: 8 },
  { name: "edc", id: 9 },
  { name: "rfv", id: 10 },
  { name: "tgb", id: 11 },
  { name: "yhn", id: 12 },
  { name: "ujm", id: 13 },
  { name: "ıkö", id: 14 },
  { name: "olç", id: 15 },
  { name: "qwe", id: 16 },
  { name: "asd", id: 17 },
  { name: "zxc", id: 18 },
];

export const roles = [
  { name: "Yönetici", id: 1 },
  { name: "Satış Danışmanı", id: 2 },
  { name: "Muhasebe", id: 3 },
  { name: "Satış Onay", id: 4 },
  { name: "Pazarlama", id: 5 },
];

export const branches = [
  { name: "Şube1", id: 1 },
  { name: "Şube2", id: 2 },
  { name: "Şube3", id: 3 },
];

export const MAX_FILE_SIZE = 5242880;

export const bottomNavItems = {
  MAIN: "Özsafir",
  CATEGORIES: "Kategoriler",
  SEARCH: "Ara",
  ORDERS: "Siparişler",
  MENU: "Menü",
};

export const roleNameConverter = {
  companyuser: "Firma Kullanıcı",
  companyadmin: "Firma Yetkili",
  seller: "Seller",
  admin: "Admin",
};

export const ordersHeaderValues = [
  {
    name: "#",
    width: 57,
    imgField: true,
  },
  {
    name: "Kaynak",
    width: 73,
  },
  {
    name: "No",
    width: 148,
  },
  {
    name: "Tarih",
    width: 130,
  },
  // {
  //   name: "Sorumlu",
  //   width: 120,
  // },
  {
    name: "Tutar",
    width: 102,
  },
  // {
  //   name: "Açıklama",
  //   width: 202,
  // },
  {
    name: "Sevk Adresi",
    width: 125,
  },
  {
    name: "Durum",
    width: 182,
  },
];

export const cities = [
  {
    id: 7,
    status: 1,
    name: "Ankara",
  },
  {
    id: 48,
    status: 1,
    name: "Kırıkkale",
  },
  {
    id: 24,
    status: 1,
    name: "Çorum",
  },
  {
    id: 75,
    status: 1,
    name: "Trabzon",
  },
  {
    id: 47,
    status: 1,
    name: "Kayseri",
  },
  {
    id: 70,
    status: 1,
    name: "Sivas",
  },
  {
    id: 1,
    status: 1,
    name: "Adana",
  },
  {
    id: 67,
    status: 1,
    name: "Samsun",
  },
  {
    id: 21,
    status: 1,
    name: "Bursa",
  },
  {
    id: 52,
    status: 1,
    name: "Kocaeli",
  },
  {
    id: 13,
    status: 1,
    name: "Bartın",
  },
  {
    id: 65,
    status: 1,
    name: "Rize",
  },
  {
    id: 25,
    status: 1,
    name: "Denizli",
  },
  {
    id: 34,
    status: 1,
    name: "Giresun",
  },
  {
    id: 6,
    status: 1,
    name: "Amasya",
  },
  {
    id: 32,
    status: 1,
    name: "Eskişehir",
  },
  {
    id: 42,
    status: 1,
    name: "Kahramanmaraş",
  },
  {
    id: 53,
    status: 1,
    name: "Konya",
  },
  {
    id: 40,
    status: 1,
    name: "İstanbul",
  },
  {
    id: 46,
    status: 1,
    name: "Kastamonu",
  },
  {
    id: 8,
    status: 1,
    name: "Antalya",
  },
  {
    id: 80,
    status: 1,
    name: "Yozgat",
  },
  {
    id: 36,
    status: 1,
    name: "Hakkari",
  },
  {
    id: 11,
    status: 1,
    name: "Aydın",
  },
  {
    id: 66,
    status: 1,
    name: "Sakarya",
  },
  {
    id: 74,
    status: 1,
    name: "Tokat",
  },
  {
    id: 62,
    status: 1,
    name: "Niğde",
  },
  {
    id: 81,
    status: 1,
    name: "Zonguldak",
  },
  {
    id: 69,
    status: 1,
    name: "Sinop",
  },
  {
    id: 39,
    status: 1,
    name: "Isparta",
  },
  {
    id: 5,
    status: 1,
    name: "Aksaray",
  },
  {
    id: 43,
    status: 1,
    name: "Karabük",
  },
  {
    id: 27,
    status: 1,
    name: "Düzce",
  },
  {
    id: 72,
    status: 1,
    name: "Şırnak",
  },
  {
    id: 50,
    status: 1,
    name: "Kırşehir",
  },
  {
    id: 41,
    status: 1,
    name: "İzmir",
  },
  {
    id: 14,
    status: 1,
    name: "Batman",
  },
  {
    id: 3,
    status: 1,
    name: "Afyonkarahisar",
  },
  {
    id: 33,
    status: 1,
    name: "Gaziantep",
  },
  {
    id: 61,
    status: 1,
    name: "Nevşehir",
  },
  {
    id: 77,
    status: 1,
    name: "Uşak",
  },
  {
    id: 44,
    status: 1,
    name: "Karaman",
  },
  {
    id: 19,
    status: 1,
    name: "Bolu",
  },
  {
    id: 56,
    status: 1,
    name: "Manisa",
  },
  {
    id: 63,
    status: 1,
    name: "Ordu",
  },
  {
    id: 55,
    status: 1,
    name: "Malatya",
  },
  {
    id: 22,
    status: 1,
    name: "Çanakkale",
  },
  {
    id: 60,
    status: 1,
    name: "Muş",
  },
  {
    id: 16,
    status: 1,
    name: "Bilecik",
  },
  {
    id: 54,
    status: 1,
    name: "Kütahya",
  },
  {
    id: 58,
    status: 1,
    name: "Mersin",
  },
  {
    id: 37,
    status: 1,
    name: "Hatay",
  },
  {
    id: 12,
    status: 1,
    name: "Balıkesir",
  },
  {
    id: 20,
    status: 1,
    name: "Burdur",
  },
  {
    id: 64,
    status: 1,
    name: "Osmaniye",
  },
  {
    id: 23,
    status: 1,
    name: "Çankırı",
  },
  {
    id: 15,
    status: 1,
    name: "Bayburt",
  },
  {
    id: 79,
    status: 1,
    name: "Yalova",
  },
  {
    id: 73,
    status: 1,
    name: "Tekirdağ",
  },
  {
    id: 26,
    status: 1,
    name: "Diyarbakır",
  },
  {
    id: 71,
    status: 1,
    name: "Şanlıurfa",
  },
  {
    id: 45,
    status: 1,
    name: "Kars",
  },
  {
    id: 17,
    status: 1,
    name: "Bingöl",
  },
  {
    id: 57,
    status: 1,
    name: "Mardin",
  },
  {
    id: 31,
    status: 1,
    name: "Erzurum",
  },
  {
    id: 10,
    status: 1,
    name: "Artvin",
  },
  {
    id: 18,
    status: 1,
    name: "Bitlis",
  },
  {
    id: 2,
    status: 1,
    name: "Adıyaman",
  },
  {
    id: 78,
    status: 1,
    name: "Van",
  },
  {
    id: 68,
    status: 1,
    name: "Siirt",
  },
  {
    id: 38,
    status: 1,
    name: "Iğdır",
  },
  {
    id: 28,
    status: 1,
    name: "Edirne",
  },
  {
    id: 30,
    status: 1,
    name: "Erzincan",
  },
  {
    id: 51,
    status: 1,
    name: "Kilis",
  },
  {
    id: 76,
    status: 1,
    name: "Tunceli",
  },
  {
    id: 59,
    status: 1,
    name: "Muğla",
  },
  {
    id: 29,
    status: 1,
    name: "Elazığ",
  },
  {
    id: 4,
    status: 1,
    name: "Ağrı",
  },
  {
    id: 49,
    status: 1,
    name: "Kırklareli",
  },
  {
    id: 35,
    status: 1,
    name: "Gümüşhane",
  },
  {
    id: 9,
    status: 1,
    name: "Ardahan",
  },
];

export const SourceValues = [
  { id: 1, status: "Web" },
  { id: 2, status: "Mobil" },
  { id: 5, status: "Logo" },
  { id: 3, status: "Panel" },
  { id: 4, status: "Sistem" },
];

export const orderStatus = [
  { id: 0, status: "Sipariş Alındı", name: "order_taken" },
  { id: 1, status: "Hazırlanıyor", name: "preparing" },
  { id: 2, status: "Tamamlandı", name: "completed" },
  { id: 3, status: "Kısmi Sevk", name: "partial_sent" },
  { id: 4, status: "İptal", name: "cancelled" },
];

export const userStatus = [
  { id: 1, status: "Aktif" },
  { id: 0, status: "Pasif" },
  // { id: 3, status: "İstek Gönderildi" },
];

export const rolesArr = [
  { id: 1, status: "Admin" },
  { id: 2, status: "Seller" },
  { id: 3, status: "Firma Yetkili" },
  { id: 4, status: "Firma Kullanıcı" },
];
