import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "PriceBox";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  price: `${PREFIX}-price`,
  vatText: `${PREFIX}-vatText`,
};
const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: 140,
    height: 61,
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(),
    borderRadius: 6,
    userSelect: "none",
    "&[color-bg='gold']": {
      border: "1px solid #E9C46A",
    },
    "&[color-bg='orange']": {
      border: "1px solid #F4A261",
    },
    "&[color-bg='green']": {
      border: "1px solid #2A9D8F",
    },
    "&[component-from='fast-order']": {
      marginRight: 0,
    },
    "&[is-mobile='true']": {
      width: "32.5%",
    },
  },

  [`& .${classes.text}`]: {
    width: "100%",
    height: 20,
    textAlign: "center",
    color: "#fff",
    fontSize: 14,
    fontWeight: 400,
    "&[color-bg='gold']": {
      backgroundColor: "#E9C46A",
    },
    "&[color-bg='orange']": {
      backgroundColor: "#F4A261",
    },
    "&[color-bg='green']": {
      backgroundColor: "#2A9D8F",
    },
  },

  [`& .${classes.price}`]: {
    width: "100%",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    "& sup": {
      fontSize: 8,
      verticalAlign: "top",
      marginBottom: 7,
    },
  },

  [`& .${classes.vatText}`]: {
    width: '100%',
    textAlign: "center",
    color: theme.palette.colors.gray,
    fontSize: 12,
    lineHeight: '16px'
  },

}));

const PriceBox = ({ color, price, header, component, mobile, productType, VatText }) => {
  return (
    <Root
      className={classes.root}
      color-bg={color}
      component-from={component}
      is-mobile={mobile ? "true" : "false"}>
      <Typography className={classes.text} color-bg={color}>
        {header}
      </Typography>
      {productType === 3 ? (
        <Typography className={classes.price}>{price} mt/₺</Typography>
      ) : (
        <Typography className={classes.price}>
          {price} m<sup>2</sup>/₺
        </Typography>
      )}
       <Typography className={classes.vatText}>
        {VatText}
      </Typography>
    </Root>
  );
};

PriceBox.propTypes = {
  color: PropTypes.string,
  price: PropTypes.number,
  header: PropTypes.string,
  component: PropTypes.string,
  mobile: PropTypes.bool,
  productType: PropTypes.number,
  VatText: PropTypes.string
};

export default PriceBox;
