import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import PageContainer from "../Common/PageContainer";
import MyCart from "./MyCart";
import OrderSummary from "./OrderSummary";

import { productActions, addressActions } from "../../Redux/Actions";
import { addressService } from "../../Services";
import AddressSelection from "./Dialogs/AddressSelection";
import AddAddressDialog from "./Dialogs/AddAddressDialog";

const Root = styled("div")(() => ({
  width: "100%",
  marginBottom: 50,
  display: "flex",
  minWidth: 1212,
  overflow: "hidden",
}));

const CartPage = () => {
  const dispatch = useDispatch();

  const data = useSelector(({ product }) => product.cart.products);
  const cart = useSelector(({ product }) => product.cart);
  const customer = useSelector(({ customer }) => customer);

  const deleteSingleProduct = (userId, productId, key) => {
    if (customer?.isAdmin) {
      deleteAdminProduct(userId, productId, key);
    } else {
      deleteProduct(productId, key);
    }
  };

  const deleteAdminProduct = (userId, productId, key) =>
    dispatch(productActions.deleteAdminProduct(userId, productId, key));

  const deleteProduct = (id, key) =>
    dispatch(productActions.deleteProduct(id, key));

  const clearCart = () => dispatch(productActions.clearCart());

  const getAllAddresses = (query) =>
    dispatch(addressActions.getAddresses(query));

  const clearAdminCart = (userId) =>
    dispatch(productActions.clearAdminCart(userId));

  const toggleAddressId = (id) => dispatch(addressActions.setNewAddressId(id));

  const [callCompanyAddress, setCallCompanyAddress] = useState(0);
  const [showAddresSelection, setShowAddressSelection] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    city_id: null,
    county_id: null,
    first_name: "",
    last_name: "",
    phone1: "",
    phone2: "",
    title: "",
    address1: "",
    email: "",
  });

  const emptyCart = () => {
    if (cart.products.length > 0) {
      if (customer?.isAdmin) {
        clearAdminCart(customer?.selectedUser?.id);
      } else {
        clearCart();
      }
    } else {
      return;
    }
  };

  const openAddressSelectionModal = () => setShowAddressSelection(true);
  const closeAddressSelectionModal = () => setShowAddressSelection(false);

  const handleSubmit = () => {
    closeAddressSelectionModal();
  };

  const openAddAddressModal = () => setOpenAddAddress(true);
  const closeAddAddressModal = () => {
    setError(false);
    setOpenAddAddress(false);
  };

  const closeAddAddressFromButton = () => {
    closeAddAddressModal();
    setValues({
      ...values,
      city_id: "",
      county_id: "",
      first_name: "",
      last_name: "",
      phone1: "",
      phone2: "",
      title: "",
      address1: "",
      email: "",
    });
  };

  const getTruthyCondition = () => {
    const {
      first_name,
      last_name,
      phone1,
      title,
      address1,
      city_id,
      county_id,
    } = values;
    const regexp = /[a-zA-Z]/g;

    return (
      first_name === "" ||
      last_name === "" ||
      phone1.length !== 12 ||
      title === "" ||
      address1 === "" ||
      city_id === "" ||
      county_id === "" ||
      !regexp.test(last_name) ||
      !regexp.test(title) ||
      !regexp.test(address1) ||
      !regexp.test(city_id) ||
      !regexp.test(county_id)
    );
  };

  const handleError = () => {
    if (getTruthyCondition()) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleAddAddress = () => {
    let isError = handleError();
    if (isError) return;
    let addressQuery = {
      pageNumber: 1,
      pageSize: 200,
      sortField: "id",
      sordOrder: "desc",
      filter: {},
    };
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.phone1).replace(/\s/g, "");
    let newPhone2 =
      values.phone2.length !== 12
        ? null
        : phoneCode.concat(values.phone2).replace(/\s/g, "");

    let query = {
      city_id: values.city_id.id,
      county_id: values.county_id.id,
      type: 1,
      company_id: customer?.currentCompany.id,
      email: values.email,
      name: `${values.first_name} ${values.last_name}`,
      phone1: newPhone,
      phone2: newPhone2,
      address1: values.address1,
      address2: "",
      tax_office: "",
      tax_number: "",
      title: values.title,
      is_default: false,
      user_id: null,
    };

    let isAdmin = customer?.isAdmin;
    let userId = customer?.selectedUser?.id;

    let query_ = isAdmin ? { ...query, user_id: userId } : query;

    closeAddAddressFromButton();
    addressService.addAddress(query_, isAdmin).then((res) => {
      if (res.data.status === true) {
        toggleAddressId(res?.data?.affected_row?.id);
        if (customer?.isAdmin) {
          setCallCompanyAddress((prev) => prev + 1);
        } else getAllAddresses(addressQuery);
      }
    });
  };

  // const renderAddressSelection = useMemo(() => {
  //   return (
  //     <AddressSelection
  //       open={showAddresSelection}
  //       onClose={closeAddressSelectionModal}
  //       handleSubmit={handleSubmit}
  //       openAddAddressModal={openAddAddressModal}
  //       callCompanyAddress={callCompanyAddress}
  //     />
  //   );
  // }, [showAddresSelection, callCompanyAddress]);

  return (
    <PageContainer noScroll noFooter>
      <Root>
        <MyCart
          data={data}
          deleteProduct={deleteSingleProduct}
          clearCart={emptyCart}
        />
        <OrderSummary
          openAddressSelectionModal={openAddressSelectionModal}
          openAddAddressModal={openAddAddressModal}
        />
      </Root>
      <AddressSelection
        open={showAddresSelection}
        onClose={closeAddressSelectionModal}
        handleSubmit={handleSubmit}
        openAddAddressModal={openAddAddressModal}
        callCompanyAddress={callCompanyAddress}
      />
      <AddAddressDialog
        onClose={closeAddressSelectionModal}
        open={openAddAddress}
        closeDialogFromButton={closeAddAddressFromButton}
        values={values}
        handleChange={handleChange}
        handleSubmit={handleAddAddress}
        error={error}
        setError={setError}
        setValues={setValues}
      />
    </PageContainer>
  );
};

export default CartPage;
