import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import QuantitySelectionMob from "../../../Ui/Fields/QuantitySelectionMob";

const StyledGrid = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
  marginTop: theme.spacing(),
}));

const StockContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
  padding: "0px 8px",
  display: "flex",
  border: "1px solid rgba(231,231,231, 0.5)",
  borderRadius: 3,
  alignItems: "center",
}));

const StockText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "stock",
})(({ stock, theme }) => ({
  fontSize: 10,
  lineHeight: "16px",
  color: stock ? "#10C66C" : theme.palette.darkGray.main,
  fontWeight: 400,
  marginLeft: stock ? 5 : 0,
}));

// const StyledButton = styled(Button, {
//   shouldForwardProp: (prop) => prop !== "color",
// })(({ color }) => ({
//   width: "100%",
//   height: 28,
//   backgroundColor: color,
//   color: "#fff",
//   textTransform: "none",
//   fontSize: 10,
//   fontWeight: 400,
//   lineHeight: "16px",
//   padding: "6px 0px",
// }));

const ButtonsAndInput = ({
  handleChange,
  selections,
  data,
  // openFeaturesDialog,
  // featuresSelected,
  // disabled,
}) => {
  const isTypeThree = data?.type.id === 3
  // const featuresButtonColor =   
  // isTypeThree
  //     ? featuresSelected
  //       ? "#6E3564"
  //       : "#FB0505"
  //     : "#6E3564";

  const quantityText = isTypeThree ? 'Metre' : 'Adet'
  return (
    <StyledGrid>
      <div style={{ width: "30%", maxWidth: 90 }}>
        <QuantitySelectionMob
          handleChange={handleChange}
          param="quantity"
          selectedValue={selections?.quantity}
          stockInfo={parseInt(data?.stock.quantity)}
        />
      </div>
      <div style={{ width: "40%" }}>
        <StockContainer>
          <StockText>Merkez Depo:</StockText>
          <StockText stock>{`${data?.stock.quantity} ${quantityText}`}</StockText>
        </StockContainer>
      </div>
      <div style={{ width: "25%" }}>
        {/* <StyledButton
          onClick={openFeaturesDialog}
          disabled={disabled}
          disableRipple
          variant="contained"
          color={featuresButtonColor}>
          Özel İşlem
        </StyledButton> */}
      </div>
    </StyledGrid>
  );
};

ButtonsAndInput.propTypes = {
  handleChange: PropTypes.func,
  selections: PropTypes.object,
  data: PropTypes.object,
  openFeaturesDialog: PropTypes.func,
  featuresSelected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonsAndInput;
