import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const categorySubList = async (query) => {
  return await axios
    .post(`${API_URL}/category/sub/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const brandsList = async (query) => {
  return await axios
    .post(`${API_URL}/brand/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const productValueList = async (query) => {
  return await axios
    .post(
      `${API_URL}/product_attribute/value/list`,
      query,
      authCustomerHeader()
    )
    .then((res) => {
      return res;
    });
};

const productAttributeList = async (query) => {
  return await axios
    .post(`${API_URL}/product_attribute/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const filterService = {
  categorySubList,
  brandsList,
  productValueList,
  productAttributeList,
};
