import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const StyledDiv = styled("div")(() => ({
  width: "100%",
  height: 35,
  display: "flex",
  position: "relative",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: theme.palette.secondary.main,
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 5,
  width: 20,
  height: 20,
}));

const MobileDialogHeaderClose = ({ text, callBack }) => {
  return (
    <StyledDiv>
      <StyledTypography>{text}</StyledTypography>
      <StyledIconButton onClick={callBack}>
        <CloseIcon />
      </StyledIconButton>
    </StyledDiv>
  );
};

MobileDialogHeaderClose.propTypes = {
  text: PropTypes.string,
  callBack: PropTypes.func,
};

export default MobileDialogHeaderClose;
