import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import profileIcon from "../../Images/TopNavbar/profile.svg";
import mailIcon from "../../Images/Cart/Mail.svg";
import navigationIcon from "../../Images/DealerProfile/Navigation.svg";
import phoneIcon from "../../Images/DealerProfile/Phone.svg";
import lockIcon from "../../Images/DealerProfile/Lock.svg";

import PopoverRow from "../../Ui/Popovers/ProfilePopover/PopoverRow";
import MobileDialogHeader from "../../Ui/Dialog/Tools/MobileDialogHeader";
import UpdatePasswordMob from "./MobileDialogs/UpdatePasswordMob";
import UserInfoMob from "./MobileDialogs/UserInfoMob";
import UpdatePhoneMob from "./MobileDialogs/UpdatePhoneMob";
import UpdateEmailMob from "./MobileDialogs/UpdateEmailMob";
import AdminAddressSelection from "../../Ui/Dialog/Mobile/AdminAddressSelection";
import UserAddressChangeMob from "./MobileDialogs/UserAddressChangeMob";

import { bottomNavItems } from "../../Utils/Constants";

import { bottomNavbarActions } from "../../Redux/Actions";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: "100vh",
  backgroundColor: "#fff",
  padding: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  position: "relative",
  userSelect: "none",
  overflow: "hidden",
}));

const DealerProfileMob = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  const customer = useSelector(({ customer }) => customer);

  const [userInfoModal, setUserInfoModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [gsmModal, setGsmModal] = useState(false);
  const [passswordModal, setPasswordModal] = useState(false);
  const [openAdminFirmModal, setOpenAdminFirmModal] = useState(false);
  const [openChangeAddress, setOpenChangeAddress] = useState(false);
  const [menuList, setMenuList] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu(bottomNavItems.PROFILE);
      let isCompanyAdmin =
        customer?.selectedUser?.role_names[0] === "companyadmin";
      let filterArr = ["Adresler"];
      if (isCompanyAdmin) {
        let newArr = profileButtonsArr.filter(
          (item) => !filterArr.includes(item.text)
        );
        setMenuList(newArr);
      } else {
        setMenuList(profileButtonsArr);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToAddresses = () => history.push("/addresses");

  const openUserInfoModal = () => setUserInfoModal(true);
  const closeUserInfoModal = () => setUserInfoModal(false);

  const openEmailModal = () => setEmailModal(true);
  const closeEmailModal = () => setEmailModal(false);

  const openGsmModal = () => setGsmModal(true);
  const closeGsmModal = () => setGsmModal(false);

  const openPasswordModal = () => setPasswordModal(true);
  const closePasswordModal = () => setPasswordModal(false);

  const toggleAdminFirmDialog = () => setOpenAdminFirmModal((prev) => !prev);

  const toggleChangeAddress = () => setOpenChangeAddress((prev) => !prev);

  const goBack = () => history.goBack();

  const profileButtonsArr = [
    {
      icon: navigationIcon,
      text: "Adresler",
      func: goToAddresses,
      isLast: "false",
      isFirst: "true",
    },
    {
      icon: profileIcon,
      text: "Kullanıcı Bilgileri",
      func: openUserInfoModal,
      isLast: "false",
      isFirst: "false",
    },
    {
      icon: profileIcon,
      text: customer?.isAdmin ? "Firma Bilgileri" : "Adres Bilgileri",
      func: customer?.isAdmin ? toggleAdminFirmDialog : toggleChangeAddress,
      isLast: "false",
      isFirst: "false",
    },
    {
      icon: mailIcon,
      text: "E-Posta Değiştir",
      func: openEmailModal,
      isLast: "false",
      isFirst: "false",
    },
    {
      icon: phoneIcon,
      text: "Gsm No Değiştir",
      func: openGsmModal,
      isLast: "false",
      isFirst: "false",
    },
    {
      icon: lockIcon,
      text: "Şifre Değiştir",
      func: openPasswordModal,
      isLast: "false",
      isFirst: "false",
    },
  ];

  return (
    <StyledContainer>
      <MobileDialogHeader header="Profil Ayarları" callback={goBack} />
      {menuList?.map((item, index) => (
        <PopoverRow
          key={index}
          icon={item.icon}
          text={item.text}
          func={item.func}
          isLast={item.isLast}
          isFirst={item.isFirst}
          mobile
        />
      ))}
      <UpdatePasswordMob
        open={passswordModal}
        handleClose={closePasswordModal}
      />
      <AdminAddressSelection
        open={openAdminFirmModal}
        callBack={toggleAdminFirmDialog}
        handleClose={toggleAdminFirmDialog}
      />
      <UserInfoMob open={userInfoModal} onClose={closeUserInfoModal} />
      <UpdatePhoneMob open={gsmModal} handleClose={closeGsmModal} />
      <UpdateEmailMob open={emailModal} handleClose={closeEmailModal} />
      <UserAddressChangeMob
        open={openChangeAddress}
        onClose={toggleChangeAddress}
      />
    </StyledContainer>
  );
};

export default DealerProfileMob;
