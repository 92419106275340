import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  customLowerCase,
  customUpperCase,
} from "../../../Utils/Helpers/CustomLetterConversion";

import grayCheckbox from "../../../Images/Filter/GrayCheckbox.svg";
import starIcon from "../../../Images/Filter/Star.svg";
import Checked from "../../../Images/Checked.svg";

import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";

const Root = styled("div")(({theme}) => ({
  width: "100%",
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  paddingBottom: 10,
  "&[section-name='Markalar']": {
    maxHeight: 120,
  },
  "&[section-name='Kategoriler']": {
    maxHeight: 375,
  },
  "&[section-name='Ebatlar']": {
    maxHeight: 375,
  },
  "&[section-name='Şekil']": {
    maxHeight: 170,
  },
  "&[section-name='Renkler']": {
    maxHeight: 195,
  },
  "&[section-name='Değerlendirme Puanı']": {
    maxHeight: 358,
    borderBottom: "none",
  },
}));

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "searchable",
})(({ searchable }) => ({
  width: "100%",
  paddingBottom: 11,
  overflowY: "scroll",
  paddingLeft: 12,
  "&[section-name='Markalar']": {
    maxHeight: searchable ? 89 : 119,
  },
  "&[section-name='Kategoriler']": {
    maxHeight: searchable ? 304 : 334,
  },
  "&[section-name='Ebatlar']": {
    maxHeight: searchable ? 304 : 334,
  },
  "&[section-name='Şekil']": {
    maxHeight: searchable ? 105 : 135,
  },
  "&[section-name='Renkler']": {
    maxHeight: searchable ? 125 : 155,
  },
  "&[section-name='Değerlendirme Puanı']": {
    maxHeight: 338,
  },
}));

const GroupTitle = styled(Typography)(({theme}) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.darkGray.main,
  margin: "5px 0px 12px 14px",
}));

const StyledLabel = styled(Typography)(({theme}) => ({
  fontSize: 14,
  fontWeight: 300,
  color: theme.palette.darkGray.main,
  marginLeft: 5,
}));

const StyledLabelRating = styled(Typography)(({theme}) => ({
  fontSize: 12,
  fontWeight: 300,
  color: theme.palette.darkGray.main,
  marginLeft: 5,
  marginTop: 3,
}));

const StyledTextField = styled(TextField)(() => ({
  width: "88%",
  height: 25,
  margin: "-6px 0px 13px 14px",
  "& .MuiInputBase-input": {
    borderRadius: 8,
    padding: "3.5px 8px",
    border: "1px solid rgba(177, 174, 174, 1)",
    fontSize: 14,
    fontWeight: 300,
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "#fff",
      border: "1px solid rgba(177, 174, 174, 1)",
    },
  },
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 5,
  color: "rgba(189, 189, 189, 1)",
  cursor: "pointer",
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 30,
  cursor: "pointer",
  zIndex: 9999,
}));

const minAmounts = {
  brands: 6,
  categories: 15,
  sizes: 15,
  colors: 9,
  shapes: 6,
  rating: 10,
};

const Section = ({
  header,
  rating,
  last,
  handleChange,
  value,
  filter,
  items,
  disabled,
}) => {
  const filters = useSelector(({ customer }) => customer.filters);

  const [searchText, setSearchText] = useState("");
  const [keyword, setKeyword] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (searchText !== "" && items?.length >= minAmounts[value]) {
      let customLower = customLowerCase(searchText);
      let customUpper = customUpperCase(searchText);

      let customInitialUpper =
        customUpperCase(searchText.charAt(0)) + searchText.slice(1);

      let customInitialLower =
        customLowerCase(searchText.charAt(0)) + searchText.slice(1);

      let initialUpper = customLower.replace(
        /(^\w{1})|(\s{1}\w{1})/g,
        (match) => customUpperCase(match)
      );

      let initialUpperAll =
        customUpperCase(customLower.charAt(0)) + customLower.slice(1);

      let newProductsList = items.filter(
        (product) =>
          product.name.includes(searchText) ||
          product.name.includes(customInitialLower) ||
          product.name.includes(customInitialUpper) ||
          product.name.includes(customLower) ||
          product.name.includes(customUpper) ||
          product.name.includes(initialUpperAll) ||
          product.name.includes(initialUpper)
      );

      setProducts(newProductsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearchText = (text) => setSearchText(text);
  const clearSearchText = () => {
    setSearchText("");
    setKeyword("");
  };
  const handleKeyword = (e) => setKeyword(e.target.value);

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchText(e.target.value);
    }
  };

  const label = (name) =>
    rating ? (
      <div style={{ display: "flex" }}>
        <img
          src={starIcon}
          width="20px"
          height="20px"
          alt="Star Icon"
          style={{ marginLeft: 0 }}
        />
        <StyledLabelRating>{name}</StyledLabelRating>
      </div>
    ) : (
      <StyledLabel>{name}</StyledLabel>
    );

  let boxValues = searchText !== "" ? products : items;

  return (
    <Root section-name={header}>
      <GroupTitle variant="subtitle1">{header}</GroupTitle>
      {items?.length >= minAmounts[value] && (
        <StyledTextField
          fullWidth
          variant="standard"
          value={keyword}
          onChange={handleKeyword}
          onKeyDown={keyPress}
          disabled={disabled}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {searchText.length > 0 && (
                  <CloseAdornment position="end" onClick={clearSearchText}>
                    <Fade in={searchText.length > 0}>
                      <CloseIcon style={{ fill: "#8E8E8E" }} />
                    </Fade>
                  </CloseAdornment>
                )}
                <StyledAdornment
                  position="end"
                  onClick={() => handleSearchText(keyword)}>
                  <SearchIcon />
                </StyledAdornment>
              </>
            ),
          }}
        />
      )}
      <StyledFormControl
        component="fieldset"
        section-name={header}
        data-position={last && "last"}
        searchable={items?.length >= minAmounts[value]}>
        <FormGroup>
          {boxValues?.map((item, index) => (
            <FormControlLabel
              style={{ marginBottom: 4 }}
              key={index}
              control={
                <Checkbox
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    margin: "0px 0px 0px 14px",
                    "&:hover, &.Mui-focusVisible": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                  disableRipple
                  disabled={disabled}
                  checked={filters[value].some((obj) => obj.id === item.id)}
                  onChange={handleChange(value)}
                  id={item.id.toString()}
                  name={item.name}
                  value={filter}
                  icon={<img src={grayCheckbox} width="20px" alt="" />}
                  checkedIcon={<img src={Checked} width="20" alt="" />}
                />
              }
              label={label(item.name)}
            />
          ))}
        </FormGroup>
      </StyledFormControl>
    </Root>
  );
};

Section.propTypes = {
  header: PropTypes.string,
  rating: PropTypes.bool,
  last: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  filter: PropTypes.string,
  items: PropTypes.array,
  disabled: PropTypes.bool,
};

export default Section;
