import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import GrayCheckbox from "../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../Images/Checked.svg";

import SectionContainer from "../Common/SectionContainer";
import ConfirmationDialog from "../NewDialogs/ConfirmationDialog";

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(),
}));

const Header = styled(Typography)(({ theme }) => ({
  height: "auto",
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "30px",
  color: theme.palette.darkGray.main,
}));

const StyledButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 6,
  height: 30,
  width: 150,
  color: "#fff",
  backgroundColor: "#6A6868",
  paddingLeft: 0,
  paddingRight: 0,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6A6868",
  },
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "auto",
  marginBottom: theme.spacing(),
  paddingBottom: theme.spacing(),
  paddingTop: theme.spacing(),
  borderBottom: "1px solid rgba(127, 91, 120, 0.5)",
}));

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing(),
  paddingTop: theme.spacing(),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "light",
})(({ light, theme }) => ({
  width: "fit-content",
  height: "auto",
  fontSize: 14,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  fontWeight: light ? 300 : 700,
  marginTop: !light && theme.spacing(),
}));

const EmailPreferences = () => {
  const [orders, setOrders] = useState(false);
  const [payments, setPayments] = useState(false);
  const [services, setServices] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleDialog = () => setOpen(!open);

  const handleSubmit = () => {
    toggleDialog();
    let query = {
      orders,
      payments,
      services,
    };
    console.log(query);
  };

  return (
    <SectionContainer>
      <HeaderContainer>
        <Header>Eposta Tercihleri</Header>
        <StyledButton onClick={toggleDialog}>
          Değişiklikleri Kaydet
        </StyledButton>
      </HeaderContainer>
      <Row>
        <Checkbox
          icon={<img src={GrayCheckbox} alt="" />}
          checkedIcon={<img src={Checked} width="20" alt="" />}
          checked={orders}
          onChange={() => setOrders(!orders)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <TextContainer>
          <Text>Başarılı siparişler</Text>
          <Text light>Her başarılı sipariş için bildirim alın.</Text>
        </TextContainer>
      </Row>
      <Row>
        <Checkbox
          icon={<img src={GrayCheckbox} alt="" />}
          checkedIcon={<img src={Checked} width="20" alt="" />}
          checked={payments}
          onChange={() => setPayments(!payments)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <TextContainer>
          <Text>Başarılı ödemeler</Text>
          <Text light>Her başarılı ödeme için bildirim alın.</Text>
        </TextContainer>
      </Row>
      <Row>
        <Checkbox
          icon={<img src={GrayCheckbox} alt="" />}
          checkedIcon={<img src={Checked} width="20" alt="" />}
          checked={services}
          onChange={() => setServices(!services)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <TextContainer>
          <Text>İade ve servis kayıtları</Text>
          <Text light>İade ve servis kayıtları için bildirim alın.</Text>
        </TextContainer>
      </Row>
      <ConfirmationDialog
        open={open}
        decline={toggleDialog}
        accept={handleSubmit}
      />
    </SectionContainer>
  );
};

export default EmailPreferences;
