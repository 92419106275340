import { combineReducers } from "redux";

import { customer } from "./CustomerReducer";
import { auth } from "./AuthReducer";
import { filterValues } from "./FilterReducer";
import { product } from "./ProductReducer";
import { addresses } from "./AddressReducers";
import { orders } from "./OrderReducer";
import { bottomNavbarValue } from "./BottomNavbarReducer";
import { favorites } from "./FavoriteReducer";
import { notifications } from "./NotificationReducer";
import { campaigns } from "./CampaignReducer";

const rootReducer = combineReducers({
  customer,
  auth,
  filterValues,
  product,
  addresses,
  orders,
  bottomNavbarValue,
  favorites,
  notifications,
  campaigns,
});

export default rootReducer;
