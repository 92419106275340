import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import NotFound from "../../../Images/Search/NotFound.svg";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 220,
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

const Text = styled(Typography)(({ theme }) => ({
  width: "100%",
  height: "auto",
  textAlign: "center",
  fontWeight: 400,
  fontSize: 10,
  lineHeight: "16px",
  color: theme.palette.secondary.main,
  position: "absolute",
  bottom: theme.spacing(11),
  left: 0,
}));

const StyledImage = styled("img")(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: "20%",
  transform: "translate(-50%, -50%)",
}));

const NoResult = () => {
  return (
    <Root>
      <StyledImage src={NotFound} width="50" height="40" alt="" />
      <Text>
        Aradığınız ürün bulunamadı fakat aşağıdaki ürünlere göz atabilirsiniz.
      </Text>
    </Root>
  );
};

export default NoResult;
