import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
  borderRadius: 6,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
}));

const Row = styled("div", { shouldForwardProp: (prop) => prop !== "last" })(
  ({ theme, last }) => ({
    display: "flex",
    marginBottom: !last && theme.spacing(),
  })
);

const RowText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "bold" && prop !== "initial",
})(({ bold, initial, theme }) => ({
  width: initial ? 150 : "fit-content",
  lineHeight: "20px",
  fontSize: 14,
  fontWeight: bold ? 700 : 400,
  color: theme.palette.darkGray.main,
}));

const MainCard = ({ data }) => {
  return (
    <Root>
      <Row>
        <RowText initial bold>
          {data?.key}
        </RowText>
        <RowText bold>{data?.date}</RowText>
      </Row>
      <Row>
        <RowText initial>{data?.buyer?.user?.full_name}</RowText>
        <RowText>{data?.address?.name}</RowText>
      </Row>
      <RowText>{data?.note}</RowText>
    </Root>
  );
};

MainCard.propTypes = {
  data: PropTypes.object,
};

export default MainCard;
