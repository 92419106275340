import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";

import grayCheckbox from "../../../../../Images/Filter/GrayCheckbox.svg";
import purpleRect from "../../../../../Images/UsersAndRoles/PurpleRect.svg";
import expandUp from "../../../../../Images/Orders/ExpandUp.svg";
import expandDown from "../../../../../Images/Orders/ExpandDown.svg";
import Checked from "../../../../../Images/Checked.svg";

const PREFIX = "SubSectionMain2";

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  iconButton: `${PREFIX}-iconButton`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
    paddingLeft: 30,
    minHeight: 38,
    height: "auto",
    justifyContent: "space-between",
  },

  [`& .${classes.formControl}`]: {
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      marginLeft: 5,
    },
  },

  [`& .${classes.iconButton}`]: {
    height: 30,
    width: 30,
    marginRight: 20,
  },
}));

const indeterminate = "indeterminate";

const SubSectionMain2 = ({
  sub2Checked,
  handleSub2Check,
  checkBoxSections,
  extended2,
  setExtended2,
}) => {
  return (
    <Root className={classes.root} sub-section="true">
      <FormControl component="fieldset" className={classes.formControl}>
        <FormControlLabel
          style={{ marginBottom: 4 }}
          control={
            <Checkbox
              className={classes.check}
              disableRipple
              checked={sub2Checked === true}
              indeterminate={sub2Checked === indeterminate}
              onChange={handleSub2Check}
              id={checkBoxSections.subSection2.subSectionLabel}
              name={checkBoxSections.subSection2.subSectionLabel}
              value={sub2Checked}
              icon={<img src={grayCheckbox} width="20px" alt="" />}
              indeterminateIcon={<img src={purpleRect} width="20px" alt="" />}
              checkedIcon={<img src={Checked} width="20" alt="" />}
            />
          }
          label={checkBoxSections.subSection2.subSectionLabel}
        />
      </FormControl>
      <IconButton
        className={classes.iconButton}
        onClick={() => setExtended2(!extended2)}
        size="large">
        {extended2 ? (
          <img src={expandUp} width="20px" alt="" />
        ) : (
          <img src={expandDown} width="20px" alt="" />
        )}
      </IconButton>
    </Root>
  );
};

SubSectionMain2.propTypes = {
  sub2Checked: PropTypes.any,
  handleSub2Check: PropTypes.func,
  checkBoxSections: PropTypes.object,
  extended2: PropTypes.bool,
  setExtended2: PropTypes.func,
};

export default SubSectionMain2;
