import React from "react";

import { styled } from "@mui/material/styles";

import ozsafirLogo from "../../../Images/FooterImages/ozsafir.svg";
import entiLogo from "../../../Images/FooterImages/enti-hali.svg";
import romansLogo from "../../../Images/FooterImages/romans.svg";
import dekorentiLogo from "../../../Images/FooterImages/dekor-enti.svg";
import { useCurrentWidth } from "../../../Utils/Hooks";

const PREFIX = "Brands";

const classes = {
  brand: `${PREFIX}-brand`,
  brandContainer: `${PREFIX}-brandContainer`,
  gridContainer: `${PREFIX}-gridContainer`,
};

const Root = styled("div")(() => ({
  [`&.${classes.brand}`]: {
    width: "100%",
    height: 75,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000",
  },

  [`& .${classes.gridContainer}`]: {
    height: 68,
    width: 1232,
    borderBottom: "1px solid #fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000",
  },

  [`& .${classes.brandContainer}`]: {
    width: 540,
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

}));

const Brands = () => {
  let currentWidth = useCurrentWidth();

  let diff =
    currentWidth <= 950 && currentWidth >= 550 && (currentWidth - 550) * 0.075;

  let decrementVal = currentWidth <= 950 ? 70 + diff : 100;

  return (
    <Root className={classes.brand}>
      <div className={classes.gridContainer}>
      <div
        className={classes.brandContainer}
        style={{
          width: currentWidth <= 950 && 795 * ((decrementVal - 10) / 100),
        }}>
        <img
          src={ozsafirLogo}
          width={98 * (decrementVal / 100)}
          height={33 * (decrementVal / 100)}
          alt="ozsafir logo"
        />
        <img
          src={entiLogo}
          width={85 * (decrementVal / 100)}
          height={23 * (decrementVal / 100)}
          alt="enti logo"
        />
        <img
          src={romansLogo}
          width={109 * (decrementVal / 100)}
          height={24 * (decrementVal / 100)}
          alt="romans logo"
        />
        <img
          src={dekorentiLogo}
          width={121.11 * (decrementVal / 100)}
          height={20 * (decrementVal / 100)}
          alt="dekorenti logo"
        />        
      </div>
      </div>
    </Root>
  );
};

export default React.memo(Brands);
