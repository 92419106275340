import * as React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";

const ColorlibConnector = styled(StepConnector, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: isActive ? "#5ABD8C" : "#F5B041",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#5ABD8C",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#B1AEAE",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "isCanceled" && prop !== "ownerState",
})(({ theme, ownerState, isCanceled }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 16,
  height: 16,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    backgroundColor: isCanceled ? "red" : "#F5B041",
  }),
  ...(ownerState.completed && {
    backgroundColor: isCanceled ? "red" : "#5ABD8C",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Check style={{ fontSize: 12 }} />,
    2: <Check style={{ fontSize: 12 }} />,
    3: <Check style={{ fontSize: 12 }} />,
  };

  return (
    <ColorlibStepIconRoot
      isCanceled={props.isCanceled}
      ownerState={{ completed, active }}
      className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Sipariş Alındı", "Hazırlanıyor", "Hazırlandı"];

const stepsCancel = ["Sipariş Alındı", "İptal"];

const StyledLabel = styled(StepLabel)(() => ({
  "& .css-epxx27-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
    fontSize: 9,
    marginTop: 8,
  },
}));

const StyledStepper = styled(Stepper)(() => ({
  "& .css-n7tliy-MuiStep-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

// const ButtonContainer = styled("div")(({ theme }) => ({
//   display: "flex",
//   justifyContent: "flex-end",
//   width: "100%",
//   height: "auto",
//   marginTop: theme.spacing(),
// }));

// const ShipmentButton = styled(Button)(({ theme }) => ({
//   textTransform: "none",
//   width: 130,
//   height: 24,
//   backgroundColor: theme.palette.primary.main,
//   "&:hover": {
//     backgroundColor: theme.palette.primary.main,
//   },
// }));

const HorizontalStepper = ({ status }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "auto",
      }}>
      {status !== 3 ? (
        <StyledStepper
          alternativeLabel
          activeStep={status + 1}
          connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StyledLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StyledLabel>
            </Step>
          ))}
        </StyledStepper>
      ) : (
        <StyledStepper
          alternativeLabel
          activeStep={status}
          connector={<ColorlibConnector />}>
          {stepsCancel.map((label) => (
            <Step key={label}>
              <StyledLabel
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{
                  isCanceled: label === "İptal" ? true : false,
                }}>
                {label}
              </StyledLabel>
            </Step>
          ))}
        </StyledStepper>
      )}
      {/* {status - 1 > 2 && (
        <ButtonContainer>
          <ShipmentButton variant="contained" disableRipple>
            Kargo Takip
          </ShipmentButton>
        </ButtonContainer>
      )} */}
    </Box>
  );
};

HorizontalStepper.propTypes = {
  status: PropTypes.number,
};

export default HorizontalStepper;
