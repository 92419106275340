import React, { useState, useMemo, useRef } from "react";

import { styled } from '@mui/material/styles';

import PageContainer from "../Common/PageContainer";
import TabButtons from "./Main/TabButtons";
import Services from "./Main/Services";
import Returns from "./Main/Returns";

const PREFIX = 'ServicesAndReturns';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    minHeight: 600,
    marginBottom: 30,
  }
}));

const servicesData = [
  {
    register_no: "1234567",
    create_date: "10.10.2021 13.00",
    name: "Kasım Karaboğa",
    product: "160*230 Klasik 1192 Krem",
    explanation: "Şube teslim ve adrese teslim olabilir",
    address: "İvedik Şube",
    state: "Red edildi",
  },
  {
    register_no: "1234567",
    create_date: "10.10.2021 13.00",
    name: "Dinçer Kazmacı",
    product: "160*230 Klasik 1192 Krem",
    explanation: "Şube teslim ve adrese teslim olabilir",
    address: "İvedik Şube",
    state: "İnceleniyor",
  },
  {
    register_no: "1234567",
    create_date: "10.10.2021 13.00",
    name: "Yaşar Özyurt",
    product: "160*230 Klasik 1192 Krem",
    explanation: "Şube teslim ve adrese teslim olabilir",
    address: "İvedik Şube",
    state: "Kabul edildi",
  },
];

const ServicesAndReturns = () => {

  const modalRef = useRef();
  const returnRef = useRef();

  const [tabIndex, setIndex] = useState(1);

  const openAddService = () => {
    console.log("clicked");
    modalRef.current.showAddService();
  };

  const openAddReturn = () => {
    returnRef.current.showAddReturn();
  };

  const renderServices = useMemo(() => {
    return <Services data={servicesData} modalRef={modalRef} />;
  }, [modalRef]);

  const renderReturns = useMemo(() => {
    return <Returns data={servicesData} returnRef={returnRef} />;
  }, [returnRef]);

  return (
    <PageContainer>
      <Root className={classes.root}>
        <TabButtons
          index={tabIndex}
          setIndex={setIndex}
          openAddService={openAddService}
          openAddReturn={openAddReturn}
        />
        {tabIndex === 1 ? renderServices : renderReturns}
      </Root>
    </PageContainer>
  );
};

export default ServicesAndReturns;
