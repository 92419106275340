import React from "react";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

import { ordersHeaderValues } from "../../../Utils/Constants";

const Root = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(),
  minWidth: 900,
  height: 44,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(),
  borderRadius: 8,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
}));

const Field = styled("div", { shouldForwardProp: (prop) => prop !== "width" })(
  ({ theme, width }) => ({
    width: width,
    marginRight: theme.spacing(),
  })
);

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "normal",
  color: theme.palette.darkGray.main,
  textAlign: "left",
  "&[img-field='true']": {
    paddingLeft: theme.spacing(2),
  },
}));

const TableHeader = () => {
  return (
    <Root>
      {ordersHeaderValues.map((item, index) => (
        <Field key={index} width={item.width}>
          <Text img-field={item.imgField ? "true" : "false"}>{item.name}</Text>
        </Field>
      ))}
    </Root>
  );
};

export default React.memo(TableHeader);
