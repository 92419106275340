import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const TableCommon = {
  border: "1px solid #BDBDBD",
  width: "33%",
  fontSize: 12,
  lineHeight: "18px",
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: 414,
    height: "auto",
    padding: 0,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  width: "100%",
  height: 30,
  padding: 0,
  display: "flex",
  justifyContent: "center",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(3),
  position: "absolute",
  color: theme.palette.grey[500],
  right: "0",
  top: "0",
  borderRadius: "0 0 0 100%",
  "& svg": {
    fontSize: "24px",
  },
}));

const StyledSvg = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(),
  right: theme.spacing(),
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  lineHeight: "28px",
  color: theme.palette.darkGray.main,
  marginTop: theme.spacing(),
  userSelect: "none",
}));

const Info = styled(Typography)(({ theme }) => ({
  width: "100%",
  height: "auto",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  textAlign: "center",
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const InfoList = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: 8,
  backgroundColor: "#FBEEF8",
  padding: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const StyledList = styled("ul")(({ theme }) => ({
  margin: 0,
  paddingInlineStart: theme.spacing(3),
}));

const StyledListItem = styled("li")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "19px",
  color: theme.palette.primary.main,
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  width: "100%",
  height: "100%",
  paddingTop: 0,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: 30,
  textTransform: "none",
  color: "#fff",
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 6,
  fontSize: 14,
  fontWeight: 400,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(),
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  marginTop: theme.spacing(),
}));

const StyledTh = styled("th")(({ theme }) => ({
  textAlign: "center",
  ...TableCommon,
  fontWeight: 700,
  backgroundColor: theme.palette.darkGray.light,
  height: 23,
  color: theme.palette.darkGray.main
}));

const StyledTd = styled("td", {
  shouldForwardProp: (prop) => prop !== "center",
})(({ theme, center }) => ({
  textAlign: center ? "center" : "left",
  ...TableCommon,
  fontWeight: 400,
  height: 30,
  paddingLeft: !center && theme.spacing(),
  color: theme.palette.darkGray.main
}));

const LotDialog = ({ open, onClose, name, data }) => {
  return (
    <StyledDialog open={open} onClose={onClose} keepMounted={false}>
      <StyledDialogTitle>
        <StyledIconButton aria-label="Close" onClick={onClose} size="large">
          <StyledSvg />
        </StyledIconButton>
        <StyledHeader>{name}</StyledHeader>
      </StyledDialogTitle>
      <StyledContent>
        <Info>Depodaki ruloların mevcut miktarları</Info>
        <InfoList>
          <StyledList>
            <StyledListItem>
              Kesme ürünlerde stok miktarı, depodaki tüm ruloların toplamını
              göstermektedir.
            </StyledListItem>
            <StyledListItem>
              Sipariş vermeden önce rulo miktar detaylarını kontrol ediniz.
            </StyledListItem>
          </StyledList>
        </InfoList>
        {data?.length === 0 ? (
          <Info>Lot Bilgisi Bulunamadı!</Info>
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <StyledTh>Rulo</StyledTh>
                <StyledTh>Miktar</StyledTh>
                <StyledTh>Birim</StyledTh>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <StyledTd>{item.name}</StyledTd>
                  <StyledTd center>{item.quantity}</StyledTd>
                  <StyledTd center>MT</StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
        <StyledButton onClick={onClose} variant="contained" disableRipple>
          Kapat
        </StyledButton>
      </StyledContent>
    </StyledDialog>
  );
};

LotDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.array,
};

export default LotDialog;
