import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import PaperComponent from "./PaperComponent";

const PREFIX = "DraggableModal";

const classes = {
  paper: `${PREFIX}-paper`,
  profile: `${PREFIX}-profile`,
  sub: `${PREFIX}-sub`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.paper}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 0,
    borderRadius: 8,
    "&[dialog-type='profile']": {
      width: 339,
      minHeight: 442,
    },
    "&[dialog-type='subs']": {
      width: 326,
      height: 362,
    },
  },

  [`& .${classes.profile}`]: {
    "& .MuiDialog-scrollPaper": {
      display: "inline-block",
      position: "absolute",
      right: width > 1280 ? value : "0%",
      top: 50,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.sub}`]: {
    "& .MuiDialog-scrollPaper": {
      display: "inline-block",
      position: "absolute",
      right: width > 1280 ? valueSub : 400,
      top: 50,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
}));

const width = window.innerWidth;
const value = (width - 1280) * 0.5;
const valueSub = value + 400;

const DraggableModal = ({ open, title, content }) => {
  return (
    <StyledDialog
      // hideBackdrop
      disableEnforceFocus
      disableScrollLock
      disableRestoreFocus
      // disableBackdropClick
      open={open}
      // onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      id="draggable-dialog"
      classes={{
        paper: classes.paper,
      }}
      className={classes.sub}>
      <DialogTitle style={{ padding: 0 }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: 0 }} id="draggable-dialog-content">
        {content}
      </DialogContent>
    </StyledDialog>
  );
};

DraggableModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.object,
  content: PropTypes.object,
};

export default DraggableModal;
