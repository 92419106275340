import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

let queryAll = {
  filter: {
    status: 1,
  },
  pageNumber: 1,
  pageSize: 1000,
  sortField: "id",
  sortOrder: "desc",
};

const getRoles = async () => {
  return await axios
    .post(`${API_URL}/role/list`, queryAll, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const rolesService = {
  getRoles,
};
