import { bottomNavItems } from "../../Utils/Constants";

import LayersGray from "../../Images/FooterImages/LayersGray.svg";
import LayersPurple from "../../Images/FooterImages/LayersPurple.svg";
import SearchGray from "../../Images/FooterImages/SearchGray.svg";
import SearchPurple from "../../Images/FooterImages/SearchPurple.svg";
import GiftBoxGray from "../../Images/FooterImages/GiftBoxGray.svg";
import GiftBoxPurple from "../../Images/FooterImages/GiftBoxPurple.svg";
import OzsafirSoloGrey from "../../Images/TopNavbar/OzsafirSoloGrey.svg";
import OzsafirSolo from "../../Images/TopNavbar/OzsafirSolo.svg";
import Menu from "../../Images/FooterImages/Menu.svg";
import MenuPurple from "../../Images/FooterImages/MenuPurple.svg";

const tabBarMenu = [
  {
    value: bottomNavItems.MAIN,
    iconActive: OzsafirSolo,
    icon: OzsafirSoloGrey,
    path: "home",
  },
  {
    value: bottomNavItems.CATEGORIES,
    iconActive: LayersPurple,
    icon: LayersGray,
    path: "categories",
  },
  {
    value: bottomNavItems.SEARCH,
    iconActive: SearchPurple,
    icon: SearchGray,
    path: "mobile-search",
  },
  {
    value: bottomNavItems.ORDERS,
    iconActive: GiftBoxPurple,
    icon: GiftBoxGray,
    path: "order-page",
  },
  {
    value: bottomNavItems.MENU,
    iconActive: MenuPurple,
    icon: Menu,
    path: "menu",
  },
];

export { tabBarMenu };
