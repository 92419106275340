import React, { useRef } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(),
}));

const Name = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.secondary.main,
  marginRight: theme.spacing(5),
  cursor: "pointer",
}));

const Field = styled("div")(({ theme }) => ({
  width: 208,
  height: 42,
  display: "flex",
  paddingLeft: theme.spacing(2.5),
  backgroundColor: "#F5F5F5",
  borderRadius: 8,
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 90,
  height: 30.48,
  "& .MuiInputBase-input": {
    height: 23,
    paddingTop: theme.spacing(0.5),
    fontSize: 34,
    fontWeight: 700,
    backgroundColor: "white",
    borderRadius: 8,
    paddingLeft: theme.spacing(0.5),
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "white",
    },
  },
  "& .MuiInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
  },
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "id",
})(({ theme, id }) => ({
  visibility: id === 2 && "hidden",
  width: 200,
  height: 42,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontSize: 16,
  textTransform: "none",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const InputField = ({
  features,
  handleFeatures,
  productType,
  toggleStockDialog,
}) => {
  const widthRef = useRef();
  const heightRef = useRef();

  const focusWidthInputArea = () => widthRef.current.focus();
  const focusHeightInputArea = () => heightRef.current.focus();

  return (
    <Root>
      <Field>
        <Name onClick={focusWidthInputArea}>En</Name>
        <StyledTextField
          fullWidth
          value={features?.width || ""}
          onChange={handleFeatures("width")}
          variant="standard"
          inputRef={widthRef}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            type: "number",
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 4);
          }}
        />
      </Field>
      <Field>
        <Name onClick={focusHeightInputArea}>Boy</Name>
        <StyledTextField
          fullWidth
          value={features?.height || ""}
          onChange={handleFeatures("height")}
          variant="standard"
          inputRef={heightRef}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            type: "number",
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 4);
          }}
        />
      </Field>
      <StyledButton id={productType} disableRipple onClick={toggleStockDialog}>
        Lotları Gör
      </StyledButton>
    </Root>
  );
};

InputField.propTypes = {
  features: PropTypes.object,
  handleFeatures: PropTypes.func,
  productType: PropTypes.number,
  toggleStockDialog: PropTypes.func,
};

export default InputField;
