import { customerConstants } from "../Utils/Constants/CustomerConstants";

const _uiHeader = {
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
};

const uiHeader = () => {
  return _uiHeader;
};

const _authCustomerHeader = {
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
};

const authCustomerHeader = () => {
  let customer = JSON.parse(localStorage.getItem(customerConstants.CUSTOMER));
  if (customer && customer.tokens) {
    _authCustomerHeader.headers[
      "Authorization"
    ] = `Bearer ${customer.tokens.access_token}`; ////'Bearer ' + user.token
  }

  return _authCustomerHeader;
};

const refreshHeader = () => {
  let customer = JSON.parse(localStorage.getItem(customerConstants.CUSTOMER));
  if (customer && customer.tokens) {
    _authCustomerHeader.headers[
      "Authorization"
    ] = `Bearer ${customer.tokens.refresh_token}`; ////'Bearer ' + user.token
  }

  return _authCustomerHeader;
};

export { uiHeader, authCustomerHeader, refreshHeader };
