import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import PageContainerMob from "../Common/PageContainerMob";
import MobileTopBar from "../../Ui/MobileTopBar/MobileTopBar";
import { bottomNavItems } from "../../Utils/Constants";

import { bottomNavbarActions } from "../../Redux/Actions";

const MobileSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu(bottomNavItems.SEARCH);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToFilters = () => history.push("./filters-page");

  if (!isMobileOnly) {
    return <>{goToFilters()}</>;
  }
  return (
    <PageContainerMob noScroll>
      <MobileTopBar searchPage />
    </PageContainerMob>
  );
};

export default MobileSearch;
