import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const PREFIX = "Info";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: 30,
    width: 288,
    height: 37.5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    userSelect: "none",
    marginBottom: 30.5,
  },

  [`& .${classes.text}`]: {
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
}));

const Info = ({ email }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.text}>
        Şifre sıfırlama linki <b>{email}</b> adresine
      </div>
      <div className={classes.text}>
        gönderildi. Lütfen mail kutunuzu kontrol edin.
      </div>
    </Root>
  );
};

Info.propTypes = {
  email: PropTypes.string,
};

export default Info;
