import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import ItemCardImage from "./ItemCard/ItemCardImage";
import ItemCardProduct from "./ItemCard/ItemCardProduct";
import Stepper from "./ItemCard/Stepper";
// import ShipmentInfo from "./ItemCard/ShipmentInfo";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  padding: theme.spacing(),
  boxShadow: "0px 4px 4px -4px rgba(0, 0, 0, 0.25)",
  marginBottom: theme.spacing(),
}));

const ProductContainer = styled("div")(() => ({
  width: 700,
  height: "100%",
  display: "flex",
}));

const StepperContainer = styled("div")(() => ({
  flex: 1,
}));

// const statusArray = [waiting_payment = 1,
//     order_taken = 2
//     getting_ready = 3
//     prepared = 4
//     awaiting_dispatch = 5
//     has_shipped = 6
//     delivery_vehicle = 7
//     shipped = 8]
// const status = 8;

const ItemCard = ({ product }) => {
  // const [height, setHeight] = useState(0);

  // const toggleShipmentInfo = useCallback(() => {
  //   height === 0 ? setHeight("auto") : setHeight(0);
  // }, [height, setHeight]);

  const renderItemCardImage = useMemo(() => {
    return <ItemCardImage product={product} />;
  }, [product]);

  const renderItemCardProduct = useMemo(() => {
    return <ItemCardProduct product={product} />;
  }, [product]);

  // const renderShipmentInfo = useMemo(() => {
  //   return <ShipmentInfo height={height} />;
  // }, [height]);

  const renderStepper = useMemo(() => {
    return <Stepper status={product.status} />;
  }, [product]);

  return (
    <Root>
      <ProductContainer>
        {renderItemCardImage}
        {renderItemCardProduct}
      </ProductContainer>
      <StepperContainer>
        {renderStepper}
        {/* {renderShipmentInfo} */}
      </StepperContainer>
    </Root>
  );
};

ItemCard.propTypes = {
  product: PropTypes.object,
};

export default ItemCard;
