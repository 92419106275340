import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import GrayCheckbox from "../../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../../Images/Checked.svg";
import purpleRect from "../../../../Images/UsersAndRoles/PurpleRect.svg";

const Row = styled("div", {
  shouldForwardProp: (prop) => prop !== "borderless",
})(({ theme, borderless }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "auto",
  //   marginBottom: theme.spacing(),
  paddingBottom: theme.spacing(),
  paddingTop: theme.spacing(),
  borderBottom: !borderless && "1px solid rgba(127, 91, 120, 0.5)",
}));

const Text = styled(Typography)(({theme}) => ({
  width: "fit-content",
  height: "auto",
  fontSize: 14,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  fontWeight: 700,
}));

const Grow = styled("div")(() => ({
  flex: 1,
}));

const CheckBoxMain = styled("div")(() => ({
  width: 400,
  height: "auto",
  display: "flex",
}));

const CheckboxContainer = styled("div")(() => ({
  width: 100,
  height: "auto",
  display: "flex",
}));

const Orders = ({ handleOrders, values }) => {
  return (
    <Row>
      <Text>Sipariş hareketleri</Text>
      <Grow />
      <CheckBoxMain>
        <FormGroup row>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<img src={GrayCheckbox} alt="" />}
                  checkedIcon={<img src={Checked} width="20" alt="" />}
                  indeterminateIcon={
                    <img src={purpleRect} width="20px" alt="" />
                  }
                  onChange={handleOrders("email")}
                  value={values?.orders?.email}
                  checked={values?.orders?.email === true}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={""}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<img src={GrayCheckbox} alt="" />}
                  checkedIcon={<img src={Checked} width="20" alt="" />}
                  indeterminateIcon={
                    <img src={purpleRect} width="20px" alt="" />
                  }
                  onChange={handleOrders("sms")}
                  value={values?.orders?.sms}
                  checked={values?.orders?.sms}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={""}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<img src={GrayCheckbox} alt="" />}
                  checkedIcon={<img src={Checked} width="20" alt="" />}
                  indeterminateIcon={
                    <img src={purpleRect} width="20px" alt="" />
                  }
                  onChange={handleOrders("application")}
                  value={values?.orders?.application}
                  checked={values?.orders?.application}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={""}
            />
          </CheckboxContainer>
        </FormGroup>
      </CheckBoxMain>
    </Row>
  );
};

Orders.propTypes = {
  handleOrders: PropTypes.func,
  values: PropTypes.object,
};

export default Orders;
