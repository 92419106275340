import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const StyledTitle = styled(DialogTitle)(() => ({
  width: "100%",
  height: 30,
  padding: 0,
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(3),
  position: "absolute",
  color: theme.palette.grey[500],
  right: "0",
  top: "0",
  borderRadius: "0 0 0 100%",
  "& svg": {
    fontSize: "24px",
  },
}));

const Icon = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(),
  right: theme.spacing(),
}));

const Title = ({ close }) => {
  return (
    <StyledTitle>
      <StyledButton
        aria-label="Close"
        onClick={close}
        size="large">
        <Icon />
      </StyledButton>
    </StyledTitle>
  );
};

Title.propTypes = {
  close: PropTypes.func,
};

export default Title;
