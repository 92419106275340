import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import checkCircle from "../../../Images/Orders/CheckCircle.svg";

const StyledPaper = styled(Paper)(() => ({
  width: 255,
  height: 250,
  display: "flex",
  flexDirection: "column",
  borderRadius: 8,
  alignItems: "center",
  padding: 10,
}));

const SuccessButton = styled(Button)(({ theme}) => ({
  width: 180,
  height: 32,
  textTransform: "none",
  backgroundColor: theme.palette.darkGray.main,
  color: "#fff",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
  "&[is-first='true']": {
    marginBottom: 10,
  },
}));

const SuccessTitle = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 20,
  alignItems: "center",
  padding: "0px 10px",
}));

const SuccessTitleText = styled(Typography)(({ theme}) => ({
  fontSize: 24,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
}));

const SuccessAddress = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 20,
}));

const SuccesAddressTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.primary.main,
  marginBottom: 5,
}));

const SuccessAddressText = styled(Typography)(({theme}) => ({
  fontSize: 12,
  fontWeight: 400,
  color:theme.palette.darkGray.main,
}));

const SuccessDialog = ({
  successDialog,
  goToHome,
  handleSuccessDialog,
  currentAddress,
}) => {
  return (
    <Dialog open={successDialog} keepMounted={false}>
      <StyledPaper>
        <SuccessTitle>
          <img src={checkCircle} width="24px" height="24px" alt="" />
          <SuccessTitleText>Siparişiniz Alındı</SuccessTitleText>
        </SuccessTitle>
        <SuccessAddress>
          <SuccesAddressTitle>Teslimat Adresi</SuccesAddressTitle>
          <SuccessAddressText>{`${currentAddress?.county?.name}/${currentAddress?.city?.name}`}</SuccessAddressText>
        </SuccessAddress>
        <SuccessButton disableRipple is-first="true" onClick={goToHome}>
          Alışverişe Devam Et
        </SuccessButton>
        <SuccessButton disableRipple onClick={handleSuccessDialog}>
          Sipariş Sayfasına Git
        </SuccessButton>
      </StyledPaper>
    </Dialog>
  );
};

SuccessDialog.propTypes = {
  successDialog: PropTypes.bool,
  goToHome: PropTypes.func,
  handleSuccessDialog: PropTypes.func,
  currentAddress: PropTypes.object,
};

export default SuccessDialog;
