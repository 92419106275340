import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import returnIcon from "../../../Images/ServicesAndReturns/Return.png";
import serviceIcon from "../../../Images/ServicesAndReturns/Service.svg";

const PREFIX = "TabButtons";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  selected: `${PREFIX}-selected`,
  tabsContainer: `${PREFIX}-tabsContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  addNewButton: `${PREFIX}-addNewButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
    marginTop: 10,
  },

  [`& .${classes.button}`]: {
    fontSize: 24,
    fontWeight: 400,
    color: "#B1AEAE",
    width: 200,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  [`& .${classes.selected}`]: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    borderBottom: "1px solid #6E3564",
  },

  [`& .${classes.tabsContainer}`]: {
    display: "flex",
    width: "content-fit",
  },

  [`& .${classes.buttonsContainer}`]: {
    width: 150,
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 8,
  },

  [`& .${classes.addNewButton}`]: {
    height: 40,
    border: "1px solid #E8E4E4",
    backgroundColor: "#fff",
    fontSize: 10,
    fontWeight: 400,
    color: "#000000",
    textTransform: "none",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

const TabButtons = ({ index, setIndex, openAddService, openAddReturn }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.tabsContainer}>
        <div
          onClick={() => setIndex(1)}
          className={classnames({
            [classes.button]: true,
            [classes.selected]: index === 1 && true,
          })}>
          Servis Kayıtları
        </div>
        <div
          onClick={() => setIndex(2)}
          className={classnames({
            [classes.button]: true,
            [classes.selected]: index === 2 && true,
          })}>
          İade Kayıtları
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {index === 1 ? (
          <Button
            className={classes.addNewButton}
            onClick={openAddService}
            disableRipple
            variant="outlined"
            startIcon={
              <img src={serviceIcon} width="16px" height="16px" alt="" />
            }>
            Yeni Servis
          </Button>
        ) : (
          <Button
            className={classes.addNewButton}
            disableRipple
            variant="outlined"
            onClick={openAddReturn}
            startIcon={
              <img src={returnIcon} width="16px" height="16px" alt="" />
            }>
            Yeni İade
          </Button>
        )}
      </div>
    </Root>
  );
};

TabButtons.propTypes = {
  index: PropTypes.number,
  setIndex: PropTypes.func,
  openAddService: PropTypes.func,
  openAddReturn: PropTypes.func,
};

export default React.memo(TabButtons);
