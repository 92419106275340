import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { authActions } from "../../Redux/Actions";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  paddingTop: theme.spacing(8),
  display: "flex",
  justifyContent: "center",
}));

const Text = styled(Typography)(({theme}) => ({
  fontSize: 18,
  fontWeight: 700,
  color: theme.palette.secondary.main,
  lineHeight: "24px",
}));

const SigningOut = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signOut = () => dispatch(authActions.signout());

  useEffect(() => {
    let active = true;
    if (active) {
      signOut();
      history.push("/signin-email");
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <Text>Çıkış Yapılıyor...</Text>
    </Root>
  );
};

export default SigningOut;
