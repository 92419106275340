import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import _ from "lodash";

import LotDialog from "../../Dialog/LotDialog";
import Title from "./FeaturesUpdateDialog/Title";
import Selections from "./FeaturesUpdateDialog/Selections";
import InputField from "./FeaturesUpdateDialog/InputField";
import QuantityAndSubmit from "./FeaturesUpdateDialog/QuantityAndSubmit";

const PREFIX = "FeaturesUpdateDialog";

const classes = {
  paper: `${PREFIX}-paper`,
  content: `${PREFIX}-content`,
  imageAndSelections: `${PREFIX}-imageAndSelections`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 700,
    height: 458,
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&.MuiDialog-paperWidthSm": {
      maxWidth: 750,
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "auto",
    padding: theme.spacing(2.5),
    paddingTop: 0,
  },

  [`& .${classes.imageAndSelections}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const FeaturesUpdateDialog = ({
  open,
  product,
  features,
  specialFeatureValues,
  handleFeatures,
  increase,
  decrease,
  selections,
  handleSubmit,
  closeDialogFromButton,
  setSelections,
}) => {
  const [stockDialog, setStockDialog] = useState(false);

  useEffect(() => {
    let active = true;
    if (active && product) {
      if (product.type.id === 2) {
        let dimensions = product.attributes_map.Ebat;
        let width = parseInt(dimensions.split("x")[0]);
        let height = parseInt(dimensions.split("x")[1]);
        let heightIsNan = _.isNaN(height) ? "" : height;
        setSelections({
          ...selections,
          quantity: product.quantity,
          features: {
            width: !features || features?.width === "" ? width : features.width,
            height:
              !features || features?.height === ""
                ? heightIsNan
                : features.height,
            shape:
              !features || features?.shape === ""
                ? "Dikdörtgen"
                : features.shape,
            side:
              !features || features?.shape === "" ? "Overlok" : features.side,
            webType:
              !features || features?.webType === "" ? "" : features.webType,
            webColor:
              !features || features?.webColor === "" ? "" : features.webColor,
          },
        });
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, product]);

  const toggleStockDialog = () => setStockDialog(!stockDialog);

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <Title close={closeDialogFromButton} />
      <DialogContent className={classes.content}>
        <div className={classes.imageAndSelections}>
          <img
            src={product?.image}
            alt=""
            width="176px"
            height="272px"
            style={{ borderRadius: 8, marginRight: 20 }}
          />
          <Selections
            product={product}
            specialFeatureValues={specialFeatureValues}
            features={features}
            handleFeatures={handleFeatures}
          />
        </div>
        <InputField
          features={features}
          handleFeatures={handleFeatures}
          productType={product?.type.id}
          toggleStockDialog={toggleStockDialog}
        />
        <QuantityAndSubmit
          decrease={decrease}
          increase={increase}
          quantity={selections?.quantity}
          handleSubmit={handleSubmit}
          features={features}
        />
      </DialogContent>
      <LotDialog
        name={product?.name}
        data={product?.stock?.detail_list}
        onClose={toggleStockDialog}
        open={stockDialog}
      />
    </StyledDialog>
  );
};

FeaturesUpdateDialog.propTypes = {
  open: PropTypes.bool,
  product: PropTypes.object,
  features: PropTypes.object,
  specialFeatureValues: PropTypes.object,
  handleFeatures: PropTypes.func,
  increase: PropTypes.func,
  decrease: PropTypes.func,
  selections: PropTypes.object,
  handleSubmit: PropTypes.func,
  closeDialogFromButton: PropTypes.func,
  setSelections: PropTypes.func,
};

export default FeaturesUpdateDialog;
