import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { mockRatings } from "../../../Utils/Constants";

import RatingModal from "./Ratings/RatingModal";

const PREFIX = "Ratings";

const classes = {
  root: `${PREFIX}-root`,
  headerContainer: `${PREFIX}-headerContainer`,
  header: `${PREFIX}-header`,
  button: `${PREFIX}-button`,
  container: `${PREFIX}-container`,
  item: `${PREFIX}-item`,
  date: `${PREFIX}-date`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: 17,
    paddingLeft: 14,
    paddingRight: 34,
    paddingBottom: 35,
    overflow: "hidden",
  },

  [`& .${classes.headerContainer}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginBottom: 16,
  },

  [`& .${classes.button}`]: {
    width: 123,
    height: 24,
    textTransform: "capitalize",
    backgroundColor: theme.palette.darkGray.light,
    color: theme.palette.darkGray.main,
    fontSize: 12,
    fontWeight: 700,
    borderRadius: 0,
    padding: 0,
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.darkGray.light,
    },
  },

  [`& .${classes.container}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    marginBottom: 20,
  },

  [`& .${classes.item}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    paddingBottom: 17.33,
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  },

  [`& .${classes.date}`]: {
    fontSize: 10,
    fontWeight: 400,
    color: "#BDBDBD",
    marginBottom: 4,
  },

  [`& .${classes.text}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: "#B1AEAE",
    marginBottom: 23,
  },
}));

const Ratings = () => {
  const [showModal, set] = useState(false);

  const openModal = () => set(true);
  const closeModal = () => set(false);

  return (
    <Root className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>Özellikler</Typography>
        <Button
          variant="contained"
          disableRipple
          className={classes.button}
          onClick={openModal}>
          Ürünü Değerlendir
        </Button>
      </div>
      <div className={classes.container}>
        {mockRatings.map((item, index) => (
          <div className={classes.item} key={index}>
            <Typography className={classes.date}>{item.date}</Typography>
            <Typography className={classes.text}>{item.text}</Typography>
            <Rating
              name="size-small"
              value={item.point}
              size="small"
              precision={0.5}
              readOnly
            />
          </div>
        ))}
      </div>
      <RatingModal open={showModal} handleClose={closeModal} />
    </Root>
  );
};

export default Ratings;
