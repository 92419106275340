import React from "react";
// import PropTypes from "prop-types";

const CheckAndBill = () => {
  return <div>Check and Bill</div>;
};

// CheckAndBill.propTypes = {};

export default CheckAndBill;
