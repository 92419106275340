import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Transition } from "../../../Utils/Tools";
import MobileDialogHeader from "../Tools/MobileDialogHeader";

import InputArea from "../SpecialDemands/SpecialDemandsModal/InputArea";
import RadioArea from "./MobileSpecialDemands/RadioArea";
import QuantitySelectionMob from "../../Fields/QuantitySelectionMob";
import StockButton from "../../../Views/ProductPage/MobileDetails/StockButton";

import Stocks from "./Stocks";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    paddingTop: theme.spacing(),
    margin: 0,
    overflow: "hidden",
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "24px",
  color: theme.palette.darkGray.main,
  marginTop: theme.spacing(),
}));

const SpaceContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const StockContainer = styled("div")(() => ({
  width: 150,
  height: 28,
  padding: "0px 8px",
  display: "flex",
  border: "1px solid rgba(231,231,231, 0.5)",
  borderRadius: 3,
  alignItems: "center",
}));

const StockText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "stock",
})(({ stock, theme }) => ({
  fontSize: 10,
  lineHeight: "16px",
  color: stock ? "#10C66C" : theme.palette.darkGray.main,
  fontWeight: 400,
  marginLeft: stock ? 5 : 0,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 30,
  backgroundColor: theme.palette.primary.main,
  textTransform: "none",
  color: "#fff",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "20px",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));
const MobileSpecialDemandsCard = ({
  handleClose,
  open,
  data,
  features,
  handleFeatures,
  specialFeatureValues,
  handleChange,
  selections,
  handleSubmit,
  setSelections,
}) => {
  const [stockDialog, setStockDialog] = useState(false);

  useEffect(() => {
    let active = true;
    if (active && data) {
      if (data.type.id === 2) {
        let dimensions = data.attributes_map.Ebat;
        let width = parseInt(dimensions.split("x")[0]);
        let height = parseInt(dimensions.split("x")[1]);
        let heightIsNan = _.isNaN(height) ? "" : height;
        setSelections({
          ...selections,
          quantity: data.quantity,
          features: {
            width: !features || features?.width === "" ? width : features.width,
            height:
              !features || features?.height === ""
                ? heightIsNan
                : features.height,
            shape:
              !features || features?.shape === ""
                ? "Dikdörtgen"
                : features.shape,
            side:
              !features || features?.shape === "" ? "Overlok" : features.side,
            webType:
              !features || features?.webType === "" ? "" : features.webType,
            webColor:
              !features || features?.webColor === "" ? "" : features.webColor,
          },
        });
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  const toggleStockDialog = () => setStockDialog((prev) => !prev);

  const handleDisableCondition = () => {
    if (features) {
      if (
        features.shape === "" ||
        features.side === "" ||
        features.width === "" ||
        features.height === "" ||
        features.width === 0 ||
        features.height === 0
      ) {
        return true;
      } else if (features.side === "Saçak" && features.webType === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeader header={data?.name} callback={handleClose} />
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: 10,
          display: "flex",
          flexDirection: "column",
        }}>
        <Header>Ebat</Header>
        <InputArea handleFeatures={handleFeatures} features={features} mobile />
        <Header>Şekil</Header>
        <RadioArea
          data={specialFeatureValues?.shapes}
          selectedValue={features?.shape}
          value="shape"
          handleFeatures={handleFeatures}
          features={features}
        />
        <Header>Kenar Dokuma</Header>
        <RadioArea
          data={specialFeatureValues?.sides}
          selectedValue={features?.side}
          value="side"
          handleFeatures={handleFeatures}
          features={features}
        />
        {features?.side === "Saçak" && (
          <>
            <Header>Saçak Tipi</Header>
            <RadioArea
              data={specialFeatureValues?.webTypes}
              selectedValue={features?.webType}
              value="webType"
              handleFeatures={handleFeatures}
              features={features}
            />
          </>
        )}
        <SpaceContainer>
          <QuantitySelectionMob
            handleChange={handleChange}
            param="quantity"
            selectedValue={selections?.quantity}
            stockInfo={parseInt(data?.stock.quantity)}
            specialFeatures
          />
          <StockContainer>
            <StockText>Merkez Depo:</StockText>
            <StockText stock>{data?.stock.quantity} Adet</StockText>
          </StockContainer>
        </SpaceContainer>
        {data?.type.id === 3 && <StockButton openDialog={toggleStockDialog} />}
        <SubmitButton
          variant="contained"
          disabled={handleDisableCondition()}
          onClick={handleSubmit}>
          Uygula
        </SubmitButton>
      </div>
      <Stocks
        open={stockDialog}
        handleClose={toggleStockDialog}
        data={data?.stock?.detail_list}
      />
    </StyledDialog>
  );
};

MobileSpecialDemandsCard.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.object,
  features: PropTypes.object,
  handleFeatures: PropTypes.func,
  specialFeatureValues: PropTypes.object,
  handleChange: PropTypes.func,
  selections: PropTypes.object,
  handleSubmit: PropTypes.func,
  setSelections: PropTypes.func,
};

export default MobileSpecialDemandsCard;
