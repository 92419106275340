import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import AuthContainer from "./AuthContainer";
import mailIcon from "../../Images/Auth/mail.svg";
import CustomDivider from "../../Ui/Divider/CustomDivider";
import { SubmitButton, RouteButton } from "./AuthCommons/Tools";
import Header from "./AuthCommons/Header";
import Label from "./AuthCommons/Label";
import ForgotPasswordText from "./AuthCommons/ForgotPasswordText";
import { authService } from "../../Services";
import SigninPhoneMob from "./SigninPhoneMob";
import { isMobileOnly } from "react-device-detect";
import Loading from "../../Utils/Loading";
import { generalConstants } from "../../Utils/Constants";

const Root = styled("div")(() => ({
  width: 440,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledTextField = styled(TextField)(({theme}) => ({
  width: 288,
  maxHeight: 32.88,
  backgroundColor: "white",
  border: "none",
  borderRadius: 8,
  paddingLeft: 12,
  paddingRight: 12,
  fontSize: 12,
  fontWeight: 400,
  marginBottom: 34.09,
  "& .MuiInputBase-input": {
    padding: "9.2px 0px",
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginLeft: -1,
  },
}));

const AdornmentText = styled(Typography)(({theme}) => ({
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.primary.main,
  userSelect: "none",
  "& > span": {
    fontWeight: 400,
  },
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  width: 24.12,
}));

const StyledIcon = styled("img")(() => ({
  marginRight: 48,
}));

const SigninPhone = () => {
  let history = useHistory()

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    code: "+90 5",
    phone: "",
  });
  const [notValid, setNotValid] = useState(true);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
    if (data.toString().length === 12) setNotValid(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    let param = `5${values.phone}`;
    let query = {
      phone: param,
    };
    authService
      .signinPhone(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setLoading(false);
          history.push(`/phone-verification`, param);
        } else {
          console.log(res);
          toast.error(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.data?.message);
      });
  };

  const handleRoute = () => {
    history.push("/signin-email");
  };

  if (isMobileOnly) {
    return <SigninPhoneMob />;
  } else {
    return (
      <AuthContainer>
        <Loading open={loading} />
        <Root>
          <Header />
          <Label>Telefon Numarası</Label>
          <InputMask
            mask="99 999 99 99"
            disabled={false}
            maskChar={0}
            onChange={handleChange("phone")}>
            {() => (
              <StyledTextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <StyledAdornment position="start">
                      <AdornmentText>
                        +90 <span>5</span>
                      </AdornmentText>
                    </StyledAdornment>
                  ),
                }}
              />
            )}
          </InputMask>
          <SubmitButton
            variant="contained"
            disableRipple
            onClick={handleSubmit}
            disabled={notValid}>
            Kod Gönder
          </SubmitButton>
          <ForgotPasswordText />
          <CustomDivider>veya</CustomDivider>
          <RouteButton variant="contained" disableRipple onClick={handleRoute}>
            <StyledIcon
              src={mailIcon}
              alt="mailIcon"
              width="20px"
              height="16.67 px"
            />
            E-posta ile giriş yap
          </RouteButton>
        </Root>
      </AuthContainer>
    );
  }
};

export default SigninPhone;
