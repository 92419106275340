const addButtonDisableCondition = (
  selections,
  data,
  activeArray3,
  featuresSelected,
  productType
) => {
  let feat = selections?.features;
  if (productType && productType === 3) {
    if (featuresSelected) {
      return false;
    } else {
      return true;
    }
  } else if (data?.has_variants) {
    if (featuresSelected && (feat?.width < 1 || feat?.height < 1)) {
      return true;
    } else if (activeArray3?.length === 0) {
      return true;
    } else {
      return false;
    }
  }
};

const fastOrderAddButtonDisableCondition = (
  showSpecialFeaturesBox,
  customHeight,
  selections
) => {
  if (showSpecialFeaturesBox || customHeight) {
    if (
      selections.features.side === "Saçak" &&
      selections.features.webType === ""
    ) {
      return true;
    } else if (
      selections.features.shape === "" ||
      selections.features.side === ""
    ) {
      return true;
    } else if (
      selections.features.width < 1 ||
      selections.features.height < 1
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export { addButtonDisableCondition, fastOrderAddButtonDisableCondition };
