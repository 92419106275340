import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PageContainerMob from "../Common/PageContainerMob";
import Loading from "../../Utils/Loading";
import MobileProductCard from "../../Ui/ProductCard/MobileProductCard";
import MobileDialogHeader from "../../Ui/Dialog/Tools/MobileDialogHeader";

import { campaignService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";

const Container = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: 0,
  marginBottom: theme.spacing(3),
  overflowY: "auto",
  overflowX: "hidden",
}));

const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkGray.main,
  fontSize: 20,
  lineHeight: "24px",
  fontWeight: 400,
  marginBottom: theme.spacing(2),
  textAlign: "left",
  width: "100%",
  paddingLeft: theme.spacing(),
}));

const CampaignsMob = () => {
  const params = useParams();
  const history = useHistory();

  const campaigns = useSelector(({ campaigns }) => campaigns.campaigns);

  const currentCampaign = campaigns?.filter((item) => item.id === +params?.id);
  let header =
    currentCampaign && currentCampaign.length > 0
      ? currentCampaign[0]?.name
      : null;

  const [campaignProducts, setCampaignProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (params) {
        getCampaignProducts(params.id);
      }
    }
    return () => {
      active = false;
    };
  }, [params]);

  const getCampaignProducts = async (id) => {
    setLoading(true);
    const response = await campaignService.getCampaignProducts(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setCampaignProducts(response.data.product_skus);
      setLoading(false);
    } else {
      console.log(response);
      setLoading(false);
    }
  };

  const goBack = () => history.goBack();

  if (loading) {
    return (
      <PageContainerMob>
        <MobileDialogHeader header="Profil Ayarları" callback={goBack} />
        <div style={{ height: "100%", width: "100%" }}>
          <Loading open={loading} />
        </div>
      </PageContainerMob>
    );
  }

  return (
    <PageContainerMob noScroll>
      <MobileDialogHeader header="Kampanyalar" callback={goBack} />
      <Header>{header && header}</Header>
      <Container container spacing={1}>
        {campaignProducts?.map((item, index) => (
          <Grid item xs={6} key={index}>
            <MobileProductCard item={item} />
          </Grid>
        ))}
      </Container>
    </PageContainerMob>
  );
};

export default CampaignsMob;
