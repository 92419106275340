import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { styled } from "@mui/material/styles";

const PREFIX = "TabButtons";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  selected: `${PREFIX}-selected`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 57,
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
  },

  [`& .${classes.button}`]: {
    paddingTop: 10,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.colors.black,
    width: 250,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  [`& .${classes.selected}`]: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    borderBottom: "1px solid #6E3564",
  },
}));

const TabButtons = ({ index, setIndex }) => {
  return (
    <Root className={classes.root}>
      <div
        onClick={() => setIndex(1)}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === 1 && true,
        })}>
        Firma Şube Adresleri
      </div>
      <div
        onClick={() => setIndex(2)}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === 2 && true,
        })}>
        Perakende Müşteri Adresleri
      </div>
    </Root>
  );
};

TabButtons.propTypes = {
  index: PropTypes.number,
  setIndex: PropTypes.func,
};

export default TabButtons;
