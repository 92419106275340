import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import info from "../../Images/Addresses/info.svg";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const MainText = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  fontSize: 18,
  fontWeight: 700,
  lineHeight: "22px",
  color: "#64748B",
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const ConfirmText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  color: "#64748B",
  marginBottom: theme.spacing(),
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  width: 240,
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "confirm_",
})(({ theme, confirm_ }) => ({
  textTransform: "none",
  color: "#fff",
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "22px",
  width: 100,
  height: 35,
  padding: 0,
  backgroundColor: confirm_
    ? theme.palette.darkGray.main
    : theme.palette.primary.main,
  borderRadius: 8,
  "&:hover": {
    backgroundColor: confirm_
      ? theme.palette.darkGray.main
      : theme.palette.primary.main,
  },
}));

const ConfirmDialog = ({ open, confirm, cancel }) => {
  return (
    <StyledDialog open={open} onClose={cancel}>
      <img src={info} height="75" alt="" />
      <MainText>Tüm bildirimleriniz okundu olarak işaretlenecektir</MainText>
      <ConfirmText>Onaylıyor musunuz?</ConfirmText>
      <ButtonContainer>
        <StyledButton onClick={cancel}>İptal</StyledButton>
        <StyledButton onClick={() => confirm()} confirm_>
          Onayla
        </StyledButton>
      </ButtonContainer>
    </StyledDialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};

export default ConfirmDialog;
