import React from "react";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

import appStoreLogo from "../../../Images/FooterImages/app-store.svg";
import playStoreLogo from "../../../Images/FooterImages/play-store.svg";
import Bufi from "../../../Images/FooterImages/Bufi.svg";

const PREFIX = "Bottom";

const classes = {
  bottom: `${PREFIX}-bottom`,
  container: `${PREFIX}-container`,
  bottomTexts: `${PREFIX}-bottomTexts`,
  img: `${PREFIX}-img`,
};

const Root = styled("div")(() => ({
  [`&.${classes.bottom}`]: {
    width: "100%",
    height: 75,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000",
  },

  [`& .${classes.container}`]: {
    width: "100%",
    maxWidth: 630,
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.bottomTexts}`]: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    width: 116,
    height: 20,
    color: "#BDBDBD",
    "&[text='privacy-notice']": {
      width: 116,
      cursor: "pointer",
    },
    "&[text='cookie-policy']": {
      width: 113,
      cursor: "pointer",
    },
    "&[text='version']": {
      width: 84,
      fontSize: 14,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  [`& .${classes.img}`]: {
    cursor: "pointer",
  },
}));

const Bottom = () => {
  return (
    <Root className={classes.bottom}>
      <div className={classes.container}>
        <div
          style={{
            width: 70,
            height: 30,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <a
            href="https://www.buficore.com/"
            target="_blank"
            rel="noopener noreferrer">
            <img src={Bufi} width="66.45px" height="30px" alt="bufi" />
          </a>
        </div>
        <div
          style={{
            width: "fit-content",
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography className={classes.bottomTexts} text="privacy-notice">
            Gizlilik Politikası
          </Typography>
        </div>
        <div
          style={{
            width: "fit-content",
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography className={classes.bottomTexts} text="cookie-policy">
            Çerez Politikası
          </Typography>
        </div>
        <div
          style={{
            width: 94,
            height: 30,
            display: "flex",
            justifyContent: "space-between",
          }}>
          <a
            href="https://apps.apple.com/app/%C3%B6zsafir-hal%C4%B1-b2b/id1599735542"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={appStoreLogo}
              width="28.1px"
              height="30px"
              alt="appStore logo"
              className={classes.img}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.buficore.b2b"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={playStoreLogo}
              width="25.36px"
              height="30px"
              alt="playStore logo"
              className={classes.img}
            />
          </a>
        </div>
      </div>
    </Root>
  );
};

export default React.memo(Bottom);
