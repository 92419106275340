import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../../Redux/Reducers";
import throttle from "lodash/throttle";

import { loadState, saveState } from "./LocalStorage";

const persistentState = loadState();

const loggerMiddleware = createLogger();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = composeEnhancers(applyMiddleware(thunkMiddleware));

if (process.env.NODE_ENV === "development") {
  middleware = composeEnhancers(
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  );
}

export const store = createStore(rootReducer, persistentState, middleware);

store.subscribe(
  throttle(() => {
    saveState({
      customer: store.getState().customer,
      orders: store.getState().orders,
      search: store.getState().search,
      auth: store.getState().auth,
      addresses: store.getState().addresses,
      filterValues: store.getState().filterValues,
      product: store.getState().product,
      bottomNavbarValue: store.getState().bottomNavbarValue,
      favorites: store.getState().favorites,
      notifications: store.getState().notifications,
      campaigns: store.getState().campaigns,
    });
  }, 1000)
);
