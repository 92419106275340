import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import CountDown from "./CountDown";

const PREFIX = "Timer";

const classes = {
  timerCircle: `${PREFIX}-timerCircle`,
};

const Root = styled("div")(() => ({
  [`&.${classes.timerCircle}`]: {
    marginTop: 36.79,
    width: 56,
    height: 56,
    borderRadius: "50%",
    border: "3px solid #6E3564",
    boxSizing: "border-box",
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Timer = ({ secondsRemaining, setSecondsRemaining }) => {
  return (
    <Root className={classes.timerCircle}>
      <CountDown
        secondsRemaining={secondsRemaining}
        setSecondsRemaining={setSecondsRemaining}
      />
    </Root>
  );
};

Timer.propTypes = {
  secondsRemaining: PropTypes.number,
  setSecondsRemaining: PropTypes.func,
};

export default Timer;
