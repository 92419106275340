import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import AutoCompleteArea from "../../../../../Ui/Fields/AutocompleteArea";
import AutoCompleteMultiple from "../../../../../Ui/Fields/AutoCompleteMultiple";

import {
  generalConstants,
  roleNameConverter,
} from "../../../../../Utils/Constants";

import {
  companyService,
  addressService,
  userService,
} from "../../../../../Services";
import { MobileDialogButton } from "../../../../../Utils/Tools";

import Loading from "../../../../../Utils/Loading";

const FirmEdit = ({
  open,
  onClose,
  roles,
  userInfo,
  newGetUsers,
  query_,
}) => {
  const query = {
    pageNumber: 1,
    pageSize: 1000,
    sortField: "id",
    sortOrder: "asc",
    filter: {},
  };

  const companyArr = ["Firma Kullanıcı", "Firma Yetkili"];

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firm: { name: userInfo?.company?.name, id: userInfo?.company?.id },
    branch: [],
    roles: {
      name: roleNameConverter[userInfo?.role_names[0]],
      id: userInfo?.role_ids[0],
    },
    defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
  });

  const [error, setError] = useState(false);
  const [firms, setFirms] = useState([
    { name: "Lütfen firma seçinizi", id: 0.5 },
  ]);
  const [addresses, setAddresses] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);

  let stringifiedFirms = JSON.stringify(values.firm);
  let stringifiedBranch = JSON.stringify(values.branch);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && userInfo) {
        let userQuery = { ...query, filter: { user_id: userInfo?.id } };
        getUserAddresses(userQuery);
        getCompanyList(query);
        setError(false);
        setValues({
          ...values,
          firm: { name: userInfo?.company?.name, id: userInfo?.company?.id },
          roles: {
            name: roleNameConverter[
              userInfo?.role_names?.length > 0 && userInfo?.role_names[0]
            ],
            id: userInfo?.role_ids?.length && userInfo?.role_ids[0],
          },
        });
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, userInfo]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values?.branch?.length === 0) {
        setValues({
          ...values,
          defaultAddress: { name: "Lütfen yetkili adres seçiniz", id: 0.5 },
        });
      } else {
        let defId = userInfo?.address?.id;
        let filteredAddr = values.branch.filter((item) => item.id === defId);
        if (filteredAddr.length > 0) {
          setValues({
            ...values,
            defaultAddress: filteredAddr[0],
          });
        } else {
          setValues({
            ...values,
            defaultAddress: values.branch[0],
          });
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedBranch]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.firm) {
        let id = values.firm.id;
        if (id !== 0.5) {
          let adminQuery = { ...query, filter: { company_id: id } };
          getCompanyAddresses(adminQuery);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFirms]);

  const getCompanyList = async (query) => {
    setLoading(true);
    const response = await companyService.getCompanies(query);
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies;
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ["name"].every((k) => t[k] === v[k])) === i
      );
      let finalData = notDuplicatedData.filter((item) => item.name !== "None");
      setFirms(finalData);
      setLoading(false);
    } else {
      setFirms([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const getCompanyAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.addresses;
      let companyBranches = data.filter((d) => d.type === 0);
      setAddresses(companyBranches);
      setLoading(false);
    } else {
      setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const getUserAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.addresses;
      let userAddresses = data.filter((item) => item.type === 0);
      let customerAddresses = data.filter((item) => item.type === 1);
      setCustomerAddresses(customerAddresses);
      setValues({
        ...values,
        branch: userAddresses,
      });
      setLoading(false);
    } else {
      setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const handleCheckUser = () => {
    if (
      !values.firm ||
      !values.branch ||
      !values.roles ||
      !values.defaultAddress
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleCheck = () => {
    if (!values.firm || !values.roles) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isUser = values?.roles?.name === "Firma Kullanıcı";
    let isCompanyAdmin = values?.roles?.name === "Firma Yetkili";
    let isError = isUser ? handleCheckUser() : handleCheck();
    let initialQuery = {
      pageNumber: 1,
      pageSize: 10,
      sortField: "id",
      sortOrder: "desc",
      filter: {},
    };
    if (!isError) {
      setLoading(true);
      let allAddrresses = customerAddresses.concat(values.branch);
      let addrIds = allAddrresses.map((item) => item.id.toString());
      let userQuery = !companyArr.includes(values?.roles?.name)
        ? { roles: [`${values.roles.id}`] }
        : values?.roles?.name === "Firma Kullanıcı"
        ? {
            roles: [`${values.roles.id}`],
            addresses: isUser ? addrIds : [],
            company_id: +values.firm.id,
          }
        : {
            roles: [`${values.roles.id}`],
            company_id: +values.firm.id,
          };
      const response = await userService.editRole(userInfo?.id, userQuery);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        if (isUser || isCompanyAdmin) {
          let query = {
            is_default: true,
            address_id: +values.defaultAddress.id,
          };
          const res = await addressService.toggleDefaultAddress(
            query,
            +userInfo?.id
          );
          if (res.data.status === generalConstants.STATUS_TRUE) {
            handleSuccess(initialQuery);
          } else {
            setLoading(false);
            toast.error(res?.data?.message);
          }
        } else {
          handleSuccess(initialQuery);
        }
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    }
  };

  const handleSuccess = (query) => {
    toast.success("Firma bilgileri başarılı bir şekilde güncellendi");
    onClose();
    newGetUsers(query_, true);
  };

  // const isActive = status && status === "Aktif";

  return (
    <div>
      <Loading open={loading} />
      <AutoCompleteArea
        data={roles}
        label="Yetki Grubu"
        setValues={setValues}
        prop="roles"
        values={values}
        component="add user"
        error={error}
        // disabled={isActive}
      />
      {companyArr.includes(values?.roles?.name) && (
        <AutoCompleteArea
          data={firms}
          label="Firma"
          setValues={setValues}
          prop="firm"
          values={values}
          error={error}
          // disabled={isActive}
          component="add user"
        />
      )}
      {values?.roles?.name === "Firma Kullanıcı" && (
        <AutoCompleteMultiple
          data={addresses}
          label="Yetkili Adresler"
          setValues={setValues}
          prop="branch"
          values={values}
          error={error}
          // disabled={isActive}
          defVal={values?.branch}
          component="add user"
        />
      )}
      {(values?.roles?.name === "Firma Kullanıcı" ||
        values?.roles?.name === "Firma Yetkili") && (
        <AutoCompleteArea
          data={values?.branch}
          label="Tanımlı Adres"
          setValues={setValues}
          prop="defaultAddress"
          values={values}
          error={error}
          // disabled={isActive}
          component="add user"
        />
      )}
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}>
        Kaydet
      </MobileDialogButton>
    </div>
  );
};

FirmEdit.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roles: PropTypes.array,
  userInfo: PropTypes.object,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
};

export default FirmEdit;
