import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TitleComponent from "./Common/TitleComponent";
import Section from "./Common/Section";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(),
}));

const FirmsSection = ({ firms, values, callBack, stateSetter }) => {
  return (
    <Root>
      <TitleComponent title="Firma" />
      <Section
        data={firms}
        callBack={callBack}
        values={values}
        stateSetter={stateSetter}
        component="Firma"
        searchable
      />
    </Root>
  );
};

FirmsSection.propTypes = {
  values: PropTypes.array,
  callBack: PropTypes.func,
  stateSetter: PropTypes.func,
};

export default FirmsSection;
