import React from "react";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import PageContainerMob from "../../../Common/PageContainerMob";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(8),
  alignItems: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textTransform: "none",
  fontSize: 16,
}));

const UnAuthorized = () => {
  const history = useHistory();

  const goToHome = () => history.push("/home");
  return (
    <PageContainerMob>
      <Root>
        <Typography variant="h3">Yetkisiz Kullanıcı</Typography>
        <StyledButton onClick={goToHome}>Ana Sayfaya Dön</StyledButton>
      </Root>
    </PageContainerMob>
  );
};

export default UnAuthorized;
