import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AuthContainerMob from "./AuthContainerMob";
import Label from "./AuthCommons/Label";
import ForgotPasswordText from "./AuthCommons/ForgotPasswordText";
import CustomDivider from "../../Ui/Divider/CustomDivider";
import { SubmitButton, RouteButton } from "./AuthCommons/Tools";
import mailIcon from "../../Images/Auth/mail.svg";

import { authService } from "../../Services";
import Loading from "../../Utils/Loading";
import { generalConstants } from "../../Utils/Constants";

import PhoneVerificationMob from "./PhoneVerificationMob";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  maxHeight: 478,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: 0,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "82%",
  maxHeight: 32.88,
  backgroundColor: "white",
  border: "none",
  borderRadius: 8,
  paddingLeft: 12,
  paddingRight: 12,
  fontSize: 12,
  fontWeight: 400,
  marginBottom: 34.09,
  "& .MuiInputBase-input": {
    padding: "9.2px 0px",
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginLeft: -1,
  },
}));

const StyledSpan = styled("span")(() => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  paddingRight: 24,
}));

const StyledCard = styled("div")(() => ({
  width: "95%",
  background: "rgba(133, 89, 122, 0.1)",
  borderRadius: 8,
  height: 220,
  paddingTop: 17,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const AdornmentText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.primary.main,
  userSelect: "none",
  "& > span": {
    fontWeight: 400,
  },
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  width: 24.12,
}));

const StyledIcon = styled("img")(() => ({
  marginRight: 0,
}));

const SigninPhoneMob = () => {
  let history = useHistory();
  const [values, setValues] = useState({
    code: "+90 5",
    phone: "",
  });
  const [notValid, setNotValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
    if (data.toString().length === 12) setNotValid(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    let param = `5${values.phone}`;
    let query = {
      phone: param,
    };
    authService
      .signinPhone(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setLoading(false);
          setStep(1);
          // history.push(`/phone-verification`, param);
        } else {
          toast.error(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleRoute = () => {
    history.push("/signin-email");
  };

  return (
    <>
      {step === 0 ? (
        <AuthContainerMob signinLogo>
          <Loading open={loading} />
          <Root>
            <StyledCard>
              <Label mobile>Telefon Numarası</Label>
              <InputMask
                mask="99 999 99 99"
                disabled={false}
                maskChar={0}
                onChange={handleChange("phone")}>
                {() => (
                  <StyledTextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <StyledAdornment position="start">
                          <AdornmentText>
                            +90 <span>5</span>
                          </AdornmentText>
                        </StyledAdornment>
                      ),
                    }}
                  />
                )}
              </InputMask>
              <SubmitButton
                variant="contained"
                disableRipple
                onClick={handleSubmit}
                disabled={notValid}
                style={{ width: "82%", marginBottom: 20 }}>
                Kod Gönder
              </SubmitButton>
            </StyledCard>
            <ForgotPasswordText
              component="siginin-email"
              mobile/>
            <CustomDivider mobile>
              veya
            </CustomDivider>
            <RouteButton
              style={{ width: "82%" }}
              variant="contained"
              disableRipple
              onClick={handleRoute}>
              <StyledIcon
                src={mailIcon}
                alt="smsIcon"
                width="24px"
                height="20.94px"
              />
              <StyledSpan>E-posta ile giriş yap</StyledSpan>
            </RouteButton>
          </Root>
        </AuthContainerMob>
      ) : (
        <PhoneVerificationMob gsm={`5${values.phone}`} />
      )}
    </>
  );
};

export default SigninPhoneMob;
