import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import CategoryItem from "./CategoryItem";

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(),
  paddingBottom: theme.spacing(15),
}));

const TabContent = ({ items, onClickHandler }) => {
  return (
    <StyledGrid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={6} key={index}>
          <CategoryItem item={item} onClickHandler={onClickHandler} />
        </Grid>
      ))}
    </StyledGrid>
  );
};

TabContent.propTypes = {
  items: PropTypes.array,
  onClickHandler: PropTypes.func,
};

export default TabContent;
