import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import AutoCompleteArea from "../../../Ui/Fields/AutocompleteArea";

import { generalConstants } from "../../../Utils/Constants";
import { addressService } from "../../../Services";
import { authActions } from "../../../Redux/Actions";

const PREFIX = "EditUserFirmCa";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  inputContainer: `${PREFIX}-inputContainer`,
  submitButton: `${PREFIX}-submitButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: theme.spacing(),
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const StyledFirm = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "20px",
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const EditUserFirmCa = ({ open, onClose, user }) => {
  const customerAddresses = useSelector(({ addresses }) => addresses.addresses);
  const customer = useSelector(({ customer }) => customer);

  const dispatch = useDispatch();

  const signOut = () => dispatch(authActions.signout());

  const [values, setValues] = useState({
    branch: { name: "Lütfen şube seçiniz", id: 0.5 },
  });
  const [error, setError] = useState(false);
  const [addresses, setAddresses] = useState([
    { name: "Lütfen şube seçiniz", id: 0.5 },
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && user) {
        setValues({
          branch: { name: user?.address?.name, id: user?.address?.id },
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, user]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customerAddresses) {
        if (customerAddresses.length > 0) {
          let companyAddresses = customerAddresses.filter(
            (addr) => addr.type === 0
          );
          setAddresses(companyAddresses);
        } else {
          setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
        }
      } else return;
    }
    return () => {
      active = false;
    };
  }, [customerAddresses]);

  const handleCheck = () => {
    if (!values.branch) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      let query = {
        is_default: true,
        address_id: +values.branch.id,
      };
      const res = await addressService.selfToggleAddress(query);
      if (res.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Adres bilgileri başarılı bir şekilde güncellendi");
        setTimeout(() => {
          onClose();
          signOut();
        }, 1000);
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Firma Bilgileri</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.inputContainer}>
          <StyledFirm>{`Firma: ${customer?.currentCompany?.name}`}</StyledFirm>
          <AutoCompleteArea
            data={addresses}
            label="Şube veya Adres"
            setValues={setValues}
            prop="branch"
            values={values}
            error={error}
          />
          <Button
            variant="contained"
            disableRipple
            onClick={handleSubmit}
            className={classes.submitButton}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

EditUserFirmCa.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default EditUserFirmCa;
