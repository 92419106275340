import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import ProductCarousel from "./ProductCarousel/ProductCarousel";
import ProductDetails from "./ProductDetails/ProductDetails";

const PREFIX = "DetailsMain";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    minHeight: 850,
    height: "auto",
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minWidth: 1020,
    overflow: "hidden",
  },
}));

const DetailsMain = ({ data, urlId }) => {
  const [carouselData, setCarouselData] = useState([]);

  useEffect(() => {
    let images = data && handleCarouselImages(data);
    setCarouselData(images);
  }, [data]);

  const handleCarouselImages = (data) => {
    return data.images.length > 0 ? data.images : [{ url: data.image }];
  };

  const renderCarousel = useMemo(() => {
    return <ProductCarousel variant={carouselData} />;
  }, [carouselData]);

  return (
    <Root className={classes.root}>
      {renderCarousel}
      <ProductDetails data={data} urlId={urlId} />
    </Root>
  );
};

DetailsMain.propTypes = {
  data: PropTypes.object,
  urlId: PropTypes.string,
};

export default DetailsMain;
