import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { customerActions } from "../../../Redux/Actions";
import { findValue, queryMakerBox, concatArray } from "../../../Utils/Helpers";

import closeIcon from "../../../Images/Filter/Close.svg";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const PREFIX = "FiltersBox";

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  fab: `${PREFIX}-fab`,
  fabHeader: `${PREFIX}-fabHeader`,
  fabHeaderText: `${PREFIX}-fabHeaderText`,
  icon: `${PREFIX}-icon`,
  fabSubText: `${PREFIX}-fabSubText`,
  clearButton: `${PREFIX}-clearButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "auto",
    padding: "9px 41px 2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 20,
  },

  [`& .${classes.grid}`]: {
    width: "100%",
    height: "auto",
    maxWidth: 674,
    padding: 0,
  },

  [`& .${classes.fab}`]: {
    width: "auto",
    height: 33,
    borderRadius: 8,
    border: `1px solid ${theme.palette.darkGray.main}`,
    marginRight: 6,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: 10,
  },

  [`& .${classes.fabHeader}`]: {
    width: "100%",
    height: 16,
    margin: "4px 5px 0px 8px",
    display: "flex",
    justifyContent: "space-between",
    lineClamp: 1,
  },

  [`& .${classes.fabHeaderText}`]: {
    width: "auto",
    height: 16,
    textAlign: "left",
    fontSize: 10,
    fontWeight: 700,
  },

  [`& .${classes.icon}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 2,
    cursor: "pointer",
    marginRight: 5,
  },

  [`& .${classes.fabSubText}`]: {
    marginLeft: 8,
    textAlign: "left",
    fontSize: 10,
    fontWeight: 300,
    marginTop: -3,
    "&[search-text='Arama']": {
      maxWidth: 150,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      contains: "content",
      overflow: "hidden",
    },
  },

  [`& .${classes.clearButton}`]: {
    width: 80,
    height: 16,
    fontSize: 10,
    fontWeight: 700,
    color: "rgba(67, 65, 65, 1)",
    cursor: "pointer",
  },
}));

const FiltersBox = ({ handleClearSearchText, setPageNumber }) => {
  const filters = useSelector(({ customer }) => customer.filters);

  const results = concatArray(filters);

  const dispatch = useDispatch();
  const setFilters = (query) => dispatch(customerActions.setFilters(query));
  const clearFilters = () => dispatch(customerActions.clearFilters());
  const clearText = () => dispatch(customerActions.clearSearchText());

  const handleChange = (prop) => (event) => {
    if (prop === "searchText") {
      clearText();
      handleClearSearchText();
    } else {
      let obj = queryMakerBox(event.target, prop);
      setFilters(obj);
    }
  };

  const handleClear = () => {
    setPageNumber(1);
    clearFilters();
  };

  return (
    <Root className={classes.root}>
      <Grid className={classes.grid} container spacing={2}>
        {results.map((item, index) => (
          <div className={classes.fab} key={index}>
            <div className={classes.fabHeader} header-text={item.filter}>
              <div className={classes.fabHeaderText}>{item.filter}</div>
              <div className={classes.icon} text={item.filter}>
                <img
                  onClick={handleChange(findValue(item.filter))}
                  src={closeIcon}
                  width="5.83px"
                  height="5.83px"
                  alt={item?.filter}
                  id={item?.id?.toString()}
                  name={item?.name}
                />
              </div>
            </div>
            <div className={classes.fabSubText} search-text={item.filter}>
              {item.name}
            </div>
          </div>
        ))}
      </Grid>
      <div className={classes.clearButton} onClick={handleClear}>
        Filtreleri temizle
      </div>
    </Root>
  );
};

FiltersBox.propTypes = {
  handleClearSearchText: PropTypes.func,
  setPageNumber: PropTypes.func,
};

export default FiltersBox;
