import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import Tick from "../../../Images/Home/Tick.svg";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isPadding",
})(({ theme, isPadding }) => ({
  width: innerWidth,
  height: isPadding ? 51 : 35,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(),
  paddingTop: isPadding ? theme.spacing(3) : theme.spacing(),
  marginBottom: theme.spacing(),
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.darkGray.main,
  marginLeft: theme.spacing(),
  lineHeight: "24px",
}));

const SubContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: theme.spacing(2),
}));

const MobileDialogHeader = ({
  header,
  callback,
  paddingTop,
  notifications,
  notificationFunc,
}) => {
  return (
    <StyledContainer isPadding={paddingTop}>
      <SubContainer>
        <IconButton
          onClick={callback}
          style={{ paddingLeft: 0, width: 24, height: 24 }}>
          <ArrowBackIcon style={{ color: "#434141" }} />
        </IconButton>
        <Header>{header}</Header>
      </SubContainer>
      {notifications && (
        <StyledIconButton onClick={notificationFunc}>
          <img src={Tick} alt="" />
        </StyledIconButton>
      )}
    </StyledContainer>
  );
};

MobileDialogHeader.propTypes = {
  header: PropTypes.string,
  callback: PropTypes.func,
  paddingTop: PropTypes.bool,
};

export default MobileDialogHeader;
