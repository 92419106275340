import { createSelector } from "reselect";

export const getName = createSelector(
  (state) => state.product.name,
  (data) => {
    return data;
  }
);

export const getId = createSelector(
  (state) => state.product.id,
  (data) => {
    return data;
  }
);

export const getCart = createSelector(
  (state) => state.product.cart,
  (data) => {
    return data;
  }
);

export const getCartProducts = createSelector(
  (state) => state.product.cart.products,
  (data) => {
    return data;
  }
);
