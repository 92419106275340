import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import PriceBox from "./PriceBox";

const PREFIX = "PriceBoxes";

const classes = {
  root: `${PREFIX}-root`,
  boxes: `${PREFIX}-boxes`,
  desc: `${PREFIX}-desc`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 70,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderBottom: "1px solid #B1AEAE",
    marginTop: 10,
    "&[component='fast-order']": {
      borderBottom: "none",
    },
  },

  [`& .${classes.boxes}`]: {
    width: "100%",
    height: 41,
    display: "flex",
    marginBottom: 11,
    "&[component='fast-order']": {
      justifyContent: "space-between",
      marginBottom: 9,
    },
  },

  [`& .${classes.desc}`]: {
    width: "fit-content",
    textAlign: "left",
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    userSelect: "none",
    "& b": {
      cursor: "pointer",
    },
  },
}));

const PriceBoxes = ({ price, component, productType }) => {
  const authInfo = useSelector(({ auth }) => auth);

  return (
    <Root className={classes.root} component={component}>
      <div className={classes.boxes} component={component}>
        <PriceBox
          price={price?.retail}
          header="Perakende Fiyatı"
          color="gold"
          component={component}
          productType={productType}
          VatText = "Kdv dahil"
        />
        {authInfo?.customerInfo.role_names[0] !== "companyuser" && (
          <>
            <PriceBox
              price={price?.wholesale}
              header="Güncel Liste Fiyatı"
              color="orange"
              component={component}
              productType={productType}
              VatText = "Kdv dahil"
            />
            <PriceBox
              price={price?.view_price}
              header="Alış Fiyatı"
              color="green"
              component={component}
              productType={productType}
              VatText = "Kdv hariç"
            />
          </>
        )}
      </div>
      {/* <div className={classes.desc}>
        Bu ürünü <strong>Temmuz 2021</strong> fiyat listesinden almaktasınız{" "}
      </div>
      <div className={classes.desc}>
        109,07 TL x 3 ay’a varan Taksit{" "}
        <b onClick={() => handleClick()}>seçenekleri</b>
      </div> */}
    </Root>
  );
};

PriceBoxes.propTypes = {
  price: PropTypes.any,
  component: PropTypes.string,
  productType: PropTypes.number,
};

export default PriceBoxes;
