import React from "react";
import Routes from "./Routes";
import B2BToastContainer from "./Ui/Notify/B2BToastContainer";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6E3564",
    },
    secondary: {
      main: "#616161",
    },
    darkGray: {
      main: "#434141",
      mid : "#c4c4c4",
      light: "#e7e7e7"
    },
    colors: {
      lightGreen: "#D2F5E4",
      green: "#10C66C",
      lightTurquoise: "#B3E8E5",
      turquoise: "#2F8F9D",
      gray: '#B1AEAE',
      black: '#000000'
    },
  },
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xs: 0,
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: [
      "Roboto",
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Montserrat",
    ].join(","),
    h3: {
      fontSize: 34,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.18,
      letterSpacing: "normal",
      color: "#333333",
    },
    h5: {
      fontSize: 20,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.9,
      letterSpacing: "normal",
      color: "#333333",
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.31,
      letterSpacing: "normal",
      color: "#333333",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#707070",
    },
    body1: {
      fontSize: 14,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#333333",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: "white",
        "&$focused": {
          backgroundColor: "white",
        },
      },
    },
  },
});

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
        <B2BToastContainer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
