import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const PREFIX = "RatingTextField";

const classes = {
  label: `${PREFIX}-label`,
  textField: `${PREFIX}-textField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({theme}) => ({
  [`& .${classes.label}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    paddingLeft: 9,
    marginBottom: 5.75,
    marginTop: 5,
  },

  [`& .${classes.textField}`]: {
    width: 377,
    height: 122.27,
    marginBottom: 15,
    "& .MuiFilledInput-root": {
      backgroundColor: "#F5F5F5",
      border: "none",
      borderRadius: 8,
      padding: "10px 12px 10px",
    },
    // "& .MuiFilledInput-multiline": {
    // },
    "& .MuiInputBase-input": {
      padding: "3.5px 8px",
      fontSize: 14,
      fontWeight: 300,
      backgroundColor: "#F5F5F5",
      "&:not(:focus)": {
        cursor: "pointer",
      },
      "&:hover, &:focus, &:active": {
        backgroundColor: "#F5F5F5",
      },
    },
  },
}));

const RatingTextField = ({ handleChange, text }) => {
  return (
    <Root>
      <Typography className={classes.label}>Yorum Yaz</Typography>
      <TextField
        multiline
        fullWidth
        minRows={5}
        value={text}
        onChange={handleChange}
        variant="filled"
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Root>
  );
};

RatingTextField.propTypes = {
  handleChange: PropTypes.func,
  text: PropTypes.string,
};

export default RatingTextField;
