import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeaderClose from "../../../Ui/Dialog/Tools/MobileDialogHeaderClose";
import PhoneArea from "../../../Ui/Fields/PhoneArea";
import VerificationStep from "../../../Ui/Dialog/VerificationStep";

import { authService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const UpdatePhoneMob = ({ handleClose, open }) => {
  const [values, setValues] = useState({
    gsm: "",
  });
  const [error, setError] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        setValues({
          gsm: "",
        });
        setError(false);
        setStep(0);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCheck = () => {
    if (values.gsm.length !== 12) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      let phoneCode = "+905";
      let phone = phoneCode.concat(values.gsm).replace(/\s/g, "");
      let query = {
        type: 0,
        phone,
      };
      const response = await authService.createConfirmCode(query);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        setStep(1);
      } else {
        console.log(response);
      }
    }
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      {step === 0 ? (
        <>
          <MobileDialogHeaderClose
            text="Cep Telefonu Numara Değişikliği"
            callBack={handleClose}
          />
          <ul style={{ margin: 0, paddingLeft: 20 }}>
            <li style={{ fontSize: 10, fontWeight: 300, color: "#6A6868" }}>
              Yeni cep numaranızı onaylamanız gerekmektedir.
            </li>
          </ul>
          <PhoneArea
            label="Yeni Numaranız"
            handleChange={handleChange}
            values={values}
            prop="gsm"
            error={error}
          />
          <MobileDialogButton
            variant="contained"
            disableRipple
            onClick={handleSubmit}>
            Tamam
          </MobileDialogButton>
        </>
      ) : (
        <VerificationStep
          gsm={"+905".concat(values.gsm).replace(/\s/g, "")}
          setStep={setStep}
          text="Telefon numaranıza"
          onClose={handleClose}
        />
      )}
    </StyledDialog>
  );
};

UpdatePhoneMob.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default UpdatePhoneMob;
