import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { MobileDialogButton, Transition } from '../../../Utils/Tools'
import MobileDialogHeaderClose from '../Tools/MobileDialogHeaderClose'
import AutoCompleteArea from '../../Fields/AutocompleteArea'
import Loading from '../../../Utils/Loading'

import { companyService, userService } from '../../../Services'
import {
  customerActions,
  favoriteActions,
  productActions,
  authActions,
} from '../../../Redux/Actions'
import { generalConstants } from '../../../Utils/Constants'
import { getUsersArr } from '../../../Utils/Helpers'

const innerWidth = window.innerWidth

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: innerWidth,
    height: 'auto',
    maxHeight: 'calc(100vh-80px)',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}))

const StyledBackDrop = styled(Backdrop, {
  name: 'MuiModal',
  slot: 'Backdrop',
  overridesResolver: (props, styles) => {
    return styles.backdrop
  },
})({
  zIndex: -1,
  backdropFilter: 'blur(2px)',
  backgroundColor: 'rgba(0,0,0,0.005)',
})

const AdminAddressSelection = ({ open, handleClose, callBack }) => {
  const dispatch = useDispatch()

  const setUserCurrent = (query) =>
    dispatch(customerActions.setUserCurrent(query))

  const setSelectedUser = (user) => dispatch(customerActions.selectedUser(user))

  const getFavoritesAdmin = (query) =>
    dispatch(favoriteActions.getFavoritesAdmin(query))

  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id))

  const signOut = () => dispatch(authActions.signout())

  const customer = useSelector(({ customer }) => customer)

  const query = {
    pageNumber: 1,
    pageSize: 1000,
    sortField: 'id',
    sortOrder: 'asc',
    filter: {
      company_id: [],
    },
  }
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [textError, setTextError] = useState(false)
  const [usersRaw, setUsersRaw] = useState(null)
  const [userAddress, setUserAddress] = useState(null)
  const [firms, setFirms] = useState([
    { name: 'Lütfen firma seçinizi', id: 0.5 },
  ])
  // const [addresses, setAddresses] = useState([
  //   { name: "Lütfen şube seçiniz", id: 0.5 },
  // ]);
  const [users, setUsers] = useState([
    { name: 'Lütfen kullanıcı seçiniz', id: 0.5 },
  ])

  const [values, setValues] = useState({
    firm: { name: 'Lütfen firma seçinizi', id: 0.5 },
    // branch: { name: "Lütfen şube seçiniz", id: 0.5 },
    user: { name: 'Lütfen kullanıcı seçiniz', id: 0.5 },
  })

  let stringifiedFirms = JSON.stringify(values.firm)

  useLayoutEffect(() => {
    let active = true
    if (active) {
      if (open) {
        userAddress && setUserAddress(null)
        getCompanyList(query)
        setError(false)
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    let active = true
    if (active) {
      if (
        customer.selectedUser &&
        customer.currentAddress &&
        customer.currentCompany
      ) {
        let { id, full_name } = customer.selectedUser
        setValues({
          firm: {
            name: customer?.currentCompany?.name,
            id: customer?.currentCompany?.id,
          },
          // branch: {
          //   name: customer?.currentAddress?.name,
          //   id: customer?.currentAddress?.id,
          // },
          user: { name: full_name, id: id },
        })
      } else return
    }
    return () => {
      active = false
    }
  }, [customer])

  useEffect(() => {
    let active = true
    if (active) {
      if (values.firm) {
        let id = values.firm.id
        if (id !== 0.5) {
          // let adminQuery = { ...query, filter: { company_id: id } };
          // getCompanyAddresses(adminQuery);
          getUsers(query, id)
        }
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFirms, open])

  useEffect(() => {
    let active = true
    if (active) {
      let activeId = customer?.selectedUser?.company?.id
      if (values?.firm?.id !== 0.5 && +activeId !== +values?.firm?.id) {
        if (values.user.id !== 0.5) {
          setValues({
            ...values,
            // branch: { name: "Lütfen şube seçiniz", id: 0.5 },
            user: { name: 'Lütfen kullanıcı seçiniz', id: 0.5 },
          })
        }
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.firm?.id])

  useEffect(() => {
    let active = true
    if (active) {
      if (values?.user && usersRaw) {
        let id = values?.user.id
        if (id !== 0.5) {
          let currentUser = usersRaw.filter((u) => u.id === values.user.id)
          let address = currentUser?.length > 0 ? currentUser[0]?.address : null
          address ? setUserAddress('exists') : setUserAddress('notExists')
        }
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.user?.id])

  const getCompanyList = async (query) => {
    setLoading(true)
    const response = await companyService.getCompanies(query)
    if (response.data.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ['name'].every((k) => t[k] === v[k])) === i
      )
      let finalData = notDuplicatedData.filter((item) => item.name !== 'None')
      setFirms(finalData)
      setLoading(false)
    } else {
      setFirms([{ name: 'Sonuç bulunamadı', id: 0.5 }])
      setLoading(false)
    }
  }

  // const getCompanyAddresses = async (query, id) => {
  //   setLoading(true);
  //   const response = await addressService.getAdminUserAndCompanyAddresses(
  //     query
  //   );
  //   if (response?.data?.status === generalConstants.STATUS_TRUE) {
  //     let data = response.data.addresses;
  //     let newAddresses = data.filter((item) => item.type === 0);
  //     setAddresses(newAddresses);
  //     setLoading(false);
  //   } else {
  //     setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
  //     setLoading(false);
  //   }
  // };

  const getUsers = async (query, id) => {
    setLoading(true)
    const response = await userService.getAllUsers_(query, id, true)
    if (response.data.status === generalConstants.STATUS_TRUE) {
      let newArr = getUsersArr(response.data.users)
      let notDuplicatedData = newArr.filter(
        (v, i, a) =>
          a.findIndex((t) => ['name'].every((k) => t[k] === v[k])) === i
      )
      let finalData = notDuplicatedData.filter((item) => item.name !== 'None')
      setUsers(finalData)
      setUsersRaw(response.data.users)
      setLoading(false)
    } else {
      setUsers([{ name: 'Sonuç bulunamadı', id: 0.5 }])
      setLoading(false)
    }
  }

  const handleCheck = () => {
    if (!values.firm || !values.user) {
      setError(true)
      return true
    } else if (
      // values.branch.id === 0.5 ||
      values.firm.id === 0.5 ||
      values.user.id === 0.5
    ) {
      setTextError(true)
      return true
    } else {
      setError(false)
      setTextError(false)
      return false
    }
  }

  const handleSubmit = () => {
    let isError = handleCheck()
    if (!isError && !textError) {
      let currentUser = usersRaw.filter((u) => u.id === values.user.id)
      let currentFirm = firms.filter((f) => f.id === values.firm.id)
      let currentAddress = currentUser?.length > 0 && currentUser[0]?.address
      let query = {
        pageNumber: 1,
        pageSize: 10,
        sortField: 'id',
        sortOrder: 'desc',
        filter: {
          user_id: currentUser.id,
        },
      }
      let query_ = {
        address: currentAddress,
        company: currentFirm[0],
      }
      setSelectedUser(currentUser[0])
      setUserCurrent(query_)
      getAdminCart(currentUser[0].id)
      getFavoritesAdmin(query)
      setTimeout(() => handleClose(), 400)
    }
  }

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeaderClose text='Firma Değiştir' callBack={callBack} />
      <Loading open={loading} />
      <AutoCompleteArea
        data={firms}
        label='Firma'
        setValues={setValues}
        prop='firm'
        values={values}
        error={error}
        customWidth={innerWidth * 0.9}
      />
      {/* <AutoCompleteArea
        data={addresses}
        label="Şube veya Adres"
        setValues={setValues}
        prop="branch"
        values={values}
        error={error}
        customWidth={innerWidth * 0.9}
      /> */}
      <AutoCompleteArea
        data={users}
        label='Kullanıcılar'
        setValues={setValues}
        prop='user'
        values={values}
        error={error}
        customWidth={innerWidth * 0.9}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 'auto',
          mt: (theme) => theme.spacing(),
        }}>
        {textError && (
          <Typography
            style={{ marginBottom: 16, fontSize: 12, color: '#FB0505' }}>
            Lütfen geçerli seçimler yapınız!
          </Typography>
        )}
        {userAddress === 'notExists' && (
          <Typography
            style={{ marginBottom: 16, fontSize: 12, color: '#FB0505' }}>
            Kullanıcı adresi bulunamadı!
          </Typography>
        )}
      </Box>
      <MobileDialogButton
        disabled={userAddress === 'notExists'}
        variant='contained'
        disableRipple
        onClick={handleSubmit}>
        Tamam
      </MobileDialogButton>
      <MobileDialogButton disableRipple onClick={signOut}>
        Çıkış Yap
      </MobileDialogButton>
    </StyledDialog>
  )
}

AdminAddressSelection.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  callBack: PropTypes.func,
}

export default AdminAddressSelection
