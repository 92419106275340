import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeaderClose from "../../../Ui/Dialog/Tools/MobileDialogHeaderClose";
import TextArea from "../../../Ui/Fields/TextArea";
import PhoneArea from "../../../Ui/Fields/PhoneArea";

import { userService } from "../../../Services";
import { authActions } from "../../../Redux/Actions";
import { generalConstants } from "../../../Utils/Constants";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    maxHeight: "calc(100vh-80px)",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const UserInfoMob = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const signOut = () => dispatch(authActions.signout());

  const userInfo = useSelector(({ auth }) => auth.customerInfo);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gsm: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setValues({
        ...values,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email,
        gsm: userInfo.gsm.substring(4),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleCheck = () => {
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
    if (
      values.first_name.length === 0 ||
      values.last_name.length === 0 ||
      values.email.length === 0 ||
      !emailRegex.test(values.email) ||
      newPhone.length !== 13
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      let phoneCode = "+905";
      let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
      let userQuery = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        gsm: newPhone,
      };
      const response = await userService.updateUser(userQuery);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Kullanıcı bilgileri başarılı bir şekilde güncellendi.");
        setTimeout(() => {
          handleClose();
          signOut();
        }, 1000);
      } else {
        toast.error(response?.data?.message);
      }
    }
  };

  const handleClose = () => {
    setError(false);
    onClose();
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeaderClose
        text="Kullanıcı Bilgileri"
        callBack={handleClose}
      />
      <TextArea
        label="Ad"
        handleChange={handleChange}
        values={values}
        prop="first_name"
        error={error}
      />
      <TextArea
        label="Soyad"
        handleChange={handleChange}
        values={values}
        prop="last_name"
        error={error}
      />
      <TextArea
        label="Eposta"
        handleChange={handleChange}
        values={values}
        prop="email"
        error={error}
      />
      <PhoneArea
        label="Telefon"
        handleChange={handleChange}
        values={values}
        prop="gsm"
        error={error}
      />
      <MobileDialogButton disableRipple onClick={handleSubmit}>
        Tamam
      </MobileDialogButton>
    </StyledDialog>
  );
};

UserInfoMob.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default UserInfoMob;
