import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const PREFIX = "TabButtons";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  selected: `${PREFIX}-selected`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
  },

  [`& .${classes.button}`]: {
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.darkGray.main,
    width: 248,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  [`& .${classes.selected}`]: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    borderBottom: "3px solid #6E3564",
  },
}));

const TabButtons = ({ setIndex, index }) => {
  const handleTabValue = (val) => setIndex(val);
  return (
    <Root className={classes.root}>
      <div
        onClick={() => handleTabValue("0")}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === "0" && true,
        })}>
        Açıklamalar
      </div>
      <div
        onClick={() => handleTabValue("1")}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === "1" && true,
        })}>
        Özellikler
      </div>
      <div
        onClick={() => handleTabValue("2")}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === "2" && true,
        })}>
        Değerlendirmeler
      </div>
    </Root>
  );
};

TabButtons.propTypes = {
  setIndex: PropTypes.func,
  index: PropTypes.string,
};

export default TabButtons;
