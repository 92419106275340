import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Fade from "@mui/material/Fade";

import MobileTabs from "./MobileResults.js/MobileTabs";

import { productService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";
import { customerActions } from "../../Redux/Actions";
import { searchQueryMaker } from "../../Utils/Helpers";
import MobileTabPanel from "./MobileResults.js/MobileTabPanel";
import MobileTabContent from "./MobileResults.js/MobileTabContent";
import MobileFilters from "./MobileDialogs/MobileFilters";
import Loading from "../../Utils/Loading";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  height: "100vh",
}));

const CloseAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  position: "absolute",
  right: theme.spacing(),
  cursor: "pointer",
  zIndex: open ? 1 : 9999,
}));

const HeaderPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 55,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(),
  borderBottom: "1px solid #B1AEAE",
  paddingBottom: theme.spacing(),
}));

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  // width: "100%",
  flex: 1,
  height: 26,
  "& .MuiInputBase-root": {
    height: 26,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 4,
    height: 17,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 4,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
    "&::placeholder": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      opacity: 1,
      paddingTop: "2px !important",
    },
  },
}));

const MobileFiltersPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setFilterTab = (value) =>
    dispatch(customerActions.setFilterTabIndex(value));
  const setText = (text) => dispatch(customerActions.setSearchText(text));
  const filterText = useSelector(({ customer }) => customer.filters.searchText);

  const customer = useSelector(({ customer }) => customer);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer?.filters.tabValue !== "") {
        setLoading(true);
        setHasMore(true);
        setProducts([]);
      } else {
        setFilterTab(0);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      handleFunctions();
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const toggleFilters = () => setOpenFilters((prev) => !prev);

  const handleChange = (event, newValue) => {
    setFilterTab(newValue);
  };

  const handleFunctions = () => {
    if (products.length === 0) {
      let filters = customer?.filters;
      const serviceQuery = handleQuery(filters, customer);
      if (filters.tabValue === 0) {
        getProducts(serviceQuery);
      } else if (filters.tabValue === 1) {
        handleBestSellers(serviceQuery);
      } else if (filters.tabValue === 2) {
        handleBestSellersArea(serviceQuery);
      } else if (filters.tabValue === 3) {
        handleBestSellersUser(serviceQuery);
      } else if (filters.tabValue === 4) {
        let newQuery = {
          ...serviceQuery,
          filter: { ...serviceQuery.filter, tag: "yeni" },
        };
        getNewArrivals(newQuery);
      } else {
        setLoading(false);
      }
    }
  };

  const getProducts = (query) => {
    if (hasMore) {
      productService
        .productSkuList(query)
        .then((res) => {
          commonFunc(res, query);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const getNewArrivals = (query) => {
    if (hasMore) {
      productService
        .productSkuList(query)
        .then((res) => {
          commonFunc(res, query);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleBestSellers = (query) => {
    if (hasMore) {
      productService
        .getBestSellers(query)
        .then((res) => {
          commonFunc(res, query);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleBestSellersArea = (query) => {
    if (hasMore) {
      productService
        .getBestSellersArea(query)
        .then((res) => {
          commonFunc(res, query);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleBestSellersUser = (query) => {
    if (hasMore) {
      productService
        .getBestSellersUser(query)
        .then((res) => {
          commonFunc(res, query);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const commonFunc = (res, query) => {
    if (res.data.status === generalConstants.STATUS_TRUE) {
      let data = products.concat(res.data.product_skus);
      setProducts(data);
      setLoading(false);
      if (res.data.product_skus.length < 20) {
        setHasMore(false);
      } else {
        setQuery({
          ...query,
          pageNumber: query.pageNumber + 1,
        });
      }
    } else {
      setLoading(false);
    }
  };

  const handleQuery = (filters, customer) => {
    let query = searchQueryMaker(filters);
    const initialQuery = {
      pageNumber: 1,
      pageSize: 20,
      sortField: "id",
      sortOrder: "desc",
      filter: {
        brands: query.brands,
        subcategories: query.categories,
        sizes: query.sizes,
        colors: query.colors,
        shapes: query.shapes,
        rating: query.rating,
        on_sale: 1,
        // type: query.type,
        // tag: query.tag
      },
    };
    if (customer.isAdmin) {
      let query_ = {
        ...query,
        filter: {
          ...query.filter,
          company_id: customer.currentCompany.id,
        },
      };
      let initialQuery_ = {
        ...initialQuery,
        filter: {
          ...initialQuery.filter,
          company_id: customer.currentCompany.id,
        },
      };
      return querySetter(query_, initialQuery_);
    } else {
      return querySetter(query, initialQuery);
    }
  };

  const querySetter = (query, initialQuery) => {
    if (!query.searchText) {
      setQuery(initialQuery);
      return initialQuery;
    } else if (query.searchText === "") {
      setQuery(initialQuery);
      return initialQuery;
    } else {
      let newQuery = {
        ...initialQuery,
        keyword: query.searchText,
      };
      setQuery(newQuery);
      return newQuery;
    }
  };

  const onBackClick = () => history.goBack();

  const clearSearchText = () => setText("");

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value.length >= 3) {
        setText(e.target.value);
      }
    }
  };

  const handleKeyword = (e) => setText(e.target.value);

  return (
    <Root>
      <Loading open={loading} />
      <HeaderPart>
        <ArrowBackIcon
          onClick={onBackClick}
          style={{ fill: "#434141", height: 24, width: 24, marginRight: 16 }}
        />
        <StyledTextField
          fullWidth
          value={filterText}
          onChange={handleKeyword}
          onKeyDown={keyPress}
          placeholder="Ara"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {filterText?.length > 0 && (
                  <CloseAdornment
                    open={openFilters}
                    position="end"
                    onMouseDown={clearSearchText}>
                    <Fade in={filterText?.length > 0}>
                      <CloseIcon style={{ fill: "#8E8E8E" }} />
                    </Fade>
                  </CloseAdornment>
                )}
              </>
            ),
          }}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
        <FilterAltRoundedIcon
          onClick={toggleFilters}
          style={{ fill: "#434141", height: 24, width: 24, marginLeft: 16 }}
        />
      </HeaderPart>
      <MobileTabs
        value={customer?.filters?.tabValue}
        handleChange={handleChange}
      />
      <MobileTabPanel value={customer?.filters?.tabValue} index={0}>
        <MobileTabContent
          products={products}
          query={query}
          getProducts={getProducts}
          hasMore={hasMore}
          loading={loading}
        />
      </MobileTabPanel>
      <MobileTabPanel value={customer?.filters?.tabValue} index={1}>
        <MobileTabContent
          products={products}
          query={query}
          getProducts={handleBestSellers}
          hasMore={hasMore}
          loading={loading}
        />
      </MobileTabPanel>
      <MobileTabPanel value={customer?.filters?.tabValue} index={2}>
        <MobileTabContent
          products={products}
          query={query}
          getProducts={handleBestSellersArea}
          hasMore={hasMore}
          loading={loading}
        />
      </MobileTabPanel>
      <MobileTabPanel value={customer?.filters?.tabValue} index={3}>
        <MobileTabContent
          products={products}
          query={query}
          getProducts={handleBestSellersUser}
          hasMore={hasMore}
          loading={loading}
        />
      </MobileTabPanel>
      <MobileTabPanel value={customer?.filters?.tabValue} index={4}>
        <MobileTabContent
          products={products}
          query={query}
          getProducts={getNewArrivals}
          hasMore={hasMore}
          loading={loading}
        />
      </MobileTabPanel>
      <MobileFilters open={openFilters} handleClose={toggleFilters} />
    </Root>
  );
};

export default MobileFiltersPage;
