import { createSelector } from "reselect";

export const tabIndex = createSelector(
  (state) => state.customer.tabIndex,
  (index) => {
    return index;
  }
);

export const filterQuery = createSelector(
  (state) => state.customer.filters,
  (filters) => {
    return filters;
  }
);
