import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import "./bubble.css";

const StyledText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 300,
  marginTop: 2,
  userSelect: "none",
}));

const Bubble = ({ text }) => {
  return (
    <div className="bubble me" style={{ marginTop: 14 }}>
      <StyledText>Lütfen {text} seçiniz</StyledText>
    </div>
  );
};

Bubble.propTypes = {
  text: PropTypes.string,
};

export default Bubble;
