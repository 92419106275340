import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import AppBar from "@mui/material/AppBar";

import { favoriteActions } from "../../../Redux/Actions";

import Loading from "../../../Utils/Loading";

const innerWidth = window.innerWidth;

const ButtonsContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: innerWidth,
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
  zIndex: 1,
  top: theme.spacing(2),
}));

const StyledIconButton = styled(IconButton)(() => ({
  backgroundColor: "rgba(255,255,255,0.5)",
  padding: 7,
  "&:hover, &:focus, &:active": {
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

const FavoriteUnChecked = styled(FavoriteBorderRoundedIcon)(() => ({
  width: 30,
  height: 30,
  fill: "#000",
}));

const FavoriteChecked = styled(FavoriteRoundedIcon)(({ theme }) => ({
  width: 30,
  height: 30,
  fill: theme.palette.primary.main,
}));

const MobileAppBar = ({ product }) => {
  const history = useHistory();

  const goBack = () => history.goBack();

  const dispatch = useDispatch();

  const [data, setData] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      if (product) {
        setData(product);
      } else {
        console.log("éno product");
      }
    }
    return () => {
      active = false;
    };
  }, [product]);

  const { favorites, loading } = useSelector(({ favorites }) => favorites);
  const {isAdmin, selectedUser} = useSelector(({ customer }) => customer);

  const toggleFavorite = (query, bool, data) =>
    dispatch(favoriteActions.toggleFavorite(query, bool, data));

  let isIncluded = favorites?.some((item) => item.id === data?.id);

  const handleFavorite = () => {
    if (isAdmin) return;
    let query = {
      product_id: data?.id,
      user_id: selectedUser?.id
    };
    toggleFavorite(query, isAdmin, data);
  };

  return (
    <AppBar>
      <Loading open={loading} />
      <ButtonsContainer>
        <StyledIconButton size="large" onClick={goBack}>
          <ArrowBackIcon style={{ fill: "black", height: 30, width: 30 }} />
        </StyledIconButton>
        <StyledIconButton size="large" onClick={handleFavorite}>
          {isIncluded ? <FavoriteChecked /> : <FavoriteUnChecked />}
        </StyledIconButton>
      </ButtonsContainer>
    </AppBar>
  );
};

MobileAppBar.propTypes = {
  product: PropTypes.object,
}

export default MobileAppBar;
