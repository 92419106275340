import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { a11yProps } from "../../../Utils/Helpers";

const PREFIX = "AppBarTabs";

const classes = {
  tab: `${PREFIX}-tab`,
  indicator: `${PREFIX}-indicator`,
  tabs: `${PREFIX}-tabs`,
};

const StyledTabs = styled(Tabs)(() => ({
  [`& .${classes.tab}`]: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: "capitalize",
    color: "rgba(106, 104, 104, 1)",
    width: 170,
    paddingLeft: 0,
    paddingRight: 0,
    "&.MuiTab-root": {
      minWidth: 110,
    },
  },

  [`& .${classes.indicator}`]: {
    backgroundColor: "black",
  },

  [`&.${classes.tabs}`]: {
    maxWidth: 1000,
    "& .Mui-selected": {
      fontSize: 14,
      fontWeight: 700,
      color: "rgba(67, 65, 65, 1)",
    },
  },
}));

const AppBarTabs = ({ value, handleChange }) => {
  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      aria-label="filter-tabs"
      classes={{
        indicator: classes.indicator,
      }}
      className={classes.tabs}>
      <Tab className={classes.tab} label="Tümü" {...a11yProps(0)} />
      <Tab className={classes.tab} label="Çok Satanlar" {...a11yProps(1)} />
      <Tab
        className={classes.tab}
        label="Bölgende Çok Satanlar"
        {...a11yProps(2)}
      />
      <Tab
        className={classes.tab}
        label="Senin Çok Sattıkların"
        {...a11yProps(3)}
      />
      <Tab className={classes.tab} label="Yeni Gelenler" {...a11yProps(4)} />
    </StyledTabs>
  );
};

AppBarTabs.propTypes = {
  value: PropTypes.number,
  handleChange: PropTypes.func,
};

export default AppBarTabs;
