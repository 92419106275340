import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import Box from "@mui/material/Box";

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ theme, mobile }) => ({
  "& .MuiDialog-paper": {
    width: !mobile && 400,
    height: mobile ? 300 : 400,
    padding: theme.spacing(2),
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "approve" && prop !== "center",
})(({ center, approve }) => ({
  fontFamily: "Inter",
  fontWeight: approve ? 400 : 700,
  fontSize: approve ? 14 : 18,
  lineHeight: "22px",
  color: "#64748B",
  textAlign: center && "center",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: 291,
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(),
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "cancel",
})(({ theme, cancel }) => ({
  padding: 0,
  textTransform: "none",
  width: cancel ? 100 : 181,
  height: 44,
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "22px",
  color: "#fff",
  backgroundColor: cancel ? "#B1AEAE" : theme.palette.primary.main,
  "&:hover": {
    backgroundColor: cancel ? "#B1AEAE" : theme.palette.primary.main,
  },
}));

const CartDeleteConfirmation = ({ open, onClose, deleteCart, mobile }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      keepMounted={false}
      mobile={mobile}>
      <StyledBox>
        <DeleteSweepIcon style={{ fontSize: 70 }} />
      </StyledBox>
      <StyledBox>
        <Text center>Sepetinizdeki ürünler silinecek</Text>
      </StyledBox>
      <StyledBox>
        <Text approve>Onaylıyor musunuz?</Text>
      </StyledBox>
      <ButtonContainer>
        <StyledButton cancel onClick={onClose}>
          İptal
        </StyledButton>
        <StyledButton onClick={deleteCart}>Onayla</StyledButton>
      </ButtonContainer>
    </StyledDialog>
  );
};

CartDeleteConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  deleteCart: PropTypes.func,
  mobile: PropTypes.bool,
};

export default CartDeleteConfirmation;
