import React, { useState, useEffect, useLayoutEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import TextArea from "../../../../Ui/Fields/TextArea";
import PhoneArea from "../../../../Ui/Fields/PhoneArea";
import AutoCompleteArea from "../../../../Ui/Fields/AutocompleteArea";
import AutoCompleteMultiple from "../../../../Ui/Fields/AutoCompleteMultiple";

import { generalConstants } from "../../../../Utils/Constants";
import {
  companyService,
  addressService,
  userService,
} from "../../../../Services";
import Loading from "../../../../Utils/Loading";

const PREFIX = "AddUser";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  inputContainer: `${PREFIX}-inputContainer`,
  submitButton: `${PREFIX}-submitButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    // minHeight: 555,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: 10,
    userSelect: "none",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 20,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const AddUser = ({
  open,
  onClose,
  roles,
  newGetUsers,
  companyId,
  isAdmin,
  query_,
}) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const adminArr = ["Admin", "Seller"];
  const companyArr = ["Firma Kullanıcı", "Firma Yetkili"];
  const query = {
    pageNumber: 1,
    pageSize: 1000,
    sortField: "id",
    sortOrder: "asc",
    filter: {},
  };

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gsm: "",
    firm: { name: "Lütfen firma seçinizi", id: 0.5 },
    branch: [],
    roles: { name: "Lütfen rol seçiniz", id: 0.5 },
    defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
  });

  const [error, setError] = useState(false);
  const [firms, setFirms] = useState([
    { name: "Lütfen firma seçinizi", id: 0.5 },
  ]);
  const [addresses, setAddresses] = useState([]);

  let stringifiedFirms = JSON.stringify(values.firm);

  useLayoutEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        getCompanyList(query);
        setValues({
          first_name: "",
          last_name: "",
          email: "",
          gsm: "",
          firm: { name: "Lütfen firma seçinizi", id: 0.5 },
          branch: [],
          roles: { name: "Lütfen rol seçiniz", id: 0.5 },
          defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.firm) {
        let id = values.firm.id;
        if (id !== 0.5) {
          let adminQuery = { ...query, filter: { company_id: id } };
          getCompanyAddresses(adminQuery);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFirms]);

  const getCompanyList = async (query) => {
    setLoading(true);
    const response = await companyService.getCompanies(query);
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies;
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ["name"].every((k) => t[k] === v[k])) === i
      );
      let finalData = notDuplicatedData.filter((item) => item.name !== "None");
      setFirms(finalData);
      setLoading(false);
    } else {
      setFirms([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const getCompanyAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.addresses;
      let companyBranches = data.filter((d) => d.type === 0);
      setAddresses(companyBranches);
      setLoading(false);
    } else {
      setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleCheck = () => {
    let phoneCode = "+905";
    let gsm = phoneCode.concat(values.gsm).replace(/\s/g, "");
    let truthyCond =
      values.first_name.length === 0 ||
      values.last_name.length === 0 ||
      values.email.length === 0 ||
      !emailRegex.test(values.email) ||
      gsm.length !== 13 ||
      !values.roles;

    let finalCond = adminArr.includes(values?.roles?.name)
      ? truthyCond
      : values?.roles?.name === "Firma Yetkili"
      ? truthyCond || !values.firm
      : truthyCond || !values.firm || !values.branch || !values.defaultAddress;

    if (finalCond) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
    let addrIds = values.branch.map((item) => item.id.toString());
    let adminQuery = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      gsm: newPhone,
      roles: [`${values.roles.id}`],
    };
    let companyAdminQuery = {
      ...adminQuery,
      company_id: values.firm.id,
      default_address_id: values.defaultAddress.id,
    };
    let userQuery = {
      ...companyAdminQuery,
      addresses: addrIds,
    };
    if (!isError) {
      let role_ = values?.roles?.name;
      let selectedQuery =
        role_ === "Firma Kullanıcı"
          ? userQuery
          : role_ === "Firma Yetkili"
          ? companyAdminQuery
          : adminQuery;
      setLoading(true);
      const response = await userService.addUser(selectedQuery);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Kullanıcı başarılı bir şekilde eklendi");
        handleClose();
        let _query = { ...query_, pageNumber: 1 };
        newGetUsers(_query, companyId, isAdmin, true);
        setLoading(false);
      } else {
        toast.error(response?.data?.message);
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setError(false);
    onClose();
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={handleClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Yeni Kullanıcı</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Loading open={loading} />
        <div className={classes.inputContainer}>
          <TextArea
            label="Ad"
            handleChange={handleChange}
            values={values}
            prop="first_name"
            component="add user"
            error={error}
          />
          <TextArea
            label="Soyad"
            handleChange={handleChange}
            values={values}
            prop="last_name"
            component="add user"
            error={error}
          />
          <TextArea
            label="Eposta"
            handleChange={handleChange}
            values={values}
            prop="email"
            component="add user"
            error={error}
          />
          <PhoneArea
            label="Telefon"
            handleChange={handleChange}
            values={values}
            prop="gsm"
            component="add user"
            error={error}
          />
          <AutoCompleteArea
            data={roles}
            label="Yetki Grubu"
            setValues={setValues}
            prop="roles"
            values={values}
            component="add user"
            error={error}
          />
          {companyArr.includes(values?.roles?.name) && (
            <AutoCompleteArea
              data={firms}
              label="Firma"
              setValues={setValues}
              prop="firm"
              values={values}
              component="add user"
              error={error}
            />
          )}
          {values?.roles?.name === "Firma Kullanıcı" && (
            <AutoCompleteMultiple
              data={addresses}
              label="Yetkili Adresler"
              setValues={setValues}
              prop="branch"
              values={values}
              error={error}
              defVal={values?.branch}
            />
          )}
          {(values?.roles?.name === "Firma Kullanıcı" ||
            values?.roles?.name === "Firma Yetkili") && (
              <AutoCompleteArea
                data={
                  values?.roles?.name === "Firma Kullanıcı"
                    ? values?.branch
                    : addresses
                }
                label="Tanımlı Adres"
                setValues={setValues}
                prop="defaultAddress"
                values={values}
                error={error}
              />
            )}
          <Button
            variant="contained"
            disableRipple
            className={classes.submitButton}
            onClick={handleSubmit}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

AddUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roles: PropTypes.array,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
  companyId: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default AddUser;
