import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import SearchArea from "../../../Ui/Fields/SearchArea";
import TableHeader from "./Services/TableHeader";
import TableRow from "./Services/TableRow";

const PREFIX = "Services";

const classes = {
  root: `${PREFIX}-root`,
  mainCard: `${PREFIX}-mainCard`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    marginTop: 14,
  },

  [`& .${classes.mainCard}`]: {
    width: 1212,
    height: "auto",
    marginTop: 10,
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16);",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    padding: 5,
    paddingTop: 35,
    paddingBottom: 10,
  },
}));

const Services = ({ data, modalRef }) => {
  return (
    <Root className={classes.root}>
      <SearchArea placeholder="Servis kayıtlarında ara" />
      <div className={classes.mainCard}>
        <TableHeader />
        {data.map((item, index) => (
          <TableRow key={index} item={item} ref={modalRef} />
        ))}
      </div>
    </Root>
  );
};

Services.propTypes = {
  data: PropTypes.array,
};

export default Services;
