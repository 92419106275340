import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

import deleteIcon from "../../../Images/Cart/Delete.svg";
import updateIcon from "../../../Images/Cart/Pencil.svg";

const Root = styled("div")(() => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
}));

const FeaturesButton = styled(Button)(({ theme }) => ({
  width: 150,
  height: 24,
  borderRadius: 6,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  textTransform: "none",
  fontSize: 12,
  paddingTop: 1.5,
  paddingBottom: 1.5,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&[features-null='true']": {
    backgroundColor: "#fff",
    border: "1px solid #6E3564",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

const SubContainer = styled("div")(() => ({
  display: "flex",
  width: 60,
  justifyContent: "flex-end",
}));

const ButtonsArea = ({
  onClickHandler,
  deleteProduct,
  userId,
  openDialog,
  extended,
  isFeatures,
  product,
}) => {
  return (
    <Root>
      <FeaturesButton
        features-null={isFeatures ? "false" : "true"}
        endIcon={
          extended ? (
            <ArrowDropUp style={{ marginBottom: 3 }} />
          ) : (
            <ArrowDropDown style={{ marginBottom: 3 }} />
          )
        }
        onClick={onClickHandler}>
        {isFeatures ? "Özel İşlem Var" : "Özel işlem Uygula"}
      </FeaturesButton>
      <SubContainer>
        {product?.features && (
          <IconButton disableRipple onClick={openDialog} size="small">
            <img
              src={updateIcon}
              alt="Delete Icon"
              width="18.31px"
              height="18px"
            />
          </IconButton>
        )}
        <IconButton
          disableRipple
          onClick={() => deleteProduct(userId, product?.id, product?.key)}
          size="small"
          style={{ marginLeft: 8 }}>
          <img
            src={deleteIcon}
            alt="Delete Icon"
            width="14.24px"
            height="18px"
          />
        </IconButton>
      </SubContainer>
    </Root>
  );
};

ButtonsArea.propTypes = {
  onClickHandler: PropTypes.func,
  deleteProduct: PropTypes.func,
  userId: PropTypes.number,
  openDialog: PropTypes.func,
  extended: PropTypes.bool,
  isFeatures: PropTypes.object,
  product: PropTypes.object,
};

export default ButtonsArea;
