import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import LeftPart from "./ProductCard/LeftPart";
import MiddlePart from "./ProductCard/MiddlePart";
import RightPart from "./ProductCard/RightPart";
import LotDialog from "../../Ui/Dialog/LotDialog";
import ConfirmationModal from "../../Ui/Dialog/ConfirmationModal";

import { productService } from "../../Services";
import { productActions } from "../../Redux/Actions";
import { fastOrderAddButtonDisableCondition } from "../../Utils/Helpers/Product/ButtonDisableCondition";
import {
  handleDataArray,
  handleActiveItems,
  handleVariants,
} from "../../Utils/Helpers";
import {
  selectionsInitialQuery,
  generalConstants,
} from "../../Utils/Constants";

const PREFIX = "ProductCard";

const classes = {
  root: `${PREFIX}-root`,
  contentContainer: `${PREFIX}-contentContainer`,
  searchArea: `${PREFIX}-searchArea`,
  textField: `${PREFIX}-textField`,
  svg: `${PREFIX}-svg`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    minWidth: 1170,
    height: 481,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
    "&[data-order='0']": {
      height: 525,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.16)",
    },
  },

  [`& .${classes.contentContainer}`]: {
    width: "100%",
    display: "flex",
    paddingRight: 17,
  },

  [`& .${classes.searchArea}`]: {
    width: "100%",
    height: 45,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  },

  [`& .${classes.textField}`]: {
    width: 562,
    height: 30.48,
    "& .MuiInputBase-input": {
      borderRadius: 8,
      height: 23,
      padding: "3.5px 8px",
      border: `1px solid ${theme.palette.darkGray.light}`,
      fontSize: 14,
      fontWeight: 300,
      backgroundColor: "#F5F5F5",
      "&:not(:focus)": {
        cursor: "pointer",
      },
      "&:hover, &:focus, &:active": {
        backgroundColor: "#F5F5F5",
        border: "1px solid #fff",
      },
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: 10,
        fontWeight: 300,
        color: theme.palette.darkGray.main,
      },
    },
  },

  [`& .${classes.svg}`]: {
    position: "absolute",
    right: 5,
    color: "rgba(189, 189, 189, 1)",
    cursor: "pointer",
  },
}));

const ProductCard = ({ product, order, handleSearchText, keyPress }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const getCart = () => dispatch(productActions.getCart());
  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id));
  const searchKeyword = useSelector(
    ({ customer }) => customer.filters.searchText
  );
  const companyId = useSelector(({ customer }) => customer.currentCompany?.id);
  const featureVals = useSelector(({ product }) => product.featureValues);
  const customer = useSelector(({ customer }) => customer);

  const [showStockModal, setShowStockModal] = useState(false);
  const [showSpecialFeaturesBox, setShowSpecialFeaturesBox] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [carouselData, setCarouselData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [data, setData] = useState(null);
  const [price, setPrice] = useState({});
  const [newSizes, setNewSizes] = useState({ height: "", width: "" });
  const [customHeight, setCustomHeight] = useState(false);
  const [initialSelection, setInitialSelection] = useState(false);
  const [activeArray1, setActiveArray1] = useState([]);
  const [activeArray2, setActiveArray2] = useState([]);
  const [activeArray3, setActiveArray3] = useState([]);
  const [featuresSelected, setFeaturesSelected] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [colorSelected, setColorSelected] = useState(false);
  const [sizeSelected, setSizeSelected] = useState(false);
  const [values, setValues] = useState({
    color: "",
    size: "",
    shape: "",
  });
  const [selections, setSelections] = useState({
    id: "",
    color: "",
    size: "",
    shape: "",
    quantity: 1,
    features: {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    },
  });

  useEffect(() => {
    product && setData(product);
  }, [product]);

  useEffect(() => {
    let currentFeatures = selections.features;
    if (product?.type.id === 3) {
      if (
        currentFeatures.shape !== "" &&
        currentFeatures.side !== "" &&
        currentFeatures.height !== 0
      ) {
        setFeaturesSelected(true);
      } else {
        setFeaturesSelected(false);
      }
    } else {
      let productSize = product?.properties.Ebat;
      let productHeight = +productSize.split("x")[1];
      if (
        currentFeatures.height !== productHeight ||
        currentFeatures.shape !== "Dikdörtgen" ||
        currentFeatures.side !== "Overlok" ||
        currentFeatures.webType !== ""
      ) {
        setFeaturesSelected(true);
      } else {
        setFeaturesSelected(false);
      }
    }
  }, [selections.features, product]);

  useEffect(() => {
    let variants = data && handleVariants(data);

    if (data) {
      let images = handleCarouselImages(data);
      setCarouselData(images);
      setData(data);
      setPrice(data.price);
    }
    if (data && variants) {
      let productType = data.type ? data.type.id : null;
      setValues({
        ...values,
        size: handleDataArray(variants, "Ebat"),
        shape: handleDataArray(variants, "Şekil"),
        color: handleDataArray(variants, "Renk"),
      });
      let defaultVariant = variants.filter(
        (variant) => variant.product_id === data.id
      );
      let initialSize = defaultVariant[0].Ebat;
      let initialShape = defaultVariant[0].Şekil;
      let initialColor = defaultVariant[0].Renk;
      let newWidth = +initialSize.split("x")[0];
      let newHeight = +initialSize.split("x")[1];
      let heightIsNan = _.isNaN(newHeight) ? 0 : newHeight;
      setNewSizes({ height: heightIsNan, width: newWidth });
      productType && productType === 3
        ? setCustomHeight(true)
        : setCustomHeight(false);
      setSelections({
        ...selectionsInitialQuery,
        size: initialSize,
        shape: initialShape,
        color: initialColor,
        features: {
          ...selectionsInitialQuery.features,
          width: newWidth,
          height: productType && productType === 3 ? 0 : heightIsNan,
        },
      });
      setActiveArray1(defaultVariant);
      setActiveArray2(defaultVariant);
      setActiveArray3(defaultVariant);
      setInitialSelection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setSearchItem(searchKeyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    let id = activeArray3.length > 0 ? activeArray3[0].product_id : null;
    if (id) {
      if (data?.id !== id) {
        let query = {
          pageNumber: 1,
          pageSize: 10,
          sortField: "id",
          sortOrder: "desc",
          filter: {
            company_id: companyId,
            on_sale: 1,
          },
          keyword: "",
        };
        getProductDetail(id, query);
      } else {
        let size = activeArray3[0].Ebat;
        let newWidth = +size.split("x")[0];
        let newHeight = +size.split("x")[1];
        let heightIsNan = _.isNaN(newHeight) ? 0 : newHeight;
        let productType = data.type ? data.type.id : null;
        setNewSizes({ height: heightIsNan, width: newWidth });
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            width: newWidth,
            height: productType && productType === 3 ? 0 : heightIsNan,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeArray3]);

  useEffect(() => {
    let condition = fastOrderAddButtonDisableCondition(
      showSpecialFeaturesBox,
      customHeight,
      selections
    );
    setDisableButton(condition);
  }, [selections, data, activeArray3, showSpecialFeaturesBox, customHeight]);

  useEffect(() => {
    if (selections.size?.length > 0) {
      setSizeSelected(true);
    } else {
      setSizeSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.size]);

  useEffect(() => {
    if (values.color.length === 1) {
      setColorSelected(true);
      let variants = handleVariants(data);
      let result = handleActiveItems(variants, "Renk", values.color[0]);
      setInitialSelection(false);
      setActiveArray1(result);
    } else if (selections.color?.length > 0 && !initialSelection) {
      setColorSelected(true);
    } else {
      setColorSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.color, values]);

  const notify = () => (toastId.current = toast("Ürün Sepete Eklendi"));

  const toggleStockModal = () => setShowStockModal(!showStockModal);

  const openSpecialFeaturesBox = () => setShowSpecialFeaturesBox(true);
  const closeSpecialFeaturesBox = () => setShowSpecialFeaturesBox(false);

  const openVerifyModal = () => setShowVerifyModal(true);
  const closeVerifyModal = () => setShowVerifyModal(false);

  const handleCarouselImages = (data) => {
    return data.images.length > 0 ? data.images : [{ url: data.image }];
  };

  const handleSearchItem = (e) => setSearchItem(e.target.value);

  const getProductDetail = (id, query) => {
    productService
      .productSkuById(id, query)
      .then((res) => {
        let data = res.data.product_sku;
        setData(data);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (prop) => (event) => {
    let evt = event.target;
    let activeSizes = handleDataArray(activeArray1, "Ebat");
    let activeShapes = handleDataArray(activeArray2, "Şekil");
    let selectedValue = prop === "quantity" ? evt.value : evt.innerText;

    if (prop === "color") {
      let variants = handleVariants(data);
      let result = handleActiveItems(variants, "Renk", evt.innerText);
      setInitialSelection(false);
      setActiveArray1(result);
      setColorSelected(true);
      activeArray2.length > 0 && setActiveArray2([]);
      activeArray3.length > 0 && setActiveArray3([]);
      setSelections({
        ...selections,
        [prop]: selectedValue,
        size: "",
        shape: "",
        quantity: 1,
        features: {
          ...selections.features,
          shape: "",
          side: "",
          webType: "",
          webColor: "",
          width: "",
          height: "",
        },
      });
    } else if (prop === "size") {
      if (initialSelection && !colorSelected) return;
      if (!colorSelected) return;
      if (!activeSizes.includes(evt.innerText)) return;
      let result = handleActiveItems(activeArray1, "Ebat", evt.innerText);
      setActiveArray2(result);
      setSizeSelected(true);
      activeArray3.length > 0 && setActiveArray3([]);
      setSelections({
        ...selections,
        [prop]: selectedValue,
        shape: "",
      });
    } else if (prop === "shape") {
      if (initialSelection) return;
      if (!sizeSelected) return;
      if (!activeShapes.includes(evt.innerText)) return;
      let result = handleActiveItems(activeArray2, "Şekil", evt.innerText);
      setActiveArray3(result);
      setPrice(result[0].price);
      setSelections({
        ...selections,
        [prop]: selectedValue,
      });
    } else if (prop === "quantity") {
      setSelections({
        ...selections,
        [prop]: selectedValue,
      });
    }
  };

  const handleFeatures = (prop) => (e) => {
    if (selections.features[prop] === e.target.value) {
      setSelections({
        ...selections,
        features: { ...selections.features, [prop]: "" },
      });
    } else {
      if (
        prop === "side" &&
        e.target.value !== "Saçak" &&
        selections.features.webType !== ""
      ) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: e.target.value,
            webType: "",
          },
        });
      } else {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: e.target.value },
        });
      }
    }
  };

  const approveDiscardFeatures = () => {
    closeVerifyModal();
    discardFeaturesSelections();
    closeSpecialFeaturesBox();
  };

  const discardFeaturesSelections = () => {
    setFeaturesSelected(false);
    setSelections({
      ...selections,
      features: {
        ...selections.features,
        shape: "Dikdörtgen",
        side: "Overlok",
        webType: "",
        webColor: "",
        width: newSizes.width,
        height: newSizes.height,
      },
    });
  };

  const handleSubmit = () => {
    let productId = data.has_variants ? activeArray3[0].product_id : data?.id;

    let query = {
      product_id: productId,
      quantity: selections.quantity,
      features: featuresSelected ? selections.features : null,
    };

    if (customer?.isAdmin) {
      let id = customer?.selectedUser?.id;
      productService
        .addToAdminCart(query, id)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getAdminCart(id);
            notify();
            discardFeaturesSelections();
            setSelections({
              ...selections,
              quantity: 1,
            });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      productService
        .addToCart(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getCart();
            notify();
            discardFeaturesSelections();
            setSelections({
              ...selections,
              quantity: 1,
            });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  return (
    <Root className={classes.root} data-order={order?.toString()}>
      {order === 0 && (
        <div className={classes.searchArea}>
          <TextField
            fullWidth
            className={classes.textField}
            placeholder="Hızlı ara..."
            variant="standard"
            value={searchItem}
            onChange={handleSearchItem}
            onKeyDown={keyPress}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" className={classes.svg}>
                  <SearchIcon onClick={() => handleSearchText(searchItem)} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      <div className={classes.contentContainer}>
        <LeftPart data={data && data} carouselData={carouselData} />
        <MiddlePart
          data={data}
          values={values}
          handleChange={handleChange}
          selections={selections}
          initialSelection={initialSelection}
          activeArray1={activeArray1}
          activeArray2={activeArray2}
        />
        <RightPart
          price={price}
          customHeight={customHeight}
          showSpecialFeaturesBox={showSpecialFeaturesBox}
          openVerifyModal={openVerifyModal}
          selections={selections}
          handleFeatures={handleFeatures}
          openSpecialFeaturesBox={openSpecialFeaturesBox}
          openStockModal={toggleStockModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          disableButton={disableButton}
          product={product}
          featureVals={featureVals}
        />
      </div>
      <LotDialog
        name={product?.name}
        data={product?.stock?.detail_list}
        onClose={toggleStockModal}
        open={showStockModal}
      />
      <ConfirmationModal
        open={showVerifyModal}
        cancel={closeVerifyModal}
        confirm={approveDiscardFeatures}
      />
    </Root>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  order: PropTypes.number,
  handleSearchText: PropTypes.func,
  keyPress: PropTypes.func,
};

export default ProductCard;
