import { productConstants, generalConstants } from "../../Utils/Constants";
import { productService } from "../../Services";
import { toast } from "react-toastify";

const setProduct = (id, name) => {
  return {
    type: productConstants.SET_PRODUCT,
    payload: { id, name },
  };
};

const getCart = () => {
  let query = {
    order: {
      order_key: "-",
      cost: "0",
      total_cost: "0",
      total_quantity: 0,
      total_count: 0,
      recreate_key: false,
      cargo_price: 0,
    },
    products: [],
  };
  return (dispatch) => {
    productService.getCart().then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: productConstants.GET_CART,
            payload: res.data.cart,
          });
        } else {
          dispatch({
            type: productConstants.GET_CART,
            payload: query,
          });
        }
      },
      (error) => {
        dispatch({
          type: productConstants.GET_CART,
          payload: query,
        });
        console.log(error);
      }
    );
  };
};

const getAdminCart = (id) => {
  let query = {
    order: {
      order_key: "-",
      cost: "0",
      total_cost: "0",
      total_quantity: 0,
      total_count: 0,
      recreate_key: false,
      cargo_price: 0,
    },
    products: [],
  };
  return (dispatch) => {
    productService.getAdminCart(id).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: productConstants.GET_CART,
            payload: res.data.cart,
          });
        } else {
          dispatch({
            type: productConstants.GET_CART,
            payload: query,
          });
        }
      },
      (error) => {
        dispatch({
          type: productConstants.GET_CART,
          payload: query,
        });
        console.log(error);
      }
    );
  };
};

const deleteProduct = (id, key) => {
  return (dispatch) => {
    productService.deleteProduct(id, key).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          productService.getCart().then(
            (res) => {
              if (res?.data?.status === generalConstants.STATUS_TRUE) {
                dispatch({
                  type: productConstants.GET_CART,
                  payload: res.data.cart,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const deleteAdminProduct = (userId, productId, key) => {
  return (dispatch) => {
    productService.deleteAdminProduct(userId, productId, key).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          productService.getAdminCart(userId).then(
            (res) => {
              if (res?.data?.status === generalConstants.STATUS_TRUE) {
                dispatch({
                  type: productConstants.GET_CART,
                  payload: res.data.cart,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const clearCart = () => {
  return (dispatch) => {
    productService.clearCart().then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: productConstants.CLEAR_CART,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const clearAdminCart = (userId) => {
  return (dispatch) => {
    productService.clearAdminCart(userId).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: productConstants.CLEAR_CART,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const updateProduct = (query) => {
  return (dispatch) => {
    dispatch(request());
    productService.cartUpdate(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          productService.getCart().then(
            (res) => {
              if (res?.data?.status === generalConstants.STATUS_TRUE) {
                dispatch({
                  type: productConstants.GET_CART,
                  payload: res.data.cart,
                });
                dispatch(success());
              } else
                toast.error(
                  "Sepetteki ürünlerin listelenmesinde bir sorun oluştu."
                );
            },
            (error) => {
              toast.error(
                "Error, Sepetteki ürünlerin listelenmesinde bir sorun oluştu.",
                error.toString()
              );
              dispatch(failure());
            }
          );
        } else {
          toast.error(res?.data?.message);
          dispatch(failure());
        }
      },
      (error) => {
        toast.error(error?.response?.data?.message);
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: productConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: productConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return { type: productConstants.UPDATE_FAILURE };
  }
};

const updateAdminProduct = (query, userId) => {
  return (dispatch) => {
    dispatch(request());
    productService.adminCartUpdate(query, userId).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          productService.getAdminCart(userId).then(
            (res) => {
              if (res?.data?.status === generalConstants.STATUS_TRUE) {
                dispatch({
                  type: productConstants.GET_CART,
                  payload: res.data.cart,
                });
                dispatch(success());
              } else
                toast.error(
                  res?.data?.message
                );
            },
            (error) => {
              toast.error(
                error?.response?.data?.message
              );
              dispatch(failure());
            }
          );
        } else {
          toast.error(res?.data?.message);
          dispatch(failure());
        }
      },
      (error) => {
        toast.error(error?.response?.data?.message);
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: productConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: productConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return { type: productConstants.UPDATE_FAILURE };
  }
};

const getFeatureValues = () => {
  return (dispatch) => {
    productService.getFeatureValues().then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          let data = res.data.features;
          dispatch({
            type: productConstants.GET_FEATURE_VALUES,
            data,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const setNewArrivalsData = (data) => {
  return {
    type: productConstants.SET_NEW_ARRIVALS_DATA,
    data,
  };
};

const clearProductReducer = () => {
  return {
    type: productConstants.CLEAR_PRODUCT_REDUCER,
  };
};

export const productActions = {
  setProduct,
  getCart,
  deleteProduct,
  clearCart,
  updateProduct,
  getFeatureValues,
  setNewArrivalsData,
  getAdminCart,
  deleteAdminProduct,
  clearAdminCart,
  updateAdminProduct,
  clearProductReducer,
};
