import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import RadioSelected from "../../Images/ProductPage/RadioSelected.svg";
import RadioUnselected from "../../Images/ProductPage/RadioUnselected.svg";

const PREFIX = "RoleUserPopover";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  radio: `${PREFIX}-radio`,
  formControl: `${PREFIX}-formControl`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  selected: `${PREFIX}-selected`,
};

const StyledPopover = styled(Popover)(({ theme }) => ({
  [`&.${classes.root}`]: { padding: 0 },

  [`& .${classes.paper}`]: {
    width: 216,
    height: 153,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.radio}`]: {
    width: 22,
    height: 22,
    padding: 0,
    marginRight: 3,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.formControl}`]: {
    marginTop: 10,
  },

  [`& .${classes.formControlLabel}`]: {
    "&.MuiFormControlLabel-root": {
      minWidth: 95,
      marginBottom: 5,
      marginLeft: 10,
    },
    "& .MuiTypography-body1": {
      marginLeft: 2,
      marginTop: 1,
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.secondary.main,
    },
    "&[used-in='fast-order']": {
      "& .MuiTypography-body1": {
        marginLeft: 0,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },

  [`& .${classes.selected}`]: {
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  },
}));

const RoleUserPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleRole,
  role,
  roles,
}) => {
  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={classes.root}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={classes.paper}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup style={{ width: "100%" }}>
            {roles?.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={item.id}
                  label={item.name}
                  className={classnames({
                    [classes.formControlLabel]: true,
                    [classes.selected]: item.id === +role && true,
                  })}
                  control={
                    <Radio
                      onClick={handleRole}
                      disableRipple
                      value={item.id}
                      checked={item.id === +role}
                      icon={<img src={RadioUnselected} alt="" />}
                      checkedIcon={<img src={RadioSelected} alt="" />}
                    />
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </StyledPopover>
  );
};

RoleUserPopover.propTypes = {
  id: PropTypes.any,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  handleRole: PropTypes.func,
  role: PropTypes.number,
  roles: PropTypes.array,
};

export default RoleUserPopover;
