import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import CircleArrow from '../../../../Images/Orders/CircleArrow.svg'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import DoDisturbRoundedIcon from '@mui/icons-material/DoDisturbRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import Button from '@mui/material/Button'

import { getFirstLetters } from '../../../../Utils/Helpers'

import ChangeRoleMob from '../MobileDialogs/ChangeRoleMob'
import MobileEditUser from '../MobileDialogs/MobileEditUser'
import MobileEditUserCa from '../MobileDialogs/MobileEditUserCa'

import { authService } from '../../../../Services'
import { generalConstants } from '../../../../Utils/Constants'

const innerWidth = window.innerWidth
const infoPartWidth = innerWidth - 112

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: 215,
  display: 'flex',
  padding: theme.spacing(),
  borderRadius: 6,
  marginBottom: theme.spacing(),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}))

const ImagePart = styled('div')(({ theme }) => ({
  width: 80,
  height: '100%',
  marginRight: theme.spacing(),
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const InfoPart = styled('div')(({ theme }) => ({
  flex: 1,
  maxWidth: infoPartWidth,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}))

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  width: 25,
  height: 25,
  padding: 5,
  right: 0,
  top: 0,
}))

const Letters = styled('div')(({ theme }) => ({
  width: 70,
  height: 70,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(),
}))

const LetterText = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  color: '#fff',
  textTransform: 'uppercase',
}))

const RoleText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '20px',
  color: theme.palette.darkGray.main,
  marginBottom: theme.spacing(),
}))

const StatusArea = styled('div')(({ theme }) => ({
  width: '100%',
  height: 24,
  display: 'flex',
  alignItems: 'center',
}))

const StatusText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})(({ theme, textColor }) => ({
  fontSize: 10,
  fontWeight: 400,
  lineHeight: '18px',
  color: textColor,
  marginLeft: theme.spacing(),
}))

const InfoText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'name',
})(({ theme, name }) => ({
  width: name ? infoPartWidth - 30 : infoPartWidth,
  height: 24,
  fontSize: name ? 16 : 14,
  fontWeight: 400,
  lineHeight: name ? '24px' : '20px',
  color: name ? theme.palette.darkGray.main : '#B1AEAE',
  marginBottom: name && theme.spacing(),
}))

const ButtonsArea = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: 30,
  marginTop: theme.spacing(),
}))

const StyledButtons = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  textTransform: 'none',
  fontSize: 10,
  fontWeight: 400,
  color: '#fff',
  height: 30,
  borderRadius: 8,
  backgroundColor: color,
  '&:hover': {
    backgroundColor: color,
  },
}))

let colors = {
  red: '#F05454',
  green: '#10C66C',
  blue: '#516BEB',
}

const UserCard = ({ user, roles, newGetUsers, query }) => {
  const customer = useSelector(({ customer }) => customer)

  const [letters, setLetters] = useState(null)
  const [statusVals, setStatusVals] = useState(null)
  const [roleDialog, setRoleDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [editDialogCa, setEditDialogCa] = useState(false)
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (user) {
      setLetters(getFirstLetters(user?.first_name, user?.last_name))
      getIcons(user)
    } else setLetters('B', 'B')
  }, [user])

  const toggleRoleDialog = () => setRoleDialog((prev) => !prev)

  const openEditUser = () => {
    if (customer?.isAdmin) {
      setEditDialog(true)
    } else {
      setEditDialogCa(true)
    }
  }

  const closeEditUser = () => {
    if (customer?.isAdmin) {
      setEditDialog(false)
    } else {
      setEditDialogCa(false)
    }
  }

  const getIcons = (user) => {
    if (user?.status === 1) {
      if (user?.is_confirmed === true) {
        let icon = <DoneRoundedIcon style={{ fill: colors.green, width: 20, height: 20 }} />
        let text = 'Aktif'
        let color = colors.green
        setStatusVals({ icon, text, color })
        setStatus('Aktif')
      } else if (user?.is_confirmed === false) {
        let icon = <AccessTimeRoundedIcon style={{ fill: colors.blue, width: 20, height: 20 }} />
        let text = 'İstek Gönderildi'
        let color = colors.blue
        setStatusVals({ icon, text, color })
        setStatus('İstek Gönderildi')
      } else {
        let icon = <AccessTimeRoundedIcon style={{ fill: colors.blue, width: 20, height: 20 }} />
        let text = 'İstek Gönderildi'
        let color = colors.blue
        setStatusVals({ icon, text, color })
        setStatus('İstek Gönderildi')
      }
    } else {
      let icon = <DoDisturbRoundedIcon style={{ fill: colors.red, width: 20, height: 20 }} />
      let text = 'Pasif'
      let color = colors.red
      setStatusVals({ icon, text, color })
      setStatus('Pasif')
    }
  }

  const handlePasswordRenew = async () => {
    let query = { email: user?.email }
    const response = await authService.userRenewPassword(query)
    if (response.data.status === generalConstants.STATUS_TRUE) {
      toast.success('Şifre yenileme linki gönderildi')
    } else {
      toast.error(response?.data?.message)
    }
  }

  const handleActivationLink = async () => {
    let id = user?.id
    const response = await authService.userActivationLink(id)
    if (response.data.status === generalConstants.STATUS_TRUE) {
      toast.success('Aktivasyon linki gönderildi')
    } else {
      toast.error(response?.data?.message)
    }
  }

  const statusArr = ['İstek Gönderildi', 'Aktif']

  return (
    <Root>
      <ImagePart>
        <Letters>
          <LetterText>{letters && letters}</LetterText>
        </Letters>
        <RoleText>{user?.role_names[0]}</RoleText>
        <StatusArea>
          {statusVals?.icon}
          <StatusText textColor={statusVals?.color}>{statusVals?.text}</StatusText>
        </StatusArea>
      </ImagePart>
      <InfoPart>
        {statusArr.includes(status) && (
          <ArrowButton onClick={openEditUser}>
            <img src={CircleArrow} alt='' width='20px' />
          </ArrowButton>
        )}
        <InfoText noWrap name>
          {user?.full_name}
        </InfoText>
        <InfoText noWrap>{user?.create_date}</InfoText>
        <InfoText noWrap>{user?.email}</InfoText>
        <InfoText noWrap>{user?.gsm}</InfoText>
        <InfoText noWrap>{user?.company?.name}</InfoText>
        <InfoText noWrap>{user?.address?.name}</InfoText>
        {status === 'Aktif' ? (
          <ButtonsArea>
            <StyledButtons onClick={handlePasswordRenew} color='#F5B041'>
              Şifre Linki Gönder
            </StyledButtons>
            <StyledButtons color='#6E3564' onClick={toggleRoleDialog}>
              Yetki Değiştir
            </StyledButtons>
          </ButtonsArea>
        ) : (
          <ButtonsArea>
            <StyledButtons onClick={handleActivationLink} color='#516BEB'>
              Aktivasyon Linki Gönder
            </StyledButtons>
          </ButtonsArea>
        )}
      </InfoPart>
      <ChangeRoleMob
        open={roleDialog}
        onClose={toggleRoleDialog}
        roles={roles}
        userRole={user?.role_ids ? user?.role_ids[0] : null}
      />
      <MobileEditUser
        open={editDialog}
        onClose={closeEditUser}
        userInfo={user}
        handlePasswordRenew={handlePasswordRenew}
        status={status}
        roles={roles}
        newGetUsers={newGetUsers}
        query={query}
      />
      <MobileEditUserCa
        open={editDialogCa}
        onClose={closeEditUser}
        roles={roles}
        userInfo={user}
        handlePasswordRenew={handlePasswordRenew}
        status={status}
        newGetUsers={newGetUsers}
        query={query}
      />
    </Root>
  )
}

UserCard.propTypes = {
  user: PropTypes.object,
  roles: PropTypes.array,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
}

export default UserCard
