import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "first" && prop !== "last",
})(({ theme, first, last }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  minWidth: 1212,
  marginBottom: last ? theme.spacing(5) : theme.spacing(2),
  marginTop: first && theme.spacing(3),
  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
}));

const SectionContainer = ({ children, first, last }) => {
  return (
    <Root first={first} last={last}>
      {children}
    </Root>
  );
};

SectionContainer.propTypes = {
  children: PropTypes.any,
  first: PropTypes.bool,
  last: PropTypes.bool,
};

export default SectionContainer;
