import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import RowPart from "./RowPart";

const Root = styled("div")(({theme}) => ({
  display: "flex",
  borderTop: `1px solid ${theme.palette.darkGray.light}`,
}));

const Row = ({ val1, val2, name1, name2, single, mobile }) => {
  return (
    <Root>
      <RowPart name={name1} value={val1} minWidth mobile={mobile} />
      {!single && <RowPart name={name2} value={val2} mobile={mobile} />}
    </Root>
  );
};

Row.propTypes = {
  val1: PropTypes.any,
  val2: PropTypes.any,
  name1: PropTypes.string,
  name2: PropTypes.string,
  single: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default Row;
