import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MobileDialogHeader from "../../Ui/Dialog/Tools/MobileDialogHeader";
import PageContainerMob from "../Common/PageContainerMob";

import { bottomNavbarActions } from "../../Redux/Actions";
import { generalConstants, notificationsQuery } from "../../Utils/Constants";
import { notificationService } from "../../Services";
import { notificationActions } from "../../Redux/Actions";

import ConfirmDialog from "./ConfirmDialog";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: "100vh",
  backgroundColor: "#fff",
  padding: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  position: "relative",
  userSelect: "none",
  overflow: "scroll",
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isRead",
})(({ theme, isRead }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  display: "flex",
  flexDirection: "column",
  opacity: isRead ? 0.7 : 1,
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "title",
})(({ theme, title }) => ({
  fontSize: title ? 14 : 12,
  lineHeight: title ? "20px" : "18px",
  fontWeight: title ? 700 : 400,
  color: theme.palette.darkGray.main,
  marginBottom: title ? theme.spacing() : 0,
}));

const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));
  const getNotificationsData = (query) =>
    dispatch(notificationActions.getNotifications(query));

  const notificationsData = useSelector(
    ({ notifications }) => notifications?.data
  );

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu("other");
      getNotificationsData(notificationsQuery);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => history.goBack();

  const handleNotificationStatus = async (id, isRead) => {
    if (isRead === 1) return;
    const response = await notificationService.readNotification(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      getNotificationsData(notificationsQuery);
    } else {
      console.log(response);
    }
  };

  const handleNotificationStatusAll = async () => {
    const response = await notificationService.readAllNotifications();
    if (response.data.status === generalConstants.STATUS_TRUE) {
      getNotificationsData(notificationsQuery);
      toggleDialog();
    } else {
      console.log(response);
      toggleDialog();
    }
  };

  const toggleDialog = () => setOpen((prev) => !prev);

  const goToHome = () => history.push("./home");

  if (!isMobileOnly) return <>{goToHome()}</>;

  return (
    <PageContainerMob noScroll>
      <MobileDialogHeader
        header="Bildirimler"
        callback={goBack}
        notifications={notificationsData?.length > 0}
        notificationFunc={toggleDialog}
      />
      <StyledContainer>
        {notificationsData ? (
          notificationsData?.map((item, index) => (
            <StyledBox
              key={index}
              isRead={item.is_read}
              onClick={() => handleNotificationStatus(item.id, item.is_read)}>
              <Text title>{item.title}</Text>
              <Text>{item.content}</Text>
            </StyledBox>
          ))
        ) : (
          <div></div>
        )}
      </StyledContainer>
      <ConfirmDialog
        open={open}
        cancel={toggleDialog}
        confirm={handleNotificationStatusAll}
      />
    </PageContainerMob>
  );
};

export default Notifications;
