import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import OrderNote from "./OrderSummary/OrderNote";
import AddressInfo from "./OrderSummary/AddresInfo";
import PaymentArea from "./OrderSummary/PaymentArea";

import { orderActions, customerActions } from "../../Redux/Actions";

import ErrorDialog from "./Dialogs/ErrorDialog";
import SuccessDalog from "./Dialogs/SuccessDialog";

import Loading from "../../Utils/Loading";

const PREFIX = "OrderSummary";

const classes = {
  header: `${PREFIX}-header`,
  orderSummaryMain: `${PREFIX}-orderSummaryMain`,
  paymentPageButton: `${PREFIX}-paymentPageButton`,
  approveButton: `${PREFIX}-approveButton`,
  gridContainer: `${PREFIX}-gridContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.header}`]: {
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    paddingLeft: theme.spacing(),
  },

  [`&.${classes.orderSummaryMain}`]: {
    width: 300,
    minWidth: 300,
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginTop: theme.spacing(2)
  },

  [`& .${classes.paymentPageButton}`]: {
    width: "content-fit",
    height: "auto",
    backgroundColor: "#fff",
    color: "#3699FF",
    textTransform: "none",
    marginBottom: 9,
    fontSize: 12,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  [`& .${classes.approveButton}`]: {
    width: "95%",
    height: 40,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    zIndex: 1,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`& .${classes.gridContainer}`]: {
    overflowY: "scroll",
    flex: 1,
    padding: theme.spacing(),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(8),
  },
}));

const OrderSummary = ({
  openAddressSelectionModal,
  openAddAddressModal,
  placeOrder,
  checkout,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const products = useSelector(({ product }) => product.cart.products);
  const customer = useSelector(({ customer }) => customer);
  const role = useSelector(({ auth }) => auth.customerInfo.role_names);
  const note = useSelector(({ orders }) => orders.note);
  const orderLoading = useSelector(({ orders }) => orders.loading);
  const success = useSelector(({ orders }) => orders.success);
  const orderError = useSelector(({ orders }) => orders.error);

  const saveNote = (note) => dispatch(orderActions.saveNote(note));
  const clearStatus = () => dispatch(orderActions.clearStatus());
  const initializeOrder = (query) =>
    dispatch(orderActions.initializeOrderOpenAccount(query));
  const initializeAdminOrder = (query, id) =>
    dispatch(orderActions.initializeOrderOpenAccountAdmin(query, id));
  const reStoreDefaultAddress = () =>
    dispatch(customerActions.restoreDefaultAddress());

  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    let active = true;
    if (active) {
      if (note) {
        if (note !== text) {
          setText(note);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    let active = true;
    if (active) {
      let urlIsValid = match?.path?.indexOf("/cart-page") > -1;
      if (success && urlIsValid) {
        setSuccessDialog(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    let active = true;
    if (active) {
      let urlIsValid = match?.path?.indexOf("/cart-page") > -1;
      if (orderError && urlIsValid) {
        setErrorDialog(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderError]);

  const handleChange = (e) => setText(e.target.value);

  const placeOrderCart = (text) => {
    let query = {
      method_id: 4,
      address_id: customer?.currentAddress?.id,
      installment: 1,
      note: text,
      extra_data: {},
      source: 1,
    };

    if (customer?.isAdmin) {
      let adminQuery = {
        ...query,
        user_id: customer?.selectedUser?.id,
        company_id: customer?.currentCompany?.id,
      };
      initializeAdminOrder(adminQuery, customer?.selectedUser?.id);
    } else {
      initializeOrder(query);
    }
  };

  const singleAccount = () => {
    let paymentMethods = customer?.selectedUser?.company?.payment_methods;
    if (!paymentMethods || paymentMethods?.length === 0) {
      return "NoPaymentMethod";
    } else if (paymentMethods.length === 1 && paymentMethods[0].id === 4) {
      return "OpenAccount";
    }
    return "SeveralMethods";
  };

  const handleClick = () => {
    let isOnlyOpenAccount = singleAccount();
    if (isOnlyOpenAccount === "NoPaymentMethod") {
      toast.warn("Hesabınıza tanımlı ödeme yöntemi bulunmamaktadır!");
      return;
    }
    if (products?.length === 0) {
      toast.warn("Sepetinizde ürün bulunmamaktadır");
      return;
    }
    if (checkout) {
      placeOrder(text);
    } else if (isOnlyOpenAccount === "OpenAccount") {
      placeOrderCart(text);
    } else {
      saveNote(text);
      history.push("/checkout");
    }
  };

  const closeSuccessDialog = () => {
    reStoreDefaultAddress();
    setSuccessDialog(false);
  };
  const closeErrorDialog = () => {
    reStoreDefaultAddress();
    setErrorDialog(false);
  };

  const handleErrorDialog = () => {
    clearStatus();
    closeErrorDialog();
  };

  const goToHome = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/home");
  };

  const handleSuccessDialog = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/order-page");
  };

  return (
    <Root className={classes.orderSummaryMain}>
      <Loading open={orderLoading} />
      <Typography className={classes.header}>Sipariş Özeti</Typography>
      <div className={classes.gridContainer}>
        <Button
          variant="contained"
          className={classes.approveButton}
          onClick={handleClick}
          disableRipple
        >
          Sepeti Onayla
        </Button>
        <OrderNote handleChange={handleChange} text={text} />
        <AddressInfo
          address={customer.currentAddress}
          openDialog={openAddressSelectionModal}
          openAddAddressModal={openAddAddressModal}
        />
        {role && role[0] !== "companyuser" && <PaymentArea />}
        {/* <Button className={classes.paymentPageButton} disableRipple>
          Ödeme Sayfası
        </Button> */}
      </div>
      <SuccessDalog
        successDialog={successDialog}
        goToHome={goToHome}
        handleSuccessDialog={handleSuccessDialog}
        currentAddress={customer.currentAddress}
      />
      <ErrorDialog
        errorDialog={errorDialog}
        handleErrorDialog={handleErrorDialog}
      />
    </Root>
  );
};

OrderSummary.propTypes = {
  openAddressSelectionModal: PropTypes.func,
  openAddAddressModal: PropTypes.func,
  placeOrder: PropTypes.func,
  checkout: PropTypes.bool,
};

export default OrderSummary;
