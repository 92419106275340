import { productConstants } from "../../Utils/Constants";
import { handleGetFeatureValues } from "../../Utils/Helpers";
import { productReducerInitialState } from "../../Utils/Constants";

const initialState = {
  id: "",
  name: "",
  cart: {
    order: {},
    products: [],
  },
  loading: false,
  featureValues: {},
  newArrivals: null,
};
const product = (state = initialState, action) => {
  switch (action.type) {
    case productConstants.SET_PRODUCT:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
      };
    case productConstants.GET_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          order: action.payload.order,
          products: action.payload.products,
        },
      };
    case productConstants.DELETE_PRODUCT:
      return {
        ...state,
        cart: {
          ...state.cart,
          products: state.cart.products.filter(
            (product) => product.id !== action.id
          ),
        },
      };
    case productConstants.CLEAR_CART:
      return {
        ...state,
        cart: {
          order: {},
          products: [],
        },
      };
    case productConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case productConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case productConstants.GET_FEATURE_VALUES:
      let newData = handleGetFeatureValues(action.data);
      return {
        ...state,
        featureValues: newData,
      };
    case productConstants.SET_NEW_ARRIVALS_DATA:
      return {
        ...state,
        newArrivals: action.data,
      };
    case productConstants.CLEAR_PRODUCT_REDUCER:
      return productReducerInitialState;

    default:
      return state;
  }
};

export { product };
