import React, { useState, useImperativeHandle } from "react";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import dotsIcon from "../../../../Images/UsersAndRoles/Dots.svg";

import ReturnItemPopover from "../../../../Ui/Popovers/ReturnItemPopover";
import AddReturn from "../Dialogs/AddReturn";

const StyledSection = styled("div")(() => ({
  "&[initial-header='true']": {
    minWidth: 35,
  },
  "&[register-id='true']": {
    width: 100,
  },
  "&[register-date='true']": {
    width: 147,
  },
  "&[name-field='true']": {
    width: 122,
  },
  "&[product-field='true']": {
    width: 200,
  },
  "&[explanation-field='true']": {
    width: 250,
  },
  "&[address-field='true']": {
    width: 120,
  },
  "&[state-field='true']": {
    width: 150,
  },
  "&[three-dots='true']": {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledCircle = styled("div")(({theme}) => ({
  width: 24,
  height: 24,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
}));

const SubHeader = styled("div")(() => ({
  flex: 1,
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledIcon = styled(IconButton)(() => ({
  height: 30,
  width: 30,
}));

const sectionText = {
  width: "100%",
  fontSize: 14,
  fontWeight: 400,
  color: "#434141",
  textAlign: "left",
};

const boxClass = {
  width: "100%",
  height: 41,
  border: "1px solid #E8E4E4",
  display: "flex",
  borderRadius: 2,
  padding: "0px 7px",
  alignItems: "center",
  marginTop: 1,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};

const TableRow = ({ item }, ref) => {
  const [openAddReturn, setOpenAddReturn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "return-popover" : undefined;

  const showAddReturn = () => {
    setOpenAddReturn(true);
    handleClose();
  };
  const closeAddReturn = () => setOpenAddReturn(false);

  useImperativeHandle(ref, () => ({
    showAddReturn: () => {
      showAddReturn();
    },
  }));

  return (
    <Box
      sx={{
        ...boxClass,
        "&:hover": {
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.50)",
          "& .sectionText": {
            color: theme =>  theme.palette.primary.main,
          },
        },
      }}>
      <StyledSection initial-header="true">
        <StyledCircle />
      </StyledSection>
      <SubHeader>
        <StyledSection register-id="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.register_no}
          </Typography>
        </StyledSection>
        <StyledSection register-date="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.create_date}
          </Typography>
        </StyledSection>
        <StyledSection name-field="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.name}
          </Typography>
        </StyledSection>
        <StyledSection product-field="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.product}
          </Typography>
        </StyledSection>
        <StyledSection explanation-field="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.explanation}
          </Typography>
        </StyledSection>
        <StyledSection address-field="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.address}
          </Typography>
        </StyledSection>
        <StyledSection state-field="true">
          <Typography
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {item?.state}
          </Typography>
        </StyledSection>
        <StyledSection three-dots="true">
          <StyledIcon onClick={handleClick} size="large">
            <img src={dotsIcon} width="24px" height="24px" alt="" />
          </StyledIcon>
        </StyledSection>
      </SubHeader>
      <ReturnItemPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        showAddReturn={showAddReturn}
      />
      <AddReturn open={openAddReturn} onClose={closeAddReturn} />
    </Box>
  );
};

export default React.forwardRef(TableRow);
