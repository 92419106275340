import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getAllUsers_ = async (query, companyId, isAdmin, page) => {
  let query_ = {
    ...query,
    filter: {
      ...query.filter,
      company_id: query.filter.company_id
      ? isAdmin && page ? query.filter.company_id : [...query.filter.company_id, companyId]
      : isAdmin && page ? [] : [companyId],
    },
  };
  let url = `${API_URL}/user/admin/list`;
  return await axios.post(url, query_, authCustomerHeader()).then((res) => {
    return res;
  });
};

const getAllRoles = async (query) => {
  return await axios
    .post(`${API_URL}/role/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getCompanyUsers = async (query, company_id, isAdmin) => {
  let query_ = { ...query, filter: { ...query.filter, company_id } };
  let url = isAdmin ? `${API_URL}/user/admin/list` : `${API_URL}/user/list`;
  return await axios.post(url, query_, authCustomerHeader()).then((res) => {
    return res;
  });
};

const addUser = async (query) => {
  return await axios
    .post(`${API_URL}/user/admin/add`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const editUser = async (query, userId) => {
  return await axios
    .put(`${API_URL}/user/admin/edit/${userId}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const deactivateUser = async (id) => {
  return await axios
    .delete(`${API_URL}/user/admin/delete/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const updateUser = async (query) => {
  return await axios
    .put(`${API_URL}/user/update`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getUser = async (id) => {
  return await axios
    .get(`${API_URL}/user/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const editRole = async (id, query) => {
  return await axios
    .put(`${API_URL}/user/admin/edit/role/${id}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const editRoleUser = async (query) => {
  return await axios
    .put(`${API_URL}/user/edit-role`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const userService = {
  getAllRoles,
  getCompanyUsers,
  addUser,
  editUser,
  deactivateUser,
  updateUser,
  getUser,
  editRole,
  editRoleUser,
  getAllUsers_,
};
