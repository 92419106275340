import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(),
  borderBottom: "1px solid #B1AEAE",
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 700,
  color: theme.palette.darkGray.main,
  marginLeft: theme.spacing(),
}));

const Header = ({ handleClose }) => {
  return (
    <HeaderContainer>
      <IconButton
        onClick={handleClose}
        style={{ paddingLeft: 0, width: 24, height: 24 }}>
        <ArrowBackIcon style={{ color: "#434141" }} />
      </IconButton>
      <HeaderText>Sipariş Detay</HeaderText>
    </HeaderContainer>
  );
};

Header.propTypes = {
  handleClose: PropTypes.func,
};

export default Header;
