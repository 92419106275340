import React from "react";

import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

const PREFIX = 'UserHeader';

const classes = {
  root: `${PREFIX}-root`,
  subHeader: `${PREFIX}-subHeader`,
  headerText: `${PREFIX}-headerText`,
  section: `${PREFIX}-section`
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    borderBottom: `2px solid ${theme.palette.darkGray.light}`,
    height: 40,
  },

  [`& .${classes.subHeader}`]: {
    width: 1172,
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  [`& .${classes.headerText}`]: {
    width: "100%",
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    textAlign: "left",
  },

  [`& .${classes.section}`]: {
    "&[initial-header='true']": {
      minWidth: 42,
    },
    "&[field='Ad-Soyad']": {
      width: 122,
    },
    "&[field='Kayıt Tarihi']": {
      width: 147,
    },
    "&[field='Gsm No']": {
      width: 120,
    },
    "&[field='E-Posta']": {
      width: 200,
    },
    "&[field='Yetki Grubu']": {
      width: 100,
    },
    "&[field='Firma']": {
      width: 120,
    },
    "&[field='Adres']": {
      width: 120,
    },
    "&[field='Kayıt Durumu']": {
      width: 140,
    },
  }
}));

const headers = [
  "Ad-Soyad",
  "Kayıt Tarihi",
  "Gsm No",
  "E-Posta",
  "Yetki Grubu",
  "Firma",
  "Adres",
  "Kayıt Durumu",
];

const UserHeader = () => {

  return (
    <Root className={classes.root}>
      <div className={classes.section} initial-header="true" />
      <div className={classes.subHeader}>
        {headers.map((item, index) => (
          <div key={index} className={classes.section} field={item}>
            <Typography className={classes.headerText}>{item}</Typography>
          </div>
        ))}
      </div>
    </Root>
  );
};

export default UserHeader;
