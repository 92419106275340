import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import MobileDialogHeader from "../../../Ui/Dialog/Tools/MobileDialogHeader";

import FirmAddressCard from "../../Addresses/FirmAddressCard";
import Loading from "../../../Utils/Loading";
import MobileAddAddress from "../../../Ui/Dialog/Mobile/MobileAddAddress";

import { addressService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";
import { addressActions } from "../../../Redux/Actions";

import { Transition } from "../../../Utils/Tools";

const TabContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 35,
  display: "flex",
}));

const TabButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selectedButton",
})(({ selectedButton, theme }) => ({
  fontSize: 12,
  lineHeight: "18px",
  fontWeight: selectedButton ? 700 : 400,
  color: selectedButton ? theme.palette.primary.main : "#B1AEAE",
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderBottom: selectedButton
    ? "3px solid #6E3564"
    : `1px solid ${theme.palette.darkGray.light}`,
  touchAction: "none",
  WebkitTapHighlightColor: "transparent",
}));

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  padding: theme.spacing(),
}));

const AddressContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  marginBottom: theme.spacing(),
  marginTop: theme.spacing(),
  padding: theme.spacing(),
  display: "flex",
  justifyContent: "space-between",
}));

const DefaultAddressContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  height: "auto",
}));

const AddressText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: 14,
  fontWeight: text ? 300 : 400,
  lineHeight: text ? "16px" : "18px",
  color: theme.palette.darkGray.main,
}));

const NewAddressButton = styled(Button)(() => ({
  textTransform: "none",
  backgroundColor: "#10C66C",
  color: "#fff",
  height: 30,
  borderRadius: 0,
  "&:hover": {
    backgroundColor: "#10C66C",
  },
}));

const CartAddressSelection = ({ open, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const getAllAddresses = (query) =>
    dispatch(addressActions.getAddresses(query));

  const query = {
    pageNumber: 1,
    pageSize: 100,
    sortField: "id",
    sortOrder: "desc",
    filter: {},
  };

  const addresses = useSelector(({ addresses }) => addresses);
  const customer = useSelector(({ customer }) => customer);

  const [index, setIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userAddresses, setUserAddresses] = useState(null);
  const [firmAddresses, setFirmAddresses] = useState(null);
  const [customerAddresses, setCustomerAddresses] = useState(null);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    city_id: null,
    county_id: null,
    first_name: "",
    last_name: "",
    phone1: "",
    phone2: "",
    title: "",
    address1: "",
    email: "",
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer?.isAdmin) {
        let id = customer?.currentCompany?.id;
        let adminQuery = { ...query, filter: { company_id: id } };
        getCompanyAddresses(adminQuery);
      } else {
        addresses.addresses.length > 0
          ? setUserAddresses(addresses.addresses)
          : setUserAddresses(null);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (userAddresses && userAddresses.length > 0) {
        let companyAddr = userAddresses.filter((addr) => addr.type === 0);
        let customerAddr = userAddresses.filter((addr) => addr.type === 1);
        setFirmAddresses(companyAddr);
        setCustomerAddresses(customerAddr);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddresses]);

  const openAddAddressModal = () => setOpenAddAddress(true);
  const closeAddAddressModal = () => setOpenAddAddress(false);

  const closeAddAddressFromButton = () => {
    closeAddAddressModal();
    setValues({
      ...values,
      city_id: "",
      county_id: "",
      first_name: "",
      last_name: "",
      phone1: "",
      phone2: "",
      title: "",
      address1: "",
      email: "",
    });
  };

  const handleError = () => {
    if (
      values.first_name === "" ||
      values.last_name === "" ||
      values.phone1.length !== 12 ||
      values.title === "" ||
      values.address1 === "" ||
      values.city_id === "" ||
      values.county_id === ""
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleAddAddress = () => {
    let isError = handleError();
    if (isError) return;
    let addressQuery = {
      pageNumber: 1,
      pageSize: 200,
      sortField: "id",
      sordOrder: "desc",
      filter: {},
    };
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.phone1).replace(/\s/g, "");
    let newPhone2 = phoneCode.concat(values.phone2).replace(/\s/g, "");

    let query = {
      city_id: values.city_id.id,
      county_id: values.county_id.id,
      type: 1,
      company_id: customer?.currentCompany.id,
      email: values.email,
      name: `${values.first_name} ${values.last_name}`,
      phone1: newPhone,
      phone2: newPhone2,
      address1: values.address1,
      address2: "",
      tax_office: "",
      tax_number: "",
    };

    let isAdmin = customer?.isAdmin;
    let userId = customer?.selectedUser?.id;

    let query_ = isAdmin ? { ...query, user_id: userId } : query;

    closeAddAddressFromButton();
    addressService.addAddress(query_, isAdmin).then((res) => {
      if (res.data.status === true) {
        getAllAddresses(addressQuery);
      }
    });
  };

  const getCompanyAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUserAddresses(response.data.addresses);
      setLoading(false);
    } else {
      setUserAddresses(null);
      setLoading(false);
    }
  };

  const goBack = () => history.goBack();

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <Loading open={loading} />
      <MobileDialogHeader
        header="Adresler"
        callback={onClose ? onClose : goBack}
      />
      <TabContainer>
        <TabButton
          onClick={() => setIndex(1)}
          selectedButton={index === 1 ? true : false}>
          Firma Şube Adresleri
        </TabButton>
        <TabButton
          onClick={() => setIndex(2)}
          selectedButton={index === 2 ? true : false}>
          Müşteri Adresleri
        </TabButton>
      </TabContainer>
      {index === 2 && (
        <AddressContainer>
          <DefaultAddressContainer>
            <AddressText>{customer?.currentAddress?.name}</AddressText>
            <AddressText text>Geçerli Adres</AddressText>
          </DefaultAddressContainer>
          <NewAddressButton variant="contained" onClick={openAddAddressModal}>
            Yeni Adres Ekle
          </NewAddressButton>
        </AddressContainer>
      )}
      <Root>
        {index === 1 ? (
          firmAddresses?.map((item, index) => (
            <FirmAddressCard data={item} key={index} cart />
          ))
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
            {customerAddresses?.map((item, index) => (
              <FirmAddressCard data={item} key={index} client cart />
            ))}
          </div>
        )}
      </Root>
      <MobileAddAddress
        open={openAddAddress}
        closeDialogFromButton={closeAddAddressFromButton}
        values={values}
        handleChange={handleChange}
        handleSubmit={handleAddAddress}
        error={error}
        setError={setError}
        setValues={setValues}
      />
    </Dialog>
  );
};

CartAddressSelection.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CartAddressSelection;
