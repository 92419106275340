import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  clearContainer: `${PREFIX}-clearContainer`,
  img: `${PREFIX}-img`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 33,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 14px 0px 8px",
  },

  [`& .${classes.text}`]: {
    width: "fit-content",
    height: "auto",
    fontSize: 10,
    fontWeight: 400,
    color: "#8E8E8E",
    padding: 0,
    userSelect: "none",
    "&[data-content='clear']": {
      fontSize: 10,
      cursor: "pointer",
    },
  },

  [`& .${classes.clearContainer}`]: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
  },

  [`& .${classes.img}`]: {
    userSelect: "none",
    marginLeft: 5,
    marginTop: 1,
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.4)",
    },
  },
}));

const Header = ({ handleHistoryClear, disabled }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.text}>ARAMA GEÇMİŞİ</div>
      <div className={classes.clearContainer}>
        <Button
          data-content="clear"
          className={classes.text}
          onClick={handleHistoryClear}
          disabled={disabled}>
          TEMİZLE
        </Button>
      </div>
    </Root>
  );
};

Header.propTypes = {
  handleHistoryClear: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Header;
