import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { BufiSwitch } from "../../Utils/Tools";

import ordersIcon from "../../Images/TopNavbar/orders.svg";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 40,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(),
}));

const StyledFormLabel = styled(Typography)(({ theme }) => ({
  color: "#B1AEAE",
  fontWeight: 400,
  fontSize: 12,
  "&[aktif='true']": {
    color: theme.palette.primary.main,
  },
}));

const SwitchArea = ({ item, handleSwitch, loading }) => {
  return (
    <Root>
      <FormControl>
        <FormControlLabel
          control={
            <BufiSwitch
              checked={item?.status === 1}
              value={item?.status}
              onChange={() => handleSwitch(item.id)}
              disabled={loading}
            />
          }
          label={
            item?.status === 1 ? (
              <StyledFormLabel aktif="true">Aktif</StyledFormLabel>
            ) : (
              <StyledFormLabel>Pasif</StyledFormLabel>
            )
          }
        />
      </FormControl>
      <IconButton>
        <img src={ordersIcon} alt="" />
      </IconButton>
    </Root>
  );
};

SwitchArea.propTypes = {
  item: PropTypes.object,
  handleSwitch: PropTypes.func,
  loading: PropTypes.bool,
};

export default SwitchArea;
