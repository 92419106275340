import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getNotifications = async (query) => {
  return await axios
    .post(`${API_URL}/notification/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const readNotification = async (id) => {
  return await axios
    .get(`${API_URL}/notification/read/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const readAllNotifications = async () => {
  return await axios
    .get(`${API_URL}/notification/read_all`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const deleteNotification = async (id) => {
  return await axios
    .delete(`${API_URL}/notification/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const notificationService = {
  getNotifications,
  readNotification,
  deleteNotification,
  readAllNotifications,
};
