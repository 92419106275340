import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Carousel from "../../Ui/Carousel/NewCarousel";
import PageContainer from "../Common/PageContainer";
import UserBox from "../../Ui/UserBox/UserBox";
// import BottomBoxes from "./Home/BottomBoxes";

import NewPageSections from "./Home/NewPageSections";
import SubCard from "../../Ui/DraggableCards/SubCard";

const Home = () => {
  const customer = useSelector(({ customer }) => customer);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer?.isAdmin && !customer?.currentAddress) {
        setOpen(true);
      } else return;
    }
    return () => {
      active = false;
    };
  }, [customer]);

  const closeDialog = () => setOpen(false);

  return (
    <PageContainer>
      <UserBox />
      <Carousel />
      <NewPageSections />
      {/* <BottomBoxes /> */}
      <SubCard handleCloseSub={closeDialog} openSub={open} noClose />
    </PageContainer>
  );
};

export default Home;
