const findValue = (data) => {
  let obj = {
    Marka: "brands",
    Kategori: "categories",
    Ebat: "sizes",
    Renk: "colors",
    Şekil: "shapes",
    "Ürün Değerlendirme": "rating",
    Arama: "searchText",
  };
  return obj[data];
};

export { findValue };
