import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CustomTF from "../Verification/CustomTF";
import Timer from "../Verification/Timer";

import { authService, userService } from "../../Services";
import { authActions } from "../../Redux/Actions";
import { generalConstants } from "../../Utils/Constants";

import { MobileDialogButton } from "../../Utils/Tools";
import MobileDialogHeader from "./Tools/MobileDialogHeader";

const Root = styled("div")(() => ({
  width: "100%",
  height: "auto",
  minHeight: 200,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const InputContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: "100%",
  height: 35,
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

const Resend = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textTransform: "none",
  fontSize: 10,
  fontWeight: 400,
  color: "#000000",
}));

const WrongCode = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: 12,
  fontWeight: 400,
  color: "#000000",
}));

const RecendNotice = styled(Typography)(({ theme }) => ({
  width: 260,
  height: "auto",
  fontSize: 10,
  fontWeight: 400,
  lineHeight: "16px",
  color: theme.palette.secondary.main,
  textAlign: "center",
  marginTop: theme.spacing(2),
}));

const VerificationStep = ({ email, gsm, onClose, text, setStep }) => {
  const regexp = /^[0-9\b]+$/;
  const dispatch = useDispatch();

  const authInfo = useSelector(({ auth }) => auth.customerInfo);
  const signOut = () => dispatch(authActions.signout());

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();
  const fifthInput = useRef();
  const sixthInput = useRef();

  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [sixthDigit, setSixthDigit] = useState("");

  const [secondsRemaining, setSecondsRemaining] = useState(120);
  const [wrongCode, setWrongCode] = useState(false);

  let tokenStr = firstDigit.concat(
    secondDigit,
    thirdDigit,
    fourthDigit,
    fifthDigit,
    sixthDigit
  );

  useEffect(() => {
    firstInput?.current?.focus();
  }, []);

  const handleFirstDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFirstDigit(d);
    } else if (regexp.test(d)) {
      setFirstDigit(d);
      secondInput.current.focus();
    }
  };

  const handleSecondDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSecondDigit(d);
      firstInput.current.focus();
    } else if (regexp.test(d)) {
      setSecondDigit(d);
      thirdInput.current.focus();
    }
  };

  const handleThirdDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setThirdDigit(d);
      secondInput.current.focus();
    } else if (regexp.test(d)) {
      setThirdDigit(d);
      fourthInput.current.focus();
    }
  };

  const handleFourthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFourthDigit(d);
      thirdInput.current.focus();
    } else if (regexp.test(d)) {
      setFourthDigit(d);
      fifthInput.current.focus();
    }
  };

  const handleFifthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFifthDigit(d);
      fourthInput.current.focus();
    } else if (regexp.test(d)) {
      setFifthDigit(d);
      sixthInput.current.focus();
    }
  };

  const handleSixthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSixthDigit(d);
      fifthInput.current.focus();
    } else if (regexp.test(d)) {
      setSixthDigit(d);
    }
  };

  const resetFields = () => {
    setFirstDigit("");
    setSecondDigit("");
    setThirdDigit("");
    setFourthDigit("");
    setFifthDigit("");
    setSixthDigit("");
  };

  const handleVerificationCode = () => {
    sendVerificationCode(handleZeroPad(tokenStr));
  };

  const handleZeroPad = (num) => {
    const zeroPad = String(num).padStart(4, "0");
    return zeroPad;
  };

  const handleQuery = (token) => {
    let query;
    if (gsm) {
      query = {
        type: 0,
        phone: gsm,
        code: token,
      };
    } else {
      query = {
        type: 1,
        email,
        code: token,
      };
    }
    return query;
  };

  const sendVerificationCode = async (token) => {
    resetFields();
    setWrongCode(false);

    let query = handleQuery(token);
    const response = await authService.validateConfirmCode(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      let userQuery = {
        first_name: authInfo.first_name,
        last_name: authInfo.last_name,
      };
      let updateQuery = gsm
        ? { ...userQuery, email: authInfo.email, gsm }
        : { ...userQuery, email, gsm: authInfo.gsm };

      const res = await userService.updateUser(updateQuery);
      if (res.data.status === generalConstants.STATUS_TRUE) {
        let text = gsm
          ? "Telefon numaranız başarılı bir şekilde güncellendi"
          : "Email adresiniz başarılı bir şekilde güncellendi";

        toast.success({ text });
        setTimeout(() => {
          onClose();
          signOut();
        }, 1000);
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  const handleResend = async () => {
    setWrongCode(false);
    resetFields();
    let query = gsm ? { type: 0, phone: gsm } : { type: 1, email };
    const response = await authService.createConfirmCode(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setSecondsRemaining(120);
    } else {
      console.log(response);
    }
  };

  const handleRoute = () => setStep(0);

  let prop = gsm ? gsm : email;

  if (!prop) {
    return (
      <Root>
        <div>Yönlendiriliyor...</div>
        {handleRoute()}
      </Root>
    );
  } else {
    return (
      <Root>
        <MobileDialogHeader
          header="Doğrulama Kodu"
          callback={handleRoute}
          mobile
        />
        <InputContainer>
          <CustomTF
            value={firstDigit}
            inputRef={firstInput}
            onChange={handleFirstDigitChange}
            mobile
          />
          <CustomTF
            value={secondDigit}
            inputRef={secondInput}
            onChange={handleSecondDigitChange}
            mobile
          />
          <CustomTF
            value={thirdDigit}
            inputRef={thirdInput}
            onChange={handleThirdDigitChange}
            mobile
          />
          <CustomTF
            value={fourthDigit}
            inputRef={fourthInput}
            onChange={handleFourthDigitChange}
            mobile
          />
          <CustomTF
            value={fifthDigit}
            inputRef={fifthInput}
            onChange={handleFifthDigitChange}
            mobile
          />
          <CustomTF
            value={sixthDigit}
            inputRef={sixthInput}
            onChange={handleSixthDigitChange}
            mobile
          />
        </InputContainer>
        {wrongCode && <WrongCode>Girdiğiniz kod hatalıdır!</WrongCode>}
        {secondsRemaining === 0 ? (
          <Resend onClick={handleResend}>Tekrar Kod Gönder</Resend>
        ) : (
          <Timer
            secondsRemaining={secondsRemaining}
            setSecondsRemaining={setSecondsRemaining}
          />
        )}
        <RecendNotice>
          {text} gönderilen kodu almadıysanız süre dolduktan sonra tekrar kod
          isteyin.
        </RecendNotice>
        <MobileDialogButton
          variant="contained"
          disabled={tokenStr.length !== 6}
          disableRipple
          onClick={handleVerificationCode}>
          Tamam
        </MobileDialogButton>
      </Root>
    );
  }
};

VerificationStep.propTypes = {
  email: PropTypes.string,
  gsm: PropTypes.string,
  onClose: PropTypes.func,
  text: PropTypes.string,
  setStep: PropTypes.func,
};

export default VerificationStep;
