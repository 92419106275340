import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import PriceBoxes from "../../../Ui/PriceBoxes/PriceBoxes";
import closeIcon from "../../../Images/DealerProfile/Close.svg";
import SectionHeader from "../../../Ui/Dialog/SpecialDemands/SpecialDemandsModal/SectionHeader";
import SpecialDemandsRadioArea from "../../../Ui/Dialog/SpecialDemands/SpecialDemandsModal/SpecialDemandsRadioArea";
import InputArea from "../../../Ui/Dialog/SpecialDemands/SpecialDemandsModal/InputArea";
import QuantitySelection from "../../../Ui/Fields/QuantitySelection";

const PREFIX = "RightPart";

const classes = {
  root: `${PREFIX}-root`,
  specialFeatures: `${PREFIX}-specialFeatures`,
  header: `${PREFIX}-header`,
  closeContainer: `${PREFIX}-closeContainer`,
  close: `${PREFIX}-close`,
  container: `${PREFIX}-container`,
  text: `${PREFIX}-text`,
  inputAreaAndStockButton: `${PREFIX}-inputAreaAndStockButton`,
  inputAreaContainer: `${PREFIX}-inputAreaContainer`,
  stockButton: `${PREFIX}-stockButton`,
  button: `${PREFIX}-button`,
  quantityAndButton: `${PREFIX}-quantityAndButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 402,
    minWidth: 402,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.specialFeatures}`]: {
    width: "100%",
    height: "auto",
    border: "1px solid #B1AEAE",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 8,
    paddingLeft: 20,
    paddingBottom: 10,
  },

  [`& .${classes.header}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 10,
  },

  [`& .${classes.close}`]: {
    width: 25,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -23,
  },

  [`& .${classes.container}`]: {
    width: "100%",
    maxHeight: 40,
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
    paddingBottom: 9,
    marginTop: -5,
    "&[last='true']": {
      borderBottom: "none",
    },
  },

  [`& .${classes.text}`]: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    borderBottom: "1px solid #B1AEAE",
    paddingBottom: 2,
    userSelect: "none",
    "& b": {
      color: "#FB0505",
      cursor: "pointer",
    },
  },

  [`& .${classes.inputAreaAndStockButton}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.inputAreaContainer}`]: {
    maxWidth: 320,
  },

  [`& .${classes.stockButton}`]: {
    fontSize: 14,
    fontWeight: 700,
    color: "#fff",
    textTransform: "capitalize",
    height: 35,
    marginBottom: 24,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.button}`]: {
    width: 248,
    height: 39,
    textTransform: "capitalize",
    backgroundColor:theme.palette.darkGray.main,
    color: "#fff",
    "&:hover, &:focus, &:active": {
      backgroundColor:theme.palette.darkGray.main,
    },
  },

  [`& .${classes.quantityAndButton}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

// const shapes = ["Daire", "Oval", "Standart"];
// const sides = ["Overlok", "Katlama", "Saçak", "İşlemsiz"];
// const webShapes = ["Orijinal", "Spor", "Klasik"];

const RightPart = ({
  price,
  customHeight,
  showSpecialFeaturesBox,
  openVerifyModal,
  selections,
  handleFeatures,
  openSpecialFeaturesBox,
  openStockModal,
  handleChange,
  handleSubmit,
  disableButton,
  product,
  featureVals,
}) => {
  const renderPriceBoxes = useMemo(() => {
    return (
      <PriceBoxes
        price={price}
        productType={product?.type?.id}
        component="fast-order"
      />
    );
  }, [price, product]);

  const isLast = selections?.features.side === "Saçak" ? "false" : "true";

  return (
    <Root className={classes.root}>
      {renderPriceBoxes}
      <div style={{ minHeight: 227 }}>
        {customHeight || showSpecialFeaturesBox ? (
          <div className={classes.specialFeatures}>
            <Typography className={classes.header}>Özel İşlem</Typography>
            {!customHeight && showSpecialFeaturesBox && (
              <div className={classes.closeContainer}>
                <IconButton
                  className={classes.close}
                  onClick={openVerifyModal}
                  size="large">
                  <img
                    src={closeIcon}
                    width="14px"
                    height="14.37px"
                    alt="Close Icon"
                  />
                </IconButton>
              </div>
            )}
            <SectionHeader usedIn="fast-order">Şekil</SectionHeader>
            <div className={classes.container}>
              <SpecialDemandsRadioArea
                data={featureVals?.shapes}
                selectedValue={selections?.features.shape}
                value="shape"
                handleFeatures={handleFeatures}
                usedIn="fast-order"
                features={selections?.features}
              />
            </div>
            <SectionHeader usedIn="fast-order">Kenar Dokuma</SectionHeader>
            <div className={classes.container} last={isLast}>
              <SpecialDemandsRadioArea
                data={featureVals.sides}
                selectedValue={selections?.features.side}
                value="side"
                handleFeatures={handleFeatures}
                usedIn="fast-order"
                features={selections?.features}
              />
            </div>
            {selections?.features.side === "Saçak" && (
              <>
                <SectionHeader usedIn="fast-order">Saçak Tipi</SectionHeader>
                <div className={classes.container} last="true">
                  <SpecialDemandsRadioArea
                    data={featureVals?.webTypes}
                    selectedValue={selections?.features.webType}
                    value="webType"
                    handleFeatures={handleFeatures}
                    usedIn="fast-order"
                    features={selections?.features}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <Typography className={classes.text}>
            Dilerseniz bu ürüne{" "}
            <b onClick={() => openSpecialFeaturesBox()}>Özel İşlem</b>{" "}
            uygulatabilirsiniz
          </Typography>
        )}
      </div>
      {customHeight || showSpecialFeaturesBox ? (
        <div className={classes.inputAreaAndStockButton}>
          <div className={classes.inputAreaContainer}>
            <InputArea
              handleFeatures={handleFeatures}
              features={selections?.features}
              fastOrder
            />
          </div>
          <Button
            disableRipple
            className={classes.stockButton}
            variant="contained"
            onClick={openStockModal}>
            Stoğu Gör
          </Button>
        </div>
      ) : (
        <div style={{ width: "100%", height: 89 }}></div>
      )}
      <div className={classes.quantityAndButton}>
        <QuantitySelection
          handleChange={handleChange}
          param="quantity"
          selectedValue={selections?.quantity}
          component="fast-order"
          stockInfo={product?.stock.quantity}
        />
        <Button
          variant="contained"
          disableRipple
          className={classes.button}
          onClick={handleSubmit}
          disabled={disableButton}>
          Sepete Ekle
        </Button>
      </div>
    </Root>
  );
};

RightPart.propTypes = {
  price: PropTypes.object,
  customHeight: PropTypes.bool,
  showSpecialFeaturesBox: PropTypes.bool,
  openVerifyModal: PropTypes.func,
  selections: PropTypes.object,
  handleFeatures: PropTypes.func,
  openSpecialFeaturesBox: PropTypes.func,
  openStockModal: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  disableButton: PropTypes.bool,
  product: PropTypes.object,
  featureVals: PropTypes.object,
};

export default RightPart;
