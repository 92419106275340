import React from "react";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";

import menuIcon from "../../../Images/Filter/Menu.svg";
import MenuGray from "../../../Images/Filter/MenuGray.svg";
import listIcon from "../../../Images/Filter/List.svg";
import ListPurple from "../../../Images/Filter/ListPurple.svg";

const AppBarButtons = ({ view, setView }) => {
  return (
    <>
      <IconButton
        onClick={() => setView("bars")}
        style={{ backgroundColor: "transparent", width: 18, height: 18 }}
        disableRipple
        size="large">
        <img
          src={view === "bars" ? menuIcon : MenuGray}
          width="18px"
          height="18px"
          alt="Menu Icon"
        />
      </IconButton>
      <IconButton
        onClick={() => setView("list")}
        style={{ backgroundColor: "transparent", width: 18, height: 18 }}
        disableRipple
        size="large">
        <img
          src={view === "list" ? ListPurple : listIcon}
          width="18px"
          height="18px"
          alt="List Icon"
        />
      </IconButton>
    </>
  );
};

AppBarButtons.propTypes = {
  view: PropTypes.string,
  setValues: PropTypes.func,
};

export default AppBarButtons;
