import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getSuggestions = async (keyword) => {
  return await axios
    .get(`${API_URL}/search/suggestions/${keyword}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getResults = async (keyword, query) => {
  return await axios
    .post(`${API_URL}/search/results/${keyword}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const clearHistory = async () => {
  return await axios.delete(`${API_URL}/search/history/clear`, authCustomerHeader()).then((res) => {
    return res;
  });
};

const getHistory = async () => {
  return await axios
    .get(`${API_URL}/search/history/list`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const searchService = {
  getSuggestions,
  getResults,
  clearHistory,
  getHistory,
};
