import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import {
  customLowerCase,
  customUpperCase,
} from "../../../../Utils/Helpers/CustomLetterConversion";
import grayCheckbox from "../../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../../Images/Checked.svg";

import { queryMakerMob } from "../../../../Utils/Helpers";
import { Transition } from "../../../../Utils/Tools";

import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import Header from "./Header";

const StyledFormControl = styled(FormControl)(() => ({
  width: "100%",
  paddingBottom: 11,
  overflowY: "scroll",
  height: "calc(100vh - 160px)",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 300,
  color: theme.palette.darkGray.main,
  marginLeft: 10,
  lineHeight: "20px",
}));

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  // width: "100%",
  flex: 1,
  height: 26,
  marginBottom: theme.spacing(),
  "& .MuiInputBase-root": {
    height: 26,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 4,
    height: 17,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 4,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
    "&::placeholder": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      opacity: 1,
      paddingTop: "2px !important",
    },
  },
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 5,
  cursor: "pointer",
  zIndex: 9999,
}));

const FormArea = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(2),
  overflow: "hidden",
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: 40,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#fff",
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 8,
}));

const FilterDialog = ({
  open,
  handleClose,
  items,
  value,
  filter,
  filters,
  setFilters,
}) => {
  const [keyword, setKeyword] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (prop, item) => (event) => {
    let obj = queryMakerMob(item, prop);
    let isExists = _.find(selectedFilters, { id: obj.id });

    if (isExists) {
      let newFilters = selectedFilters.filter((item) => item.id !== obj.id);
      setSelectedFilters(newFilters);
    } else {
      setSelectedFilters([...selectedFilters, obj]);
    }
  };

  const onDelete = () => setSelectedFilters([]);

  useEffect(() => {
    let active = true;
    if (active) {
      setSelectedFilters(filters[value]);
    }
    return () => {
      active = false;
    };
  }, [filters, value]);

  useEffect(() => {
    if (keyword !== "") {
      let customLower = customLowerCase(keyword);
      let customUpper = customUpperCase(keyword);

      let customInitialUpper =
        customUpperCase(keyword.charAt(0)) + keyword.slice(1);

      let customInitialLower =
        customLowerCase(keyword.charAt(0)) + keyword.slice(1);

      let initialUpper = customLower.replace(
        /(^\w{1})|(\s{1}\w{1})/g,
        (match) => customUpperCase(match)
      );

      let initialUpperAll =
        customUpperCase(customLower.charAt(0)) + customLower.slice(1);

      let newProductsList = items.filter(
        (product) =>
          product.name.includes(keyword) ||
          product.name.includes(customInitialLower) ||
          product.name.includes(customInitialUpper) ||
          product.name.includes(customLower) ||
          product.name.includes(customUpper) ||
          product.name.includes(initialUpperAll) ||
          product.name.includes(initialUpper)
      );

      setProducts(newProductsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const clearSearchText = () => setKeyword("");
  const handleKeyword = (e) => setKeyword(e.target.value);

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleKeyword(e.target.value);
    }
  };

  const handleSubmit = () => {
    setFilters({ ...filters, [value]: selectedFilters });
    handleClose(value);
  };

  let boxValues = keyword !== "" ? products : items;

  const getLabel = (item, value) => {
    let obj = {
      user_id: item.full_name,
      shipping_address: item.name,
      order_key: item.status,
      order_status: item.status,
      source: item.status,
    };
    return obj[value];
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <Header
        handleClose={handleClose}
        headerText={filter}
        onDelete={onDelete}
        prop={value}
      />
      <FormArea>
        <StyledTextField
          fullWidth
          value={keyword}
          onChange={handleKeyword}
          onKeyDown={keyPress}
          placeholder="Ara"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {keyword?.length > 0 && (
                  <CloseAdornment position="end" onMouseDown={clearSearchText}>
                    <Fade in={keyword?.length > 0}>
                      <CloseIcon style={{ fill: "#8E8E8E" }} />
                    </Fade>
                  </CloseAdornment>
                )}
              </>
            ),
          }}
          inputProps={{
            autoComplete: "new-password",
          }}
        />

        <StyledFormControl component="fieldset">
          <FormGroup>
            {boxValues &&
              boxValues?.map((item, index) => (
                <FormControlLabel
                  style={{ marginBottom: 8 }}
                  key={index}
                  control={
                    <Checkbox
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        margin: "0px 0px 0px 14px",
                      }}
                      disableRipple
                      checked={selectedFilters.some(
                        (obj) => obj.id === item.id
                      )}
                      onChange={handleChange(value, item)}
                      id={item.id.toString()}
                      name={item.name}
                      value={filter}
                      icon={<img src={grayCheckbox} width="20px" alt="" />}
                      checkedIcon={<img src={Checked} width="20" alt="" />}
                    />
                  }
                  label={<StyledLabel>{getLabel(item, value)}</StyledLabel>}
                />
              ))}
          </FormGroup>
        </StyledFormControl>
        <ApplyButton onClick={handleSubmit} disableRipple>
          Uygula
        </ApplyButton>
      </FormArea>
    </Dialog>
  );
};

FilterDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  items: PropTypes.any,
  value: PropTypes.string,
  filter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default FilterDialog;
