import React from "react";
import PropTypes from "prop-types";
import {useSelector, shallowEqual} from "react-redux"

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 84,
  height: "auto",
  marginRight: theme.spacing(),
}));

const StyledImage = styled("img")(({ theme }) => ({
  borderRadius: 8,
  marginBottom: theme.spacing(),
}));

const Price = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: "#000000",
  width: "100%",
  textAlign: "center",
}));

const ItemCardImage = ({ product }) => {
  const {role_names} = useSelector(({ customer }) => customer?.selectedUser, shallowEqual);

  return (
    <Root>
      <StyledImage src={product?.image} width="83px" height="123px" alt="" />
      <Price>{role_names && role_names[0] === "companyuser" ? 0 : product?.cost} TL</Price>
    </Root>
  );
};

ItemCardImage.propTypes = {
  product: PropTypes.object,
};

export default ItemCardImage;
