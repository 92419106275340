import React from "react";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import PropTypes from "prop-types";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import RadioSelected from "../../../../Images/ProductPage/RadioSelected.svg";
import RadioUnselected from "../../../../Images/ProductPage/RadioUnselected.svg";

const PREFIX = "SpecialDemandsRadioArea";

const classes = {
  radio: `${PREFIX}-radio`,
  formControl: `${PREFIX}-formControl`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  selected: `${PREFIX}-selected`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.radio}`]: {
    width: 22,
    height: 22,
    padding: 0,
    marginRight: 3,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`&.${classes.formControl}`]: {
    marginTop: 13,
  },

  [`& .${classes.formControlLabel}`]: {
    "&.MuiFormControlLabel-root": {
      margin: 0,
      minWidth: 95,
    },
    "& .MuiTypography-body1": {
      marginLeft: 2,
      marginTop: 1,
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.secondary.main,
    },
    "&[used-in='fast-order']": {
      "& .MuiTypography-body1": {
        marginLeft: 0,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },

  [`& .${classes.selected}`]: {
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  },
}));

const SpecialDemandsRadioArea = ({
  data,
  selectedValue,
  value,
  handleFeatures,
  usedIn,
  features,
}) => {
  return (
    <StyledFormControl component="fieldset" className={classes.formControl}>
      <RadioGroup row style={{ width: "100%" }}>
        {data?.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item}
            label={item}
            used-in={usedIn}
            style={{
              display:
                value === "shape"
                  ? features?.side === "Katlama" &&
                    (item === "Daire" || item === "Oval")
                    ? "none"
                    : "inline-flex"
                  : value === "side"
                  ? (features?.shape === "Daire" ||
                      features?.shape === "Oval") &&
                    item === "Katlama"
                    ? "none"
                    : "inline-flex"
                  : "inline-flex",
            }}
            className={classnames({
              [classes.formControlLabel]: true,
              [classes.selected]: item === selectedValue && true,
            })}
            control={
              <Radio
                className={classes.radio}
                onClick={handleFeatures(value)}
                disableRipple
                value={item}
                checked={item === selectedValue}
                icon={<img src={RadioUnselected} alt="" />}
                checkedIcon={<img src={RadioSelected} alt="" />}
              />
            }
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  );
};

SpecialDemandsRadioArea.propTypes = {
  data: PropTypes.array,
  selectedValue: PropTypes.string,
  value: PropTypes.string,
  handleFeatures: PropTypes.func,
  usedIn: PropTypes.string,
  features: PropTypes.object,
};

export default SpecialDemandsRadioArea;
