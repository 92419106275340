import { campaignConstants } from "../../Utils/Constants";
import { campaignReducerInitialState } from "../../Utils/Constants";

const initialState = {
  campaigns: null,
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case campaignConstants.GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case campaignConstants.CLEAR_CAMPAIGN_REDUCER:
      return {
        campaignReducerInitialState,
      };
    default:
      return state;
  }
};

export { campaigns };
