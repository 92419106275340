import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TitleComponent from "./Common/TitleComponent";
import Section from "./Common/Section";

import { SourceValues } from "../../../../Utils/Constants";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(),
}));

const Sources = ({ sources, handleSource }) => {
  return (
    <Root>
      <TitleComponent title="Kaynak" />
      <Section data={SourceValues} callBack={handleSource} values={sources} />
    </Root>
  );
};

Sources.propTypes = {
  sources: PropTypes.array,
  handleSource: PropTypes.func,
};

export default Sources;
