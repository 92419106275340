import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

import { customerActions } from "../../../Redux/Actions";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  marginTop: theme.spacing(2),
}));

const History = ({ data, setKeyword }) => {
  const dispatch = useDispatch();
  const history_ = useHistory();

  const setText = (text) => dispatch(customerActions.setSearchText(text));

  const handleSearchText = (item) => {
    setKeyword(item);
    setText(item);
    history_.push("/filters-page");
  };

  return (
    <Root>
      {data && data.length > 0
        ? data.map((item, index) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "0px 8px",
                marginBottom: (theme) => theme.spacing(),
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
                "&:hover .text": {
                  color: (theme) => theme.palette.primary.main,
                },
                "&:hover .svg": {
                  fill: (theme) => theme.palette.primary.main,
                },
              }}
              key={index}
              onClick={() => handleSearchText(item.keyword)}>
              <SearchIcon className="svg" fontSize="12" />
              <Typography
                className="text"
                sx={{
                  marginLeft: (theme) => theme.spacing(2),
                  fontSize: 12,
                  fontWeight: 400,
                  color: theme => theme.palette.darkGray.main,
                }}>
                {item.keyword}
              </Typography>
            </Box>
          ))
        : null}
    </Root>
  );
};

History.propTypes = {
  data: PropTypes.array,
  setKeyword: PropTypes.func,
};

export default History;
