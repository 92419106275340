import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import MobileDialogHeader from '../../Ui/Dialog/Tools/MobileDialogHeader'
import PageContainerMob from '../Common/PageContainerMob'
import UnAuthorized from './Main/Mobile/UnAuthorized'
import MobileUsers from './Main/Mobile/MobileUsers'

const TabContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 35,
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  marginBottom: theme.spacing(),
}))

const TabButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selectedButton',
})(({ selectedButton, theme }) => ({
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: selectedButton ? 700 : 400,
  color: selectedButton ? theme.palette.primary.main : '#B1AEAE',
  width: '50%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: selectedButton ? '3px solid #6E3564' : `1px solid ${theme.palette.darkGray.light}`,
  touchAction: 'none',
  WebkitTapHighlightColor: 'transparent',
}))

const UsersAndRolesMob = () => {
  const history = useHistory()

  const authInfo = useSelector(({ auth }) => auth.customerInfo)

  const [index, setIndex] = useState(1)

  const goBack = () => history.goBack()

  if (authInfo?.role_names[0] === 'companyuser') {
    return <UnAuthorized />
  }

  return (
    <PageContainerMob noScroll>
      <MobileDialogHeader header='Kullanıcı Yönetimi' callback={goBack} />
      <TabContainer>
        <TabButton onClick={() => setIndex(1)} selectedButton={index === 1 ? true : false}>
          Kullanıcılar
        </TabButton>
        {/* <TabButton
          onClick={() => setIndex(2)}
          selectedButton={index === 2 ? true : false}>
          Yetkiler
        </TabButton> */}
      </TabContainer>
      {index === 1 ? <MobileUsers /> : <div />}
    </PageContainerMob>
  )
}

export default UsersAndRolesMob
