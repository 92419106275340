import React from "react";

import { styled } from "@mui/material/styles";

import tickIcon from "../../../Images/Auth/tick.svg";

const PREFIX = "Info";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  img: `${PREFIX}-img`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: 288,
    height: 37.5,
    display: "flex",
    justifyContent: "center",
    userSelect: "none",
  },

  [`& .${classes.text}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },

  [`& .${classes.img}`]: {
    marginLeft: 5,
  },
}));

const Info = () => {
  return (
    <Root className={classes.root}>
      <div className={classes.text}>Şifreniz başarıyla yenilendi</div>
      <div className={classes.img}>
        <img src={tickIcon} alt="tickIcon" width="17.5px" height="13.41" />
      </div>
    </Root>
  );
};

export default Info;
