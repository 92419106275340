import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import MobileDialogHeader from "../../../../Ui/Dialog/Tools/MobileDialogHeader";

import { Transition } from "../../../../Utils/Tools";

import FirmEdit from "./MobileEditUser/FirmEdit";
import UserEdit from "./MobileEditUser/UserEdit";

const TabContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 35,
  display: "flex",
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  marginBottom: theme.spacing(),
}));

const TabButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selectedButton",
})(({ selectedButton, theme }) => ({
  fontSize: 12,
  lineHeight: "18px",
  fontWeight: selectedButton ? 700 : 400,
  color: selectedButton ? theme.palette.primary.main : "#B1AEAE",
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderBottom: selectedButton ? "3px solid #6E3564" : `1px solid ${theme.palette.darkGray.light}`,
  touchAction: "none",
  WebkitTapHighlightColor: "transparent",
}));

const MobileEditUser = ({
  open,
  onClose,
  userInfo,
  status,
  roles,
  newGetUsers,
  query,
}) => {
  const [index, setIndex] = useState(1);
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <MobileDialogHeader
        header="Kullanıcı Bilgileri"
        callback={onClose}
        paddingTop
      />
      <DialogContent style={{ padding: 0 }}>
        <TabContainer>
          <TabButton
            onClick={() => setIndex(1)}
            selectedButton={index === 1 ? true : false}>
            Kullanıcı Bilgileri
          </TabButton>
          <TabButton
            onClick={() => setIndex(2)}
            selectedButton={index === 2 ? true : false}>
            Firma Bilgileri
          </TabButton>
        </TabContainer>
        <div
          style={{
            padding: 16,
            width: "100%",
            height: "auto",
          }}>
          {index === 1 ? (
            <UserEdit
              onClose={onClose}
              userInfo={userInfo}
              status={status}
              newGetUsers={newGetUsers}
              query={query}
      
            />
          ) : (
            <FirmEdit
              open={open}
              onClose={onClose}
              roles={roles}
              userInfo={userInfo}
              status={status}
              newGetUsers={newGetUsers}
              query_={query}
      
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

MobileEditUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userInfo: PropTypes.object,
  status: PropTypes.string,
  roles: PropTypes.array,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
};

export default MobileEditUser;
