import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PageContainer from "../Common/PageContainer";
import AboutUsImage from "../../Images/AboutUs/AboutUs.png";

const StyledCard = styled("div")(() => ({
  width: "100%",
  height: "auto",
  borderRadius: 8,
  backgroundColor: "#fff",
  padding: 20,
  marginBottom: 30,
}));

const StyledText = styled(Typography, {shouldForwardProp: prop => prop !== 'topMargin'})(({theme, topMargin}) => ({
  fontSize: 24,
  fontWeight: 400,
  color: "#000000",
  textAlign: "left",
  lineHeight: "30px",
  marginBottom: theme.spacing(5),
  marginTop: topMargin && theme.spacing(10)
}));

const StyledImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const AboutUs = () => {
  return (
    <PageContainer>
      <StyledCard>
        <StyledImageContainer>
          <img src={AboutUsImage} alt="" />
        </StyledImageContainer>
        <StyledText topMargin>Hakkımızda;</StyledText>
        <StyledText>
          2003 yılı Mart ayında Ankara'nın Altındağ İlçesi Malazgirt Caddesinde
          Özsafir Halı çatısı altında ticaret hayatımıza başladık.
        </StyledText>
        <StyledText>2008 yılında "Enti Halı" markasını kurduk.</StyledText>
        <StyledText>
          2010 yılında Şenkal Sokak No:11 Siteler / Ankara adresinde ki binamıza
          taşınarak 500'e yakın bayi noktasına ulaştık.
        </StyledText>
        <StyledText>
          2015 yılında İç Anadolu, Batı Karadeniz Bölge Bayisi olduğumuz Empera
          Halı markasını da bünyemize ekleyerek, makina halıcılığında
          birbirinden farklı kaliteler ile bayilerimize hizmet vermeye devam
          ettik.
        </StyledText>
        <StyledText>
          2018 yılında 5.000 metrekare kapalı alanda Demirhendek Cad. No:14
          Siteler Ankara mevcut merkez adresimize taşındık.
        </StyledText>
        <StyledText>
          2019 yılında Romans Halı markasını da bünyemize katarak, farklı tarz
          ve kalitede yeni ürünlerle, 800'e yakın bayi noktasına ulaştık.
        </StyledText>
        <StyledText>
          Görevimiz: Makina halıcılığında öncü olmayı başarmaktır.
        </StyledText>
        <StyledText>
          Hedefimiz: Hizmet verdiğimiz bayilerimizin piyasa ile rekabet edebilme
          gücünü ve kararlılığını arttırabilmek için yenilikçi hizmet
          anlayışıyla çalışmaktır. "Hep birlikte daha güzel yarınlara ulaşmak
          dileğiyle."
        </StyledText>
        <StyledText>
          "Hep birlikte daha güzel yarınlara ulaşmak dileğiyle."{" "}
        </StyledText>
      </StyledCard>
    </PageContainer>
  );
};

export default AboutUs;
