import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const PREFIX = "Features";

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(3),
    "&[is-mobile='true']": {
      paddingLeft: theme.spacing(),
      paddingTop: 0,
    },
  },

  [`& .${classes.item}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: "#B1AEAE",
    marginBottom: theme.spacing(),
    "& > span": {
      fontWeight: 800,
    },
  },

  [`& .${classes.list}`]: {
    paddingLeft: theme.spacing(3),
  },
}));

const Features = ({ data, mobile }) => {
  return (
    <Root className={classes.root} is-mobile={mobile ? "true" : "false"}>
      <ul className={classes.list}>
        {Object.keys(data).map((keyName, index) => (
          <li key={index} className={classes.item}>
            <span>{keyName}:</span> {data[keyName]}
          </li>
        ))}
      </ul>
    </Root>
  );
};

Features.propTypes = {
  data: PropTypes.object,
  mobile: PropTypes.bool,
};

export default Features;
