export const addressConstants = {
  GET_ADDRESSES_SUCCESS: "GET_ADDRESSES_SUCCESS",
  GET_ADDRESSES_REQUEST: "GET_ADDRESSES_REQUEST",
  GET_ADDRESSES_FAILURE: "GET_ADDRESSES_FAILURE",

  TOGGLE_ADDRESS_REQUEST: "TOGGLE_ADDRESS_REQUEST",
  TOGGLE_ADDRESS_SUCCESS: "TOGGLE_ADDRESS_SUCCESS",
  TOGGLE_ADDRESS_FAILURE: "TOGGLE_ADDRESS_FAILURE",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  ADD_ADDRESS: "ADD_ADDRESS",
  EDIT_ADDRESS: "EDIT_ADDRESS",

  SET_ADMIN_ADDRESS: "SET_ADMIN_ADDRESS",
  SET_USER_ADDRESS: "SET_USER_ADDRESS",

  CLEAR_ADDRESSES: "CLEAR_ADDRESSES",
  TOGGLE_ADDRESS_ID: "TOGGLE_ADDRESS_ID"
};
