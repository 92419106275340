import { isMobileOnly } from "react-device-detect";

export const FirstColumn = [
  {
    name: "Hakkımızda",
    path: "about-us",
  },
  {
    name: "Satış Noktalarımız",
    path: "map",
  },
  {
    name: "Bölge Bayilerimiz",
    path: "",
  },
  {
    name: "İletişim",
    path: "",
  },
];

export const SecondColumn = [
  {
    name: "Ürünler",
    path: "filters-page",
  },
  {
    name: "Kategoriler",
    path: "filters-page",
  },
  {
    name: "Markalar",
    path: "filters-page",
  },
];

export const ThirdColumn = [
  {
    name: "Siparişlerim",
    path: "order-page",
  },
  {
    name: "Arızalı Ürün Formu",
    path: "",
  },
  {
    name: "Ödeme Formu",
    path: "",
  },
  !isMobileOnly && {
    name: "Hızlı Sipariş",
    path: "fast-order",
  },
];

export const FourthColumn = [
  {
    name: "Profil Ayarları",
    path: "dealer-profile",
  },
  {
    name: "Raporlarım",
    path: "",
  },
  {
    name: "Adreslerim",
    path: "addresses",
  },
  {
    name: "Kullanıcı ve Roller",
    path: "",
  },
];
