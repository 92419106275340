import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";

import MobileDialogHeader from "../../../Ui/Dialog/Tools/MobileDialogHeader";
import Features from "../FeaturesAndRatings/Features";
import { Transition } from "../../../Utils/Tools";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  position: "relative",
}));

const TabContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: 35,
  display: "flex",
}));

const TabButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selectedButton",
})(({ selectedButton, theme }) => ({
  fontSize: 14,
  fontWeight: selectedButton ? 400 : 300,
  color: selectedButton
    ? theme.palette.primary.main
    : theme.palette.darkGray.main,
  width: innerWidth / 2,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderBottom: selectedButton ? "3px solid #6E3564" : `1px solid ${theme.palette.darkGray.light}`,
  touchAction: "none",
  WebkitTapHighlightColor: "transparent",
}));

const FeaturesAndDetails = ({ open, handleClose, data }) => {
  const [index, setIndex] = useState(1);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <StyledContainer>
        <MobileDialogHeader header={data?.name} callback={handleClose} />
        <TabContainer>
          <TabButton
            onClick={() => setIndex(1)}
            selectedButton={index === 1 ? true : false}>
            Açıklama
          </TabButton>
          <TabButton
            onClick={() => setIndex(2)}
            selectedButton={index === 2 ? true : false}>
            Özellikler
          </TabButton>
        </TabContainer>
        {index === 1 ? (
          <div style={{ width: "100%", height: "100%" }}>
            <Features data={data?.properties} mobile />
          </div>
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            <Features data={data?.properties} mobile />
          </div>
        )}
      </StyledContainer>
    </Dialog>
  );
};

FeaturesAndDetails.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

export default FeaturesAndDetails;
