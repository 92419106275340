import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: "auto" }}
      role="tabpanel"
      hidden={value !== index}
      id={`filter-tabpanel-${index}`}
      aria-labelledby={`filter-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          p={2}
          id="scrollableDiv"
          style={{
            maxHeight: "auto",
            overflow: "scroll",
            scrollBehavior: "smooth",
          }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
