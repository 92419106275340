import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const RouteButton = styled(Button)(({theme}) => ({
  userSelect: "none",
  width: 288,
  height: 41.67,
  textTransform: "none",
  borderRadius: 8,
  textAlign: "center",
  marginTop: 14.33,
  backgroundColor: "white",
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: "400",
  display: "flex",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "white",
  },
}));

export const SubmitButton = styled(Button)(({theme}) => ({
  userSelect: "none",
  width: 288,
  height: 41.67,
  textTransform: "none",
  borderRadius: 8,
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  fontSize: 16,
  fontWeight: 400,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const CustomTextField = styled(TextField)(({theme}) => ({
  width: 288,
  maxHeight: 32.88,
  backgroundColor: "white",
  border: "none",
  borderRadius: 8,
  padding: "2px 12px",
  fontSize: 12,
  fontWeight: 400,
  "& .MuiInputBase-input": {
    padding: "5px 0px",
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
}));
