import React, { useState } from "react";
import PropTypes from "prop-types";
import {useSelector, shallowEqual} from "react-redux"

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";

import OrderItemDetails from "./OrderItem/OrderItemDetails";

import expandUp from "../../../Images/Orders/ExpandUp.svg";
import expandDown from "../../../Images/Orders/ExpandDown.svg";
import blueBox from "../../../Images/Orders/BlueBox.svg";
import redBox from "../../../Images/Orders/RedBox.svg";
import greenBox from "../../../Images/Orders/GreenBox.svg";
import yellowBox from "../../../Images/Orders/YellowBox.svg";
import OzsafirSolo from "../../../Images/TopNavbar/OzsafirSolo.svg";

const PREFIX = "OrderItem";

const classes = {
  root: `${PREFIX}-root`,
  subContainer: `${PREFIX}-subContainer`,
  imgField: `${PREFIX}-imgField`,
  source: `${PREFIX}-source`,
  no: `${PREFIX}-no`,
  date: `${PREFIX}-date`,
  person: `${PREFIX}-person`,
  amount: `${PREFIX}-amount`,
  note: `${PREFIX}-note`,
  address: `${PREFIX}-address`,
  status: `${PREFIX}-status`,
  text: `${PREFIX}-text`,
  imgContainer1: `${PREFIX}-imgContainer1`,
  imgContainer2: `${PREFIX}-imgContainer2`,
  imageText: `${PREFIX}-imageText`,
  img: `${PREFIX}-img`,
  card: `${PREFIX}-card`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.root}`]: {
    minWidth: 900,
    height: "auto",
    paddingTop: 5,
    borderRadius: 8,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.50)",
      "&[collapsed='true']": {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    "&[collapsed='true']": {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },

  [`& .${classes.subContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 34,
    padding: "0px 10px",
    "&[collapsed='true']": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },

  [`& .${classes.imgField}`]: {
    width: 51,
    marginRight: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.source}`]: {
    width: 66,
    marginRight: 10,
  },

  [`& .${classes.no}`]: {
    width: 150,
    marginRight: 10,
  },

  [`& .${classes.date}`]: {
    width: 130,
    marginRight: 10,
  },

  [`& .${classes.person}`]: {
    width: 120,
    marginRight: 10,
  },

  [`& .${classes.amount}`]: {
    width: 100,
    marginRight: 10,
  },

  [`& .${classes.note}`]: {
    width: 200,
    marginRight: 10,
  },

  [`& .${classes.address}`]: {
    width: 120,
    marginRight: 10,
  },

  [`& .${classes.status}`]: {
    width: 180,
    marginRight: 10,
    display: "flex",
  },

  [`& .${classes.text}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    textAlign: "left",
  },

  [`& .${classes.imgContainer1}`]: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    // border: "1px solid #BDBDBD",
    position: "relative",
  },

  [`& .${classes.imgContainer2}`]: {
    width: 26,
    height: 26,
    borderRadius: "50%",
    border: "1px solid #BDBDBD",
    position: "absolute",
    left: 11,
    zIndex: 1,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 1,
  },

  [`& .${classes.imageText}`]: {
    fontSize: 12,
    color: theme.palette.darkGray.main,
  },

  [`& .${classes.img}`]: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    position: "absolute",
    left: -1,
    top: -1,
  },

  [`&.${classes.card}`]: {
    marginTop: 10,
    paddingTop: 0,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5,
    marginBottom: 10,
    borderRadius: 8,
    boxShadow: "none",
    "&[collapsed='true']": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const OrderItem = ({ order }) => {
  const selectedUser = useSelector(({ customer }) => customer?.selectedUser, shallowEqual);

  const [extended, setExtended] = useState(false);

  const toggleCollapse = () => setExtended(!extended);

  // const statusArray = [waiting_payment = 1,
  //     order_taken = 2
  //     getting_ready = 3
  //     prepared = 4
  //     awaiting_dispatch = 5
  //     has_shipped = 6
  //     delivery_vehicle = 7
  //     shipped = 8]

  const orderStatus = {
    0: { icon: blueBox, status: "Sipariş Alındı" },
    1: { icon: blueBox, status: "Hazırlanıyor" },
    2: { icon: greenBox, status: "Tamamlandı" },
    3: { icon: yellowBox, status: "Kısmi Sevk" },
    4: { icon: redBox, status: "İptal" },
  };

  const orderSource = {
    1: "Web",
    2: "Mobil",
    3: "Logo",
    4: "Panel",
    5: "Sistem",
  };

  return (
    <StyledCard
      className={classes.card}
      collapsed={extended ? "true" : "false"}>
      <div
        className={classes.root}
        onClick={toggleCollapse}
        collapsed={extended ? "true" : "false"}>
        <div
          className={classes.subContainer}
          collapsed={extended ? "true" : "false"}>
          <div
            style={{
              display: "flex",
            }}>
            <div className={classes.imgField}>
              <div className={classes.imgContainer1}>
                <img
                  src={
                    order?.products && order?.products.length > 0
                      ? order?.products[0].image
                      : OzsafirSolo
                  }
                  alt=""
                  className={classes.img}
                />
              </div>
              {order?.total_quantity > 1 && (
                <div className={classes.imgContainer2}>
                  <Typography className={classes.imageText}>
                    +{order?.total_quantity - 1}
                  </Typography>
                </div>
              )}
            </div>
            <div className={classes.source}>
              <Typography noWrap className={classes.text}>
                {orderSource[order?.source] || "Web"}
              </Typography>
            </div>
            <div className={classes.no}>
              <Typography noWrap className={classes.text}>
                #{order?.key}
              </Typography>
            </div>
            <div className={classes.date}>
              <Typography noWrap className={classes.text}>
                {order?.date}
              </Typography>
            </div>
            {/* <div className={classes.person}>
            <Typography noWrap className={classes.text}>
              {order?.buyer?.user?.full_name || order?.buyer.company.name}
            </Typography>
          </div> */}
            <div className={classes.amount}>
              <Typography noWrap className={classes.text}>
                {selectedUser?.role_names && selectedUser?.role_names[0] === 'companyuser' ? 0 : order?.total_cost}
              </Typography>
            </div>
            {/* <Tooltip
            title={order?.note ? order.note : ""}
            placement="bottom-start"
            arrow
            disableInteractive>
            <div className={classes.note}>
              <Typography noWrap className={classes.text} is-note="true">
                {order?.note}
              </Typography>
            </div>
          </Tooltip> */}
            <div className={classes.address}>
              <Typography noWrap className={classes.text}>
                {order?.address.name}
              </Typography>
            </div>
            <div className={classes.status}>
              <img
                src={orderStatus[order?.status]?.icon}
                width="20px"
                height="20px"
                alt=""
                style={{ marginRight: 10 }}
              />
              <Typography className={classes.text}>
                {orderStatus[order?.status]?.status}
              </Typography>
            </div>
          </div>
          {extended ? (
            <img src={expandUp} width="20px" alt="" />
          ) : (
            <img src={expandDown} width="20px" alt="" />
          )}
        </div>
      </div>
      <Collapse in={extended}>
        <OrderItemDetails extended={extended} order={order} />
      </Collapse>
    </StyledCard>
  );
};

OrderItemDetails.propTypes = {
  order: PropTypes.object,
};

export default OrderItem;
