import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
const PREFIX = "CustomDivider";

const classes = {
  dividerContainer: `${PREFIX}-dividerContainer`,
  divider: `${PREFIX}-divider`,
  dividerText: `${PREFIX}-dividerText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.dividerContainer}`]: {
    width: "100%",
    height: 20.83,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    marginTop: theme.spacing(3),
  },

  [`& .${classes.divider}`]: {
    width: 192,
    height: 1.04,
    borderBottom: `1.04px solid ${theme.palette.darkGray.light}`,
    "&[is-mobile='true']": {
      width: (innerWidth - 35) / 2,
      borderBottom: `1.04px solid ${theme.palette.primary.main}`,
    },
  },

  [`& .${classes.dividerText}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: "#85597A",
    textAlign: "center",
    userSelect: "none",
  },
}));

const innerWidth = window.innerWidth;

const CustomDivider = ({ children, mobile }) => {
  return (
    <Root className={classes.dividerContainer}>
      <div className={classes.divider} is-mobile={mobile ? "true" : "false"} />
      <div className={classes.dividerText}>{children}</div>
      <div className={classes.divider} is-mobile={mobile ? "true" : "false"} />
    </Root>
  );
};

CustomDivider.propTypes = {
  children: PropTypes.string,
  mobile: PropTypes.bool,
};

export default CustomDivider;
