import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

import ozsafirLogo from "../../Images/Auth/ozsafir-logo.svg";
import leaf from "../../Images/Auth/leaf.svg";
import branch from "../../Images/Auth/branch.svg";
import Header from "./AuthCommons/Header";

const PREFIX = "AuthContainer";

const gradientBackground =
  "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(210, 23, 163, 0.19) 0%, rgba(180, 48, 147, 0.19) 28.13%, rgba(133, 89, 122, 0.19) 100%)";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  logoContainer: `${PREFIX}-logoContainer`,
  card: `${PREFIX}-card`,
  branch: `${PREFIX}-branch`,
  leaf: `${PREFIX}-leaf`,
  footer: `${PREFIX}-footer`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    width: "100%",
    minHeight: "100vh",
    height: "100vh",
    background: gradientBackground,
  },

  [`& .${classes.container}`]: {
    overflow: "visible",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "transparent",
    padding: 0,
    contain: "content",
    marginBottom: 0,
    height: "100%",
  },

  [`& .${classes.logoContainer}`]: {
    marginTop: 31,
    marginBottom: 30,
    userSelect: "none",
    cursor: "pointer",
    position: "relative",
  },

  [`& .${classes.branch}`]: {
    position: "absolute",
    right: "-6%",
    top: "-1.5%",
    opacity: 0.4,
    transform: "rotate(-16.71deg)",
    userSelect: "none",
  },

  [`& .${classes.leaf}`]: {
    position: "absolute",
    width: 58,
    height: 100,
    left: 0,
    bottom: 0,
    userSelect: "none",
  },

  [`& .${classes.footer}`]: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: 0,
  },
}));

const AuthContainerMob = ({ children, signinLogo }) => {
  const history = useHistory();

  const goToHome = () => history.push("/home");
  return (
    <Root>
      <div className={classes.root}>
        <Container className={classes.container}>
          <div className={classes.branch}>
            <img src={branch} alt="branch" width="83px" height="120px" />
          </div>
          <div className={classes.logoContainer} onClick={goToHome}>
            <img src={ozsafirLogo} alt="ozsafirLogo" width="176" height="25" />
          </div>
          {signinLogo && <Header mobile />}
          {children}
          <div className={classes.leaf}>
            <img src={leaf} width="58px" height="100px" alt="leafBig" />
          </div>
        </Container>
      </div>
    </Root>
  );
};

AuthContainerMob.propTypes = {
  children: PropTypes.any,
  signinLogo: PropTypes.bool,
};

export default AuthContainerMob;
