import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const innerWidth = window.innerWidth;

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "noPadding",
})(({ theme, noPadding }) => ({
  width: noPadding ? "100%" : innerWidth,
  height: "auto",
  minHeight: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: !noPadding && theme.spacing(),
  paddingRight: !noPadding && theme.spacing(),
  position: "absolute",
  bottom: 0,
  zIndex: 2,
  backgroundColor: "white",
}));

const PriceContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "noPadding",
})(({ noPadding }) => ({
  width: noPadding ? 92 : 100,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const PriceText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "price" && prop !== "noPadding",
})(({ theme, price, noPadding }) => ({
  fontSize: price ? 16 : 10,
  fontWeight: 400,
  lineHeight: price ? "24px" : "16px",
  color: theme.palette.darkGray.main,
  paddingLeft: !noPadding && theme.spacing(),
}));

const SubmitButton = styled(Button)(({theme}) => ({
  width: 110,
  height: 26,
  backgroundColor: theme.palette.darkGray.main,
  textTransform: "none",
  color: "#fff",
  borderRadius: 6,
  fontSize: 10,
  fontWeight: "bold",
  "&:hover, &:focus, &:active": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const PriceAndSubmit = ({
  data,
  noPadding,
  handleSubmit,
  disabled,
  mobile,
}) => {
  return (
    <Root noPadding={noPadding} style={{ paddingRight: mobile && 10 }}>
      <PriceContainer noPadding={noPadding}>
        <PriceText noPadding={noPadding}>Tutar: </PriceText>
        <PriceText noPadding={noPadding} price>
          {data?.price?.view_price || "---"}₺
        </PriceText>
      </PriceContainer>
      <SubmitButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}
        disabled={disabled}>
        Sepete Ekle
      </SubmitButton>
    </Root>
  );
};

PriceAndSubmit.propTypes = {
  data: PropTypes.object,
  noPadding: PropTypes.bool,
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default PriceAndSubmit;
