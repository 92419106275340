import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

const StyledTypography = styled(Typography)(() => ({
  width: "100%",
  height: 20,
  fontSize: 14,
  fontWeight: 600,
  color: "#BDBDBD",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: 16,
}));

const Header = ({ children }) => {
  return <StyledTypography>{children}</StyledTypography>;
};

Header.propTypes = {
  children: PropTypes.string,
};

export default Header;
