import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

import DateSelection from "./DrawerContent/DateSelection";
import OrderStatus from "./DrawerContent/OrderStatus";
import Sources from "./DrawerContent/Sources";
import Users from "./DrawerContent/Users";
import CompanyAddresses from "./DrawerContent/CompanyAddresses";
import ButtonsArea from "./DrawerContent/ButtonsArea";

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
  position: "absolute",
  top: 0,
  paddingRight: theme.spacing(2),
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  lineHeight: "42px",
  color: theme.palette.primary.main,
  fontWeight: 400,
}));

const SubContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  height: "100vh",
}));

const DrawerContent = ({
  toggleDrawer,
  date,
  setDate,
  statusArr,
  handleStatus,
  sources,
  handleSource,
  userArr,
  users,
  handleUsers,
  userAddresses,
  addressArr,
  handleAddresses,
  clearFilters,
  handleFiltersSubmit,
}) => {
  const renderDateSelection = useMemo(
    () => <DateSelection date={date} setDate={setDate} />,
    [date, setDate]
  );
  const renderOrderStatus = useMemo(
    () => <OrderStatus statusArr={statusArr} handleStatus={handleStatus} />,
    [statusArr, handleStatus]
  );
  const renderSources = useMemo(
    () => <Sources sources={sources} handleSource={handleSource} />,
    [sources, handleSource]
  );
  const renderUsers = useMemo(
    () => <Users users_={users} userArr={userArr} handleUsers={handleUsers} />,
    [users, userArr, handleUsers]
  );
  const renderCompanyAddresses = useMemo(
    () => (
      <CompanyAddresses
        userAddresses={userAddresses}
        addressArr={addressArr}
        handleAddresses={handleAddresses}
      />
    ),
    [userAddresses, addressArr, handleAddresses]
  );

  return (
    <Box
      sx={{
        width: 400,
        padding: (theme) => theme.spacing(1.5),
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        paddingTop: "60px",
      }}
      role="presentation">
      <HeaderContainer>
        <Header>Filtre</Header>
        <IconButton onClick={toggleDrawer}>
          <CloseRoundedIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </HeaderContainer>
      <SubContainer>
        {renderDateSelection}
        <Divider />
        {renderOrderStatus}
        <Divider />
        {renderSources}
        <Divider />
        {renderUsers}
        <Divider />
        {renderCompanyAddresses}
        <Divider />
        <ButtonsArea
          clearFilters={clearFilters}
          handleFiltersSubmit={handleFiltersSubmit}
        />
      </SubContainer>
    </Box>
  );
};

DrawerContent.propTypes = {
  toggleDrawer: PropTypes.func,
  date: PropTypes.object,
  setDate: PropTypes.func,
  statusArr: PropTypes.array,
  handleStatus: PropTypes.func,
  sources: PropTypes.array,
  handleSource: PropTypes.func,
  userArr: PropTypes.array,
  users: PropTypes.array,
  handleUsers: PropTypes.func,
  userAddresses: PropTypes.array,
  addressArr: PropTypes.array,
  handleAddresses: PropTypes.func,
  clearFilters: PropTypes.func,
  handleFiltersSubmit: PropTypes.func,
};

export default DrawerContent;
