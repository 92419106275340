import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { isMobileOnly } from "react-device-detect";

import Button from "@mui/material/Button";

import AuthContainer from "./AuthContainer";
import CustomTF from "../../Ui/Verification/CustomTF";
import HeaderRoute from "./AuthCommons/HeaderRoute";
import Timer from "../../Ui/Verification/Timer";
import ResendNotice from "../../Ui/Verification/ResendNotice";
import { SubmitButton } from "./AuthCommons/Tools";
import { authService } from "../../Services";
import { authActions } from "../../Redux/Actions";
import { generalConstants } from "../../Utils/Constants";

import PhoneVerificationMob from "./PhoneVerificationMob";

const Root = styled("div")(() => ({
  width: 440,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const InputContainer = styled("div")(() => ({
  marginTop: 27.08,
  width: 300.03,
  height: 42,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Resend = styled(Button)(({ theme }) => ({
  marginTop: 36.79,
  textTransform: "capitalize",
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.primary.main,
}));

const WrongCode = styled("div")(({ theme }) => ({
  marginTop: 30,
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

const Phoneverification = () => {
  let history = useHistory();
  const regexp = /^[0-9\b]+$/;
  const dispatch = useDispatch();

  const setUserInfo = (data) => dispatch(authActions.setSigninInfo(data));

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();
  const fifthInput = useRef();
  const sixthInput = useRef();

  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [sixthDigit, setSixthDigit] = useState("");

  const [secondsRemaining, setSecondsRemaining] = useState(120);
  const [wrongCode, setWrongCode] = useState(false);

  let tokenStr = firstDigit.concat(
    secondDigit,
    thirdDigit,
    fourthDigit,
    fifthDigit,
    sixthDigit
  );

  useEffect(() => {
    firstInput?.current?.focus();
  }, []);

  const handleFirstDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFirstDigit(d);
    } else if (regexp.test(d)) {
      setFirstDigit(d);
      secondInput.current.focus();
    }
  };

  const handleSecondDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSecondDigit(d);
      firstInput.current.focus();
    } else if (regexp.test(d)) {
      setSecondDigit(d);
      thirdInput.current.focus();
    }
  };

  const handleThirdDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setThirdDigit(d);
      secondInput.current.focus();
    } else if (regexp.test(d)) {
      setThirdDigit(d);
      fourthInput.current.focus();
    }
  };

  const handleFourthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFourthDigit(d);
      thirdInput.current.focus();
    } else if (regexp.test(d)) {
      setFourthDigit(d);
      fifthInput.current.focus();
    }
  };

  const handleFifthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFifthDigit(d);
      fourthInput.current.focus();
    } else if (regexp.test(d)) {
      setFifthDigit(d);
      sixthInput.current.focus();
    }
  };

  const handleSixthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSixthDigit(d);
      fifthInput.current.focus();
    } else if (regexp.test(d)) {
      setSixthDigit(d);
    }
  };

  const resetFields = () => {
    setFirstDigit("");
    setSecondDigit("");
    setThirdDigit("");
    setFourthDigit("");
    setFifthDigit("");
    setSixthDigit("");
  };

  const handleVerificationCode = () => {
    sendVerificationCode(handleZeroPad(tokenStr));
  };

  const handleZeroPad = (num) => {
    const zeroPad = String(num).padStart(4, "0");
    return zeroPad;
  };

  const sendVerificationCode = (token) => {
    resetFields();
    let query = {
      gsm: history.location.state,
      code: token,
    };
    authService
      .validateAndSignin(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setUserInfo(res.data);
        } else {
          setWrongCode(true);
          setSecondsRemaining(0);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleResend = () => {
    setWrongCode(false);
    resetFields();
    let param = history.location.state;
    let query = {
      phone: param,
    };
    authService
      .signinPhone(query)
      .then((res) => setSecondsRemaining(120))
      .catch((err) => console.log(err));
  };

  const handleRoute = () => {
    history.push("/signin-phone");
  };

  if (!history.location.state) {
    return (
      <AuthContainer>
        <div>Yönlendiriliyor...</div>
        {handleRoute()}
      </AuthContainer>
    );
  } else {
    if (isMobileOnly) {
      return <PhoneVerificationMob />;
    } else {
      return (
        <AuthContainer>
          <Root>
            <HeaderRoute handleRoute={handleRoute}>Doğrulama Kodu</HeaderRoute>
            <InputContainer>
              <CustomTF
                value={firstDigit}
                inputRef={firstInput}
                onChange={handleFirstDigitChange}
              />
              <CustomTF
                value={secondDigit}
                inputRef={secondInput}
                onChange={handleSecondDigitChange}
              />
              <CustomTF
                value={thirdDigit}
                inputRef={thirdInput}
                onChange={handleThirdDigitChange}
              />
              <CustomTF
                value={fourthDigit}
                inputRef={fourthInput}
                onChange={handleFourthDigitChange}
              />
              <CustomTF
                value={fifthDigit}
                inputRef={fifthInput}
                onChange={handleFifthDigitChange}
              />
              <CustomTF
                value={sixthDigit}
                inputRef={sixthInput}
                onChange={handleSixthDigitChange}
              />
            </InputContainer>
            {wrongCode && <WrongCode>Girdiğiniz kod hatalıdır!</WrongCode>}
            {secondsRemaining === 0 ? (
              <Resend onClick={handleResend}>Kod Gönder</Resend>
            ) : (
              <Timer
                secondsRemaining={secondsRemaining}
                setSecondsRemaining={setSecondsRemaining}
              />
            )}
            <ResendNotice />
            <SubmitButton
              disableRipple
              variant="contained"
              disabled={tokenStr.length !== 6}
              onClick={handleVerificationCode}>
              Tamam
            </SubmitButton>
          </Root>
        </AuthContainer>
      );
    }
  }
};

export default Phoneverification;
