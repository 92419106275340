import React from "react";

import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CssBaseline from "@mui/material/CssBaseline";

import TopNavbar from "../../Ui/TopNavbar/TopNavbar";
import StickyFooter from "../../Ui/Footer/StickyFooter";
import FooterMain from "../../Ui/Footer/FooterMain";
import ScrollTop from "../../Ui/ScrollToTop/ScrollTop";

import BottomNavbar from "../../Ui/BottomNavbar/BottomNavbar";
import MobileTopBar from "../../Ui/MobileTopBar/MobileTopBar";

import { useCurrentWidth } from "../../Utils/Hooks";

import { isMobileOnly } from "react-device-detect";

const PREFIX = "PageContainer";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  subContainer: `${PREFIX}-subContainer`,
  footer: `${PREFIX}-footer`,
  footerMobile: `${PREFIX}-footerMobile`,
};

const Root = styled("div", { shouldForwardProp: (prop) => prop !== "narrow" })(
  ({ narrow }) => ({
    [`&.${classes.root}`]: {
      width: "100%",
      position: "relative",
      padding: 0,
      backgroundColor: "#fff",
      overflow: "hidden",
    },

    [`& .${classes.container}`]: {
      padding: "0px 34px",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      overflow: "hidden",
    },

    [`& .${classes.subContainer}`]: {
      paddingTop: 80,
      width: "100%",
      "&[no-scroll='true']": {
        overflow: "hidden",
        maxHeight: "100vh",
      },
      "&[narrow='true']": {
        paddingTop: isMobileOnly ? 0 : 150,
      },
    },

    [`& .${classes.footer}`]: {
      position: "relative",
      bottom: 0,
      width: "100%",
      padding: 0,
      height: "auto",
    },

    [`& .${classes.footerMobile}`]: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      overflow: "hidden",
      zIndex: 2,
      display: "flex",
      justifyContent: "center",
    },
  })
);

const PageContainer = (props) => {
  const width = useCurrentWidth();

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <div id="back-to-top-anchor"></div>
      <Container maxWidth="lg" className={classes.container}>
        {width > 550 ? (
          <TopNavbar searchTextRef={props.searchTextRef} />
        ) : (
          <MobileTopBar web />
        )}

        <div
          className={classes.subContainer}
          no-scroll={props.noScroll ? "true" : "false"}
          narrow={width <= 1050 ? "true" : "false"}>
          {props.children}
        </div>
      </Container>
      {!props.noFooter &&
        (width > 600 ? (
          <div className={classes.footer}>
            <FooterMain />
            <StickyFooter />
          </div>
        ) : (
          <div className={classes.footerMobile} style={{ width: width }}>
            <BottomNavbar web />
          </div>
        ))}
      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          style={{ backgroundColor: "#6E3564" }}>
          <KeyboardArrowUpIcon style={{ color: "white" }} />
        </Fab>
      </ScrollTop>
    </Root>
  );
};

export default PageContainer;
