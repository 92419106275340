import React from "react";

import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const OrderDetails = () => {
  return <Root>OrderDetails</Root>;
};

export default OrderDetails;
