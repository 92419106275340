import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeaderClose from "../../../Ui/Dialog/Tools/MobileDialogHeaderClose";
import PasswordArea from "../../../Ui/Fields/PasswordArea";

import { generalConstants } from "../../../Utils/Constants";
import Loading from "../../../Utils/Loading";

import { authService } from "../../../Services";
import { authActions } from "../../../Redux/Actions";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const UpdatePasswordMob = ({ handleClose, open }) => {
  const dispatch = useDispatch();

  const signOut = () => dispatch(authActions.signout());

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        setValues({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
          showOldPassword: false,
          showNewPassword: false,
          showConfirmPassword: false,
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCheck = () => {
    if (
      values.oldPassword === "" ||
      values.newPassword === "" ||
      values.confirmPassword === "" ||
      values.newPassword !== values.confirmPassword
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleClickShowPassword = (prop) => (e) => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      setLoading(true);
      let query = {
        old_password: values.oldPassword,
        new_password: values.newPassword,
      };
      const response = await authService.changePassword(query);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        setLoading(false);
        toast.success("Şifreniz başarılı bir şekilde değiştirilmiştir.");
        handleClose();
        setTimeout(() => signOut(), 1000);
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    }
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeaderClose text="Şifre Yenileme" callBack={handleClose} />
      <Loading open={loading} />
      <PasswordArea
        label="Güncel Şifreniz"
        handleChange={handleChange}
        values={values}
        prop="oldPassword"
        error={error}
        handleMouseDownPassword={handleMouseDownPassword}
        handleClickShowPassword={handleClickShowPassword}
        visibilityProp="showOldPassword"
      />
      <PasswordArea
        label="Yeni Şifreniz"
        handleChange={handleChange}
        values={values}
        prop="newPassword"
        error={error}
        handleMouseDownPassword={handleMouseDownPassword}
        handleClickShowPassword={handleClickShowPassword}
        visibilityProp="showNewPassword"
      />
      <PasswordArea
        label="Yeni Şifreniz Tekrar"
        handleChange={handleChange}
        values={values}
        prop="confirmPassword"
        error={error}
        handleMouseDownPassword={handleMouseDownPassword}
        handleClickShowPassword={handleClickShowPassword}
        visibilityProp="showConfirmPassword"
      />
      <MobileDialogButton disableRipple onClick={handleSubmit}>
        Tamam
      </MobileDialogButton>
    </StyledDialog>
  );
};

UpdatePasswordMob.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default UpdatePasswordMob;
