import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const PREFIX = "OrderNote";

const classes = {
  orderNote: `${PREFIX}-orderNote`,
  orderNoteHeader: `${PREFIX}-orderNoteHeader`,
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.orderNote}`]: {
    width: "100%",
    height: 110,
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: "3px 13px",
    marginBottom: 11,
    position: "relative",
  },

  [`& .${classes.orderNoteHeader}`]: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.darkGray.main,
  },

  [`& .${classes.textField}`]: {
    width: "100%",
    "& .MuiFilledInput-root": {
      backgroundColor: "#fff",
      border: "none",
      borderRadius: 8,
      padding: 0,
    },
    "& .MuiFilledInput-multiline": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      // padding: "3.5px 8px",
      height: "70px !important",
      padding: 0,
      fontSize: 14,
      fontWeight: 300,
      color: theme.palette.secondary.main,
      backgroundColor: "#fff",
      "&:not(:focus)": {
        cursor: "pointer",
      },
      "&:hover, &:focus, &:active": {
        backgroundColor: "#fff",
      },
      "&::placeholder": {
        fontSize: 14,
        fontStyle: "italic",
      },
    },
  },

  [`& .${classes.button}`]: {
    position: "absolute",
    bottom: 5,
    left: "50%",
    transform: "translate(-50%, 0%)",
    width: 260,
    height: 25,
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: 2,
    color: "#fff",
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const OrderNote = ({ handleChange, text }) => {
  return (
    <Root className={classes.orderNote}>
      <Typography className={classes.orderNoteHeader}>
        Sipariş Notu :
      </Typography>
      <TextField
        placeholder="Sipariş notunuzu bu alana yazabilirsiniz"
        multiline
        fullWidth
        minRows={5}
        value={text}
        onChange={handleChange}
        variant="filled"
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          maxLength: 50,
        }}
      />
    </Root>
  );
};

OrderNote.propTypes = {
  text: PropTypes.string,
  handleChange: PropTypes.func,
};

export default OrderNote;
