import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "SectionHeader";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTypography = styled(Typography)(({theme}) => ({
  [`&.${classes.root}`]: {
    marginTop: 10,
    width: "100%",
    textAlign: "left",
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.darkGray.main,
    "&[used-in='fast-order']": {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.secondary.main,
      paddingLeft: 3,
      marginTop: 7,
    },
  },
}));

const SectionHeader = ({ children, usedIn }) => {
  return (
    <StyledTypography className={classes.root} used-in={usedIn}>
      {children}
    </StyledTypography>
  );
};

SectionHeader.propTypes = {
  children: PropTypes.string,
  usedIn: PropTypes.string,
};

export default SectionHeader;
