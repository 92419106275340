const handleGetFeatureValues = (data) => {
  let shapes = [];
  let sides = [];
  let webTypes = [];
  let webColors = [];

  let shapesData = data.filter((item) => item.name === "shape");
  shapesData[0].values.map((item) => shapes.push(item.label));

  let sidesData = data.filter((item) => item.name === "side");
  sidesData[0].values.map((item) => sides.push(item.label));

  let webData = sidesData[0].values.filter((item) => item.label === "Saçak");

  let webTypeData = webData[0].values.filter((item) => item.name === "webType");
  webTypeData[0].values.map((item) => webTypes.push(item.label));

  let webColorData = webData[0].values.filter(
    (item) => item.name === "webColor"
  );
  webColorData[0].values.map((item) => webColors.push(item.label));

  let result = {
    shapes,
    sides,
    webTypes,
    webColors,
  };

  return result;
};

export { handleGetFeatureValues };
