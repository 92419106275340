import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import trLocale from "date-fns/locale/tr";

import { Transition } from "../../../../Utils/Tools";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { dateFormatter } from "../../../../Utils/Helpers";

import Header from "./Header";

const ApplyButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: 40,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#fff",
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 8,
}));

const Title = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const DateDialog = ({
  open,
  handleClose,
  date,
  setDate,
  goBackDateDialog,
  clearDates,
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = () => handleClose();

  useEffect(() => {
    let active = true;
    if (active) {
      let startDate = Date.parse(dateFormatter(date?.startDate));
      let endDate = Date.parse(dateFormatter(date?.endDate));
      startDate > endDate ? setDisabled(true) : setDisabled(false);
    }
    return () => {
      active = false;
    };
  }, [date]);

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <Header
        handleClose={goBackDateDialog}
        headerText="Tarih Aralığı"
        onDelete={clearDates}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: (theme) => theme.spacing(),
          width: "100%",
          height: "100%",
        }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Title>Başlangıç Tarihi</Title>
            <MobileDatePicker
              value={date.startDate}
              mask="__.__.____"
              onChange={(newValue) => {
                setDate({ ...date, startDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
              InputProps={{
                style: { borderRadius: 6, height: 40, marginBottom: 24 },
              }}
            />
            <Title>Bitiş Tarihi</Title>
            <MobileDatePicker
              value={date.endDate}
              mask="__.__.____"
              onChange={(newValue) => {
                setDate({ ...date, endDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
              minDate={date.startDate}
              InputProps={{
                style: { borderRadius: 6, height: 40 },
              }}
            />
          </Box>
        </LocalizationProvider>
        <div style={{ flex: 1 }} />
        <ApplyButton
          onClick={handleSubmit}
          variant="contained"
          disableRipple
          disabled={disabled}>
          Uygula
        </ApplyButton>
      </Box>
    </Dialog>
  );
};

DateDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  items: PropTypes.any,
  value: PropTypes.string,
  filter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default DateDialog;
