import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import { useCurrentWidth } from "../../../Utils/Hooks";

import CartAddressBox from "../../../Ui/CartAddressBox/CartAddressBox";

const StyledGrid = styled(Grid)(({ theme }) => ({
  height: "auto",
  minHeight: 200,
  overflow: "scroll",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(8),
  paddingBottom: theme.spacing(),
}));

const CompanyAddresses = ({ data, defaultAddr, loading }) => {
  const currentWidth = useCurrentWidth();

  return (
    <StyledGrid container spacing={2}>
      {data?.map((item, index) => (
        <Grid
          item
          xs={
            currentWidth >= 600
              ? currentWidth >= 920
                ? currentWidth >= 1200
                  ? 3
                  : 4
                : 6
              : 12
          }
          key={index}>
          <CartAddressBox
            item={item}
            firm
            addressPage
            defaultAddr={defaultAddr}
            loading={loading}
          />
        </Grid>
      ))}
    </StyledGrid>
  );
};

CompanyAddresses.propTypes = {
  data: PropTypes.array,
  defaultAddr: PropTypes.object,
  loading: PropTypes.bool,
};

export default CompanyAddresses;
