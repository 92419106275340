import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const PREFIX = "InnerTabButtons";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  selected: `${PREFIX}-selected`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 37,
    display: "flex",
    borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
    marginTop: 10,
  },

  [`& .${classes.button}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: "#B1AEAE",
    width: 200,
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    paddingBottom: 10,
    paddingLeft: 15,
  },

  [`& .${classes.selected}`]: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    borderBottom: "1px solid #6E3564",
  },
}));

const InnerTabButtons = ({ index, setIndex }) => {
  return (
    <Root className={classes.root}>
      <div
        onClick={() => setIndex(1)}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === 1 && true,
        })}>
        Yetki Detay
      </div>
      <div
        onClick={() => setIndex(2)}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === 2 && true,
        })}>
        Yetkiye ait kullanıcılar
      </div>
    </Root>
  );
};

InnerTabButtons.propTypes = {
  index: PropTypes.number,
  setIndex: PropTypes.func,
};

export default React.memo(InnerTabButtons);
