import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";

const QuantityContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 150,
  height: 24,
  backgroundColor: "#F5F3FF",
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(),
  paddingRight: theme.spacing(),
  paddingLeft: theme.spacing(),
}));

const QuantitySubContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ButtonAndQuantity = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  "&[not-right-margin='true']": {
    marginRight: 0,
  },
  "&[not-left-margin='true']": {
    marginLeft: 0,
  },
}));

const QuantityText = styled(Typography)(({theme}) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.darkGray.main,
}));

const QuantityLetters = styled(Typography)(({theme}) => ({
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.secondary.main,
  marginTop: 2,
  marginBottom: 0,
}));

const QuantitySelection = ({ product, decreaseQuantity, increaseQuantity }) => {
  return (
    <QuantityContainer>
      <QuantitySubContainer>
        <ButtonAndQuantity>
          <StyledIconButton
            not-left-margin="true"
            disabled={product.quantity === 1}
            onClick={decreaseQuantity}
            size="large">
            <RemoveIcon />
          </StyledIconButton>
          <QuantityText>{product?.quantity}</QuantityText>
          <StyledIconButton
            not-right-margin="true"
            disabled={product?.quantity === +product?.stock.quantity}
            onClick={increaseQuantity}
            size="large">
            <AddIcon />
          </StyledIconButton>
        </ButtonAndQuantity>
        <QuantityLetters>Adet</QuantityLetters>
      </QuantitySubContainer>
    </QuantityContainer>
  );
};

QuantitySelection.propTypes = {
  product: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  increaseQuantity: PropTypes.func,
};

export default QuantitySelection;
