import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import MobileProductCard from "../ProductCard/MobileProductCard";
import SectionHeaderMob from "../../Views/Common/Sections/SectionHeaderMob";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  padding: 0,
  width: innerWidth,
  height: 330,
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(),
}));

const ItemContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "scroll",
  width: "100%",
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const MobileSectionCarousel = ({
  text,
  handleClick,
  value,
  data,
  noButton,
}) => {
  return (
    <StyledContainer>
      <SectionHeaderMob
        header={text}
        handleClick={handleClick}
        value={value}
        noButton={noButton}
      />
      <ItemContainer>
        {data?.map((item, index) => (
          <MobileProductCard item={item} key={index} />
        ))}
      </ItemContainer>
    </StyledContainer>
  );
};

MobileSectionCarousel.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  value: PropTypes.number,
  data: PropTypes.array,
  noButton: PropTypes.bool,
};

export default MobileSectionCarousel;
