import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import TextArea from "../../../../Ui/Fields/TextArea";

import { MAX_FILE_SIZE } from "../../../../Utils/Constants";

import ImageBox from "./Common/ImageBox";
import InputButton from "./Common/InputButton";

const PREFIX = "AddService";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  inputContainer: `${PREFIX}-inputContainer`,
  submitButton: `${PREFIX}-submitButton`,
  inputArea: `${PREFIX}-inputArea`,
  inputAreaText: `${PREFIX}-inputAreaText`,
  visualsAndUploadButton: `${PREFIX}-visualsAndUploadButton`,
  visuals: `${PREFIX}-visuals`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    // minHeight: 555,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: 10,
    userSelect: "none",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 20,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`& .${classes.inputArea}`]: {
    width: "100%",
    height: 141,
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
  },

  [`& .${classes.inputAreaText}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[700],
    marginBottom: 5,
  },

  [`& .${classes.visualsAndUploadButton}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
  },

  [`& .${classes.visuals}`]: {
    width: 220,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

const AddService = ({ open, onClose }) => {
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    reason: "",
    name: "",
    explanation: "",
  });
  const [files, setFiles] = useState({
    file1: null,
    file2: null,
    file3: null,
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        setValues({
          reason: "",
          name: "",
          explanation: "",
        });
        setFiles({
          file1: null,
          file2: null,
          file3: null,
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCheck = () => {
    if (
      values.reason.length === 0 ||
      values.name.length === 0 ||
      values.explanation.length === 0
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const selectFile = (e) => {
    let selectedFile = e.target.files[0];
    if (!selectedFile) return;
    if (selectedFile.size > MAX_FILE_SIZE) {
      return toast.error("En fazla 5 mb boyutunda yükleme yapabilirsiniz");
    } else if (selectedFile) {
      if (files.file1 && files.file2 && files.file3) {
        return toast.error("Maximum dosya yükleme sayısına ulaştınız");
      } else if (files.file1) {
        if (files.file2) {
          setFiles({
            ...files,
            file3: selectedFile,
          });
        } else {
          setFiles({
            ...files,
            file2: selectedFile,
          });
        }
      } else {
        setFiles({
          ...files,
          file1: selectedFile,
        });
      }
    } else {
      console.log("Please select pdf file");
    }
  };

  const handleSubmit = () => {
    let isError = handleCheck();
    console.log(
      "🚀 ~ file: AddUser.js ~ line 153 ~ handleSubmit ~ isError",
      isError
    );
  };

  const removeFile = (prop) => (e) => {
    setFiles({
      ...files,
      [prop]: null,
    });
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Yeni Servis Kaydı</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.inputContainer}>
          <TextArea
            label="Servis Nedeni"
            handleChange={handleChange}
            values={values}
            prop="reason"
            error={error}
          />
          <TextArea
            label="Ürün ve hizmet adı"
            handleChange={handleChange}
            values={values}
            prop="name"
            error={error}
          />
          <TextArea
            label="Açıklama"
            handleChange={handleChange}
            values={values}
            prop="explanation"
            error={error}
            multiline
            minRows={4}
          />
          <div className={classes.inputArea}>
            <Typography className={classes.inputAreaText}>Görseller</Typography>
            <div className={classes.visualsAndUploadButton}>
              <div className={classes.visuals}>
                {files.file1 && (
                  <ImageBox
                    removeFile={removeFile}
                    file={files.file1}
                    prop="file1"
                  />
                )}
                {files.file2 && (
                  <ImageBox
                    removeFile={removeFile}
                    file={files.file2}
                    prop="file2"
                  />
                )}
                {files.file3 && (
                  <ImageBox
                    removeFile={removeFile}
                    file={files.file3}
                    prop="file3"
                  />
                )}
              </div>
              <InputButton selectFile={selectFile} />
            </div>
          </div>
          <Button
            variant="contained"
            disableRipple
            className={classes.submitButton}
            onClick={handleSubmit}>
            Gönder
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

AddService.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddService;
