import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";

import PageContainerMob from "../Common/PageContainerMob";
import FilterSearchTopbar from "../../Ui/MobileTopBar/FilterSearchTopBar";
import MobileOrderItem from "./Common/MobileOrderItem";
import MobileOrderFilters from "./MobileDialogs/MobileOrderFilters";
import { generalConstants } from "../../Utils/Constants";
import { orderQueryMaker } from "../../Utils/Helpers";
import Loading from "../../Utils/Loading";

import { bottomNavItems } from "../../Utils/Constants";

import { bottomNavbarActions } from "../../Redux/Actions";
import { orderService, userService, addressService } from "../../Services";

const innerHeight = window.innerHeight;

const MobileOrderPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  const customer = useSelector(({ customer }) => customer);
  const addresses = useSelector(({ addresses }) => addresses.addresses);

  const [keyword, setKeyword] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [users, setUsers] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: {
      status: 1,
      order_key: "",
      order_status: [],
      source: [],
      user_id: [],
      shipping_address: [],
      created_at: [],
    },
  });

  // useEffect(() => {
  //   let active = true;
  //   if (active) {
  //     getOrders(query, false);
  //   }
  //   return () => {
  //     active = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      setHasMore(true);
      getOrders(query, true);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query.filter)]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer?.selectedUser?.company) {
        let query_ = {
          pageNumber: 1,
          pageSize: 1000,
          sortField: "id",
          sortOrder: "asc",
          filter: {
            company_id: [],
          },
        };
        let id = customer?.selectedUser?.company?.id;
        let isAdmin = customer.isAdmin;
        getUsers(query_, id, isAdmin);
        if (customer?.isAdmin) {
          let id = customer?.currentCompany?.id;
          let adminQuery = { ...query_, filter: { company_id: id } };
          getCompanyAddresses(adminQuery);
        } else {
          addresses.length > 0
            ? setUserAddresses(addresses)
            : setUserAddresses(null);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, addresses]);

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu(bottomNavItems.ORDERS);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanyAddresses = async (query) => {
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUserAddresses(response.data.addresses);
    } else {
      setUserAddresses(null);
    }
  };

  const handleChange = (e) => setKeyword(e.target.value);

  const onCloseClick = () => setKeyword("");

  const onBackClick = () => history.goBack();

  const toggleFiltersDialog = () => setOpenFilters((prev) => !prev);

  const getUsers = async (query, id, isAdmin) => {
    const response = await userService.getAllUsers_(query, id, isAdmin);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUsers(response.data.users);
    } else {
      setUsers([]);
    }
  };

  const getOrders = async (query, bool) => {
    if (hasMore || bool) {
      let isAdm = customer?.isAdmin;
      let id = customer?.currentCompany?.id;
      let isCompanyAdmin = customer?.selectedUser?.role_names[0] === 'companyadmin'
      const { getAllOrders, getAdminOrders } = orderService;
      setOpen(true);
      let query_ = orderQueryMaker(query, bool, id, isAdm, isCompanyAdmin);
      const res = isAdm || isCompanyAdmin
        ? await getAdminOrders(query_)
        : await getAllOrders(query_);
      if (res?.data?.status === generalConstants.STATUS_TRUE) {
        let data =
          query.pageNumber === 1
            ? res.data.orders
            : orders.concat(res.data.orders);
        setOrders(data);
        setOpen(false);
        if (res.data.orders.length < 10) {
          setHasMore(false);
        } else {
          setQuery({
            ...query,
            pageNumber: query.pageNumber + 1,
          });
        }
      } else {
        console.log(res);
        setOpen(false);
      }
    } else {
      console.log("Not has more");
    }
  };

  return (
    <PageContainerMob noScroll>
      <Loading open={open} />
      <FilterSearchTopbar
        handleChange={handleChange}
        onCloseClick={onCloseClick}
        keyword={keyword}
        onBackClick={onBackClick}
        toggleFiltersDialog={toggleFiltersDialog}
      />
      <div
        id="scrollableDiv"
        style={{
          width: "100%",
          height: innerHeight - 120,
          paddingTop: 8,
          paddingBottom: 8,
          overflowY: "scroll",
        }}>
        <InfiniteScroll
          style={{
            padding: "0px 8px",
            overflowX: "hidden",
          }}
          scrollableTarget="scrollableDiv"
          dataLength={orders.length}
          next={() => getOrders(query)}
          hasMore={hasMore}
          loader={<></>}>
          {orders.map((order, index) => (
            <MobileOrderItem key={index} order={order} />
          ))}
        </InfiniteScroll>
      </div>
      <MobileOrderFilters
        open={openFilters}
        handleClose={toggleFiltersDialog}
        setQuery={setQuery}
        query={query}
        users={users}
        userAddresses={userAddresses}
      />
    </PageContainerMob>
  );
};

export default MobileOrderPage;
