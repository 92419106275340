import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import grayCheckbox from "../../../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../../../Images/Checked.svg";

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "searchable",
})(({ searchable, theme }) => ({
  width: "100%",
  paddingBottom: theme.spacing(),
  overflowY: "scroll",
  marginTop: theme.spacing(),
  "&[section-name='Users']": {
    maxHeight: searchable ? 125 : 155,
  },
  "&[section-name='Sevk Adresi']": {
    maxHeight: searchable ? 125 : 155,
  },
}));

const Section = ({ data, callBack, values, searchable, component }) => {
  const getLabel = (text, item) => {
    if (text === "Users") {
      return item.full_name;
    } else if (text === "Sevk Adresi") {
      return item.name;
    } else {
      return item.status;
    }
  };

  return (
    <StyledFormControl
      component="fieldset"
      searchable={searchable}
      section-name={component}>
      <FormGroup>
        {data?.map((item, index) => (
          <FormControlLabel
            style={{ marginBottom: 4 }}
            key={index}
            control={
              <Checkbox
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  margin: "0px 0px 0px 8px",
                  "&:hover, &.Mui-focusVisible": {
                    backgroundColor: "transparent !important",
                  },
                }}
                disableRipple
                checked={values?.some((id) => id === item.id)}
                onChange={() => callBack(item.id)}
                id={item.id.toString()}
                name={item.name}
                value={item.id}
                icon={<img src={grayCheckbox} width="20px" alt="" />}
                checkedIcon={<img src={Checked} width="20" alt="" />}
              />
            }
            label={getLabel(component, item)}
          />
        ))}
      </FormGroup>
    </StyledFormControl>
  );
};

Section.propTypes = {
  data: PropTypes.array,
  callBack: PropTypes.func,
  values: PropTypes.array,
  searchable: PropTypes.bool,
  component: PropTypes.string,
};

export default Section;
