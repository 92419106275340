import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

import AuthContainer from "./AuthContainer";
import HeaderRoute from "./AuthCommons/HeaderRoute";
import Label from "./AuthCommons/Label";
import Info from "./ForgotPassword/Info";
import { SubmitButton, CustomTextField } from "./AuthCommons/Tools";

import { generalConstants } from "../../Utils/Constants";
import { authService } from "../../Services";


const Root = styled("div")(() => ({
  width: 440,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const ForgotPassword = () => {
  let history = useHistory();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [notValid, setNotValid] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
    emailRegex.test(email) ? setNotValid(false) : setNotValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleChange = (event) => {
    let data = event.target.value;
    setEmail(data);
  };

  const handleSubmit = async () => {
    let query = { email };
    const response = await authService.userRenewPassword(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      toast.success("Şifre yenileme linki gönderildi");
      history.push("password-sent", email);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const handleRoute = () => {
    history.goBack();
  };

  return (
    <AuthContainer component="forgot-password">
      <Root>
        <HeaderRoute handleRoute={handleRoute} component="forgot-password">
          Şifremi Unuttum
        </HeaderRoute>
        <Label>E-posta Adresi</Label>
        <CustomTextField
          value={email}
          onChange={handleChange}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Info />
        <SubmitButton
          disableRipple
          variant="contained"
          disabled={notValid}
          onClick={handleSubmit}>
          Gönder
        </SubmitButton>
      </Root>
    </AuthContainer>
  );
};

export default ForgotPassword;
