import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

import AuthContainerMob from "./AuthContainerMob";
import HeaderRoute from "./AuthCommons/HeaderRoute";
import Label from "./AuthCommons/Label";
import Info from "./ForgotPassword/Info";
import { SubmitButton, CustomTextField } from "./AuthCommons/Tools";
import { generalConstants } from "../../Utils/Constants";
import { authService } from "../../Services";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledCard = styled("div")(() => ({
  marginTop: 30,
  width: "95%",
  background: "rgba(133, 89, 122, 0.1)",
  borderRadius: 8,
  height: 320,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ForgotPasswordMob = () => {
  let history = useHistory();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [notValid, setNotValid] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
    emailRegex.test(email) ? setNotValid(false) : setNotValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleChange = (event) => {
    let data = event.target.value;
    setEmail(data);
  };

  const handleSubmit = async () => {
    let query = { email };
    const response = await authService.userRenewPassword(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      toast.success("Şifre yenileme linki gönderildi");
      history.push("password-sent", email);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const handleRoute = () => {
    history.goBack();
  };

  return (
    <AuthContainerMob>
      <Root>
        <StyledCard>
          <HeaderRoute handleRoute={handleRoute} component="forgot-password">
            Şifremi Unuttum
          </HeaderRoute>
          <Label mobile>E-posta Adresi</Label>
          <CustomTextField
            value={email}
            onChange={handleChange}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            style={{ marginBottom: 8.21, width: "82%" }}
          />
          <Info />
          <SubmitButton
            disableRipple
            variant="contained"
            disabled={notValid}
            style={{ width: "82%", marginBottom: 20 }}
            onClick={handleSubmit}>
            Gönder
          </SubmitButton>
        </StyledCard>
      </Root>
    </AuthContainerMob>
  );
};

export default ForgotPasswordMob;
