import React from "react";

import { styled } from '@mui/material/styles';

import UserHeader from "./RowCollapseContent/UserHeader";
import UserRow from "./RowCollapseContent/UserRow";

const PREFIX = 'TableRowCollapseUsers';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }
}));

let data = [
  {
    full_name: "Dinçer Kazmacı",
    first_name: "Dinçer",
    last_name: "Kazmacı",
    create_date: "10.10.2021 13:00",
    gsm: "0535 000 1212",
    email: "info@asperahali.com",
    role: "Yönetici",
    firm: "Aspera Halı",
    branch: "İvedik Şube",
    state: "Aktif",
  },
  {
    full_name: "Yaşar Özyurt",
    first_name: "Yaşar",
    last_name: "Özyurt",
    create_date: "10.10.2021 13:00",
    gsm: "0535 000 1212",
    email: "info@asperahali.com",
    role: "Yönetici",
    firm: "Aspera Halı",
    branch: "İvedik Şube",
    state: "Aktif",
  },
  {
    full_name: "Emrah Yaşartürk",
    first_name: "Emrah",
    last_name: "Yaşartürk",
    create_date: "10.10.2021 13:00",
    gsm: "0535 000 1212",
    email: "info@asperahali.com",
    role: "Yönetici",
    firm: "Aspera Halı",
    branch: "İvedik Şube",
    state: "Aktif",
  },
];

const TableRowCollapseUsers = () => {

  return (
    <Root className={classes.root}>
      <UserHeader />
      {data.map((item, index) => (
        <UserRow user={item} key={index} isLast={index === data.length - 1} />
      ))}
    </Root>
  );
};

export default TableRowCollapseUsers;
