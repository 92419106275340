import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

import Collapse from "@mui/material/Collapse";

import AllChecked from "./RowCollapseContent/AllChecked";
import SubSectionMain1 from "./RowCollapseContent/SubSectionMain1";
import SubSectionMain2 from "./RowCollapseContent/SubSectionMain2";
import SubSectionOptions1 from "./RowCollapseContent/SubSectionOptions1";
import SubSectionOptions2 from "./RowCollapseContent/SubSectionOptions2";

import { checkBoxSections, indeterminate } from "../../../../Utils/Constants";

const PREFIX = "TableRowCollapse";

const classes = {
  main: `${PREFIX}-main`,
};

const Root = styled("div")(() => ({
  [`&.${classes.main}`]: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
}));

const TableRowCollapse = () => {
  const [extended1, setExtended1] = useState(false);
  const [extended2, setExtended2] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [sub1Checked, setSub1Checked] = useState(false);
  const [sub1Values, setSub1Values] = useState({
    Sub1_label1: false,
    Sub1_label2: false,
    Sub1_label3: false,
    Sub1_label4: false,
    Sub1_label5: false,
    Sub1_label6: false,
    Sub1_label7: false,
    Sub1_label8: false,
  });
  const [sub2Checked, setSub2Checked] = useState(false);
  const [sub2Values, setSub2Values] = useState({
    Sub2_label1: false,
    Sub2_label2: false,
    Sub2_label3: false,
    Sub2_label4: false,
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (allChecked === true) {
        setSub1Checked(true);
        setSub2Checked(true);
      } else if (allChecked === false) {
        setSub1Checked(false);
        setSub2Checked(false);
      } else {
        return;
      }
    }
    return () => {
      active = false;
    };
  }, [allChecked]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (true) {
        if (sub1Checked === true) {
          setSub1Values({
            Sub1_label1: true,
            Sub1_label2: true,
            Sub1_label3: true,
            Sub1_label4: true,
            Sub1_label5: true,
            Sub1_label6: true,
            Sub1_label7: true,
            Sub1_label8: true,
          });
        } else if (sub1Checked === false) {
          setSub1Values({
            Sub1_label1: false,
            Sub1_label2: false,
            Sub1_label3: false,
            Sub1_label4: false,
            Sub1_label5: false,
            Sub1_label6: false,
            Sub1_label7: false,
            Sub1_label8: false,
          });
        }
      }
      if (true) {
        if (sub2Checked === true) {
          setSub2Values({
            Sub2_label1: true,
            Sub2_label2: true,
            Sub2_label3: true,
            Sub2_label4: true,
          });
        } else if (sub2Checked === false) {
          setSub2Values({
            Sub2_label1: false,
            Sub2_label2: false,
            Sub2_label3: false,
            Sub2_label4: false,
          });
        }
      }

      if (true) {
        if (sub2Checked === true && sub1Checked === true) {
          setAllChecked(true);
        } else if (sub2Checked === false && sub1Checked === false) {
          setAllChecked(false);
        } else {
          setAllChecked(indeterminate);
        }
      }
    }
    return () => {
      active = false;
    };
  }, [sub1Checked, sub2Checked]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (
        sub1Values.Sub1_label1 &&
        sub1Values.Sub1_label2 &&
        sub1Values.Sub1_label3 &&
        sub1Values.Sub1_label4 &&
        sub1Values.Sub1_label5 &&
        sub1Values.Sub1_label6 &&
        sub1Values.Sub1_label7 &&
        sub1Values.Sub1_label8
      ) {
        setSub1Checked(true);
      } else if (
        !sub1Values.Sub1_label1 &&
        !sub1Values.Sub1_label2 &&
        !sub1Values.Sub1_label3 &&
        !sub1Values.Sub1_label4 &&
        !sub1Values.Sub1_label5 &&
        !sub1Values.Sub1_label6 &&
        !sub1Values.Sub1_label7 &&
        !sub1Values.Sub1_label8
      ) {
        setSub1Checked(false);
      } else {
        setSub1Checked(indeterminate);
      }
    }
    return () => {
      active = false;
    };
  }, [sub1Values]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (
        sub2Values.Sub2_label1 &&
        sub2Values.Sub2_label2 &&
        sub2Values.Sub2_label3 &&
        sub2Values.Sub2_label4
      ) {
        setSub2Checked(true);
      } else if (
        !sub2Values.Sub2_label1 &&
        !sub2Values.Sub2_label2 &&
        !sub2Values.Sub2_label3 &&
        !sub2Values.Sub2_label4
      ) {
        setSub2Checked(false);
      } else {
        setSub2Checked(indeterminate);
      }
    }
    return () => {
      active = false;
    };
  }, [sub2Values]);

  const handleSection1 = (prop) => (event) => {
    setSub1Values({
      ...sub1Values,
      [prop]: !sub1Values[prop],
    });
  };

  const handleSection2 = (prop) => (event) => {
    setSub2Values({
      ...sub2Values,
      [prop]: !sub2Values[prop],
    });
  };

  const handleAllCheck = () => {
    if (allChecked === indeterminate) {
      setAllChecked(true);
    } else {
      setAllChecked(!allChecked);
    }
  };

  const handleSub1Check = () => {
    if (sub1Checked === indeterminate) {
      setSub1Checked(true);
    } else {
      setSub1Checked(!sub1Checked);
    }
  };

  const handleSub2Check = () => {
    if (sub2Checked === indeterminate) {
      setSub2Checked(true);
    } else {
      setSub2Checked(!sub2Checked);
    }
  };

  return (
    <Root className={classes.main}>
      <AllChecked
        allChecked={allChecked}
        handleAllCheck={handleAllCheck}
        checkBoxSections={checkBoxSections}
      />

      <SubSectionMain1
        sub1Checked={sub1Checked}
        handleSub1Check={handleSub1Check}
        checkBoxSections={checkBoxSections}
        extended1={extended1}
        setExtended1={setExtended1}
      />
      <Collapse in={extended1}>
        <SubSectionOptions1
          sub1Values={sub1Values}
          handleSection1={handleSection1}
          checkBoxSections={checkBoxSections}
          extended1={extended1}
        />
      </Collapse>
      <SubSectionMain2
        sub2Checked={sub2Checked}
        handleSub2Check={handleSub2Check}
        checkBoxSections={checkBoxSections}
        extended2={extended2}
        setExtended2={setExtended2}
      />
      <Collapse in={extended2}>
        <SubSectionOptions2
          sub2Values={sub2Values}
          handleSection2={handleSection2}
          checkBoxSections={checkBoxSections}
          extended2={extended2}
        />
      </Collapse>
    </Root>
  );
};

export default TableRowCollapse;
