import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Ozsafir from "../../Images/TopNavbar/Ozsafir.svg";

import Actions from "./Actions";
import Search from "./Search";

import { userData } from "../../Redux/Selectors";

import SubCard from "../DraggableCards/SubCard";
import NotificationPopover from "../Popovers/NotificationPopover";
import CartPopover from "../Popovers/CartPopover";
import ProfilePopover from "../Popovers/ProfilePopover";

import { useCurrentWidth } from "../../Utils/Hooks";

const PREFIX = "TopNavbar";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  link: `${PREFIX}-link`,
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "narrow",
})(({ narrow }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: narrow ? 150 : 80,
    display: "flex",
    flexDirection: "column",
    "&.MuiAppBar-colorPrimary": {
      color: "none",
      backgroundColor: "#fff",
    },
    "&.MuiAppBar-root": {
      flexDirection: "column",
      boxShadow: "none",
    },
  },

  [`& .${classes.container}`]: {
    width: "100%",
    height: 80,
    minWidth: 550,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 34,
    paddingLeft: 34,
  },

  [`& .${classes.button}`]: {
    textTransform: "none",
    borderRadius: 8,
    backgroundColor: "black",
    color: "white",
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    "&:hover": {
      backgroundColor: "black",
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
}));

const TopNavbar = ({ searchTextRef }) => {
  let history = useHistory();
  const width = useCurrentWidth();

  const userInfo = useSelector(userData);
  const cartInfo = useSelector(({ product }) => product.cart);

  const [openSub, setOpenSub] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotificationsPopover, setOpenNotificationsPopover] = useState(
    false
  );
  const [openCartPopover, setOpenCartPopover] = useState(false);
  const [openProfilePopover, setOpenProfilePopover] = useState(false);

  const openAddressModal = () => {
    closeProfilePopover();
    setOpenSub(true);
  };

  const closeSubModal = () => setOpenSub(false);

  const showNotificationsPopover = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenNotificationsPopover(true);
  };
  const closeNotificationsPopover = () => {
    setAnchorEl(null);
    setOpenNotificationsPopover(false);
  };

  const showProfilePopover = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenProfilePopover(true);
  };
  const closeProfilePopover = () => {
    setAnchorEl(null);
    setOpenProfilePopover(false);
  };

  const showCartPopover = (e) => {
    setAnchorEl(e.target);
    setOpenCartPopover(true);
  };
  const closeCartPopover = () => {
    setAnchorEl(null);
    setOpenCartPopover(false);
  };

  const goToHome = () => history.push("/home");

  return (
    <StyledAppBar
      className={classes.root}
      narrow={width <= 1050 ? true : false}>
      <Container maxWidth="lg" className={classes.container}>
        <Link to="/home" className={classes.link}>
          <img
            src={Ozsafir}
            width="160px"
            height="34px"
            alt="logo"
            onClick={goToHome}
            style={{ cursor: "pointer" }}
          />
        </Link>
        {width > 1050 && <Search ref={searchTextRef} />}
        <Actions
          showNotificationsPopover={showNotificationsPopover}
          showCartPopover={showCartPopover}
          showProfilePopover={showProfilePopover}
          userInfo={userInfo}
          cartInfo={cartInfo}
        />
        <SubCard handleCloseSub={closeSubModal} openSub={openSub} />
        <NotificationPopover
          open={openNotificationsPopover}
          anchorEl={anchorEl}
          handleClose={closeNotificationsPopover}
        />
        <CartPopover
          open={openCartPopover}
          anchorEl={anchorEl}
          handleClose={closeCartPopover}
          data={cartInfo}
        />
        <ProfilePopover
          open={openProfilePopover}
          anchorEl={anchorEl}
          handleClose={closeProfilePopover}
          userInfo={userInfo}
          openAdressDialog={openAddressModal}
        />
      </Container>
      {width <= 1050 && (
        <div
          style={{
            width: "100%",
            height: 70,
            display: "flex",
            justifyContent: "center",
            marginTop: 8,
            marginBottom: 8,
            minWidth: 550,
          }}>
          <Search ref={searchTextRef} />
        </div>
      )}
    </StyledAppBar>
  );
};

export default TopNavbar;
