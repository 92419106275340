import React from "react";

import { styled } from "@mui/material/styles";

import { mockFeatures } from "../../../Utils/Constants";

const PREFIX = "Descriptions";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  item: `${PREFIX}-item`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: 32,
    paddingLeft: 24,
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.item}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: "#B1AEAE",
    marginBottom: 8,
  },

  [`& .${classes.list}`]: {
    paddingLeft: 15,
  },
}));

const Descriptions = () => {
  return (
    <Root className={classes.root}>
      <div className={classes.header}>Açıklamalar</div>
      <ul className={classes.list}>
        {mockFeatures.map((item, index) => (
          <li key={index} className={classes.item}>
            {item}
          </li>
        ))}
      </ul>
    </Root>
  );
};

export default Descriptions;
