import axios from "axios";
import { refreshHeader } from "./Config";
import { history } from "../Utils/Helpers/History";
import { customerConstants } from "../Utils/Constants";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const getLocalStorageData = async () => {
  let data = await localStorage.getItem(customerConstants.CUSTOMER);
  return JSON.parse(data);
};

const addNewToken = async (access, refresh) => {
  let localCustomerData = await getLocalStorageData();
  localCustomerData.tokens.access_token = access;
  localCustomerData.tokens.refresh_token = refresh;

  await localStorage.setItem(
    customerConstants.CUSTOMER,
    JSON.stringify(localCustomerData)
  );
};

axios.interceptors.response.use(
  (response) => {
    // console.log("🚀 ~ file: index.js ~ line 25 ~ response", response)
    return response;
  },
  async (error) => {
    // console.log(JSON.parse(JSON.stringify(error)))
    let networkErr = error.message.includes("401");
    // console.log("🚀 ~ file: index.js ~ line 29 ~ networkErr", networkErr)
    // console.log("----------------------", error)
    const originalRequest = error.config;

    if (networkErr && originalRequest.url === `${API_URL}/auth/refresh`) {
      history.push("/signing-out");
      return Promise.reject(error);
    }

    if (networkErr && !originalRequest._retry) {
      // originalRequest._retry = true;
      try {
        try {
          const res = await axios.get(
            `${API_URL}/auth/refresh`,
            refreshHeader()
          );
          if (res.status === 200) {
            await addNewToken(res.data.access_token, res.data.refresh_token);
            originalRequest.headers.Authorization =
              "Bearer " + res.data.access_token;
            return axios(originalRequest);
          } else {
            toast.error(res?.data?.message);
          }
        } catch (err) {
          return console.log(err);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        Promise.reject(error);
        return await Promise.reject(error);
      }
    } else {
    }
    return Promise.reject(error);
  }
);

export * from "./AuthServices";
export * from "./FilterServices";
export * from "./ProductServices";
export * from "./AddressService";
export * from "./OrderService";
export * from "./UserService";
export * from "./RoleServices";
export * from "./SearchServices";
export * from "./CompanyServices";
export * from "./FavoriteService";
export * from "./NotificationService";
export * from "./CampaignService";
