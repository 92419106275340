import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { isMobileOnly } from "react-device-detect";

import AuthContainer from "./AuthContainer";
import HeaderRoute from "./AuthCommons/HeaderRoute";
import Info from "./PasswordSent/Info";
import ReturnHome from "./PasswordSent/ReturnHome";
import passwordIcon from "../../Images/Auth/password.svg";

import PasswordSentMob from "./PasswordSentMob";

const Root = styled("div")(() => ({
  width: 440,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const PasswordSent = (props) => {
  let history = useHistory();

  const handleRoute = () => {
    history.push("forgot-password");
  };
  if (isMobileOnly) {
    return <PasswordSentMob email={props.location.state} />;
  } else {
    return (
      <AuthContainer component="password-sent">
        <Root>
          <HeaderRoute handleRoute={handleRoute} component="password-sent">
            Şifremi Unuttum
          </HeaderRoute>
          <img
            src={passwordIcon}
            alt="passwordIcon"
            width="92px"
            height="91px"
          />
          <Info email={props?.location.state} />
          <ReturnHome />
        </Root>
      </AuthContainer>
    );
  }
};

export default PasswordSent;
