import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import AuthContainer from "./AuthContainer";
import Header from "./UpdatePassword/Header";
import Label from "./AuthCommons/Label";
import ReturnHome from "./UpdatePassword/ReturnHome";
import { SubmitButton, CustomTextField } from "./AuthCommons/Tools";
import UpdatePaswordMob from "./UpdatePasswordMob";
import { authService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";

const Root = styled("div")(() => ({
  width: 440,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const ErrorContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: 20,
  height: 20,
}));

const ForgotPassword = () => {
  let history = useHistory();
  const match = useRouteMatch();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState({
    first: false,
    second: false,
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (error === "cleared") {
        history.push("/password-updated");
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
  };

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
  };

  const handleSubmit = () => {
    const isReset = match.path.includes("reset-password");
    isReset ? handleReset() : handleActivate();
  };

  const handleReset = () => {
    if (password1 === password2) {
      let query = {
        token: match.params.token,
        password: password2,
      };
      authService
        .userResetPassword(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            setError("cleared");
          } else {
            setError(res?.data?.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleActivate = () => {
    if (password1 === password2) {
      let query = {
        token: match.params.token,
        password: password2,
      };
      console.log(query);
      authService
        .userActivation(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            setError("cleared");
          } else {
            setError(res?.data?.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleClickShowPassword = (prop) => {
    setShowPassword({ ...showPassword, [prop]: !showPassword[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (isMobileOnly) {
    return <UpdatePaswordMob />;
  } else {
    return (
      <AuthContainer component="forgot-password">
        <Root>
          <Header />
          <Label>Yeni Şifre</Label>
          <CustomTextField
            type={showPassword.first ? "text" : "password"}
            value={password1}
            onChange={handlePassword1Change}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("first")}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showPassword.first ? <Visibility /> : <VisibilityOff />}
                  </StyledIconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: 5.21 }}
          />
          <Label>Yeni Şifre Tekrar</Label>
          <CustomTextField
            type={showPassword.second ? "text" : "password"}
            value={password2}
            onChange={handlePassword2Change}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("second")}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showPassword.second ? <Visibility /> : <VisibilityOff />}
                  </StyledIconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: 21.21 }}
          />
          {error && (
            <ErrorContainer>
              <Typography align="center">{error}</Typography>
            </ErrorContainer>
          )}
          <SubmitButton
            disableRipple
            variant="contained"
            disabled={
              password2 !== password1 ||
              password1.length < 6 ||
              password2.length < 6
            }
            onClick={handleSubmit}>
            Tamam
          </SubmitButton>
          <ReturnHome />
        </Root>
      </AuthContainer>
    );
  }
};

export default ForgotPassword;
