import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeader from "../../../Ui/Dialog/Tools/MobileDialogHeader";
import TextArea from "../../../Ui/Fields/TextArea";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    paddingTop: theme.spacing(),
    margin: 0,
    overflow: "hidden",
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const InfoBox = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(),
  backgroundColor: "#F5F3FF",
  borderRadius: 8,
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  lineHeight: "16px",
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

const FeedBack = ({ handleClose, open }) => {
  const [values, setValues] = useState({ feedBack: "" });

  useEffect(() => {
    let active = true;
    if (active) {
      setValues({ feedBack: "" });
    }
    return () => {
      active = false;
    };
  }, []);

  const handleChange = (prop) => (event) =>
    setValues({ ...values, [prop]: event.target.value });

  const handleSubmit = () => {
    let query = { feedBack: values.feedBack };
    console.log(query);
    handleClose();
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeader
        header="Hata Bildir Veya Tavsiyede Bulun"
        callback={handleClose}
      />
      <InfoBox>
        <ul style={{ margin: 0, paddingLeft: 16 }}>
          <li>
            <Text>
              Bildirimleriniz bizim için çok önemli, sizden gelen yorumlar
              uygulamayı daha kullanışlı hale getirecektir.
            </Text>
          </li>
        </ul>
      </InfoBox>
      <TextArea
        label="Geri Bildirim"
        handleChange={handleChange}
        values={values}
        prop="feedBack"
        multiline
        rows={4}
      />
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}
        noMargin>
        Gönder
      </MobileDialogButton>
    </StyledDialog>
  );
};

FeedBack.propTypes = {
  handleChange: PropTypes.func,
  open: PropTypes.bool,
};

export default FeedBack;
