import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getCompanies = async (query) => {
  return await axios
    .post(`${API_URL}/company/admin/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const companyService = {
  getCompanies,
};
