import React, { useState } from "react";
import PropTypes from "prop-types";
import {useSelector, shallowEqual} from "react-redux"

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Collapse from "@mui/material/Collapse";

import NameAndPrice from "../../../../../Ui/CartProduct/CartProductMob/NameAndPrice";

const StyledImage = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  marginBottom: theme.spacing(),
}));

const QuantityContainer = styled("div")(({ theme }) => ({
  width: 110,
  height: 24,
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
  display: "flex",
  borderRadius: 8,
  backgroundColor: "#F5F3FF",
  alignItems: "center",
  marginBottom: theme.spacing(),
}));

const QuantityText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "bold",
})(({ theme, bold }) => ({
  fontSize: bold ? 16 : 12,
  fontWeight: bold ? 700 : 400,
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  color: bold ? theme.palette.darkGray.main : theme.palette.secondary.main,
  marginRight: bold && theme.spacing(2),
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: "18px",
  color: theme.palette.secondary.main,
  marginRight: theme.spacing(2),
}));

const FlexSide = styled("div", {
  shouldForwardProp: (prop) => prop !== "border",
})(({ border, theme }) => ({
  display: "flex",
  borderTop: border && `1px solid ${theme.palette.darkGray.light}`,
}));

const FlexColumn = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const ButtonAndPrice = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(),
}));

const FeturesButton = styled(Button)(({ theme }) => ({
  width: 150,
  height: 24,
  borderRadius: 6,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  textTransform: "none",
  fontSize: 12,
  paddingTop: 1.5,
  paddingBottom: 1.5,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CollapseContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(2),
}));

const CollapseName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.main,
  marginRight: 5,
}));

const CollapseValue = styled(Typography)(({theme}) => ({
  fontSize: 14,
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const OrderInfo = ({ product }) => {
  const isFeatures = product?.features;
  const {role_names} = useSelector(({ customer }) => customer?.selectedUser, shallowEqual);

  const [extended, setExtended] = useState(false);

  const onClickHandler = () => setExtended((prev) => !prev);

  return (
    <>
      <FlexSide>
        <StyledImage src={product?.image} alt="" width="60px" height="80px" />
        <FlexColumn style={{ width: "100%" }}>
          <NameAndPrice product={product} />
          <>
            <QuantityContainer>
              <QuantityText bold>{product?.quantity}</QuantityText>
              <QuantityText>Adet</QuantityText>
            </QuantityContainer>
            <ButtonAndPrice>
              <PriceText>{`${role_names && role_names[0] === "companyuser" ? 0 : product?.cost}₺`}</PriceText>
              {isFeatures && (
                <FeturesButton
                  endIcon={
                    extended ? (
                      <ArrowDropUp style={{ marginBottom: 3 }} />
                    ) : (
                      <ArrowDropDown style={{ marginBottom: 3 }} />
                    )
                  }
                  onClick={onClickHandler}>
                  Özel İşlem Bilgileri
                </FeturesButton>
              )}
            </ButtonAndPrice>
          </>
        </FlexColumn>
      </FlexSide>
      {product?.features && (
        <Collapse in={extended}>
          <CollapseContent>
            <FlexSide border>
              <FlexSide style={{ minWidth: 150 }}>
                <CollapseName>En:</CollapseName>
                <CollapseValue>{product?.features?.width}</CollapseValue>
              </FlexSide>
              <FlexSide>
                <CollapseName>Boy:</CollapseName>
                <CollapseValue>{product?.features?.height}</CollapseValue>
              </FlexSide>
            </FlexSide>
            <FlexSide border>
              <FlexSide style={{ minWidth: 150 }}>
                <CollapseName>Şekil:</CollapseName>
                <CollapseValue>{product?.features?.shape}</CollapseValue>
              </FlexSide>
              <FlexSide>
                <CollapseName>Kenar:</CollapseName>
                <CollapseValue>{product?.features?.side}</CollapseValue>
              </FlexSide>
            </FlexSide>
            {product?.features?.side === "Saçak" && (
              <FlexSide border style={{ width: "100%" }}>
                <CollapseName>Saçak Tipi:</CollapseName>
                <CollapseValue>{product?.features?.webType}</CollapseValue>
              </FlexSide>
            )}
          </CollapseContent>
        </Collapse>
      )}
    </>
  );
};

OrderInfo.propTypes = {
  product: PropTypes.object,
};

export default OrderInfo;
