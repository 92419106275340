import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeader from "../../../Ui/Dialog/Tools/MobileDialogHeader";
import PriceBox from "../../../Ui/PriceBoxes/PriceBox";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    paddingTop: theme.spacing(),
    margin: 0,
    overflow: "hidden",
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const BoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 42,
  marginBottom: theme.spacing(),
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 400,
  lineHeight: "16px",
  color: "#434141",
  paddingLeft: 2,
  marginBottom: theme.spacing(),
  "& > span": {
    fontWeight: 700,
  },
}));

const ProductPrices = ({ handleClose, open, price }) => {
  const authInfo = useSelector(({ auth }) => auth);

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeader header="Ürün Fiyatları" callback={handleClose} />
      <BoxContainer>
        <PriceBox
          price={price?.retail}
          header="Perakende Satış Fiyatı"
          color="gold"
          component="fast-order"
          mobile
          VatText = "Kdv dahil"
        />
        {authInfo?.customerInfo.role_names[0] !== "companyuser" && (
          <>
            <PriceBox
              price={price?.wholesale}
              header="Toptan Liste Fiyatı"
              color="orange"
              component="fast-order"
              mobile
              VatText = "Kdv dahil"
            />
            <PriceBox
              price={price?.view_price}
              header="İndirimli Alış Fiyatı"
              color="green"
              component="fast-order"
              mobile
              VatText = "Kdv hariç"
            />
          </>
        )}
      </BoxContainer>
      <StyledText>
        Bu ürünü <span>Temmuz 2021</span> fiyat listesinden almaktasınız
      </StyledText>
      {/* <StyledText>109,07 TL x 3 ay’a varan Taksit seçenekleri</StyledText> */}
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleClose}
        noMargin>
        Kapat
      </MobileDialogButton>
    </StyledDialog>
  );
};

ProductPrices.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  price: PropTypes.object,
};

export default ProductPrices;
