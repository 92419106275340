import React, { useEffect, useState, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";

import PageContainerMob from "../Common/PageContainerMob";
import ProductHeaderMob from "./Details/ProductDetails/ProductHeaderMob";
import Loading from "../../Utils/Loading";
import MobileNotFound from "./MobileDetails/MobileNotFound";
import MobileAppBar from "./MobileDetails/MobileAppBar";
import ButtonsAndInput from "./MobileDetails/ButtonsAndInput";
import MobileVariants from "./MobileDetails/MobileVariants";
import PriceAndSubmit from "./MobileDetails/PriceAndSubmit";
import DialogButtons from "./MobileDetails/DialogButtons";
import StockButton from "./MobileDetails/StockButton";
import Stocks from "../../Ui/Dialog/Mobile/Stocks";
import MobileSpecialDemands from "../../Ui/Dialog/Mobile/MobileSpecialDemands";

import { productService } from "../../Services";
import { productActions } from "../../Redux/Actions";
import {
  selectionsInitialQuery,
  generalConstants,
} from "../../Utils/Constants";
import {
  handleDataArray,
  handleActiveItems,
  handleVariants,
} from "../../Utils/Helpers";

const innerWidth = window.innerWidth;
const innerHeight = window.innerHeight;

const Root = styled("div")(() => ({
  width: innerWidth,
  height: "auto",
  overflowY: "scroll",
  overflowX: "hidden",
  scrollBehavior: "smooth",
}));

const StyledImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "height",
})(({ height }) => ({
  objectFit: "fill",
  width: innerWidth,
  height: height,
  position: "fixed",
}));

const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "height",
})(({ height }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: "#fff",
  zIndex: 2,
  marginTop: innerHeight - height < 140 ? height - 80 : height,
  position: "relative",
  display: "block",
  paddingBottom: 70,
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
}));

const ProductPageMob = (props) => {
  const toastId = useRef(null);
  const history = useHistory();
  const params = useParams();

  const dispatch = useDispatch();

  const getCart = () => dispatch(productActions.getCart());
  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id));

  const specialFeatureValues = useSelector(
    ({ product }) => product?.featureValues
  );

  const customer = useSelector(({ customer }) => customer);
  const companyId = useSelector(
    ({ customer }) => customer?.selectedUser?.company?.id
  );

  const [newSizes, setNewSizes] = useState({ height: "", width: "" });
  const [initialSelection, setInitialSelection] = useState(false);
  const [activeArray1, setActiveArray1] = useState([]);
  const [activeArray2, setActiveArray2] = useState([]);
  const [activeArray3, setActiveArray3] = useState([]);
  const [colorSelected, setColorSelected] = useState(false);
  const [sizeSelected, setSizeSelected] = useState(false);
  const [featuresSelected, setFeaturesSelected] = useState(false);
  const [openStockDialog, setOpenStockDialog] = useState(false);
  const [featuresDialog, setFeaturesDialog] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    color: "",
    size: "",
    shape: "",
  });
  const [selections, setSelections] = useState({
    id: "",
    name: "",
    color: "",
    size: "",
    shape: "",
    quantity: 1,
    features: {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    },
  });
  const [height, setHeight] = useState(innerWidth);

  const getProductDetail = async (id, query) => {
    setLoading(true);
    await productService
      .productSkuById(id, query)
      .then((res) => {
        const data = res.data.product_sku;
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let active = true;
    if (active) {
      const query = {
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "desc",
        filter: {
          company_id: companyId,
          on_sale: 1,
        },
        keyword: "",
      };

      params && companyId && getProductDetail(params.id, query);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, companyId]);

  useEffect(() => {
    let active = true;
    if (active) {
      setFeaturesSelected(false);
      const variants = data && handleVariants(data);
      if (data) {
        const img = new Image();
        img.src = data.image;
        img.onload = () => {
          const ratio = innerWidth / img.width;
          const newHeight = img.height * ratio;
          setHeight(newHeight);
        };
      }
      if (data && variants) {
        const productType = data?.type ? data.type.id : null;
        setValues({
          ...values,
          size: handleDataArray(variants, "Ebat"),
          shape: handleDataArray(variants, "Şekil"),
          color: handleDataArray(variants, "Renk"),
        });
        const defaultVariant = variants.filter(
          (variant) => variant.product_id === data.id
        );
        const initialSize = defaultVariant[0].Ebat;
        const initialShape = defaultVariant[0].Şekil;
        const initialColor = defaultVariant[0].Renk;
        const newWidth = +initialSize.split("x")[0];
        const newHeight = +initialSize.split("x")[1];
        const heightIsNan = _.isNaN(newHeight) ? 0 : newHeight;
        setNewSizes({ height: heightIsNan, width: newWidth });
        setSelections({
          ...selectionsInitialQuery,
          size: initialSize,
          shape: initialShape,
          color: initialColor,
          features: {
            ...selectionsInitialQuery.features,
            width: newWidth,
            height: productType && productType === 3 ? 0 : heightIsNan,
          },
        });
        setActiveArray1(defaultVariant);
        setActiveArray2(defaultVariant);
        setActiveArray3(defaultVariant);
        setInitialSelection(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (selections.size?.length > 0) {
        setSizeSelected(true);
      } else {
        setSizeSelected(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.size]);

  useEffect(() => {
    let active = true;
    if (active && data) {
      if (values.color.length === 1) {
        setColorSelected(true);
        const variants = handleVariants(data);
        const result = handleActiveItems(variants, "Renk", values.color[0]);
        setInitialSelection(false);
        setActiveArray1(result);
      } else if (selections.color?.length > 0 && !initialSelection) {
        setColorSelected(true);
      } else {
        setColorSelected(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.color, values, data]);

  useEffect(() => {
    let active = true;
    if (active) {
      const id = activeArray3.length > 0 ? activeArray3[0].product_id : null;
      id && parseInt(params.id) !== id && history.push(`/product/${id}`);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeArray3]);

  const handleTouch = () => {
    console.log("clicked");
  };

  const toggleStockDialog = () => setOpenStockDialog(!openStockDialog);
  const toggleFeaturesDialog = () => {
    discardFeaturesSelections();
    setFeaturesDialog(!featuresDialog);
  };
  const handleChange = (prop) => (event) => {
    const evt = event.target;
    const activeSizes = handleDataArray(activeArray1, "Ebat");
    const activeShapes = handleDataArray(activeArray2, "Şekil");
    const selectedValue = prop === "quantity" ? evt.value : evt.innerText;
    if (prop === "color") {
      const variants = handleVariants(data);
      const result = handleActiveItems(variants, "Renk", evt.innerText);
      setInitialSelection(false);
      setActiveArray1(result);
      activeArray2.length > 0 && setActiveArray2([]);
      activeArray3.length > 0 && setActiveArray3([]);
      setSelections({
        ...selections,
        [prop]: selectedValue,
        size: "",
        shape: "",
        quantity: 1,
        features: {
          ...selections.features,
          shape: "",
          side: "",
          webType: "",
          webColor: "",
          width: newSizes.width,
          height: newSizes.height,
        },
      });
    } else if (prop === "size") {
      if (initialSelection && !colorSelected) return;
      if (!colorSelected) return;
      if (!activeSizes.includes(evt.innerText)) return;
      const result = handleActiveItems(activeArray1, "Ebat", evt.innerText);
      setActiveArray2(result);
      const result2 = handleDataArray(result, "Şekil");
      setValues({ ...values, shape: result2 });
      if (result2.length === 1) {
        const result_ = handleActiveItems(result, "Şekil", result2[0]);
        setActiveArray3(result_);
        setSelections({
          ...selections,
          [prop]: selectedValue,
          shape: result2[0],
        });
      } else {
        activeArray3.length > 0 && setActiveArray3([]);
        setSelections({
          ...selections,
          [prop]: selectedValue,
          shape: "",
        });
      }
    } else if (prop === "shape") {
      if (initialSelection) return;
      if (!sizeSelected) return;
      if (!activeShapes.includes(evt.innerText)) return;
      const result = handleActiveItems(activeArray2, "Şekil", evt.innerText);
      setActiveArray3(result);
      setSelections({
        ...selections,
        [prop]: selectedValue,
      });
    } else if (prop === "quantity") {
      setSelections({
        ...selections,
        [prop]: selectedValue,
      });
    }
  };

  const handleFeatures = (prop) => (e) => {
    if (selections.features[prop] === e.target.value) {
      setSelections({
        ...selections,
        features: { ...selections.features, [prop]: "" },
      });
    } else {
      if (
        prop === "side" &&
        e.target.value !== "Saçak" &&
        selections.features.webType !== ""
      ) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: e.target.value,
            webType: "",
          },
        });
      } else if (prop === "width" || prop === "height") {
        const data = parseInt(e.target.value);
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: data },
        });
      } else {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: e.target.value },
        });
      }
    }
  };

  const discardFeaturesSelections = () => {
    setFeaturesSelected(false);
    setSelections({
      ...selections,
      features: {
        ...selections.features,
        shape: "",
        side: "",
        webType: "",
        webColor: "",
        width: newSizes.width,
        height: newSizes.height,
      },
    });
  };

  const notify = () => (toastId.current = toast("Ürün Sepete Eklendi"));

  const handleAddToChart = () => {
    const productId = data.has_variants
      ? activeArray3[0]?.product_id || data?.id
      : data?.id;

    const query = {
      product_id: productId,
      quantity: selections.quantity,
      features: featuresSelected ? selections.features : null,
    };

    if (customer?.isAdmin) {
      const id = customer?.selectedUser?.id;
      productService
        .addToAdminCart(query, id)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getAdminCart(id);
            notify();
            setFeaturesSelected(false);
            setSelections({
              ...selections,
              quantity: 1,
              features: {
                ...selections.features,
                shape: "",
                side: "",
                webType: "",
                webColor: "",
                width: newSizes.width,
                height: newSizes.height,
              },
            });
            featuresDialog && setFeaturesDialog(false);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    } else {
      productService
        .addToCart(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getCart();
            notify();
            setFeaturesSelected(false);
            setSelections({
              ...selections,
              quantity: 1,
              features: {
                ...selections.features,
                shape: "",
                side: "",
                webType: "",
                webColor: "",
                width: newSizes.width,
                height: newSizes.height,
              },
            });
            featuresDialog && setFeaturesDialog(false);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleSubmit = () => {
    if (data.type.id === 3) {
      if (featuresSelected) {
        handleAddToChart();
      } else {
        toggleFeaturesDialog();
      }
    } else {
      handleAddToChart();
    }
  };

  const renderProductHeader = useMemo(() => {
    return <ProductHeaderMob data={data} />;
  }, [data]);

  if (loading) {
    return (
      <div style={{ width: innerWidth, height: innerHeight }}>
        <Loading open={loading} />
      </div>
    );
  } else {
    if (!data && !loading) {
      return <MobileNotFound />;
    } else {
      return (
        <PageContainerMob noScroll noPadding noFooter>
          <Fade in={!loading}>
            <Root>
              <MobileAppBar product={data} />
              <StyledImage
                height={height}
                onClick={handleTouch}
                src={data?.image}
              />
              <ContentContainer height={height}>
                {renderProductHeader}
                {(data?.has_variants || data?.variants?.length > 0) && (
                  <MobileVariants
                    handleChange={handleChange}
                    values={values}
                    selections={selections}
                  />
                )}
                <ButtonsAndInput
                  handleChange={handleChange}
                  selecttions={selections}
                  data={data}
                  openFeaturesDialog={toggleFeaturesDialog}
                  featuresSelected={featuresSelected}
                  disabled={activeArray3.length === 0}
                />
                {data?.type?.id === 3 && (
                  <StockButton
                    openDialog={toggleStockDialog}
                    disabled={activeArray3.length === 0}
                  />
                )}
                <DialogButtons
                  data={data}
                  disabled={activeArray3.length === 0}
                />
              </ContentContainer>
            </Root>
          </Fade>
          <PriceAndSubmit
            data={data}
            handleSubmit={handleSubmit}
            disabled={activeArray3.length === 0}
          />
          <Stocks
            open={openStockDialog}
            handleClose={toggleStockDialog}
            data={data?.stock?.detail_list}
          />
          <MobileSpecialDemands
            handleClose={toggleFeaturesDialog}
            open={featuresDialog}
            data={data}
            features={selections.features}
            handleFeatures={handleFeatures}
            specialFeatureValues={specialFeatureValues}
            specialFeaturesSelected={featuresSelected}
            setSpecialFeaturesSelected={setFeaturesSelected}
            handleChange={handleChange}
            selections={selections}
            handleSubmit={handleSubmit}
            setSelections={setSelections}
          />
        </PageContainerMob>
      );
    }
  }
};

export default ProductPageMob;
