import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "minwidth",
})((minwidth) => ({
  display: "flex",
  minWidth: minwidth && 150,
}));

const Name = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ theme, mobile }) => ({
  fontSize: mobile ? 14 : 16,
  color: theme.palette.primary.main,
  marginRight: 5,
}));

const Value = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ mobile, theme }) => ({
  fontSize: mobile ? 14 : 16,
  color: theme.palette.secondary.main,
  fontWeight: "bold",
}));

const RowPart = ({ value, minWidth, name, mobile }) => {
  return (
    <Root minwidth={minWidth}>
      <Name mobile={mobile}>{name}</Name>
      <Value mobile={mobile}>{value}</Value>
    </Root>
  );
};

RowPart.propTypes = {
  value: PropTypes.any,
  minWidth: PropTypes.bool,
  name: PropTypes.string,
  mobile: PropTypes.bool,
};

export default RowPart;
