import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import TabButtons from "./AddressSelection/TabButtons";
import CartAddressBox from "../../../Ui/CartAddressBox/CartAddressBox";
import Loading from "../../../Utils/Loading";

import { addressService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    minWidth: 920,
  },
}));

const StyledContent = styled(DialogContent)(() => ({
  width: "100%",
  height: "100%",
  padding: 0,
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: "25px",
  position: "absolute",
  color: theme.palette.grey[500],
  right: "0",
  top: "0",
  borderRadius: "0 0 0 100%",
  "& svg": {
    fontSize: "24px",
  },
}));

const StyledCloseIcon = styled(CloseIcon)(() => ({
  position: "absolute",
  top: "8px",
  right: "7px",
}));

const ButtonContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 15,
  paddingRight: 20,
}));

const NewAddressButton = styled(Button)(() => ({
  width: 120,
  height: 32,
  backgroundColor: "#5ABD8C",
  color: "#fff",
  fontSize: 16,
  textTransform: "none",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: "#5ABD8C",
  },
}));

const StyledGrid = styled(Grid)(() => ({
  height: "auto",
  minHeight: 200,
  overflow: "scroll",
  marginTop: 15,
  marginBottom: 60,
  paddingBottom: 10,
  paddingLeft: 20,
}));

const Submit = styled(Button)(({ theme }) => ({
  position: "absolute",
  bottom: 20,
  left: "50%",
  transform: "translate(-50%, 0)",
  width: 200,
  height: 32,
  backgroundColor: theme.palette.darkGray.main,
  color: "#fff",
  fontSize: 16,
  textTransform: "none",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const AddressSelection = ({
  open,
  onClose,
  handleSubmit,
  openAddAddressModal,
  callCompanyAddress,
}) => {
  const query = {
    pageNumber: 1,
    pageSize: 100,
    sortField: "id",
    sortOrder: "desc",
    filter: {},
  };

  const addresses = useSelector(({ addresses }) => addresses);
  const customer = useSelector(({ customer }) => customer);

  const [tabIndex, setIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userAddresses, setUserAddresses] = useState(null);
  const [firmAddresses, setFirmAddresses] = useState(null);
  const [customerAddresses, setCustomerAddresses] = useState(null);

  let customerIsAdmin = useMemo(() => customer?.isAdmin, [customer]);
  let currentCompanyId = useMemo(() => customer?.currentCompany?.id, [
    customer,
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customerIsAdmin) {
        let adminQuery = { ...query, filter: { company_id: currentCompanyId } };
        getCompanyAddresses(adminQuery);
      } else {
        addresses.addresses.length > 0
          ? setUserAddresses(addresses.addresses)
          : setUserAddresses(null);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, addresses, customerIsAdmin, currentCompanyId, callCompanyAddress]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (userAddresses && userAddresses.length > 0) {
        let companyAddr = userAddresses.filter((addr) => addr.type === 0);
        let customerAddr = userAddresses.filter(
          (addr) => addr.type === 1 && addr.status === 1
        );
        setFirmAddresses(companyAddr);
        setCustomerAddresses(customerAddr);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddresses]);

  const getCompanyAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUserAddresses(response.data.addresses);
      setLoading(false);
    } else {
      setUserAddresses(null);
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={open} maxWidth={"md"} keepMounted={false}>
      <StyledContent>
        <StyledIconButton aria-label="Close" onClick={onClose} size="large">
          <StyledCloseIcon />
        </StyledIconButton>
        <TabButtons index={tabIndex} setIndex={setIndex} />
        <Loading open={loading} />
        {tabIndex !== 1 ? (
          <>
            {tabIndex === 2 && (
              <ButtonContainer>
                <NewAddressButton
                  disableRipple
                  onClick={openAddAddressModal}
                  varaint="contained">
                  Yeni Ekle
                </NewAddressButton>
              </ButtonContainer>
            )}
            <StyledGrid container spacing={2}>
              {customerAddresses?.map((item, index) => (
                <Grid item xs={12} sm={4} md={4} key={index}>
                  <CartAddressBox item={item} onClose={onClose} />
                </Grid>
              ))}
            </StyledGrid>
          </>
        ) : (
          <StyledGrid container spacing={2}>
            {firmAddresses?.map((item, index) => (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <CartAddressBox item={item} firm onClose={onClose} />
              </Grid>
            ))}
          </StyledGrid>
        )}
        {/* <Submit disableRipple onClick={handleSubmit} varaint="contained">
          Tamam
        </Submit> */}
      </StyledContent>
    </StyledDialog>
  );
};

AddressSelection.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  openAddAddressModal: PropTypes.func,
  callCompanyAddresses: PropTypes.number,
};

export default AddressSelection;
