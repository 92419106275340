import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

const StyledPaper = styled(Paper)(() => ({
  width: 250,
  height: 120,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 10,
}));

const SuccessButton = styled(Button)(({theme}) => ({
  width: 180,
  height: 32,
  textTransform: "none",
  backgroundColor: theme.palette.darkGray.main,
  color: "#fff",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const ErrorDialog = ({ errorDialog, handleErrorDialog }) => {
  return (
    <Dialog open={errorDialog} keepMounted={false}>
      <StyledPaper>
        <Typography align="center" style={{ marginBottom: 20 }}>
          Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.
        </Typography>
        <SuccessButton onClick={handleErrorDialog}>Kapat</SuccessButton>
      </StyledPaper>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  errorDialog: PropTypes.bool,
  handleErrorDialog: PropTypes.func,
};

export default ErrorDialog;
