import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import CategoryCardMob from "../../../Ui/ProductCard/CategoryCardMob";
import SectionHeaderMob from "../../Common/Sections/SectionHeaderMob";

const innerWidth = window.innerWidth;
const Root = styled("div")(({ theme }) => ({
  padding: 0,
  width: innerWidth,
  height: 292,
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(),
}));

const ItemContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "scroll",
  width: "100%",
  height: 260,
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const CategoriesMob = ({ categories, handleClick, value }) => {
  return (
    <Root>
      <SectionHeaderMob
        header="Kategoriler"
        handleClick={handleClick}
        value={value}
      />
      <ItemContainer>
        {categories?.map((item, index) => (
          <CategoryCardMob category={item} key={index} />
        ))}
      </ItemContainer>
    </Root>
  );
};

CategoriesMob.propTypes = {
  categories: PropTypes.array,
  handleClick: PropTypes.func,
  value: PropTypes.number,
};

export default CategoriesMob;
