import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

import ozsafirLogo from "../../Images/Auth/ozsafir-logo.svg";
import leaf from "../../Images/Auth/leaf.svg";
import branch from "../../Images/Auth/branch.svg";
import StickyFooter from "../../Ui/Footer/StickyFooter";

const PREFIX = "AuthContainer";

const gradientBackground =
  "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(210, 23, 163, 0.19) 0%, rgba(180, 48, 147, 0.19) 28.13%, rgba(133, 89, 122, 0.19) 100%)";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  logoContainer: `${PREFIX}-logoContainer`,
  card: `${PREFIX}-card`,
  branch: `${PREFIX}-branch`,
  leaf: `${PREFIX}-leaf`,
  footer: `${PREFIX}-footer`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    background: gradientBackground,
  },

  [`& .${classes.container}`]: {
    overflow: "visible",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "transparent",
    padding: 0,
    contain: "content",
    marginBottom: 0,
    minHeight: 800,
  },

  [`& .${classes.logoContainer}`]: {
    marginTop: 78,
    marginBottom: 43,
    userSelect: "none",
    cursor: "pointer",
  },

  [`& .${classes.card}`]: {
    width: 440,
    height: 418,
    background: "rgba(133, 89, 122, 0.1)",
    borderRadius: 8,
    "&[component='signin-email']": {
      minHeight: 478,
    },
    "&[component='forgot-password']": {
      height: 353,
    },
    "&[component='password-sent']": {
      height: 353,
    },
    "&[component='password-updated']": {
      height: 353,
    },
    "&[component='error-boundary']": {
      background: 'transparent'
    },
  },

  [`& .${classes.branch}`]: {
    position: "absolute",
    top: -25,
    left: "92%",
    opacity: 0.4,
    transform: " rotate(-16.71deg)",
    userSelect: "none",
  },

  [`& .${classes.leaf}`]: {
    position: "absolute",
    width: 130,
    height: 250,
    left: 7.52,
    top: 416,
    userSelect: "none",
  },

  [`& .${classes.footer}`]: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: 0,
  },
}));

const AuthContainer = ({ children, component }) => {
  const history = useHistory();

  const goToHome = () => history.push("/home");
  return (
    <Root>
      <div className={classes.root}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.branch}>
            <img src={branch} alt="branch" />
          </div>
          <div className={classes.logoContainer} onClick={goToHome}>
            <img src={ozsafirLogo} alt="ozsafirLogo" width="269" height="44" />
          </div>
          <div className={classes.card} component={component}>
            {children}
          </div>
          <div className={classes.leaf}>
            <img src={leaf} width="125px" height="240px" alt="leafBig" />
          </div>
        </Container>
        <div className={classes.footer}>
          <StickyFooter />
        </div>
      </div>
    </Root>
  );
};

AuthContainer.propTypes = {
  children: PropTypes.any,
  component: PropTypes.string,
};

export default AuthContainer;
