import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 45,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(),
  borderBottom: "1px solid #B1AEAE",
  paddingBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  // width: "100%",
  flex: 1,
  height: 25,
  "& .MuiInputBase-root": {
    height: 25,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 4,
    height: 16,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 4,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
    "&::placeholder": {
      fontSize: 16,
      lineHeight: "18px",
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      opacity: 1,
      paddingTop: "2px !important",
    },
  },
}));

const CloseAdornment = styled(InputAdornment)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(),
  cursor: "pointer",
  zIndex: 9999,
}));

const FilterSearchTopbar = ({
  handleChange,
  onCloseClick,
  keyword,
  onBackClick,
  toggleFiltersDialog,
}) => {
  return (
    <Root>
      <ArrowBackIcon
        onClick={onBackClick}
        style={{ fill: "#434141", height: 24, width: 24, marginRight: 16 }}
      />
      <StyledTextField
        fullWidth
        value={keyword}
        onChange={handleChange}
        placeholder="Ara"
        variant="standard"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {keyword.length > 0 && (
                <CloseAdornment position="end" onMouseDown={onCloseClick}>
                  <Fade in={keyword.length > 0}>
                    <CloseIcon style={{ fill: "#8E8E8E" }} />
                  </Fade>
                </CloseAdornment>
              )}
            </>
          ),
        }}
        inputProps={{
          autoComplete: "new-password",
        }}
      />
      <FilterAltRoundedIcon
        onClick={toggleFiltersDialog}
        style={{ fill: "#434141", height: 24, width: 24, marginLeft: 16 }}
      />
    </Root>
  );
};

FilterSearchTopbar.propTypes = {
  handleChange: PropTypes.func,
  onCloseClick: PropTypes.func,
  keyword: PropTypes.string,
  onBackClick: PropTypes.func,
  toggleFiltersDialog: PropTypes.func,
};

export default FilterSearchTopbar;
