import React from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  // backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

export const Loading = ({ open }) => {
  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      BackdropComponent={StyledBackDrop}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%", width: "100%" }}>
        <ReactLoading type="spokes" color="#6E3564" height={80} width={80} />
      </Grid>
    </Dialog>
  );
};

Loading.propTypes = {
  open: PropTypes.bool,
};

export default Loading;
