import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const PREFIX = "RatingCheckBox";

const classes = {
  checkBox: `${PREFIX}-checkBox`,
};

const StyledFormGroup = styled(FormGroup)(() => ({
  [`&.${classes.checkBox}`]: {
    "&.MuiFormGroup-row": {
      paddingLeft: 11,
      marginTop: 15,
    },
    "& .MuiTypography-body1": {
      fontSize: 14,
    },
  },
}));

const RatingCheckBox = ({ checked, handleCheck }) => {
  return (
    <StyledFormGroup row className={classes.checkBox}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleCheck} />}
        label="Ürünü satın aldım"
      />
    </StyledFormGroup>
  );
};

RatingCheckBox.propTypes = {
  checked: PropTypes.bool,
  handleCheck: PropTypes.func,
};

export default RatingCheckBox;
