import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import RadioSelected from "../../../../Images/ProductPage/RadioSelected.svg";
import RadioUnselected from "../../../../Images/ProductPage/RadioUnselected.svg";

const StyledRadio = styled(Radio)(({ theme }) => ({
  width: 16,
  height: 16,
  padding: 0,
  marginRight: theme.spacing(0.5),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const StyledLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  "&.MuiFormControlLabel-root": {
    margin: 0,
    minWidth: 95,
  },
  "& .MuiTypography-body1": {
    marginLeft: 2,
    marginTop: 1,
    fontSize: 12,
    fontWeight: selected ? 700 : 400,
    color: selected ? theme.palette.primary.main : theme.palette.secondary.main,
  },
}));

const RadioArea = ({
  data,
  selectedValue,
  value,
  handleFeatures,
  features,
}) => {
  return (
    <StyledFormControl component="fieldset">
      <Grid container spacing={2} style={{ width: "100%" }}>
        {data?.map((item, index) => (
          <Grid
            item
            xs={3}
            key={index}
            style={{
              display:
                value === "shape"
                  ? features?.side === "Katlama" &&
                    (item === "Daire" || item === "Oval")
                    ? "none"
                    : "inline-flex"
                  : value === "side"
                  ? (features?.shape === "Daire" ||
                      features?.shape === "Oval") &&
                    item === "Katlama"
                    ? "none"
                    : "inline-flex"
                  : "inline-flex",
            }}>
            <StyledLabel
              key={index}
              value={item}
              label={item}
              selected={item === selectedValue}
              control={
                <StyledRadio
                  onClick={handleFeatures(value)}
                  disableRipple
                  value={item}
                  checked={item === selectedValue}
                  icon={<img src={RadioUnselected} width="16px" alt="" />}
                  checkedIcon={<img src={RadioSelected} width="16px" alt="" />}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </StyledFormControl>
  );
};

RadioArea.propTypes = {
  data: PropTypes.array,
  selectedValue: PropTypes.string,
  value: PropTypes.string,
  handleFeatures: PropTypes.func,
  features: PropTypes.object,
};

export default RadioArea;
