import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";

import TabButtons from "./NotificationPopover/TabButtons";
import Messages from "./NotificationPopover/Messages";
import Notifications from "./NotificationPopover/Notifications";

const PREFIX = "NotificationPopover";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};

const StyledPopover = styled(Popover)(() => ({
  [`&.${classes.root}`]: {
    marginTop: 15,
    height: 413,
  },

  [`& .${classes.paper}`]: {
    width: 320,
    height: 320,
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
  },
}));

const NotificationPopover = ({ open, anchorEl, handleClose }) => {
  const [index, setIndex] = useState(1);

  const id = open ? "simple-popover" : undefined;

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      className={classes.root}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={classes.paper}>
        <TabButtons setIndex={setIndex} index={index} />
        {index === 1 ? <Notifications index={index} /> : <Messages />}
      </div>
    </StyledPopover>
  );
};

NotificationPopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

export default NotificationPopover;
