import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Column from "./FooterMain/Column";
import {
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  FourthColumn,
} from "../../Utils/Constants";

import ozsafir from "../../Images/FooterImages/ozsafir.svg";
import entiLogo from "../../Images/FooterImages/enti-hali.svg";
import romansLogo from "../../Images/FooterImages/romans.svg";
import dekorentiLogo from "../../Images/FooterImages/dekor-enti.svg";
import appStoreLogo from "../../Images/FooterImages/app-store.svg";
import playStoreLogo from "../../Images/FooterImages/play-store.svg";
import Bufi from "../../Images/FooterImages/Bufi.svg";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({ theme }) => ({
  width: innerWidth,
  height: "auto",
  backgroundColor: "#000000",
  userSelect: "none",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
  paddingTop: theme.spacing(3),
}));

const FirmLogoContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(),
  padding: theme.spacing(),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const BrandsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 67,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderTop: "1px solid #fff",
  borderBottom: "1px solid #fff",
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const MobileText = styled(Typography)(() => ({
  width: "100%",
  fontSize: 14,
  lineHeight: "20px",
  color: "#BDBDBD",
  textAlign: "center",
}));

const StoreLogoContainer = styled("div")(() => ({
  width: "100%",
  height: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const MobileFooter = () => {
  return (
    <Root>
      <Grid container spacing={4} style={{ paddingLeft: 24 }}>
        <Grid item xs={6}>
          <Column header="ÖzSafir Halıcılık" data={FirstColumn} />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 60 }}>
          <Column header="Ürün ve Markalar" data={SecondColumn} />
        </Grid>
        <Grid item xs={6}>
          <Column header="Sipariş ve Ödeme" data={ThirdColumn} />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 60 }}>
          <Column header="Ayarlar" data={FourthColumn} />
        </Grid>
      </Grid>
      <FirmLogoContainer>
        <img src={ozsafir} alt="" width="107" height="30" />
      </FirmLogoContainer>
      <BrandsContainer>
        <img
          src={entiLogo}
          alt=""
          height="24"
          width="90"
          style={{ marginRight: 8 }}
        />
        <img
          src={romansLogo}
          alt=""
          height="24"
          width="116"
          style={{ marginRight: 8 }}
        />
        <img src={dekorentiLogo} alt="" height="18" width="114" />
      </BrandsContainer>
      <MobileText>Mobil Uygulamaları Keşfet</MobileText>
      <StoreLogoContainer>
        <a
          href="https://apps.apple.com/app/%C3%B6zsafir-hal%C4%B1-b2b/id1599735542"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src={appStoreLogo}
            alt=""
            width="74"
            style={{ marginRight: 64 }}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.buficore.b2b"
          target="_blank"
          rel="noopener noreferrer">
          <img src={playStoreLogo} alt="" width="68" />
        </a>
      </StoreLogoContainer>
      <Grid container>
        <Grid item xs={6}>
          <MobileText>Gizlilik Politikası</MobileText>
        </Grid>
        <Grid item xs={6}>
          <MobileText>Çerez Politikası</MobileText>
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 16,
          marginBottom: 16,
        }}>
        <a href="https://www.buficore.com/">
          <img
            src={Bufi}
            width="36"
            height="16"
            alt=""
            style={{ marginRight: 16 }}
          />
        </a>
      </div>
    </Root>
  );
};

export default React.memo(MobileFooter);
