import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontsize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#F5B041",
  textTransform: "capitalize",
  marginTop: theme.spacing(),
  marginLeft: theme.spacing(),
}));

const Header = styled(Typography)(({ theme }) => ({
  width: "fit-content",
  height: "auto",
  fontWeight: 400,
  fontSize: 34,
  lineHeight: "42px",
  color: theme.palette.darkGray.main,
}));

const NewSectionHeader = ({ header, noButton, handleClick, value }) => {
  return (
    <HeaderContainer>
      <Header>{header}</Header>
      {!noButton && (
        <StyledButton
          onClick={() => handleClick && handleClick(value)}
          endIcon={<ArrowForwardIosIcon style={{ fontSize: 12 }} />}>
          Tümü
        </StyledButton>
      )}
    </HeaderContainer>
  );
};

NewSectionHeader.propTypes = {
  header: PropTypes.string,
  noButton: PropTypes.bool,
  handleClick: PropTypes.func,
  value: PropTypes.number,
};

export default NewSectionHeader;
