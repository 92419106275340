import React from "react";
import { isMobileOnly } from "react-device-detect";

import WebBoundary from "./ErrorBoundary/WebBoundary";
import MobileBoundary from "./ErrorBoundary/MobileBoundary";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.goToHome = this.goToHome.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // console.log("error =>", error);
    // console.log("info =>", info);
  }

  goToHome = () => {
    this.props.history.push("/home");
    this.setState({ hasError: false });
  };

  getContent = () => {
    if (isMobileOnly) {
      return <MobileBoundary goToHome={this.goToHome} />;
    }
    return <WebBoundary goToHome={this.goToHome} />;
  };

  render() {
    if (this.state.hasError) {
      return <>{this.getContent()}</>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
