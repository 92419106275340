import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

const PREFIX = "QuantitySelectionMob";

const classes = {
  select: `${PREFIX}-select`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  adornment: `${PREFIX}-adornment`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.select}`]: {
    borderRadius: 3,
    backgroundColor: "#fff",
    // border: `1px solid ${theme.palette.darkGray.light}`,
    height: 28,
    width: 90,
    "&[in-specialfeatures='true']": {
      width: 87,
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "#fff",
    },
    "& .MuiSelect-root, .MuiSelect-select": {
      padding: "0px 2px 0px 0px",
      borderRadius: 8,
      backgroundColor: "#fff",
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      "&:hover, &:focus, &:active": {
        backgroundColor: "transparent",
      },
    },
    "&.css-18mpjkg-MuiInputBase-root-MuiOutlinedInput-root": {
      paddingLeft: 8,
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(231, 231, 231, 0.5)",
    },
    "& .css-bf1e45-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-bf1e45-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-bf1e45-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        paddingRight: 0,
      },
  },

  [`& .${classes.menu}`]: {
    width: "100%",
    height: "auto",
    borderRadius: 8,
    marginTop: 8,
    maxHeight: 80,
    "&.MuiList-root, .MuiMenu-list, .MuiList-padding": {
      padding: 0,
    },
  },

  [`& .${classes.menuItem}`]: {
    height: 24,
    "&.MuiListItem-root, .Mui-selected, .Mui-selected:hover": {
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#fff",
      borderBottom: "1px solid rgba(231, 231, 231, 1)",
      fontSize: 13,
      fontWeight: 400,
      color: "rgba(142, 142, 142, 1)",
      "&:hover, &:focus, &:active": {
        fontSize: 15,
        fontWeight: 500,
        color: "rgba(127, 91, 120, 1)",
        backgroundColor: "#fff",
      },
    },
    "&.MuiButtonBase-root.MuiMenuItem-root": {
      minHeight: 24,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 10,
    },
  },

  [`& .${classes.adornment}`]: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    paddingLeft: 0,
    userSelect: "none",
  },
}));

const QuantitySelectionMob = ({
  handleChange,
  param,
  selectedValue,
  stockInfo,
  specialFeatures,
}) => {
  let initialArr = [1, 2, 3, 4, 5];

  const [valueArr, setValueArr] = useState(initialArr);

  useEffect(() => {
    let active = true;
    if (active) {
      let limit = stockInfo > 5 || +stockInfo === 0 ? 5 : stockInfo;
      const values = Array.from({ length: limit }, (_, i) => i + 1);
      setValueArr(values);
    }
    return () => {
      active = false;
    };
  }, [stockInfo]);

  return (
    <StyledFormControl>
      <Select
        value={selectedValue || 1}
        onChange={handleChange(param)}
        className={classes.select}
        in-specialfeatures={specialFeatures ? "true" : "false"}
        startAdornment={
          <InputAdornment position="start">
            <div className={classes.adornment}>Miktar:</div>
          </InputAdornment>
        }
        MenuProps={{
          classes: { paper: classes.menu },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}>
        {valueArr.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            className={classes.menuItem}
            style={{
              minHeight: 24,
              fontSize: 12,
              padding: 4,
              justifyContent: "center",
            }}
            disableRipple>
            {item}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

QuantitySelectionMob.propTypes = {
  handleChange: PropTypes.func,
  param: PropTypes.string,
  selectedValue: PropTypes.number,
  stockInfo: PropTypes.any,
  specialFeatures: PropTypes.bool,
};

export default QuantitySelectionMob;
