import React from "react";

import { styled } from "@mui/material/styles";

import Brands from "./StickyFooter/Brands";
import Bottom from "./StickyFooter/Bottom";

const PREFIX = "StickyFooter";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100vw",
    backgroundColor: "#000000",
    height: 145,
    padding: 0,
    userSelect: "none",
  },
}));

const StickyFooter = () => {
  return (
    <Root className={classes.root}>
      <Brands />
      <Bottom />
    </Root>
  );
};

export default React.memo(StickyFooter);
