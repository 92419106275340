import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: 45,
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  position: "relative",
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.secondary.main,
  marginLeft: theme.spacing(),
  lineHeight: "20px",
}));

const CheckoutHeader = ({ goBack }) => {
  return (
    <StyledContainer>
      <IconButton
        onClick={goBack}
        style={{ paddingLeft: 0, width: 24, height: 24 }}>
        <ArrowBackIcon />
      </IconButton>
      <Header>Ödeme Sayfası</Header>
    </StyledContainer>
  );
};

CheckoutHeader.propTypes = {
  goBack: PropTypes.func,
};

export default CheckoutHeader;
