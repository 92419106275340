import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import NewProductCard from "../../../Ui/ProductCard/NewProductCard";
import NewSectionHeader from "./NewSectionHeader";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 1212,
  height: "auto",
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const ProductsContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
}));

const NewSection = ({ header, products, handleClick, value, noButton }) => {
  return (
    <Root>
      <NewSectionHeader
        header={header}
        handleClick={handleClick}
        value={value}
        noButton={noButton}
      />
      <ProductsContainer container spacing={2}>
        {products?.map((item, index) => (
          <Grid item key={index}>
            <NewProductCard item={item} />
          </Grid>
        ))}
      </ProductsContainer>
    </Root>
  );
};

NewSection.propTypes = {
  header: PropTypes.string,
  products: PropTypes.array,
  handleClick: PropTypes.func,
  value: PropTypes.number,
  noButton: PropTypes.bool,
};

export default NewSection;
