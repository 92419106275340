import React from "react";
import PropTypes from "prop-types";

import DraggableModal from "./DraggableModal";
import SubCardHeader from "./SubCard/SubCardHeader";
import SubCardContent from "./SubCard/SubCardContent";

const SubCard = ({ handleCloseSub, openSub, noClose }) => {
  return (
    <DraggableModal
      open={openSub}
      handleClose={handleCloseSub}
      title={<SubCardHeader handleClose={handleCloseSub} noClose={noClose} />}
      content={<SubCardContent open={openSub} handleClose={handleCloseSub} />}
    />
  );
};

SubCard.propTypes = {
  handleCloseSub: PropTypes.func,
  openSub: PropTypes.bool,
  noClose: PropTypes.bool,
};

export default SubCard;
