import React, { useState, useEffect, useLayoutEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import AutoCompleteArea from "../../Fields/AutocompleteArea";

import Loading from "../../../Utils/Loading";

import { companyService, userService } from "../../../Services";
import {
  customerActions,
  productActions,
  favoriteActions,
  authActions,
} from "../../../Redux/Actions";
import { generalConstants } from "../../../Utils/Constants";
import { getUsersArr } from "../../../Utils/Helpers";

const PREFIX = "SubCardContent";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 326,
    height: "auto",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    juustifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    alignSelf: "center",
    width: 290,
    height: 41.67,
    textTransform: "capitalize",
    borderRadius: 8,
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    fontSize: 16,
    fontWeight: 400,
    "&[is-first='true']": {
      marginBottom: theme.spacing(2),
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const SubCardContent = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const setUserCurrent = (query) =>
    dispatch(customerActions.setUserCurrent(query));

  const setSelectedUser = (user) =>
    dispatch(customerActions.selectedUser(user));

  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id));

  const getFavoritesAdmin = (query) =>
    dispatch(favoriteActions.getFavoritesAdmin(query));

  const signOut = () => dispatch(authActions.signout());

  const customer = useSelector(({ customer }) => customer);

  const query = {
    pageNumber: 1,
    pageSize: 1000,
    sortField: "id",
    sortOrder: "asc",
    filter: {
      status: 1,
      company_id: []
    },
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [usersRaw, setUsersRaw] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [firms, setFirms] = useState([
    { name: "Lütfen firma seçinizi", id: 0.5 },
  ]);
  // const [addresses, setAddresses] = useState([
  //   { name: "Lütfen şube seçiniz", id: 0.5 },
  // ]);
  const [users, setUsers] = useState([
    { name: "Lütfen kullanıcı seçiniz", id: 0.5 },
  ]);

  const [values, setValues] = useState({
    firm: { name: "Lütfen firma seçinizi", id: 0.5 },
    // branch: { name: "Lütfen şube seçiniz", id: 0.5 },
    user: { name: "Lütfen kullanıcı seçiniz", id: 0.5 },
  });

  let stringifiedFirms = JSON.stringify(values.firm);

  useLayoutEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        userAddress && setUserAddress(null)
        getCompanyList(query);
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (
        customer.selectedUser &&
        customer.currentAddress &&
        customer.currentCompany
      ) {
        let { id, full_name } = customer.selectedUser;
        setValues({
          firm: {
            name: customer?.currentCompany?.name,
            id: customer?.currentCompany?.id,
          },
          // branch: {
          //   name: customer?.currentAddress?.name,
          //   id: customer?.currentAddress?.id,
          // },
          user: { name: full_name, id: id },
        });
      } else return;
    }
    return () => {
      active = false;
    };
  }, [customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.firm) {
        let id = values.firm.id;
        if (id !== 0.5) {
          // let adminQuery = { ...query, filter: { company_id: id } };
          // getCompanyAddresses(adminQuery);
          getUsers(query, id);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFirms, open]);

  useEffect(() => {
    let active = true;
    if (active) {
      let activeId = customer?.selectedUser?.company?.id;
      if (values?.firm?.id !== 0.5 && +activeId !== +values?.firm?.id) {
        if (values.user.id !== 0.5) {
          setValues({
            ...values,
            // branch: { name: "Lütfen şube seçiniz", id: 0.5 },
            user: { name: "Lütfen kullanıcı seçiniz", id: 0.5 },
          });
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.firm?.id]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values?.user) {
        let id = values?.user.id;
        if (id !== 0.5 && usersRaw) {
          let currentUser = usersRaw?.filter((u) => u.id === values.user.id);
          let address = currentUser?.length > 0 ? currentUser[0]?.address : null
          address ? setUserAddress('exists') : setUserAddress('notExists')
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.user?.id]);

  const getCompanyList = async (query) => {
    setLoading(true);
    const response = await companyService.getCompanies(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies;
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ["name"].every((k) => t[k] === v[k])) === i
      );
      let finalData = notDuplicatedData.filter((item) => item.name !== "None");
      setFirms(finalData);
      setLoading(false);
    } else {
      setFirms([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  // const getCompanyAddresses = async (query, id) => {
  //   setLoading(true);
  //   const response = await addressService.getAdminUserAndCompanyAddresses(
  //     query
  //   );
  //   if (response.data.status === generalConstants.STATUS_TRUE) {
  //     let data = response.data.addresses;
  //     let newAddresses = data.filter((item) => item.type === 0);
  //     setAddresses(newAddresses);
  //     setLoading(false);
  //   } else {
  //     setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
  //     setLoading(false);
  //   }
  // };

  const getUsers = async (query, id) => {
    setLoading(true);
    const response = await userService.getAllUsers_(query, id, true);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      let newArr = getUsersArr(response.data.users);
      let notDuplicatedData = newArr.filter(
        (v, i, a) =>
          a.findIndex((t) => ["name"].every((k) => t[k] === v[k])) === i
      );
      let finalData = notDuplicatedData.filter((item) => item.name !== "None");
      setUsers(finalData);
      setUsersRaw(response.data.users);
      setLoading(false);
    } else {
      setUsers([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const handleCheck = () => {
    if (!values.firm || !values.user) {
      setError(true);
      return true;
    } else if (
      values.firm.id === 0.5 ||
      values.user.id === 0.5
    ) {
      setTextError(true);
      return true;
    } else {
      setError(false);
      setTextError(false);
      return false;
    }
  };

  const handleSubmit = () => {
    let isError = handleCheck();
    if (!isError && !textError) {
      let currentUser = usersRaw.filter((u) => u.id === values.user.id);
      let currentFirm = firms.filter((f) => f.id === values.firm.id);
      let currentAddress = currentUser?.length > 0 && currentUser[0]?.address
      let query = {
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "desc",
        filter: {
          user_id: currentUser.id,
        },
      };
      let query_ = {
        address: currentAddress,
        company: currentFirm[0],
      };
      setSelectedUser(currentUser[0]);
      setUserCurrent(query_);
      getAdminCart(currentUser[0].id);
      getFavoritesAdmin(query);
      setTimeout(() => handleClose(), 500);
    }
  };

  return (
    <Root className={classes.root}>
      <Loading open={loading} />
      {/* <CustomDivider component="SubCard">Geçerli Firma</CustomDivider>
      <FirmInfo /> */}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: 290,
            marginBottom: 30,
            display: "flex",
            flexDirection: "column",
          }}>
          {/* <NewAutoComplete
            data={firms}
            label="Firma"
            setValues={setValues}
            prop="firm"
            values={values}
            component="add user"
            error={error}
            customWidth={290}
          /> */}
          <AutoCompleteArea
            data={firms}
            label="Firma"
            setValues={setValues}
            prop="firm"
            values={values}
            component="add user"
            error={error}
            customWidth={290}
          />
          {/* <AutoCompleteArea
            data={addresses}
            label="Şube veya Adres"
            setValues={setValues}
            prop="branch"
            values={values}
            component="add user"
            error={error}
            customWidth={290}
          /> */}
          <AutoCompleteArea
            data={users}
            label="Kullanıcılar"
            setValues={setValues}
            prop="user"
            values={values}
            component="add user"
            error={error}
            customWidth={290}
          />
        </div>
      </div>
      {textError && (
        <Typography
          style={{ marginBottom: 16, fontSize: 12, color: "#FB0505" }}>
          Lütfen geçerli seçimler yapınız!
        </Typography>
      )}
       {userAddress === 'notExists' && (
        <Typography
          style={{ marginBottom: 16, fontSize: 12, color: "#FB0505" }}>
          Kullanıcı adresi bulunamadı!
        </Typography>
      )}
      <Button
        disableRipple
        variant="contained"
        className={classes.button}
        is-first="true"
        disabled={userAddress === 'notExists'}
        onClick={handleSubmit}>
        Tamam
      </Button>
      <Button
        disableRipple
        variant="contained"
        className={classes.button}
        onClick={signOut}>
        Çıkış Yap
      </Button>
    </Root>
  );
};

SubCardContent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default SubCardContent;
