import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import EntiLogo from "../../../Images/Home/EntiLogo.svg";
import RomansLogo from "../../../Images/Home/RomansLogo.svg";
import DekorEntiLogo from "../../../Images/Home/DekorEntiLogo.svg";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: 80,
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: theme.spacing(),
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  width: "100%",
  padding: 0,
  marginBottom: theme.spacing(0.5),
  fontSize: 14,
  fontWeight: 400,
  textAlign: "left",
}));

const BrandContainer = styled("div")(({ theme }) => ({
  width: innerWidth * 0.8,
  minWidth: 280,
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const MobileBrands = () => {
  return (
    <StyledContainer>
      <StyledHeader>Markalar</StyledHeader>
      <BrandContainer>
        <img src={EntiLogo} alt="" width="57" height="20" />
        <img src={RomansLogo} alt="" width="93.3" height="20" />
        <img src={DekorEntiLogo} alt="" width="68" height="20" />
      </BrandContainer>
    </StyledContainer>
  );
};

export default MobileBrands;
