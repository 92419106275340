import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import leftArrowIcon from "../../../Images/Auth/left-arrow.svg";

const PREFIX = "HeaderRoute";

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  headerText: `${PREFIX}-headerText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 20.83,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 25.63,
    userSelect: "none",
    "&[component='forgot-password']": {
      marginBottom: 40.54,
    },
    "&[component='password-sent']": {
      marginBottom: 49.54,
    },
  },

  [`& .${classes.icon}`]: {
    marginLeft: 20.5,
    width: 24,
    height: 24,
    padding: 0,
  },

  [`& .${classes.headerText}`]: {
    fontSize: 14,
    fontWeight: 400,
    alignItems: "left",
    color: theme.palette.primary.main,
    marginLeft: 11.5,
  },
}));

const HeaderRoute = ({ handleRoute, children, component }) => {
  return (
    <Root className={classes.root} component={component}>
      <IconButton className={classes.icon} onClick={handleRoute} size="large">
        <img
          src={leftArrowIcon}
          width="9px"
          height="16.67px"
          alt="leftArrowIcon"
        />
      </IconButton>
      <div className={classes.headerText}>{children}</div>
    </Root>
  );
};

HeaderRoute.propTypes = {
  handleRoute: PropTypes.func,
  children: PropTypes.string,
  component: PropTypes.string,
};

export default HeaderRoute;
