import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import SpecialDemandsRadioArea from "../SpecialDemandsModal/SpecialDemandsRadioArea";
import SectionHeader from "../SpecialDemandsModal/SectionHeader";

const Root = styled("div")(() => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
}));

const ProductName = styled(Typography)(({theme}) => ({
  width: "100%",
  textAlign: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: theme.palette.secondary.main,
}));

const StyledContainer = styled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  paddingBottom: theme.spacing(1.5),
}));

const SelectionsMain = ({
  name,
  specialFeatureValues,
  features,
  handleFeatures,
}) => {
  return (
    <Root>
      <ProductName>{name}</ProductName>
      <SectionHeader>Şekil</SectionHeader>
      <StyledContainer>
        <SpecialDemandsRadioArea
          data={specialFeatureValues?.shapes}
          selectedValue={features?.shape}
          value="shape"
          handleFeatures={handleFeatures}
          features={features}
        />
      </StyledContainer>
      <SectionHeader>Kenar Dokuma</SectionHeader>
      <StyledContainer>
        <SpecialDemandsRadioArea
          data={specialFeatureValues?.sides}
          selectedValue={features?.side}
          value="side"
          handleFeatures={handleFeatures}
          features={features}
        />
      </StyledContainer>
      {features?.side === "Saçak" && (
        <>
          <SectionHeader>Saçak Tipi</SectionHeader>
          <StyledContainer>
            <SpecialDemandsRadioArea
              data={specialFeatureValues?.webTypes}
              selectedValue={features?.webType}
              value="webType"
              handleFeatures={handleFeatures}
              features={features}
            />
          </StyledContainer>
        </>
      )}
    </Root>
  );
};

SelectionsMain.propTypes = {
  name: PropTypes.string,
  specialFeatureValues: PropTypes.any,
  features: PropTypes.object,
  handleFeatures: PropTypes.func,
};

export default SelectionsMain;
