import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";

import grayTick from "../../../../../Images/Orders/GrayTick.svg";
import greenTick from "../../../../../Images/Orders/GreenTick.svg";
import yellowTick from "../../../../../Images/Orders/YellowTick.svg";
// import grayRadio from "../../../../../Images/Orders/GrayRadio.svg";
import RedTick from "../../../../../Images/Orders/RedTick.svg";

const PREFIX = "Stepper";

const classes = {
  stepperStep: `${PREFIX}-stepperStep`,
  stepHead: `${PREFIX}-stepHead`,
  tick: `${PREFIX}-tick`,
  stepperTitle: `${PREFIX}-stepperTitle`,
  stepContent: `${PREFIX}-stepContent`,
  content: `${PREFIX}-content`,
  lastStepHeader: `${PREFIX}-lastStepHeader`,
  customBox: `${PREFIX}-customBox`,
  followShipmentButton: `${PREFIX}-followShipmentButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({theme}) => ({
  [`& .${classes.stepperStep}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.stepHead}`]: {
    display: "flex",
  },

  [`& .${classes.tick}`]: {
    marginRight: 10,
    width: 16,
    height: 16,
    "&[is-radio='true']": {
      width: 20,
      height: 20,
      marginLeft: -2,
      marginTop: -2,
    },
  },

  [`& .${classes.stepperTitle}`]: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.darkGray.main,
    "&[first-step-completed='true']": {
      color: "#5ABD8C",
    },
    "&[first-step-completed='statuszero']": {
      color: "#F5B041",
    },
    "&[second-step-completed='true']": {
      color: "#5ABD8C",
    },
    "&[second-step-completed='preparing']": {
      color: "#F5B041",
    },
    "&[third-step-completed='true']": {
      color: "#5ABD8C",
    },
    "&[third-step-completed='preparing']": {
      color: "#F5B041",
    },
    "&[is-canceled='true']": {
      color: "#FB0505",
    },
  },

  [`& .${classes.stepContent}`]: {
    minHeight: 30,
    height: "auto",
    paddingLeft: 8,
    marginTop: -2,
  },

  [`& .${classes.content}`]: {
    borderLeft: "1px solid #B1AEAE",
    minHeight: 30,
    height: "auto",
    paddingBottom: 10,
    "&[first-step-completed='true']": {
      borderLeft: "1px solid #5ABD8C",
    },
    "&[first-step-completed='statuszero']": {
      borderLeft: "1px solid #F5B041",
    },
    "&[second-step-completed='true']": {
      borderLeft: "1px solid #5ABD8C",
    },
    "&[second-step-completed='preparing']": {
      borderLeft: "1px solid #F5B041",
    },
    "&[third-step-completed='true']": {
      borderLeft: "1px solid #5ABD8C",
    },
    "&[third-step-completed='preparing']": {
      borderLeft: "1px solid #F5B041",
    },
  },

  [`& .${classes.lastStepHeader}`]: {
    fontSize: 12,
    fontWeight: 700,
    color: "#B1AEAE",
  },

  [`& .${classes.customBox}`]: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 4,
    wordBreak: "break-all",
    width: 200,
    fontSize: 12,
    paddingLeft: 18,
    paddingTop: 5,
    paddingBottom: 10,
    maxHeight: 70,
    "&[first-step-completed='true']": {
      color: "#5ABD8C",
    },
    "&[second-step-completed='true']": {
      color: "#5ABD8C",
    },
    "&[second-step-completed='preparing']": {
      color: "#F5B041",
    },
    "&[third-step-completed='true']": {
      color: "#5ABD8C",
    },
    "&[third-step-completed='preparing']": {
      color: "#F5B041",
    },
  },

  [`& .${classes.followShipmentButton}`]: {
    width: 100,
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: 700,
    textTransform: "none",
    borderRadius: 6,
    marginTop: 10,
    marginBottom: 5,
    color: "#fff",
    marginLeft: 18,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const Stepper = ({ status }) => {
  return (
    <Root>
      <div className={classes.stepperStep}>
        <div className={classes.stepHead}>
          <img
            src={status >= 0 ? greenTick : grayTick}
            alt=""
            className={classes.tick}
          />
          <Typography
            className={classes.stepperTitle}
            first-step-completed={status >= 0 ? "true" : "false"}>
            Sipariş Alındı
          </Typography>
        </div>
        <div className={classes.stepContent}>
          <div
            className={classes.content}
            first-step-completed={status >= 0 ? "true" : "false"}>
            <Box
              component="div"
              overflow="hidden"
              whiteSpace="pre-line"
              textOverflow="ellipsis"
              // classes={{ root: classes.customBox }}
              className={classes.customBox}
              first-step-completed={status >= 0 ? "true" : "false"}>
              {/* Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum lorem Lorem ipsum
              lorem Lorem ipsum lorem Lorem ipsum lorem Lorem ipsum lorem Lorem
              ipsum lorem */}
            </Box>
          </div>
        </div>
      </div>
      {status !== 3 ? (
        <>
          <div className={classes.stepperStep}>
            <div className={classes.stepHead}>
              <img
                src={
                  status >= 1 ? greenTick : status === 0 ? yellowTick : grayTick
                }
                alt=""
                className={classes.tick}
              />
              <Typography
                className={classes.stepperTitle}
                second-step-completed={
                  status >= 1 ? "true" : status === 0 ? "preparing" : "false"
                }>
                Hazırlanıyor
              </Typography>
            </div>
            <div className={classes.stepContent}>
              <div
                className={classes.content}
                second-step-completed={
                  status >= 1 ? "true" : status === 0 ? "preparing" : "false"
                }>
                <Box
                  component="div"
                  overflow="hidden"
                  whiteSpace="pre-line"
                  textOverflow="ellipsis"
                  // classes={{ root: classes.customBox }}
                  className={classes.customBox}
                  second-step-completed={
                    status >= 1 ? "preparing" : status > 1 ? "true" : "false"
                  }>
                  {/* Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum lorem Lorem ipsum
              lorem Lorem ipsum lorem Lorem ipsum lorem Lorem ipsum lorem Lorem
              ipsum lorem */}
                </Box>
              </div>
            </div>
          </div>
          <div className={classes.stepperStep}>
            <div className={classes.stepHead}>
              <img
                src={
                  status === 2
                    ? greenTick
                    : status === 1
                    ? yellowTick
                    : grayTick
                }
                alt=""
                className={classes.tick}
              />
              <Typography
                className={classes.stepperTitle}
                third-step-completed={
                  status === 2 ? "true" : status === 1 ? "preparing" : "false"
                }>
                Hazırlandı
              </Typography>
            </div>
            {/* <div className={classes.stepContent}>
              <div
                className={classes.content}
                third-step-completed={status === 2 ? "true" : "false"}>
                <Box
                  component="div"
                  overflow="hidden"
                  whiteSpace="pre-line"
                  textOverflow="ellipsis"
                  // classes={{ root: classes.customBox }}
                  className={classes.customBox}
                  third-step-completed={status === 2 ? "true" : "false"}>
                  Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum lorem Lorem
                  ipsum lorem Lorem ipsum lorem Lorem ipsum lorem Lorem ipsum
                  lorem Lorem ipsum lorem
                </Box>
                {status >= 6 && status !== 7 && (
                  <Button
                    variant="contained"
                    aria-controls="example-panel"
                    aria-expanded={height !== 0}
                    className={classes.followShipmentButton}
                    disableRipple
                    onClick={toggleShipmentInfo}>
                    Kargo Takip
                  </Button>
                )}
              </div> 
            </div>*/}
          </div>
          {/* <div className={classes.stepperStep}>
            <div className={classes.stepHead}>
              <img
                src={grayRadio}
                alt=""
                className={classes.tick}
                is-radio="true"
              />
              <Typography className={classes.lastStepHeader}>
                Teslim Edildi
              </Typography>
            </div>
          </div> */}
        </>
      ) : (
        <div className={classes.stepperStep}>
          <div className={classes.stepHead}>
            <img src={RedTick} alt="" className={classes.tick} />
            <Typography className={classes.stepperTitle} is-canceled="true">
              İptal Edildi
            </Typography>
          </div>
        </div>
      )}
    </Root>
  );
};

Stepper.propTypes = {
  status: PropTypes.number,
};

export default Stepper;
