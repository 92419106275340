import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

const PREFIX = "QuantitySelection";

const classes = {
  select: `${PREFIX}-select`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  adornment: `${PREFIX}-adornment`,
};

const StyledFormControl = styled(FormControl)(({theme}) => ({
  [`& .${classes.select}`]: {
    borderRadius: 3,
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.darkGray.light}`,
    height: 33,
    width: 110,
    "&[used-in='fast-order']": {
      height: 40,
      width: 138,
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "#fff",
    },
    "& .MuiSelect-root, .MuiSelect-select": {
      padding: "0px 24px 0px 0px",
      borderRadius: 8,
      backgroundColor: "#fff",
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      "&:hover, &:focus, &:active": {
        backgroundColor: "transparent",
      },
    },
  },

  [`& .${classes.menu}`]: {
    width: 105,
    height: "auto",
    borderRadius: 8,
    marginTop: 8,
    maxHeight: 80,
    "&.MuiList-root, .MuiMenu-list, .MuiList-padding": {
      padding: 0,
    },
  },

  [`& .${classes.menuItem}`]: {
    height: 21,
    "&.MuiListItem-root, .Mui-selected, .Mui-selected:hover": {
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#fff",
      borderBottom: "1px solid rgba(231, 231, 231, 1)",
      fontSize: 13,
      fontWeight: 400,
      color: "rgba(142, 142, 142, 1)",
      "&:hover, &:focus, &:active": {
        fontSize: 15,
        fontWeight: 500,
        color: "rgba(127, 91, 120, 1)",
        backgroundColor: "#fff",
      },
    },
    "&.css-1l53d7f-MuiButtonBase-root-MuiMenuItem-root": {
      display: "flex",
      justifyContent: "center",
    },
  },

  [`& .${classes.adornment}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    paddingLeft: 6,
    userSelect: "none",
  },
}));

const QuantitySelection = ({
  handleChange,
  param,
  selectedValue,
  component,
  // stockInfo,
}) => {
  let initialArr = [1, 2, 3, 4, 5];

  // const [valueArr, setValueArr] = useState(initialArr);

  // useEffect(() => {
  //   let active = true;
  //   if (active) {
  //     let limit = stockInfo > 5 || +stockInfo === 0 ? 5 : stockInfo;
  //     const values = Array.from({ length: limit }, (_, i) => i + 1);
  //     setValueArr(values);
  //   }
  //   return () => {
  //     active = false;
  //   };
  // }, [stockInfo]);

  return (
    <StyledFormControl>
      <Select
        value={selectedValue || 1}
        onChange={handleChange(param)}
        className={classes.select}
        used-in={component}
        startAdornment={
          <InputAdornment position="start">
            <div className={classes.adornment}>Adet:</div>
          </InputAdornment>
        }
        MenuProps={{
          classes: { paper: classes.menu },
          style: {
            width: component === "fast-order" && 168,
            marginTop: component === "fast-order" && 5,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}>
        {initialArr.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            className={classes.menuItem}
            disableRipple>
            {item}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

QuantitySelection.propTypes = {
  handleChange: PropTypes.func,
  param: PropTypes.string,
  selectedValue: PropTypes.number,
  component: PropTypes.string,
  stockInfo: PropTypes.any,
};

export default QuantitySelection;
