import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import SectionHeaderMob from "../../../Views/Common/Sections/SectionHeaderMob";
import { customerActions } from "../../../Redux/Actions";
import MobileProductCard from "../../ProductCard/MobileProductCard";

const innerWidth = window.innerWidth;
const innerHeight = window.innerHeight;

const Root = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: innerHeight - 360,
  overflowY: "auto",
  overflowX: "hidden",
  padding: 0,
  paddingBottom: theme.spacing(),
  marginTop: theme.spacing(),
}));

const Trends = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const trendProducts = useSelector(({ product }) => product.newArrivals);

  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (value) => {
    let query = { ...filterQuery, tabValue: value };
    updateFilters(query);
    history.push("/filters-page");
  };

  return (
    <>
      <SectionHeaderMob
        header="Yeni Gelenler"
        handleClick={goToFilters}
        value={4}
      />
      <Root container spacing={2}>
        {trendProducts &&
          trendProducts.map((item, index) => (
            <Grid
              key={index}
              item
              xs={6}
              style={{
                padding: 0,
                paddingLeft: innerWidth < 350 ? 8 : 16,
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 16,
              }}>
              <MobileProductCard item={item} key={index} noMargin />
            </Grid>
          ))}
      </Root>
    </>
  );
};

export default Trends;
