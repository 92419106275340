import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

import grayCheckbox from "../../../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../../../Images/Checked.svg";

const PREFIX = "SubSectionOptions2";

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  iconButton: `${PREFIX}-iconButton`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
    minHeight: 38,
    height: "auto",
    padding: "10px 0px 0px 50px",
  },

  [`& .${classes.formControl}`]: {
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      marginLeft: 5,
    },
  },

  [`& .${classes.iconButton}`]: {
    height: 30,
    width: 30,
    marginRight: 20,
  },
}));

const SubSectionOptions2 = ({
  sub2Values,
  handleSection2,
  checkBoxSections,
}) => {
  return (
    <Root className={classes.root} sub-section-options="true">
      <Grid container spacing={2}>
        {checkBoxSections.subSection2.subSectionOptions.map((item, index) => {
          return (
            <Grid
              item
              xs={3}
              lg={3}
              key={index}
              style={{ paddingLeft: 8, paddingTop: 8 }}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormControlLabel
                  style={{ marginBottom: 4 }}
                  control={
                    <Checkbox
                      className={classes.check}
                      disableRipple
                      checked={sub2Values[item]}
                      onChange={handleSection2(item)}
                      id={item}
                      name={item}
                      value={sub2Values[item]}
                      icon={<img src={grayCheckbox} width="20px" alt="" />}
                      checkedIcon={<img src={Checked} width="20" alt="" />}
                    />
                  }
                  label={item}
                />
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </Root>
  );
};

SubSectionOptions2.propTypes = {
  sub2Values: PropTypes.object,
  handleSection2: PropTypes.func,
  checkBoxSections: PropTypes.object,
};

export default SubSectionOptions2;
