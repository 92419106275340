import { addressConstants, generalConstants } from "../../Utils/Constants";
import { addressService } from "../../Services";
import { authActions } from "./AuthActions";
import { customerActions } from "./CustomerActions";
import { toast } from "react-toastify";

const getAddresses = (query) => {
  return (dispatch) => {
    dispatch(request());
    addressService.getAddresses(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch(success(res.data.addresses));
        }
      },
      (error) => {
        dispatch(failure);
      }
    );
  };
  function request() {
    return { type: addressConstants.GET_ADDRESSES_REQUEST };
  }
  function success(data) {
    return {
      type: addressConstants.GET_ADDRESSES_SUCCESS,
      payload: data,
    };
  }
  function failure() {
    return { type: addressConstants.GET_ADDRESSES_FAILURE };
  }
};

const clearAddresses = () => {
  return (dispatch) => {
    dispatch({ type: addressConstants.CLEAR_ADDRESSES });
  };
};

const toggleDefault = (isAdmin, query, id, address) => {
  return async (dispatch) => {
    dispatch(request());
    const response = isAdmin
      ? await addressService.toggleDefaultAddress(query, id)
      : await addressService.selfToggleAddress(query);

    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      dispatch(success());
      dispatch(customerActions.updateCustomerAddressByToggle(address));
      dispatch(authActions.updateAddressByToggle(address));
    } else {
      toast.error(response?.data?.message);
      dispatch(failure());
    }
  };
  function request() {
    return { type: addressConstants.TOGGLE_ADDRESS_REQUEST };
  }
  function success() {
    return { type: addressConstants.TOGGLE_ADDRESS_SUCCESS };
  }
  function failure() {
    return { type: addressConstants.TOGGLE_ADDRESS_FAILURE };
  }
};

const setNewAddressId = id => {
  return {type: addressConstants.TOGGLE_ADDRESS_ID, id}
}

export const addressActions = {
  getAddresses,
  clearAddresses,
  toggleDefault,
  setNewAddressId
};
