import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import Typography from "@mui/material/Typography";
import { getFirstLetters } from "../../Utils/Helpers";

import { userData } from "../../Redux/Selectors";
import PageContainerMob from "../Common/PageContainerMob";

import userSettingsIcon from "../../Images/DraggableCard/UserSettings.svg";
import addGroupIcon from "../../Images/DraggableCard/AddGroup.svg";
import userChangeIcon from "../../Images/DraggableCard/UserChange.svg";
import walletIcon from "../../Images/DraggableCard/Wallet.svg";
import invoiceIcon from "../../Images/DraggableCard/Invoice.svg";
import refundIcon from "../../Images/DraggableCard/Refund.svg";
import pieChartIcon from "../../Images/DraggableCard/PieChart.svg";
import bIcon from "../../Images/FooterImages/b-icon.svg";
import exitIcon from "../../Images/DraggableCard/ExitGray.svg";
import bell from "../../Images/TopNavbar/bell.svg";

import PopoverRow from "../../Ui/Popovers/ProfilePopover/PopoverRow";
import { authActions, bottomNavbarActions } from "../../Redux/Actions";
import { bottomNavItems } from "../../Utils/Constants";
import AdminAddressSelection from "../../Ui/Dialog/Mobile/AdminAddressSelection";

const innerWidth = window.innerWidth;
const innerHeight = window.innerHeight;

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: "100vh",
  backgroundColor: "#fff",
  padding: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  position: "relative",
  userSelect: "none",
  overflow: "hidden",
}));

const Head = styled("div")(({ theme }) => ({
  width: "100%",
  height: 48,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(),
  borderBottom: `0.5px solid ${theme.palette.darkGray.light}`,
}));

const Letters = styled("div")(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: theme.spacing(2.5),
}));

const LetterText = styled(Typography)(() => ({
  fontSize: 10,
  fontWeight: 700,
  color: "#fff",
  textTransform: "uppercase",
}));

const StyledUser = styled("div")(() => ({
  width: innerWidth - 100,
  height: 30,
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
}));

const UserName = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
  lineHeight: "16px",
  marginBottom: 2,
}));

const UserFirm = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "line",
})(({ theme, line }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: "#B1AEAE",
  lineHeight: "12px",
  marginRight: theme.spacing(),
  minWidth: !line && 70,
}));

const Bottom = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: innerHeight * 0.16,
  width: "95%",
  height: 26,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
}));

const BottomLeft = styled("div")(() => ({
  width: 150,
  display: "flex",
  alignItems: "center",
}));

const BottomText = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
  marginLeft: 10,
}));

const BottomRight = styled("div")(() => ({
  width: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  cursor: "pointer",
}));

const MenuPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useSelector(userData);
  const customer = useSelector(({ customer }) => customer);
  const authInfo = useSelector(({ auth }) => auth.customerInfo);

  const signOut = () => dispatch(authActions.signout());
  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  const [letters, setLetters] = useState(null);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [listArr, setListArr] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu(bottomNavItems.MENU);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo) {
      setLetters(getFirstLetters(userInfo?.first_name, userInfo?.last_name));
      if (customer?.isAdmin) {
        if (authInfo?.role_names[0] === "seller") {
          let newArr = profileButtonsArr.filter(
            (item) => item.text !== "Kullanıcı İşlemleri"
          );
          setListArr(newArr);
        } else {
          setListArr(profileButtonsArr);
        }
      } else {
        if (authInfo?.role_names[0] === "companyuser") {
          let newArr = profileButtonsArr.filter(
            (item) =>
              item.text !== "Adres Değiştir" &&
              item.text !== "Kullanıcı İşlemleri"
          );
          setListArr(newArr);
        } else {
          let newArr = profileButtonsArr.filter(
            (item) => item.text !== "Adres Değiştir"
          );
          setListArr(newArr);
        }
      }
    } else setLetters("B", "B");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, customer, authInfo]);

  const handleSignOut = () => {
    signOut();
    history.push("/signin-email");
  };

  const toggleAddressDialog = () => setOpenAddressDialog((prev) => !prev);

  const goToUsers = () => history.push("/users-and-roles");
  //   const goToServices = () => history.push("/services-and-returns");
  const goToProfile = () => history.push("./dealer-profile");
  const goToNotifications = () => history.push("./notifications");

  const profileButtonsArr = [
    {
      icon: userSettingsIcon,
      text: "Profil Ayarları",
      func: goToProfile,
      isLast: "false",
    },
    {
      icon: bell,
      text: "Bildirimler",
      func: goToNotifications,
      isLast: "false",
    },
    {
      icon: addGroupIcon,
      text: "Kullanıcı İşlemleri",
      func: goToUsers,
      isLast: "false",
    },
    {
      icon: userChangeIcon,
      text: "Adres Değiştir",
      func: toggleAddressDialog,
      isLast: "false",
    },
    {
      icon: walletIcon,
      text: "Ödeme Yap",
      func: null,
      isLast: "false",
    },
    {
      icon: refundIcon,
      text: "İade ve Arızalı Ürün İşlemleri",
      func: null,
      isLast: "false",
    },
    {
      icon: invoiceIcon,
      text: "Hesap Ekstresi",
      func: null,
      isLast: "false",
    },
    {
      icon: pieChartIcon,
      text: "Raporlar",
      func: null,
      isLast: "true",
    },
  ];

  const goToHome = () => history.push("./home");

  if (!isMobileOnly) {
    return <>{goToHome()}</>;
  }

  return (
    <PageContainerMob>
      <StyledContainer>
        <Head>
          <div style={{ display: "flex" }}>
            <Letters>
              <LetterText>{letters && letters}</LetterText>
            </Letters>
            <StyledUser>
              <UserName>{userInfo?.full_name}</UserName>
              <div style={{ display: "flex" }}>
                <UserFirm noWrap>{customer?.currentCompany?.name}</UserFirm>
                <UserFirm line>-</UserFirm>
                <UserFirm noWrap>{customer?.currentAddress?.title}</UserFirm>
              </div>
            </StyledUser>
          </div>
        </Head>
        {listArr?.map((item, index) => (
          <PopoverRow
            key={index}
            icon={item.icon}
            text={item.text}
            func={item.func}
            isLast={item.isLast}
            mobile
          />
        ))}
        <Bottom>
          <BottomLeft>
            <img src={bIcon} width="16px" height="16px" alt="" />
            <BottomText>Bufi</BottomText>
          </BottomLeft>
          <BottomRight onClick={handleSignOut}>
            <img src={exitIcon} width="12px" height="13.33px" alt="" />
            <BottomText exit-text="true">Çıkış</BottomText>
          </BottomRight>
        </Bottom>
      </StyledContainer>
      <AdminAddressSelection
        open={openAddressDialog}
        handleClose={toggleAddressDialog}
        callBack={toggleAddressDialog}
      />
    </PageContainerMob>
  );
};

export default MenuPage;
