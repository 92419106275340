import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "24px",
  color: theme.palette.secondary.main,
}));

const TitleContainer = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const TitleComponent = ({ title, func }) => {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      {func && (
        <IconButton onClick={func}>
          <DeleteIcon />
        </IconButton>
      )}
    </TitleContainer>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.string,
  func: PropTypes.func,
};

export default TitleComponent;
