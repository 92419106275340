import {
  orderConstants,
  orderReducerInitialState,
} from "../../Utils/Constants";

const initialState = {
  orders: [],
  loading: false,
  error: false,
  success: false,
  note: "",
  totalCount: 0,
};
const orders = (state = initialState, action) => {
  switch (action.type) {
    case orderConstants.GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.GET_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case orderConstants.GET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        totalCount: action.payload.totalCount,
        loading: false,
      };
    case orderConstants.INITIALIZE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case orderConstants.INITIALIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case orderConstants.INITIALIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case orderConstants.INITIALIZE_CLEAR:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        note: "",
      };
    case orderConstants.SAVE_NOTE:
      return {
        ...state,
        note: action.note,
      };
    case orderConstants.CLEAR_ORDERS:
      return orderReducerInitialState;
    default:
      return state;
  }
};

export { orders };
