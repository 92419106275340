import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import { customerActions } from "../../../Redux/Actions";

import Header from "./Header";
import Section from "./Section";
import { queryMaker } from "../../../Utils/Helpers";

import WordSearch from "./WordSearch";

const PREFIX = "FiltersMain";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    maxWidth: "18%",
    width: "18%",
    height: "100%",
    display: "flex",
    borderRadius: 8,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minWidth: 220,
    marginRight: 19,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.16)",
    backgroundColor: "white",
  },
}));

const FiltersMain = () => {
  const dispatch = useDispatch();
  const setFilters = (query) => dispatch(customerActions.setFilters(query));
  const setText = (text) => dispatch(customerActions.setSearchText(text));

  const [keyword, setKeyword] = useState("");

  const checkboxItems = useSelector(({ filterValues }) => filterValues);
  const filterText = useSelector(({ customer }) => customer.filters.searchText);
  // const tabValue = useSelector(({ customer }) => customer.filters.tabValue);
  // let items = checkboxItems ? checkboxItems[value] : null;

  useEffect(() => {
    let active = true;
    if (active) {
      setKeyword(filterText);
    }
    return () => {
      active = false;
    };
  }, [filterText]);

  const handleChange = (prop) => (event) => {
    let obj = queryMaker(event.target, prop);
    setFilters(obj);
  };

  const handleSearchText = (text) => setText(text);

  const clearSearchText = () => {
    setText("");
    setKeyword("");
  };
  const handleKeyword = (e) => setKeyword(e.target.value);

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setText(e.target.value);
    }
  };

  return (
    <Root className={classes.root}>
      <Header />
      <WordSearch
        keyword={keyword}
        handleKeyword={handleKeyword}
        keyPress={keyPress}
        clearSearchText={clearSearchText}
        handleSearchText={handleSearchText}
        // disabled={tabValue === 4}
      />
      <Section
        header="Markalar"
        handleChange={handleChange}
        value="brands"
        filter="Marka"
        items={checkboxItems ? checkboxItems["brands"] : null}
        // disabled={tabValue === 4}
      />
      <Section
        header="Kategoriler"
        handleChange={handleChange}
        value="categories"
        filter="Kategori"
        items={checkboxItems ? checkboxItems["categories"] : null}
        // disabled={tabValue === 4}
      />
      <Section
        header="Ebatlar"
        handleChange={handleChange}
        value="sizes"
        filter="Ebat"
        items={checkboxItems ? checkboxItems["sizes"] : null}
        // disabled={tabValue === 4}
      />
      <Section
        header="Renkler"
        handleChange={handleChange}
        value="colors"
        filter="Renk"
        items={checkboxItems ? checkboxItems["colors"] : null}
        // disabled={tabValue === 4}
      />
      <Section
        header="Şekil"
        handleChange={handleChange}
        value="shapes"
        filter="Şekil"
        items={checkboxItems ? checkboxItems["shapes"] : null}
        // disabled={tabValue === 4}
      />
      <Section
        header="Değerlendirme Puanı"
        rating
        last
        handleChange={handleChange}
        value="rating"
        filter="Ürün Değerlendirme"
        items={checkboxItems ? checkboxItems["rating"] : null}
        // disabled={tabValue === 4}
      />
    </Root>
  );
};

export default FiltersMain;
