import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import PageContainer from "../Common/PageContainer";
import TabButtons from "./Main/TabButtons";
import Orders from "./Main/Orders";
import OrderDetails from "./Main/OrderDetails";

const StyledContainer = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: 600,
  marginBottom: theme.spacing(4),
}));

const OrderPage = () => {
  const [tabIndex, setIndex] = useState(1);

  return (
    <PageContainer>
      <StyledContainer>
        <TabButtons index={tabIndex} setIndex={setIndex} />
        {tabIndex === 1 ? <Orders /> : <OrderDetails />}
      </StyledContainer>
    </PageContainer>
  );
};

export default OrderPage;
