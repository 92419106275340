import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import TrashGrey from "../../Images/Cart/TrashGrey.svg";

import {
  productActions,
  orderActions,
  customerActions,
} from "../../Redux/Actions";

import CartDeleteConfirmation from "../../Ui/Dialog/CartDeleteConfirmation";
import CartProductMob from "../../Ui/CartProduct/CartProductMob";
import ErrorDialog from "./Dialogs/ErrorDialog";
import SuccessDalog from "./Dialogs/SuccessDialog";
import Loading from "../../Utils/Loading";

import CartAddressSelection from "./MobileDialogs/CartAddressSelection";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: innerWidth,
  height: "100vh",
  position: "relative",
  overflow: "hidden",
}));

const StyledContainer = styled("div")(({ theme }) => ({
  width: innerWidth,
  height: 45,
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  // marginBottom: theme.spacing(),
  position: "relative",
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.secondary.main,
  marginLeft: theme.spacing(),
  lineHeight: "20px",
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  width: 30,
  height: 30,
  right: theme.spacing(2),
  top: theme.spacing(),
}));

const BottomPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 50,
  position: "absolute",
  bottom: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: "white",
  zIndex: 2,
}));

const CostContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));

const TotalCost = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 500,
  color: theme.palette.darkGray.main,
  marginRight: text && theme.spacing(),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: 40,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#fff",
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const AddressArea = styled("div")(({ theme }) => ({
  width: "100%",
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(),
  paddingRight: 0,
  paddingLeft: theme.spacing(2),
}));

const AddressField = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const AddressValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: text ? 12 : 16,
  fontWeight: text ? 300 : 400,
  lineHeight: text ? "16px" : "18px",
  color: theme.palette.darkGray.main,
}));

const AddressRouteButton = styled(Button)(() => ({
  textTransform: "none",
  backgroundColor: "#8692A6",
  color: "#fff",
  borderRadius: 0,
  height: 30,
  width: 180,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "18px",
  "&:hover": {
    backgroundColor: "#8692A6",
  },
}));

const StyledTextField = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  minHeight: 24,
  border: "none",
  fontFamily: "Roboto",
  padding: 8,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 0,
  outline: "none",
  color: theme.palette.primary.main,
  backgroundColor: "#EAFDF3",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  "&:hover, &:focus, &:active": {
    border: "none",
    outline: "none",
  },
  "&::placeholder": {
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: 400,
    color: theme.palette.primary.main,
    paddingTop: 3,
  },
}));

const CartPageMob = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cart = useSelector(({ product }) => product.cart);
  const customer = useSelector(({ customer }) => customer);
  const orderLoading = useSelector(({ orders }) => orders.loading);
  const success = useSelector(({ orders }) => orders.success);
  const error = useSelector(({ orders }) => orders.error);
  const products = useSelector(({ product }) => product.cart.products);
  const note = useSelector(({ orders }) => orders.note);

  const deleteProduct = (id, key) =>
    dispatch(productActions.deleteProduct(id, key));

  const deleteAdminProduct = (userId, productId, key) =>
    dispatch(productActions.deleteAdminProduct(userId, productId, key));

  const clearCart = () => dispatch(productActions.clearCart());

  const clearAdminCart = (userId) =>
    dispatch(productActions.clearAdminCart(userId));

  const initializeOrder = (query) =>
    dispatch(orderActions.initializeOrderOpenAccount(query));
  const initializeAdminOrder = (query, id) =>
    dispatch(orderActions.initializeOrderOpenAccountAdmin(query, id));
  const saveNote = (note) => dispatch(orderActions.saveNote(note));

  const clearStatus = () => dispatch(orderActions.clearStatus());
  const reStoreDefaultAddress = () =>
    dispatch(customerActions.restoreDefaultAddress());

  const [openDelete, setOpenDelete] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [openAddressSelection, setOpenAddressSelection] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    let active = true;
    if (active) {
      if (note) {
        if (note !== text) {
          setText(note);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (success) {
        setSuccessDialog(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (error) {
        setErrorDialog(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = ({ target }) => {
    const val = target.value
    if(val.length >= 50) return
    setText(val);
  } 

  const handleSuccessDialog = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/order-page");
  };

  const closeSuccessDialog = () => {
    reStoreDefaultAddress();
    setSuccessDialog(false);
  };
  const closeErrorDialog = () => {
    reStoreDefaultAddress();
    setErrorDialog(false);
  };

  const handleErrorDialog = () => {
    clearStatus();
    closeErrorDialog();
  };

  const goToHome = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/home");
  };

  const placeOrderCart = (text) => {
    let query = {
      method_id: 4,
      address_id: customer?.currentAddress?.id,
      installment: 1,
      note: text,
      extra_data: {},
      source: 1,
    };

    if (customer?.isAdmin) {
      let adminQuery = {
        ...query,
        user_id: customer?.selectedUser?.id,
        company_id: customer?.currentCompany?.id,
      };
      initializeAdminOrder(adminQuery, customer?.selectedUser?.id);
    } else {
      initializeOrder(query);
    }
  };

  const emptyCart = () => {
    if (cart.products.length > 0) {
      if (customer?.isAdmin) {
        clearAdminCart(customer?.selectedUser?.id);
      } else {
        clearCart();
      }
      toggleDeleteDialog();
    } else {
      return;
    }
  };

  const deleteSingleProduct = (userId, productId, key) => {
    if (customer?.isAdmin) {
      deleteAdminProduct(userId, productId, key);
    } else {
      deleteProduct(productId, key);
    }
  };

  const goBack = () => {
    saveNote(text);
    history.goBack();
  };
  const toggleDeleteDialog = () => setOpenDelete((prev) => !prev);

  const toggleAddressDialog = () => setOpenAddressSelection((prev) => !prev);

  const singleAccount = () => {
    let paymentMethods = customer?.selectedUser?.company?.payment_methods;
    if (!paymentMethods || paymentMethods?.length === 0) {
      return "NoPaymentMethod";
    } else if (paymentMethods.length === 1 && paymentMethods[0].id === 4) {
      return "OpenAccount";
    }
    return "SeveralMethods";
  };

  const handleClick = () => {
    let isOnlyOpenAccount = singleAccount();

    if (products?.length === 0) {
      toast.warn("Sepetinizde ürün bulunmamaktadır");
      return;
    }

    if (isOnlyOpenAccount === "NoPaymentMethod") {
      toast.warn("Hesabınıza tanımlı ödeme yöntemi bulunmamaktadır!");
      return;
    }

    if (isOnlyOpenAccount === "OpenAccount") {
      placeOrderCart(text);
    } else {
      saveNote(text);
      history.push("/checkout");
    }
  };

  return (
    <>
      <Root>
        <Loading open={orderLoading} />
        <StyledContainer>
          <IconButton
            onClick={goBack}
            style={{ paddingLeft: 0, width: 24, height: 24 }}>
            <ArrowBackIcon />
          </IconButton>
          <Header>{`Sepetim (${cart?.order.total_quantity || 0} Ürün)`}</Header>
          <DeleteButton
            disabled={
              !cart?.order.total_quantity || cart?.order.total_quantity === 0
            }
            onClick={toggleDeleteDialog}>
            <img src={TrashGrey} height="19" alt="" />
          </DeleteButton>
        </StyledContainer>
        <AddressArea>
          <AddressField>
            <AddressValue>{customer?.currentAddress?.name}</AddressValue>
            <AddressValue text>Geçerli Adres</AddressValue>
          </AddressField>
          <AddressRouteButton
            onClick={toggleAddressDialog}
            endIcon={<ArrowForwardIosIcon style={{ fontSize: 14 }} />}>
            Farklı Adrese Gönder
          </AddressRouteButton>
        </AddressArea>
        <StyledTextField
          maxRows={4}
          value={text}
          aria-label="maximum height"
          placeholder="Sipariş Notunuzu buraya ekleyebilirsiniz"
          onChange={handleChange}
        />
        <div
          style={{
            padding: 0,
            paddingBottom: 40,
            height: "90vh",
            overflow: "scroll",
          }}>
          {cart && cart?.products.length > 0
            ? cart.products.map((item, index) => (
                <CartProductMob
                  product={item}
                  deleteProduct={deleteSingleProduct}
                  key={index}
                />
              ))
            : null}
        </div>
        <BottomPart>
          <CostContainer>
            <TotalCost text>Tutar: </TotalCost>
            <TotalCost>{cart?.order.total_cost || 0}</TotalCost>
          </CostContainer>
          <SubmitButton onClick={handleClick} variant="contained">
            Siparişi Onayla
          </SubmitButton>
        </BottomPart>
      </Root>
      <CartDeleteConfirmation
        open={openDelete}
        onClose={toggleDeleteDialog}
        deleteCart={emptyCart}
        mobile
      />
      <SuccessDalog
        successDialog={successDialog}
        goToHome={goToHome}
        handleSuccessDialog={handleSuccessDialog}
        currentAddress={customer.currentAddress}
      />
      <ErrorDialog
        errorDialog={errorDialog}
        handleErrorDialog={handleErrorDialog}
      />
      <CartAddressSelection
        open={openAddressSelection}
        onClose={toggleAddressDialog}
      />
    </>
  );
};

export default CartPageMob;
