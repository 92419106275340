import React from "react";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";

import { styled } from "@mui/material/styles";

import Header from "./Header";
import Links from "./Links";

const StyledContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "dataHeader",
})(({ theme, dataHeader }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingLeft: dataHeader && !isMobileOnly ? theme.spacing(2) : 0,
}));

const Column = ({ data, header }) => {
  return (
    <StyledContainer dataHeader={header === "ÖzSafir Halıcılık" ? true : false}>
      <Header>{header}</Header>
      {data.map((d, index) => (
        <Links key={index} text={d.name} path={d.path} />
      ))}
    </StyledContainer>
  );
};

Column.propTypes = {
  data: PropTypes.array,
  header: PropTypes.string,
};

export default Column;
