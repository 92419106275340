import React, { useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import { orderActions } from "../../../Redux/Actions";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(),
  paddingBottom: theme.spacing(),
}));

const OpenAccount = (props, ref) => {
  const dispatch = useDispatch();

  const customer = useSelector(({ customer }) => customer);

  const initializeOrder = (query) =>
    dispatch(orderActions.initializeOrderOpenAccount(query));

  const initializeAdminOrder = (query, id) =>
    dispatch(orderActions.initializeOrderOpenAccountAdmin(query, id));

  const placeOrder = (text) => {
    let query = {
      method_id: 4,
      address_id: customer?.currentAddress?.id,
      installment: 1,
      note: text,
      extra_data: {},
      source: 1,
    };

    if (customer?.isAdmin) {
      let adminQuery = {
        ...query,
        user_id: customer?.selectedUser?.id,
        company_id: customer?.currentCompany?.id,
      };
      initializeAdminOrder(adminQuery, customer?.selectedUser?.id);
    } else {
      initializeOrder(query);
    }
  };

  useImperativeHandle(ref, (text) => ({
    placeOrder: (text) => {
      placeOrder(text);
    },
  }));
  return <Root></Root>;
};

export default React.forwardRef(OpenAccount);
