import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";

import MobileDialogHeader from "../../../Ui/Dialog/Tools/MobileDialogHeader";
import RatingDialog from "./RatingDialog";

import { Transition } from "../../../Utils/Tools";
import { mockRatings } from "../../../Utils/Constants";

const innerWidth = window.innerWidth;

const StyledContainer = styled("div")(() => ({
  width: innerWidth,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  position: "relative",
}));

const TabContainer = styled("div")(() => ({
  width: innerWidth,
  height: 35,
  display: "flex",
}));

const TabButton = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selectedButton",
})(({ selectedButton, theme }) => ({
  fontSize: 14,
  fontWeight: selectedButton ? 400 : 300,
  color: selectedButton
    ? theme.palette.primary.main
    : theme.palette.darkGray.main,
  width: innerWidth / 2,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderBottom: selectedButton ? "2px solid #6E3564" : `1px solid ${theme.palette.darkGray.light}`,
  touchAction: "none",
  WebkitTapHighlightColor: "transparent",
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
}));

const Date = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 400,
  lineHeight: "16px",
  color: "#BDBDBD",
  marginBottom: theme.spacing(0.5),
  paddingLeft: 1,
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: "#B1AEAE",
  marginBottom: theme.spacing(),
  paddingLeft: 2,
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "calc(100vh - 80px)",
  overflow: "scroll",
  paddingBottom: theme.spacing(5),
  position: "relative",
}));

const StyledButton = styled(Button)(({theme}) => ({
  width: innerWidth,
  height: 26,
  position: "absolute",
  bottom: 0,
  borderRadius: 0,
  backgroundColor: theme.palette.darkGray.main,
  color: "#fff",
  textTransform: "none",
  display: "flex",
  fontSize: "10",
  fontWeight: 700,
  lineHeight: "16px",
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const Assessments = ({ open, handleClose, data }) => {
  const [openDialog, set] = useState(false);

  const toggleDialog = () => set(!openDialog);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <StyledContainer>
        <MobileDialogHeader header={data?.name} callback={handleClose} />
        <TabContainer>
          <TabButton selectedButton>Değerlendirmeler</TabButton>
        </TabContainer>
        <ContentContainer>
          {mockRatings.map((item, index) => (
            <Row key={index}>
              <Date>{item.date}</Date>
              <Text>{item.text}</Text>
              <Rating
                name="size-small"
                value={item.point}
                size="small"
                precision={0.5}
                readOnly
              />
            </Row>
          ))}
        </ContentContainer>
        <StyledButton onClick={toggleDialog}>
          <StarIcon
            fontSize="10px"
            style={{ marginRight: 8, marginBottom: 2 }}
          />
          Değerlendir
        </StyledButton>
      </StyledContainer>
      <RatingDialog open={openDialog} handleClose={toggleDialog} />
    </Dialog>
  );
};

Assessments.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

export default Assessments;
