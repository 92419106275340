import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const PREFIX = "UserItemPopover";

const classes = {
  popoverRoot: `${PREFIX}-popoverRoot`,
  paper: `${PREFIX}-paper`,
  row: `${PREFIX}-row`,
  rowText: `${PREFIX}-rowText`,
};

const StyledPopover = styled(Popover)(({theme}) => ({
  [`&.${classes.popoverRoot}`]: {
    padding: 0,
  },

  [`& .${classes.paper}`]: {
    width: 280,
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.row}`]: {
    width: "100%",
    height: 44,
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
    paddingLeft: 16,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    userSelect: "none",
    cursor: "pointer",
    "&[last-row='true']": {
      borderBottom: "none",
    },
  },

  [`& .${classes.rowText}`]: {
    fontSize: 14,
    fontWeight: 14,
    color: "#333333",
  },
}));

const UserItemPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  showEditUser,
  user,
  handlePasswordRenew,
  status,
  handleActivationLink,
  showEditFirm,
}) => {
  const editInfoArr = ["Aktif", "İstek Gönderildi"];
  const activationLinkArr = ["Pasif", "İstek Gönderildi"];
  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={classes.popoverRoot}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={classes.paper}>
        {editInfoArr.includes(status) && (
          <>
            <div className={classes.row} onClick={showEditUser}>
              <Typography className={classes.rowText}>Bilgi Düzenle</Typography>
            </div>
            <div className={classes.row} onClick={showEditFirm}>
              <Typography className={classes.rowText}>
                Firma/Adres Değiştir
              </Typography>
            </div>
          </>
        )}
        {status === "Aktif" && (
          <div
            className={classes.row}
            last-row={user?.is_confirmed ? "true" : "false"}
            onClick={handlePasswordRenew}>
            <Typography className={classes.rowText}>
              Şifre yenileme linki gönder
            </Typography>
          </div>
        )}
        {activationLinkArr.includes(status) && (
          <div
            className={classes.row}
            last-row="true"
            onClick={handleActivationLink}>
            <Typography className={classes.rowText}>
              Aktivasyon linki gönder
            </Typography>
          </div>
        )}
      </div>
    </StyledPopover>
  );
};

UserItemPopover.propTypes = {
  id: PropTypes.any,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  showEditUser: PropTypes.func,
  user: PropTypes.object,
  handlePasswordRenew: PropTypes.func,
  status: PropTypes.string,
  handleActivationLink: PropTypes.func,
  showEditFirm: PropTypes.func,
};

export default UserItemPopover;
