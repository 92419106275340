import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TitleComponent from "./Common/TitleComponent";
import Section from "./Common/Section";

import { orderStatus } from "../../../../Utils/Constants";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(),
}));

const OrderStatus = ({ statusArr, handleStatus }) => {
  return (
    <Root>
      <TitleComponent title="Sipariş Durumu" />
      <Section data={orderStatus} callBack={handleStatus} values={statusArr} />
    </Root>
  );
};

OrderStatus.propTypes = {
  statusArr: PropTypes.array,
  handleStatus: PropTypes.func,
};

export default OrderStatus;
