import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const AddressArea = styled("div")(({ theme }) => ({
  width: "100%",
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(),
  paddingRight: 0,
  paddingLeft: theme.spacing(2),
}));

const AddressField = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const AddressValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: text ? 12 : 16,
  fontWeight: text ? 300 : 400,
  lineHeight: text ? "16px" : "18px",
  color: theme.palette.darkGray.main,
}));

const AddressRouteButton = styled(Button)(() => ({
  textTransform: "none",
  backgroundColor: "#8692A6",
  color: "#fff",
  borderRadius: 0,
  height: 30,
  width: 180,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "18px",
  "&:hover": {
    backgroundColor: "#8692A6",
  },
}));

const StyledTextField = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  minHeight: 24,
  border: "none",
  fontFamily: "Roboto",
  padding: 8,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 0,
  outline: "none",
  color: theme.palette.primary.main,
  backgroundColor: "#EAFDF3",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  "&:hover, &:focus, &:active": {
    border: "none",
    outline: "none",
  },
  "&::placeholder": {
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: 400,
    color: theme.palette.primary.main,
    paddingTop: 3,
  },
}));

const AddressAndNote = ({ text, toggleAddressDialog, handleChange }) => {
  const addressName = useSelector(
    ({ customer }) => customer?.currentAddress?.name
  );
  return (
    <>
      <AddressArea>
        <AddressField>
          <AddressValue>{addressName}</AddressValue>
          <AddressValue text>Geçerli Adres</AddressValue>
        </AddressField>
        <AddressRouteButton
          onClick={toggleAddressDialog}
          endIcon={<ArrowForwardIosIcon style={{ fontSize: 14 }} />}>
          Farklı Adrese Gönder
        </AddressRouteButton>
      </AddressArea>
      <StyledTextField
        maxRows={4}
        value={text}
        aria-label="maximum height"
        placeholder="Sipariş Notunuzu buraya ekleyebilirsiniz"
        onChange={handleChange}
      />
    </>
  );
};

AddressAndNote.propTypes = {
  text: PropTypes.string,
  toggleAddressDialog: PropTypes.func,
  handleChange: PropTypes.func,
};

export default AddressAndNote;
