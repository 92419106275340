import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { errorTexts } from "../../Utils/Constants";

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "creditCard",
})(({ theme, error, creditCard }) => ({
  width: "100%",
  height: creditCard ? 45 : 30.48,
  "& .MuiInputBase-root": {
    height: creditCard ? 45 : 30.48,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 8,
    height: creditCard ? 35 : 20,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 8,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[700],
  marginTop: 10,
  marginBottom: 5,
  marginLeft: 1,
  userSelect: "none",
}));

const StyledHelperText = styled(FormHelperText)(() => ({
  fontSize: 8,
  color: "red",
  userSelect: "none",
  "&.MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

const TextArea = ({
  label,
  handleChange,
  values,
  prop,
  error,
  multiline,
  rows,
  disabled,
  placeholder,
  creditCard,
}) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const regexpWhiteSpace = /[a-zA-Z]/g; 

  let errorStatus =
    prop === "email"
      ? !emailRegex.test(values[prop])
      : prop === "newEmail"
      ? !emailRegex.test(values[prop]) && values.email !== values.newEmail
      : values[prop] === "" || !regexpWhiteSpace.test(values[prop])

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
      <StyledLabel style={{ marginBottom: rows && rows !== 1 && 40 }}>
        {label}
      </StyledLabel>
      <FormControl style={{ width: "100%" }}>
        <StyledTextField
          creditCard={creditCard}
          disabled={disabled}
          fullWidth
          placeholder={placeholder ? placeholder : ""}
          value={values[prop] || ""}
          onChange={handleChange(prop)}
          name={prop}
          error={
            prop === "email"
              ? error && !emailRegex.test(values[prop])
              : prop === "newEmail"
              ? error &&
                (!emailRegex.test(values[prop]) ||
                  values.email !== values.newEmail)
              : (error && values[prop].length === 0 )|| (error && !regexpWhiteSpace.test(values[prop]))
          }
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          multiline={multiline}
          minRows={rows ? rows : 1}
          maxRows={rows ? rows : 1}
          inputProps={{
            autoComplete: "new-password",
            maxLength: 100
          }}
        />
        <StyledHelperText style={{ marginTop: rows && rows !== 1 && 35 }}>
          {error && errorStatus ? errorTexts[prop] : ""}
        </StyledHelperText>
      </FormControl>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  prop: PropTypes.string,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.any,
  placeholder: PropTypes.string,
  creditCard: PropTypes.bool,
};

export default TextArea;
