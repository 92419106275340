import React from "react";

import { styled } from '@mui/material/styles';

import SearchArea from "../../../Ui/Fields/SearchArea";
import TableHeader from "./Roles/TableHeader";
import TableRow from "./Roles/TableRow";

const PREFIX = 'Roles';

const classes = {
  root: `${PREFIX}-root`,
  mainCard: `${PREFIX}-mainCard`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginTop: 14,
  },

  [`& .${classes.mainCard}`]: {
    width: 1212,
    height: "auto",
    // minHeight: 400,
    marginTop: 10,
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16);",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    paddingBottom: 10,
  }
}));

const Roles = ({ roleRef }) => {
  return (
    <Root className={classes.root}>
      <SearchArea placeholder="Yetkilerde Ara" />
      <div className={classes.mainCard}>
        <TableHeader />
        <TableRow ref={roleRef} firstRow />
        <TableRow ref={roleRef} />
        <TableRow ref={roleRef} />
      </div>
    </Root>
  );
};

export default Roles;
