import {
  filterConstants,
  rating,
  filterReducerInitialState,
} from "../../Utils/Constants";

const initialState = {
  brands: [],
  categories: [],
  sizes: [],
  colors: [],
  shapes: [],
  others: [],
  rating: rating,
};

const filterValues = (state = initialState, action) => {
  switch (action.type) {
    case filterConstants.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case filterConstants.GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case filterConstants.GET_PRODUCT_VALUES:
      let data = action.payload;
      let sizeValues = data?.filter((item) => item.attribute === "Ebat");
      let colorValues = data?.filter((item) => item.attribute === "Renk");
      let shapeValues = data?.filter((item) => item.attribute === "Şekil");
      return {
        ...state,
        sizes: sizeValues,
        colors: colorValues,
        shapes: shapeValues,
      };
    case filterConstants.CLEAR_FILTER_REDUCER:
      return filterReducerInitialState;
    default:
      return state;
  }
};

export { filterValues };
