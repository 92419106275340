import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";

import filterIcon from "../../Images/Orders/FilterIcon.svg";

const PREFIX = "SearchArea";

const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
  svg: `${PREFIX}-svg`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
    paddingRight: 0,
    height: 60,
    alignItems: "center",
  },

  [`& .${classes.textField}`]: {
    width: 538,
    "& .MuiInputBase-input": {
      borderRadius: 8,
      height: 31,
      padding: "3.5px 8px",
      paddingRight: 30,
      border: "1px solid #6E3564",
      fontSize: 14,
      fontWeight: 400,
      backgroundColor: "#fff",
      marginRight: 17,
      "&:not(:focus)": {
        cursor: "pointer",
      },
      "&:hover, &:focus, &:active": {
        backgroundColor: "#fff",
        border: "2px solid #6E3564",
      },
      "&::placeholder": {
        fontSize: 16,
        fontWeight: 400,
        color: "#B1AEAE",
      },
    },
  },

  [`& .${classes.svg}`]: {
    position: "absolute",
    right: 25,
    color: "rgba(189, 189, 189, 1)",
    cursor: "pointer",
  },
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 55,
  cursor: "pointer",
  zIndex: 2,
}));

const SearchArea = ({
  handleChange,
  searchText,
  placeholder,
  handleKeyword,
  clearKeyword,
  keyPress,
  toggleDrawer,
}) => {
  return (
    <Root className={classes.root}>
      <TextField
        fullWidth
        placeholder={placeholder}
        className={classes.textField}
        value={searchText || ""}
        onChange={handleChange}
        onKeyDown={keyPress}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {searchText?.length > 0 && (
                <CloseAdornment position="end" onMouseDown={clearKeyword}>
                  <Fade in={searchText?.length > 0}>
                    <CloseIcon style={{ fill: "#8E8E8E" }} />
                  </Fade>
                </CloseAdornment>
              )}
              <InputAdornment
                onClick={handleKeyword}
                position="end"
                className={classes.svg}>
                <SearchIcon />
              </InputAdornment>
            </>
          ),
        }}
      />
      <IconButton onClick={toggleDrawer}>
        <img src={filterIcon} alt="" width="25.8px" height="22.5px" />
      </IconButton>
    </Root>
  );
};

SearchArea.propTypes = {
  handleChange: PropTypes.func,
  searchText: PropTypes.string,
  placeholder: PropTypes.string,
  handleKeyword: PropTypes.func,
  clearKeyword: PropTypes.func,
  keyPress: PropTypes.func,
  toggleDrawer: PropTypes.func,
};

export default SearchArea;
