import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import TextArea from "../../../Ui/Fields/TextArea";
import VerificationStep from "./VerificationStep";

import { authService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";

const PREFIX = "UpdateEmail";

const classes = {
  paper: `${PREFIX}-paper`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  inputContainer: `${PREFIX}-inputContainer`,
  switchAndButtonContainer: `${PREFIX}-switchAndButtonContainer`,
  formLabel: `${PREFIX}-formLabel`,
  submitButton: `${PREFIX}-submitButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 382,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.header}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginTop: 10,
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.inputContainer}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.submitButton}`]: {
    height: 30,
    textTransform: "none",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const UpdateEmail = ({ open, onClose }) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const verificationRef = useRef();

  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        setValues({
          email: "",
        });
        setError(false);
        setStep(0);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleError = () => {
    if (!emailRegex.test(values.email)) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (step === 0) {
      let isError = handleError();
      if (!isError) {
        let query = {
          type: 1,
          email: values.email,
        };
        const response = await authService.createConfirmCode(query);
        if (response.data.status === generalConstants.STATUS_TRUE) {
          setStep(1);
        } else {
          console.log(response);
        }
      }
    } else {
      verificationRef.current.handleVerificationCode();
    }
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Eposta Değiştir</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.inputContainer}>
          {step === 0 ? (
            <TextArea
              label="Yeni eposta"
              handleChange={handleChange}
              values={values}
              prop="email"
              error={error}
            />
          ) : (
            <VerificationStep
              ref={verificationRef}
              prop={values.email}
              value="email"
              onClose={onClose}
            />
          )}
          <Button
            variant="contained"
            disableRipple
            className={classes.submitButton}
            onClick={handleSubmit}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

UpdateEmail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UpdateEmail;
