import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import QuantitySelection from "../../../../Ui/Fields/QuantitySelection";
import ShowStockButton from "./ProductQuantity/ShowStockButton";
import LotDialog from "../../../../Ui/Dialog/LotDialog";

const PREFIX = "ProductQuantity";

const classes = {
  dropDown: `${PREFIX}-dropDown`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.dropDown}`]: {
    width: "100%",
    height: "auto",
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(),
    marginBottom: theme.spacing(),
    borderBottom: "1px solid #B1AEAE",
  },
}));

const ProductQuantity = ({
  handleChange,
  param,
  selectedValue,
  // features,
  // handleFeatures,
  customHeight,
  stockInfo,
  openSpecialDemandsModal,
  type,
  data,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <Root className={classes.dropDown}>
      <QuantitySelection
        handleChange={handleChange}
        param={param}
        selectedValue={selectedValue}
        stockInfo={stockInfo}
      />
      {/* {customHeight && (
        <CustomProductSize
          features={features}
          handleFeatures={handleFeatures}
        />
      )} */}
      {customHeight && (
        <ShowStockButton openModal={toggleModal} text="Stok Gör" />
      )}
      {/* <ShowStockButton
        type={type === 3}
        openModal={openSpecialDemandsModal}
        text="Özel İşlem Uygula"
        disabled={disabled}
      /> */}
      <LotDialog
        onClose={toggleModal}
        open={showModal}
        name={data?.name}
        data={data?.stock?.detail_list}
      />
    </Root>
  );
};

ProductQuantity.propTypes = {
  handleChange: PropTypes.func,
  param: PropTypes.string,
  selectedValue: PropTypes.number,
  features: PropTypes.object,
  handleFeatures: PropTypes.func,
  customHeight: PropTypes.bool,
  stockInfo: PropTypes.number,
  openSpecialDemandsModal: PropTypes.func,
  type: PropTypes.number,
  data: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ProductQuantity;
