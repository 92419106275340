import React from "react";

import { styled } from "@mui/material/styles";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  textBold: `${PREFIX}-textBold`,
  textLight: `${PREFIX}-textLight`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: 150,
    height: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 30,
  },

  [`& .${classes.textBold}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginBottom: 3.17,
  },

  [`& .${classes.textLight}`]: {
    fontSize: 10,
    fontWeight: 300,
    color: theme.palette.primary.main,
  },
}));

const Header = () => {
  return (
    <Root className={classes.root}>
      <div className={classes.textBold}>Şifre Yenileme</div>
      <div className={classes.textLight}>Yeni şifre belirleyin</div>
    </Root>
  );
};

export default Header;
