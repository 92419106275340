import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CheckoutHeader from "./CheckoutMob/CheckoutHeader";
import TabButtonsMob from "./CheckoutMob/TabButtonsMob";
import CreditCardMob from "./CheckoutMob/CreditCardMob";
import BankTransferMob from "./CheckoutMob/BankTransferMob";
import OpenAccountMob from "./CheckoutMob/OpenAccountMob";
import CheckAndBillMob from "./CheckoutMob/CheckAndBillMob";

import ErrorDialog from "./MobileDialogs/ErrorDialog";
import SuccessDialog from "./MobileDialogs/SuccessDialog";

import CartAddressSelection from "./MobileDialogs/CartAddressSelection";

import { orderActions } from "../../Redux/Actions";

import { styled } from "@mui/material/styles";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: innerWidth,
  height: "100vh",
  position: "relative",
  overflow: "hidden",
}));

const CheckoutMob = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const success = useSelector(({ orders }) => orders.success);
  const error = useSelector(({ orders }) => orders.error);
  const customer = useSelector(({ customer }) => customer);
  const note = useSelector(({ orders }) => orders.note);
  const paymentMethods = useSelector(
    ({ customer }) => customer.currentCompany.payment_methods
  );

  const saveNote = (note) => dispatch(orderActions.saveNote(note));
  const clearStatus = () => dispatch(orderActions.clearStatus());

  const [tabValue, setTabValue] = useState({ index: 0, id: 1 });
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [openAddressSelection, setOpenAddressSelection] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    let active = true;
    if (active) {
      if (note) {
        if (note !== text) {
          setText(note);
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (success) {
        setSuccessDialog(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (error) {
        setErrorDialog(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (paymentMethods.length > 0) {
        let isDefault = paymentMethods.find((pm) => pm.is_default === 1);
        let arrs = handlePaymentMethos();
        let defObj = isDefault
          ? arrs.filter((arr) => arr.id === isDefault.id)
          : null;
        isDefault ? setTabValue(defObj[0]) : setTabValue(arrs[0]);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  const handlePaymentMethos = () => {
    let arr = [];
    for (let i = 0; i < paymentMethods.length; i++) {
      let currentItem = paymentMethods[i];
      let obj = { index: i, id: currentItem.id };
      arr.push(obj);
    }
    return arr;
  };

  const handleChange = (index, id) => {
    setTabValue({ index, id });
  };

  const handleNote = (e) => setText(e.target.value);

  const goBack = () => {
    saveNote(text);
    history.goBack();
  };
  const toggleAddressDialog = () => setOpenAddressSelection((prev) => !prev);

  const handleSuccessDialog = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/order-page");
  };

  const closeSuccessDialog = () => setSuccessDialog(false);
  const closeErrorDialog = () => setErrorDialog(false);

  const handleErrorDialog = () => {
    clearStatus();
    closeErrorDialog();
  };

  const goToHome = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/home");
  };

  const contents = {
    1: (
      <CreditCardMob
        text={text}
        handleNote={handleNote}
        toggleAddressDialog={toggleAddressDialog}
      />
    ),
    2: (
      <BankTransferMob
        text={text}
        handleNote={handleNote}
        toggleAddressDialog={toggleAddressDialog}
      />
    ),
    3: (
      <CheckAndBillMob
        text={text}
        handleNote={handleNote}
        toggleAddressDialog={toggleAddressDialog}
      />
    ),
    4: (
      <OpenAccountMob
        text={text}
        handleNote={handleNote}
        toggleAddressDialog={toggleAddressDialog}
      />
    ),
  };
  return (
    <Root>
      <CheckoutHeader goBack={goBack} />
      <TabButtonsMob index={tabValue.index} handleChange={handleChange} />
      {contents[tabValue.id]}
      <SuccessDialog
        successDialog={successDialog}
        goToHome={goToHome}
        handleSuccessDialog={handleSuccessDialog}
        currentAddress={customer.currentAddress}
      />
      <ErrorDialog
        errorDialog={errorDialog}
        handleErrorDialog={handleErrorDialog}
      />
      <CartAddressSelection
        open={openAddressSelection}
        onClose={toggleAddressDialog}
      />
    </Root>
  );
};

export default CheckoutMob;
