import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(4),
}));

const InputFieldContainer = styled("div")(({ theme }) => ({
  width: 208,
  height: 42,
  display: "flex",
  paddingLeft: theme.spacing(2.5),
  backgroundColor: "#F5F5F5",
  borderRadius: 8,
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: theme.spacing(),
  marginLeft: theme.spacing(),
  "&[not-right-margin='true']": {
    marginRight: 0,
  },
  "&[not-left-margin='true']": {
    marginLeft: 0,
  },
}));

const QuantityNumber = styled(Typography)(({theme}) => ({
  fontSize: 34,
  fontWeight: 700,
  color: theme.palette.darkGray.main,
}));

const QuantityText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.secondary.main,
  marginTop: 2,
  marginBottom: 0,
  marginLeft: 10,
}));

const SubmitButton = styled(Button)(({theme}) => ({
  width: 303,
  height: 42,
  backgroundColor: theme.palette.secondary.main,
  color: "#fff",
  fontSize: 16,
  textTransform: "none",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const QuantityAndSubmit = ({
  decrease,
  quantity,
  increase,
  handleSubmit,
  features,
  card,
}) => {
  const handleDisableCondition = () => {
    if (features) {
      if (
        features.shape === "" ||
        features.side === "" ||
        features.width === "" ||
        features.height === "" ||
        features.width === 0 ||
        features.height === 0
      ) {
        return true;
      } else if (features.side === "Saçak" && features.webType === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Root>
      <InputFieldContainer>
        <StyledIconButton
          not-left-margin="true"
          onClick={decrease}
          size="large">
          <RemoveIcon />
        </StyledIconButton>
        <QuantityNumber>{quantity}</QuantityNumber>
        <StyledIconButton
          not-right-margin="true"
          // disabled={selections.quantity === product.stock}
          onClick={increase}
          size="large">
          <AddIcon />
        </StyledIconButton>
        <QuantityText>Adet</QuantityText>
      </InputFieldContainer>
      {card ? (
        <SubmitButton
          variant="contained"
          disableRipple
          onClick={handleSubmit}
          disabled={handleDisableCondition()}>
          Sepete Ekle
        </SubmitButton>
      ) : (
        <SubmitButton
          variant="contained"
          disableRipple
          onClick={handleSubmit("special")}
          disabled={handleDisableCondition()}>
          Sepete Ekle
        </SubmitButton>
      )}
    </Root>
  );
};

QuantityAndSubmit.propTypes = {
  decrease: PropTypes.func,
  quantity: PropTypes.number,
  increase: PropTypes.func,
  handleSubmit: PropTypes.func,
  features: PropTypes.object,
  card: PropTypes.bool,
};

export default QuantityAndSubmit;
