import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import FilterTabs from "./FilterTabs";

const PREFIX = "ResultsMain";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    flex: 1,
    minWidth: 973,
    height: "100%",
    borderRadius: 8,
    // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.16)",
    backgroundColor: "white",
  },
}));

const ResultsMain = ({ handleClearSearchText, setChildLoading }) => {
  return (
    <Root className={classes.root}>
      <FilterTabs
        handleClearSearchText={handleClearSearchText}
        setChildLoading={setChildLoading}
      />
    </Root>
  );
};

ResultsMain.propTypes = {
  handleClearSearchText: PropTypes.func,
  setChildLoading: PropTypes.func,
};

export default ResultsMain;
