import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import AuthContainerMob from "./AuthContainerMob";
import CustomTF from "../../Ui/Verification/CustomTF";
import HeaderRoute from "./AuthCommons/HeaderRoute";
import Timer from "../../Ui/Verification/Timer";
import ResendNotice from "../../Ui/Verification/ResendNotice";
import { SubmitButton } from "./AuthCommons/Tools";
import { authService } from "../../Services";
import { authActions } from "../../Redux/Actions";
import { generalConstants } from "../../Utils/Constants";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  maxHeight: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const InputContainer = styled("div")(() => ({
  marginTop: 27.08,
  width: innerWidth,
  height: 35,
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

const Resend = styled(Button)(({ theme }) => ({
  marginTop: 36.79,
  textTransform: "capitalize",
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.primary.main,
}));

const WrongCode = styled("div")(({ theme }) => ({
  marginTop: 30,
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

const StyledCard = styled("div")(() => ({
  marginTop: 30,
  width: "95%",
  background: "rgba(133, 89, 122, 0.1)",
  borderRadius: 8,
  height: 400,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PhoneverificationMob = ({ gsm }) => {
  let history = useHistory();
  const regexp = /^[0-9\b]+$/;
  const dispatch = useDispatch();

  const setUserInfo = (data) => dispatch(authActions.setSigninInfo(data));

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();
  const fifthInput = useRef();
  const sixthInput = useRef();

  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [sixthDigit, setSixthDigit] = useState("");

  const [secondsRemaining, setSecondsRemaining] = useState(120);
  const [wrongCode, setWrongCode] = useState(false);

  let tokenStr = firstDigit.concat(
    secondDigit,
    thirdDigit,
    fourthDigit,
    fifthDigit,
    sixthDigit
  );

  useEffect(() => {
    firstInput?.current?.focus();
  }, []);

  const handleFirstDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFirstDigit(d);
    } else if (regexp.test(d)) {
      setFirstDigit(d);
      secondInput.current.focus();
    }
  };

  const handleSecondDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSecondDigit(d);
      firstInput.current.focus();
    } else if (regexp.test(d)) {
      setSecondDigit(d);
      thirdInput.current.focus();
    }
  };

  const handleThirdDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setThirdDigit(d);
      secondInput.current.focus();
    } else if (regexp.test(d)) {
      setThirdDigit(d);
      fourthInput.current.focus();
    }
  };

  const handleFourthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFourthDigit(d);
      thirdInput.current.focus();
    } else if (regexp.test(d)) {
      setFourthDigit(d);
      fifthInput.current.focus();
    }
  };

  const handleFifthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFifthDigit(d);
      fourthInput.current.focus();
    } else if (regexp.test(d)) {
      setFifthDigit(d);
      sixthInput.current.focus();
    }
  };

  const handleSixthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSixthDigit(d);
      fifthInput.current.focus();
    } else if (regexp.test(d)) {
      setSixthDigit(d);
    }
  };

  const resetFields = () => {
    setFirstDigit("");
    setSecondDigit("");
    setThirdDigit("");
    setFourthDigit("");
    setFifthDigit("");
    setSixthDigit("");
  };

  const handleVerificationCode = () => {
    sendVerificationCode(handleZeroPad(tokenStr));
  };

  const handleZeroPad = (num) => {
    const zeroPad = String(num).padStart(4, "0");
    return zeroPad;
  };

  const sendVerificationCode = (token) => {
    resetFields();
    let query = {
      gsm: gsm,
      code: token,
    };
    authService
      .validateAndSignin(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setUserInfo(res.data);
        } else {
          setWrongCode(true);
          setSecondsRemaining(0);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleResend = () => {
    setWrongCode(false);
    resetFields();
    let query = {
      phone: gsm,
    };
    authService
      .signinPhone(query)
      .then((res) => setSecondsRemaining(120))
      .catch((err) => console.log(err));
  };

  const handleRoute = () => {
    history.push("/signin-phone");
  };

  // if (!history.location.state) {
  //   return (
  //     <AuthContainerMob>
  //       <div>Yönlendiriliyor...</div>
  //       {handleRoute()}
  //     </AuthContainerMob>
  //   );
  // } else {
  return (
    <AuthContainerMob>
      <Root>
        <StyledCard>
          <HeaderRoute handleRoute={handleRoute}>Doğrulama Kodu</HeaderRoute>
          <InputContainer>
            <CustomTF
              value={firstDigit}
              inputRef={firstInput}
              onChange={handleFirstDigitChange}
              mobile
            />
            <CustomTF
              value={secondDigit}
              inputRef={secondInput}
              onChange={handleSecondDigitChange}
              mobile
            />
            <CustomTF
              value={thirdDigit}
              inputRef={thirdInput}
              onChange={handleThirdDigitChange}
              mobile
            />
            <CustomTF
              value={fourthDigit}
              inputRef={fourthInput}
              onChange={handleFourthDigitChange}
              mobile
            />
            <CustomTF
              value={fifthDigit}
              inputRef={fifthInput}
              onChange={handleFifthDigitChange}
              mobile
            />
            <CustomTF
              value={sixthDigit}
              inputRef={sixthInput}
              onChange={handleSixthDigitChange}
              mobile
            />
          </InputContainer>
          {wrongCode && <WrongCode>Girdiğiniz kod hatalıdır!</WrongCode>}
          {secondsRemaining === 0 ? (
            <Resend onClick={handleResend}>Kod Gönder</Resend>
          ) : (
            <Timer
              secondsRemaining={secondsRemaining}
              setSecondsRemaining={setSecondsRemaining}
            />
          )}
          <ResendNotice />
          <SubmitButton
            disableRipple
            variant="contained"
            disabled={tokenStr.length !== 6}
            onClick={handleVerificationCode}>
            Tamam
          </SubmitButton>
        </StyledCard>
      </Root>
    </AuthContainerMob>
  );
};
// };

PhoneverificationMob.propTypes = {
  gsm: PropTypes.string,
};

export default PhoneverificationMob;
