import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import PageContainerMob from "../Common/PageContainerMob";

import { bottomNavbarActions } from "../../Redux/Actions";

const StyledCard = styled("div")(() => ({
  width: "100%",
  height: "auto",
  boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16)",
  borderRadius: 8,
  backgroundColor: "#fff",
  padding: 20,
  marginBottom: 30,
}));

const StyledHeader = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 400,
  color: "#B1AEAE",
  marginBottom: 13,
}));

const StyledText = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 400,
  color: "#000000",
  marginTop: 10,
  textAlign: "left",
}));

const AboutUsMob = () => {
  const dispatch = useDispatch();

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu("other");
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainerMob>
      <StyledHeader>Hakkımızda</StyledHeader>
      <StyledCard>
        <StyledText>Biz Kimiz</StyledText>
        <StyledText>
          2003 yılı Mart ayında Ankara'nın Altındağ İlçesi Malazgirt Caddesinde
          Özsafir Halı çatısı altında ticaret hayatımıza başladık.
        </StyledText>
        <StyledText>2008 yılında "Enti Halı" markasını kurduk.</StyledText>
        <StyledText>
          2010 yılında Şenkal Sokak No:11 Siteler / Ankara adresinde ki binamıza
          taşınarak 500'e yakın bayi noktasına ulaştık.
        </StyledText>
        <StyledText>
          2015 yılında İç Anadolu, Batı Karadeniz Bölge Bayisi olduğumuz Empera
          Halı markasını da bünyemize ekleyerek, makina halıcılığında
          birbirinden farklı kaliteler ile bayilerimize hizmet vermeye devam
          ettik.
        </StyledText>
        <StyledText>
          2018 yılında 5.000 metrekare kapalı alanda Demirhendek Cad. No:14
          Siteler Ankara mevcut merkez adresimize taşındık.
        </StyledText>
        <StyledText>
          2019 yılında Romans Halı markasını da bünyemize katarak, farklı tarz
          ve kalitede yeni ürünlerle, 800'e yakın bayi noktasına ulaştık.
        </StyledText>
        <StyledText>
          Görevimiz: Makina halıcılığında öncü olmayı başarmaktır.
        </StyledText>
        <StyledText>
          Hedefimiz: Hizmet verdiğimiz bayilerimizin piyasa ile rekabet edebilme
          gücünü ve kararlılığını arttırabilmek için yenilikçi hizmet
          anlayışıyla çalışmaktır. "Hep birlikte daha güzel yarınlara ulaşmak
          dileğiyle."
        </StyledText>
      </StyledCard>
    </PageContainerMob>
  );
};

export default AboutUsMob;
