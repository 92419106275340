import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { getFirstLetters } from "../../../../Utils/Helpers";
import { authService } from "../../../../Services";
import { generalConstants } from "../../../../Utils/Constants";

import dotsIcon from "../../../../Images/UsersAndRoles/Dots.svg";

import UserItemPopover from "../../../../Ui/Popovers/UserItemPopover";
import EditUser from "../Dialogs/EditUser";
import EditUserCa from "../Dialogs/EditUserCa";
import EditUserFirm from "../Dialogs/EditUserFirm";
import EditUserFirmCa from "../Dialogs/EditUserFirmCa";

const StyledSection = styled("div")(() => ({
  "&[initial-header='true']": {
    width: 30,
  },
  "&[initial-text='true']": {
    width: 122,
  },
  "&[register-date='true']": {
    width: 147,
  },
  "&[gsm-field='true']": {
    width: 120,
  },
  "&[email-field='true']": {
    width: 200,
  },
  "&[auth-field='true']": {
    width: 100,
  },
  "&[firm-name='true']": {
    width: 120,
  },
  "&[address-field='true']": {
    width: 120,
  },
  "&[status-field='true']": {
    width: 140,
  },
  "&[three-dots='true']": {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledLetters = styled("div")(({ theme }) => ({
  width: 22,
  height: 22,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
}));

const StyledLettersText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 700,
  color: "#fff",
  paddingTop: 2,
}));

const StyledSubHeader = styled("div")(() => ({
  flex: 1,
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const StyledIconButton = styled(IconButton)(() => ({
  height: 30,
  width: 30,
}));

const sectionText = {
  width: "100%",
  fontSize: 14,
  fontWeight: 400,
  color: "#434141",
  textAlign: "left",
};

const boxClass = {
  width: "100%",
  height: 41,
  border: "1px solid #E8E4E4",
  display: "flex",
  borderRadius: 2,
  padding: "0px 7px",
  alignItems: "center",
  marginTop: 1,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};

const TableRow = ({ user, roles, newGetUsers, companyId, isAdmin, query }) => {
  const customer = useSelector(({ customer }) => customer);

  const [letters, setLetters] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogCa, setEditDialogCa] = useState(false);
  const [editFirm, setEditFirm] = useState(false);
  const [editFirmCa, setEditFirmCa] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    let active = true;
    if (active) {
      if (user) {
        getStatus(user);
        let letters = getFirstLetters(user.first_name, user.last_name);
        setLetters(letters);
      }
    }
    return () => {
      active = false;
    };
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openEditUser = () => {
    handleClose();
    if (customer?.isAdmin) {
      setEditDialog(true);
    } else {
      setEditDialogCa(true);
    }
  };

  const closeEditUser = () => {
    if (customer?.isAdmin) {
      setEditDialog(false);
    } else {
      setEditDialogCa(false);
    }
  };

  const openEditFirm = () => {
    handleClose();
    if (customer?.isAdmin) {
      setEditFirm(true);
    } else {
      setEditFirmCa(true);
    }
  };

  const closeEditFirm = () => {
    if (customer?.isAdmin) {
      setEditFirm(false);
    } else {
      setEditFirmCa(false);
    }
  };

  const getStatus = (user) => {
    if (user?.status === 1) {
      if (user?.is_confirmed === true) {
        setStatus("Aktif");
      } else {
        setStatus("İstek Gönderildi");
      }
    } else {
      setStatus("Pasif");
    }
  };

  const handlePasswordRenew = async () => {
    let query = { email: user?.email };
    const response = await authService.userRenewPassword(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      toast.success("Şifre yenileme linki gönderildi");
    } else {
      toast.error(response?.data?.message);
    }
    handleClose();
  };

  const handleActivationLink = async () => {
    let id = user?.id;
    const response = await authService.userActivationLink(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      toast.success("Aktivasyon linki gönderildi");
    } else {
      toast.error(response?.data?.message);
    }
    handleClose();
  };

  return (
    <Box
      sx={{
        ...boxClass,
        "&:hover": {
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.50)",
          "& .sectionText": {
            color: (theme) => theme.palette.primary.main,
          },
        },
      }}>
      <StyledSection initial-header="true">
        <StyledLetters>
          <StyledLettersText align="center">
            {letters && letters}
          </StyledLettersText>
        </StyledLetters>
      </StyledSection>
      <StyledSubHeader>
        <StyledSection initial-text="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.full_name}
          </Typography>
        </StyledSection>
        <StyledSection register-date="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.create_date}
          </Typography>
        </StyledSection>
        <StyledSection gsm-field="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.gsm}
          </Typography>
        </StyledSection>
        <StyledSection email-field="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.email}
          </Typography>
        </StyledSection>
        <StyledSection auth-field="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.role_names?.length > 0 ? user?.role_names[0] : ""}
          </Typography>
        </StyledSection>
        <StyledSection firm-name="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.company?.name}
          </Typography>
        </StyledSection>
        <StyledSection address-field="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {user?.address?.name}
          </Typography>
        </StyledSection>
        <StyledSection status-field="true">
          <Typography
            noWrap
            className="sectionText"
            sx={{
              ...sectionText,
            }}>
            {status}
          </Typography>
        </StyledSection>
        <StyledSection three-dots="true">
          <StyledIconButton onClick={handleClick} size="large">
            <img src={dotsIcon} width="24px" height="24px" alt="" />
          </StyledIconButton>
        </StyledSection>
      </StyledSubHeader>
      {open && <UserItemPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        showEditUser={openEditUser}
        showEditFirm={openEditFirm}
        user={user}
        handlePasswordRenew={handlePasswordRenew}
        handleActivationLink={handleActivationLink}
        status={status}
      />}
      {editDialog && <EditUser
        open={editDialog}
        onClose={closeEditUser}
        letters={letters}
        user={user}
        status={status}
        newGetUsers={newGetUsers}
        companyId={companyId}
        isAdmin={isAdmin}
        query={query}
      />}
      {editDialogCa && <EditUserCa
        open={editDialogCa}
        onClose={closeEditUser}
        letters={letters}
        user={user}
        status={status}
        newGetUsers={newGetUsers}
        companyId={companyId}
        isAdmin={isAdmin}
        query={query}
      />}
      {editFirm && <EditUserFirm
        open={editFirm}
        onClose={closeEditFirm}
        roles={roles}
        user={user}
        status={status}
        newGetUsers={newGetUsers}
        companyId={companyId}
        isAdmin={isAdmin}
        query_={query}
      />}
     {editFirmCa && <EditUserFirmCa
        open={editFirmCa}
        onClose={closeEditFirm}
        roles={roles}
        user={user}
        status={status}
        newGetUsers={newGetUsers}
        companyId={companyId}
        isAdmin={isAdmin}
        query_={query}
      />}
    </Box>
  );
};

TableRow.propTypes = {
  roles: PropTypes.array,
  users: PropTypes.array,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
  companyId: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default TableRow;
