import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { isMobileOnly } from "react-device-detect";
import AddressCard from "./AddressCard";


const Content = ({companies, pageNumber, pageCount, onPageNumberChange}) => {
  return (
    <Container maxWidth="lg" style={{ marginBottom: isMobileOnly ? 72 : 32 }}>
      <Grid container spacing={2}>
        {companies.map((company, index) => (
          <Grid item key={index}>
            <AddressCard company={company} />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: 'auto',
          display: "flex",
          justifyContent: "center",
          mt: theme => theme.spacing(3)
        }}>
        <Pagination
          page={pageNumber || 1}
          count={pageCount}
          onChange={onPageNumberChange}
        />
      </Box>
    </Container>
  );
};

export default Content;
