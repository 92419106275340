import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  width: 42.11,
  borderRadius: 8,
  "&[is-mobile='true']": {
    width: 38,
  },
  "& .MuiOutlinedInput-root": {
    padding: 6.1,
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.primary.main,
    borderRadius: 8,
  },
  "& .MuiInputBase-input": {
    padding: 0,
  },
}));

const CustomTF = ({ value, inputRef, onChange, mobile }) => {
  return (
    <StyledTextField
      autoComplete="first"
      autoFocus
      value={value}
      inputRef={inputRef}
      onChange={onChange}
      variant="outlined"
      is-mobile={mobile ? "true" : "false"}
      InputProps={{
        inputProps: {
          style: { textAlign: "center" },
          maxLength: 1,
        },
      }}
    />
  );
};

CustomTF.propTypes = {
  value: PropTypes.string,
  inputRef: PropTypes.object,
  onChange: PropTypes.func,
  mobile: PropTypes.bool,
};

export default CustomTF;
