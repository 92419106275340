import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import filterIcon from "../../../Images/Orders/FilterIcon.svg";

const PREFIX = "OrdersSearchArea";

const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
  svg: `${PREFIX}-svg`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
    paddingRight: 0,
    height: 60,
    alignItems: "center",
    position: "relative",
  },

  [`& .${classes.textField}`]: {
    width: 538,
    "& .MuiInputBase-input": {
      borderRadius: 8,
      height: 31,
      padding: "3.5px 8px",
      paddingRight: 30,
      border: "1px solid #6E3564",
      fontSize: 14,
      fontWeight: 400,
      backgroundColor: "#fff",
      marginRight: 17,
      "&:not(:focus)": {
        cursor: "pointer",
      },
      "&:hover, &:focus, &:active": {
        backgroundColor: "#fff",
        border: "2px solid #6E3564",
      },
      "&::placeholder": {
        fontSize: 16,
        fontWeight: 400,
        color: "#B1AEAE",
      },
    },
  },

  [`& .${classes.svg}`]: {
    position: "absolute",
    right: 25,
    color: "rgba(189, 189, 189, 1)",
  },
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 55,
  cursor: "pointer",
  zIndex: 9999,
  color: "rgba(189, 189, 189, 1)",
}));

const OrdersSearchArea = ({
  handleChange,
  searchText,
  clearSearchtext,
  doSearch,
  toggleDrawer,
  fetchOrders
}) => {
  return (
    <Root className={classes.root}>
      <IconButton onClick={() => fetchOrders("refresh")}>
        <RefreshRoundedIcon />
      </IconButton>
      <TextField
        fullWidth
        placeholder="Siparişlerimde ara"
        className={classes.textField}
        value={searchText || ""}
        onChange={handleChange}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {searchText.length > 0 && (
                <CloseAdornment position="end" onMouseDown={clearSearchtext}>
                  <Fade in={searchText.length > 0}>
                    <CloseIcon />
                  </Fade>
                </CloseAdornment>
              )}
              <InputAdornment
                position="end"
                className={classes.svg}
                onClick={doSearch}>
                <SearchIcon />
              </InputAdornment>
            </>
          ),
        }}
      />
      <IconButton onClick={toggleDrawer}>
        <img src={filterIcon} alt="" width="25.8px" height="22.5px" />
      </IconButton>
    </Root>
  );
};

OrdersSearchArea.propTypes = {
  handleChange: PropTypes.func,
  searchText: PropTypes.string,
  clearSearchtext: PropTypes.func,
  doSearch: PropTypes.func,
  toggleDrawer: PropTypes.func,
  fetchOrders: PropTypes.func
};

export default OrdersSearchArea;
