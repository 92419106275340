const customLowerCase = (text) => {
  var letters = { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ö: "ö", Ç: "ç" };
  text = text.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
    return letters[letter];
  });
  return text.toLowerCase();
};

const customUpperCase = (text) => {
  var letters = { i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I" };
  text = text.replace(/(([iışğüçö]))/g, function (letter) {
    return letters[letter];
  });
  return text.toUpperCase();
};

export { customLowerCase, customUpperCase };
