import React from "react";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import StockBox from "../../../Ui/Stocks/StockBox";

import prevIcon from "../../../Images/ProductPage/PrevIcon.svg";
import nextIcon from "../../../Images/ProductPage/NextIcon.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const PREFIX = "LeftPart";

const classes = {
  root: `${PREFIX}-root`,
  stocks: `${PREFIX}-stocks`,
  listItem: `${PREFIX}-listItem`,
  list: `${PREFIX}-list`,
  main: `${PREFIX}-main`,
  prevButton: `${PREFIX}-prevButton`,
  nextButton: `${PREFIX}-nextButton`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: 206,
    minWidth: 206,
    height: "100%",
    paddingTop: 22,
    paddingLeft: 26,
    marginRight: 11,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.stocks}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 8,
    height: 100,
  },

  [`& .${classes.listItem}`]: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.list}`]: {
    paddingLeft: 15,
  },

  [`& .${classes.main}`]: {
    width: 180,
    "& .carousel.carousel-slider": {
      display: "flex",
    },
  },

  [`& .${classes.prevButton}`]: {
    height: 254,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    zIndex: 2000,
  },

  [`& .${classes.nextButton}`]: {
    height: 254,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    right: 0,
    zIndex: 1,
  },
}));

const LeftPart = ({ carouselData, data }) => {
  return (
    <Root className={classes.root}>
      {carouselData.length < 1 ? (
        <div style={{ width: 180, height: 254 }} />
      ) : (
        <Carousel
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
          showThumbs={false}
          className={classes.main}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <div className={classes.prevButton}>
                <IconButton onClick={onClickHandler} size="large">
                  <img alt="" src={prevIcon} width="15px" height="20px" />
                </IconButton>
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div className={classes.nextButton}>
                <IconButton onClick={onClickHandler} size="large">
                  <img alt="" src={nextIcon} width="15px" height="20px" />
                </IconButton>
              </div>
            )
          }>
          {carouselData?.map((item, index) => (
            <div key={index} style={{ height: 254, width: 180 }}>
              <img
                alt=""
                src={item?.url}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ))}
        </Carousel>
      )}
      <div className={classes.stocks}>
        <StockBox
          text="Depo Miktarı: "
          value={`${data?.stock.quantity} Adet`}
          order="first"
          component="fast-order"
        />
        {/* <StockBox
          text="Konya Depo: "
          value="3 Adet"
          order="second"
          component="fast-order"
        />
        <StockBox
          text="Fabrika Depo: "
          value="Stok Sorunuz"
          order="third"
          component="fast-order"
        /> */}
      </div>
      {/* <ul className={classes.list}>
        <li className={classes.listItem}>Tahmini Sevk Tarihi : 23.08.2021 </li>
        <li className={classes.listItem}>
          Tahmini Termin Tarihi : 23.08.2021{" "}
        </li>
      </ul> */}
    </Root>
  );
};

LeftPart.propTypes = {
  carouselData: PropTypes.array,
  data: PropTypes.object,
};

export default LeftPart;
