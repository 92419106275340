import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { errorTexts } from "../../Utils/Constants";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    fontSize: 14,
    color: theme.palette.darkGray.main,
    fontWeight: 300,
    height: 19,
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    animationDuration: "300ms",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    paddingLeft: 10,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderColor: "rgba(127, 91, 120, 0.5)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
    borderColor: "#fff",
    borderWidth: 1,
  },
  "& .MuiOutlinedInput-root.Mui-active .MuiOutlinedInput-notchedOutline": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
    borderColor: "#fff",
    borderWidth: 1,
  },
  "& .MuiOutlinedInput-root.Mui-active:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(127, 91, 120, 0)",
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(127, 91, 120, 0)",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderColor: "rgba(127, 91, 120, 1)",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderColor: "#f44336",
  },
  "& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    boxShadow: `${alpha("#f44336", 0.5)} 0 0 0 0.1rem`,
    borderColor: "#fff",
  },
  "& .MuiFormHelperText-root": {
    fontSize: 8,
    color: "red",
    userSelect: "none",
    "&.MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
    padding: 0,
  },
  "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-of-type": {
    paddingLeft: 10,
  },
  "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
    padding: "5px 10px",
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[700],
  marginTop: 10,
  marginBottom: 5,
  marginLeft: 1,
  userSelect: "none",
  "&[add-user='true']": {
    marginTop: 15,
  },
}));

const CustomPaper = (props) => {
  return (
    <Paper style={{ maxHeight: 150, marginTop: 2 }} elevation={8} {...props} />
  );
};

const AutoCompleteMultiple = ({
  data,
  label,
  prop,
  setValues,
  values,
  component,
  error,
  customWidth,
  disabled,
  defVal,
}) => {
  const result = data.filter((ad) => defVal.every((fd) => fd.id !== ad.id));
  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{ width: customWidth ? customWidth : 335 }}
        placement="bottom-start"
      />
    );
  };
  let errorStatus = !values[prop];
  return (
    <>
      <StyledLabel add-user={component === "add user" ? "true" : "false"}>
        {label}
      </StyledLabel>
      <StyledAutocomplete
        multiple
        id="field1"
        options={result}
        getOptionLabel={(option) => option?.name || ""}
        defaultValue={defVal}
        disabled={disabled}
        // isOptionEqualToValue={(option, value) => option?.id === value?.id}
        value={values[prop]}
        noOptionsText={"Herhangi bir sonuç bulunamadı"}
        // value prop should be discarded if three commented props above is being activated
        style={{ width: "100%" }}
        PopperComponent={PopperMy}
        PaperComponent={CustomPaper}
        onChange={(event, newValue) =>
          setValues({ ...values, [prop]: newValue })
        }
        // renderOption={(props, option, { selected }) => (
        //     <li {...props}>
        //       <Checkbox
        //         icon={icon}
        //         checkedIcon={checkedIcon}
        //         style={{ marginRight: 8 }}
        //         checked={selected}
        //       />
        //       {option.title}
        //     </li>
        //   )}
        renderInput={(params) => {
          const inputProps = params.inputProps;
          inputProps.autoComplete = "new-password";
          return (
            <StyledTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
              name="field1"
              id="field1"
              autoComplete="off"
              type="text"
              label=""
              variant="outlined"
              error={error && !values[prop]}
              helperText={error && errorStatus ? errorTexts[prop] : ""}
            />
          );
        }}
      />
    </>
  );
};

AutoCompleteMultiple.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  prop: PropTypes.string,
  setValues: PropTypes.func,
  values: PropTypes.object,
  component: PropTypes.string,
  error: PropTypes.bool,
  customWidth: PropTypes.number,
  disabled: PropTypes.any,
  defVal: PropTypes.array,
};

export default AutoCompleteMultiple;
