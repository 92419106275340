import _ from "lodash";

const concatArray = (data) => {
  let arrs = [
    data.brands,
    data.categories,
    data.colors,
    data.rating,
    data.shapes,
    data.sizes,
    data.searchText.length > 0
      ? [{ filter: "Arama", id: 0, name: data.searchText }]
      : [],
  ];
  const newArray = _.uniq(_.flatten(arrs));
  return newArray;
};

export { concatArray };
