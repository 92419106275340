import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import closeIcon from "../../../../../Images/ServicesAndReturns/Close.svg";
import pdfIcon from "../../../../../Images/ServicesAndReturns/PdfIcon.png";

const PREFIX = "ImageBox";

const classes = {
  imageBox: `${PREFIX}-imageBox`,
  closeIcon: `${PREFIX}-closeIcon`,
  imageContainer: `${PREFIX}-imageContainer`,
  image: `${PREFIX}-image`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.imageBox}`]: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 70,
    height: 115,
    border: "1px solid #6E3564",
    borderRadius: 6,
    marginRight: 5,
    backgroundColor: "#fff",
  },

  [`& .${classes.closeIcon}`]: {
    position: "absolute",
    right: 3,
    top: 3,
    width: 10,
    height: 10,
    cursor: "pointer",
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.standard,
    }),
    "&:hover": {
      transform: "scale(1.2)",
    },
  },

  [`& .${classes.imageContainer}`]: {
    width: 50,
    height: 79,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.image}`]: {
    objectFit: "contain",
    maxWidth: 50,
    maxHeight: 79,
  },
}));

const ImageBox = ({ removeFile, prop, file }) => {
  return (
    <Root className={classes.imageBox}>
      <img
        src={closeIcon}
        className={classes.closeIcon}
        alt=""
        onClick={removeFile(prop)}
      />
      <div className={classes.imageContainer}>
        {file.type !== "application/pdf" ? (
          <img
            src={URL.createObjectURL(file)}
            alt=""
            className={classes.image}
          />
        ) : (
          <img src={pdfIcon} alt="" className={classes.image} />
        )}
      </div>
    </Root>
  );
};

ImageBox.propTypes = {
  removeFile: PropTypes.func,
  prop: PropTypes.string,
  file: PropTypes.any,
};

export default ImageBox;
