import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "StockBox";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "fit-content",
    minWidth: 162,
    height: 42,
    border: `1px solid ${theme.palette.colors.gray}`,
    borderRadius: 6,
    padding: theme.spacing(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    "&[data-order='third']": {
      minWidth: 141,
    },
    "&[component='fast-order']": {
      width: "100%",
    },
  },

  [`& .${classes.text}`]: {
    width: "fit-content",
    fontSize: 14,
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    "&[data-order='first']": {
      color: theme.palette.colors.green,
    },
    "&[data-order='second']": {
      color: "#FFD600",
    },
    "&[data-order='third']": {
      color: "#FB0505",
    },
  },
}));

const StockBox = ({ order, text, value, component }) => {
  return (
    <Root className={classes.root} data-order={order} component={component}>
      <Typography className={classes.text}>{text}</Typography>
      <Typography
        className={classes.text}
        style={{ marginLeft: 3 }}
        data-order={order}>
        {value}
      </Typography>
    </Root>
  );
};

StockBox.propTypes = {
  order: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  component: PropTypes.string,
};

export default StockBox;
