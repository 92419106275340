import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TitleComponent from "./Common/TitleComponent";
import Section from "./Common/Section";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(),
}));

const CompanyAddresses = ({ userAddresses, addressArr, handleAddresses }) => {
  return (
    <Root>
      <TitleComponent title="Sevk Adres" />
      <Section
        data={userAddresses}
        callBack={handleAddresses}
        values={addressArr}
        component="Sevk Adresi"
      />
    </Root>
  );
};

CompanyAddresses.propTypes = {
  userAddresses: PropTypes.array,
  addressArr: PropTypes.array,
  handleAddresses: PropTypes.func,
};

export default CompanyAddresses;
