import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeaderClose from "../../../Ui/Dialog/Tools/MobileDialogHeaderClose";
import AutoCompleteArea from "../../../Ui/Fields/AutocompleteArea";

import { addressService } from "../../../Services";
import { authActions } from "../../../Redux/Actions";
import { generalConstants } from "../../../Utils/Constants";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    maxHeight: "calc(100vh-80px)",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const StyledFirm = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "20px",
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const UserAddressChangeMob = ({ onClose, open }) => {
  const dispatch = useDispatch();

  const signOut = () => dispatch(authActions.signout());

  const customerAddresses = useSelector(({ addresses }) => addresses.addresses);
  const customer = useSelector(({ customer }) => customer);
  const user = useSelector(({ auth }) => auth.customerInfo);

  const [values, setValues] = useState({
    branch: { name: "Lütfen şube seçiniz", id: 0.5 },
  });
  const [error, setError] = useState(false);
  const [addresses, setAddresses] = useState([
    { name: "Lütfen şube seçiniz", id: 0.5 },
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && user) {
        setValues({
          branch: { name: user?.address?.name, id: user?.address?.id },
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, user]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customerAddresses) {
        if (customerAddresses.length > 0) {
          let companyAddresses = customerAddresses.filter(
            (addr) => addr.type === 0
          );
          setAddresses(companyAddresses);
        } else {
          setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
        }
      } else return;
    }
    return () => {
      active = false;
    };
  }, [customerAddresses]);

  const handleCheck = () => {
    if (!values.branch) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    if (!isError) {
      let query = {
        is_default: true,
        address_id: +values.branch.id,
      };
      const res = await addressService.selfToggleAddress(query);
      if (res.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Adres bilgileri başarılı bir şekilde güncellendi");
        setTimeout(() => {
          onClose();
          signOut();
        }, 1000);
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeaderClose text="Adres Bilgileri" callBack={onClose} />
      <StyledFirm>{`Firma: ${customer?.currentCompany?.name}`}</StyledFirm>
      <AutoCompleteArea
        data={addresses}
        label="Şube veya Adres"
        setValues={setValues}
        prop="branch"
        values={values}
        error={error}
      />
      <MobileDialogButton disableRipple onClick={handleSubmit}>
        Tamam
      </MobileDialogButton>
    </StyledDialog>
  );
};

UserAddressChangeMob.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default UserAddressChangeMob;
