import React from "react";
import { isMobileOnly } from "react-device-detect";

import NewContent from "./NewContent";
import PageContainer from "../Common/PageContainer";
import DealerProfileMob from "./DealerProfileMob";
import UserBox from "../../Ui/UserBox/UserBox";

const DealerProfile = () => {
  if (isMobileOnly) {
    return <DealerProfileMob />;
  } else {
    return (
      <PageContainer>
        <UserBox />
        <NewContent />
      </PageContainer>
    );
  }
};

export default DealerProfile;
