import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const PREFIX = "InputArea";

const classes = {
  inputContainer: `${PREFIX}-inputContainer`,
  adornment: `${PREFIX}-adornment`,
  textfield: `${PREFIX}-textfield`,
  desc: `${PREFIX}-desc`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.inputContainer}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 10,
  },

  [`& .${classes.adornment}`]: {
    fontSize: 10,
    fontWeight: 400,
    "& .MuiTypography-colorTextSecondary": {
      color: "#B1AEAE",
    },
  },

  [`& .${classes.textfield}`]: {
    width: 138,
    maxHeight: 48,
    backgroundColor: "white",
    borderRadius: 3,
    marginRight: 33,
    marginTop: 3,
    "&[fast-order='true']": {
      marginRight: 15,
    },
    "&[is-left='true']": {
      marginRight: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: 3,
      border: `1px solid ${theme.palette.darkGray.main}`,
    },
    "&  .MuiFormLabel-root.Mui-focused": {
      color: "#B1AEAE",
      marginTop: 0,
    },
    "& .MuiFormLabel-root": {
      color: "#B1AEAE",
    },
  },

  [`& .${classes.desc}`]: {
    marginTop: 10,
    width: "100%",
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    "&[is-mobile='true']": {
      borderBottom: "none",
      "& > ul": {
        paddingLeft: 20,
      },
    },
    "&[fast-order='true']": {
      borderBottom: "none",
      fontSize: 10,
      fontWeight: 300,
      color: theme.palette.secondary.main,
      marginBottom: 12,
    },
    "& ul": {
      paddingLeft: 15,
      margin: "3px 0px",
    },
  },
}));

const InputArea = ({ handleFeatures, features, fastOrder, mobile }) => {
  return (
    <Root>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.textfield}
          variant="outlined"
          label="En"
          value={features?.width || ""}
          onChange={handleFeatures("width")}
          size="small"
          fast-order={fastOrder ? "true" : "false"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.adornment}>
                CM
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.textfield}
          variant="outlined"
          label="Boy"
          value={features?.height || ""}
          onChange={handleFeatures("height")}
          size="small"
          is-left="true"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.adornment}>
                CM
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div
        className={classes.desc}
        fast-order={fastOrder ? "true" : "false"}
        is-mobile={mobile ? "true" : "false"}>
        <ul>
          {!mobile ? (
            <li> En daraltma için bu alanı değiştirebilirsiniz</li>
          ) : (
            <li>
              {" "}
              En daraltma için <span style={{ fontWeight: 700 }}>En</span>{" "}
              alanını değiştirebilirsiniz
            </li>
          )}
        </ul>
      </div>
    </Root>
  );
};

InputArea.propTypes = {
  handleFeatures: PropTypes.func,
  features: PropTypes.object,
  fastOrder: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default InputArea;
