import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import OzsafirSolo from "../../../../Images/TopNavbar/OzsafirSolo.svg";

const ImageField = styled("div")(({ theme }) => ({
  width: 50,
  marginRight: theme.spacing(),
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const ImageContainer1 = styled("div")(() => ({
  width: 25,
  height: 25,
  borderRadius: "50%",
  position: "relative",
}));

const StyledImage = styled("img")(() => ({
  width: 25,
  height: 25,
  borderRadius: "50%",
  position: "absolute",
  left: -1,
  top: -1,
}));

const ImageContainer2 = styled("div")(() => ({
  width: 26,
  height: 26,
  borderRadius: "50%",
  border: "1px solid #BDBDBD",
  position: "absolute",
  left: 11,
  zIndex: 1,
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 1,
}));

const ImageText = styled(Typography)(({theme}) => ({
  fontSize: 12,
  color:theme.palette.darkGray.main,
}));

const ImgField = ({ order }) => {
  return (
    <ImageField>
      <ImageContainer1>
        <StyledImage
          src={
            order?.products && order?.products.length > 0
              ? order?.products[0].image
              : OzsafirSolo
          }
          alt=""
        />
      </ImageContainer1>
      {order?.total_quantity > 1 && (
        <ImageContainer2>
          <ImageText>+{order?.total_quantity - 1}</ImageText>
        </ImageContainer2>
      )}
    </ImageField>
  );
};

ImgField.propTypes = {
  order: PropTypes.object,
};

export default ImgField;
