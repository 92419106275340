import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SectionContainer from "../Common/SectionContainer";
import ConfirmationDialog from "../NewDialogs/ConfirmationDialog";
import Notifications from "./NotificationsPreferences/Notifications";
import Orders from "./NotificationsPreferences/Orders";
import Payments from "./NotificationsPreferences/Payments";
import Services from "./NotificationsPreferences/Services";

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const Header = styled(Typography)(({ theme }) => ({
  height: "auto",
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "30px",
  color: theme.palette.darkGray.main,
}));

const StyledButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 6,
  height: 30,
  width: 150,
  color: "#fff",
  backgroundColor: "#6A6868",
  paddingLeft: 0,
  paddingRight: 0,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6A6868",
  },
}));

const NotificationPreferences = () => {
  const [values, setValues] = useState({
    emailAll: false,
    smsAll: false,
    applicationAll: false,
    orders: {
      email: false,
      sms: false,
      application: false,
    },
    payments: {
      email: false,
      sms: false,
      application: false,
    },
    services: {
      email: false,
      sms: false,
      application: false,
    },
  });
  const [open, setOpen] = useState(false);

  const toggleDialog = () => setOpen(!open);

  useEffect(() => {
    let active = true;
    if (active) {
      if (
        values.orders.email &&
        values.payments.email &&
        values.services.email
      ) {
        setValues({
          ...values,
          emailAll: true,
        });
      } else if (
        !values.orders.email &&
        !values.payments.email &&
        !values.services.email
      ) {
        setValues({
          ...values,
          emailAll: false,
        });
      } else
        setValues({
          ...values,
          emailAll: "indeterminate",
        });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.orders.email, values.payments.email, values.services.email]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values.orders.sms && values.payments.sms && values.services.sms) {
        setValues({
          ...values,
          smsAll: true,
        });
      } else if (
        !values.orders.sms &&
        !values.payments.sms &&
        !values.services.sms
      ) {
        setValues({
          ...values,
          smsAll: false,
        });
      } else
        setValues({
          ...values,
          smsAll: "indeterminate",
        });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.orders.sms, values.payments.sms, values.services.sms]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (
        values.orders.application &&
        values.payments.application &&
        values.services.application
      ) {
        setValues({
          ...values,
          applicationAll: true,
        });
      } else if (
        !values.orders.application &&
        !values.payments.application &&
        !values.services.application
      ) {
        setValues({
          ...values,
          applicationAll: false,
        });
      } else
        setValues({
          ...values,
          applicationAll: "indeterminate",
        });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.orders.application,
    values.payments.application,
    values.services.application,
  ]);

  const handleEmailAll = () => {
    if (values.emailAll === "indeterminate" || !values.emailAll) {
      setValues({
        ...values,
        emailAll: true,
        orders: {
          ...values.orders,
          email: true,
        },
        payments: {
          ...values.payments,
          email: true,
        },
        services: {
          ...values.services,
          email: true,
        },
      });
    } else {
      setValues({
        ...values,
        emailAll: false,
        orders: {
          ...values.orders,
          email: false,
        },
        payments: {
          ...values.payments,
          email: false,
        },
        services: {
          ...values.services,
          email: false,
        },
      });
    }
  };

  const handleSmsAll = () => {
    if (values.smsAll === "indeterminate" || !values.smsAll) {
      setValues({
        ...values,
        smsAll: true,
        orders: {
          ...values.orders,
          sms: true,
        },
        payments: {
          ...values.payments,
          sms: true,
        },
        services: {
          ...values.services,
          sms: true,
        },
      });
    } else {
      setValues({
        ...values,
        smsAll: false,
        orders: {
          ...values.orders,
          sms: false,
        },
        payments: {
          ...values.payments,
          sms: false,
        },
        services: {
          ...values.services,
          sms: false,
        },
      });
    }
  };

  const handleApplicationAll = () => {
    if (values.applicationAll === "indeterminate" || !values.applicationAll) {
      setValues({
        ...values,
        applicationAll: true,
        orders: {
          ...values.orders,
          application: true,
        },
        payments: {
          ...values.payments,
          application: true,
        },
        services: {
          ...values.services,
          application: true,
        },
      });
    } else {
      setValues({
        ...values,
        applicationAll: false,
        orders: {
          ...values.orders,
          application: false,
        },
        payments: {
          ...values.payments,
          application: false,
        },
        services: {
          ...values.services,
          application: false,
        },
      });
    }
  };

  const handleSubmit = () => {
    toggleDialog();
    let query = {
      ...values,
    };
    console.log(query);
  };

  const handleOrders = (prop) => (e) => {
    setValues((prevState) => ({
      ...values,
      orders: {
        ...prevState.orders,
        [prop]: !prevState.orders[prop],
      },
    }));
  };

  const handlePayments = (prop) => (e) => {
    setValues((prevState) => ({
      ...values,
      payments: {
        ...prevState.payments,
        [prop]: !prevState.payments[prop],
      },
    }));
  };

  const handleServices = (prop) => (e) => {
    setValues((prevState) => ({
      ...values,
      services: {
        ...prevState.services,
        [prop]: !prevState.services[prop],
      },
    }));
  };

  return (
    <SectionContainer last>
      <HeaderContainer>
        <Header>Bildirim Tercihleri</Header>
        <StyledButton onClick={toggleDialog}>
          Değişiklikleri Kaydet
        </StyledButton>
      </HeaderContainer>
      <Notifications
        handleEmailAll={handleEmailAll}
        values={values}
        handleSmsAll={handleSmsAll}
        handleApplicationAll={handleApplicationAll}
      />
      <Orders handleOrders={handleOrders} values={values} />
      <Payments handlePayments={handlePayments} values={values} />
      <Services handleServices={handleServices} values={values} />
      <ConfirmationDialog
        open={open}
        decline={toggleDialog}
        accept={handleSubmit}
      />
    </SectionContainer>
  );
};

export default NotificationPreferences;
