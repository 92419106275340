import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import downloadCloud from "../../../../../Images/ServicesAndReturns/DownloadCloud.png";

const PREFIX = "InputButton";

const classes = {
  uploadButton: `${PREFIX}-uploadButton`,
  buttonText: `${PREFIX}-buttonText`,
};

const Root = styled("div")(() => ({
  [`& .${classes.uploadButton}`]: {
    width: 70,
    height: 53,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 3,
    border: "1px solid #8692A6",
    borderRadius: 6,
    cursor: "pointer",
  },

  [`& .${classes.buttonText}`]: {
    fontSize: 10,
    fontWeight: 400,
    color: "#8692A6",
  },
}));

const InputButton = ({ selectFile }) => {
  return (
    <Root>
      <label htmlFor="file-upload3" className={classes.uploadButton}>
        <img src={downloadCloud} width="28px" height="28px" alt="" />
        <Typography className={classes.buttonText}>Görsel Yükle</Typography>
      </label>
      <input
        id="file-upload3"
        type="file"
        onChange={selectFile}
        accept="application/pdf, image/jpeg, image/png"
        className={classes.input}
        style={{ display: "none" }}
      />
    </Root>
  );
};

InputButton.propTypes = {
  selectFile: PropTypes.func,
};

export default InputButton;
