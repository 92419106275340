import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(),
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: 233,
  height: 46,
  color: "#fff",
  fontSize: 14,
  lineHeight: "17px",
  borderRadius: 4,
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ButtonsArea = ({ clearFilters, handleFiltersSubmit }) => {
  return (
    <Root>
      <IconButton onClick={clearFilters}>
        <DeleteIcon />
      </IconButton>
      <StyledButton onClick={handleFiltersSubmit}>Uygula</StyledButton>
    </Root>
  );
};

ButtonsArea.propTypes = {
  clearFilters: PropTypes.func,
  handleFiltersSubmit: PropTypes.func,
};

export default ButtonsArea;
