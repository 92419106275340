import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import ActionButtons from "./ActionButtons";
import { productActions } from "../../Redux/Actions";
import FeaturesUpdateDialog from "./CartProductInner/FeaturesUpdateDialog";
import QuantityArea from "./CartProductInner/QuantityArea";
import FeaturesButton from "./CartProductInner/FeaturesButton";
import CollapseContent from "./CartProductInner/CollapseContent";

const PREFIX = "CartProduct";

const classes = {
  productContainer: `${PREFIX}-productContainer`,
  flexColumn: `${PREFIX}-flexColumn`,
  flexSide: `${PREFIX}-flexSide`,
  productName: `${PREFIX}-productName`,
  cartPrice: `${PREFIX}-cartPrice`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.productContainer}`]: {
    width: "100%",
    padding: "11px 6px 0px 16px",
    marginBottom: theme.spacing(),
    minWidth: 275,
    maxWidth: 390,
    minHeight: 196,
    height: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
  },

  [`&.${classes.flexColumn}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.flexSide}`]: {
    display: "flex",
  },

  [`& .${classes.productName}`]: {
    fontSize: 16,
    fontWeight: 700,
    color: "#000000",
    marginBottom: theme.spacing(0.5),
    cursor: "pointer",
  },

  [`& .${classes.cartPrice}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5),
  },
}));

const CartProduct = ({ product, deleteProduct }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isFeatures = product?.features;

  const update = (query) => dispatch(productActions.updateProduct(query));
  const updateAdmin = (query, userId) =>
    dispatch(productActions.updateAdminProduct(query, userId));

  const authInfo = useSelector(({ auth }) => auth.customerInfo);
  const customer = useSelector(({ customer }) => customer);
  let isCompanyUser =
    customer?.selectedUser?.role_names[0] === "companyuser" || null;

  const specialFeatureValues = useSelector(
    ({ product }) => product.featureValues
  );

  const [open, setOpen] = useState(false);
  const [extended, setExtended] = useState(false);
  const [initialQuery, setInitialQuery] = useState(null);
  const [selections, setSelections] = useState({
    product_id: "",
    quantity: product?.quantity,
    key: "",
    features: {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    },
  });

  useEffect(() => {
    if (product) {
      setSelections({
        ...selections,
        quantity: product.quantity,
        features: product.features,
      });
      setInitialQuery({
        product_id: product.id,
        quantity: product.quantity,
        features: product.features,
        key: product.key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const closeDialogFromButton = () => {
    let initialFeatures = {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    };
    if (product.features) {
      setSelections(initialQuery);
    } else {
      setSelections({
        ...initialQuery,
        features: initialFeatures,
      });
    }
    closeDialog();
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if (isFeatures) {
        setExtended(true);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatures]);

  const handleClick = () => history.push(`/product/${product.id}`);

  const handleCollapse = () => setExtended(!extended);

  const onClickHandler = () => {
    isFeatures ? handleCollapse() : openDialog();
  };

  const increaseQuantityFromModal = () => {
    setSelections({
      ...selections,
      quantity: selections.quantity + 1,
    });
  };

  const decreaseQuantityFromModal = () => {
    if (selections.quantity === 1) return;
    setSelections({
      ...selections,
      quantity: selections.quantity - 1,
    });
  };

  const handleFeatures = (prop) => (e) => {
    let dimensions = product.attributes_map.Ebat;
    let width = parseInt(dimensions.split("x")[0]);
    let height = parseInt(dimensions.split("x")[1]);
    let data = e.target.value;
    const { shape } = selections.features;
    const maxWidth = +data > +width ? +width : +data;
    const isCircle = shape === "Daire";
    if (
      prop === "side" &&
      data !== "Saçak" &&
      selections.features.webType !== ""
    ) {
      setSelections({
        ...selections,
        features: {
          ...selections.features,
          [prop]: data,
          webType: "",
        },
      });
    } else if (prop === "shape") {
      if (data === "Daire") {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: data,
            height: selections.features.width,
          },
        });
      } else {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: data,
            width: width,
            height: 0,
          },
        });
      }
    } else if (prop === "height") {
      if (product.type.id === 2 && +data > +height) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: isCircle ? maxWidth : +height,
            width: isCircle ? +height : selections.features.width,
          },
        });
      } else {
        let height_ =
          +data > 2500
            ? isCircle
              ? width
              : 2500
            : isCircle && +data > width
            ? width
            : +data;
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: height_,
            width: isCircle ? +height_ : selections.features.width,
          },
        });
      }
    } else if (prop === "width") {
      const currentHeight = isCircle
        ? +data > +width
          ? +width
          : +data
        : selections.features.height;
      const currentWidth = +data > +width ? +width : +data;

      setSelections({
        ...selections,
        features: {
          ...selections.features,
          [prop]: currentWidth,
          height: currentHeight,
        },
      });
    } else {
      setSelections({
        ...selections,
        features: { ...selections.features, [prop]: data },
      });
    }
  };

  const increaseQuantity = () => {
    let query = {
      product_id: product.id,
      quantity: product.quantity + 1,
      features: product.features,
      key: product.key,
    };
    if (customer?.isAdmin) {
      updateAdmin(query, customer?.selectedUser?.id);
    } else {
      update(query);
    }
  };

  const decreaseQuantity = () => {
    let query = {
      product_id: product.id,
      quantity: product.quantity - 1,
      features: product.features,
      key: product.key,
    };
    if (customer?.isAdmin) {
      updateAdmin(query, customer?.selectedUser?.id);
    } else {
      update(query);
    }
  };

  const handleModalSubmit = () => {
    closeDialog();
    let query = {
      ...selections,
      product_id: product.id,
      key: product.key,
    };
    if (customer?.isAdmin) {
      updateAdmin(query, customer?.selectedUser?.id);
    } else {
      update(query);
    }
  };
  return (
    <Root
      className={classnames({
        [classes.productContainer]: true,
        [classes.flexColumn]: true,
      })}
    >
      <div className={classes.flexSide}>
        <img
          src={product?.image}
          alt=""
          width="92.53px"
          height="133px"
          style={{ borderRadius: 8, marginRight: 8.13 }}
        />
        <div className={classes.flexColumn}>
          <Typography className={classes.productName} onClick={handleClick}>
            {product?.name}
          </Typography>
          <>
            {!isCompanyUser && (
              <Typography className={classes.cartPrice}>
                {product?.cost}
              </Typography>
            )}
            <QuantityArea
              product={product}
              decreaseQuantity={decreaseQuantity}
              increaseQuantity={increaseQuantity}
            />
            <FeaturesButton
              isFeatures={isFeatures}
              extended={extended}
              onClickHandler={onClickHandler}
            />
          </>
        </div>
      </div>
      <ActionButtons
        deleteProduct={deleteProduct}
        product={product}
        openDialog={openDialog}
        userId={customer?.isAdmin ? customer?.selectedUser?.id : authInfo?.id}
      />
      {product?.features && (
        <Collapse in={extended}>
          <CollapseContent features={selections?.features} />
        </Collapse>
      )}
      <FeaturesUpdateDialog
        open={open}
        product={product}
        features={selections.features}
        specialFeatureValues={specialFeatureValues && specialFeatureValues}
        handleFeatures={handleFeatures}
        increase={increaseQuantityFromModal}
        decrease={decreaseQuantityFromModal}
        selections={selections}
        handleSubmit={handleModalSubmit}
        closeDialogFromButton={closeDialogFromButton}
        setSelections={setSelections}
      />
    </Root>
  );
};

export default CartProduct;
