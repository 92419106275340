import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import PageContainerMob from "../../Common/PageContainerMob";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  height: "100vh",
  position: "relative",
}));

const Text = styled(Typography)(() => ({
  width: "fit-content",
  height: "auto",
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "24px",
  color: "#707070",
}));

const MobileNotFound = () => {
  return (
    <PageContainerMob>
      <Root>
        <Text>Ürün Bulunamadı!</Text>
      </Root>
    </PageContainerMob>
  );
};

export default MobileNotFound;
