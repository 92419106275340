import { notificationConstants, generalConstants } from "../../Utils/Constants";
import { notificationService } from "../../Services";

const getNotifications = (query) => {
  return (dispatch) => {
    notificationService.getNotifications(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: notificationConstants.GET_NOTIFICATIONS,
            payload: res.data.notifications,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const clearNotificationReducer = () => {
  return {
    type: notificationConstants.CLEAR_NOTIFICATION_REDUCER,
  };
};

export const notificationActions = {
  getNotifications,
  clearNotificationReducer,
};
