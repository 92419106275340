import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PageContainer from "../Common/PageContainer";
import TabButtons from "./Checkout/TabButtons";
import OpenAccount from "./Checkout/OpenAccount";
import BankTransfer from "./Checkout/BankTransfer";
import CheckAndBill from "./Checkout/CheckAndBill";
import CreditCard from "./Checkout/CreditCard";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ErrorDialog from "../CartPage/Dialogs/ErrorDialog";
import SuccessDalog from "../CartPage/Dialogs/SuccessDialog";
import OrderSummary from "../CartPage/OrderSummary";
import AddressSelection from "../CartPage/Dialogs/AddressSelection";
import AddAddressDialog from "../CartPage/Dialogs/AddAddressDialog";

import { addressService } from "../../Services";

import Loading from "../../Utils/Loading";

import { orderActions, addressActions } from "../../Redux/Actions";

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minWidth: 870,
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(3),
  lineHeight: "30px",
}));

const Root = styled("div")(() => ({
  width: "100%",
  marginBottom: 50,
  display: "flex",
  minWidth: 1212,
  overflow: "hidden",
  justifyContent: "space-between",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "content-fit",
  height: "auto",
  backgroundColor: "#fff",
  color: theme.palette.darkGray.main,
  textTransform: "none",
  fontSize: 12,
  fontWeight: 500,
  // marginRight: 40,
  border: `1px solid ${theme.palette.darkGray.main}`,
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const Checkout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const creditCardRef = useRef();
  const openAccountRef = useRef();

  const paymentMethods = useSelector(
    ({ customer }) => customer.currentCompany.payment_methods
  );
  const orderLoading = useSelector(({ orders }) => orders.loading);
  const success = useSelector(({ orders }) => orders.success);
  const orderError = useSelector(({ orders }) => orders.error);
  const customer = useSelector(({ customer }) => customer);

  const clearStatus = () => dispatch(orderActions.clearStatus());
  const openAddressSelectionModal = () => setShowAddressSelection(true);
  const closeAddressSelectionModal = () => setShowAddressSelection(false);
  const getAllAddresses = (query) =>
    dispatch(addressActions.getAddresses(query));

  const [index, setIndex] = useState(0);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [error, setError] = useState(false);
  const [showAddresSelection, setShowAddressSelection] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [values, setValues] = useState({
    city_id: null,
    county_id: null,
    first_name: "",
    last_name: "",
    phone1: "",
    phone2: "",
    title: "",
    address1: "",
    email: "",
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (success) {
        setSuccessDialog(true);
      }
    }
    return () => {
      active = false;
    };
  }, [success]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (orderError) {
        setErrorDialog(true);
      }
    }
    return () => {
      active = false;
    };
  }, [orderError]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (paymentMethods.length > 0) {
        let isDefault = paymentMethods.find((pm) => pm.is_default === 1);
        isDefault ? setIndex(isDefault.id) : setIndex(paymentMethods[0].id);
      }
    }
    return () => {
      active = false;
    };
  }, [paymentMethods]);

  const handleSuccessDialog = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/order-page");
  };

  const closeSuccessDialog = () => setSuccessDialog(false);
  const closeErrorDialog = () => setErrorDialog(false);

  const openAddAddressModal = () => setOpenAddAddress(true);
  const closeAddAddressModal = () => setOpenAddAddress(false);

  const closeAddAddressFromButton = () => {
    closeAddAddressModal();
    setValues({
      ...values,
      city_id: "",
      county_id: "",
      first_name: "",
      last_name: "",
      phone1: "",
      phone2: "",
      title: "",
      address1: "",
      email: "",
    });
  };

  const handleErrorDialog = () => {
    clearStatus();
    closeErrorDialog();
  };

  const handleSubmit = () => {
    closeAddressSelectionModal();
  };

  const goToHome = () => {
    clearStatus();
    closeSuccessDialog();
    history.push("/home");
  };
  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleError = () => {
    if (
      values.first_name === "" ||
      values.last_name === "" ||
      values.phone1.length !== 12 ||
      values.title === "" ||
      values.address1 === "" ||
      values.city_id === "" ||
      values.county_id === ""
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleAddAddress = () => {
    let isError = handleError();
    if (isError) return;
    let addressQuery = {
      pageNumber: 1,
      pageSize: 200,
      sortField: "id",
      sordOrder: "desc",
      filter: {},
    };
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.phone1).replace(/\s/g, "");
    let newPhone2 = phoneCode.concat(values.phone2).replace(/\s/g, "");

    let query = {
      city_id: values.city_id.id,
      county_id: values.county_id.id,
      type: 1,
      company_id: customer?.currentCompany.id,
      email: values.email,
      name: `${values.first_name} ${values.last_name}`,
      phone1: newPhone,
      phone2: newPhone2,
      address1: values.address1,
      address2: "",
      tax_office: "",
      tax_number: "",
    };

    let isAdmin = customer?.isAdmin;
    let userId = customer?.selectedUser?.id;

    let query_ = isAdmin ? { ...query, user_id: userId } : query;

    closeAddAddressFromButton();
    addressService.addAddress(query_, isAdmin).then((res) => {
      if (res.data.status === true) {
        getAllAddresses(addressQuery);
      }
    });
  };

  const returnToCart = () => history.push("/cart-page");

  const placeOrder = (text) => {
    index === 1
      ? creditCardRef.current.placeOrder(text)
      : openAccountRef.current.placeOrder(text);
  };

  const contents = {
    1: <CreditCard ref={creditCardRef} />,
    2: <BankTransfer />,
    3: <CheckAndBill />,
    4: <OpenAccount ref={openAccountRef} />,
  };
  return (
    <PageContainer noFooter noScroll>
      <Loading open={orderLoading} />
      <Root>
        <div>
          <HeaderContainer>
            <Header>Ödeme Bilgileri</Header>
            <StyledButton
              disableRipple
              onClick={returnToCart}
              variant="outlined"
              startIcon={<ArrowBackIcon />}>
              Sepete Dön
            </StyledButton>
          </HeaderContainer>

          <TabButtons index={index} setIndex={setIndex} />
          {contents[index]}
        </div>
        <OrderSummary
          openAddressSelectionModal={openAddressSelectionModal}
          openAddAddressModal={openAddAddressModal}
          checkout
          placeOrder={placeOrder}
        />
      </Root>
      <SuccessDalog
        successDialog={successDialog}
        goToHome={goToHome}
        handleSuccessDialog={handleSuccessDialog}
        currentAddress={customer.currentAddress}
      />
      <ErrorDialog
        errorDialog={errorDialog}
        handleErrorDialog={handleErrorDialog}
      />
      <AddressSelection
        open={showAddresSelection}
        onClose={closeAddressSelectionModal}
        handleSubmit={handleSubmit}
        openAddAddressModal={openAddAddressModal}
      />
      <AddAddressDialog
        onClose={closeAddressSelectionModal}
        open={openAddAddress}
        closeDialogFromButton={closeAddAddressFromButton}
        values={values}
        handleChange={handleChange}
        handleSubmit={handleAddAddress}
        error={error}
        setError={setError}
        setValues={setValues}
      />
    </PageContainer>
  );
};

export default Checkout;
