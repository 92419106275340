import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { errorTexts } from "../../../../Utils/Constants";

import CustomMask from "./CustomMask";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "auto",
}));

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  width: "100%",
  height: 45,
  "& .MuiInputBase-root": {
    height: 45,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 8,
    height: 35,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 8,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[700],
  marginTop: 10,
  marginBottom: 5,
  marginLeft: 1,
  userSelect: "none",
}));

const StyledHelperText = styled(FormHelperText)(() => ({
  fontSize: 8,
  color: "red",
  userSelect: "none",
  "&.MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

const CustomField = ({
  label,
  handleChange,
  values,
  prop,
  error,
  disabled,
  placeholder,
}) => {
  let errorStatus =
    prop === "cardNumber"
      ? values.cardNumber.length !== 19 ||
        values.cardNumber === "**** **** **** ****"
      : prop === "expiryDate"
      ? values.expiryDate.length !== 5 || values.expiryDate === "  /  "
      : prop === "cvc"
      ? values.cvc.length !== 3 || values.cvc === "   "
      : false;

  return (
    <Root>
      <StyledLabel>{label}</StyledLabel>
      <FormControl style={{ width: "100%" }}>
        <StyledTextField
          disabled={disabled}
          fullWidth
          value={values[prop] || ""}
          placeholder={placeholder}
          name={prop}
          onChange={handleChange}
          error={error && errorStatus}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            inputComponent: CustomMask,
          }}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
        <StyledHelperText>
          {error && errorStatus ? errorTexts[prop] : ""}
        </StyledHelperText>
      </FormControl>
    </Root>
  );
};

CustomField.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  prop: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default CustomField;
