import React from "react";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import PropTypes from "prop-types";

const PaperComponent = (props) => {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
};

PaperComponent.propTypes = {
  props: PropTypes.any,
};

export default PaperComponent;
