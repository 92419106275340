import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getAddresses = async (query) => {
  return await axios
    .post(`${API_URL}/address/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getCities = async () => {
  return await axios
    .get(`${API_URL}/address/cities/list`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getCounties = async (id) => {
  return await axios
    .get(`${API_URL}/address/counties/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getDistricts = async (id) => {
  return await axios
    .get(`${API_URL}/address/districts/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const addAddress = async (query, isAdmin) => {
  let url = isAdmin ? `${API_URL}/address/admin/add` : `${API_URL}/address/add`;
  return await axios.post(url, query, authCustomerHeader()).then((res) => {
    return res;
  });
};
// address/admin/list query={..., filter: { company_id ya da user_id}}
const getCompanyAddresses = async (query, id) => {
  return await axios
    .post(`${API_URL}/address/list/company/${id}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getUserAddresses = async (query, id) => {
  return await axios
    .post(`${API_URL}/address/list/user/${id}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};
// ----------------------------------------------------------------------//
const getAdminUserAndCompanyAddresses = async (query) => {
  return await axios
    .post(`${API_URL}/address/admin/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

// ----------------------------------------------------------------------//

const toggleDefaultAddress = async (query, userId) => {
  return await axios
    .put(
      `${API_URL}/address/admin/toggle/default/${userId}`,
      query,
      authCustomerHeader()
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

const selfToggleAddress = async (query) => {
  return await axios
    .put(`${API_URL}/address/toggle/default`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const deactivateAddress = async (id) => {
  return await axios
    .delete(`${API_URL}/address/delete/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getCompaniesInCities = async (query) => {
  return await axios
    .post(`${API_URL}/address/companies/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const addressService = {
  getAddresses,
  getCities,
  getCounties,
  getDistricts,
  addAddress,
  getCompanyAddresses,
  toggleDefaultAddress,
  selfToggleAddress,
  getUserAddresses,
  getAdminUserAndCompanyAddresses,
  deactivateAddress,
  getCompaniesInCities,
};
