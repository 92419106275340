import React from "react";
import { styled } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { isMobileOnly } from "react-device-detect";

import "react-toastify/dist/ReactToastify.min.css";

import successIcon from "../../Images/Notify/SuccessIcon.svg";
import infoIcon from "../../Images/Notify/InfoIcon.svg";
import errorIcon from "../../Images/Notify/ErrorIcon.svg";
import warningIcon from "../../Images/Notify/WarningIcon.svg";
import addedToCart from "../../Images/Notify/AddedToCart.png";

const StyledToastContainer = styled(ToastContainer)(({ theme }) => ({
  width: isMobileOnly ? "100%" : "299px",
  height: 80,
  "& .Toastify__toast": {
    pading: 0,
    right: !isMobileOnly ? 65 : 0,
    left: isMobileOnly && 0,
    top: isMobileOnly && 0,
    cursor: "default",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    "&:before": {
      content: '""',
      // margin: "auto 0",
      width: "110px",
      height: "65px",
      backgroundSize: "60px 60px",
      backgroundRepeat: "no-repeat",
      opacity: ".9",
      marginLeft: "6px",
      display: "inline-table",
      backgroundImage: `url("${addedToCart}")`,
      marginTop: 5,
    },
  },
  "& .Toastify__toast-body": {
    margin: "auto 0px",
    // padding: "6px",
    width: "100%",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 700,
    color: theme.palette.darkGray.main,
    fontSize: 12,
  },
  "& .Toastify__toast--success": {
    color: "#3c763d",
    background: "#dff0d8",
    "&:before": {
      marginLeft: "10px !important",
      padding: "10px !important",
      margin: "auto 0 !important",
      width: "21px !important",
      height: "21px !important",
      backgroundSize: "21px !important",
      backgroundImage: `url("${successIcon}") !important`,
    },
    "& .Toastify__close-button > svg": {
      fill: "#3c763d",
    },
    "& .Toastify__toast-body": {
      paddingLeft: 20,
    },
  },
  "& .Toastify__toast--error": {
    color: "#721c24",
    background: "#f8d7da",
    "&:before": {
      marginLeft: "10px !important",
      padding: "10px !important",
      margin: "auto 0 !important",
      width: "21px !important",
      height: "21px !important",
      backgroundSize: "21px !important",
      backgroundImage: `url("${errorIcon}") !important`,
    },
    "& .Toastify__close-button > svg": {
      fill: "#721c24",
    },
    "& .Toastify__toast-body": {
      paddingLeft: 20,
    },
  },
  "& .Toastify__toast--info": {
    color: "#31708f",
    background: "#d9edf7",
    "&:before": {
      marginLeft: "10px !important",
      padding: "10px !important",
      margin: "auto 0 !important",
      width: "21px !important",
      height: "21px !important",
      backgroundSize: "21px !important",
      backgroundImage: `url("${infoIcon}") !important`,
    },
    "& .Toastify__close-button > svg": {
      fill: "#31708f",
    },
    "& .Toastify__toast-body": {
      paddingLeft: 20,
    },
  },
  "& .Toastify__toast--warning": {
    color: "#A89263",
    background: "#FCF8E2",
    "&:before": {
      marginLeft: "10px !important",
      padding: "10px !important",
      margin: "auto 0 !important",
      width: "21px !important",
      height: "21px !important",
      backgroundSize: "21px !important",
      backgroundImage: `url("${warningIcon}") !important`,
    },
    "& .Toastify__close-button > svg": {
      fill: "#A89263",
    },
    "& .Toastify__toast-body": {
      paddingLeft: 20,
    },
  },
  "& .Toastify__toast--dark": {
    color: "#A89263",
    background: "#fff",
    "&:before": {
      marginLeft: "10px !important",
      padding: "10px !important",
      margin: "auto 0 !important",
      width: "21px !important",
      height: "21px !important",
      backgroundSize: "21px !important",
      backgroundImage: `url("${warningIcon}") !important`,
    },
    "& .Toastify__close-button > svg": {
      fill: "#A89263",
    },
    "& .Toastify__toast-body": {
      paddingLeft: 20,
    },
  },
  "& .Toastify__close-button": {
    padding: "24px",
    borderRadius: "50%",
    alignSelf: "center",
    position: "relative",
    opacity: ".3",
    "&:hover": {
      background: "rgba(0,0,0,0.03);",
      opacity: "1",
    },
    "&:active, &:focus": {
      background: "rgba(0,0,0,0.08);",
    },
    "& > svg": {
      height: "22px",
      width: "22px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      marginLeft: "1px",
      fill: "#000",
    },
  },
  "& .Toastify__progress-bar": {
    opacity: ".7 !important",
  },
}));

const B2BToastContainer = () => {
  return (
    <StyledToastContainer
      position="top-right"
      autoClose={5000}
      limit={3}
      closeOnClick={false}
      draggable
      pauseOnHover
    />
  );
};

export default B2BToastContainer;
