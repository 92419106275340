import { customerConstants, authConstants } from "../../Utils/Constants";

let localCustomerData = JSON.parse(
  localStorage.getItem(customerConstants.CUSTOMER)
);

const initialState = {
  signedIn: localCustomerData ? true : false,
  customerInfo: localCustomerData ? localCustomerData.user : {},
  loading: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.SIGN_IN_SUCCESS:
      return {
        ...state,
        signedIn: true,
        customerInfo: action.payload,
        loading: false,
      };
    case authConstants.SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case authConstants.SIGN_OUT:
      return {
        ...state,
        signedIn: false,
        customerInfo: {},
        loading: false,
      };
    case authConstants.UPDATE_USER_NAME: {
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          first_name: action.query.first_name,
          last_name: action.query.last_name,
          full_name: `${action.query.first_name} ${action.query.last_name}`,
        },
      };
    }
    case authConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case authConstants.USER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case authConstants.UPDATE_ADDRESS_BY_TOGGLE:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          address: action.address,
        },
      };
    default:
      return state;
  }
};

export { auth };
