import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { addressService } from "../../../Services";

import TextArea from "../../../Ui/Fields/TextArea";
import PhoneArea from "../../../Ui/Fields/PhoneArea";
import AutoCompleteArea from "../../../Ui/Fields/AutocompleteArea";

const PREFIX = "AddAddressDialog";

const classes = {
  paper: `${PREFIX}-paper`,
  content: `${PREFIX}-content`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  submitButton: `${PREFIX}-submitButton`,
  header: `${PREFIX}-header`,
  submitButtonContainer: `${PREFIX}-submitButtonContainer`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 444,
    minHeight: 600,
    height: "auto",
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "100%",
    paddingTop: 0,
    position: "relative",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.submitButton}`]: {
    // position: "absolute",
    // bottom: 20,
    // left: "50%",
    // transform: "translate(-50%, 0)",
    width: 200,
    height: 32,
    backgroundColor: theme.palette.darkGray.main,
    color: "#fff",
    fontSize: 16,
    textTransform: "none",
    borderRadius: 6,
    "&:hover": {
      backgroundColor: theme.palette.darkGray.main,
    },
  },

  [`& .${classes.header}`]: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.darkGray.main,
    marginTop: 10,
  },

  [`& .${classes.submitButtonContainer}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
}));

const AddAddressDialog = ({
  open,
  closeDialogFromButton,
  handleSubmit,
  handleChange,
  values,
  error,
  setError,
  setValues,
}) => {
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    if (open) {
      getCities();
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (values?.city_id) {
      getCounties(values.city_id.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.city_id]);

  const getCities = () => {
    addressService
      .getCities()
      .then((res) => setCities(res.data.cities))
      .catch((err) => console.log(err));
  };

  const getCounties = (id) => {
    addressService
      .getCounties(id)
      .then((res) => {
        setCounties(res.data.counties);
      })
      .catch((err) => console.log(err));
  };

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={closeDialogFromButton}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <Typography className={classes.header}>Adres Ekle</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextArea
          label="Adres Başlığı"
          handleChange={handleChange}
          values={values}
          prop="title"
          error={error}
        />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextArea
              label="Ad"
              handleChange={handleChange}
              values={values}
              prop="first_name"
              error={error}
            />
          </Grid>
          <Grid item xs={6}>
            <TextArea
              label="Soyad"
              handleChange={handleChange}
              values={values}
              prop="last_name"
              error={error}
            />
          </Grid>
        </Grid>
        <PhoneArea
          label="Telefon"
          handleChange={handleChange}
          values={values}
          prop="phone1"
          error={error}
        />
        <PhoneArea
          label="Telefon - 2"
          handleChange={handleChange}
          values={values}
          prop="phone2"
        />
        <TextArea
          label="Eposta"
          handleChange={handleChange}
          values={values}
          prop="email"
        />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AutoCompleteArea
              data={cities || []}
              label="İl"
              setValues={setValues}
              prop="city_id"
              values={values}
              error={error}
              customWidth={188}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoCompleteArea
              data={counties || []}
              label="İlçe"
              setValues={setValues}
              prop="county_id"
              values={values}
              error={error}
              customWidth={188}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item xs={6}>
            <AutoCompleteArea
              data={districts}
              label="Mahalle"
              setValues={setValues}
              prop="district_id"
              values={values}
              error={error}
              customWidth={188}
            />
          </Grid>
        </Grid> */}
        <TextArea
          label="Adres"
          handleChange={handleChange}
          values={values}
          prop="address1"
          error={error}
          multiline
          rows={4}
        />
        <div className={classes.submitButtonContainer}>
          <Button
            className={classes.submitButton}
            disableRipple
            // disabled={buttonDisabledStatus}
            onClick={handleSubmit}
            variant="contained">
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

AddAddressDialog.propTypes = {
  open: PropTypes.bool,
  closeDialogFromButton: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  error: PropTypes.bool,
  setError: PropTypes.func,
  setValues: PropTypes.func,
};

export default AddAddressDialog;
