import React from "react";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import WebNotFound from "./NotFound/WebNotFound";
import MobileNotFound from "./NotFound/MobileNotFound";

const NotFound = () => {
  const history = useHistory();

  const goToHome = () => history.push("/home");

  const getContent = () => {
    if (isMobileOnly) {
      return <MobileNotFound goToHome={goToHome} />;
    }
    return <WebNotFound goToHome={goToHome} />;
  };

  return getContent();
};

export default NotFound;
