import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

const CustomMask = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  let content = {
    cardNumber: "0000 0000 0000 0000",
    expiryDate: "00/00",
    cvc: "000",
  };
  return (
    <IMaskInput
      {...other}
      mask={content[props.name]}
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

CustomMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomMask;
