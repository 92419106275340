import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";

import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import AuthContainerMob from "./AuthContainerMob";
import Label from "./AuthCommons/Label";
import ForgotPasswordText from "./AuthCommons/ForgotPasswordText";
import CustomDivider from "../../Ui/Divider/CustomDivider";
import {
  CustomTextField,
  SubmitButton,
  RouteButton,
} from "./AuthCommons/Tools";
import smsIcon from "../../Images/Auth/sms2.svg";

import { authActions } from "../../Redux/Actions";
import Loading from "../../Utils/Loading";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  maxHeight: 478,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: 0,
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: 20,
  height: 20,
}));

const StyledIcon = styled("img")(() => ({
  marginRight: 0,
}));

const StyledSpan = styled("span")(() => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  paddingRight: 24,
}));

const StyledCard = styled("div")(() => ({
  width: "95%",
  background: "rgba(133, 89, 122, 0.1)",
  borderRadius: 8,
  height: 220,
  paddingTop: 17,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const SigninEmailMob = () => {
  let history = useHistory();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const dispatch = useDispatch();

  const signIn = (query) => dispatch(authActions.signin(query));

  const loading = useSelector(({ auth }) => auth.loading);

  const [values, setValues] = useState({
    password: "",
    email: "",
    showPassword: false,
  });
  const [notValid, setNotValid] = useState(true);

  useEffect(() => {
    emailRegex.test(values.email) && values.password.length >= 6
      ? setNotValid(false)
      : setNotValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email, values.password]);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    let query = {
      email: values.email,
      password: values.password,
    };
    signIn(query);
  };

  const handleRoute = () => {
    history.push("/signin-phone");
  };

  return (
    <AuthContainerMob signinLogo>
      <Loading open={loading} />
      <Root>
        <StyledCard>
          <Label mobile>E-posta Adresi</Label>
          <CustomTextField
            value={values.email}
            onChange={handleChange("email")}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            style={{ marginBottom: 8.21, width: "82%" }}
          />
          <Label mobile>Şifre</Label>
          <CustomTextField
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </StyledIconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: 23, width: "82%" }}
          />
          <SubmitButton
            variant="contained"
            disableRipple
            onClick={handleSubmit}
            disabled={notValid}
            style={{ width: "82%", marginBottom: 20 }}>
            Giriş Yap
          </SubmitButton>
        </StyledCard>
        <ForgotPasswordText
          component="siginin-email"
          mobile/>
        <CustomDivider mobile>
          veya
        </CustomDivider>
        <RouteButton
          style={{ width: "82%" }}
          variant="contained"
          disableRipple
          onClick={handleRoute}>
          <StyledIcon
            src={smsIcon}
            alt="smsIcon"
            width="24px"
            height="20.94px"
          />
          <StyledSpan>Sms ile giriş yap</StyledSpan>
        </RouteButton>
      </Root>
    </AuthContainerMob>
  );
};

export default SigninEmailMob;
