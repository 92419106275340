import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeader from "../Tools/MobileDialogHeader";

const innerWidth = window.innerWidth;

const TableCommon = {
  border: "1px solid #BDBDBD",
  width: "33%",
  fontSize: 12,
  lineHeight: "18px",
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
    paddingTop: theme.spacing(),
    margin: 0,
    overflow: "hidden",
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const InfoBox = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(),
  backgroundColor: "#F5F3FF",
  borderRadius: 8,
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  lineHeight: "16px",
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  width: "100%",
  height: "auto",
  textAlign: "center",
  fontWeight: "normal",
  fontSize: 14,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  marginBottom: theme.spacing(),
}));

const TableContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 300,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  padding: theme.spacing(),
  overflow: "auto",
}));

const StyledTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  marginTop: theme.spacing(),
}));

const StyledTh = styled("th")(({ theme }) => ({
  textAlign: "center",
  ...TableCommon,
  fontWeight: 700,
  backgroundColor: theme.palette.darkGray.light,
  height: 23,
  color: theme.palette.darkGray.main
}));

const StyledTd = styled("td", {
  shouldForwardProp: (prop) => prop !== "center",
})(({ theme, center }) => ({
  textAlign: center ? "center" : "left",
  ...TableCommon,
  fontWeight: 400,
  height: 30,
  paddingLeft: !center && theme.spacing(),
  color: theme.palette.darkGray.main
}));

const StyledList = styled("li")(({ theme }) => ({
  "&::marker": {
    color: theme.palette.primary.main,
  },
}));

const Info = styled(Typography)(({ theme }) => ({
  width: "100%",
  height: "auto",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  textAlign: "center",
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
}));

const Stocks = ({ handleClose, open, data }) => {
  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeader header={data?.name} callback={handleClose} />
      <HeaderText>Depodaki ruloların mevcut miktarları</HeaderText>
      <InfoBox>
        <ul style={{ margin: 0, paddingLeft: 16 }}>
          <StyledList>
            <Text>
              Kesme ürünlerde stok miktarı, depodaki tüm ruloların toplamını
              göstermektedir.
            </Text>
          </StyledList>
          <StyledList>
            <Text>
              Sipariş vermeden önce rulo miktar detaylarını kontrol ediniz.
            </Text>
          </StyledList>
        </ul>
      </InfoBox>
      {data?.length === 0 ? (
        <Info>Lot Bilgisi Bulunamadı!</Info>
      ) : (
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <StyledTh>Rulo</StyledTh>
                <StyledTh>Miktar</StyledTh>
                <StyledTh>Birim</StyledTh>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <StyledTd>{item.name}</StyledTd>
                  <StyledTd center>{item.quantity}</StyledTd>
                  <StyledTd center>MT</StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </TableContainer>
      )}
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleClose}
        noMargin>
        Kapat
      </MobileDialogButton>
    </StyledDialog>
  );
};

Stocks.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.array,
};

export default Stocks;
