const handleDataArray = (data, param) => {
  let dataArray = [];
  data.map((item) => dataArray.push(item[param]));
  return uniqueItems(dataArray);
};

const uniqueItems = (arr) => {
  return [...new Set(arr)];
};

const handleActiveItems = (data, param, value) => {
  let newArr = data.filter((item) => item[param] === value);
  return newArr;
};

const handleVariants = (data) => {
  if (!data?.has_variants || data?.variants?.length === 0) return null;
  let variants = data.variants;
  let filteredVariants = variants?.filter((v) => v.on_sale === true);
  let arr = [];
  let newVariant = createVariant(data);
  filteredVariants?.length > 0 &&
    filteredVariants.forEach((item) => arr.push(createVariant(item)));
  arr.unshift(newVariant);
  return arr;
};

const createVariant = (data) => {
  let variant = {};
  let currentImageData =
    data.images && data.images?.length !== 0 ? data.images : data.image;
  let values = data.attributes_map;
  variant["Ebat"] = values["Ebat"];
  variant["Renk"] = values["Renk"];
  variant["Şekil"] = values["Şekil"];
  variant["price"] = data?.price?.view_price;
  variant["product_id"] = data?.id;
  variant["stock"] = parseInt(data?.stock?.quantity);
  variant["image"] = currentImageData;

  return variant;
};

export { handleDataArray, handleActiveItems, handleVariants };
