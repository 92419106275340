import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import grayCheckbox from "../../../../../../Images/Filter/GrayCheckbox.svg";
import Checked from "../../../../../../Images/Checked.svg";

import {
  customLowerCase,
  customUpperCase,
} from "../../../../../../Utils/Helpers/CustomLetterConversion";

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "searchable",
})(({ searchable, theme }) => ({
  width: "100%",
  paddingBottom: theme.spacing(),
  overflowY: "scroll",
  marginTop: theme.spacing(),
  "&[section-name='Users']": {
    maxHeight: searchable ? 125 : 155,
  },
  "&[section-name='Firma']": {
    maxHeight: searchable ? 155 : 185,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  height: 25,
  margin: `${theme.spacing()} 0px ${theme.spacing()}`,
  "& .MuiInputBase-input": {
    borderRadius: 8,
    padding: "3.5px 8px",
    border: "1px solid rgba(177, 174, 174, 1)",
    fontSize: 14,
    fontWeight: 300,
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: "#fff",
      border: "1px solid rgba(177, 174, 174, 1)",
    },
  },
}));

const StyledAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 5,
  color: "rgba(189, 189, 189, 1)",
  cursor: "pointer",
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 30,
  cursor: "pointer",
  zIndex: 9999,
}));

const Section = ({
  data,
  callBack,
  values,
  searchable,
  component,
  stateSetter,
}) => {
  const [searchText, setSearchText] = useState("");
  const [keyword, setKeyword] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (searchText !== "") {
      let customLower = customLowerCase(searchText);
      let customUpper = customUpperCase(searchText);

      let customInitialUpper =
        customUpperCase(searchText.charAt(0)) + searchText.slice(1);

      let customInitialLower =
        customLowerCase(searchText.charAt(0)) + searchText.slice(1);

      let initialUpper = customLower.replace(
        /(^\w{1})|(\s{1}\w{1})/g,
        (match) => customUpperCase(match)
      );

      let initialUpperAll =
        customUpperCase(customLower.charAt(0)) + customLower.slice(1);

      let newProductsList = data.filter(
        (product) =>
          product.name.includes(searchText) ||
          product.name.includes(customInitialLower) ||
          product.name.includes(customInitialUpper) ||
          product.name.includes(customLower) ||
          product.name.includes(customUpper) ||
          product.name.includes(initialUpperAll) ||
          product.name.includes(initialUpper)
      );

      setProducts(newProductsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearchText = (text) => setSearchText(text);
  const clearSearchText = () => {
    setSearchText("");
    setKeyword("");
  };
  const handleKeyword = (e) => setKeyword(e.target.value);

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchText(e.target.value);
    }
  };

  const getLabel = (text, item) => {
    if (text === "Users") {
      return item.full_name;
    } else if (text === "Firma") {
      return item.name;
    } else {
      return item.status;
    }
  };

  let boxValues = searchText !== "" ? products : data;

  return (
    <div style={{ height: component === "Firma" && 210 }}>
      {searchable && (
        <StyledTextField
          fullWidth
          variant="standard"
          value={keyword}
          onChange={handleKeyword}
          onKeyDown={keyPress}
          // disabled={disabled}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {searchText.length > 0 && (
                  <CloseAdornment position="end" onClick={clearSearchText}>
                    <Fade in={searchText.length > 0}>
                      <CloseIcon style={{ fill: "#8E8E8E" }} />
                    </Fade>
                  </CloseAdornment>
                )}
                <StyledAdornment
                  position="end"
                  onClick={() => handleSearchText(keyword)}>
                  <SearchIcon />
                </StyledAdornment>
              </>
            ),
          }}
        />
      )}
      <StyledFormControl
        component="fieldset"
        searchable={searchable}
        section-name={component}>
        <FormGroup>
          {boxValues?.map((item, index) => (
            <FormControlLabel
              style={{ marginBottom: 4 }}
              key={index}
              control={
                <Checkbox
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    margin: "0px 0px 0px 8px",
                    "&:hover, &.Mui-focusVisible": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                  disableRipple
                  checked={values?.some((id) => id === item.id)}
                  onChange={() => callBack(item.id, values, stateSetter)}
                  id={item.id.toString()}
                  name={item.name}
                  value={item.id}
                  icon={<img src={grayCheckbox} width="20px" alt="" />}
                  checkedIcon={<img src={Checked} width="20" alt="" />}
                />
              }
              label={getLabel(component, item)}
            />
          ))}
        </FormGroup>
      </StyledFormControl>
    </div>
  );
};

Section.propTypes = {
  data: PropTypes.array,
  callBack: PropTypes.func,
  values: PropTypes.array,
  searchable: PropTypes.bool,
  component: PropTypes.string,
  stateSetter: PropTypes.func,
};

export default Section;
