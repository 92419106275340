import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
// import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import prevIcon from "../../Images/ProductPage/PrevIcon.svg";
import nextIcon from "../../Images/ProductPage/NextIcon.svg";

import { campaignActions } from "../../Redux/Actions";

const Root = styled("div")(() => ({
  width: "100%",
  minWidth: 1120,
  height: 560,
  backgroundColor: "#F6F4F9",
  borderRadius: 8,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 30,
}));

const StyledCarousel = styled(Carousel)(() => ({
  width: "100%",
  height: 450,
  borderRadius: 8,
  "& .carousel .thumbs": {
    margin: 0,
    display: "flex",
    paddingLeft: 10,
    justifyContent: "center",
  },
  "& .carousel .thumb": {
    transition: "none",
    border: "none",
    height: "44px !important",
    width: "70px !important",
    minWidth: 70,
    borderRadius: 8,
    cursor: "pointer",
  },
  "& .carousel .thumb:hover": {
    border: "none",
  },
  "& .carousel .thumb.selected": {
    border: "2px solid #fff",
    padding: 0,
  },
  "& .carousel .thumbs-wrapper": {
    margin: "15px 0px",
  },
  "& .carousel .control-disabled.control-arrow": {
    display: "flex",
    opacity: 1,
  },
  "& .carousel .control-next.control-arrow:before": {
    position: "absolute",
    right: -25,
    cursor: "pointer",
  },
  "& .carousel .control-prev.control-arrow:before": {
    position: "absolute",
    left: -25,
    cursor: "pointer",
  },
  "& .carousel-root": {
    borderRadius: 8,
  },
  "& .carousel.carousel-slider": {
    borderRadius: 8,
  },
}));

const SlideRoot = styled("div")(() => ({
  width: "100%",
  height: 450,
  backgroundColor: "#fff",
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  padding: 5,
}));

const SlideLeft = styled("div")(() => ({
  flex: 1,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "80px 30px",
}));

const SlideRight = styled("div")(() => ({
  width: 800,
  minWidth: 800,
  height: "100%",
  borderRadius: 8,
  fontSize: 50,
  color: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: 170,
  borderRadius: 25,
  color: "#fff",
  fontWeight: 500,
  backgroundColor: theme.palette.primary.main,
  marginTop: 50,
  textTransform: "none",
  fontSize: 15,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Prev = styled("div")(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "absolute",
  left: 0,
  zIndex: 1,
  top: "-7%",
}));

const Next = styled("div")(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "absolute",
  right: 0,
  zIndex: 1,
  top: "-7%",
}));

const NewCarousel = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getCampaigns = (query) => dispatch(campaignActions.getCampaigns(query));

  const campaigns = useSelector(({ campaigns }) => campaigns.campaigns);

  const [imageArr, setImageArr] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      let query = {
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "desc",
        filter: {},
      };
      getCampaigns(query);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;
    if (active) {
      if (campaigns) {
        let images_ = handleImages(campaigns);
        setImageArr(images_);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns]);

  const handleImages = (data) => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = {};
      obj["original"] = handleOriginal(data[i]);
      obj["thumbnail"] = data[i].image;
      arr.push(obj);
    }
    return arr;
  };

  const handleOriginal = (data) => {
    return (
      <SlideRoot>
        <SlideLeft>
          <Typography align="left" gutterBottom paragraph variant="h4">
            {data.name}
          </Typography>
          {/* <Typography align="left" gutterBottom paragraph variant="h3">
          10% indirim fırsatı
        </Typography> */}
          <StyledButton onClick={() => onCampaignClick(data.id)}>
            İncele
          </StyledButton>
        </SlideLeft>
        <SlideRight>
          <img
            src={data?.image}
            style={{ width: "100%", height: "100%", borderRadius: 6 }}
            alt=""
          />
        </SlideRight>
      </SlideRoot>
    );
  };

  const renderCustomThumbs = () => {
    // const siteUrl = API_URL
    const thumbList = imageArr?.map((product, index) => (
      <picture key={index}>
        <source srcSet={product.thumbnail} type="image/jpg" />
        <img
          key={index}
          src={product.thumbnail}
          alt={""}
          height="40"
          style={{ borderRadius: 5 }}
        />
      </picture>
    ));
    return thumbList;
  };

  const onCampaignClick = (id) => history.push(`./campaign/${id}`);

  return (
    <Root>
      <StyledCarousel
        showArrows={false}
        showStatus={false}
        infiniteLoop={true}
        showThumbs={true}
        showIndicators={false}
        // thumbWidth={70}
        // swipeable={true}
        useKeyboardArrows={true}
        renderThumbs={renderCustomThumbs}
        customWidth={imageArr?.length * 75}
        autoPlay
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Prev>
              <IconButton onClick={onClickHandler} size="large">
                <img alt="" src={prevIcon} />
              </IconButton>
            </Prev>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <Next>
              <IconButton onClick={onClickHandler} size="large">
                <img alt="" src={nextIcon} />
              </IconButton>
            </Next>
          )
        }>
        {imageArr?.map((item, index) => (
          <Card key={index}>{item.original}</Card>
        ))}
      </StyledCarousel>
    </Root>
  );
};

export default NewCarousel;
