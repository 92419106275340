export const orderConstants = {
  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",

  INITIALIZE_ORDER: "INITIALIZE_ORDER",
  INITIALIZE_REQUEST: "INITIALIZE_REQUEST",
  INITIALIZE_SUCCESS: "INITIALIZE_SUCCESS",
  INITIALIZE_FAILURE: "INITIALIZE_FAILURE",
  INITIALIZE_CLEAR: "INITIALIZE_CLEAR",

  CLEAR_ORDERS: "CLEAR_ORDERS",

  SAVE_NOTE: "SAVE_NOTE",
};
