import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import InnerImageZoom from "react-inner-image-zoom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import prevIcon from "../../../../Images/ProductPage/PrevIcon.svg";
import nextIcon from "../../../../Images/ProductPage/NextIcon.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

const PREFIX = "ProductCarousel";

const flexColumn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const flexRow = {
  display: "flex",
  justifyContent: "center",
};

const classes = {
  root: `${PREFIX}-root`,
  imgContainer: `${PREFIX}-imgContainer`,
  smallImages: `${PREFIX}-smallImages`,
  main: `${PREFIX}-main`,
  prevButton: `${PREFIX}-prevButton`,
  nextButton: `${PREFIX}-nextButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "50%",
    minWidth: 400,
    height: "fit-content",
    // padding: "0px 115px 0px 80px",
    ...flexRow,
  },

  [`& .${classes.smallImages}`]: {
    width: "100%",
    height: 137,
    border: "1px solid lightgray",
    ...flexRow,
    alignItems: "center",
  },

  [`& .${classes.main}`]: {
    position: "relative",
    userSelect: "none",
    width: 503,
    "& .slider": {
      height: "auto",
      width: 503,
    },
    "& .slider-wrapper": {
      height: "auto",
      width: 503,
      minWidth: 503,
    },
    "& .thumbs": {
      ...flexRow,
      paddingLeft: 0,
    },
    "& .thumb": {
      width: 53,
      height: 64,
      maxWidth: "53px !important",
      border: `1px solid ${theme.palette.darkGray.light}`,
      borderRadius: 8,
      ...flexColumn,
      alignItems: "center",
      "&:hover": {
        border: "2px solid #6E3564",
      },
    },
    "& .thumb.selected": {
      width: 53,
      height: 64,
      maxWidth: "53px !important",
      border: "2px solid #6E3564",
      borderRadius: 8,
      ...flexColumn,
      "&:hover": {
        border: "2px solid #6E3564",
      },
    },
    "& .carousel .thumb": {
      padding: 0,
      transition: "none",
    },
    "& .carousel.carousel-slider": {
      display: "flex",
    },
    //   "& .carousel .thumb.selected, .carousel .thumb:hover": {
    //     border: `1px solid ${theme.palette.darkGray.light}`,
    // }
  },

  [`& .${classes.prevButton}`]: {
    position: "absolute",
    top: "50%",
    transform: "translate(20%, -50%)",
    height: 40,
    ...flexColumn,
    left: 0,
    zIndex: 2000,
  },

  [`& .${classes.nextButton}`]: {
    position: "absolute",
    top: "50%",
    transform: "translate(-20%, -50%)",
    height: 40,
    ...flexColumn,
    right: 0,
    zIndex: 1,
  },
}));

const ProductCarousel = ({ variant }) => {
  const [heights, setHeights] = useState({});

  useEffect(() => {
    let active = true;
    if (active) {
      if (variant) {
        variant.map((item, index) => {
          return handleImage(item, index);
        });
      }
    }
    return () => {
      active = false;
    };
  }, [variant]);

  const handleImage = (item, index) => {
    let img = new Image();
    img.src = item.url;
    img.onload = () => {
      let ratio = 400 / img.width;
      let newHeight = img.height * ratio;
      setHeights({ [index]: newHeight });
    };
  };
  return (
    <Root className={classes.root}>
      <Carousel
        showArrows={true}
        showStatus={false}
        className={classes.main}
        showIndicators={false}
        infiniteLoop={true}
        dynamicHeight={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div className={classes.prevButton}>
              <IconButton
                onClick={onClickHandler}
                disableRipple
                style={{ backgroundColor: "transparent" }}
                size="large">
                <img alt="" src={prevIcon} width="20.24px" height="34.83" />
              </IconButton>
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <div className={classes.nextButton}>
              <IconButton
                onClick={onClickHandler}
                disableRipple
                style={{ backgroundColor: "transparent" }}
                size="large">
                <img alt="" src={nextIcon} width="20.24px" height="34.83" />
              </IconButton>
            </div>
          )
        }>
        {variant?.map((item, index) => (
          <div key={index} style={{ maxHeight: heights[index] }}>
            <InnerImageZoom
              src={item?.url}
              zoomSrc={item?.url}
              zoomType="hover"
              zoomPreload={true}
              width={400}
              height={heights[index]}
              zoomScale={2}
              hideHint
              // hasSpacer
            />
            <img
              alt=""
              src={item?.url}
              loading="lazy"
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "2px",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
        ))}
      </Carousel>
    </Root>
  );
};

ProductCarousel.propTypes = {
  variant: PropTypes.array,
};

export default ProductCarousel;
