import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import MobileSpecialDemands from "../Dialog/Mobile/MobileSpecialDemands";

import { productActions } from "../../Redux/Actions";
import { generalConstants } from "../../Utils/Constants";
import { productService } from "../../Services";

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  width: "100%",
  whiteSpace: "pre-wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  //   "-webkit-line-clamp": "2",
  WebkitLineClamp: "2",
  lineClamp: "2",
  display: "-webkit-box",
  //   "-webkit-box-orient": "vertical",
  WebkitBoxOrient: "vertical",
  marginBottom: theme.spacing(),
  cursor: "default",
  textAlign: "center",
}));

const StyledImage = styled("img")(() => ({
  width: "90px",
  objectFit: "cover",
  borderRadius: "2px",
}));

const CardAction = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `${theme.spacing()}, ${theme.spacing(2)}`,
}));

const SubContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "direction",
})(({ direction, theme }) => ({
  width: "50%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: direction === "left" ? "flex-start" : "flex-end",
  paddingLeft: direction === "left" && theme.spacing(),
  paddingRight: direction === "right" && theme.spacing(),
  marginBottom: theme.spacing(),
}));

const CardText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "noPrice",
})(({ color, noPrice }) => ({
  fontSize: noPrice ? 10 : 11,
  fontWeight: 400,
  color: color,
}));

const AddButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  width: "100%",
  height: 30,
  borderRadius: 6,
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "normal",
  position: "relative",
  border: "1px solid #6E3564",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const MobileProductCard = ({ item, noMargin }) => {
  const history = useHistory();
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const specialFeatureValues = useSelector(
    ({ product }) => product.featureValues
  );

  const customer = useSelector(({ customer }) => customer);

  const getCart = () => dispatch(productActions.getCart());
  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id));

  const [openModal, setOpenModal] = useState(false);
  const [customWidth, setCustomWidth] = useState(null);
  const [featuresSelected, setFeaturesSelected] = useState(false);
  const [selections, setSelections] = useState({
    product_id: "",
    quantity: 1,
    features: null,
  });

  useEffect(() => {
    let active = true;
    if (active && item) {
      let dimensions = item?.attributes_map?.Ebat;
      let width = parseInt(dimensions?.split("x")[0]);
      let height = parseInt(dimensions?.split("x")[1]);
      let heightIsNan = _.isNaN(height) ? "" : height;
      if (item.type.id === 3) {
        setCustomWidth(width);
        setSelections({
          ...selections,
          product_id: item.id,
          features: {
            width,
            height: heightIsNan,
            shape: "Dikdörtgen",
            side: "Overlok",
            webType: "",
            webColor: "",
          },
        });
      } else {
        setSelections({
          ...selections,
          product_id: item.id,
          features: {
            ...selections.features,
            width,
            height: heightIsNan,
            shape: "Dikdörtgen",
            side: "Overlok",
            webType: "",
            webColor: "",
          },
        });
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const toggleModal = () => setOpenModal(!openModal);

  const closeDialogFromButton = () => {
    toggleModal();
    discardFeaturesSelections();
  };

  const handleFeatures = (prop) => (e) => {
    if (selections.features[prop] === e.target.value) {
      setSelections({
        ...selections,
        features: { ...selections.features, [prop]: "" },
      });
    } else {
      if (
        prop === "side" &&
        e.target.value !== "Saçak" &&
        selections.features.webType !== ""
      ) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: e.target.value,
            webType: "",
          },
        });
      } else if (prop === "width" || prop === "height") {
        let data = parseInt(e.target.value);
        if (prop === "width" && data > customWidth) {
          setSelections({
            ...selections,
            features: { ...selections.features, [prop]: customWidth },
          });
        } else {
          let height_ = data > 2500 ? 2500 : data;
          setSelections({
            ...selections,
            features: { ...selections.features, [prop]: height_ },
          });
        }
      } else {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: e.target.value },
        });
      }
    }
  };

  const handleClick = (id) => {
    history.push(`/product/${id}`);
  };

  const handleAdd = () => {
    if (item.type.id === 3) {
      toggleModal();
    } else {
      handleSubmit();
    }
  };

  const notify = () => (toastId.current = toast("Ürün Sepete Eklendi"));

  const discardFeaturesSelections = () => {
    setSelections({
      ...selections,
      quantity: 1,
      product_id: item.id,
      features: {
        width: customWidth,
        height: "",
        shape: "Dikdörtgen",
        side: "Overlok",
        webType: "",
        webColor: "",
      },
    });
  };

  const handleSubmit = () => {
    openModal && closeDialogFromButton();
    let query =
      item?.type?.id === 3 ? selections : { ...selections, features: null };

    if (customer?.isAdmin) {
      let id = customer?.selectedUser?.id;
      productService
        .addToAdminCart(query, id)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getAdminCart(id);
            notify();
            discardFeaturesSelections();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    } else {
      productService
        .addToCart(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getCart();
            notify();
            discardFeaturesSelections();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleStockText = (item) => {
    if (parseInt(item.stock.quantity) === 0) {
      return "Stok Sor";
    } else {
      return `${item?.stock.quantity}  ${
        item.type.id === 3 ? "Metre" : "Adet"
      }`;
    }
  };

  const handleChange = (prop) => (event) => {
    setSelections({
      ...selections,
      [prop]: event.target.value,
    });
  };

  let priceText =
    item?.price && item?.price.view_price
      ? `${item?.price.view_price}₺`
      : "------";

  return (
    <Box
      sx={{
        width: 150,
        minWidth: 150,
        height: 270,
        p: 1,
        m: !noMargin && 1,
        position: "relative",
        // border: "1px solid #B1AEAE",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "realtive",
        }}
        onClick={() => handleClick(item?.id)}>
        <StyledHeader>{item?.name}</StyledHeader>
        <Box
          sx={{
            width: "100%",
            height: "140px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: (theme) => theme.spacing(),
            backgroundColor: "transparent",
          }}>
          <StyledImage src={item?.image} alt="" />
        </Box>
      </div>
      <CardAction>
        <SubContainer direction="left">
          <CardText
            color={
              parseInt(item?.stock.quantity) === 0
                ? "#C62C2C"
                : parseInt(item?.stock.quantity) > 5
                ? "#10C66C"
                : "#F5B041"
            }>
            {handleStockText(item)}
          </CardText>
        </SubContainer>
        <SubContainer direction="right">
          <CardText noPrice={priceText === "Fiyat Bulunamadı"} color="#434141">
            {priceText}
          </CardText>
        </SubContainer>
      </CardAction>
      <AddButton variant="outlined" onClick={handleAdd}>
        Sepete Ekle
      </AddButton>
      {item.type.id === 3 && selections.features && (
        <MobileSpecialDemands
          handleClose={closeDialogFromButton}
          open={openModal}
          data={item}
          features={selections?.features}
          handleFeatures={handleFeatures}
          specialFeaturesSelected={featuresSelected}
          setSpecialFeaturesSelected={setFeaturesSelected}
          specialFeatureValues={specialFeatureValues && specialFeatureValues}
          handleChange={handleChange}
          selections={selections}
          handleSubmit={handleSubmit}
          setSelections={setSelections}
        />
      )}
    </Box>
  );
};

MobileProductCard.propTypes = {
  item: PropTypes.object,
  noMargin: PropTypes.bool,
};

export default MobileProductCard;
