import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Fade from "@mui/material/Fade";

import CategoryTabs from "./CategoriesMob/CategoryTabs";
import TabContent from "./CategoriesMob/TabContent";

import { customerActions, bottomNavbarActions } from "../../Redux/Actions";
import MobileTabPanel from "../FiltersPage/MobileResults.js/MobileTabPanel";
import { bottomNavItems } from "../../Utils/Constants";

import PageContainerMob from "../Common/PageContainerMob";

import {
  customLowerCase,
  customUpperCase,
} from "../../Utils/Helpers/CustomLetterConversion";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  width: "100%",
}));

const CloseAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  position: "absolute",
  right: theme.spacing(),
  cursor: "pointer",
  zIndex: open ? 1 : 9999,
}));

const HeaderPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 55,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(),
  borderBottom: "1px solid #B1AEAE",
  paddingBottom: theme.spacing(),
}));

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  // width: "100%",
  flex: 1,
  height: 26,
  "& .MuiInputBase-root": {
    height: 26,
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRadius: 4,
    height: 17,
    padding: "3.5px 8px",
    border: error ? "1px solid red" : "1px solid rgba(127, 91, 120, 0.5)",
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.darkGray.main,
    backgroundColor: "#fff",
    "&:not(:focus)": {
      cursor: "pointer",
    },
    "&:hover": {
      backgroundColor: "#fff",
      border: error ? "1px solid red" : "1px solid #6E3564",
      borderRadius: 4,
    },
    "&:focus, &:active": {
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: error
        ? `${alpha(red[500], 1)} 0 0 0 0.08rem`
        : `${alpha(theme.palette.primary.main, 1)} 0 0 0 0.08rem`,
      borderColor: "#fff",
    },
    "&::placeholder": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.darkGray.main,
      opacity: 1,
      paddingTop: "2px !important",
    },
  },
}));

const CategoriesMob = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  const categories = useSelector(({ filterValues }) => filterValues.categories);

  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [items, setItems] = useState([]);
  useEffect(() => {
    let active = true;
    if (active) {
      setMenu(bottomNavItems.CATEGORIES);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;
    if (active) {
      if (categories) {
        setItems(categories);
      } else return;
    }
    return () => {
      active = false;
    };
  }, [categories]);

  useEffect(() => {
    let customLower = customLowerCase(searchText);
    let customUpper = customUpperCase(searchText);

    let customInitialUpper =
      customUpperCase(searchText.charAt(0)) + searchText.slice(1);

    let customInitialLower =
      customLowerCase(searchText.charAt(0)) + searchText.slice(1);

    let initialUpper = customLower.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
      customUpperCase(match)
    );

    let initialUpperAll =
      customUpperCase(customLower.charAt(0)) + customLower.slice(1);

    let newItems = categories?.filter(
      (cat) =>
        cat.name.includes(searchText) ||
        cat.name.includes(customInitialLower) ||
        cat.name.includes(customInitialUpper) ||
        cat.name.includes(customLower) ||
        cat.name.includes(customUpper) ||
        cat.name.includes(initialUpperAll) ||
        cat.name.includes(initialUpper)
    );

    setItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearchText = (e) => setSearchText(e.target.value);

  const clearSearchText = () => setSearchText("");

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchText(e.target.value);
    }
  };

  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (id, name) => {
    let query = {
      ...filterQuery,
      categories: [{ id, name, filter: "Kategori" }],
      tabValue: 0,
    };
    updateFilters(query);
    history.push("/filters-page");
  };

  const onBackClick = () => history.goBack();

  const handleTabValue = (e, val) => setTabValue(val);

  return (
    <PageContainerMob>
      <Root>
        <HeaderPart>
          <ArrowBackIcon
            onClick={onBackClick}
            style={{ fill: "#434141", height: 24, width: 24, marginRight: 16 }}
          />
          <StyledTextField
            fullWidth
            value={searchText}
            onChange={handleSearchText}
            onKeyDown={keyPress}
            placeholder="Ara"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  {searchText?.length > 0 && (
                    <CloseAdornment
                      position="end"
                      onMouseDown={clearSearchText}>
                      <Fade in={searchText?.length > 0}>
                        <CloseIcon style={{ fill: "#8E8E8E" }} />
                      </Fade>
                    </CloseAdornment>
                  )}
                </>
              ),
            }}
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </HeaderPart>
        <CategoryTabs value={tabValue} handleChange={handleTabValue} />
        <MobileTabPanel value={tabValue} index={0}>
          <TabContent items={items} onClickHandler={goToFilters} />
        </MobileTabPanel>
        <MobileTabPanel value={tabValue} index={1}>
          <TabContent items={items} onClickHandler={goToFilters} />
        </MobileTabPanel>
        <MobileTabPanel value={tabValue} index={2}>
          <TabContent items={items} onClickHandler={goToFilters} />
        </MobileTabPanel>
        <MobileTabPanel value={tabValue} index={3}>
          <TabContent items={items} onClickHandler={goToFilters} />
        </MobileTabPanel>
      </Root>
    </PageContainerMob>
  );
};

export default CategoriesMob;
