import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import purpleStar from "../../../../Images/ProductPage/PurpleStar.svg";
import grayStar from "../../../../Images/ProductPage/GreyStar.svg";

const PREFIX = "RatingField";

const classes = {
  label: `${PREFIX}-label`,
  ratingContainer: `${PREFIX}-ratingContainer`,
};

const Root = styled("div")(({theme}) => ({
  [`& .${classes.label}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    paddingLeft: 9,
    marginBottom: 5.75,
    marginTop: 5,
    "&[is-mobile='true']": {
      fontSize: 12,
    },
  },

  [`& .${classes.ratingContainer}`]: {
    width: "100%",
    height: 105.65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5F5F5",
    borderRadius: 8,
    "&[is-mobile='true']": {
      backgroundColor: "#fff",
      height: 60,
    },
  },
}));

const RatingField = ({ rating, setRating, mobile }) => {
  return (
    <Root>
      <Typography
        className={classes.label}
        is-mobile={mobile ? "true" : "false"}>
        Puanla
      </Typography>
      <div
        className={classes.ratingContainer}
        is-mobile={mobile ? "true" : "false"}>
        <Rating
          name="purple-stars"
          precision={0.5}
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          icon={
            <img
              src={purpleStar}
              width="32px"
              height="30.68px"
              alt="Gray Star"
              style={{ marginRight: 8 }}
            />
          }
          emptyIcon={
            <img
              src={grayStar}
              width="32px"
              height="30.68px"
              alt="Purple Star"
              style={{ marginRight: 8 }}
            />
          }
        />
      </div>
    </Root>
  );
};

RatingField.propTypes = {
  rating: PropTypes.number,
  setRating: PropTypes.func,
  mobile: PropTypes.bool,
};

export default RatingField;
