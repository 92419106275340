import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { isMobileOnly } from "react-device-detect";

import AuthContainer from "./AuthContainer";
import Info from "./PasswordUpdated/Info";
import passwordIcon from "../../Images/Auth/password.svg";

import PasswordUpdatedMob from "./PasswordUpdatedMob";

const Root = styled("div")(() => ({
  width: 440,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledImg = styled("img")(() => ({
  marginTop: 71.5,
  marginBottom: 53.85,
}));

const PasswordUpdated = () => {
  let history = useHistory();

  const returnSignIn = () =>
    setTimeout(() => {
      history.push("/signin-email");
    }, 3000);

  returnSignIn();

  if (isMobileOnly) {
    return <PasswordUpdatedMob />;
  } else {
    return (
      <AuthContainer component="password-updated">
        <Root>
          <StyledImg
            src={passwordIcon}
            alt="passwordIcon"
            width="133.40px"
            height="102.28px"
          />
          <Info />
        </Root>
      </AuthContainer>
    );
  }
};

export default PasswordUpdated;
