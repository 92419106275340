import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const toggleFavorite = async (query, isAdmin) => {
  let url = isAdmin ? `${API_URL}/favorite/admin/toggle` : `${API_URL}/favorite/toggle`
  return await axios
    .post(url, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getFavoritesAdmin = async (query) => {
  return await axios
    .post(`${API_URL}/favorite/admin/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getFavorites = async (query) => {
  return await axios
    .post(`${API_URL}/favorite/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const favoriteService = {
  toggleFavorite,
  getFavoritesAdmin,
  getFavorites,
};
