import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import NoResultImage from "../../../Images/Filter/NoResultImage.svg";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  overflowX: "hidden"
}));

const StyledImg = styled("img")(() => ({
  width: 400,
  height: 200,
}));

const Text = styled(Typography)(({theme}) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
}));

const NoResult = () => {
  return (
    <Root>
      <StyledImg width="270px" src={NoResultImage} alt="" />
      <Text>Aradığınız ürün bulunamadı!</Text>
    </Root>
  );
};

export default NoResult;
