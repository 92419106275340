import React, { useState, useEffect } from 'react'
import { Transition } from '../../../../Utils/Tools'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

import MobileFilterRows from './MobileUserFilters/MobileFilterRows'
import Header from './MobileUserFilters/Header'
import FilterDialog from './MobileUserFilters/FilterDialog'

import {
  userStatus,
  rolesArr,
  generalConstants,
} from '../../../../Utils/Constants'

import { companyService } from '../../../../Services'

const ApplyButton = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'none',
  height: 40,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: '#fff',
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 8,
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
}))

const MobileUserFilters = ({ open, handleClose, setQuery, query }) => {
  const isAdmin = useSelector(({ customer }) => customer.isAdmin)

  const filterSections = [
    {
      sectionName: 'Durumu',
      sectionValue: 'status',
    },
    {
      sectionName: 'Yetki Grubu',
      sectionValue: 'roles',
    },
    {
      sectionName: 'Firma',
      sectionValue: 'company_id',
    },
  ]

  const [firms, setFirms] = useState([])
  const [openDialog, setOpenDialog] = useState({
    status: false,
    roles: false,
    company_id: false,
  })
  const [filters, setFilters] = useState({
      status: [],
      roles: [],
      company_id: [],
    })

  const [filterVals, setFilterVals] = useState({
    status: [],
    roles: [],
    company_id: [],
  })

  useEffect(() => {
    let active = true
    if (active) {
      setFilterVals({
        status: userStatus,
        roles: rolesArr,
        company_id: firms,
      })
    }
    return () => {
      active = false
    }
  }, [firms])

  useEffect(() => {
    let active = true
    if (active) {
      if (isAdmin) {
        const query_ = {
          pageNumber: 1,
          pageSize: 1000,
          sortField: 'id',
          sortOrder: 'asc',
          filter: {},
        }
        getCompanyList(query_)
      }
    }

    return () => {
      active = false
    }
  }, [isAdmin])

  const getCompanyList = async (query) => {
    const response = await companyService.getCompanies(query)
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ['name'].every((k) => t[k] === v[k])) === i
      )
      let finalData = notDuplicatedData.filter((item) => item.name !== 'None')
      setFirms(finalData)
    } else {
      setFirms([{ name: 'Sonuç bulunamadı', id: 0.5 }])
    }
  }

  const openRelatedDialog = (prop) => (e) => {
    setOpenDialog({ ...openDialog, [prop]: true })
  }

  const closeRelatedDialog = (prop) =>
    setOpenDialog({ ...openDialog, [prop]: false })

  const onDelete = () => {
    clearFilters()
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        ...query.filter,
        status: [],
        roles: [],
        company_id: [],
      },
    })
    handleClose()
  }

  const clearFilters = () => {
    setFilters({
      status: [],
      roles: [],
      company_id: [],
    })
  }

  const handleSubmit = () => {
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        ...query.filters,
        ...filters,
      },
    })
    handleClose()
  }

  const finalSections = isAdmin
    ? filterSections
    : filterSections.filter((f) => f.sectionName !== 'Firma')
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: 'hidden' }}>
      <Header
        handleClose={handleClose}
        headerText='Filtre'
        onDelete={onDelete}
      />
      {finalSections.map((item, index) => (
        <MobileFilterRows
          key={index}
          name={item.sectionName}
          items={filters[item.sectionValue]}
          value={item.sectionValue}
          openDialog={openRelatedDialog}
        />
      ))}
      {filterSections.map(
        (item, index) =>
          openDialog[item.sectionValue] && (
            <FilterDialog
              key={index}
              open={openDialog[item.sectionValue]}
              handleClose={closeRelatedDialog}
              items={filterVals[item.sectionValue]}
              value={item.sectionValue}
              filter={item.sectionName}
              filters={filters}
              setFilters={setFilters}
            />
          )
      )}
      <div style={{ flex: 1 }}></div>
      <ButtonContainer>
        <ApplyButton onClick={handleSubmit} disableRipple>
          Uygula
        </ApplyButton>
      </ButtonContainer>
    </Dialog>
  )
}

MobileUserFilters.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  query: PropTypes.object,
  setQuery: PropTypes.func,
}

export default MobileUserFilters
