import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PREFIX = "RatingSubmitButton";

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.buttonContainer}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },

  [`& .${classes.button}`]: {
    width: 161,
    height: 26.78,
    textTransform: "capitalize",
    backgroundColor: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: 400,
    color: "#FFFFFF",
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const RatingSubmitButton = ({ handleSubmit }) => {
  return (
    <Root className={classes.buttonContainer}>
      <Button
        variant="contained"
        disableRipple
        className={classes.button}
        onClick={handleSubmit}>
        Ekle
      </Button>
    </Root>
  );
};

RatingSubmitButton.propTypes = {
  handleSubmit: PropTypes.func,
};

export default RatingSubmitButton;
