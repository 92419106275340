import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import { favoriteActions } from "../../../../Redux/Actions";

import Loading from "../../../../Utils/Loading";

const PREFIX = "IconButtons";

const classes = {
  root: `${PREFIX}-root`,
  items: `${PREFIX}-items`,
  text: `${PREFIX}-text`,
  iconButton: `${PREFIX}-iconButton`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    marginTop: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 25,
    width: "87.5%",
    userSelect: "none",
  },

  [`& .${classes.items}`]: {
    width: "fit-content",
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },

  [`& .${classes.text}`]: {
    fontSize: 10,
    fontWeight: 700,
    color: theme.palette.darkGray.main,
  },

  [`& .${classes.iconButton}`]: {
    width: 35,
    height: 35,
  },
}));

const FavoriteUnChecked = styled(FavoriteBorderRoundedIcon)(() => ({
  width: 30,
  height: 30,
  fill: "#000",
}));

const FavoriteChecked = styled(FavoriteRoundedIcon)(({ theme }) => ({
  width: 30,
  height: 30,
  fill: theme.palette.primary.main,
}));

const IconButtons = ({ product }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      if (product) {
        setData(product);
      } else return;
    }
    return () => {
      active = false;
    };
  }, [product]);

  const { favorites, loading } = useSelector(({ favorites }) => favorites);
  const {isAdmin, selectedUser} = useSelector(({ customer }) => customer);

  const toggleFavorite = (query, bool, data) =>
    dispatch(favoriteActions.toggleFavorite(query, bool, data));

  let isIncluded = favorites?.some((item) => item.id === data?.id);

  const handleFavorite = () => {
    if (isAdmin) return;
    let query = {
      product_id: data?.id,
      user_id: selectedUser?.id
    };
    toggleFavorite(query, isAdmin, data);
  };

  return (
    <Root className={classes.root}>
      <Loading open={loading} />
      <div className={classes.items}>
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={handleFavorite}>
          {isIncluded ? <FavoriteChecked /> : <FavoriteUnChecked />}
        </IconButton>
        <div className={classes.text}>Beğen</div>
      </div>
      <div className={classes.items}>
        <IconButton className={classes.iconButton} size="large">
         <BookmarkBorderIcon fontSize="large" style={{fill: '#000000', fontWeight: 200}} />
        </IconButton>
        <div className={classes.text}>Listeme Ekle</div>
      </div>
    </Root>
  );
};

IconButtons.propTypes = {
  product: PropTypes.object,
};

export default IconButtons;
