import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";

import Header from "./SearchHistory/Header";
import History from "./SearchHistory/History";
import Bottom from "./SearchHistory/Bottom";
import Results from "./SearchHistory/Results";

const StyledPopper = styled(Popper)(() => ({
  // WebkitTransition: "none",
  // transition: "none",
  // borderRadius: 0,
  // boxShadow: "none",
  // borderBottomLeftRadius: 4,
  // borderBottomRightRadius: 4,
  zIndex: 9999,
}));

const SearchHistory = ({
  open,
  anchorEl,
  handleClose,
  data,
  handleHistoryClear,
  setKeyword,
  keyword,
  results,
  handleResultClick,
  disabled,
}) => {
  return (
    <StyledPopper
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      TransitionProps={{ timeout: 0 }}
      placement="bottom">
      <Box
        sx={{
          width: 440,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          border: "2px solid #6E3564",
          p: 1,
          zIndex: 9999,
          marginTop: "-3px",
          marginLeft: "-48px",
        }}>
        {keyword?.length < 3 && <Bottom />}
        {keyword?.length < 3 && (
          <Header handleHistoryClear={handleHistoryClear} disabled={disabled} />
        )}
        {keyword.length < 3 ? (
          <History data={data} setKeyword={setKeyword} handleClose={handleClose}/>
        ) : (
          <Results data={results} handleResultClick={handleResultClick} />
        )}
      </Box>
    </StyledPopper>
  );
};

SearchHistory.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  data: PropTypes.array,
  handleHistoryClear: PropTypes.func,
  setKeyword: PropTypes.func,
  keyword: PropTypes.string,
  results: PropTypes.array,
  handleResultClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SearchHistory;
