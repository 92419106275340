import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import { productService, filterService } from "../../../Services";
import { customerActions, productActions } from "../../../Redux/Actions";
import { generalConstants } from "../../../Utils/Constants";

import NewSection from "../../Common/Sections/NewSection";
// import WebBrands from "./WebBrands";
import ImagesRow from "./ImagesRow";
import Categories from "./Categories";
import NewSectionSkeleton from "../../Common/Sections/NewSectionSkeleton";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(2),
}));

const NewPageSections = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const setNewArrivalsData = (query) =>
    dispatch(productActions.setNewArrivalsData(query));

  const customer = useSelector(({ customer }) => customer);

  const [newArrivals, setNewArrivals] = useState(null);
  const [lastVisiteds, setLastVisiteds] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      let newArrivalsQuery = {
        pageNumber: 1,
        pageSize: 5,
        sortField: "id",
        sortOrder: "desc",
        filter: {
          tag: "yeni",
          on_sale: 1,
        },
      };
      let categoriesQuery = {
        pageNumber: 1,
        pageSize: 6,
        sortField: "id",
        sortOrder: "desc",
        filter: { status: 1 },
      };
      if (customer?.selectedUser) {
        let isAdmin = customer?.isAdmin;
        let userId = +customer?.selectedUser?.id;
        getLastVisiteds(isAdmin, userId);
        if (isAdmin) {
          setLoading(true);
          if (customer.currentCompany) {
            let query_ = {
              ...newArrivalsQuery,
              filter: {
                ...newArrivalsQuery.filter,
                company_id: customer.currentCompany.id,
              },
            };
            getNewArrivals(query_);
          } else return;
        } else {
          getNewArrivals(newArrivalsQuery);
        }
      }
      getCategories(categoriesQuery);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const getNewArrivals = async (query) => {
    await productService
      .productSkuList(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let data_ = res.data.product_skus;
          setNewArrivals(data_);
          setNewArrivalsData(data_);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getLastVisiteds = async (isAdmin, userId) => {
    await productService
      .getLastVisits(isAdmin, userId)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let response = res.data.product_skus;
          let data = response
            ? response?.slice(Math.max(response.length - 5, 0))
            : [];
          //array.slice(0, n);
          setLastVisiteds(data);
        } else {
          console.log("else", res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCategories = async (query) => {
    await filterService
      .categorySubList(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setCategories(res.data.sub_categories);
        } else {
          console.log("else", res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (value) => {
    let query = { ...filterQuery, tabValue: value };
    updateFilters(query);
    history.push("/filters-page");
  };

  return (
    <Root>
      {loading ? (
        <NewSectionSkeleton header="Yeni Gelenler" />
      ) : (
        <NewSection
          products={newArrivals}
          header="Yeni Gelenler"
          handleClick={goToFilters}
          value={4}
        />
      )}
      {/* <WebBrands /> */}
      <ImagesRow />
      <Categories categories={categories} handleClick={goToFilters} value={0} />
      <NewSection products={lastVisiteds} header="Son Gezilenler" noButton />
    </Root>
  );
};

export default NewPageSections;
