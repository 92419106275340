import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import PageContainer from "../Common/PageContainer";
import TabButtons from "./Main/TabButtons";
import Users from "./Main/Users";
import Roles from "./Main/Roles";

import { rolesService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";
import { convertRoles } from "../../Utils/Helpers";

import AddRole from "./Main/Dialogs/AddRole";

const PREFIX = "UsersAndRoles";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    minHeight: "calc(100vh - 350px)",
    marginBottom: 30,
  },
}));

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: theme.spacing(10),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textTransform: "none",
  fontSize: 20,
}));

const UsersAndRoles = () => {
  const roleRef = useRef();
  const history = useHistory();

  const authInfo = useSelector(({ auth }) => auth.customerInfo);
  const customer = useSelector(({ customer }) => customer);

  const [tabIndex, setIndex] = useState(1);
  const [roles, setRoles] = useState([{ name: "Sonuç bulunamadı", id: 1 }]);
  const [addUser, setAddUser] = useState(false);
  const [addUserCA, setAddUserCA] = useState(false);
  const [addRole, setAddRole] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      getRoles();
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoles = () => {
    rolesService
      .getRoles()
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let data = res.data.roles;
          if (data.length > 0) {
            if (authInfo?.role_names[0] === "companyadmin") {
              let newData = data.filter(
                (item) => item.id === 3 || item.id === 4
              );
              let rolesArr = convertRoles(newData);
              setRoles(rolesArr);
            } else {
              let rolesArr = convertRoles(data);
              setRoles(rolesArr);
            }
          } else {
            setRoles([{ name: "Sonuç bulunamadı", id: 1 }]);
          }
        } else {
          setRoles([{ name: "Sonuç bulunamadı", id: 1 }]);
        }
      })
      .catch((err) => {
        setRoles([{ name: "Sonuç bulunamadı", id: 1 }]);
      });
  };

  const openAddUser = () => {
    if (customer?.isAdmin) {
      setAddUser(true);
    } else {
      setAddUserCA(true);
    }
  };

  const closeAddUser = () => {
    if (customer?.isAdmin) {
      setAddUser(false);
    } else {
      setAddUserCA(false);
    }
  };

  const openAddRole = () => setAddRole(true);
  const closeAddRole = () => setAddRole(false);

  const goToHome = () => history.push("/home");

  const renderUsers = (
    <Users
      roles={roles}
      addUser={addUser}
      addUserCA={addUserCA}
      closeAddUser={closeAddUser}
    />
  );

  const renderRoles = useMemo(() => {
    return <Roles roleRef={roleRef} />;
  }, [roleRef]);

  if (authInfo?.role_names[0] === "companyuser") {
    return (
      <PageContainer className={classes.root}>
        <Root className={classes.root}>
          <StyledDiv>
            <Typography variant="h2">Yetkisiz Kullanıcı</Typography>
            <StyledButton onClick={goToHome}>Ana Sayfaya Dön</StyledButton>
          </StyledDiv>
        </Root>
      </PageContainer>
    );
  }

  return (
    <PageContainer className={classes.root}>
      <Root className={classes.root}>
        <TabButtons
          index={tabIndex}
          setIndex={setIndex}
          openAddUser={openAddUser}
          openAddRole={openAddRole}
          roles={roles}
        />
        {tabIndex === 1 ? renderUsers : renderRoles}
      </Root>

      <AddRole open={addRole} onClose={closeAddRole} />
    </PageContainer>
  );
};

export default UsersAndRoles;
