import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import MailGray from "../../Images/Home/MailGray.svg";
import User from "../../Images/Home/User.svg";
import BlueTick from "../../Images/Home/BlueTick.svg";
import UserDarkGray from "../../Images/Home/UserDarkGray.svg";
// import TrendingUp from "../../Images/Home/TrendingUp.svg";

import { getFirstLetters } from "../../Utils/Helpers";

// import Boxes from "./Boxes";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 1135,
  height: 108,
  marginBottom: theme.spacing(),
  display: "flex",
  alignItems: "center",
}));

const Letters = styled("div")(({ theme }) => ({
  width: 88,
  height: 88,
  borderRadius: 88 / 2,
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: theme.spacing(),
}));

const LetterText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "30px",
  color: "#FFFFFF",
}));

const UserContainer = styled("div")(({ theme }) => ({
  width: 530,
  height: 88,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
}));

const UserInfoContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "userInfo",
})(({ userInfo, theme }) => ({
  width: "100%",
  height: userInfo ? 24 : 30,
  display: "flex",
  alignItems: "center",
  marginBottom: !userInfo && theme.spacing(),
  marginRight: theme.spacing(2),
}));

const UserHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "userName",
})(({ userName, theme }) => ({
  fontSize: 24,
  fontWeight: userName ? 700 : 300,
  lineHeight: "30px",
  color: "#091164",
  marginRight: theme.spacing(),
}));

const UserInfoText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#B1AEAE",
  marginRight: theme.spacing(2),
}));

const StyledImage = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(),
}));

const IconButtonContainer = styled("div")(({ theme }) => ({
  width: 40,
  height: "100%",
  marginLeft: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: 35,
  height: 35,
}));

const UserBox = () => {
  const history = useHistory();

  const userInfo = useSelector(({ auth }) => auth.customerInfo);
  const [letters, setLetters] = useState(null);

  useEffect(() => {
    if (userInfo) {
      setLetters(getFirstLetters(userInfo?.first_name, userInfo?.last_name));
    }
  }, [userInfo]);

  const goToProfile = () => history.push("./dealer-profile");

  return (
    <Root>
      <Letters>
        <LetterText>{letters && letters}</LetterText>
      </Letters>
      <UserContainer>
        <UserInfoContainer>
          <UserHeader>Hoşgeldiniz,</UserHeader>
          <UserHeader userName>{userInfo?.full_name}</UserHeader>
          <img src={BlueTick} width="29" height="17" alt="" />
        </UserInfoContainer>
        <UserInfoContainer userInfo>
          <StyledImage src={User} width="20" height="20" alt="" />
          <UserInfoText>{userInfo?.role_names[0]}</UserInfoText>
          <StyledImage src={MailGray} width="20" height="20" alt="" />
          <UserInfoText>{userInfo?.email}</UserInfoText>
        </UserInfoContainer>
      </UserContainer>
      <div style={{ flex: 1 }} />
      {/* <Boxes /> */}
      <IconButtonContainer>
        <StyledIconButton onClick={goToProfile}>
          <img src={UserDarkGray} alt="" width="30" height="30" />
        </StyledIconButton>
        {/* <StyledIconButton>
          <img src={TrendingUp} alt="" width="30" height="30" />
        </StyledIconButton> */}
      </IconButtonContainer>
    </Root>
  );
};

export default UserBox;
