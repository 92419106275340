import React from "react";
import PropTypes from "prop-types";

import AuthContainer from "../../AuthPages/AuthContainer";
import { SubmitButton } from "../../AuthPages/AuthCommons/Tools";

import ServerError from "../../../Images/ErrorBoundary/ServerError.svg";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const WebBoundary = ({ goToHome }) => {
  return (
    <AuthContainer component="error-boundary">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={ServerError} alt="" />
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 19,
            lineHeight: "28px",
            mt: (theme) => theme.spacing(2),
          }}>
          Sayfa yüklenmesi sırasında bir sorun oluştu,
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 19,
            lineHeight: "28px",
            mb: (theme) => theme.spacing(4),
          }}>
          sayfayı yenileyebilir ya da tekrar giriş yapabilirsiniz.
        </Typography>
        <SubmitButton onClick={goToHome}>Giriş Sayfasına Gön</SubmitButton>
      </Box>
    </AuthContainer>
  );
};

WebBoundary.propTypes = {
  goToHome: PropTypes.func,
};

export default WebBoundary;
