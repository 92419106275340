const convertRoles = (roles) => {
  let arr = [];
  for (let item of roles) {
    let obj = {};
    obj.name = item.name;
    obj.id = item.id;
    arr.push(obj);
  }
  return arr;
};

export { convertRoles };
