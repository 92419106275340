import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 90,
  backgroundColor: "#ECF0F4",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 6,
  padding: theme.spacing(),
  paddingRight: theme.spacing(2),
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  fontWeight: 400,
}));

const CategoryItem = ({ item, onClickHandler }) => {
  const handleClick = () => {
    const { id, name } = item;
    onClickHandler(id, name);
  };
  return (
    <Root onClick={handleClick}>
      <Text>{item?.name}</Text>
      <img
        src={item?.image}
        alt=""
        width="47"
        height="70"
        style={{ transform: "rotate(25deg)" }}
      />
    </Root>
  );
};

CategoryItem.propTypes = {
  item: PropTypes.object,
  onClickHandler: PropTypes.func,
};

export default CategoryItem;
