import React from "react";
import PropTypes from "prop-types";

import AuthContainer from "../../AuthPages/AuthContainer";
import { SubmitButton } from "../../AuthPages/AuthCommons/Tools";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NotFoundText = styled(Typography)(({ theme }) => ({
  fontSize: 100,
  fontWeight: 500,
  color: theme.palette.darkGray.main,
}));

const WebNotFound = ({ goToHome }) => {
  return (
    <AuthContainer component="error-boundary">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <NotFoundText>404</NotFoundText>
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 20,
            lineHeight: "28px",
            mt: (theme) => theme.spacing(2),
            mb: (theme) => theme.spacing(4),
          }}>
          Aradığınız sayfa bulunamadı
        </Typography>
        <SubmitButton onClick={goToHome}>Ana Sayfaya Dön</SubmitButton>
      </Box>
    </AuthContainer>
  );
};

WebNotFound.propTypes = {
  goToHome: PropTypes.func,
};

export default WebNotFound;
