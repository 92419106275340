import {
  authConstants,
  generalConstants,
  customerConstants,
} from "../../Utils/Constants";
import { authService } from "../../Services";
import { toast } from "react-toastify";
import { history } from "../../Utils/Helpers/History";
import { customerActions } from "./CustomerActions";
import { addressActions } from "./AddressActions";
import { orderActions } from "./OrderActions";
import { productActions } from "./ProductActions";
import { bottomNavbarActions } from "./BottomNavbarActions";
import { filterActions } from "./FilterActions";
import { favoriteActions } from "./FavoriteActions";
import { notificationActions } from "./NotificationActions";
import { campaignActions } from "./CampaignActions";

const signin = (query) => {
  return (dispatch) => {
    dispatch(request());
    authService.signin(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          localStorage.setItem(
            customerConstants.CUSTOMER,
            JSON.stringify(res?.data)
          );
          dispatch(success(res?.data?.user));
          history.push("/home");
        } else {
          toast.error(res?.data?.message);
          dispatch(failure());
        }
      },
      (error) => {
        toast.error(error?.response?.data?.message);
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: authConstants.SIGN_IN_REQUEST };
  }
  function success(data) {
    return { type: authConstants.SIGN_IN_SUCCESS, payload: data };
  }
  function failure() {
    return { type: authConstants.SIGN_IN_FAILURE };
  }
};

const setSigninInfo = (data) => {
  localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(data));
  return (dispatch) => {
    dispatch({ type: authConstants.SIGN_IN_SUCCESS, payload: data.user });
    history.push("/home");
  };
};

const setSignin = (data) => {
  return (dispatch) => {
    dispatch({ type: authConstants.SIGN_IN_SUCCESS, payload: data });
    // history.push("/home");
  };
};

const signout = (errorBoundary) => {
  return (dispatch) => {
    authService.signout();
    dispatch(customerActions.clearCustomerReducer());
    dispatch({ type: authConstants.SIGN_OUT });
    dispatch(addressActions.clearAddresses());
    dispatch(orderActions.clearOrderReducer());
    dispatch(productActions.clearProductReducer());
    dispatch(bottomNavbarActions.clearbottomNavbarReducer());
    dispatch(filterActions.clearFilterReducer());
    dispatch(favoriteActions.clearFavorites());
    dispatch(notificationActions.clearNotificationReducer());
    dispatch(campaignActions.clearCampaignReducer());
    localStorage.removeItem(customerConstants.CUSTOMER);
    localStorage.removeItem("B2B");
    errorBoundary && history.push("/signin-email");
  };
};

const updateUserName = (query) => {
  return {
    type: authConstants.UPDATE_USER_NAME,
    query,
  };
};

const updateUser = (query) => {
  return (dispatch) => {
    dispatch(request());
    authService.userUpdate(query).then(
      (res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          dispatch(success(query));
          dispatch(updateUserName(query));
        } else {
          toast.error(res.data.message);
          dispatch(failure());
        }
      },
      (error) => {
        toast.error(error?.response?.data?.message);
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: authConstants.USER_UPDATE_REQUEST };
  }
  function success() {
    return { type: authConstants.USER_UPDATE_SUCCESS };
  }
  function failure() {
    return { type: authConstants.USER_UPDATE_FAILURE };
  }
};

const updateAddressByToggle = (address) => {
  return { type: authConstants.UPDATE_ADDRESS_BY_TOGGLE, address };
};

export const authActions = {
  signin,
  signout,
  setSigninInfo,
  updateUserName,
  updateUser,
  updateAddressByToggle,
  setSignin,
};

// const resetPassword = email => {
//   return (dispatch) => {
//     customerService.resetPassword(email).then(
//       (res) => {
//         if (res.status === generalConstants.STATUS_OK)
//           dispatch(alertActions.success(res.data));
//       },
//       (error) => {
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };
// }

// const updatePassword = (currentPass, newPass) => {
//   return (dispatch) => {
//     customerService.updatePassword(currentPass, newPass).then(
//       (res) => {
//         if (res.status === generalConstants.STATUS_OK)
//           dispatch(alertActions.success(res.data));
//       },
//       (error) => {
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };
// }
