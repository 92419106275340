import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";

import ProductCard from "./ProductCard";
import PageContainer from "../Common/PageContainer";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { productService } from "../../Services";
import Loading from "../../Utils/Loading";
import {
  customLowerCase,
  customUpperCase,
} from "../../Utils/Helpers/CustomLetterConversion";
import { generalConstants } from "../../Utils/Constants";
import { customerActions } from "../../Redux/Actions";

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
  marginTop: 6,
  marginBottom: 22,
  paddingLeft: 5,
}));

const WarnContainer = styled("div")(() => ({
  width: "100%",
  height: 700,
  position: "relative",
}));

const WarnSubContainer = styled("div")(() => ({
  width: 400,
  height: 200,
  position: "absolute",
  transform: "translateX(-50%) translateY(-50%)",
  top: "30%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const WarnText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 500,
  color: theme.palette.darkGray.main,
  marginBottom: 15,
  "&[type-button='true']": {
    cursor: "pointer",
  },
}));

const WarnBackButton = styled(Button)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  padding: "10px 20px",
  maxHeight: 45,
  color: theme.palette.primary.main,
  textTransform: "none",
  backgroundColor: "transparent",
  border: "1px solid #6E3564",
  borderRadius: 100,
  "&:hover, &:active": {
    backgroundColor: "transparent",
  },
}));

const FastOrder = () => {
  const dispatch = useDispatch();
  const setText = (text) => dispatch(customerActions.setSearchText(text));
  const searchKeyword = useSelector(
    ({ customer }) => customer.filters.searchText
  );

  const companyId = useSelector(({ customer }) => customer?.currentCompany?.id);

  const [products, setProducts] = useState([]);
  const [initialProducts, setInitialProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: {
      company_id: companyId,
    },
    keyword: searchKeyword,
  });

  // useEffect(() => {
  //   let active = true;
  //   if (active) {
  //     getProducts(query);
  //     searchText !== "" && setSearchText("");
  //   }
  //   return () => {
  //     active = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    let active = true;
    if (active) {
      if (companyId) {
        let newQuery = {
          pageNumber: 1,
          pageSize: 10,
          sortField: "id",
          sortOrder: "desc",
          filter: {
            company_id: companyId,
            on_sale: 1,
          },
          keyword: searchKeyword,
        };
        getProducts(newQuery);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, companyId]);

  const getProducts = (query) => {
    if (hasMore) {
      setOpen(true);
      productService
        .productSkuListAll(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            let data =
              query.pageNumber === 1
                ? res.data.product_skus
                : [...products, ...res.data.product_skus];
            // : products.concat(res.data.product_skus);
            setProducts(data);
            setInitialProducts(data);
            setOpen(false);
            if (res.data.product_skus.length < 10) {
              setHasMore(false);
            } else {
              setQuery({
                ...query,
                pageNumber: query.pageNumber + 1,
              });
            }
          } else {
            console.log(res);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    let customLower = customLowerCase(searchKeyword);
    let customUpper = customUpperCase(searchKeyword);

    let customInitialUpper =
      customUpperCase(searchKeyword.charAt(0)) + searchKeyword.slice(1);

    let customInitialLower =
      customLowerCase(searchKeyword.charAt(0)) + searchKeyword.slice(1);

    let initialUpper = customLower.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
      customUpperCase(match)
    );

    let initialUpperAll =
      customUpperCase(customLower.charAt(0)) + customLower.slice(1);

    let newProductsList = initialProducts.filter(
      (product) =>
        product.name.includes(searchKeyword) ||
        product.name.includes(customInitialLower) ||
        product.name.includes(customInitialUpper) ||
        product.name.includes(customLower) ||
        product.name.includes(customUpper) ||
        product.name.includes(initialUpperAll) ||
        product.name.includes(initialUpper)
    );

    setProducts(newProductsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const handleSearchText = (text) => setText(text);

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchText(e.target.value);
    }
  };

  const goBack = () => {
    setHasMore(true);
    setQuery({ ...query, pageSize: 1, keyword: "" });
    setText("");
  };

  return (
    <PageContainer>
      <Loading open={open} />
      <Header>Hızlı Sipariş</Header>
      {open && <div style={{ width: "100%", height: 1000 }}></div>}
      {products.length > 0 ? (
        <InfiniteScroll
          style={{
            padding: "0px 5px 10px",
            overflowX: "hidden",
          }}
          dataLength={products.length}
          next={() => getProducts(query)}
          hasMore={hasMore}
          loader={<></>}>
          {products.map((product, index) => (
            <ProductCard
              key={index}
              product={product}
              order={index}
              handleSearchText={handleSearchText}
              keyPress={keyPress}
            />
          ))}
        </InfiniteScroll>
      ) : searchKeyword !== "" ? (
        <WarnContainer>
          <WarnSubContainer>
            <WarnText>Aradığınız Ürün Bulunamadı!</WarnText>
            <WarnBackButton disableRipple onClick={goBack}>
              Geri Dön
            </WarnBackButton>
          </WarnSubContainer>
        </WarnContainer>
      ) : (
        <div style={{ width: "100%", height: 1000 }}></div>
      )}
    </PageContainer>
  );
};

export default FastOrder;
