import { campaignConstants, generalConstants } from "../../Utils/Constants";
import { campaignService } from "../../Services";

const getCampaigns = (query) => {
  return (dispatch) => {
    campaignService.getCampaigns(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: campaignConstants.GET_CAMPAIGNS,
            payload: res?.data?.campaigns,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const clearCampaignReducer = () => {
  return {
    type: campaignConstants.CLEAR_CAMPAIGN_REDUCER,
  };
};

export const campaignActions = {
  getCampaigns,
  clearCampaignReducer,
};
