import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

import Button from "@mui/material/Button";

import CustomTF from "../../../Ui/Verification/CustomTF";
import Timer from "../../../Ui/Verification/Timer";
import ResendNotice from "../../../Ui/Verification/ResendNotice";
import { authService, userService } from "../../../Services";
import { authActions } from "../../../Redux/Actions";
import { generalConstants } from "../../../Utils/Constants";

const Root = styled("div")(() => ({
  width: "100%",
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const InputContainer = styled("div")(() => ({
  marginTop: 27.08,
  width: 300.03,
  height: 42,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Resend = styled(Button)(({ theme }) => ({
  marginTop: 36.79,
  textTransform: "capitalize",
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.primary.main,
}));

const WrongCode = styled("div")(({ theme }) => ({
  marginTop: 30,
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

const VerificationStep = ({ value, prop, onClose }, ref) => {
  const dispatch = useDispatch();
  const regexp = /^[0-9\b]+$/;

  const authInfo = useSelector(({ auth }) => auth.customerInfo);
  const signOut = () => dispatch(authActions.signout());

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();
  const fifthInput = useRef();
  const sixthInput = useRef();

  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [sixthDigit, setSixthDigit] = useState("");

  const [secondsRemaining, setSecondsRemaining] = useState(120);
  const [wrongCode, setWrongCode] = useState(false);

  let tokenStr = firstDigit.concat(
    secondDigit,
    thirdDigit,
    fourthDigit,
    fifthDigit,
    sixthDigit
  );

  useEffect(() => {
    firstInput?.current?.focus();
  }, []);

  const handleFirstDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFirstDigit(d);
    } else if (regexp.test(d)) {
      setFirstDigit(d);
      secondInput.current.focus();
    }
  };

  const handleSecondDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSecondDigit(d);
      firstInput.current.focus();
    } else if (regexp.test(d)) {
      setSecondDigit(d);
      thirdInput.current.focus();
    }
  };

  const handleThirdDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setThirdDigit(d);
      secondInput.current.focus();
    } else if (regexp.test(d)) {
      setThirdDigit(d);
      fourthInput.current.focus();
    }
  };

  const handleFourthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFourthDigit(d);
      thirdInput.current.focus();
    } else if (regexp.test(d)) {
      setFourthDigit(d);
      fifthInput.current.focus();
    }
  };

  const handleFifthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setFifthDigit(d);
      fourthInput.current.focus();
    } else if (regexp.test(d)) {
      setFifthDigit(d);
      sixthInput.current.focus();
    }
  };

  const handleSixthDigitChange = (event) => {
    let d = event.target.value;
    if (d === "") {
      setSixthDigit(d);
      fifthInput.current.focus();
    } else if (regexp.test(d)) {
      setSixthDigit(d);
    }
  };

  const resetFields = () => {
    setFirstDigit("");
    setSecondDigit("");
    setThirdDigit("");
    setFourthDigit("");
    setFifthDigit("");
    setSixthDigit("");
  };

  const handleVerificationCode = () => {
    sendVerificationCode(handleZeroPad(tokenStr));
  };

  const handleZeroPad = (num) => {
    const zeroPad = String(num).padStart(4, "0");
    return zeroPad;
  };

  const sendVerificationCode = async (token) => {
    setWrongCode(false);
    resetFields();
    let phoneCode = "+905";
    let gsm = phoneCode.concat(prop).replace(/\s/g, "");
    let query =
      value === "phone"
        ? { type: 0, phone: gsm, code: token }
        : { type: 1, email: prop, code: token };
    const response = await authService.validateConfirmCode(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      let userQuery = {
        first_name: authInfo.first_name,
        last_name: authInfo.last_name,
      };
      let phoneCode = "+905";
      let gsm = phoneCode.concat(prop).replace(/\s/g, "");
      let updateQuery =
        value === "phone"
          ? { ...userQuery, email: authInfo.email, gsm }
          : { ...userQuery, email: prop, gsm: authInfo.gsm };

      const res = await userService.updateUser(updateQuery);
      if (res.data.status === generalConstants.STATUS_TRUE) {
        let text =
          value === "phone"
            ? "Telefon numaranız başarılı bir şekilde güncellendi"
            : "Email adresiniz başarılı bir şekilde güncellendi";

        toast.success({ text });
        setTimeout(() => {
          onClose();
          signOut();
        }, 1000);
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  const handleResend = async () => {
    setWrongCode(false);
    resetFields();
    let query =
      value === "phone" ? { type: 0, phone: prop } : { type: 1, email: prop };
    const response = await authService.createConfirmCode(query);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setSecondsRemaining(120);
    } else {
      console.log(response);
    }
  };

  useImperativeHandle(ref, () => ({
    handleVerificationCode: () => {
      handleVerificationCode();
    },
  }));

  return (
    <Root>
      <InputContainer>
        <CustomTF
          value={firstDigit}
          inputRef={firstInput}
          onChange={handleFirstDigitChange}
        />
        <CustomTF
          value={secondDigit}
          inputRef={secondInput}
          onChange={handleSecondDigitChange}
        />
        <CustomTF
          value={thirdDigit}
          inputRef={thirdInput}
          onChange={handleThirdDigitChange}
        />
        <CustomTF
          value={fourthDigit}
          inputRef={fourthInput}
          onChange={handleFourthDigitChange}
        />
        <CustomTF
          value={fifthDigit}
          inputRef={fifthInput}
          onChange={handleFifthDigitChange}
        />
        <CustomTF
          value={sixthDigit}
          inputRef={sixthInput}
          onChange={handleSixthDigitChange}
        />
      </InputContainer>
      {wrongCode && <WrongCode>Girdiğiniz kod hatalıdır!</WrongCode>}
      {secondsRemaining === 0 ? (
        <Resend onClick={handleResend}>Kod Gönder</Resend>
      ) : (
        <Timer
          secondsRemaining={secondsRemaining}
          setSecondsRemaining={setSecondsRemaining}
        />
      )}
      <ResendNotice />
    </Root>
  );
};

export default React.forwardRef(VerificationStep);
