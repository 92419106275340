import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AddressAndNote from "./AddressAndNote";

import { orderActions } from "../../../Redux/Actions";

import Loading from "../../../Utils/Loading";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const InputContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
  position: "relative",
}));

const BottomPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 50,
  position: "absolute",
  bottom: 94.5,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: "white",
  zIndex: 2,
}));

const CostContainer = styled("div")(() => ({
  display: "flex",
}));

const TotalCost = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 500,
  color: theme.palette.darkGray.main,
  marginRight: text && theme.spacing(),
}));

const SubmitButton = styled(Button)(({theme}) => ({
  textTransform: "none",
  height: 40,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#fff",
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const OpenAccountMob = ({ text, toggleAddressDialog, handleNote }) => {
  const dispatch = useDispatch();

  const cart = useSelector(({ product }) => product.cart);
  const customer = useSelector(({ customer }) => customer);
  const orderLoading = useSelector(({ orders }) => orders.loading);

  const initializeOrder = (query) =>
    dispatch(orderActions.initializeOrderOpenAccount(query));

  const initializeAdminOrder = (query, id) =>
    dispatch(orderActions.initializeOrderOpenAccountAdmin(query, id));

  const placeOrder = () => {
    let query = {
      method_id: 4,
      address_id: customer?.currentAddress?.id,
      installment: 1,
      note: text,
      extra_data: {},
      source: 1,
    };

    if (customer?.isAdmin) {
      let adminQuery = {
        ...query,
        user_id: customer?.selectedUser?.id,
        company_id: customer?.currentCompany?.id,
      };
      initializeAdminOrder(adminQuery, customer?.selectedUser?.id);
    } else {
      initializeOrder(query);
    }
  };

  return (
    <Root>
      <AddressAndNote
        text={text}
        toggleAddressDialog={toggleAddressDialog}
        handleChange={handleNote}
      />
      <Loading open={orderLoading} />
      <InputContainer>
        <BottomPart>
          <CostContainer>
            <TotalCost text>Tutar: </TotalCost>
            <TotalCost>{cart?.order.total_cost || 0}</TotalCost>
          </CostContainer>
          <SubmitButton onClick={placeOrder} variant="contained">
            Siparişi Onayla
          </SubmitButton>
        </BottomPart>
      </InputContainer>
    </Root>
  );
};

OpenAccountMob.propTypes = {
  text: PropTypes.string,
  toggleAddressDialog: PropTypes.func,
  handleNote: PropTypes.func,
};

export default OpenAccountMob;
