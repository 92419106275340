import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import OrdersSearchArea from '../Common/OrdersSearchArea'
import TableHeader from '../Common/TableHeader'
import OrderItem from '../Common/OrderItem'
import DrawerContent from './DrawerContent'
import OrdersFilterBox from './OrdersFilterBox'

import { orderActions } from '../../../Redux/Actions/OrderActions'
import { userService, addressService } from '../../../Services'
import { generalConstants } from '../../../Utils/Constants'
import { dateFormatter } from '../../../Utils/Helpers'
import Loading from '../../../Utils/Loading'

import { styled } from '@mui/material/styles'
import Pagination from '@mui/material/Pagination'
import Drawer from '@mui/material/Drawer'
import Backdrop from '@mui/material/Backdrop'

const Root = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const StyledBackDrop = styled(Backdrop, {
  name: 'MuiDrawer',
  slot: 'Backdrop',
  overridesResolver: (props, styles) => {
    return styles.backdrop
  },
})({
  zIndex: -1,
  backdropFilter: 'blur(2px)',
  backgroundColor: 'rgba(0,0,0,0.005)',
})

const Orders = () => {
  const dispatch = useDispatch()

  const getOrders = (query) => dispatch(orderActions.getAllOrders(query))
  const getAdminOrders = (query) => dispatch(orderActions.getAdminOrders(query))

  const orders = useSelector(({ orders }) => orders)
  const loading = useSelector(({ orders }) => orders.loading)
  const customer = useSelector(({ customer }) => customer)
  const addresses = useSelector(({ addresses }) => addresses.addresses)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [pageCount, setPageCount] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [statusArr, setStatusArr] = useState([])
  const [userArr, setUserArr] = useState([])
  const [addressArr, setAddressArr] = useState([])
  const [sources, setSources] = useState([])
  const [users, setUsers] = useState([])
  const [dateChanged, setDateChanged] = useState(false)
  const [userAddresses, setUserAddresses] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortField: 'id',
    sortOrder: 'desc',
    filter: {
      status: 1,
      order_key: '',
      order_status: [],
      source: [],
      user_id: [],
      shipping_address: [],
    },
  })

  let isAdmin_ = customer?.isAdmin

  useEffect(() => {
    let active = true
    if (active) {
      let query_ = {
        pageNumber: 1,
        pageSize: 1000,
        sortField: 'id',
        sortOrder: 'asc',
        filter: {
          company_id: [],
        },
      }
      fetchOrders()
      let id = customer?.selectedUser?.company?.id
      let isAdmin = customer.isAdmin
      getUsers(query_, id, isAdmin)
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(customer), query])

  useEffect(() => {
    let active = true
    if (active && orders) {
      let pageCount_ = Math.round(orders?.totalCount / 10)
      setPageCount(pageCount_)
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  useEffect(() => {
    let active = true
    if (active) {
      if (customer?.isAdmin) {
        let id = customer?.currentCompany?.id
        let adminQuery = { ...query, filter: { company_id: id } }
        getCompanyAddresses(adminQuery)
      } else {
        addresses.length > 0
          ? setUserAddresses(addresses)
          : setUserAddresses(null)
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, customer])

  useEffect(() => {
    let active = true
    if (active) {
      let initialStart = new Date()
      let initialEnd = new Date()
      if (
        dateFormatter(initialStart) !== dateFormatter(date.startDate) ||
        dateFormatter(initialEnd) !== dateFormatter(date.endDate)
      ) {
        setDateChanged(true)
      } else {
        dateChanged && setDateChanged(false)
      }
    }

    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const getCompanyAddresses = async (query) => {
    const response = await addressService.getAdminUserAndCompanyAddresses(query)
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUserAddresses(response.data.addresses)
    } else {
      setUserAddresses(null)
    }
  }

  const fetchOrders = (refresh) => {
    const { selectedUser } = customer 
    refresh && setPageNumber(1)
    if (isAdmin_ || selectedUser?.role_names[0] === 'companyadmin') {
      let query_ = {
        ...query,
        filter: { ...query.filter, company_id: customer?.currentCompany?.id },
      }
      let refreshQuery = refresh ? {...query_, pageNumber: 1} : query_
      getAdminOrders(refreshQuery)
    } else {
      let refreshQuery = refresh ? {...query, pageNumber: 1} : query
      getOrders(refreshQuery)
    }
  }

  const handleChange = (e) => setSearchText(e.target.value)

  const clearSearchtext = () => {
    setSearchText('')
    setPageNumber(1)
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        ...query?.filter,
        order_key: '',
      },
    })
  }

  const onPageNumberChange = (event, value) => {
    setPageNumber(value)
    setQuery({
      ...query,
      pageNumber: value,
    })
  }

  const doSearch = () => {
    setPageNumber(1)
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        ...query?.filter,
        order_key: searchText,
      },
    })
  }

  const toggleDrawer = () => setOpenDrawer((prev) => !prev)

  const handleStatus = (id) => {
    if (statusArr.includes(id)) {
      let filteredArr = statusArr.filter((item) => item !== id)
      setStatusArr(filteredArr)
    } else {
      let newArr = [...statusArr, id]
      setStatusArr(newArr)
    }
  }

  const handleSource = (id) => {
    if (sources.includes(id)) {
      let filteredArr = sources.filter((item) => item !== id)
      setSources(filteredArr)
    } else {
      let newArr = [...sources, id]
      setSources(newArr)
    }
  }

  const handleUsers = (id) => {
    if (userArr.includes(id)) {
      let filteredArr = userArr.filter((item) => item !== id)
      setUserArr(filteredArr)
    } else {
      let newArr = [...userArr, id]
      setUserArr(newArr)
    }
  }

  const handleAddresses = (id) => {
    if (addressArr.includes(id)) {
      let filteredArr = addressArr.filter((item) => item !== id)
      setAddressArr(filteredArr)
    } else {
      let newArr = [...addressArr, id]
      setAddressArr(newArr)
    }
  }

  const getUsers = async (query, id, isAdmin) => {
    const response = await userService.getAllUsers_(query, id, isAdmin)
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUsers(response.data.users)
    } else {
      setUsers([])
    }
  }

  const clearFilters = () => {
    setStatusArr([])
    setSources([])
    setUserArr([])
    setAddressArr([])
    setSubmitted(false)
    setDate({
      startDate: new Date(),
      endDate: new Date(),
    })
  }

  const handleFiltersSubmit = () => {
    setPageNumber(1)
    setSubmitted(true)
    let startDate = dateFormatter(date.startDate)
    let endDate = dateFormatter(date.endDate)
    let query_ = {
      ...query,
      pageNumber: 1,
      filter: {
        ...query.filter,
        order_status: statusArr,
        source: sources,
        user_id: userArr,
        shipping_address: addressArr,
        created_at: dateChanged ? [startDate, endDate] : [],
      },
    }
    setQuery(query_)
    toggleDrawer()
  }

  const deleteFab = (item) => {
    setPageNumber(1)
    if (item.source === 'status') {
      handleStatus(item.id)
      setQuery({
        ...query,
        pageNumber: 1,
        filter: {
          ...query.filter,
          order_status: statusArr.filter((x) => x !== item.id),
        },
      })
    } else if (item.source === 'sources') {
      handleSource(item.id)
      setQuery({
        ...query,
        pageNumber: 1,
        filter: {
          ...query.filter,
          source: sources.filter((x) => x !== item.id),
        },
      })
    } else if (item.source === 'addresses') {
      handleAddresses(item.id)
      setQuery({
        ...query,
        pageNumber: 1,
        filter: {
          ...query.filter,
          shipping_address: query.filter.shipping_address.filter(
            (x) => x !== item.id
          ),
        },
      })
    } else if (item.source === 'users') {
      handleUsers(item.id)
      setQuery({
        ...query,
        pageNumber: 1,
        filter: {
          ...query.filter,
          user_id: query.filter.user_id.filter((x) => x !== item.id),
        },
      })
    } else if (item.source === 'date') {
      setSubmitted(false)
      setDate({
        startDate: new Date(),
        endDate: new Date(),
      })
      setQuery({
        ...query,
        pageNumber: 1,
        filter: {
          ...query.filter,
          created_at: [],
        },
      })
    }
  }

  const clearAll = () => {
    clearFilters()
    setPageNumber(1)
    setQuery({
      ...query,
      pageNumber: 1,
      filter: {
        ...query.filter,
        order_status: [],
        source: [],
        user_id: [],
        shipping_address: [],
        created_at: [],
      },
    })
  }

  return (
    <Root>
      <Loading open={loading} />
      <OrdersSearchArea
        handleChange={handleChange}
        clearSearchtext={clearSearchtext}
        searchText={searchText}
        doSearch={doSearch}
        toggleDrawer={toggleDrawer}
        fetchOrders={fetchOrders}
      />
      {query.filter.order_status.length > 0 ||
      query.filter.source.length > 0 ||
      query.filter.user_id.length > 0 ||
      (dateChanged && submitted) ||
      query.filter.shipping_address.length > 0 ? (
        <OrdersFilterBox
          users={users}
          userArr={userArr}
          statusArr={statusArr}
          sources={sources}
          date={date}
          addresses={userAddresses}
          addressArr={addressArr}
          deleteFab={deleteFab}
          clearFilters={clearAll}
          dateChanged={dateChanged}
        />
      ) : (
        <div />
      )}
      <TableHeader />
      {orders.orders &&
        orders.orders.map((order, index) => (
          <OrderItem key={index} order={order} />
        ))}
      {pageCount > 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Pagination
            page={pageNumber || 1}
            count={pageCount}
            onChange={onPageNumberChange}
          />
        </div>
      )}
      <Drawer
        BackdropComponent={StyledBackDrop}
        anchor={'right'}
        open={openDrawer}
        onClose={toggleDrawer}>
        <DrawerContent
          toggleDrawer={toggleDrawer}
          date={date}
          setDate={setDate}
          statusArr={statusArr}
          handleStatus={handleStatus}
          sources={sources}
          handleSource={handleSource}
          users={users}
          userArr={userArr}
          handleUsers={handleUsers}
          userAddresses={userAddresses}
          addressArr={addressArr}
          handleAddresses={handleAddresses}
          clearFilters={clearFilters}
          handleFiltersSubmit={handleFiltersSubmit}
        />
      </Drawer>
    </Root>
  )
}

export default Orders
