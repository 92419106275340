import React, { useState, useEffect, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import SearchHistory from "../Popovers/SearchHistory";

import { customerActions } from "../../Redux/Actions";

import { searchService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";

const StyledForm = styled("form")(() => ({
  width: "488px",
  marginTop: 10,
  minWidth: 320,
  maxWidth: 488,
  position: "relative",
}));

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isAnchor",
})(({ isAnchor, theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    height: 26,
    borderRadius: 4,
    padding: "3.5px 8px",
    fontSize: 14,
    fontWeight: 300,
    backgroundColor: "#fff",
    borderBottomLeftRadius: isAnchor && 0,
    borderBottomRightRadius: isAnchor && 0,
    "&:not(:focus)": {
      cursor: "pointer",
      border: isAnchor
        ? "2px solid #6E3564"
        : `2px solid ${theme.palette.darkGray.mid}`,
    },
    // "&:hover": {
    //   border: "2px solid #6E3564",
    // },
    "&:focus, &:active": {
      border: "2px solid #6E3564",
      borderBottom: isAnchor && "none",
      marginBottom: isAnchor && 2,
      borderBottomLeftRadius: isAnchor && 0,
      borderBottomRightRadius: isAnchor && 0,
    },
  },
}));

const CloseAdornment = styled(InputAdornment)(() => ({
  position: "absolute",
  right: 55,
  cursor: "pointer",
  zIndex: 9999,
}));

const StyledAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== "isAnchored" && prop !== "isFocused",
})(({ isAnchored, isFocused, theme }) => ({
  position: "absolute",
  cursor: "pointer",
  width: 50,
  height: 37,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: isAnchored ? 0 : 4,
  right: 0,
  top: 0,
  bottom: 0,
  border:
    isAnchored || isFocused
      ? "2px solid #6E3564"
      : `2px solid ${theme.palette.darkGray.mid}`,
  backgroundColor:
    isAnchored || isFocused
      ? theme.palette.primary.main
      : theme.palette.darkGray.mid,
  borderLeft: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "&.MuiInputAdornment-root": {
    maxHeight: 100,
  },
  "&:hover": {
    backgroundColor: !isAnchored && !isFocused && theme.palette.darkGray.mid,
    border:
      !isAnchored && !isFocused && `2px solid ${theme.palette.darkGray.mid}`,
    paddingRight: !isAnchored && !isFocused && 2,
  },
}));

const StyledSearchButton = styled(Typography)(() => ({
  fontWeight: 500,
  color: "#fff",
}));

const Search = (props, ref) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setText = (text) => dispatch(customerActions.setSearchText(text));

  const [keyword, setKeyword] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [focused, setFocused] = useState(false);
  const [results, setResults] = useState([]);
  const [historyResults, setHistoryResults] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const showPopover = (e) => setAnchorEl(e.currentTarget);
  const openPopover = Boolean(anchorEl);
  const closePopover = () => setAnchorEl(null);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const handleClearKeyword = () => setKeyword("");

  useEffect(() => {
    let active = true;
    if (active) {
      getSearchHistory();
    }
    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    let active = true;
    if (active && anchorEl) {
      getSearchHistory();
    }
    return () => {
      active = false;
    };
  }, [anchorEl]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (clicked) {
        closePopover();
        setClicked(false);
      }
    }
    return () => {
      active = false;
    };
  }, [clicked]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (keyword.length === 3) {
        getSearchHistory();
      }
    }
    return () => {
      active = false;
    };
  }, [keyword]);

  const handleHistoryClear = () => {
    if (historyResults.length === 0) return;
    setDisabled(true);
    searchService.clearHistory().then((res) => {
      if (res.data.status === generalConstants.STATUS_TRUE) {
        getSearchHistory();
      }
    });
  };

  const getSearchHistory = () => {
    searchService.getHistory().then((res) => {
      if (res?.data?.status === generalConstants.STATUS_TRUE) {
        setHistoryResults(res.data.search_history);
        setDisabled(false);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setText(keyword);
    closePopover();
    history.push("/filters-page");
  };

  const handleResultClick = (id) => {
    closePopover();
    history.push(`/product/${id}`);
  };

  const handleSearchClick = (e) => {
    if (keyword.length !== 0) {
      handleSubmit(e);
    }
    setClicked(true);
  };

  const handleChange = (e) => {
    let keyword = e.target.value;
    setKeyword(keyword);
    !anchorEl && showPopover(e);
    if (keyword.length >= 3) {
      searchService.getSuggestions(keyword).then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setResults(res.data.keywords);
        }
      });
    } else {
      setResults([]);
    }
  };

  const onCloseClick = () => {
    getSearchHistory();
    setKeyword("");
  };

  useImperativeHandle(ref, () => ({
    handleClearKeyword: () => {
      handleClearKeyword();
    },
  }));

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={closePopover}>
      <div style={{ position: "relative" }}>
        <StyledForm onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            value={keyword}
            isAnchor={Boolean(anchorEl)}
            size="small"
            placeholder="Binlerce ürün arasından arama yapabilirsiniz"
            onChange={handleChange}
            onMouseDown={showPopover}
            variant="standard"
            onFocus={onFocus}
            onBlur={onBlur}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  <StyledAdornment
                    position="end"
                    onMouseDown={handleSearchClick}
                    isAnchored={Boolean(anchorEl)}
                    isFocused={focused}>
                    <StyledSearchButton>ARA</StyledSearchButton>
                  </StyledAdornment>
                  {keyword.length > 0 && (
                    <CloseAdornment position="end" onMouseDown={onCloseClick}>
                      <Fade in={keyword.length > 0}>
                        <CloseIcon style={{ fill: "#8E8E8E" }} />
                      </Fade>
                    </CloseAdornment>
                  )}
                </>
              ),
            }}
          />
        </StyledForm>
        <SearchHistory
          open={openPopover}
          anchorEl={anchorEl}
          handleClose={closePopover}
          data={historyResults}
          results={results}
          handleHistoryClear={handleHistoryClear}
          setKeyword={setKeyword}
          keyword={keyword}
          handleResultClick={handleResultClick}
          disabled={disabled}
        />
      </div>
    </ClickAwayListener>
  );
};

export default React.forwardRef(Search);
