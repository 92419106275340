import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import deleteIcon from "../../Images/Cart/Delete.svg";
import updateIcon from "../../Images/Cart/Pencil.svg";

const Root = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Price = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(0.5),
}));

const SubContainer = styled("div")(() => ({
  display: "flex",
}));

const ActionButtons = ({ deleteProduct, product, openDialog, userId }) => {
  const role = useSelector(({ auth }) => auth.customerInfo.role_names);
  return (
    <Root>
      <Price>{role[0] === "companyuser" ? 0 : `${product?.total_cost}₺`}</Price>
      <SubContainer>
        <IconButton
          disableRipple
          onClick={() => deleteProduct(userId, product?.id, product?.key)}
          size="large">
          <img
            src={deleteIcon}
            alt="Delete Icon"
            width="14.24px"
            height="18px"
          />
        </IconButton>
        {product?.features && (
          <IconButton disableRipple onClick={openDialog} size="large">
            <img
              src={updateIcon}
              alt="Delete Icon"
              width="18.31px"
              height="18px"
            />
          </IconButton>
        )}
      </SubContainer>
    </Root>
  );
};

ActionButtons.propTypes = {
  deleteProduct: PropTypes.func,
  product: PropTypes.object,
  openDialog: PropTypes.func,
  userId: PropTypes.number,
};

export default ActionButtons;
