import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import grayCheckbox from "../../../../../Images/Filter/GrayCheckbox.svg";
import purpleRect from "../../../../../Images/UsersAndRoles/PurpleRect.svg";
import Checked from "../../../../../Images/Checked.svg";

const PREFIX = "AllChecked";

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 38,
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
  },

  [`& .${classes.formControl}`]: {
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      marginLeft: 5,
    },
  },
}));

const indeterminate = "indeterminate";

const AllChecked = ({ allChecked, handleAllCheck, checkBoxSections }) => {
  return (
    <Root className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormControlLabel
          style={{ marginBottom: 4 }}
          control={
            <Checkbox
              className={classes.check}
              disableRipple
              checked={allChecked === true}
              indeterminate={allChecked === indeterminate}
              onChange={handleAllCheck}
              id={checkBoxSections.mainLabel}
              name={checkBoxSections.mainLabel}
              value={allChecked}
              icon={<img src={grayCheckbox} width="20px" alt="" />}
              indeterminateIcon={<img src={purpleRect} width="20px" alt="" />}
              checkedIcon={<img src={Checked} width="20" alt="" />}
            />
          }
          label={checkBoxSections.mainLabel}
        />
      </FormControl>
    </Root>
  );
};

AllChecked.propTypes = {
  allChecked: PropTypes.any,
  handleAllCheck: PropTypes.func,
  checkBoxSections: PropTypes.object,
};

export default AllChecked;
