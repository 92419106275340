import React from "react";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

const PREFIX = "Header";

const classes = {
  headerContainer: `${PREFIX}-headerContainer`,
  headerText: `${PREFIX}-headerText`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.headerContainer}`]: {
    width: "100%",
    height: 38,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  },

  [`& .${classes.headerText}`]: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",
    margin: "9px 0px 5px 14px",
  },
}));

const Header = () => {
  return (
    <Root className={classes.headerContainer}>
      <Typography className={classes.headerText}>Filtreler</Typography>
    </Root>
  );
};

export default Header;
