import React, { useState, useImperativeHandle } from "react";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import usersApproved from "../../../../Images/UsersAndRoles/UsersApproved.svg";
import expandUp from "../../../../Images/Orders/ExpandUp.svg";
import expandDown from "../../../../Images/Orders/ExpandDown.svg";
import dotsIcon from "../../../../Images/UsersAndRoles/Dots.svg";

import InnerTabButtons from "./InnerTabButtons";
import TableRowCollapseOptions from "./TableRowCollapseOptions";
import TableRowCollapseUsers from "./TableRowCollapseUsers";
import RoleItemPopover from "../../../../Ui/Popovers/RoleItemPopover";

import AddRole from "../Dialogs/AddRole";
import EditRole from "../Dialogs/EditRole";

const boxClass = {
  width: "100%",
  height: 41,
  borderTop: "1px solid #E8E4E4",
  display: "flex",
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  padding: "0px 7px",
  alignItems: "center",
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "transparent",
  paddingTop: 0,
  paddingBottom: 5,
  paddingRight: 5,
  paddingLeft: 5,
  borderRadius: 8,
  boxShadow: "none",
  marginTop: 5,
  transition: theme.transitions.create(
    ["padding-right", "padding-left", "box-shadow"],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
  "&[collapsed='true']": {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

const StyledSubContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: 34,
  paddingRight: 20,
}));

const RowParts = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
}));

const ImgField = styled("div")(() => ({
  width: 23,
  marginRight: 10,
  position: "relative",
  display: "flex",
  alignItems: "center",
  marginLeft: 4,
}));

const Source = styled("div")(() => ({
  width: 120,
  marginRight: 10,
}));

const No = styled("div")(() => ({
  width: 150,
  marginRight: 10,
}));

const DateField = styled("div")(() => ({
  width: 150,
  marginRight: 10,
}));

const Person = styled("div")(() => ({
  width: 150,
}));

const StyledIconButton = styled(IconButton)(() => ({
  height: 30,
  width: 30,
  marginRight: 20,
  "&[expand-icon='true']": {
    marginRight: 0,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
  textAlign: "left",
  transition: theme.transitions.create(
    ["font-size", "padding-left", "font-weight"],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
}));

const TableRow = (props, ref) => {
  const [extended, setExtended] = useState(false);
  const [index, setIndex] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditRole, setOpenEditRole] = useState(false);
  const [openAddRole, setOpenAddRole] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const showEditRole = () => {
    setOpenEditRole(true);
    handleClose();
  };
  const closeEditRole = () => setOpenEditRole(false);

  const showAddRole = () => {
    setOpenAddRole(true);
    handleClose();
  };
  const closeAddRole = () => setOpenAddRole(false);

  const toggleCollapse = () => setExtended(!extended);

  useImperativeHandle(ref, () => ({
    showAddRole: () => {
      showAddRole();
    },
  }));

  return (
    <>
      <StyledCard collapsed={extended ? "true" : "false"}>
        <Box
          sx={{
            ...boxClass,
            borderBottom: extended ? "none" : "1px solid #E8E4E4",
            borderLeft: extended ? "none" : "1px solid #E8E4E4",
            borderRight: extended ? "none" : "1px solid #E8E4E4",
            borderBottomRightRadius: extended ? 0 : 8,
            borderBottomLeftRadius: extended ? 0 : 8,
            marginTop: props.firstRow && 0.8,
            transition: (theme) =>
              theme.transitions.create(["box-shadow"], {
                duration: (theme) => theme.transitions.duration.standard,
              }),
            boxShadow: extended ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "& .text": {
              color: theme =>  extended ? "#6E3564" : theme.palette.darkGray.main,
              fontSize: extended ? 16 : 14,
              fontWeight: extended ? 500 : 400,
              paddingLeft: extended && 1,
            },
            "&:hover": {
              boxShadow: extended
                ? "none"
                : "0px 4px 8px 0px rgba(0, 0, 0, 0.50)",
              "& .text": {
                color: (theme) => theme.palette.primary.main,
                fontSize: extended ? 16 : 14,
                fontWeight: extended ? 500 : 400,
                paddingLeft: extended && 1,
              },
            },
          }}>
          <StyledSubContainer collapsed={extended ? "true" : "false"}>
            <RowParts>
              <ImgField>
                <img
                  src={usersApproved}
                  width="19.17px"
                  height="11.67px"
                  alt=""
                />
              </ImgField>
              <Source>
                <Text noWrap className="text">
                  Yönetici
                </Text>
              </Source>
              <No>
                <Text noWrap className="text">
                  10.10.2021 13:00
                </Text>
              </No>
              <DateField>
                <Text noWrap className="text">
                  Kasım Karaboğa
                </Text>
              </DateField>
              <Person>
                <Text noWrap className="text">
                  8 Kullanıcı
                </Text>
              </Person>
            </RowParts>
            <RowParts>
              <StyledIconButton onClick={handleClick} size="large">
                <img src={dotsIcon} width="24px" height="24px" alt="" />
              </StyledIconButton>
              <StyledIconButton
                onClick={toggleCollapse}
                expand-icon="true"
                size="large">
                {extended ? (
                  <img src={expandUp} width="20px" alt="" />
                ) : (
                  <img src={expandDown} width="20px" alt="" />
                )}
              </StyledIconButton>
            </RowParts>
          </StyledSubContainer>
        </Box>
        <Collapse in={extended}>
          <div
            style={{
              width: "100%",
              height: "auto",
            }}>
            <InnerTabButtons index={index} setIndex={setIndex} />
            {index === 1 ? (
              <TableRowCollapseOptions />
            ) : (
              <TableRowCollapseUsers />
            )}
          </div>
        </Collapse>
      </StyledCard>
      <RoleItemPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        showEditRole={showEditRole}
        showAddRole={showAddRole}
      />
      <EditRole open={openEditRole} onClose={closeEditRole} />
      <AddRole open={openAddRole} onClose={closeAddRole} />
    </>
  );
};

export default React.forwardRef(TableRow);
