import React from "react";

import { styled } from '@mui/material/styles';

import Typography from "@mui/material/Typography";

const PREFIX = 'TableHeader';

const classes = {
  root: `${PREFIX}-root`,
  subHeader: `${PREFIX}-subHeader`,
  headerText: `${PREFIX}-headerText`,
  section: `${PREFIX}-section`
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
    paddingBottom: 5,
    borderBottom: `2px solid ${theme.palette.darkGray.light}`,
    height: 30,
  },

  [`& .${classes.subHeader}`]: {
    width: 1172,
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },

  [`& .${classes.headerText}`]: {
    width: "100%",
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    textAlign: "left",
  },

  [`& .${classes.section}`]: {
    "&[initial-header='true']": {
      minWidth: 42,
    },
    "&[register-id='true']": {
      width: 100,
    },
    "&[register-date='true']": {
      width: 147,
    },
    "&[name-field='true']": {
      width: 122,
    },
    "&[product-field='true']": {
      width: 200,
    },
    "&[explanation-field='true']": {
      width: 250,
    },
    "&[address-field='true']": {
      width: 120,
    },
    "&[state-field='true']": {
      width: 150,
    },
  }
}));

const TableHeader = () => {

  return (
    <Root className={classes.root}>
      <div className={classes.section} initial-header="true" />
      <div className={classes.subHeader}>
        <div className={classes.section} register-id="true">
          <Typography className={classes.headerText}>Kayıt no</Typography>
        </div>
        <div className={classes.section} register-date="true">
          <Typography className={classes.headerText}>Kayıt Tarihi</Typography>
        </div>
        <div className={classes.section} name-field="true">
          <Typography className={classes.headerText}>Ad-Soyad</Typography>
        </div>
        <div className={classes.section} product-field="true">
          <Typography className={classes.headerText}>Ürün</Typography>
        </div>
        <div className={classes.section} explanation-field="true">
          <Typography className={classes.headerText}>Açıklama</Typography>
        </div>
        <div className={classes.section} address-field="true">
          <Typography className={classes.headerText}>Sevk Adresi</Typography>
        </div>
        <div className={classes.section} state-field="true">
          <Typography className={classes.headerText}>Durum</Typography>
        </div>
      </div>
    </Root>
  );
};

export default TableHeader;
