import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Rating from "@mui/material/Rating";
import purpleStar from "../../../../Images/ProductPage/PurpleStar.svg";
import grayStar from "../../../../Images/ProductPage/GreyStar.svg";

const PREFIX = "ProductHeader";

const classes = {
  root: `${PREFIX}-root`,
  nameAndBrand: `${PREFIX}-nameAndBrand`,
  ratingContainer: `${PREFIX}-ratingContainer`,
  name: `${PREFIX}-name`,
  brand: `${PREFIX}-brand`,
  ratingText: `${PREFIX}-ratingText`,
  ratingNumber: `${PREFIX}-ratingNumber`,
  categoriesContainer:  `${PREFIX}-categoriesContainer`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "auto",
    borderBottom: "1px solid #B1AEAE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "&[component='fast-order']": {
      paddingBottom: 7,
    },
  },

  [`& .${classes.nameAndBrand}`]: {
    width: "fit-content",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  [`& .${classes.ratingContainer}`]: {
    width: "fit-content",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.name}`]: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.darkGray.main,
    marginRight: theme.spacing(3),
  },

  [`& .${classes.brand}`]: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.colors.green,
    backgroundColor: theme.palette.colors.lightGreen,
    padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
    borderRadius: 6,
    marginRight: 6,
    "&[sub-name='true']": {
      marginRight: 0,
      color: theme.palette.colors.turquoise,
      backgroundColor: theme.palette.colors.lightTurquoise,
  
    },
  },

  [`& .${classes.ratingText}`]: {
    width: "100%",
    fontSize: 10,
    fontWeight: 300,
    color: theme.palette.darkGray.main,
    textAlign: "right",
  },

  [`& .${classes.ratingNumber}`]: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.darkGray.main,
    marginRight: 5,
  },

  [`& .${classes.categoriesContainer}`]: {
    display: 'flex',
    paddingBottom: theme.spacing()
  },
}));

const ProductHeader = ({ data, component }) => {
  return (
    <Root className={classes.root} component={component}>
      <div className={classes.nameAndBrand}>
        <Typography className={classes.name}>{data?.name}</Typography>
        <Box className={classes.categoriesContainer}>
          <Typography className={classes.brand}>{data?.brand.name}</Typography>
          <Typography className={classes.brand} sub-name="true">
            {data?.sub_category.name}
          </Typography>
        </Box>
      </div>
      {data?.rating && (
        <div className={classes.ratingContainer}>
          <div style={{ display: "flex" }}>
            <Typography className={classes.ratingNumber}>
              {parseInt(data?.rating.score).toFixed(1)}
            </Typography>
            <Rating
              name="purple-stars"
              value={parseInt(data?.rating.score)}
              precision={0.5}
              icon={
                <img
                  src={purpleStar}
                  width="13.33px"
                  height="13.33px"
                  alt="Gray Star"
                />
              }
              emptyIcon={
                <img
                  src={grayStar}
                  width="13.33px"
                  height="13.33px"
                  alt="Purple Star"
                />
              }
              readOnly
            />
          </div>
          <Typography className={classes.ratingText}>
            {data?.rating.count} Değerlendirme
          </Typography>
        </div>
      )}
    </Root>
  );
};

ProductHeader.propTypes = {
  data: PropTypes.object,
  component: PropTypes.string,
};

export default ProductHeader;
