const dateFormatter = (date, fab) => {
  let date_ = new Date(date);
  let dd = String(date_.getDate()).padStart(2, "0");
  let mm = String(date_.getMonth() + 1).padStart(2, "0");
  let yyyy = date_.getFullYear();
  let today = yyyy + "-" + mm + "-" + dd;
  let fabDate = dd + "/" + mm + "/" + yyyy;
  return fab ? fabDate : today;
};

export { dateFormatter };
