import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import Collapse from "@mui/material/Collapse";

import { productActions } from "../../Redux/Actions";
import Loading from "../../Utils/Loading";

import NameAndPrice from "./CartProductMob/NameAndPrice";
import ImageAndCost from "./CartProductMob/ImageAndCost";
import QuantitySelection from "./CartProductMob/QuantitySelection";
import MobileSpecialDemandsCart from "../Dialog/Mobile/MobileSpecialDemandsCart";
import CollapseContent from "./CartProductInner/CollapseContent";
import ButtonsArea from "./CartProductMob/ButtonsArea";

const PREFIX = "CartProductMob";

const classes = {
  productContainer: `${PREFIX}-productContainer`,
  flexColumn: `${PREFIX}-flexColumn`,
  flexSide: `${PREFIX}-flexSide`,
  borderTop: `${PREFIX}-borderTop`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.productContainer}`]: {
    backgroundColor: "#F9F7F7",
    width: "100%",
    padding: "11px 6px 0px 16px",
    minWidth: 275,
    minHeight: 110,
    height: "auto",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: theme.spacing(2),
  },

  [`&.${classes.flexColumn}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.flexSide}`]: {
    display: "flex",
  },

  [`& .${classes.borderTop}`]: {
    borderTop: `1px solid ${theme.palette.darkGray.light}`,
  },
}));

const CartProductMob = ({ product, deleteProduct }) => {
  const dispatch = useDispatch();
  const isFeatures = product?.features;

  const update = (query) => dispatch(productActions.updateProduct(query));
  const updateAdmin = (query, userId) =>
    dispatch(productActions.updateAdminProduct(query, userId));

  const authInfo = useSelector(({ auth }) => auth.customerInfo);
  const customer = useSelector(({ customer }) => customer);
  const loading = useSelector(({ product }) => product.loading);

  const specialFeatureValues = useSelector(
    ({ product }) => product.featureValues
  );

  let isCompanyUser =
    customer?.selectedUser?.role_names[0] === "companyuser" || null;

  const [open, setOpen] = useState(false);
  const [extended, setExtended] = useState(false);
  const [initialQuery, setInitialQuery] = useState(null);
  const [selections, setSelections] = useState({
    product_id: "",
    quantity: product?.quantity,
    key: "",
    features: {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    },
  });

  useEffect(() => {
    if (product) {
      setSelections({
        ...selections,
        quantity: product.quantity,
        features: product.features,
      });
      setInitialQuery({
        product_id: product.id,
        quantity: product.quantity,
        features: product.features,
        key: product.key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const closeDialogFromButton = () => {
    let initialFeatures = {
      width: "",
      height: "",
      shape: "",
      side: "",
      webType: "",
      webColor: "",
    };
    if (product.features) {
      setSelections(initialQuery);
    } else {
      setSelections({
        ...initialQuery,
        features: initialFeatures,
      });
    }
    closeDialog();
  };

  const handleCollapse = () => setExtended(!extended);

  const onClickHandler = () => {
    isFeatures ? handleCollapse() : openDialog();
  };

  const handleChange = (prop) => (event) => {
    setSelections({
      ...selections,
      [prop]: event.target.value,
    });
  };

  const handleFeatures = (prop) => (e) => {
    let dimensions = product.attributes_map.Ebat;
    let width = parseInt(dimensions.split("x")[0]);
    let height = parseInt(dimensions.split("x")[1]);
    let data = e.target.value;
    if (selections.features[prop] === data) {
      setSelections({
        ...selections,
        features: { ...selections.features, [prop]: "" },
      });
    } else {
      if (
        prop === "side" &&
        data !== "Saçak" &&
        selections.features.webType !== ""
      ) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: data,
            webType: "",
          },
        });
      } else if (prop === "height") {
        if (product.type.id === 2 && data > height) {
          setSelections({
            ...selections,
            features: { ...selections.features, [prop]: height },
          });
        } else {
          let height_ = data > 2500 ? 2500 : data;
          setSelections({
            ...selections,
            features: { ...selections.features, [prop]: height_ },
          });
        }
      } else if (prop === "width" && data > width) {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: width },
        });
      } else {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: data },
        });
      }
    }
  };

  const increaseQuantity = () => {
    let query = {
      product_id: product.id,
      quantity: product.quantity + 1,
      features: product.features,
      key: product.key,
    };
    if (customer?.isAdmin) {
      updateAdmin(query, customer?.selectedUser?.id);
    } else {
      update(query);
    }
  };

  const decreaseQuantity = () => {
    let query = {
      product_id: product.id,
      quantity: product.quantity - 1,
      features: product.features,
      key: product.key,
    };
    if (customer?.isAdmin) {
      updateAdmin(query, customer?.selectedUser?.id);
    } else {
      update(query);
    }
  };

  const handleModalSubmit = () => {
    closeDialog();
    let query = {
      ...selections,
      product_id: product.id,
      key: product.key,
    };
    if (customer?.isAdmin) {
      updateAdmin(query, customer?.selectedUser?.id);
    } else {
      update(query);
    }
  };

  let userId = customer?.isAdmin ? customer?.selectedUser?.id : authInfo?.id;
  return (
    <Root
      className={classnames({
        [classes.productContainer]: true,
        [classes.flexColumn]: true,
      })}>
      <Loading open={loading} />
      <div className={classes.flexSide}>
        <ImageAndCost isCompanyUser={isCompanyUser} product={product} />
        <div className={classes.flexColumn} style={{ width: "100%" }}>
          <NameAndPrice product={product} isCompanyUser={isCompanyUser} />
          <>
            <QuantitySelection
              product={product}
              decreaseQuantity={decreaseQuantity}
              increaseQuantity={increaseQuantity}
            />
            <ButtonsArea
              onClickHandler={onClickHandler}
              deleteProduct={deleteProduct}
              userId={userId}
              openDialog={openDialog}
              extended={extended}
              isFeatures={isFeatures}
              product={product}
            />
          </>
        </div>
      </div>
      {product?.features && (
        <Collapse in={extended}>
          <CollapseContent features={selections?.features} mobile />
        </Collapse>
      )}
      <MobileSpecialDemandsCart
        handleClose={closeDialogFromButton}
        open={open}
        data={product}
        features={selections.features}
        handleFeatures={handleFeatures}
        specialFeatureValues={specialFeatureValues && specialFeatureValues}
        selections={selections}
        handleChange={handleChange}
        handleSubmit={handleModalSubmit}
        setSelections={setSelections}
      />
    </Root>
  );
};

export default CartProductMob;
