import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: 300,
    height: 100,
    padding: theme.spacing(2),
  },
  display: "flex",
  flexDirection: "column",
}));

const ButtonsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "decline",
})(({ decline }) => ({
  width: 100,
  height: 30,
  color: "#fff",
  textTransform: "capitalize",
  backgroundColor: decline ? "#6A6868" : "#10C66C",
  "&:hover, &:focus, &:active": {
    backgroundColor: decline ? "#6A6868" : "#10C66C",
  },
}));

const StyledText = styled(Typography)(({theme}) => ({
  width: "100%",
  textAlign: "center",
  color: theme.palette.darkGray.main,
  fontWeight: 500,
  fontSize: 16,
}));

const ConfirmationDialog = ({ open, decline, accept }) => {
  return (
    <StyledDialog open={open}>
      <StyledText>Değişiklikleri onaylıyor musunuz?</StyledText>
      <ButtonsContainer>
        <StyledButton decline onClick={decline}>
          Vazgeç
        </StyledButton>
        <StyledButton onClick={accept}>Onayla</StyledButton>
      </ButtonsContainer>
    </StyledDialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  decline: PropTypes.func,
  accept: PropTypes.func,
};

export default ConfirmationDialog;
