import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import noNotifications from "../../../Images/TopNavbar/NoNotifications.svg";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: 320,
  minHeight: 320,
  overflowY: "scroll !important",
  paddingBottom: theme.spacing(8),
}));

const ImgContainer = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const data = [
  // {
  //   header: "Yeni sipariş eklendi",
  //   content:
  //     "Siparişiniz tarafımıza ulaştı. Size en hızlı şekilde ulaştırabilmek için hazırlamaya başladık.",
  // },
  // {
  //   header: "Yeni desenlerimiz eklendi",
  //   content:
  //     "Rapsodi kalitemize 7 adet yeni desen eklenmiştir. Görmek için #rapsodi kategorisine bakınız.",
  // },
  // {
  //   header: "Arızalı ürünleriniz sonuçlandı",
  //   content:
  //     "12.01.2021 Tarihli arızalı ürün kaydınızı sonuçlandırdık. Detay için #1234455 nolu kayda bakınız.",
  // },
  // {
  //   header: "Ürünleriniz kargoya verildi",
  //   content:
  //     "13.06.2021 13:23 Tarihinde ürünleriniz kargoya verdik. Detay için #1234455 nolu kayda bakınız.",
  // },
];

const Messages = () => {
  return (
    <StyledRoot>
      {data.length > 0 ? (
        data.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderBottom: "1px solid #ECECEC",
              padding: "20px 16px 16px 16px",
              overflowY: "auto",
              "&:hover": {
                "& .header": {
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: 700,
                },
              },
              "&:hover ": {
                "& .content": {
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: 400,
                },
              },
            }}>
            <Typography
              className="header"
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: (theme) => theme.palette.secondary.main,
              }}>
              {item.header}
            </Typography>
            <Typography
              className="content"
              sx={{ fontSize: 12, fontWeight: 300, color: "#696969" }}>
              {item.content}
            </Typography>
          </Box>
        ))
      ) : (
        <ImgContainer>
          <img
            src={noNotifications}
            width="110.82px"
            height="98.84px"
            alt="No Notifications"
          />
          <Typography>Mesaj kutunuz boş.</Typography>
        </ImgContainer>
      )}
    </StyledRoot>
  );
};

export default Messages;
