import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const PREFIX = "Label";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 270,
    height: 17.93,
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 4,
    userSelect: "none",
  },
}));

const MobileLabel = styled("p")(({ theme }) => ({
  width: "82%",
  height: 17.93,
  textAlign: "left",
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 400,
  marginBottom: 4,
  userSelect: "none",
  paddingLeft: 10,
  marginTop: 0,
}));

const Label = ({ children, mobile }) => {
  return (
    <>
      {mobile ? (
        <MobileLabel>{children}</MobileLabel>
      ) : (
        <Root className={classes.root}>{children}</Root>
      )}
    </>
  );
};

Label.propTypes = {
  children: PropTypes.string,
  mobile: PropTypes.bool,
};

export default Label;
