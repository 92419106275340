import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getAllOrders = async (query) => {
  return await axios
    .post(`${API_URL}/order/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getAdminOrders = async (query) => {
  return await axios
    .post(`${API_URL}/order/admin/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const initializeOrder = async (query) => {
  return await axios
    .post(`${API_URL}/payment/3ds/initialize`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const initializeAdminOrder = async (query) => {
  return await axios
    .post(
      `${API_URL}/payment/admin/3ds/initialize`,
      query,
      authCustomerHeader()
    )
    .then((res) => {
      return res;
    });
};

const initializeOrderOpenAccount = async (query) => {
  return await axios
    .post(`${API_URL}/payment/create`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const initializeOrderOpenAccountAdmin = async (query) => {
  return await axios
    .post(`${API_URL}/payment/admin/create`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getOrder = async (id) => {
  return await axios
    .get(`${API_URL}/order/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const orderCallback = async (query) => {
  return await axios
    .post(`${API_URL}/order/callback`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const orderService = {
  initializeOrder,
  getAllOrders,
  getOrder,
  orderCallback,
  getAdminOrders,
  initializeAdminOrder,
  initializeOrderOpenAccount,
  initializeOrderOpenAccountAdmin,
};
