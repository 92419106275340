import React, { useState } from "react";
import PropTypes from "prop-types";
import {useSelector, shallowEqual} from "react-redux"

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import ImgField from "./MobileOrderItem/ImgField";
import CircleArrow from "../../../Images/Orders/CircleArrow.svg";
import blueBox from "../../../Images/Orders/BlueBox.svg";
import redBox from "../../../Images/Orders/RedBox.svg";
import greenBox from "../../../Images/Orders/GreenBox.svg";
import yellowBox from "../../../Images/Orders/YellowBox.svg";

import MobileOrderDetails from "../MobileDialogs/MobileOrderDetails";

import { SourceValues } from "../../../Utils/Constants";

const innerWidth = window.innerWidth;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 130,
  padding: theme.spacing(),
  paddingBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  marginBottom: theme.spacing(2),
  borderRadius: 6,
}));

const UpContainer = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(),
  display: "flex",
  justifyContent: "space-between",
  flex: 1,
  marginBottom: theme.spacing(),
}));

const UpLeft = styled("div")(({ theme }) => ({
  width: 40,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginRight: theme.spacing(),
}));

const SourceText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: "16px",
  fontWeight: 500,
  color: theme.palette.darkGray.main,
}));

const UpMiddle = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const MiddleRow = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
}));

const RowText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "bold",
})(({ theme, bold }) => ({
  width: bold ? 85 : innerWidth - 210,
  height: "auto",
  fontSize: 14,
  lineHeight: "16px",
  color: bold ? theme.palette.darkGray.main : "#B1AEAE",
  fontWeight: 400,
}));

const UpRight = styled("div")(() => ({
  width: 30,
  height: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));

const BottomContainer = styled("div")(() => ({
  width: "100%",
  height: 36,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const BottomLeft = styled("div")(({ theme }) => ({
  width: 90,
  height: 16,
  display: "flex",
  // justifyContent: "space-between",
}));

const BottomText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "bold",
})(({ theme, bold }) => ({
  fontSize: 12,
  lineHeight: "16px",
  fontWeight: bold ? 700 : 400,
  color: theme.palette.secondary.main,
  width: bold && 80,
  textAlign: bold && "right",
}));

const MobileOrderItem = ({ order }) => {
  const {role_names} = useSelector(({ customer }) => customer?.selectedUser, shallowEqual);

  const [open, setOpen] = useState(false);

  const toggleDetailDialog = () => setOpen((prev) => !prev);

  const orderStatus = {
    0: { icon: blueBox, status: "Sipariş Alındı" },
    1: { icon: blueBox, status: "Hazırlanıyor" },
    2: { icon: greenBox, status: "Tamamlandı" },
    3: { icon: yellowBox, status: "Kısmi Sevk" },
    4: { icon: redBox, status: "İptal" },
  };

  const getSource = (src) => {
    if (src) {
      let source = SourceValues.filter((s) => s.id === src);
      if (source.length > 0) return source[0].status;
      return null;
    } else {
      return null;
    }
  };

  return (
    <Root>
      <UpContainer>
        <UpLeft>
          <ImgField order={order} />
          <SourceText>{getSource(order?.source)}</SourceText>
        </UpLeft>
        <UpMiddle>
          <MiddleRow>
            <RowText bold>No</RowText>
            <RowText noWrap>{order?.key}</RowText>
          </MiddleRow>
          <MiddleRow>
            <RowText bold>Tarih</RowText>
            <RowText noWrap>{order?.date}</RowText>
          </MiddleRow>
          <MiddleRow>
            <RowText bold>Oluşturan</RowText>
            <RowText noWrap>{order?.buyer.company.name}</RowText>
          </MiddleRow>
          <MiddleRow>
            <RowText bold>Sevk Adresi</RowText>
            <RowText noWrap>{order?.address.name}</RowText>
          </MiddleRow>
        </UpMiddle>
        <UpRight>
          <img
            onClick={toggleDetailDialog}
            src={CircleArrow}
            width="25"
            alt=""
          />
        </UpRight>
      </UpContainer>
      <BottomContainer>
        <BottomLeft>
          <img
            src={orderStatus[order?.status]?.icon}
            width="20px"
            height="20px"
            alt=""
            style={{ marginRight: 10 }}
          />
          <BottomText>{orderStatus[order?.status]?.status}</BottomText>
        </BottomLeft>
        <BottomText bold>{role_names && role_names[0] === 'companyuser' ? 0 : order?.total_cost}</BottomText>
      </BottomContainer>
      <MobileOrderDetails
        open={open}
        handleClose={toggleDetailDialog}
        id={order?.id}
      />
    </Root>
  );
};

MobileOrderItem.propTypes = {
  order: PropTypes.object,
};

export default MobileOrderItem;
