export const attributesQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: "name",
  sortOrder: "asc",
  filter: {
    status: 1,
  },
};

export const addressQuery = {
  page: 1,
  pageSize: 200,
  sortField: "id",
  sortOrder: "asc",
  filter: {},
};

export const brandsAndCategoriesQuery = {
  pageNumber: 1,
  pageSize: 200,
  sortField: "name",
  sortOrder: "asc",
  filter: { status: 1 },
};

export const generalReqQuery = {
  pageNumber: 1,
  pageSize: 1000,
  sortField: "name",
  sortOrder: "asc",
  filter: { status: 1 },
};

export const ordersQuery = {
  pageNumber: 1,
  pageSize: 50,
  sortField: "id",
  sortOrder: "desc",
  filter: { status: 1 },
};

export const usersQuery = {
  pageNumber: 1,
  pageSize: 1000,
  sortField: "id",
  sortOrder: "asc",
  filter: {},
};

export const notificationsQuery = {
  pageNumber: 1,
  pageSize: 1000,
  sortField: "id",
  sortOrder: "desc",
  filter: {},
};
