import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const ProductName = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: "#000000",
  lineHeight: "20px",
  marginBottom: theme.spacing(),
  cursor: "pointer",
}));

const FlexSide = styled("div")(() => ({
  display: "flex",
}));

const UnitPrice = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "priceValue",
})(({ theme, priceValue }) => ({
  fontSize: 10,
  fontWeight: priceValue ? 400 : 700,
  lineHeight: "16px",
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(),
  marginLeft: priceValue && theme.spacing(),
}));

const NameAndPrice = ({ product, isCompanyUser }) => {
  const history = useHistory();

  const handleClick = () => history.push(`/product/${product.id}`);

  return (
    <>
      <ProductName onClick={handleClick}>{product?.name}</ProductName>
      {!isCompanyUser && <FlexSide>
        <UnitPrice>Birim Fiyat</UnitPrice>
        <UnitPrice priceValue>{product?.cost}</UnitPrice>
      </FlexSide>}
    </>
  );
};

NameAndPrice.propTypes = {
  product: PropTypes.object,
  isCompanyUser: PropTypes.bool,
};

export default NameAndPrice;
