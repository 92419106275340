import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Row from "./CollapseContent/Row";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(2.5),
}));

const CollapseContent = ({ features, mobile }) => {
  return (
    <Root>
      <Row
        val1={features?.width}
        val2={features?.height}
        name1="En:"
        name2="Boy:"
        mobile={mobile}
      />
      <Row
        val1={features?.shape}
        val2={features?.side}
        name1="Şekil:"
        name2="Kenar:"
        mobile={mobile}
      />
      {features?.side === "Saçak" && (
        <Row
          val1={features?.webType}
          name1="Saçak Tipi:"
          single
          mobile={mobile}
        />
      )}
    </Root>
  );
};

CollapseContent.propTypes = {
  features: PropTypes.object,
  mobile: PropTypes.bool,
};

export default CollapseContent;
