import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import { toast } from "react-toastify";

import sendIcon from "../../Images/Cart/Send.svg";
import sendPurpleIcon from "../../Images/Cart/SendPurple.svg";
import purpleRadio from "../../Images/DealerProfile/PurpleRadio.svg";
import mailIcon from "../../Images/Cart/Mail.svg";
import mailPurpleIcon from "../../Images/Cart/MailPurple.svg";
import phoneIcon from "../../Images/Cart/Phone.svg";
import phonePurpleIcon from "../../Images/Cart/PhonePurple.svg";
import HomePurple from "../../Images/Cart/HomePurple.svg";
import Home from "../../Images/Cart/Home.svg";

import SwitchArea from "./SwitchArea";

import { customerActions, addressActions } from "../../Redux/Actions";
import MobileAddressSelectionConfirmation from "../../Views/Addresses/MobileDialogs/MobileAddressSelectionConfirmation";

const Root = styled(Card, {
  shouldForwardProp: (prop) => prop !== "isPassive" && prop !== "newAddress",
})(({ theme, isPassive, newAddress }) => ({
  width: 280,
  height: "auto",
  backgroundColor: newAddress ? "#d2f5e4" : "#fff",
  borderRadius: 6,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  opacity: isPassive ? 0.5 : 1,
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const Header = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: selected ? theme.palette.primary.main : theme.palette.darkGray.main,
}));

const AddressRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
  marginBottom: theme.spacing(0.5),
}));

const CardText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.darkGray.main,
}));

const StyledImg = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(),
}));

const PhoneAndMailRow = styled("div")(({ theme }) => ({
  display: "flex",
  borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const CartAddressBox = ({
  item,
  firm,
  addressPage,
  handleSwitch,
  defaultAddr,
  loading,
  onClose
}) => {
  const dispatch = useDispatch();

  const setUserCurrent = (query) =>
    dispatch(customerActions.setUserCurrent(query));

  const toggleAddress = (isAdmin, query, id, address) =>
    dispatch(addressActions.toggleDefault(isAdmin, query, id, address));

  const resetNewAddress = () => dispatch(addressActions.setNewAddressId(null))

  const customer = useSelector(({ customer }) => customer);
  const newAddressId = useSelector(({ addresses }) => addresses.newAddressId);

  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    let active = true;
    if (active) {
      addressPage && !firm ? setShow(false) : setShow(true);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressPage, firm]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (newAddressId && newAddressId === item.id) {
        handleCheckCart(item);
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddressId, item]);

  const handleCheckCart = (item, e) => {
    let query = {
      address: item,
      company: customer.currentCompany,
    };
    setUserCurrent(query);
    if(newAddressId !== item.id) {
      resetNewAddress()
      setTimeout(() => closeAndShowToast(), 1000 ) 
    }
  };

  const closeAndShowToast = () => {
    onClose()
    toast.success("Sevk adresiniz değiştirilmiştir.");
  }

  const handleItemCheck = (item, e) => {
    setAddress(item);
    addressPage ? toggleDialog() : handleCheckCart(item);
  };

  let id = +customer?.currentAddress?.id;
  let checkedParam = addressPage ? (defaultAddr ? +defaultAddr.id : id) : id;

  const toggleDialog = () => setOpen((prev) => !prev);

  const confirm = () => {
    let query = {
      is_default: true,
      address_id: address.id,
    };

    let isAdmin = customer?.isAdmin;
    let userId = customer?.selectedUser?.id;
    toggleAddress(isAdmin, query, userId, address);
    toggleDialog();
  };

  return (
    <Root
      elevation={3}
      isPassive={item.status === 0}
      newAddress={!addressPage && newAddressId === item.id}>
      <Row>
        <img
          src={
            +item?.id === +customer?.currentAddress?.id
              ? firm
                ? HomePurple
                : sendPurpleIcon
              : firm
              ? Home
              : sendIcon
          }
          height="24px"
          width="24px"
          alt=""
        />
        <Header selected={+item?.id === checkedParam}>{item?.name}</Header>
        {show ? (
          <Radio
            checkedIcon={<img src={purpleRadio} alt="Purple Radio" />}
            onChange={(e) => handleItemCheck(item, e)}
            checked={+item?.id === +customer?.currentAddress?.id}
            style={{ height: 20, width: 20 }}
            value={item?.id}
            disabled={item?.status === 0}
          />
        ) : (
          <div style={{ width: 30, height: 30 }} />
        )}
      </Row>
      <AddressRow>
        <CardText>{item?.address1}</CardText>
        <CardText>{`${item?.county.name}/${item?.city.name}`}</CardText>
      </AddressRow>
      <PhoneAndMailRow>
        <StyledImg
          src={
            +item?.id === +customer?.currentAddress?.id
              ? mailPurpleIcon
              : mailIcon
          }
          width="18px"
          alt=""
        />
        <CardText>{item?.email ? item.email : ""}</CardText>
      </PhoneAndMailRow>
      <PhoneAndMailRow>
        <StyledImg
          src={
            +item?.id === +customer?.currentAddress?.id
              ? phonePurpleIcon
              : phoneIcon
          }
          width="18px"
          alt=""
        />
        <CardText>{item?.phone1}</CardText>
      </PhoneAndMailRow>
      {addressPage && !firm && (
        <SwitchArea item={item} handleSwitch={handleSwitch} loading={loading} />
      )}
      <MobileAddressSelectionConfirmation
        open={open}
        cancel={toggleDialog}
        confirm={confirm}
        item={address}
      />
    </Root>
  );
};

CartAddressBox.propTypes = {
  item: PropTypes.object,
  firm: PropTypes.bool,
  addressPage: PropTypes.bool,
  handleSwitch: PropTypes.func,
  defaultAddr: PropTypes.object,
  loading: PropTypes.bool,
  onClose: PropTypes.func
};

export default CartAddressBox;
