import React, { useEffect } from "react";
import Carousel from "nuka-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { campaignActions } from "../../Redux/Actions";

import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")(() => ({
  outline: "none",
  "&:focus": { outline: "none" },
  backgroundColor: "#ffffff",
  height: 200,
  // marginLeft: theme.spacing(),
  // marginRight: theme.spacing(),
  borderRadius: 8,
}));

const MobileCarousel = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // const slidesToShow = 1 + ((innerWidth - innerWidth * 0.7) / innerWidth) * 0.8;

  const getCampaigns = (query) => dispatch(campaignActions.getCampaigns(query));

  const campaigns = useSelector(({ campaigns }) => campaigns.campaigns);

  useEffect(() => {
    let active = true;
    if (active) {
      let query = {
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "desc",
        filter: {},
      };
      getCampaigns(query);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCampaignClick = (id) => history.push(`./campaign/${id}`);

  return (
    <Carousel
      transitionMode="scroll"
      slidesToShow={1}
      speed={500}
      cellSpacing={8}
      withoutControls
      slideWidth={0.9}
      wrapAround={true}
      cellAlign="center"
      style={{
        marginTop: 8,
        height: 200,
        WebkitTapHighlightColor: "transparent",
      }}>
      {campaigns?.map((item, index) => (
        <StyledDiv key={index} onClick={() => onCampaignClick(item.id)}>
          <img
            src={item.image}
            width={
              '100%'
            }
            height="175px"
            alt=""
            style={{ borderRadius: 8 }}
          />
        </StyledDiv>
      ))}
    </Carousel>
  );
};

export default MobileCarousel;
