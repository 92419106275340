import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SectionContainer from "../Common/SectionContainer";

import EditProfileDetails from "../NewDialogs/EditProfileDetails";
import EditFirmDetails from "../NewDialogs/EditFirmDetails";

import SubCard from "../../../Ui/DraggableCards/SubCard";

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const Header = styled(Typography)(({ theme }) => ({
  height: "auto",
  fontSize: 24,
  fontWeight: 700,
  lineHeight: "30px",
  color: theme.palette.darkGray.main,
}));

const StyledButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 6,
  height: 30,
  width: 150,
  color: "#fff",
  backgroundColor: "#6A6868",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6A6868",
  },
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "auto",
  marginBottom: theme.spacing(),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "value",
})(({ theme, value }) => ({
  width: value ? "fit-content" : 300,
  height: "auto",
  fontSize: 24,
  lineHeight: "30px",
  color: value ? theme.palette.darkGray.main : "#6A6868",
  marginRight: value && theme.spacing(2),
  fontWeight: 400,
}));

const Grow = styled("div")(() => ({
  flex: 1,
}));

const ProfileDetails = ({ person }) => {
  const customer = useSelector(({ customer }) => customer);

  const [open, setOpen] = useState(false);
  const [openFirmSelection, setOpenFirmSelection] = useState(false);
  const [openAddressSelection, setOpenAddressSelection] = useState(false);
  const [values, setValues] = useState({
    firm: null,
    branch: null,
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer.isAdmin) {
        setValues({
          firm: "---",
          branch: "---",
        });
      } else {
        setValues({
          firm: customer.currentCompany,
          branch: customer.currentAddress,
        });
      }
    }
    return () => {
      active = false;
    };
  }, [customer]);

  const toggleDialog = () => setOpen(!open);

  const toggleAddressDialog = () => {
    customer?.isAdmin
      ? setOpenFirmSelection((prev) => !prev)
      : setOpenAddressSelection((prev) => !prev);
  };

  return (
    <SectionContainer first>
      <HeaderContainer>
        <Header>Profil Detay</Header>
      </HeaderContainer>
      <Row>
        <Text>Ad/Soyad</Text>
        <Text value>{person?.full_name}</Text>
        <Grow />
        <StyledButton onClick={toggleDialog}>Bilgi Değiştir</StyledButton>
      </Row>
      <Row>
        <Text>Firma</Text>
        <Text value>{values?.firm?.name}</Text>
        <Grow />
        <StyledButton onClick={toggleAddressDialog}>
          {customer?.isAdmin ? "Firma/Adres Değiştir" : "Adres Değiştir"}
        </StyledButton>
      </Row>
      <Row>
        <Text>Tanımlı Adres</Text>
        <Text value>{values?.branch?.name}</Text>
        <Grow />
      </Row>
      <EditProfileDetails open={open} onClose={toggleDialog} user={person} />
      <EditFirmDetails
        open={openAddressSelection}
        onClose={toggleAddressDialog}
        user={person}
      />
      <SubCard
        handleCloseSub={toggleAddressDialog}
        openSub={openFirmSelection}
      />
    </SectionContainer>
  );
};

ProfileDetails.propTypes = {
  person: PropTypes.object,
};

export default ProfileDetails;
