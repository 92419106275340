import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

import ButtonsArea from "./DrawerContent/ButtonsArea";
import StatusSection from "./DrawerContent/StatusSection";
import FirmsSection from "./DrawerContent/FirmsSection";
import RolesSection from "./DrawerContent/RolesSection";

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
  position: "absolute",
  top: 0,
  paddingRight: theme.spacing(2),
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  lineHeight: "42px",
  color: theme.palette.primary.main,
  fontWeight: 400,
}));

const SubContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  height: "100vh",
}));

const DrawerContent = ({
  toggleDrawer,
  statusArr,
  setStatusArr,
  commonHandler,
  clearFilters,
  handleFiltersSubmit,
  firms,
  firmsArr,
  setFirmsArr,
  isAdmin,
  rolesArr,
  setRolesArr,
}) => {
  const renderStatusSection = useMemo(
    () => (
      <StatusSection
        values={statusArr}
        callBack={commonHandler}
        stateSetter={setStatusArr}
      />
    ),
    [statusArr, commonHandler, setStatusArr]
  );

  const renderFirmsSection = useMemo(
    () => (
      <FirmsSection
        values={firmsArr}
        callBack={commonHandler}
        stateSetter={setFirmsArr}
        firms={firms}
      />
    ),
    [firms, firmsArr, commonHandler, setFirmsArr]
  );

  const renderRolesSection = useMemo(
    () => (
      <RolesSection
        values={rolesArr}
        callBack={commonHandler}
        stateSetter={setRolesArr}
      />
    ),
    [rolesArr, commonHandler, setRolesArr]
  );

  return (
    <Box
      sx={{
        width: 400,
        height: "100vh",
        padding: (theme) => theme.spacing(1.5),
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        paddingTop: "60px",
      }}
      role="presentation">
      <HeaderContainer>
        <Header>Filtre</Header>
        <IconButton onClick={toggleDrawer}>
          <CloseRoundedIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </HeaderContainer>
      <SubContainer>
        {renderStatusSection}
        <Divider />
        {renderRolesSection}
        <Divider />
        {isAdmin && renderFirmsSection}
        <Divider />
        <div style={{ flex: 1 }} />
        <ButtonsArea
          clearFilters={clearFilters}
          handleFiltersSubmit={handleFiltersSubmit}
        />
      </SubContainer>
    </Box>
  );
};

DrawerContent.propTypes = {
  toggleDrawer: PropTypes.func,
  statusArr: PropTypes.array,
  setStatusArr: PropTypes.func,
  commonHandler: PropTypes.func,
  clearFilters: PropTypes.func,
  handleFiltersSubmit: PropTypes.func,
  firms: PropTypes.array,
  firmsArr: PropTypes.array,
  setFirmsArr: PropTypes.func,
  isAdmin: PropTypes.bool,
  rolesArr: PropTypes.array,
  setRolesArr: PropTypes.func,
};

export default DrawerContent;
