import {
  customerConstants,
  customerReducerInitialState,
} from "../../Utils/Constants";
import _ from "lodash";

const initialState = {
  filters: {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "", //rating, fiyat(düşükten yükseğe, yüksekten düşüğe)
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  },
  tabIndex: 1,
  isAdmin: false,
  currentAddress: null,
  currentCompany: null,
  selectedUser: null,
};
const customer = (state = initialState, action) => {
  switch (action.type) {
    case customerConstants.ADD_FILTER:
      let query = action.query;
      let newFilter = {};
      let matches = _.find(state.filters[query.prop], ["id", +action.query.id]);
      let newObj = {
        id: query.id,
        name: query.name,
        filter: query.filter,
      };
      if (matches) {
        newFilter = {
          ...state.filters,
          [query.prop]: state.filters[query.prop].filter(
            (item) => !_.isEqual(item, newObj)
          ),
        };
      } else {
        newFilter = {
          ...state.filters,
          [query.prop]: [...state.filters[query.prop], newObj],
        };
      }
      return {
        ...state,
        filters: newFilter,
      };
    case customerConstants.SET_SEARCH_TEXT:
      return {
        ...state,
        filters: { ...state.filters, searchText: action.text },
      };
    case customerConstants.CLEAR_SEARCH_TEXT:
      return {
        ...state,
        filters: { ...state.filters, searchText: "" },
      };
    case customerConstants.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      };
    case customerConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.index,
      };
    case customerConstants.SET_FILTER_TAB_INDEX:
      return {
        ...state,
        filters: {
          ...state.filters,
          tabValue: action.value,
        },
      };
    case customerConstants.UPDATE_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case customerConstants.SET_ADMIN:
      return {
        ...state,
        isAdmin: action.bool,
      };
    case customerConstants.SELECTED_USER:
      return {
        ...state,
        selectedUser: action.user,
      };
    case customerConstants.SET_USER_CURRENT:
      return {
        ...state,
        currentAddress: action.query.address,
        currentCompany: action.query.company,
      };
    case customerConstants.ADD_MOBILE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.prop]: action.payload.query,
        },
      };
    case customerConstants.UPDATE_CUSTOMER_ADDRESS_BY_TOGGLE:
      return {
        ...state,
        currentAddress: action.address,
      };
    case customerConstants.RESTORE_DEFAULT_ADDRESS:
      return {
        ...state,
        currentAddress: state.selectedUser.address
          ? state.selectedUser.address
          : state.currentAddress,
      };
    case customerConstants.CLEAR_CUSTOMER_REDUCER:
      return customerReducerInitialState;
    default:
      return state;
  }
};

export { customer };
