import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeaderClose from "../../../Ui/Dialog/Tools/MobileDialogHeaderClose";
import TextArea from "../../../Ui/Fields/TextArea";
import RatingField from "../FeaturesAndRatings/Ratings/RatingField";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const RatingDialog = ({ handleClose, open }) => {
  const [values, setValues] = useState({
    ratingText: "",
  });
  const [rating, setRating] = useState(0);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open) {
        setValues({ ratingText: "" });
        setRating(0);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (prop) => (e) =>
    setValues({ ...values, [prop]: e.target.value });
  const clearFields = () => {
    setValues({ ratingText: "" });
    setRating(0);
  };

  const handleSubmit = () => {
    const ratingQuery = {
      text: values.ratingText,
      rating,
    };
    console.log(ratingQuery);
    handleClose();
    clearFields();
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeaderClose
        text="Ürünü Değerlendir"
        callBack={handleClose}
      />
      <TextArea
        label="Yorum Yaz"
        handleChange={handleChange}
        values={values}
        prop="ratingText"
        multiline
        rows={4}
      />
      <RatingField rating={rating} setRating={setRating} mobile />
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}>
        Tamam
      </MobileDialogButton>
    </StyledDialog>
  );
};

RatingDialog.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default RatingDialog;
