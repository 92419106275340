import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

const StyledButton = styled(Button)(({ theme }) => ({
  width: 158,
  borderRadius: 6,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  textTransform: "none",
  fontSize: 12,
  paddingTop: 1.5,
  paddingBottom: 1.5,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&[features-null='true']": {
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

const FeaturesButton = ({ isFeatures, extended, onClickHandler }) => {
  return (
    <StyledButton
      features-null={isFeatures ? "false" : "true"}
      endIcon={
        extended ? (
          <ArrowDropUp style={{ marginBottom: 3 }} />
        ) : (
          <ArrowDropDown style={{ marginBottom: 3 }} />
        )
      }
      onClick={onClickHandler}>
      {isFeatures ? "Özel İşlem Var" : "Özel işlem Uygula"}
    </StyledButton>
  );
};

FeaturesButton.propTypes = {
  isFeatures: PropTypes.object,
  extended: PropTypes.bool,
  onClickHandler: PropTypes.func,
};

export default FeaturesButton;
