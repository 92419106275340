import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import noNotifications from "../../../Images/TopNavbar/NoNotifications.svg";

import { notificationService } from "../../../Services";
import { generalConstants, notificationsQuery } from "../../../Utils/Constants";

import { notificationActions } from "../../../Redux/Actions";

const PREFIX = "Notifications";

const classes = {
  root: `${PREFIX}-root`,
  illustrationContainer: `${PREFIX}-illustrationContainer`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 320,
    minHeight: 320,
    overflowY: "scroll !important",
    paddingBottom: theme.spacing(12),
  },

  [`& .${classes.illustrationContainer}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.text}`]: {
    width: "fit-content",
    fontSize: 14,
    fontWeight: 300,
    color: "#696969",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  "&:hover": {
    ".title": {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    ".content": {
      color: theme.palette.primary.main,
    },
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(),
  position: "absolute",
  bottom: 0,
  backgroundColor: "#fff",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 30,
  backgroundColor: theme.palette.secondary.main,
  color: "#fff",
  fontSize: 14,
  fontWeight: 400,
  textTransform: "none",
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isRead",
})(({ theme, isRead }) => ({
  fontsize: 14,
  fontWeight: 600,
  lineHeight: "20px",
  color: isRead ? theme.palette.secondary.main : "black",
  marginBottom: theme.spacing(),
  opacity: isRead ? 0.7 : 1,
}));

const Content = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isRead",
})(({ theme, isRead }) => ({
  fontsize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: isRead ? theme.palette.secondary.main : "black",
  opacity: isRead ? 0.7 : 1,
}));

const Notifications = ({ index }) => {
  const dispatch = useDispatch();

  const getNotificationsData = (query) =>
    dispatch(notificationActions.getNotifications(query));

  const notificationsData = useSelector(
    ({ notifications }) => notifications?.data
  );

  useEffect(() => {
    let active = true;
    if (active) {
      if (index === 1) {
        getNotificationsData(notificationsQuery);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleNotificationStatus = async (id, isRead) => {
    // if (isRead === 1) return;
    const response = await notificationService.readNotification(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      getNotificationsData(notificationsQuery);
    } else {
      console.log(response);
    }
  };

  const handleNotificationStatusAll = async () => {
    const response = await notificationService.readAllNotifications();
    if (response.data.status === generalConstants.STATUS_TRUE) {
      getNotificationsData(notificationsQuery);
    } else {
      console.log(response);
    }
  };

  return (
    <Root className={classes.root}>
      {notificationsData && notificationsData?.length > 0 ? (
        notificationsData.map((notification, i) => (
          <StyledBox
            key={i}
            onClick={() =>
              handleNotificationStatus(notification.id, notification.is_read)
            }>
            <Title className="title" isRead={notification.is_read === 1}>
              {notification.title}
            </Title>
            <Content className="content" isRead={notification.is_read === 1}>
              {notification.content}
            </Content>
          </StyledBox>
        ))
      ) : (
        <div className={classes.illustrationContainer}>
          {notificationsData && (
            <>
              <img
                src={noNotifications}
                width="110.82px"
                height="98.84px"
                alt="No Notifications"
              />
              <Typography>Bildirim kutunuz boş.</Typography>
            </>
          )}
        </div>
      )}
      {notificationsData?.length > 0 && (
        <ButtonContainer>
          <SubmitButton onClick={handleNotificationStatusAll}>
            Tümünü Okundu Yap
          </SubmitButton>
        </ButtonContainer>
      )}
    </Root>
  );
};

Notifications.propTypes = {
  index: PropTypes.number,
};

export default Notifications;
