import React from "react";

import AuthContainer from "../AuthPages/AuthContainer";

import ServerError_ from "../../Images/ErrorBoundary/ServerError.svg";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ServerError = () => {
  return (
    <AuthContainer component="error-boundary">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src={ServerError_} alt="" />
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 20,
            lineHeight: "28px",
            mt: (theme) => theme.spacing(2),
          }}>
          Sunucu iletişimi kesildi,
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 20,
            lineHeight: "28px",
          }}>
          bu sorunu çözmeye çalışıyoruz.
        </Typography>
      </Box>
    </AuthContainer>
  );
};

export default ServerError;
