import { dateFormatter } from '../Orders/DateFormatter'

const queryMaker = (data, prop) => {
  let query = { id: +data.id, name: data.name, filter: data.value, prop }
  return query
}

const queryMakerMob = (data, prop) => {
  let query = {
    id: +data.id,
    name: getLabel(data, prop),
    filter: data.value,
    prop,
  }
  return query
}

const getLabel = (item, value) => {
  let obj = {
    user_id: item.full_name,
    shipping_address: item.name,
    order_key: item.status,
    order_status: item.status,
    source: item.status,
    status: item.status,
    roles: item.status,
    company_id: item.name,
  }
  return obj[value]
}

const queryMakerBox = (data, prop) => {
  let query = { id: +data.id, name: data.name, filter: data.alt, prop }
  return query
}

const searchQueryMaker = (filters) => {
  let newObj = {
    brands: filters.brands.map((item) => item.id),
    categories: filters.categories.map((item) => item.id),
    colors: filters.colors.map((item) => item.id),
    rating: filters.rating.map((item) => item.id),
    shapes: filters.shapes.map((item) => item.id),
    sizes: filters.sizes.map((item) => item.id),
    searchText: filters.searchText,
    orderByKey: filters.orderByKey,
    orderByValue: filters.orderByValue,
    tabValue: filters.tabValue,
  }

  return newObj
}

const orderQueryMaker = (query, bool, id, isAdmin, isCompanyAdmin) => {
  let filters = query.filter
  let initialStart = new Date()
  let initialEnd = new Date()
  let orderDates = []
  if (filters.created_at.length > 0) {
    if (
      dateFormatter(initialStart) !== dateFormatter(filters.created_at[0]) ||
      dateFormatter(initialEnd) !== dateFormatter(filters.created_at[1])
    ) {
      orderDates = filters.created_at
    }
  }

  let newObj = {
    order_key: filters.order_key,
    order_status: filters.order_status.map((item) => item.id),
    source: filters.source.map((item) => item.id),
    user_id: filters.user_id.map((item) => item.id),
    shipping_address: filters.shipping_address.map((item) => item.id),
    created_at: orderDates,
  }
  let finalObj = isAdmin || isCompanyAdmin ? { ...newObj, company_id: id } : newObj
  let query_ = bool ? { ...query, pageNumber: 1 } : query
  let finalQuery = { ...query_, filter: finalObj }

  return finalQuery
}

const userQueryMaker = (query) => {
  let filters = query.filter

  let newObj = {
    status: filters.status.length > 0 ? +filters?.status[0]?.id : null,
    roles: filters.roles.map((item) => item.id),
    company_id: filters.company_id.map((item) => item.id),
  }

  let finalQuery = { ...query, filter: { ...filters, ...newObj } }

  return finalQuery
}

export {
  queryMaker,
  queryMakerBox,
  searchQueryMaker,
  orderQueryMaker,
  queryMakerMob,
  userQueryMaker
}
