import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(),
  display: "flex",
  flexDirection: "column",
}));

const Header = styled(Typography)(({theme}) => ({
  width: "100%",
  height: "auto",
  textAlign: "left",
  fontSize: 20,
  fontWeight: 400,
  lineHeight: "28px",
  color:theme.palette.darkGray.main,
}));

const Brands = styled("div")(() => ({
  display: "flex",
}));

const Brandtext = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "first",
})(({ first, theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: first ? "#10C66C" : "#3699FF",
  marginRight: first && theme.spacing(),
}));

const ProductHeaderMob = ({ data }) => {
  return (
    <Root>
      <Header>{data?.name}</Header>
      <Brands>
        <Brandtext first>{data?.brand.name}</Brandtext>
        <Brandtext>{data?.sub_category.name}</Brandtext>
      </Brands>
    </Root>
  );
};

ProductHeaderMob.propTypes = {
  data: PropTypes.object,
};

export default ProductHeaderMob;
