import { bottomNavbarConstants } from "../../Utils/Constants";

const setMenu = (menu) => {
  return {
    type: bottomNavbarConstants.SET_MENU,
    menu,
  };
};

const clearbottomNavbarReducer = () => {
  return {
    type: bottomNavbarConstants.CLEAR_BOTTOM_NAVBAR_REDUCER,
  };
};

export const bottomNavbarActions = {
  setMenu,
  clearbottomNavbarReducer,
};
