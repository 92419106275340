import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import PageContainer from "../Common/PageContainer";
import TabButtons from "./AddressesWeb/TabButtons";
import CustomerAddresses from "./AddressesWeb/CustomerAddresses";
import CompanyAddresses from "./AddressesWeb/CompanyAddresses";
import AddressesSearchArea from "./AddressesWeb/AddressesSearchArea";

import Loading from "../../Utils/Loading";

import { addressService } from "../../Services";
import { generalConstants, addressQuery } from "../../Utils/Constants";
import { addressActions } from "../../Redux/Actions";

const StyledContainer = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: 600,
  marginBottom: theme.spacing(4),
}));

const AddressesWeb = () => {
  const dispatch = useDispatch();

  const addresses = useSelector(({ addresses }) => addresses);
  const customer = useSelector(({ customer }) => customer);

  const getAddresses = (query) => dispatch(addressActions.getAddresses(query));

  const [tabIndex, setIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userAddresses, setUserAddresses] = useState(null);
  const [firmAddresses, setFirmAddresses] = useState(null);
  const [customerAddresses, setCustomerAddresses] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [all, setAll] = useState(true);
  const [defaultAddr, setDefaultAddress] = useState(null);

  const query = {
    pageNumber: 1,
    pageSize: 100,
    sortField: "id",
    sortOrder: "desc",
    filter: {},
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer?.isAdmin) {
        let id = customer?.currentCompany?.id;
        let adminQuery = {
          ...query,
          filter: { ...query.filter, company_id: id },
        };
        getCompanyAddresses(adminQuery);
      } else {
        addresses.addresses.length > 0
          ? setUserAddresses(addresses.addresses)
          : setUserAddresses(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (userAddresses && userAddresses.length > 0) {
        let companyAddr = userAddresses.filter((addr) => addr.type === 0);
        let customerAddr = userAddresses.filter((addr) => addr.type === 1);
        let defaultAddr = companyAddr.filter((item) => item.is_default === 1);
        defaultAddr.length > 0
          ? setDefaultAddress(defaultAddr[0])
          : setDefaultAddress(null);
        setFirmAddresses(companyAddr);
        setCustomerAddresses(customerAddr);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddresses]);

  const getCompanyAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setUserAddresses(response.data.addresses);
      setLoading(false);
    } else {
      setUserAddresses(null);
      setLoading(false);
    }
  };

  const handleChange = (e) => setSearchText(e.target.value);
  const clearSearchtext = () => setSearchText("");

  const handleSwitch = async (id) => {
    const response = await addressService.deactivateAddress(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      if (customer?.isAdmin) {
        let id = customer?.currentCompany?.id;
        let adminQuery = {
          ...query,
          filter: { ...query.filter, company_id: id },
        };
        getCompanyAddresses(adminQuery);
      } else {
        getAddresses(addressQuery);
      }
    }
  };

  const handleSwitchMain = () => setAll((prev) => !prev);

  let loading_ = loading || addresses?.loading;

  return (
    <PageContainer>
      <Loading open={loading_} />
      <StyledContainer>
        <TabButtons index={tabIndex} setIndex={setIndex} />
        <AddressesSearchArea
          handleChange={handleChange}
          clearSearchtext={clearSearchtext}
          searchText={searchText}
          customer={tabIndex === 2}
          handleSwitchMain={handleSwitchMain}
          all={all}
        />
        {tabIndex === 1 ? (
          <CompanyAddresses
            data={firmAddresses}
            defaultAddr={defaultAddr}
            loading={loading_}
          />
        ) : (
          <CustomerAddresses
            data={customerAddresses}
            handleSwitch={handleSwitch}
            all={all}
            loading={loading_}
          />
        )}
      </StyledContainer>
    </PageContainer>
  );
};

export default AddressesWeb;
