import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { SourceValues, orderStatus } from '../../../Utils/Constants'
import { dateFormatter } from '../../../Utils/Helpers'

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}))

const FabContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  marginRight: theme.spacing(),
}))

const ButtonContainer = styled(Box)(() => ({
  width: 100,
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Fab = styled(Box)(({ theme }) => ({
  width: 'auto',
  height: 30,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 6,
  padding: theme.spacing(),
  paddingRight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  width: 25,
  height: 25,
  marginLeft: theme.spacing(),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  height: 30,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  lineHeight: '24px',
  fontSize: 16,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}))

const FabText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineStyle: '24px',
  color: theme.palette.darkGray.main,
}))

const OrdersFilterBox = ({
  users,
  userArr,
  statusArr,
  sources,
  date,
  addresses,
  addressArr,
  deleteFab,
  clearFilters,
  dateChanged,
}) => {
  const [data, setData] = useState([])
  useEffect(() => {
    let active = true
    if (active) {
      let statusRaw =
        statusArr.length > 0 ? getRawData(statusArr, orderStatus, 'status') : []
      let sourcesRaw =
        sources.length > 0 ? getRawData(sources, SourceValues, 'sources') : []
      let addressesRaw =
        addressArr.length > 0
          ? getRawData(addressArr, addresses, 'addresses')
          : []
      let usersRaw =
        userArr.length > 0 ? getRawData(userArr, users, 'users') : []

      let startDate = dateFormatter(date.startDate, true)
      let endDate = dateFormatter(date.endDate, true)
      let dateArr = dateChanged
        ? [{ name: `(${startDate}) - (${endDate})`, id: 1, source: 'date' }]
        : []

      let wholeData = [
        ...statusRaw,
        ...sourcesRaw,
        ...addressesRaw,
        ...usersRaw,
        ...dateArr,
      ]
      setData(wholeData)
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, userArr, statusArr, sources, date, addresses, addressArr])

  const getRawData = (selection, root, name) => {
    let arr = []
    for (let i = 0; i < selection.length; i++) {
      let newArr = root.filter((status) => status.id === selection[i])
      if (name === 'status' || name === 'sources') {
        arr.push({ name: newArr[0].status, id: newArr[0].id, source: name })
      } else if (name === 'addresses') {
        arr.push({ name: newArr[0].name, id: newArr[0].id, source: name })
      } else if (name === 'users') {
        arr.push({ name: newArr[0].full_name, id: newArr[0].id, source: name })
      }
    }
    return arr
  }

  return (
    <Root>
      <FabContainer>
        <Grid container spacing={1}>
          {data.map((item, index) => (
            <Grid item key={index}>
              <Fab>
                <FabText>{item.name}</FabText>
                <CloseButton onClick={() => deleteFab(item)}>
                  <CloseRoundedIcon />
                </CloseButton>
              </Fab>
            </Grid>
          ))}
        </Grid>
      </FabContainer>
      {data.length > 0 && (
        <ButtonContainer>
          <StyledButton onClick={clearFilters}>Temizle</StyledButton>
        </ButtonContainer>
      )}
    </Root>
  )
}

OrdersFilterBox.propTypes = {
  users: PropTypes.array,
  userArr: PropTypes.array,
  statusArr: PropTypes.array,
  sources: PropTypes.array,
  date: PropTypes.object,
  addresses: PropTypes.array,
  addressArr: PropTypes.array,
  deleteFab: PropTypes.func,
  clearFilters: PropTypes.func,
  dateChanged: PropTypes.bool,
}

export default OrdersFilterBox
