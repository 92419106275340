import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import ProfileDetails from "./NewContent/ProfileDetails";
import SigninDetails from "./NewContent/SigninDetails";
import EmailPreferences from "./NewContent/EmailPreferences";
import NotificationPreferences from "./NewContent/NotificationPreferences";
// import SuspendAccount from "./NewContent/SuspendAccount";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const NewContent = () => {
  const authInfo = useSelector(({ auth }) => auth.customerInfo);

  return (
    <Root>
      <ProfileDetails person={authInfo} />
      <SigninDetails person={authInfo} />
      <EmailPreferences />
      <NotificationPreferences />
      {/* <SuspendAccount /> */}
    </Root>
  );
};

export default NewContent;
