import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import VariantValues from "../../../Ui/VariantValues/VariantValues";
import ProductHeader from "../../ProductPage/Details/ProductDetails/ProductHeader";
import { handleDataArray } from "../../../Utils/Helpers";

const PREFIX = "MiddlePart";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    minWidth: 410,
    height: "100%",
    paddingTop: 22,
    display: "flex",
    flexDirection: "column",
    paddingRight: 100,
  },
}));

const MiddlePart = ({
  data,
  values,
  handleChange,
  selections,
  activeArray1,
  activeArray2,
}) => {
  const renderProductHeader = useMemo(() => {
    return <ProductHeader data={data} component="fast-order" />;
  }, [data]);

  return (
    <Root className={classes.root}>
      {renderProductHeader}
      {data?.has_variants && (
        <>
          <VariantValues
            data={values.color}
            param="color"
            handleChange={handleChange}
            header="Renkler"
            selectedValue={selections?.color}
            activeArray={[]}
            selections={selections}
            component="fast-order"
          />
          <VariantValues
            data={values?.size}
            param="size"
            handleChange={handleChange}
            header="Ebatlar"
            selectedValue={selections?.size}
            activeArray={handleDataArray(activeArray1, "Ebat")}
            selections={selections}
            component="fast-order"
          />
          <VariantValues
            data={values.shape}
            param="shape"
            handleChange={handleChange}
            header="Şekil"
            selectedValue={selections.shape}
            activeArray={
              activeArray2.length > 0
                ? handleDataArray(activeArray2, "Şekil")
                : handleDataArray(activeArray1, "Şekil")
            }
            selections={selections}
            component="fast-order"
          />
        </>
      )}
    </Root>
  );
};

MiddlePart.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  selections: PropTypes.object,
  activeArray1: PropTypes.array,
  activeArray2: PropTypes.array,
};

export default MiddlePart;
