import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useInterval } from "../../Utils/Helpers/UseInterval";
import { status } from "../../Utils/Constants";

const CountDown = ({ secondsRemaining, setSecondsRemaining }) => {
  const [statusState, setStatusState] = useState(status.STOPPED);

  useEffect(() => {
    handleStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStart = () => {
    setStatusState(status.STARTED);
  };

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatusState(status.STOPPED);
      }
    },
    statusState === status.STARTED ? 1000 : null
    // passing null stops the interval
  );
  return (
    <div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 700,
          color: "#6E3564",
          userSelect: "none",
        }}>
        {secondsRemaining}
      </div>
    </div>
  );
};

CountDown.propTypes = {
  secondsRemaining: PropTypes.number,
  setSecondsRemaining: PropTypes.func,
};

export default CountDown;
