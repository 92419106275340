import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import { getFirstLetters } from "../../Utils/Helpers";

const StyledButton = styled(Button)(({ theme }) => ({
  width: 50,
  maxWidth: 50,
  height: 50,
  marginLeft: 15,
  borderRadius: "100%",
  textTransform: "capitalize",
  padding: "5px",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  "&.MuiButton-root": {
    minWidth: 50,
  },
  "&:hover, &:focus, &:active": {
    backgroundColor: theme.palette.primary.main,
    transform: "scale(1.05)",
    transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
  },
}));

const ProfileMenu = ({ showProfilePopover, userInfo }) => {
  const [letters, setLetters] = useState(null);

  useEffect(() => {
    if (userInfo) {
      setLetters(getFirstLetters(userInfo?.first_name, userInfo?.last_name));
    }
  }, [userInfo]);

  return (
    <StyledButton variant="outlined" onClick={showProfilePopover}>
      {letters && letters}
    </StyledButton>
  );
};

ProfileMenu.propTypes = {
  showProfilePopover: PropTypes.func,
  userInfo: PropTypes.object,
};

export default ProfileMenu;
