const handleAttrList = (data) => {
  let attributeArr = [];
  let nameArr = ["Renk", "Şekil", "Ebat"];
  for (let item of data) {
    if (nameArr.includes(item.name)) {
      attributeArr.unshift(item.id);
    }
  }
  return attributeArr;
};

export { handleAttrList };
