import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getCampaigns = async (query) => {
  return await axios
    .post(`${API_URL}/campaign/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getCampaignProducts = async (id) => {
  return await axios
    .get(`${API_URL}/campaign/products/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

export const campaignService = {
  getCampaigns,
  getCampaignProducts,
};
