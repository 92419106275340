import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import NewSectionHeader from "./NewSectionHeader";
import CardSkeleton from "../../../Ui/ProductCard/CardSkeleton";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 1212,
  height: "auto",
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(2),
}));

const ProductsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const NewSectionSkeleton = ({ header, handleClick, value, noButton }) => {
  let arr = [1, 2, 3, 4, 5];
  return (
    <Root>
      <NewSectionHeader
        header={header}
        handleClick={handleClick}
        value={value}
        noButton={noButton}
      />
      <ProductsContainer>
        {arr?.map((item, index) => (
          <CardSkeleton key={index} />
        ))}
      </ProductsContainer>
    </Root>
  );
};

NewSectionSkeleton.propTypes = {
  header: PropTypes.string,
  noButton: PropTypes.bool,
  handleClick: PropTypes.func,
  value: PropTypes.number,
};

export default NewSectionSkeleton;
