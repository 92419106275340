import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 37,
  display: "flex",
  borderBottom: `1px solid ${theme.palette.darkGray.mid}`,
  marginTop: theme.spacing(),
}));

const StyledButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  fontSize: 24,
  fontWeight: 400,
  color: selected ? theme.palette.primary.main : "#B1AEAE",
  width: 200,
  height: "100%",
  display: "flex",
  //   justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  paddingBottom: theme.spacing(),
  borderBottom: selected && "1px solid #6E3564",
  userSelect: "none",
}));

const TabButtons = ({ index, setIndex }) => {
  const paymentMethods = useSelector(
    ({ customer }) => customer.currentCompany.payment_methods
  );
  return (
    <Root>
      {paymentMethods?.length > 0 &&
        paymentMethods.map((item, i) => (
          <StyledButton
            key={i}
            onClick={() => setIndex(item.id)}
            selected={index === item.id}>
            {item.name}
          </StyledButton>
        ))}
    </Root>
  );
};

TabButtons.propTypes = {
  index: PropTypes.number,
  setIndex: PropTypes.func,
};

export default TabButtons;
