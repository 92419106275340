import React from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

const PREFIX = "ReturnHome";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 150,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const ReturnHome = () => {
  let history = useHistory();

  const handleClick = () => history.push("/home");

  return (
    <Root className={classes.root} onClick={handleClick}>
      Giriş Sayfasına Dön
    </Root>
  );
};

export default ReturnHome;
