export const authConstants = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",

  UPDATE_USER_NAME: "UPDATE_USER_NAME",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",

  UPDATE_ADDRESS_BY_TOGGLE: "UPDATE_ADDRESS_BY_TOGGLE",
};
