import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";

import { tabBarMenu } from "./NabvarItems";

const innerWidth = window.innerWidth;

const StyledNavigationAction = styled(BottomNavigationAction, {
  shouldForwardProp: (prop) => prop !== "web",
})(({ theme, web }) => ({
  padding: "0px 12px 0px",
  minWidth: "50px",
  maxWidth: web ? "100%" : "168px",
  "& .css-689vr9-MuiBottomNavigationAction-label": {
    fontSize: "0.8rem",
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
  },
  "&.MuiBottomNavigationAction-root.Mui-selected": {
    paddingTop: 0,
    transition: theme.transitions.create(["background-color"]),
    backgroundColor: "rgba(127, 91, 120, 0.16)",
    borderRadius: 6,
  },
  "& .MuiBottomNavigationAction-label.Mui-selected": {
    fontSize: "0.8rem",
    fontWeight: 500,
  },
}));

export default function LabelBottomNavigation({ web }) {
  const history = useHistory();

  const mainMenu = useSelector(
    ({ bottomNavbarValue }) => bottomNavbarValue.menuValue
  );

  const tabClickHandler = (route, value) => {
    if (value === mainMenu) return;
    history.push(`/${route}`);
  };

  return (
    <BottomNavigation
      showLabels
      sx={{
        width: innerWidth,
        // borderTop: "1px solid #434141",
        paddingTop: 0.5,
        height: 60,
        paddingBottom: 0.5,
      }}
      value={mainMenu}
      // onChange={handleChange}
    >
      {tabBarMenu.map((item, i) => (
        <StyledNavigationAction
          key={i}
          value={item.value}
          label={item.value}
          path={item.path}
          web={web}
          icon={
            <div>
              {mainMenu === item.value ? (
                <img
                  src={item.iconActive}
                  alt={item.path}
                  height={item.value === "Menü" ? 16 : 20}
                />
              ) : (
                <img
                  src={item.icon}
                  alt={item.path}
                  height={item.value === "Menü" ? 16 : 20}
                />
              )}
            </div>
          }
          onClick={() => tabClickHandler(item.path, item.value)}
        />
      ))}
    </BottomNavigation>
  );
}
