import React from "react";

import { styled } from "@mui/material/styles";
import { useRouteMatch } from "react-router-dom";

import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CssBaseline from "@mui/material/CssBaseline";

import ScrollTop from "../../Ui/ScrollToTop/ScrollTop";

import BottomNavbar from "../../Ui/BottomNavbar/BottomNavbar";

const PREFIX = "PageContainer";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  subContainer: `${PREFIX}-subContainer`,
  footer: `${PREFIX}-footer`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: "100%",
    position: "relative",
    padding: 0,
    backgroundColor: "#fff",
    overflow: "hidden",
    // height: "100vh",
  },

  [`& .${classes.container}`]: {
    padding: "10px 0px 60px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    overflow: "scroll",
    position: "relative",
    "&[no-scroll='true']": {
      overflow: "hidden",
    },
    "&[no-padding='true']": {
      padding: 0,
    },
  },

  [`& .${classes.footer}`]: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    overflow: "hidden",
    zIndex: 2,
  },
}));

const PageContainerMob = (props) => {
  const match = useRouteMatch();
  if (
    match?.path?.indexOf("/product/" || match?.path?.indexOf("/cart-page/")) >
    -1
  ) {
    window.scrollTo(0, 100);
  }
  return (
    <Root className={classes.root}>
      <CssBaseline />
      <div id="back-to-top-anchor"></div>
      <Container
        maxWidth="lg"
        className={classes.container}
        no-padding={props.noPadding ? "true" : "false"}
        no-scroll={props.noScroll ? "true" : "false"}>
        {props.children}
      </Container>
      {!props.noFooter && (
        <div className={classes.footer}>
          <BottomNavbar />
        </div>
      )}
      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          style={{ backgroundColor: "#6E3564" }}>
          <KeyboardArrowUpIcon style={{ color: "white" }} />
        </Fab>
      </ScrollTop>
    </Root>
  );
};

export default PageContainerMob;
