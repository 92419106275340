import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import ArrowLightGray from "../../../Images/ProductPage/ArrowLightGray.svg";

import FeaturesAndDetails from "../MobileDialogs/FeaturesAndDetails";
import ProductPrices from "../MobileDialogs/ProductPrices";
import ProductStocks from "../MobileDialogs/ProductStocks";
import Assessments from "../MobileDialogs/Assessments";
import FeedBack from "../MobileDialogs/FeedBack";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  marginTop: theme.spacing(),
  padding: theme.spacing(),
  paddingBottom: 0,
}));

const Main = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderTop: `1px solid ${theme.palette.darkGray.light}`,
  borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  paddingTop: theme.spacing(),
}));

const Row = styled("div")(({ theme }) => ({
  width: "100%",
  height: 26,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: theme.spacing(),
  position: "relative",
  marginBottom: theme.spacing(),
}));

const RowText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
  color: theme.palette.darkGray.main,
  textAlign: "left",
}));

const StyledImage = styled("img")(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(),
  width: 7,
  height: 12,
}));

const DialogButtons = ({ data, disabled }) => {
  const [featuresAndDetails, setFeaturesAndDetails] = useState(false);
  const [prices, setPrices] = useState(false);
  const [stocks, setStocks] = useState(false);
  const [assessments, setAssessments] = useState(false);
  const [feedBack, setFeedBack] = useState(false);

  const toggleFeaturesAndDetails = () =>
    setFeaturesAndDetails(!featuresAndDetails);

  const togglePrices = () => setPrices(!prices);
  const toggleStocks = () => setStocks(!stocks);
  const toggleAssessments = () => setAssessments(!assessments);
  const toggleFeedBack = () => setFeedBack(!feedBack);

  const buttonsArr = [
    {
      name: "Açıklama ve Özellikler",
      func: toggleFeaturesAndDetails,
    },
    {
      name: "Diğer Fiyatlar",
      func: togglePrices,
    },
    {
      name: "Depo Miktarları",
      func: toggleStocks,
    },
    {
      name: "Değerlendirme",
      func: toggleAssessments,
    },
    {
      name: "Hata Bildir veya Tavsiyede Bulun",
      func: toggleFeedBack,
    },
  ];

  const handleFunc = (func) => {
    if (disabled) return;
    func();
  };

  return (
    <Root>
      <Main>
        {buttonsArr.map((item, index) => (
          <Row key={index} onClick={() => handleFunc(item.func)}>
            <RowText>{item.name}</RowText>
            <StyledImage src={ArrowLightGray} alt="" />
          </Row>
        ))}
      </Main>
      <FeaturesAndDetails
        open={featuresAndDetails}
        handleClose={toggleFeaturesAndDetails}
        data={data}
      />
      <ProductPrices
        open={prices}
        handleClose={togglePrices}
        price={data?.price}
      />
      <ProductStocks
        open={stocks}
        handleClose={toggleStocks}
        stock={data?.stock}
      />
      <Assessments
        open={assessments}
        handleClose={toggleAssessments}
        data={data}
      />
      <FeedBack open={feedBack} handleClose={toggleFeedBack} />
    </Root>
  );
};

DialogButtons.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DialogButtons;
