import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const innerWidth = window.innerWidth;

const ForgetPasswordWeb = styled("div")(({ theme }) => ({
  width: 100,
  height: 18,
  fontSize: "12px !important",
  fontWeight: 700,
  textAlign: "center",
  marginTop: 33.46,
  cursor: "pointer",
  color: theme.palette.primary.main,
  userSelect: "none",
  "&[component='siginin-email']": {
    marginTop: 28.12,
  },
}));

const TextContainer = styled("div")(() => ({
  width: innerWidth,
  height: "auto",
  marginTop: 18,
  display: "flex",
  justifyContent: "center",
}));

const ForgetPassword = styled(Typography)(() => ({
  width: "fit-content",
  height: 18,
  fontSize: 14,
  fontWeight: 400,
  color: "#8E8E8E",
  userSelect: "none",
}));

const Renew = styled(Typography)(({ theme }) => ({
  width: "fit-content",
  height: 18,
  fontSize: 14,
  fontWeight: 700,
  cursor: "pointer",
  color: theme.palette.primary.main,
  userSelect: "none",
  marginLeft: 3,
  // "-webkit-tap-highlight-color": "transparent",
  touchAction: "none",
  WebkitTapHighlightColor: "transparent",
}));

const ForgotPasswordText = ({ component, mobile }) => {
  let history = useHistory();

  const handleRoute = () => {
    history.push("/forgot-password");
  };

  return (
    <>
      {mobile ? (
        <TextContainer>
          <ForgetPassword>Şifrenizi mi unuttunuz?</ForgetPassword>
          <Renew onClick={handleRoute}>Yenile</Renew>
        </TextContainer>
      ) : (
        <ForgetPasswordWeb component={component} onClick={handleRoute}>
          Şifremi Unuttum
        </ForgetPasswordWeb>
      )}
    </>
  );
};

ForgotPasswordText.propTypes = {
  component: PropTypes.string,
  mobile: PropTypes.bool,
};

export default ForgotPasswordText;
