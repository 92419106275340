import { favoriteConstants, generalConstants } from "../../Utils/Constants";
import { favoriteService } from "../../Services";
import { toast } from "react-toastify";

const getFavorites = (query, id) => {
  return (dispatch) => {
    favoriteService.getFavorites(query, id).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: favoriteConstants.GET_FAVORITES,
            payload: res.data.favorites,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const getFavoritesAdmin = (query) => {
  return (dispatch) => {
    favoriteService.getFavoritesAdmin(query).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch({
            type: favoriteConstants.GET_FAVORITES,
            payload: res?.data?.favorites,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const toggleFavorite = (query, bool, product) => {
  console.log(query, bool, product)
  return (dispatch) => {
    dispatch(request());
    favoriteService.toggleFavorite(query, bool).then(
      (res) => {
        if (res?.data?.status === generalConstants.STATUS_TRUE) {
          dispatch(success(product));
        } else {
          toast.error(res?.data?.message);
          dispatch(failure());
        }
      },
      (err) => {
        console.log(
          "🚀 ~ file: FavoriteActions.js ~ line 35 ~ return ~ err",
          err
        );
        toast.error(err?.response?.message);
        dispatch(failure());
      }
    );
  };
  function request() {
    return { type: favoriteConstants.TOGGLE_FAVORITE_REQUEST };
  }
  function success(product) {
    return { type: favoriteConstants.TOGGLE_FAVORITE_SUCCESS, product };
  }
  function failure() {
    return { type: favoriteConstants.TOGGLE_FAVORITE_SUCCESS };
  }
};

const clearFavorites = () => {
  return (dispatch) => {
    dispatch({ type: favoriteConstants.CLEAR_FAVORITES });
  };
};

export const favoriteActions = {
  getFavorites,
  toggleFavorite,
  clearFavorites,
  getFavoritesAdmin,
};
