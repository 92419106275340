import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

const HeaderPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 55,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(),
  borderBottom: "1px solid #B1AEAE",
  paddingBottom: theme.spacing(),
  backgroundColor: "white",
  zIndex: 3000,
}));

const ArrowAndHeader = styled("div")(() => ({
  width: 200,
  height: "100%",
  display: "flex",
  alignItems: "center",
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "24px",
  color: theme.palette.darkGray.main,
}));

const Header = ({ handleClose, headerText, onDelete, prop }) => {
  const closeDialog = () => {
    prop ? handleClose(prop) : handleClose();
  };
  return (
    <HeaderPart>
      <ArrowAndHeader>
        <ArrowBackIcon
          onClick={() => closeDialog()}
          style={{ fill: "#434141", height: 24, width: 24, marginRight: 16 }}
        />
        <HeaderText>{headerText}</HeaderText>
      </ArrowAndHeader>
      <DeleteIcon
        onClick={onDelete}
        style={{ fill: "#434141", height: 24, width: 24 }}
      />
    </HeaderPart>
  );
};

Header.propTypes = {
  handleClose: PropTypes.func,
  headerText: PropTypes.string,
  onDelete: PropTypes.func,
  prop: PropTypes.string,
};

export default Header;
