import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import AutoCompleteArea from "../../../../../Ui/Fields/AutocompleteArea";
import AutoCompleteMultiple from "../../../../../Ui/Fields/AutoCompleteMultiple";

import {
  generalConstants,
  roleNameConverter,
} from "../../../../../Utils/Constants";

import { addressService, userService } from "../../../../../Services";
import { MobileDialogButton } from "../../../../../Utils/Tools";
import Loading from "../../../../../Utils/Loading";

const StyledFirm = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "20px",
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const FirmEditCa = ({
  open,
  onClose,
  userInfo,
  newGetUsers,
  query_,
}) => {
  const customerAddresses = useSelector(({ addresses }) => addresses.addresses);
  const customer = useSelector(({ customer }) => customer);

  const roles = [
    { id: "4", name: "Firma Kullanıcı" },
    { id: "3", name: "Firma Yetkili" },
  ];

  const [values, setValues] = useState({
    firm: { name: userInfo?.company?.name, id: userInfo?.company?.id },
    branch: [],
    roles: {
      name: roleNameConverter[userInfo?.role_names[0]],
      id: userInfo?.role_ids[0],
    },
    defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
  });

  const [error, setError] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [customerAddr, setCustomerAddr] = useState([]);
  const [loading, setLoading] = useState(false);

  let stringifiedBranch = JSON.stringify(values.branch);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && userInfo) {
        const query_ = {
          pageNumber: 1,
          pageSize: 1000,
          sortField: "id",
          sortOrder: "asc",
          filter: {
            user_id: userInfo?.id,
          },
        };
        getUserAddresses(query_);
        setError(false);
        setValues({
          ...values,
          firm: { name: userInfo?.company?.name, id: userInfo?.company?.id },
          roles: {
            name: roleNameConverter[
              userInfo?.role_names?.length > 0 && userInfo?.role_names[0]
            ],
            id: userInfo?.role_ids?.length && userInfo?.role_ids[0],
          },
        });
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, userInfo]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customerAddresses) {
        if (customerAddresses.length > 0) {
          let companyAddresses = customerAddresses.filter(
            (addr) => addr.type === 0
          );
          setAddresses(companyAddresses);
        } else {
          setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
        }
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAddresses]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (values?.branch?.length === 0) {
        setValues({
          ...values,
          defaultAddress: { name: "Lütfen yetkili adres seçiniz", id: 0.5 },
        });
      } else {
        let defId = userInfo?.address?.id;
        let filteredAddr = values.branch.filter((item) => item.id === defId);
        if (filteredAddr.length > 0) {
          setValues({
            ...values,
            defaultAddress: filteredAddr[0],
          });
        } else {
          setValues({
            ...values,
            defaultAddress: values.branch[0],
          });
        }
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedBranch]);

  const getUserAddresses = async (query, id) => {
    setLoading(true);
    const response = await addressService.getAdminUserAndCompanyAddresses(
      query
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.addresses;
      let userAddresses = data.filter((item) => item.type === 0);
      let customerAddrs = data.filter((item) => item.type === 1);
      setCustomerAddr(customerAddrs);
      setValues({
        ...values,
        branch: userAddresses,
      });
      setLoading(false);
    } else {
      setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLoading(false);
    }
  };

  const handleCheckUser = () => {
    if (!values.firm || !values.branch || !values.roles) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleCheck = () => {
    if (!values.firm || !values.roles) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isUser = values?.roles?.name === "Firma Kullanıcı";
    let isError = isUser ? handleCheckUser() : handleCheck();
    let initialQuery = {
      pageNumber: 1,
      pageSize: 10,
      sortField: "id",
      sortOrder: "desc",
      filter: {},
    };
    if (!isError) {
      let allAddrresses = customerAddr.concat(values.branch);
      let addrIds = allAddrresses.map((item) => item.id.toString());
      let userQuery =
        values?.roles?.name === "Firma Kullanıcı"
          ? {
              roles: [`${values.roles.id}`],
              addresses: isUser ? addrIds : [],
              company_id: +values.firm.id,
            }
          : {
              roles: [`${values.roles.id}`],
              company_id: +values.firm.id,
            };
      const response = await userService.editRole(userInfo?.id, userQuery);
      if (response?.data.status === generalConstants.STATUS_TRUE) {
        if (isUser) {
          let query = {
            is_default: true,
            address_id: +values.defaultAddress.id,
          };
          const res = await addressService.toggleDefaultAddress(
            query,
            +userInfo?.id
          );
          if (res?.data?.status === generalConstants.STATUS_TRUE) {
            handleSuccess(initialQuery);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        } else {
          handleSuccess(initialQuery);
        }
      } else {
        toast.error(response?.data?.message);
        setLoading(false);
      }
    }
  };

  const handleSuccess = (query) => {
    toast.success("Firma bilgileri başarılı bir şekilde güncellendi");
    setLoading(false);
    onClose();
    newGetUsers(query_, true);
  };

  // const isActive = status && status === "Aktif";

  return (
    <div>
      <Loading open={loading} />
      <AutoCompleteArea
        data={roles}
        label="Yetki Grubu"
        setValues={setValues}
        prop="roles"
        values={values}
        component="add user"
        error={error}
        // disabled={isActive}
      />
      <StyledFirm>{`Firma: ${customer?.currentCompany?.name}`}</StyledFirm>
      {values?.roles?.name === "Firma Kullanıcı" && (
        <AutoCompleteMultiple
          data={addresses}
          label="Yetkili Adresler"
          setValues={setValues}
          prop="branch"
          values={values}
          error={error}
          // disabled={isActive}
          defVal={values?.branch}
          component="add user"
        />
      )}
      {(values?.roles?.name === "Firma Kullanıcı" ||
        values?.roles?.name === "Firma Yetkili") && (
        <AutoCompleteArea
          data={values?.branch}
          label="Tanımlı Adres"
          setValues={setValues}
          prop="defaultAddress"
          values={values}
          error={error}
          // disabled={isActive}
          component="add user"
        />
      )}
      <MobileDialogButton
        variant="contained"
        disableRipple
        onClick={handleSubmit}>
        Kaydet
      </MobileDialogButton>
    </div>
  );
};

FirmEditCa.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userInfo: PropTypes.object,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
};

export default FirmEditCa;
