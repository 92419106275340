import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "TabButtons";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  selected: `${PREFIX}-selected`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: 320,
    height: 37,
    display: "flex",
  },

  [`& .${classes.button}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.darkGray.mid,
    width: 160,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  [`& .${classes.selected}`]: {
    color: "#6E3564",
    fontWeight: 700,
    borderBottom: "3px solid #6E3564",
  },
}));

const TabButtons = ({ setIndex, index }) => {
  return (
    <Root className={classes.root}>
      <Typography
        onClick={() => setIndex(1)}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === 1 && true,
        })}>
        Bildirimler
      </Typography>
      <Typography
        onClick={() => setIndex(2)}
        className={classnames({
          [classes.button]: true,
          [classes.selected]: index === 2 && true,
        })}>
        Mesajlar
      </Typography>
    </Root>
  );
};

TabButtons.propTypes = {
  setIndex: PropTypes.func,
  index: PropTypes.number,
};

export default TabButtons;
