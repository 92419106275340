import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Transition } from "../../../Utils/Tools";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import MobileFilterRows from "./MobileFilters/MobileFilterRows";
import Header from "./MobileFilters/Header";
import FilterDialog from "./MobileFilters/FilterDialog";

import { customerActions } from "../../../Redux/Actions";

const MobileFilters = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const filters = useSelector(({ customer }) => customer.filters);
  const filterValues = useSelector(({ filterValues }) => filterValues);

  const clearFilters = () => dispatch(customerActions.clearFilters());

  const [openDialog, setOpenDialog] = useState({
    brands: false,
    categories: false,
    sizes: false,
    shapes: false,
    colors: false,
    rating: false,
  });

  const filterSections = [
    {
      sectionName: "Marka",
      sectionValue: "brands",
    },
    {
      sectionName: "Kategori",
      sectionValue: "categories",
    },
    {
      sectionName: "Ebat",
      sectionValue: "sizes",
    },
    {
      sectionName: "Şekil",
      sectionValue: "shapes",
    },
    {
      sectionName: "Renk",
      sectionValue: "colors",
    },
    {
      sectionName: "Değerlendirme Puanı",
      sectionValue: "rating",
    },
  ];

  const openRelatedDialog = (prop) => (e) => {
    setOpenDialog({ ...openDialog, [prop]: true });
  };

  const closeRelatedDialog = (prop) =>
    setOpenDialog({ ...openDialog, [prop]: false });

  const onDelete = () => {
    clearFilters();
    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <Header
        handleClose={handleClose}
        headerText="Filtre"
        onDelete={onDelete}
      />
      {filterSections.map((item, index) => (
        <MobileFilterRows
          key={index}
          name={item.sectionName}
          items={filters[item.sectionValue]}
          value={item.sectionValue}
          openDialog={openRelatedDialog}
        />
      ))}
      {filterSections.map((item, index) => (
        <FilterDialog
          key={index}
          open={openDialog[item.sectionValue]}
          handleClose={closeRelatedDialog}
          headerText={item.sectionName}
          items={filterValues[item.sectionValue]}
          value={item.sectionValue}
          filter={item.sectionName}
        />
      ))}
    </Dialog>
  );
};

MobileFilters.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MobileFilters;
