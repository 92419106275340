import React from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledText = styled(Typography)(({theme}) => ({
  width: "100%",
    paddingLeft: theme.spacing(2),
    height: "auto",
    fontSize: 12,
    fontWeight: 400,
    color: "#B1AEAE",
    userSelect: "none",
}))

const Bottom = () => {
  return (
    <StyledText >
      Aramaya başlamak için en az 3 karakter yazmalısınız
    </StyledText>
  );
};

export default Bottom;
