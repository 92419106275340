import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";

import { MobileDialogButton, Transition } from "../../../Utils/Tools";
import MobileDialogHeaderClose from "../Tools/MobileDialogHeaderClose";
import AutoCompleteArea from "../../Fields/AutocompleteArea";
import TextArea from "../../Fields/TextArea";
import PhoneArea from "../../Fields/PhoneArea";

import { addressService } from "../../../Services";

const innerWidth = window.innerWidth;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: innerWidth,
    height: "auto",
    maxHeight: "calc(100vh-80px)",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const MobileAddAddress = ({
  open,
  closeDialogFromButton,
  values,
  handleChange,
  handleSubmit,
  error,
  setError,
  setValues,
}) => {
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    if (open) {
      getCities();
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (values?.city_id) {
      getCounties(values.city_id.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.city_id]);

  const getCities = () => {
    addressService
      .getCities()
      .then((res) => setCities(res.data.cities))
      .catch((err) => console.log(err));
  };

  const getCounties = (id) => {
    addressService
      .getCounties(id)
      .then((res) => {
        setCounties(res.data.counties);
      })
      .catch((err) => console.log(err));
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      BackdropComponent={StyledBackDrop}>
      <MobileDialogHeaderClose
        text="Firma Değiştir"
        callBack={closeDialogFromButton}
      />
      <TextArea
        label="Adres Başlığı"
        handleChange={handleChange}
        values={values}
        prop="title"
        error={error}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextArea
            label="Ad"
            handleChange={handleChange}
            values={values}
            prop="first_name"
            error={error}
          />
        </Grid>
        <Grid item xs={6}>
          <TextArea
            label="Soyad"
            handleChange={handleChange}
            values={values}
            prop="last_name"
            error={error}
          />
        </Grid>
      </Grid>
      <PhoneArea
        label="Telefon"
        handleChange={handleChange}
        values={values}
        prop="phone1"
        error={error}
      />
      <PhoneArea
        label="Telefon - 2"
        handleChange={handleChange}
        values={values}
        prop="phone2"
        error={error}
      />
      <TextArea
        label="Eposta"
        handleChange={handleChange}
        values={values}
        prop="email"
        error={error}
      />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AutoCompleteArea
            data={cities}
            label="İl"
            setValues={setValues}
            prop="city_id"
            values={values}
            error={error}
            customWidth={innerWidth * 0.9}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteArea
            data={counties}
            label="İlçe"
            setValues={setValues}
            prop="county_id"
            values={values}
            error={error}
            customWidth={innerWidth * 0.9}
          />
        </Grid>
      </Grid>
      <TextArea
        label="Adres"
        handleChange={handleChange}
        values={values}
        prop="address1"
        error={error}
        multiline
        rows={4}
      />
      <MobileDialogButton disableRipple onClick={handleSubmit}>
        Tamam
      </MobileDialogButton>
    </StyledDialog>
  );
};

MobileAddAddress.propTypes = {
  open: PropTypes.bool,
  closeDialogFromButton: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  setValues: PropTypes.func,
};

export default MobileAddAddress;
