import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const FlexColumn = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledImage = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  marginBottom: theme.spacing(),
}));

const Cost = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: "#000000",
  lineHeight: "20px",
  marginBottom: theme.spacing(),
}));

const ImageAndCost = ({ isCompanyUser, product }) => {
  return (
    <FlexColumn>
      <StyledImage src={product?.image} alt="" width="60px" height="80px" />
      {!isCompanyUser && <Cost>{`${product?.total_cost}₺`}</Cost>}
    </FlexColumn>
  );
};

ImageAndCost.propTypes = {
  isCompanyUser: PropTypes.bool,
  product: PropTypes.object,
};

export default ImageAndCost;
