import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { getFirstLetters } from "../../Utils/Helpers";

import userSettingsIcon from "../../Images/DraggableCard/UserSettings.svg";
import addGroupIcon from "../../Images/DraggableCard/AddGroup.svg";
import userChangeIcon from "../../Images/DraggableCard/UserChange.svg";
import walletIcon from "../../Images/DraggableCard/Wallet.svg";
import invoiceIcon from "../../Images/DraggableCard/Invoice.svg";
import refundIcon from "../../Images/DraggableCard/Refund.svg";
import pieChartIcon from "../../Images/DraggableCard/PieChart.svg";
import bIcon from "../../Images/FooterImages/b-icon.svg";
import exitIcon from "../../Images/DraggableCard/ExitGray.svg";
import SendLight from "../../Images/Cart/SendLight.svg";

import PopoverRow from "./ProfilePopover/PopoverRow";

import { authActions } from "../../Redux/Actions";

const PREFIX = "ProfilePopover";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  head: `${PREFIX}-head`,
  letters: `${PREFIX}-letters`,
  lettersText: `${PREFIX}-lettersText`,
  user: `${PREFIX}-user`,
  userName: `${PREFIX}-userName`,
  userFirm: `${PREFIX}-userFirm`,
  bottom: `${PREFIX}-bottom`,
  bottomLeft: `${PREFIX}-bottomLeft`,
  bottomText: `${PREFIX}-bottomText`,
  bottomRight: `${PREFIX}-bottomRight`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
};

const StyledPopover = styled(Popover)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: 15,
  },

  [`& .${classes.paper}`]: {
    width: 320,
    height: 400,
    boxShadow: "0px 2px 12px 0px #0000001A",
    borderRadius: 8,
    backgroundColor: "#fff",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    userSelect: "none",
  },

  [`& .${classes.head}`]: {
    width: "90%",
    height: 48,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "9px 0px 9px 10px",
    borderBottom: `0.5px solid ${theme.palette.darkGray.light}`,
    position: "relative",
  },

  [`& .${classes.letters}`]: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
  },

  [`& .${classes.lettersText}`]: {
    fontSize: 12,
    fontWeight: 700,
    color: "#fff",
    textTransform: "uppercase",
  },

  [`& .${classes.user}`]: {
    width: 191,
    height: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },

  [`& .${classes.userName}`]: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    lineHeight: "16px",
    marginBottom: 2,
  },

  [`& .${classes.userFirm}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: "#B1AEAE",
    lineHeight: "12px",
    marginRight: 5,
    maxWidth: 100,
  },

  [`& .${classes.bottom}`]: {
    position: "absolute",
    bottom: 10,
    width: 300,
    height: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.bottomLeft}`]: {
    width: 180,
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.bottomText}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.darkGray.main,
    marginLeft: 10,
  },

  [`& .${classes.bottomRight}`]: {
    width: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },
}));

const ProfilePopover = ({
  open,
  anchorEl,
  handleClose,
  userInfo,
  openAdressDialog,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const id = open ? "simple-popover" : undefined;

  const signOut = () => dispatch(authActions.signout());
  const customer = useSelector(({ customer }) => customer);
  const authInfo = useSelector(({ auth }) => auth.customerInfo);

  const [letters, setLetters] = useState(null);
  const [listArr, setListArr] = useState(null);

  useEffect(() => {
    if (userInfo) {
      setLetters(getFirstLetters(userInfo?.first_name, userInfo?.last_name));
      if (customer?.isAdmin) {
        setListArr(profileButtonsArr);
      } else {
        if (authInfo?.role_names[0] === "companyuser") {
          let newArr = profileButtonsArr.filter(
            (item) =>
              item.text !== "Adres Değiştir" &&
              item.text !== "Kullanıcı İşlemleri"
          );
          setListArr(newArr);
        } else {
          let newArr = profileButtonsArr.filter(
            (item) => item.text !== "Adres Değiştir" && item.text !== "Adresler"
          );
          setListArr(newArr);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, customer, authInfo]);

  const handleSignOut = () => {
    signOut();
    handleClose();
    history.push("/signin-email");
  };

  const goToProfile = () => history.push("/dealer-profile");
  const goToUsers = () => history.push("/users-and-roles");
  // const goToServices = () => history.push("/services-and-returns");
  const goToAddresses = () => history.push("/addresses");

  const profileButtonsArr = [
    {
      icon: userSettingsIcon,
      text: "Profil Ayarları",
      func: goToProfile,
      isLast: "false",
    },
    {
      icon: addGroupIcon,
      text: "Kullanıcı İşlemleri",
      func: goToUsers,
      isLast: "false",
    },
    {
      icon: SendLight,
      text: "Adresler",
      func: goToAddresses,
      isLast: "false",
    },
    {
      icon: userChangeIcon,
      text: "Adres Değiştir",
      func: openAdressDialog,
      isLast: "false",
    },
    {
      icon: walletIcon,
      text: "Ödeme Yap",
      func: null,
      isLast: "false",
    },
    {
      icon: refundIcon,
      text: "İade ve Arızalı Ürün İşlemleri",
      func: null,
      isLast: "false",
    },
    {
      icon: invoiceIcon,
      text: "Hesap Ekstresi",
      func: null,
      isLast: "false",
    },
    {
      icon: pieChartIcon,
      text: "Raporlar",
      func: null,
      isLast: "true",
    },
  ];

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      className={classes.root}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={classes.paper}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={handleClose}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
        <div className={classes.head}>
          <div className={classes.letters}>
            <Typography className={classes.lettersText}>
              {letters && letters}
            </Typography>
          </div>
          <div className={classes.user}>
            <Typography className={classes.userName}>
              {userInfo?.full_name}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography noWrap className={classes.userFirm}>
                {customer?.currentCompany?.name}
              </Typography>
              <Typography className={classes.userFirm}>-</Typography>
              <Typography noWrap className={classes.userFirm}>
                {customer?.currentAddress?.title}
              </Typography>
            </div>
          </div>
          {/* <IconButton
            style={{ position : 'absolute', right: -10, top: -10, padding: 10}}
            aria-label="Close"
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton> */}
        </div>
        {listArr?.map((item, index) => (
          <PopoverRow
            key={index}
            icon={item.icon}
            text={item.text}
            func={item.func}
            isLast={item.isLast}
          />
        ))}
        <div className={classes.bottom}>
          <div className={classes.bottomLeft}>
            <img src={bIcon} width="20px" height="20px" alt="" />
            <Typography className={classes.bottomText}>Bufi</Typography>
          </div>
          <div className={classes.bottomRight} onClick={handleSignOut}>
            <img src={exitIcon} width="16px" height="18px" alt="" />
            <Typography className={classes.bottomText} exit-text="true">
              Çıkış
            </Typography>
          </div>
        </div>
      </div>
    </StyledPopover>
  );
};

ProfilePopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  userInfo: PropTypes.object,
  openAdressDialog: PropTypes.func,
};

export default ProfilePopover;
