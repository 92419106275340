import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";

import HomePurple from "../../Images/Cart/HomePurple.svg";
import PhonePurple from "../../Images/Cart/PhonePurple.svg";
import MailPurple from "../../Images/Cart/MailPurple.svg";
import Home from "../../Images/Cart/Home.svg";
import Phone from "../../Images/Cart/Phone.svg";
import Mail from "../../Images/Cart/Mail.svg";
import Send from "../../Images/Cart/Send.svg";
import SendPurple from "../../Images/Cart/SendPurple.svg";

import purpleRadio from "../../Images/DealerProfile/PurpleRadio.svg";
import RadioUnselected from "../../Images/ProductPage/RadioUnselected.svg";

import { customerActions, addressActions } from "../../Redux/Actions";
import MobileAddressSelectionConfirmation from "./MobileDialogs/MobileAddressSelectionConfirmation";

const StyledCard = styled("div", {
  shouldForwardProp: (prop) => prop !== "newAddress",
})(({ theme, newAddress }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(),
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: 6,
  marginBottom: theme.spacing(),
  marginTop: theme.spacing(),
  backgroundColor: newAddress ? "#d2f5e4" : "#fff",
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #6E3564",
  paddingBottom: theme.spacing(),
}));

const Row = styled("div", {
  shouldForwardProp: (prop) => prop !== "last" && prop !== "column",
})(({ last, theme, column, selected }) => ({
  display: "flex",
  flexDirection: column ? "column" : "row",
  alignItems: column ? "left" : "center",
  borderBottom: !last
    ? selected
      ? "1px solid #6E3564"
      : `1px solid ${theme.palette.darkGray.main}`
    : null,
  paddingBottom: !last && theme.spacing(),
  paddingTop: theme.spacing(),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "header" && prop !== "selected",
})(({ header, theme, selected }) => ({
  fontSize: 14,
  fontWeight: header ? 700 : 400,
  lineHeight: "20px",
  color:
    header && selected
      ? theme.palette.primary.main
      : theme.palette.darkGray.main,
  marginTop: header && 4,
}));

const StyledImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "header",
})(({ theme, header }) => ({
  width: header ? 24 : 18,
  height: header ? 24 : 18,
  marginRight: !header && theme.spacing(),
}));

const FirmAddressCard = ({ data, client, cart }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  const setUserCurrent = (query) =>
    dispatch(customerActions.setUserCurrent(query));

  const toggleAddress = (isAdmin, query, id, address) =>
    dispatch(addressActions.toggleDefault(isAdmin, query, id, address));

  const customer = useSelector(({ customer }) => customer);
  const newAddressId = useSelector(({ addresses }) => addresses.newAddressId);

  useEffect(() => {
    let active = true;
    if (active) {
      if (newAddressId && newAddressId === data.id) {
        handleCheckCart(data);
      } else return;
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddressId, data]);

  const handleCheckCart = (data, e) => {
    let query = {
      address: data,
      company: customer.currentCompany,
    };
    setUserCurrent(query);
  };

  const handleItemCheck = (item) => {
    let query = {
      is_default: true,
      address_id: item.id,
    };

    let userQuery = {
      address: item,
      company: customer.currentCompany,
    };

    let isAdmin = customer?.isAdmin;
    let userId = customer?.selectedUser?.id;
    cart
      ? setUserCurrent(userQuery)
      : toggleAddress(isAdmin, query, userId, item);
    setOpen((prev) => !prev);
  };

  const toggleDialog = (data) => {
    setItem(data);
    setOpen((prev) => !prev);
  };

  let selected = +data?.id === +customer?.currentAddress?.id;

  return (
    <StyledCard newAddress={newAddressId === data?.id}>
      <Header>
        <StyledImage
          header
          src={
            client
              ? selected
                ? SendPurple
                : Send
              : selected
              ? HomePurple
              : Home
          }
          alt=""
        />
        <Text header selected={selected}>
          {data?.name}
        </Text>
        {client && !cart ? (
          <div style={{ width: 20, height: 20 }} />
        ) : (
          <Radio
            icon={<img src={RadioUnselected} width="20px" alt="" />}
            checkedIcon={<img src={purpleRadio} width="20px" alt="" />}
            onChange={() => toggleDialog(data)}
            checked={+data?.id === +customer?.currentAddress?.id}
            style={{ height: 20, width: 20 }}
            value={data?.id}
          />
        )}
      </Header>
      <Row column selected={selected}>
        <Text>{data?.address1}</Text>
        <Text>{data?.address2}</Text>
        <Text>{`${data?.county?.name}/${data?.city?.name}`}</Text>
      </Row>
      <Row selected={selected}>
        <StyledImage src={selected ? MailPurple : Mail} alt="" />
        <Text>{data?.email}</Text>
      </Row>
      <Row last selected={selected}>
        <StyledImage src={selected ? PhonePurple : Phone} alt="" />
        <Text>{data?.phone1}</Text>
      </Row>
      <MobileAddressSelectionConfirmation
        open={open}
        cancel={toggleDialog}
        confirm={handleItemCheck}
        item={item}
      />
    </StyledCard>
  );
};

FirmAddressCard.propTypes = {
  data: PropTypes.object,
  client: PropTypes.bool,
  cart: PropTypes.bool,
};

export default FirmAddressCard;
