import React, { useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

import FiltersMain from "./Filters/FiltersMain";
import ResultsMain from "./Results/ResultsMain";
import PageContainer from "../Common/PageContainer";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: theme.spacing(6),
  height: "auto",
  marginTop: theme.spacing(2),
  "&.MuiContainer-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const FiltersPage = () => {
  const searchTextRef = useRef();

  const [childLoading, setChildLoading] = useState(false);

  const handleClearSearchText = () => {
    searchTextRef.current.handleClearKeyword();
  };

  return (
    <PageContainer
      searchTextRef={searchTextRef}
      noScroll={childLoading}
      noFooter>
      <StyledContainer>
        <FiltersMain />
        <ResultsMain
          handleClearSearchText={handleClearSearchText}
          setChildLoading={setChildLoading}
        />
      </StyledContainer>
    </PageContainer>
  );
};

export default FiltersPage;
