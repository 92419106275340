import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import closeIcon from "../../../Images/DraggableCard/CloseSub.svg";

const PREFIX = "SubCardHeader";

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  svgContainer: `${PREFIX}-svgContainer`,
  iconButton: `${PREFIX}-iconButton`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    width: 326,
    height: 29,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },

  [`& .${classes.text}`]: {
    width: "fit-content",
    height: "auto",
    fontSize: 20,
    fontWeight: 700,
    color: "rgba(97, 97, 97, 1)",
  },

  [`& .${classes.svgContainer}`]: {
    width: 20,
    height: 20,
    position: "absolute",
    top: 8,
    right: 20,
  },

  [`& .${classes.iconButton}`]: {
    width: 25,
    height: 25,
    padding: 0,
  },
}));

const SubCardHeader = ({ handleClose, noClose }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.text}>Firma Değiştir</div>
      {!noClose && (
        <div className={classes.svgContainer}>
          <IconButton
            disableRipple
            className={classes.iconButton}
            onClick={handleClose}
            size="large">
            <img
              src={closeIcon}
              width="17.62px"
              height="17.5px"
              alt="Close Icon"
            />
          </IconButton>
        </div>
      )}
    </Root>
  );
};

SubCardHeader.propTypes = {
  handleClose: PropTypes.func,
  noClose: PropTypes.bool,
};

export default SubCardHeader;
