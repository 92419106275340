import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PageContainer from "../Common/PageContainer";
import NewProductCard from "../../Ui/ProductCard/NewProductCard";

import { campaignService } from "../../Services";
import { generalConstants } from "../../Utils/Constants";

import Loading from "../../Utils/Loading";

const Container = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "auto",
  minHeight: "100vh",
  padding: 0,
  marginBottom: theme.spacing(4),
}));

const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkGray.main,
  fontSize: 34,
  lineHeight: "42px",
  fontWeight: 400,
  marginBottom: theme.spacing(2),
}));

const Campaigns = () => {
  const params = useParams();

  const campaigns = useSelector(({ campaigns }) => campaigns.campaigns);

  const currentCampaign = campaigns?.filter((item) => item.id === +params?.id);
  let header =
    currentCampaign && currentCampaign.length > 0
      ? currentCampaign[0]?.name
      : null;

  const [campaignProducts, setCampaignProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      if (params) {
        getCampaignProducts(params.id);
      }
    }
    return () => {
      active = false;
    };
  }, [params]);

  const getCampaignProducts = async (id) => {
    setLoading(true);
    const response = await campaignService.getCampaignProducts(id);
    if (response.data.status === generalConstants.STATUS_TRUE) {
      setCampaignProducts(response.data.product_skus);
      setLoading(false);
    } else {
      console.log(response);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <div style={{ height: 500, width: "100%" }}>
          <Loading open={loading} />
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header>{header && header}</Header>
      <Container container spacing={2}>
        {campaignProducts?.map((item, index) => (
          <Grid item key={index}>
            <NewProductCard item={item} />
          </Grid>
        ))}
      </Container>
    </PageContainer>
  );
};

export default Campaigns;
