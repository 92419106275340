import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";

import BestSellers from "../../../Images/Home/BestSellers.svg";
import BestSellersArea from "../../../Images/Home/BestSellersArea.svg";
import BestSellersUser from "../../../Images/Home/BestSellersUser.svg";
import MissingProducts from "../../../Images/Home/MissingProducts.svg";
import ProductSuggestions from "../../../Images/Home/ProductSuggestions.svg";
import { customerActions } from "../../../Redux/Actions";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 1212,
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const CardContainer = styled("div")(({ theme }) => ({
  width: 225,
  height: 475,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
}));

// const Text = styled(Typography, {
//   shouldForwardProp: (prop) => prop !== "bold",
// })(({ theme, bold }) => ({
//   fontSize: 19,
//   fontWeight: bold ? 700 : 400,
//   lineHeight: "20px",
//   color: theme.palette.darkGray.main,
// }));

const StyledImage = styled("img")(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: 227,
  height: 403,
  borderRadius: 8,
  transition: "box-shadow 0.2s ease-in-out",
  "&:hover": {
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
    boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.25)",
    outline: "none",
    border: "none",
  },
}));

const ImagesRow = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (value) => {
    let query = { ...filterQuery, tabValue: value };
    updateFilters(query);
    history.push("/filters-page");
  };

  const imagesArr = [
    {
      text1: "Bizde Çok",
      text2: "Satanlar",
      image: BestSellers,
      value: 1,
    },
    {
      text1: "Senin Çok",
      text2: "Sattıkların",
      image: BestSellersUser,
      value: 3,
    },
    {
      text1: "Senin Bölgende",
      text2: "Çok Satanlar",
      image: BestSellersArea,
      value: 2,
    },
    {
      text1: "Mağazanda",
      text2: "Eksik Desenler",
      image: MissingProducts,
      value: null,
    },
    {
      text1: "Mağazan İçin",
      text2: "Tavsiyeler",
      image: ProductSuggestions,
      value: null,
    },
  ];

  return (
    <Root>
      {imagesArr.map((item, index) => (
        <CardContainer
          key={index}
          onClick={() => item.value && goToFilters(item.value)}>
          {/* <Text bold>{item.text1}</Text>
          <Text>{item.text2}</Text> */}
          <StyledImage src={item.image} alt="" />
        </CardContainer>
      ))}
    </Root>
  );
};

export default ImagesRow;
