import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

import trackIcon from "../../Images/TopNavbar/Track.svg";
import ordersIcon from "../../Images/TopNavbar/orders.svg";
import cartIcon from "../../Images/TopNavbar/cart.svg";
import bellIcon from "../../Images/TopNavbar/bell.svg";

import ProfileMenu from "../TopNavbar/ProfileMenu";

const PREFIX = "Actions";

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`,
  actionsText: `${PREFIX}-actionsText`,
  link: `${PREFIX}-link`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 300,
    marginTop: theme.spacing(),
    userSelect: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.actions}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& img": {
        transform: "scale(1.1)",
      },
      "& $badge": {
        transform: "scale(1.1)",
      },
    },
    marginTop: 5,
  },

  [`& .${classes.actionsText}`]: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 10,
    textAlign: "center",
    marginTop: theme.spacing(),
    color: theme.palette.darkGray.main,
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 2,
    top: 1,
    padding: "0 0px",
    backgroundColor: theme.palette.primary.main,
    fontSize: 10,
    color: "#fff",
    height: 16,
    minWidth: 16,
  },
}));

const Actions = ({
  showNotificationsPopover,
  showCartPopover,
  showProfilePopover,
  userInfo,
  cartInfo,
}) => {
  const notificationsData = useSelector(
    ({ notifications }) => notifications?.data
  );

  const [cartBadge, setCartBadge] = useState(0);

  useEffect(() => {
    if (cartInfo) {
      if (cartInfo.order.total_quantity) {
        setCartBadge(parseInt(cartInfo.order.total_quantity));
      } else {
        setCartBadge(0);
      }
    } else {
      setCartBadge(0);
    }
  }, [cartInfo]);

  const unReadNotifications = notificationsData
    ? notificationsData.filter((n) => n.is_read === 0)
    : [];

  return (
    <Root className={classes.root}>
      <Link to="/fast-order" className={classes.link}>
        <span style={{ display: "block" }}>
          <div className={classes.actions}>
            <img src={trackIcon} alt="fastOrder" width="24px" height="20px" />
            <Typography className={classes.actionsText}>
              Hızlı Sipariş
            </Typography>
          </div>
        </span>
      </Link>
      <Link to="/order-page" className={classes.link}>
        <span style={{ display: "block" }}>
          <div className={classes.actions}>
            <img
              src={ordersIcon}
              alt="orders icon"
              width="22px"
              height="20px"
            />
            <Typography className={classes.actionsText}>
              Siparişlerim
            </Typography>
          </div>
        </span>
      </Link>
      <Link to="/cart-page" className={classes.link}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
              "& .img": {
                transform: "scale(1.1)",
              },
              "& .badge": {
                transform: "scale(1.1)",
              },
            },
            marginTop: 0.7,
          }}
          onMouseEnter={showCartPopover}>
          <StyledBadge
            badgeContent={cartBadge}
            color="primary"
            className="badge">
            <img
              className="img"
              src={cartIcon}
              alt="cart icon"
              width="20px"
              height="20px"
            />
          </StyledBadge>
          <Typography className={classes.actionsText}>Sepetim</Typography>
        </Box>
      </Link>

      <div className={classes.actions} onClick={showNotificationsPopover}>
        <StyledBadge
          badgeContent={unReadNotifications.length}
          color="primary"
          className="badge">
          <img src={bellIcon} alt="bell icon" width="18px" height="21px" />
        </StyledBadge>

        <Typography className={classes.actionsText}>Bildirimler</Typography>
      </div>
      <ProfileMenu
        showProfilePopover={showProfilePopover}
        userInfo={userInfo}
      />
    </Root>
  );
};

Actions.propTypes = {
  showNotificationsPopover: PropTypes.func,
  showCartPopover: PropTypes.func,
  showProfilePopover: PropTypes.func,
  userInfo: PropTypes.object,
  cartInfo: PropTypes.object,
};

export default Actions;
