import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { productActions } from "../../Redux/Actions";

import NoProduct from "./CartPopover/NoProduct";
import CartProduct from "../CartProduct/CartProduct";
import CartDeleteConfirmation from "../Dialog/CartDeleteConfirmation";

const StyledPopover = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(2),
  maxHeight: 620,
}));

const StyledPaper = styled("div")(() => ({
  width: 330,
  height: "auto",
  maxHeight: 600,
  boxShadow: "0px 2px 11px 0px #0000001A",
  borderRadius: 8,
  backgroundColor: "#fff",
  overflow: "auto",
  padding: "17px 15px 14px 12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const HeaderTextContainer = styled("div")(() => ({
  width: "fit-content",
  display: "flex",
  height: "auto",
}));

const HeaderText = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#000000",
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "submit",
})(({ submit, theme }) => ({
  textTransform: "none",
  fontSize: submit ? 14 : 12,
  fontWeight: submit ? 400 : 700,
  color: "#fff",
  borderRadius: 6,
  width: 144,
  height: submit ? 40 : 30,
  padding: 0,
  backgroundColor: submit ? theme.palette.darkGray.main : "#F05454",
  "&:hover": {
    backgroundColor: submit ? theme.palette.darkGray.main : "#F05454",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: "100%",
  height: 24,
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const CartPopover = ({ open, anchorEl, handleClose, data }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openDelete, setOpenDelete] = useState(false);

  const cart = useSelector(({ product }) => product.cart);
  const customer = useSelector(({ customer }) => customer);

  const deleteSingleProduct = (userId, productId, key) => {
    console.log(userId, productId, key);
    if (customer?.isAdmin) {
      deleteAdminProduct(userId, productId, key);
    } else {
      deleteProduct(productId, key);
    }
  };

  const deleteProduct = (id, key) =>
    dispatch(productActions.deleteProduct(id, key));

  const deleteAdminProduct = (userId, productId, key) =>
    dispatch(productActions.deleteAdminProduct(userId, productId, key));

  const clearCart = () => dispatch(productActions.clearCart());

  const clearAdminCart = (userId) =>
    dispatch(productActions.clearAdminCart(userId));

  const emptyCart = () => {
    if (cart.products.length > 0) {
      if (customer?.isAdmin) {
        clearAdminCart(customer?.selectedUser?.id);
      } else {
        clearCart();
      }
      toggleDeleteDialog();
    } else {
      return;
    }
  };

  const toggleDeleteDialog = () => setOpenDelete(!openDelete);

  const routeToCartPage = () => {
    console.log("clicked");
    history.push("/cart-page");
  };
  const id = open ? "cart-popover" : undefined;
  // let orderLength = Object.keys(data?.order).length;

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <StyledPaper>
        {data?.products.length > 0 && (
          <HeaderContainer>
            <HeaderTextContainer>
              <HeaderText>Sepetim</HeaderText>
              <HeaderText>({data?.order.total_quantity} Ürün)</HeaderText>
            </HeaderTextContainer>
            <StyledButton
              disableRipple
              variant="contained"
              onClick={toggleDeleteDialog}
              startIcon={<DeleteIcon />}>
              Sepeti Temizle
            </StyledButton>
          </HeaderContainer>
        )}
        <div>
          {data?.products.length !== 0 ? (
            <Stack spacing={1}>
              {data?.products.map((product, index) => (
                <CartProduct
                  key={index}
                  product={product}
                  deleteProduct={deleteSingleProduct}
                />
              ))}
            </Stack>
          ) : (
            <NoProduct />
          )}
          {data?.products.length !== 0 && (
            <ButtonContainer>
              <StyledButton
                disableRipple
                submit
                startIcon={<ShoppingCartIcon />}
                onClick={routeToCartPage}>
                Sepete Git
              </StyledButton>
            </ButtonContainer>
          )}
        </div>
      </StyledPaper>
      <CartDeleteConfirmation
        open={openDelete}
        onClose={toggleDeleteDialog}
        deleteCart={emptyCart}
      />
    </StyledPopover>
  );
};

CartPopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

export default CartPopover;
