import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

import { useCurrentWidth } from '../../../Utils/Hooks'

import CartAddressBox from '../../../Ui/CartAddressBox/CartAddressBox'

const StyledGrid = styled(Grid)(({ theme }) => ({
  height: 'auto',
  minHeight: 200,
  overflow: 'scroll',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(8),
  paddingBottom: theme.spacing(),
}))

const CustomerAddresses = ({ data, handleSwitch, all, loading }) => {
  const currentWidth = useCurrentWidth()

  const [values, setValues] = useState(null)

  useEffect(() => {
    let active = true
    if (active) {
      if (all) {
        setValues(data)
      } else {
        let newArr = data.filter((item) => item.status === 1)
        setValues(newArr)
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all])
  return (
    <StyledGrid container spacing={2}>
      {values?.map((item, index) => (
        <Grid
          item
          xs={
            currentWidth >= 600
              ? currentWidth >= 920
                ? currentWidth >= 1200
                  ? 3
                  : 4
                : 6
              : 12
          }
          key={index}>
          <CartAddressBox
            item={item}
            addressPage
            handleSwitch={handleSwitch}
            loading={loading}
          />
        </Grid>
      ))}
    </StyledGrid>
  )
}

CustomerAddresses.propTypes = {
  data: PropTypes.array,
  handleSwitch: PropTypes.func,
  all: PropTypes.bool,
  loading: PropTypes.bool,
}

export default CustomerAddresses
