import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  customerActions,
  productActions,
  bottomNavbarActions,
} from "../../Redux/Actions";
import { filterService, productService } from "../../Services";
import { generalConstants, bottomNavItems } from "../../Utils/Constants";

import TopBoxMob from "./Home/TopBoxMob";
import PageContainerMob from "../Common/PageContainerMob";
import MobileTopBar from "../../Ui/MobileTopBar/MobileTopBar";
import MobileCarousel from "../../Ui/Carousel/MobileCarousel";
import MobileSectionCarousel from "../../Ui/Carousel/MobileSectionCarousel";
import MobileBrands from "./Home/MobileBrands";
import ImagesRowMob from "./Home/ImagesRowMob";
import CategoriesMob from "./Home/CategoriesMob";
import Loading from "../../Utils/Loading";
import MobileFooter from "../../Ui/Footer/MobileFooter";
import AdminAddressSelection from "../../Ui/Dialog/Mobile/AdminAddressSelection";

import { styled } from "@mui/material/styles";

const ContentContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: 0,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  overflowX: "hidden",
  marginTop: theme.spacing(),
}));

const HomeMob = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const setNewArrivalsData = (query) =>
    dispatch(productActions.setNewArrivalsData(query));

  const setMenu = (value) => dispatch(bottomNavbarActions.setMenu(value));

  const customer = useSelector(({ customer }) => customer);

  const [newArrivals, setNewArrivals] = useState(null);
  const [lastVisiteds, setLastVisiteds] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      setMenu(bottomNavItems.MAIN);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;
    if (active) {
      let newArrivalsQuery = {
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "desc",
        filter: {
          tag: "yeni",
          on_sale: 1,
        },
      };
      let categoriesQuery = {
        pageNumber: 1,
        pageSize: 6,
        sortField: "id",
        sortOrder: "desc",
        filter: { status: 1 },
      };
      if (customer?.selectedUser) {
        let isAdmin = customer?.isAdmin;
        let userId = +customer?.selectedUser?.id;
        getLastVisiteds(isAdmin, userId);
        setLoading(true);
        if (isAdmin) {
          if (customer.currentCompany) {
            let query_ = {
              ...newArrivalsQuery,
              filter: {
                ...newArrivalsQuery.filter,
                company_id: customer.currentCompany.id,
              },
            };
            getNewArrivals(query_);
          } else return;
        } else {
          getNewArrivals(newArrivalsQuery);
        }
      }
      getCategories(categoriesQuery);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customer?.isAdmin && !customer?.currentAddress) {
        setOpen(true);
      } else return;
    }
    return () => {
      active = false;
    };
  }, [customer]);

  const getNewArrivals = async (query) => {
    await productService
      .productSkuList(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let data_ = res.data.product_skus;
          setNewArrivals(data_);
          setNewArrivalsData(data_);
          setLoading(false);
        } else {
          console.log("else", res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getLastVisiteds = async (isAdmin, userId) => {
    await productService
      .getLastVisits(isAdmin, userId)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let response = res.data.product_skus;
          let data = response.slice(Math.max(response.length - 5, 0));
          //array.slice(0, n);
          setLastVisiteds(data);
        } else {
          console.log("else", res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCategories = async (query) => {
    await filterService
      .categorySubList(query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setCategories(res.data.sub_categories);
        } else {
          console.log("else", res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (value) => {
    let query = { ...filterQuery, tabValue: value };
    updateFilters(query);
    history.push("/filters-page");
  };

  const closeDialog = () => setOpen(false);

  return (
    <PageContainerMob noScroll>
      <Loading open={loading} />
      <MobileTopBar />
      <ContentContainer>
        <TopBoxMob />
        <MobileCarousel />
        <MobileSectionCarousel
          text="Yeni Gelenler"
          data={newArrivals}
          handleClick={goToFilters}
          value={4}
        />
        <MobileBrands />
        <ImagesRowMob />
        <CategoriesMob
          categories={categories}
          handleClick={goToFilters}
          value={0}
        />
        <MobileSectionCarousel
          text="Son Gezilenler"
          data={lastVisiteds}
          noButton
        />
        <MobileFooter />
      </ContentContainer>
      {/* <NoResult /> */}
      <AdminAddressSelection open={open} handleClose={closeDialog} />
    </PageContainerMob>
  );
};

export default HomeMob;
