import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import TabButtons from "./TabButtons";
import Features from "./Features";
import Ratings from "./Ratings";
import Descriptions from "./Descriptions";

const PREFIX = "FeaturesAndRatings";

const classes = {
  root: `${PREFIX}-root`,
  tabsContainer: `${PREFIX}-tabsContainer`,
};

const Root = styled("div")(({theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 432,
    border: `1px solid ${theme.palette.darkGray.light}`,
    marginTop: 4,
  },

  [`& .${classes.tabsContainer}`]: {
    width: "100%",
    height: 41,
    display: "flex",
    justifyContent: "flex-start",
    borderBottom: `1px solid ${theme.palette.darkGray.light}`,
  },
}));

const FeaturesAndRatings = ({ data }) => {
  const [index, setIndex] = useState("0");

  return (
    <Root className={classes.root}>
      <div className={classes.tabsContainer}>
        <TabButtons setIndex={setIndex} index={index} />
      </div>
      {index === "0" ? (
        <Descriptions />
      ) : index === "1" ? (
        <Features data={data?.properties} />
      ) : (
        <Ratings />
      )}
    </Root>
  );
};

FeaturesAndRatings.propTypes = {
  data: PropTypes.object,
};

export default FeaturesAndRatings;
