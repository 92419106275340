import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Backdrop from "@mui/material/Backdrop";
import Pagination from "@mui/material/Pagination";

import SearchArea from "../../../Ui/Fields/SearchArea";
import TableHeader from "./Users/TableHeader";
import TableRow from "./Users/TableRow";
import DrawerContent from "./Users/DrawerContent";
import UsersFiltersBox from "./Users/UsersFiltersBox";
import AddUser from "../Main/Dialogs/AddUser";
import AddUserCA from "../Main/Dialogs/AddUserCA";

import Loading from "../../../Utils/Loading";

import { companyService, userService } from "../../../Services";
import { generalConstants } from "../../../Utils/Constants";

const PREFIX = "Users";

const classes = {
  root: `${PREFIX}-root`,
  mainCard: `${PREFIX}-mainCard`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    marginTop: 14,
  },

  [`& .${classes.mainCard}`]: {
    width: 1212,
    height: "auto",
    minHeight: 400,
    marginTop: 10,
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16);",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    padding: 5,
    paddingTop: 35,
    paddingBottom: 10,
  },
}));

const StyledBackDrop = styled(Backdrop, {
  name: "MuiDrawer",
  slot: "Backdrop",
  overridesResolver: (props, styles) => {
    return styles.backdrop;
  },
})({
  zIndex: -1,
  backdropFilter: "blur(2px)",
  backgroundColor: "rgba(0,0,0,0.005)",
});

const Users = ({ roles, addUser, addUserCA, closeAddUser }) => {
  const isAdmin = useSelector(({ customer }) => customer.isAdmin);
  const companyId = useSelector(({ customer }) => customer?.currentCompany?.id);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [firms, setFirms] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [firmsArr, setFirmsArr] = useState([]);
  const [rolesArr, setRolesArr] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: {
      full_name: "",
      status: [],
      roles: [],
      company_id: [],
    },
  });

  useEffect(() => {
    let active = true;
    if (active) {
      if (companyId) {
        newGetUsers(query, companyId, isAdmin);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, companyId, query]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (isAdmin) {
        const query_ = {
          pageNumber: 1,
          pageSize: 1000,
          sortField: "id",
          sortOrder: "asc",
          filter: {},
        };
        getCompanyList(query_);
      }
    }

    return () => {
      active = false;
    };
  }, [isAdmin]);

  const newGetUsers = async (query, companyId, isAdmin, initial) => {
    setLoading(true);
    let query_ = initial ? { ...query, pageNumber: 1 } : query;
    setPageNumber(initial ? 1 : query.pageNumber);
    const response = await userService.getAllUsers_(
      query_,
      companyId,
      isAdmin,
      true
    );
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let users_ = response.data.users;
      setUsers(users_);
      let pageCount_ = Math.round(response?.data?.total_count / 10);
      setPageCount(pageCount_);
      setLoading(false);
    } else {
      console.log(response);
      setLoading(false);
    }
  };


  const getCompanyList = async (query) => {
    setLocalLoading(true);
    const response = await companyService.getCompanies(query);
    if (response?.data?.status === generalConstants.STATUS_TRUE) {
      let data = response.data.companies;
      let notDuplicatedData = data.filter(
        (v, i, a) =>
          a.findIndex((t) => ["name"].every((k) => t[k] === v[k])) === i
      );
      let finalData = notDuplicatedData.filter((item) => item.name !== "None");
      setFirms(finalData);
      setLocalLoading(false);
    } else {
      setFirms([{ name: "Sonuç bulunamadı", id: 0.5 }]);
      setLocalLoading(false);
    }
  };

  const toggleDrawer = () => setOpenDrawer((prev) => !prev);

  const clearFilters = () => {
    setStatusArr([]);
    setFirmsArr([]);
    setRolesArr([]);
  };

  const handleFiltersSubmit = () => {
    let query_ = {
      ...query,
      pageNumber: 1,
      filter: {
        ...query.filter,
        status: statusArr?.length > 0 ? statusArr[0] : "",
        roles: rolesArr,
        company_id: firmsArr,
      },
    };
    setQuery(query_);
    toggleDrawer();
  };

  const deleteFab = (item) => {
    if (item.source === "status") {
      commonHandler(item.id, statusArr, setStatusArr);
      setQuery({
        ...query,
        filter: {
          ...query.filter,
          status: statusArr.filter((x) => x !== item.id),
        },
      });
    } else if (item.source === "roles") {
      commonHandler(item.id, rolesArr, setRolesArr);
      setQuery({
        ...query,
        filter: {
          ...query.filter,
          roles: rolesArr.filter((x) => x !== item.id),
        },
      });
    } else if (item.source === "firms") {
      commonHandler(item.id, firmsArr, setFirmsArr);
      setQuery({
        ...query,
        filter: {
          ...query.filter,
          company_id: firmsArr.filter((x) => x !== item.id),
        },
      });
    }
  };

  const commonHandler = (id, arr, func) => {
    if (arr === statusArr) {
      if (arr?.length === 1) {
        let newArr = [];
        if (arr[0] === id) {
          func(newArr);
        } else {
          func([id]);
        }
      } else {
        func([id]);
      }
    } else if (arr.includes(id)) {
      let filteredArr = arr.filter((item) => item !== id);
      func(filteredArr);
    } else {
      let newArr = [...arr, id];
      func(newArr);
    }
  };

  const clearAll = () => {
    clearFilters();
    let query_ = {
      ...query,
      pageNumber: 1,
      filter: {
        ...query.filter,
        status: [],
        roles: [],
        company_id: [],
      },
    };
    setQuery(query_);
    newGetUsers(query_, companyId, isAdmin, true);
  };

  const handleChange = (e) => setKeyword(e.target.value);

  const handleKeyword = () =>
    setQuery({ ...query,pageNumber: 1, filter: { full_name: keyword } });

  const clearKeyword = () => {
    setQuery({ ...query, filter: { full_name: "" } });
    setKeyword("");
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleKeyword();
    }
  };

  const onPageNumberChange = (event, value) => {
    setPageNumber(value);
    setQuery({
      ...query,
      pageNumber: value,
    });
  };

  return (
    <Root className={classes.root}>
      <Loading open={loading || localLoading} />
      <SearchArea
        handleChange={handleChange}
        searchText={keyword}
        placeholder="Kullanıcılarda Ara"
        handleKeyword={handleKeyword}
        clearKeyword={clearKeyword}
        keyPress={keyPress}
        toggleDrawer={toggleDrawer}
      />
      {query.filter.status === 0 ||
      query.filter.status === 1 ||
      query.filter.roles?.length > 0 ||
      query.filter.company_id?.length > 0 ? (
        <UsersFiltersBox
          statusArr={statusArr}
          rolesArr_={rolesArr}
          firmsArr={firmsArr}
          firms={firms}
          deleteFab={deleteFab}
          clearFilters={clearAll}
          query={query}
        />
      ) : (
        <div />
      )}
      <div className={classes.mainCard}>
        <TableHeader />
        {users?.length > 0 &&
          users?.map((user, index) => (
            <TableRow
              key={index}
              user={user}
              roles={roles}
              newGetUsers={newGetUsers}
              companyId={companyId}
              isAdmin={isAdmin}
              query={query}
            />
          ))}
        {pageCount > 1 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 24,
            }}>
            <Pagination
              page={pageNumber || 1}
              count={pageCount}
              onChange={onPageNumberChange}
            />
          </div>
        )}
      </div>
      <Drawer
        BackdropComponent={StyledBackDrop}
        anchor={"right"}
        open={openDrawer}
        onClose={toggleDrawer}>
        <DrawerContent
          toggleDrawer={toggleDrawer}
          commonHandler={commonHandler}
          statusArr={statusArr}
          setStatusArr={setStatusArr}
          firms={firms}
          firmsArr={firmsArr}
          setFirmsArr={setFirmsArr}
          isAdmin={isAdmin}
          rolesArr={rolesArr}
          setRolesArr={setRolesArr}
          handleFiltersSubmit={handleFiltersSubmit}
        />
      </Drawer>
      <AddUser
        open={addUser}
        onClose={closeAddUser}
        roles={roles}
        newGetUsers={newGetUsers}
        isAdmin={isAdmin}
        companyId={companyId}
        query_={query}
      />
      <AddUserCA
        open={addUserCA}
        onClose={closeAddUser}
        newGetUsers={newGetUsers}
        isAdmin={isAdmin}
        companyId={companyId}
        query_={query}
      />
    </Root>
  );
};

Users.propTypes = {
  roles: PropTypes.array,
  addUser: PropTypes.bool,
  adUserCA: PropTypes.bool,
  closeAddUser: PropTypes.func,
};

export default Users;
