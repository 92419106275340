import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.darkGray.main,
    userSelect: "none",
  },
}));

const Header = ({ text }) => {
  return <StyledTypography className={classes.root}>{text}</StyledTypography>;
};

Header.propTypes = {
  text: PropTypes.string,
};

export default Header;
