import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import CriticalStock from "../../Images/ProductCard/CriticalStock.svg";
import NoStock from "../../Images/ProductCard/NoStock.svg";
import GreenTick from "../../Images/ProductCard/GreenTick.svg";

import SpecialDemandsCard from "../Dialog/SpecialDemands/SpecialDemandsCard";

import { productActions } from "../../Redux/Actions";
import { generalConstants } from "../../Utils/Constants";
import { productService } from "../../Services";

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  width: "100%",
  whiteSpace: "pre-wrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  //   "-webkit-line-clamp": "2",
  WebkitLineClamp: "2",
  lineClamp: "2",
  display: "-webkit-box",
  //   "-webkit-box-orient": "vertical",
  WebkitBoxOrient: "vertical",
  marginBottom: theme.spacing(),
  cursor: "default",
  height: "fit-content",
  marginRight: theme.spacing(),
}));

const StyledImage = styled("img")(() => ({
  width: "auto",
  objectFit: "cover",
  borderRadius: "2px",
  maxHeight: 84,
}));

const CardAction = styled("div")(({ theme }) => ({
  width: 172,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 2,
}));

const SubContainer = styled("div")(({ theme }) => ({
  width: 64,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginRight: theme.spacing(),
}));

const CardText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "price",
})(({ theme, price }) => ({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  width: price && 100,
  textAlign: price && "center",
  cursor: "default",
}));

const AddButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  width: 100,
  height: 30,
  borderRadius: 6,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "normal",
  display: "none",
  zIndex: 2000,
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const FlipBoxFront = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  height: " 100%",
  " -webkit-backface-visibility": "hidden",
  backfaceVisibility: "hidden",
  backgroundColor: "#fff",
  color: "black",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const FlipBoxBack = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  height: " 100%",
  " -webkit-backface-visibility": "hidden",
  backfaceVisibility: "hidden",
  backgroundColor: "#fff",
  color: "white",
  transform: "rotateY(180deg)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Corner = styled("div", {
  shouldForwardProp: (prop) => prop !== "condition",
})(({ theme, condition }) => ({
  height: condition === "Yeni" ? 35 : 37,
  width: condition === "Yeni" ? 35 : 37,
  position: "absolute",
  backgroundColor: condition === "Yeni" ? "#D2F5E4" : "#FFE4B8",
  color: condition === "Yeni" ? "#10C66C" : "#F5B041",
  fontSize: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  right: 0,
  paddingTop: condition === "Yeni" && theme.spacing(),
  paddingLeft: condition !== "Yeni" ? 6 : 2,
  top: 0,
  borderRadius: "0 0 0 100%",
}));

const CornerText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "condition",
})(({ condition }) => ({
  fontSize: 10,
  color: condition === "Yeni" ? "#10C66C" : "#F5B041",
}));

const NewProductCard = ({ item }) => {
  const history = useHistory();
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const specialFeatureValues = useSelector(
    ({ product }) => product.featureValues
  );

  const customer = useSelector(({ customer }) => customer);

  const getCart = () => dispatch(productActions.getCart());
  const getAdminCart = (id) => dispatch(productActions.getAdminCart(id));

  const [openModal, setOpenModal] = useState(false);
  const [customWidth, setCustomWidth] = useState(null);
  const [imageArr, setImageArr] = useState(null);
  const [selections, setSelections] = useState({
    product_id: "",
    quantity: 1,
    features: null,
  });

  useEffect(() => {
    let active = true;
    if (active && item) {
      const dimensions = item.attributes_map.Ebat;
      const width = parseInt(dimensions?.split("x")[0]);
      const height = parseInt(dimensions?.split("x")[1]);
      const heightIsNan = _.isNaN(height) ? "" : height;
      const imageArr =
        item.images.length > 1
          ? item.images.map((item) => item.url)
          : [item.image, item.image];
      setImageArr(imageArr);
      if (item.type.id === 3) {
        setCustomWidth(width);
        setSelections({
          ...selections,
          product_id: item.id,
          features: {
            width,
            height: heightIsNan,
            shape: "Dikdörtgen",
            side: "Overlok",
            webType: "",
            webColor: "",
          },
        });
      } else {
        setSelections({
          ...selections,
          product_id: item.id,
          features: {
            ...selections.features,
            width,
            height: heightIsNan,
            shape: "Dikdörtgen",
            side: "Overlok",
            webType: "",
            webColor: "",
          },
        });
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const toggleModal = () => setOpenModal(!openModal);

  const closeDialogFromButton = () => {
    toggleModal();
    discardFeaturesSelections();
  };

  const handleFeatures = (prop) => (e) => {
    if (selections.features[prop] === e.target.value) {
      setSelections({
        ...selections,
        features: { ...selections.features, [prop]: "" },
      });
    } else {
      if (
        prop === "side" &&
        e.target.value !== "Saçak" &&
        selections.features.webType !== ""
      ) {
        setSelections({
          ...selections,
          features: {
            ...selections.features,
            [prop]: e.target.value,
            webType: "",
          },
        });
      } else if (prop === "width" || prop === "height") {
        let data = parseInt(e.target.value);
        if (prop === "width" && data > customWidth) {
          setSelections({
            ...selections,
            features: { ...selections.features, [prop]: customWidth },
          });
        } else {
          let height = data > 2500 ? 2500 : data;
          setSelections({
            ...selections,
            features: { ...selections.features, [prop]: height },
          });
        }
      } else {
        setSelections({
          ...selections,
          features: { ...selections.features, [prop]: e.target.value },
        });
      }
    }
  };

  const handleClick = (id) => {
    history.push(`/product/${id}`);
  };

  let stockQuantity = parseInt(item?.stock.quantity);
  let source =
    stockQuantity < 4 && stockQuantity > 0
      ? { img: CriticalStock, text: "Kritik Stok" }
      : stockQuantity === 0
      ? { img: NoStock, text: "Stok Sorunuz" }
      : { img: GreenTick, text: "Stok Uygun" };

  let priceText =
    item?.price && item?.price.view_price
      ? `${item?.price.view_price}₺`
      : "Fiyat Bulunamadı";

  const handleAdd = () => {
    if (item.type.id === 3) {
      toggleModal();
    } else {
      handleSubmit();
    }
  };

  const notify = () => (toastId.current = toast("Ürün Sepete Eklendi"));

  const discardFeaturesSelections = () => {
    setSelections({
      ...selections,
      quantity: 1,
      product_id: item.id,
      features: {
        width: customWidth,
        height: "",
        shape: "Dikdörtgen",
        side: "Overlok",
        webType: "",
        webColor: "",
      },
    });
  };

  const handleSubmit = () => {
    openModal && closeDialogFromButton();
    let query =
      item?.type?.id === 3 ? selections : { ...selections, features: null };

    if (customer?.isAdmin) {
      let id = customer?.selectedUser?.id;
      productService
        .addToAdminCart(query, id)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getAdminCart(id);
            notify();
            discardFeaturesSelections();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    } else {
      productService
        .addToCart(query)
        .then((res) => {
          if (res.data.status === generalConstants.STATUS_TRUE) {
            getCart();
            notify();
            discardFeaturesSelections();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const increaseQuantityFromModal = () => {
    setSelections({
      ...selections,
      quantity: selections.quantity + 1,
    });
  };

  const decreaseQuantityFromModal = () => {
    if (selections.quantity === 1) return;
    setSelections({
      ...selections,
      quantity: selections.quantity - 1,
    });
  };

  return (
    <Box
      sx={{
        width: 450,
        height: 100,
        p: (theme) => theme.spacing(),
        ml: (theme) => theme.spacing(),
        display: "flex",
        alignItems: "center",
        position: "relative",
        // border: "1px solid #B1AEAE",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",

        transition: (theme) =>
          theme.transitions.create(["box-shadow", "display", "font-weight"]),
        "&:hover": {
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.25)",
          outline: "none",
          border: "none",
        },
        "&:hover .price": {
          display: "none",
        },
        "&:hover .button": {
          display: "flex",
          WebkitAppearance: "auto",
        },
        "&:hover .flipbox": {
          transform: "rotateY(180deg)",
        },
        "&:hover .corner": {
          fontWeight: 600,
        },
      }}>
      {(item?.tag === "Yeni" || item?.tag === "Serisonu") && (
        <Corner condition={item?.tag}>
          {item?.tag === "Yeni" ? (
            <CornerText className="corner" condition={item?.tag}>
              {item?.tag}
            </CornerText>
          ) : (
            <>
              <CornerText className="corner" condition={item?.tag}>
                Seri
              </CornerText>
              <CornerText className="corner" condition={item?.tag}>
                Sonu
              </CornerText>
            </>
          )}
        </Corner>
      )}
      <div
        style={{
          display: "flex",
          position: "realtive",
          alignItems: "center",
        }}
        onClick={() => handleClick(item?.id)}>
        <Box
          sx={{
            width: 100,
            height: "84px",
            maxHeight: 84,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            perspective: "1000px",
            mr: (theme) => theme.spacing(),
          }}>
          <Box
            className="flipbox"
            sx={{
              position: "relative",
              width: "100%",
              height: 84,
              textAlign: "center",
              transition: "transform 0.8s",
              transformStyle: "preserve-3d",
            }}>
            <FlipBoxFront>
              <StyledImage src={imageArr && imageArr[1]} alt="" />
            </FlipBoxFront>
            <FlipBoxBack>
              <StyledImage src={imageArr && imageArr[0]} alt="" />
            </FlipBoxBack>
          </Box>
        </Box>
        <StyledHeader>{item?.name}</StyledHeader>
      </div>
      <CardAction>
        <SubContainer>
          <CardText>{item?.stock.quantity}</CardText>
          <Tooltip
            title={source.text}
            placement="bottom-start"
            arrow
            disableInteractive>
            <img src={source.img} width="20px" alt="" />
          </Tooltip>
        </SubContainer>
        <AddButton className="button" onClick={handleAdd}>
          Sepete Ekle
        </AddButton>
        <CardText className="price" price>
          {priceText}
        </CardText>
      </CardAction>
      {item.type.id === 3 && selections.features && (
        <SpecialDemandsCard
          onClose={toggleModal}
          closeDialogFromButton={closeDialogFromButton}
          open={openModal}
          product={item}
          features={selections?.features}
          specialFeatureValues={specialFeatureValues}
          handleFeatures={handleFeatures}
          increase={increaseQuantityFromModal}
          decrease={decreaseQuantityFromModal}
          selections={selections}
          handleSubmit={handleSubmit}
        />
      )}
    </Box>
  );
};

NewProductCard.propTypes = {
  item: PropTypes.object,
};

export default NewProductCard;
