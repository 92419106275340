import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import AuthContainerMob from "./AuthContainerMob";
import HeaderRoute from "./AuthCommons/HeaderRoute";
import Info from "./PasswordSent/Info";
import ReturnHome from "./PasswordSent/ReturnHome";
import passwordIcon from "../../Images/Auth/password.svg";

const innerWidth = window.innerWidth;

const Root = styled("div")(() => ({
  width: innerWidth,
  maxHeight: 418,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledCard = styled("div")(() => ({
  marginTop: 30,
  width: "95%",
  background: "rgba(133, 89, 122, 0.1)",
  borderRadius: 8,
  height: 320,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const PasswordSent = ({ email }) => {
  let history = useHistory();

  const handleRoute = () => {
    history.push("forgot-password");
  };

  return (
    <AuthContainerMob>
      <Root>
        <StyledCard>
          <HeaderRoute handleRoute={handleRoute} component="password-sent">
            Şifremi Unuttum
          </HeaderRoute>
          <img
            src={passwordIcon}
            alt="passwordIcon"
            width="92px"
            height="91px"
          />
          <Info email={email} />
          <ReturnHome />
        </StyledCard>
      </Root>
    </AuthContainerMob>
  );
};

PasswordSent.propTypes = {
  email: PropTypes.string,
};

export default PasswordSent;
