import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  marginBottom: theme.spacing(),
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== "title" &&
    prop !== "city" &&
    prop !== "borderBttm" &&
    prop !== "address" &&
    prop !== "textWithIcon",
})(({ theme, title, address, borderBttm, textWithIcon }) => ({
  fontSize: title ? 14 : 12,
  fontWeight: title ? 700 : 400,
  lineHeight: title ? "20px" : "18px",
  color: theme.palette.darkGray.main,
  borderBottom: borderBttm && `1px solid ${theme.palette.darkGray.main}`,
  paddingBottom: borderBttm && theme.spacing(),
  paddingTop: address && theme.spacing(),
  marginLeft: textWithIcon && theme.spacing(2),
}));


const AddressContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  borderBottom: `1px solid ${theme.palette.darkGray.main}`,
  paddingBottom: theme.spacing(),
}));

const PhoneContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: `${theme.spacing()} 0px`,
  borderBottom: `1px solid ${theme.palette.darkGray.main}`,
}));

const MailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: `${theme.spacing()} 0px`,
}));

const AddressCard = ({ company }) => {
  return (
    <Box
      sx={{
        width: 275,
        height: "auto",
        p: (theme) => theme.spacing(1.5),
        boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16)",
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        "&:hover .mapIcon": {
          fill: (theme) => theme.palette.primary.main,
        },
        "&:hover .icon": {
          fill: (theme) => theme.palette.primary.main,
        },
        "&:hover .title": {
          color: (theme) => theme.palette.primary.main,
        },
        "&:hover .titleBorder": {
          color: (theme) => theme.palette.primary.main,
          borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
        },
        "&:hover .addressContainer": {
          borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
        },
        "&:hover .container": {
          borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      <HeaderContainer>
        <FmdGoodIcon className="mapIcon" />
        <Text title textWithIcon className="title">
          {company?.name}
        </Text>
      </HeaderContainer>
      <Text title borderBttm className="titleBorder">
        {company?.address?.title}
      </Text>
      <Text address>{company?.address?.address1}</Text>
      <AddressContainer className="addressContainer">
        <Text city>{company?.address?.county?.name}</Text>
        <Text city>/</Text>
        <Text city>{company?.address?.city?.name}</Text>
      </AddressContainer>
      <PhoneContainer className="container">
        <LocalPhoneIcon style={{ fontSize: 18 }} className="icon" />
        <Text textWithIcon>{company?.address?.phone1}</Text>
      </PhoneContainer>
      <MailContainer>
        <EmailOutlinedIcon style={{ fontSize: 18 }} className="icon" />
        <Text textWithIcon>{company?.address?.email}</Text>
      </MailContainer>
    </Box>
  );
};

AddressCard.propTypes = {
  company: PropTypes.object,
};

export default AddressCard;
