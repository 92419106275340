import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextArea from "../../../../Ui/Fields/TextArea";
import PhoneArea from "../../../../Ui/Fields/PhoneArea";
import AutoCompleteArea from "../../../../Ui/Fields/AutocompleteArea";
import MobileDialogHeader from "../../../../Ui/Dialog/Tools/MobileDialogHeader";
import AutoCompleteMultiple from "../../../../Ui/Fields/AutoCompleteMultiple";

import { generalConstants } from "../../../../Utils/Constants";

import { userService } from "../../../../Services";
import Loading from "../../../../Utils/Loading";
import { MobileDialogButton, Transition } from "../../../../Utils/Tools";
import { BufiSwitch } from "../../../../Utils/Tools";

const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .css-1pzulcg-MuiTypography-root": {
    color: theme.palette.secondary.main,
    fontWeight: 300,
    fontSize: 12,
    "&[aktif='true']": {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledFirm = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "20px",
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const MobileAddUserCa = ({
  open,
  onClose,
  newGetUsers,
  query_,
}) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const customerAddresses = useSelector(({ addresses }) => addresses.addresses);
  const customer = useSelector(({ customer }) => customer);

  const roles = [
    { id: "4", name: "Firma Kullanıcı" },
    { id: "3", name: "Firma Yetkili" },
  ];

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gsm: "",
    firm: "",
    branch: [],
    roles: { name: "Lütfen rol seçiniz", id: 0.5 },
    defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
    status: 1,
  });

  const [error, setError] = useState(false);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (open && customer) {
        setValues({
          first_name: "",
          last_name: "",
          email: "",
          gsm: "",
          firm: customer?.currentCompany?.id,
          branch: [],
          roles: { name: "Lütfen rol seçiniz", id: 0.5 },
          defaultAddress: { name: "Lütfen şube seçiniz", id: 0.5 },
          status: 1,
        });
        setError(false);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, customer]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (customerAddresses) {
        if (customerAddresses.length > 0) {
          let companyAddresses = customerAddresses.filter(
            (addr) => addr.type === 0
          );
          setAddresses(companyAddresses);
        } else {
          setAddresses([{ name: "Sonuç bulunamadı", id: 0.5 }]);
        }
      } else return;
    }
    return () => {
      active = false;
    };
  }, [customerAddresses]);

  const handleChange = (prop) => (event) => {
    let data = event.target.value;
    setValues({ ...values, [prop]: data });
  };

  const handleSwitch = (e) => {
    e.target.checked === true
      ? setValues({ ...values, status: 1 })
      : setValues({ ...values, status: 0 });
  };

  const handleCheck = () => {
    let phoneCode = "+905";
    let gsm = phoneCode.concat(values.gsm).replace(/\s/g, "");
    let truthyCond =
      values.first_name.length === 0 ||
      values.last_name.length === 0 ||
      values.email.length === 0 ||
      !emailRegex.test(values.email) ||
      gsm.length !== 13 ||
      !values.roles ||
      !values.firm;

    let finalCond =
      values?.roles?.name === "Firma Yetkili"
        ? truthyCond
        : truthyCond || !values.branch || !values.defaultAddress;

    if (finalCond) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    let isError = handleCheck();
    let phoneCode = "+905";
    let newPhone = phoneCode.concat(values.gsm).replace(/\s/g, "");
    let addrIds = values.branch.map((item) => item.id.toString());
    let companyAdminQuery = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      gsm: newPhone,
      roles: [`${values.roles.id}`],
      company_id: values.firm,
      default_address_id: values.defaultAddress.id.toString(),
    };
    let userQuery = {
      ...companyAdminQuery,
      addresses: addrIds,
    };
    if (!isError) {
      setLoading(true);
      let role_ = values?.roles?.name;
      let selectedQuery =
        role_ === "Firma Kullanıcı" ? userQuery : companyAdminQuery;
      const response = await userService.addUser(selectedQuery);
      if (response.data.status === generalConstants.STATUS_TRUE) {
        toast.success("Kullanıcı başarılı bir şekilde eklendi");
        handleClose();
        newGetUsers(query_, true);
        setLoading(false);
      } else {
        toast.error(response?.data?.message);
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setError(false);
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      style={{ overflow: "hidden" }}>
      <MobileDialogHeader
        header="Yeni Kullanıcı"
        callback={onClose}
        paddingTop
      />
      <DialogContent style={{ padding: 8 }}>
        <Loading open={loading} />
        <div>
          <TextArea
            label="Ad"
            handleChange={handleChange}
            values={values}
            prop="first_name"
            component="add user"
            error={error}
          />
          <TextArea
            label="Soyad"
            handleChange={handleChange}
            values={values}
            prop="last_name"
            component="add user"
            error={error}
          />
          <TextArea
            label="Eposta"
            handleChange={handleChange}
            values={values}
            prop="email"
            component="add user"
            error={error}
          />
          <PhoneArea
            label="Telefon"
            handleChange={handleChange}
            values={values}
            prop="gsm"
            component="add user"
            error={error}
          />
          <AutoCompleteArea
            data={roles}
            label="Yetki Grubu"
            setValues={setValues}
            prop="roles"
            values={values}
            component="add user"
            error={error}
          />
          <StyledFirm>{`Firma: ${customer?.currentCompany?.name}`}</StyledFirm>
          {values?.roles?.name === "Firma Kullanıcı" && (
            <AutoCompleteMultiple
              data={addresses}
              label="Yetkili Adresler"
              setValues={setValues}
              prop="branch"
              values={values}
              error={error}
              component="add user"
              defVal={values?.branch}
            />
          )}
          <AutoCompleteArea
            data={
              values?.roles?.name === "Firma Kullanıcı"
                ? values?.branch
                : addresses
            }
            label="Tanımlı Adres"
            setValues={setValues}
            prop="defaultAddress"
            values={values}
            error={error}
            component="add user"
          />
          <FormControl style={{ marginTop: 16 }}>
            <StyledFormLabel
              control={
                <BufiSwitch
                  checked={values.status === 1}
                  value={values.status}
                  onChange={handleSwitch}
                />
              }
              label={
                values.status === 1 ? (
                  <Typography aktif="true">Aktif</Typography>
                ) : (
                  <Typography>Pasif</Typography>
                )
              }
            />
          </FormControl>
          <MobileDialogButton
            variant="contained"
            disableRipple
            onClick={handleSubmit}>
            Kaydet
          </MobileDialogButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

MobileAddUserCa.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  newGetUsers: PropTypes.func,
  query_: PropTypes.object,
};

export default MobileAddUserCa;
