import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AddressAndNote from "./AddressAndNote";
import CustomField from "../Checkout/CreditCart/CustomField";
import TextArea from "../../../Ui/Fields/TextArea";

import { orderActions } from "../../../Redux/Actions";

import Loading from "../../../Utils/Loading";

const Root = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const InputContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(),
}));

const BottomPart = styled("div")(({ theme }) => ({
  width: "100%",
  height: 50,
  position: "absolute",
  bottom: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: "white",
  zIndex: 2,
}));

const CostContainer = styled("div")(() => ({
  display: "flex",
}));

const TotalCost = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "text",
})(({ theme, text }) => ({
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 500,
  color: theme.palette.darkGray.main,
  marginRight: text && theme.spacing(),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: 40,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  color: "#fff",
  backgroundColor: theme.palette.darkGray.main,
  borderRadius: 6,
  "&:hover": {
    backgroundColor: theme.palette.darkGray.main,
  },
}));

const CreditCardMob = ({ text, toggleAddressDialog, handleNote }) => {
  const dispatch = useDispatch();

  const customer = useSelector(({ customer }) => customer);
  const cart = useSelector(({ product }) => product.cart);
  const orderLoading = useSelector(({ orders }) => orders.loading);

  const initializeOrder = (query) =>
    dispatch(orderActions.initializeOrder(query));

  const initializeAdminOrder = (query, id) =>
    dispatch(orderActions.initializeAdminOrder(query, id));

  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    cardHolderName: "",
    cardNumber: "**** **** **** ****",
    expiryDate: "  /  ",
    cvc: "   ",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeName = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const handleCheck = () => {
    const { cardHolderName, cardNumber, expiryDate, cvc } = values;
    if (
      cardHolderName.length === 0 ||
      cardNumber.length !== 19 ||
      expiryDate.length !== 5 ||
      cvc.length !== 3 ||
      cvc === "   " ||
      expiryDate === "  /  " ||
      cardHolderName === "" ||
      cardNumber === "**** **** **** ****"
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };
  const placeOrder = () => {
    let query = {
      method_id: 1,
      address_id: customer.currentAddress.id,
      card_owner: values.cardHolderName,
      card_number: values.cardNumber.replace(/\s+/g, "").toString(),
      expire_month: values.expiryDate.substring(0, 2),
      expire_year: values.expiryDate.substring(3, 5),
      cvc: +values.cvc,
      installment: 1,
      extra_args: {},
      note: text,
      source: 1,
    };
    let isError = handleCheck();
    if (!isError) {
      if (customer?.isAdmin) {
        let adminQuery = {
          ...query,
          user_id: customer?.selectedUser?.id,
          company_id: customer?.currentCompany?.id,
        };
        initializeAdminOrder(adminQuery, customer?.selectedUser?.id);
      } else {
        initializeOrder(query);
      }
    } else {
      console.log("error");
    }
  };

  return (
    <Root>
      <AddressAndNote
        text={text}
        toggleAddressDialog={toggleAddressDialog}
        handleChange={handleNote}
      />
      <Loading open={orderLoading} />
      <InputContainer>
        <TextArea
          label="Kart Üzerindeki Ad-Soyad"
          handleChange={handleChangeName}
          values={values}
          prop="cardHolderName"
          error={error}
          placeholder="Kart sahibinin adı soyadı"
          creditCard
        />
        <CustomField
          label="Kart Numarası"
          handleChange={handleChange}
          values={values}
          prop="cardNumber"
          error={error}
          placeholder="* * * *    * * * *    * * * *    * * * *"
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomField
              label="Tarih"
              handleChange={handleChange}
              values={values}
              prop="expiryDate"
              error={error}
              placeholder="_ _ / _ _"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomField
              label="CVC"
              handleChange={handleChange}
              values={values}
              prop="cvc"
              error={error}
              placeholder="* * *"
            />
          </Grid>
        </Grid>
        <BottomPart>
          <CostContainer>
            <TotalCost text>Tutar: </TotalCost>
            <TotalCost>{cart?.order.total_cost || 0}</TotalCost>
          </CostContainer>
          <SubmitButton onClick={placeOrder} variant="contained">
            Siparişi Onayla
          </SubmitButton>
        </BottomPart>
      </InputContainer>
    </Root>
  );
};

CreditCardMob.propTypes = {
  text: PropTypes.string,
  toggleAddressDialog: PropTypes.func,
  handleNote: PropTypes.func,
};

export default CreditCardMob;
