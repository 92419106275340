import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";

import LotDialog from "../LotDialog";
import SelectionsMain from "./NewSpecialDemandsModal/SelectionsMain";
import InputArea from "./NewSpecialDemandsModal/InputArea";
import QuantityAndSubmit from "./NewSpecialDemandsModal/QuantityAndSubmit";

const PREFIX = "NewSpecialDemandsModal";

const classes = {
  paper: `${PREFIX}-paper`,
  content: `${PREFIX}-content`,
  closeContainer: `${PREFIX}-closeContainer`,
  dialogCloseButton: `${PREFIX}-dialogCloseButton`,
  dialogCloseButtonIcon: `${PREFIX}-dialogCloseButtonIcon`,
  imageAndSelections: `${PREFIX}-imageAndSelections`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: 700,
    height: 458,
    padding: 0,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&.MuiDialog-paperWidthSm": {
      maxWidth: 750,
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    height: "auto",
    padding: 20,
    paddingTop: 0,
  },

  [`& .${classes.closeContainer}`]: {
    width: "100%",
    height: 30,
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.dialogCloseButton}`]: {
    padding: "25px",
    position: "absolute",
    color: theme.palette.grey[500],
    right: "0",
    top: "0",
    borderRadius: "0 0 0 100%",
    "& svg": {
      fontSize: "24px",
    },
  },

  [`& .${classes.dialogCloseButtonIcon}`]: {
    position: "absolute",
    top: "8px",
    right: "7px",
  },

  [`& .${classes.imageAndSelections}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const NewSpecialDemandsModal = ({
  open,
  product,
  features,
  specialFeatureValues,
  handleFeatures,
  increase,
  decrease,
  selections,
  handleSubmit,
  closeDialogFromButton,
  setSelections,
}) => {
  const [lotDialog, setLotDialog] = useState(false);

  const toggleLotDialog = () => setLotDialog(!lotDialog);

  useEffect(() => {
    let active = true;
    if (active && product) {
      let dimensions = product.attributes_map.Ebat;
      let width = parseInt(dimensions.split("x")[0]);
      let height = parseInt(dimensions.split("x")[1]);
      let heightIsNan = _.isNaN(height) ? "" : height;
      if (product.type.id === 2) {
        setSelections({
          ...selections,
          quantity: selections.quantity,
          features: {
            width: !features || features?.width === "" ? width : features.width,
            height:
              !features || features?.height === ""
                ? heightIsNan
                : features.height,
            shape:
              !features || features?.shape === ""
                ? "Dikdörtgen"
                : features.shape,
            side:
              !features || features?.shape === "" ? "Overlok" : features.side,
            webType:
              !features || features?.webType === "" ? "" : features.webType,
            webColor:
              !features || features?.webColor === "" ? "" : features.webColor,
          },
        });
      } else {
        setSelections({
          ...selections,
          quantity: selections.quantity,
          features: {
            width,
            height: heightIsNan,
            shape: "Dikdörtgen",
            side: "Overlok",
            webType: "",
            webColor: "",
          },
        });
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, product]);

  return (
    <StyledDialog
      open={open}
      // onClose={onClose}
      classes={{ paper: classes.paper }}
      keepMounted={false}>
      <DialogTitle className={classes.closeContainer}>
        <IconButton
          aria-label="Close"
          className={classes.dialogCloseButton}
          onClick={closeDialogFromButton}
          size="large">
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.imageAndSelections}>
          <img
            src={product?.image}
            alt=""
            width="176px"
            height="272px"
            style={{ borderRadius: 8, marginRight: 20 }}
          />
          <SelectionsMain
            name={product?.name}
            specialFeatureValues={specialFeatureValues}
            features={features}
            handleFeatures={handleFeatures}
          />
        </div>
        <InputArea
          features={features}
          handleFeatures={handleFeatures}
          id={product?.type?.id}
          toggleLotDialog={toggleLotDialog}
        />
        <QuantityAndSubmit
          decrease={decrease}
          quantity={selections.quantity}
          increase={increase}
          handleSubmit={handleSubmit}
          features={features}
        />
      </DialogContent>
      <LotDialog
        open={lotDialog}
        onClose={toggleLotDialog}
        name={product?.name}
        data={product?.stock?.detail_list}
      />
    </StyledDialog>
  );
};

NewSpecialDemandsModal.propTypes = {
  open: PropTypes.bool,
  product: PropTypes.object,
  features: PropTypes.object,
  specialFeatureValues: PropTypes.object,
  handleFeatures: PropTypes.func,
  increase: PropTypes.func,
  decrease: PropTypes.func,
  selections: PropTypes.object,
  handleSubmit: PropTypes.func,
  closeDialogFromButton: PropTypes.func,
  setSelections: PropTypes.func,
};

export default NewSpecialDemandsModal;
