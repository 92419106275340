import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { customerActions } from "../../Redux/Actions";

const Text = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  fontSize: 19,
  lineHeight: "24px",
  fontWeight: 700,
  color: theme.palette.darkGray.main,
  marginBottom: theme.spacing(2),
}));

const StyledImage = styled("img")(() => ({
  flex: 1,
  width: "100%",
  objectFit: "cover",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
}));

const ImageContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  minHeight: 250,
}));

const CategoryCard = ({ category }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (value, name) => {
    let query = {
      ...filterQuery,
      categories: [{ id: value, name: name, filter: "Kategori" }],
      tabValue: 0,
    };
    updateFilters(query);
    history.push("/filters-page");
  };

  return (
    <Box
      sx={{
        width: 180,
        height: "auto",
        minHeight: 317.15,
        p: 1,
        position: "relative",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        transition: (theme) => theme.transitions.create(["box-shadow"]),
        "&:hover": {
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.25)",
          outline: "none",
          border: "none",
        },
      }}
      onClick={() => goToFilters(category?.id, category?.name)}>
      <Text>{category?.name}</Text>
      <ImageContainer>
        <StyledImage src={category?.image} alt="" />
      </ImageContainer>
    </Box>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.object,
};

export default CategoryCard;
