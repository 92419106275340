import React from "react";
import PropTypes from "prop-types";

import VariantValuesMob from "../../../Ui/VariantValues/VariantValuesMob";

const MobileVariants = ({ values, handleChange, selections }) => {
  return (
    <>
      <VariantValuesMob
        data={values?.color}
        param="color"
        handleChange={handleChange}
        header="Renkler"
        selectedValue={selections.color}
      />
      <VariantValuesMob
        data={values.size}
        param="size"
        handleChange={handleChange}
        header="Ebatlar"
        selectedValue={selections.size}
      />
      <VariantValuesMob
        data={values.shape}
        param="shape"
        handleChange={handleChange}
        header="Şekil"
        selectedValue={selections.shape}
      />
    </>
  );
};

MobileVariants.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  selections: PropTypes.object,
};

export default MobileVariants;
