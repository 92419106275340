import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const PREFIX = "AddresInfo";

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  branch: `${PREFIX}-branch`,
  address: `${PREFIX}-address`,
  button: `${PREFIX}-button`,
  warnText: `${PREFIX}-warnText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: 170,
    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: "6px 16px",
    marginBottom: 27,
    position: "relative",
  },

  [`& .${classes.header}`]: {
    color: theme.palette.darkGray.main,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 4,
  },

  [`& .${classes.branch}`]: {
    color: theme.palette.darkGray.main,
    fontSize: 14,
    fontWeight: 300,
    marginBottom: 11,
  },

  [`& .${classes.address}`]: {
    color: theme.palette.darkGray.main,
    fontSize: 10,
    fontWeight: 400,
    marginRight: 5,
    marginBottom: 5,
  },

  [`& .${classes.button}`]: {
    position: "absolute",
    bottom: 5,
    left: "50%",
    transform: "translate(-50%, 0%)",
    width: 260,
    height: 25,
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: 2,
    color: "#fff",
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.warnText}`]: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
    width: 260,
    textAlign: "center",
  },
}));

const AddresInfo = ({ address, openDialog, openAddAddressModal }) => {
  return (
    <Root className={classes.root}>
      {address ? (
        <>
          <Typography className={classes.header}>Adres Bilgileri</Typography>
          <Typography className={classes.branch}>
            {address?.name || address?.title}
          </Typography>
          <Box display="flex">
            <Typography className={classes.address}>
              {address?.phone1}{" "}
            </Typography>
            <Typography className={classes.address}>
              {address?.email && address.email}
            </Typography>
          </Box>
          <Typography className={classes.address}>
            {address?.district}
          </Typography>
          <Typography className={classes.address}>
            {address?.address1}
          </Typography>
          <Typography
            className={
              classes.address
            }>{`${address?.county.name}/${address?.city.name}`}</Typography>
        </>
      ) : (
        <Typography className={classes.warnText}>
          Lütfen adres ekleyiniz!
        </Typography>
      )}
      <Button
        variant="contained"
        className={classes.button}
        disableRipple
        onClick={address ? openDialog : openAddAddressModal}>
        {address ? "Farklı bir adrese gönder" : "Address Ekle"}
      </Button>
    </Root>
  );
};

AddresInfo.propTypes = {
  address: PropTypes.object,
  openDialog: PropTypes.func,
  openAddAddressModal: PropTypes.func,
};

export default AddresInfo;
