import { authCustomerHeader } from "./Config";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const productSkuList = async (query) => {
  return await axios
    .post(`${API_URL}/product/sku/list`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const productSkuListAll = async (data) => {
  return await axios
    .post(`${API_URL}/product/sku/list`, data, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getBestSellers = async (data) => {
  return await axios
    .post(`${API_URL}/product/sku/best_seller`, data, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getBestSellersArea = async (data) => {
  return await axios
    .post(`${API_URL}/product/sku/best_seller/area`, data, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getBestSellersUser = async (data) => {
  return await axios
    .post(`${API_URL}/product/sku/best_seller/user`, data, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getLastVisits = async (isAdmin, userId) => {
  let url = isAdmin
    ? `${API_URL}/last_visit/admin/list/${userId}`
    : `${API_URL}/last_visit/list`;
  return await axios.get(url, authCustomerHeader()).then((res) => {
    return res;
  });
};

const productSkuById = async (product_sku_id, query) => {
  return await axios
    .post(
      `${API_URL}/product/sku/list/${product_sku_id}`,
      query,
      authCustomerHeader()
    )
    .then((res) => {
      return res;
    });
};

const addToCart = async (query) => {
  return await axios
    .post(`${API_URL}/cart/add`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const addToAdminCart = async (query, id) => {
  return await axios
    .post(`${API_URL}/cart/admin/add/${id}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getCart = async () => {
  return await axios
    .get(`${API_URL}/cart/list`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getAdminCart = async (id) => {
  return await axios
    .get(`${API_URL}/cart/admin/list/${id}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const deleteProduct = async (id, key) => {
  return await axios
    .get(`${API_URL}/cart/remove/${id}/${key}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const deleteAdminProduct = async (userId, productId, key) => {
  return await axios
    .get(
      `${API_URL}/cart/admin/remove/${userId}/${productId}/${key}`,
      authCustomerHeader()
    )
    .then((res) => {
      return res;
    });
};

const clearCart = async () => {
  return await axios
    .delete(`${API_URL}/cart/delete`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const clearAdminCart = async (userId) => {
  return await axios
    .delete(`${API_URL}/cart/admin/delete/${userId}`, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const cartUpdate = async (query) => {
  return await axios
    .put(`${API_URL}/cart/update`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const adminCartUpdate = async (query, userId) => {
  return await axios
    .put(`${API_URL}/cart/admin/update/${userId}`, query, authCustomerHeader())
    .then((res) => {
      return res;
    });
};

const getFeatureValues = async () => {
  return await axios
    .get(`${API_URL}/setting/features`, authCustomerHeader())
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

export const productService = {
  productSkuList,
  productSkuById,
  productSkuListAll,
  addToCart,
  getCart,
  deleteProduct,
  clearCart,
  cartUpdate,
  getFeatureValues,
  getBestSellersArea,
  getBestSellersUser,
  getBestSellers,
  getLastVisits,
  getAdminCart,
  addToAdminCart,
  deleteAdminProduct,
  clearAdminCart,
  adminCartUpdate,
};
