import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 227,
  height: 42,
  backgroundColor: "#F5F3FF",
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginBottom: theme.spacing(),
}));

const SubContainer = styled("div")(() => ({
  width: 157,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  marginRight: theme.spacing(),
  marginLeft: theme.spacing(),
  "&[not-right-margin='true']": {
    marginRight: 0,
  },
  "&[not-left-margin='true']": {
    marginLeft: 0,
  },
}));

const ButtonAndQuantity = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const Quantity = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  fontWeight: 700,
  color: theme.palette.darkGray.main,
}));

const QuantityText = styled(Typography)(({theme}) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.secondary.main,
  marginTop: 2,
  marginBottom: 0,
}));

const QuantityArea = ({ product, decreaseQuantity, increaseQuantity }) => {
  return (
    <Root>
      <SubContainer>
        <ButtonAndQuantity>
          <StyledIconButton
            not-left-margin="true"
            disabled={product.quantity === 1}
            onClick={decreaseQuantity}
            size="large">
            <RemoveIcon />
          </StyledIconButton>
          <Quantity>{product?.quantity}</Quantity>
          <StyledIconButton
            not-right-margin="true"
            disabled={product?.quantity === +product?.stock.quantity}
            onClick={increaseQuantity}
            size="large">
            <AddIcon />
          </StyledIconButton>
        </ButtonAndQuantity>
        <QuantityText>Adet</QuantityText>
      </SubContainer>
    </Root>
  );
};

QuantityArea.propTypes = {
  product: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  increaseQuantity: PropTypes.func,
};

export default QuantityArea;
