import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { orderService } from "../../../../Services";
import Loading from "../../../../Utils/Loading";
import { generalConstants } from "../../../../Utils/Constants";
import ItemCard from "./ItemCard";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  minHeight: 200,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(),
}));

const InfoContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  minHeight: 40,
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),
  boxShadow: "0px 4px 4px -4px rgba(0, 0, 0, 0.25)",
  marginBottom: theme.spacing(),
}));

const TextArea = styled("div", {
  shouldForwardProp: (prop) => prop !== "name",
})(({ theme, name }) => ({
  display: "flex",
  width: "fit-content",
  marginRight: name ? theme.spacing(4) : 0,
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "header",
})(({ theme, header }) => ({
  marginLeft: header ? 0 : theme.spacing(2),
  fontSize: 14,
  lineHeight: "20px",
  color: theme.palette.darkGray.main,
  fontWeight: header ? 700 : 400,
}));

const OrderItemDetails = ({ extended, order }) => {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const getRelatedProducts = async (id) => {
    setLoading(true);
    await orderService
      .getOrder(id)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          setCurrentOrder(res.data.order);
          setLoading(false);
        } else {
          toast.error(res.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if (extended && order?.id) {
        getRelatedProducts(order.id);
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extended, order?.id]);

  return (
    <Root>
      <InfoContainer>
        <TextArea name>
          <Text header>Oluşturan:</Text>
          <Text>
            {order?.buyer?.user?.full_name || order?.buyer.company.name}
          </Text>
        </TextArea>
        <TextArea>
          <Text header>Açıklama:</Text>
          <Text>{order?.note}</Text>
        </TextArea>
      </InfoContainer>
      <Loading open={loading} />
      {currentOrder?.products &&
        currentOrder?.products.length > 0 &&
        currentOrder?.products.map((product, index) => (
          <ItemCard product={product} key={index} />
        ))}
    </Root>
  );
};

OrderItemDetails.propTypes = {
  extended: PropTypes.bool,
  order: PropTypes.object,
};

export default OrderItemDetails;
