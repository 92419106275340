import { rating } from "./GeneralConstants";

export const productReducerInitialState = {
  id: "",
  name: "",
  cart: {
    order: {},
    products: [],
  },
  loading: false,
  featureValues: [],
  newArrivals: null,
};

export const addressReducerInitialState = {
  addresses: [],
  loading: false,
};

export const authReducerInitialState = {
  signedIn: false,
  customerInfo: {},
  loading: false,
};

export const bottomNavbarInitialState = {
  menuValue: "home",
};

export const customerReducerInitialState = {
  filters: {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "", //rating, fiyat(düşükten yükseğe, yüksekten düşüğe)
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  },
  tabIndex: 1,
  isAdmin: false,
  currentAddress: null,
  currentCompany: null,
  selectedUser: null,
};

export const filterReducerInitialState = {
  brands: [],
  categories: [],
  sizes: [],
  colors: [],
  shapes: [],
  others: [],
  rating: rating,
};

export const orderReducerInitialState = {
  orders: [],
  loading: false,
  error: false,
  success: false,
  note: "",
};

export const notificationReducerInitialState = {
  data: null,
};

export const campaignReducerInitialState = {
  campaigns: null,
};
