export const customerConstants = {
  CUSTOMER: "B2B_CUSTOMER",
  USER: "B2B_USER",

  ADD_FILTER: "ADD_FILTER",
  ADD_MOBILE_FILTER: "ADD_MOBILE_FILTER",

  CLEAR_FILTERS: "CLEAR_FILTERS",
  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
  CLEAR_SEARCH_TEXT: "CLEAR_SEARCH_TEXT",

  SET_TAB_INDEX: "SET_TAB_INDEX",
  SET_FILTER_TAB_INDEX: "SET_FILTER_TAB_INDEX",

  UPDATE_FILTERS: "UPDATE_FILTERS",

  SET_ADMIN: "SET_ADMIN",
  SET_USER_CURRENT: "SET_USER_CURRENT",
  SELECTED_USER: "SELECTED_USER",

  CLEAR_CUSTOMER_REDUCER: "CLEAR_CUSTOMER_REDUCER",

  UPDATE_CUSTOMER_ADDRESS_BY_TOGGLE: "UPDATE_CUSTOMER_ADDRESS_BY_TOGGLE",

  RESTORE_DEFAULT_ADDRESS: "RESTORE_DEFAULT_ADDRESS",
};
