import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import DetailsMain from "./Details/DetailsMain";
import FeaturesAndRatings from "./FeaturesAndRatings/FeaturesAndRatings";
// import PageSections from "../Common/PageSections";
import PageContainer from "../Common/PageContainer";
import Loading from "../../Utils/Loading";
import NewSection from "../Common/Sections/NewSection";

import { productService } from "../../Services";
// import { productPageSections } from "../../Utils/Constants";
import { customerActions } from "../../Redux/Actions";
import { generalConstants } from "../../Utils/Constants";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const NoProductContainer = styled(Box)(() => ({
  width: "100%",
  height: 400,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  lineHeight: "50px",
  fontWeight: 400,
  color: theme.palette.darkGray.main,
}));

const RouteButton = styled(Button)(({ theme }) => ({
  width: "auto",
  height: 40,
  marginTop: theme.spacing(4),
  color: "#fff",
  backgroundColor: theme.palette.primary.main,
  fontSize: 18,
  fontWeight: 400,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProductPage = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const newArrivals = useSelector(({ product }) => product?.newArrivals);
  const companyId = useSelector(
    ({ customer }) => customer?.selectedUser?.company?.id
  );
  const updateFilters = (query) =>
    dispatch(customerActions.updateFilters(query));

  const [data, set] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (active) {
      let query = {
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "desc",
        filter: {
          company_id: companyId,
          on_sale: 1,
        },
        keyword: "",
      };

      params && companyId && getProductDetail(params.id, query);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, companyId]);

  const getProductDetail = async (id, query) => {
    setLoading(true);
    await productService
      .productSkuById(id, query)
      .then((res) => {
        if (res.data.status === generalConstants.STATUS_TRUE) {
          let data = res.data.product_sku;
          set(data);
          setLoading(false);
        } else {
          set(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const filterQuery = {
    brands: [],
    categories: [],
    sizes: [],
    colors: [],
    shapes: [],
    rating: [],
    orderByKey: "",
    orderByValue: "asc",
    searchText: "",
    tabValue: 0,
  };

  const goToFilters = (value) => {
    let query = { ...filterQuery, tabValue: value };
    updateFilters(query);
    history.push("/filters-page");
  };

  const goToHome = () => history.goBack();
  if (loading)
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <Loading open={loading} />
      </div>
    );
  return (
    <PageContainer>
      {!data ? (
        <NoProductContainer>
          <Text>Bu ürün satışa kapalıdır!</Text>
          <RouteButton variant="contained" onClick={goToHome}>
            Geri Dön
          </RouteButton>
        </NoProductContainer>
      ) : (
        !loading && (
          <>
            <DetailsMain data={data} urlId={params?.id} />
            <FeaturesAndRatings data={data} />
          </>
        )
      )}
      <NewSection
        products={newArrivals}
        header="Yeni Gelenler"
        handleClick={goToFilters}
        value={4}
      />
    </PageContainer>
  );
};

export default ProductPage;
