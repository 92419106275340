import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { SubmitButton } from "../../AuthPages/AuthCommons/Tools";

import ServerError from "../../../Images/ErrorBoundary/ServerError.svg";

const innerWidth = window.innerWidth;

const gradientBackground =
  "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(210, 23, 163, 0.19) 0%, rgba(180, 48, 147, 0.19) 28.13%, rgba(133, 89, 122, 0.19) 100%)";

const Root = styled(Box)(({ theme }) => ({
  width: innerWidth,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  background: gradientBackground,
}));

const MobileBoundary = ({ goToHome }) => {
  return (
    <Root>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: (theme) => theme.spacing(2),
        }}>
        <img src={ServerError} alt="" />
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 16,
            lineHeight: "20px",
            mt: (theme) => theme.spacing(2),
          }}>
          Sayfa yüklenmesi sırasında bir sorun oluştu,
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: (theme) => theme.palette.darkGray.main,
            fontSize: 16,
            lineHeight: "20px",
            mb: (theme) => theme.spacing(4),
          }}>
          sayfayı yenileyebilir ya da tekrar giriş yapabilirsiniz.
        </Typography>
        <SubmitButton onClick={goToHome}>Giriş Sayfasına Gön</SubmitButton>
      </Box>
    </Root>
  );
};

MobileBoundary.propTypes = {
  goToHome: PropTypes.func,
};

export default MobileBoundary;
