import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import NotificationsIcon from '@mui/icons-material/Notifications';

import StockBox from "./StockBox";

const PREFIX = "Stocks";

const classes = {
  root: `${PREFIX}-root`,
  stockBoxContainer: `${PREFIX}-stockBoxContainer`,
  stockTextContainer: `${PREFIX}-stockTextContainer`,
  text: `${PREFIX}-text`,
  stockAlertAndDate: `${PREFIX}-stockAlertAndDate`,
  alert: `${PREFIX}-alert`,
  alertButton: `${PREFIX}-alertButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.colors.gray}`,
    paddingBottom: theme.spacing(),
  },

  [`& .${classes.stockBoxContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(4),
  },

  [`& .${classes.stockTextContainer}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },

  [`& .${classes.text}`]: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(),
    "&[data-content='alert']": {
      fontWeight: 400,
      marginLeft: 7.25,
      cursor: "pointer",
    },
  },

  [`& .${classes.stockAlertAndDate}`]: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 70,
  },

  [`& .${classes.alert}`]: {
    display: "flex",
    userSelect: "none",
  },

  [`& .${classes.alertButton}`]: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    color: "#fff",
    fontSize: 12,
    height: 30,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Stocks = ({ stock, productType }) => {
  const quantityText = productType === 3 ? "Metre" : "Adet"
  return (
    <Root className={classes.root}>
      <div className={classes.stockBoxContainer}>
        <StockBox
          text="Depo Miktarı: "
          value={`${stock?.quantity} ${quantityText}`}
          order="first"
        />
        {/* <div
          style={{
            width: "fit-content",
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}>
          <Typography className={classes.text}>
            Tahmini Sevk Tarihi :{" "}
          </Typography>
          <Typography className={classes.text}>
            Tahmini Temin Tarihi :{" "}
          </Typography>
          <Button
            className={classes.alertButton}
            disableRipple
            variant="contained"
            startIcon={<NotificationsIcon/>}>
            Gelince Haber Ver
          </Button>
        </div>         */}
      </div>
    </Root>
  );
};

Stocks.propTypes = {
  stock: PropTypes.object,
  productType: PropTypes.number
};

export default Stocks;
