import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import passwordIcon from "../../../Images/Auth/password.svg";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  headerText: `${PREFIX}-headerText`,
  img: `${PREFIX}-img`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.headerText}`]: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    marginTop: 6,
    marginBottom: 15,
    userSelect: "none",
    "&[component='signin-email']": {
      marginBottom: 9,
    },
    "&[is-mobile='true']": {
      fontSize: 16,
      fontWeight: 400,
      marginTop: 0,
    },
  },

  [`& .${classes.img}`]: {
    marginTop: 9.35,
    userSelect: "none",
    "&[component='signin-email']": {
      marginTop: 12.35,
    },
    "&[is-mobile='true']": {
      marginTop: 0,
    },
  },
}));

const Header = ({ component, mobile }) => {
  return (
    <Root className={classes.root}>
      <img
        src={passwordIcon}
        alt="passwordIcon"
        width={mobile ? "32px" : "49px"}
        height={mobile ? "32px" : "47px"}
        className={classes.img}
        component={component}
        is-mobile={mobile ? "true" : "false"}
      />
      <Typography
        className={classes.headerText}
        is-mobile={mobile ? "true" : "false"}>
        Giriş Yap
      </Typography>
    </Root>
  );
};

Header.propTypes = {
  component: PropTypes.string,
  mobile: PropTypes.bool,
};

export default Header;
