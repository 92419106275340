import { generalConstants } from "../Constants";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(generalConstants.B2B);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(generalConstants.B2B, serializedState);
  } catch (err) {}
};
