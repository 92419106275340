import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Header from "./Header";
import Bubble from "./Bubble";

const PREFIX = "VariantValues";

const classes = {
  root: `${PREFIX}-root`,
  gridContainer: `${PREFIX}-gridContainer`,
  value: `${PREFIX}-value`,
  selected: `${PREFIX}-selected`,
  none: `${PREFIX}-none`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
    "&[item-name='Şekil']": {
      borderBottom: "1px solid #B1AEAE",
      marginTop: 0,
    },
    "&[item-name='Ebatlar']": {
      marginTop: 0,
    },
  },

  [`& .${classes.gridContainer}`]: {
    marginTop: theme.spacing(),
    marginLeft: 0,
    maxWidth: 445,
    minWidth: 280,
  },

  [`& .${classes.value}`]: {
    maxWidth: 62,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.darkGray.light}`,
    borderRadius: 3,
    color: theme.palette.darkGray.main,
    fontWeight: 400,
    fontSize: 12,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    cursor: "pointer",
    userSelect: "none",
    padding: 0,
    "&.MuiGrid-item": {
      paddingTop: 0,
      paddingLeft: 0,
    },
    "&:hover": {
      border: "1px solid #6E3564",
    },
  },

  [`& .${classes.selected}`]: {
    border: "2px solid #6E3564",
    color: "#6E3564",
    cursor: "pointer",
  },

  [`& .${classes.none}`]: {
    display: "none",
  },
}));

const Value = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  textTransform: "none",
  width: "fit-content",
  height: 40,
  minWidth: 102,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.colors.gray}`,
  borderRadius: 8,
  color: selected ? "#fff" : theme.palette.darkGray.main,
  backgroundColor: selected ? theme.palette.primary.main : "#fff",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "22px",
  marginRight: theme.spacing(),
  marginBottom: theme.spacing(),
  cursor: "pointer",
  userSelect: "none",
  padding: 4,
  //   touchAction: "none",
  WebkitTapHighlightColor: "transparent",
  boxShadow: 'none',
  "&.MuiGrid-item": {
    paddingTop: 0,
    paddingLeft: 0,
  },
  "&:hover, &:focus, &:active": {
    border: "1px solid #6E3564",
    backgroundColor: selected ? theme.palette.primary.main : "#fff",
  },
}));
const VariantValues = ({
  data,
  param,
  handleChange,
  header,
  selectedValue,
  activeArray,
  selections,
  // initialSelection,
  component,
}) => {
  // const inactiveCondition = (elem) => {
  //   if (param === "color") return false;
  //   if (param === "size") {
  //     if (activeArray.length === 0) return false;
  //     if (!activeArray.includes(elem)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   if (param === "shape") {
  //     if (activeArray.length === 0) return false;
  //     if (!activeArray.includes(elem)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  const showBubble = () => {
    if (component) return;
    // if (initialSelection) return;
    if (param === "color" && selectedValue === "" && data?.length > 0) {
      return true;
    } else if (
      param === "size" &&
      selectedValue === "" &&
      selections?.color.length > 0 &&
      data?.length > 0
    ) {
      return true;
    } else if (
      param === "shape" &&
      selectedValue === "" &&
      selections?.size.length > 0 &&
      data?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleText = () =>
    param === "color" ? "renk" : param === "size" ? "ebat" : "şekil";

  let arr = param === "color" ? data : activeArray;

  return (
    <Root className={classes.root} item-name={header}>
      <Header text={header} />
      <div style={{ display: "flex" }}>
        <Grid container spacing={2} className={classes.gridContainer}>
          {arr?.length > 0 &&
            arr?.map((elem, index) => (
              <Value
                key={index}
                variant="contained"
                value={elem}
                disableRipple
                selected={selectedValue === elem}
                onClick={handleChange(param)}>
                {elem}
                {/* <div
                  className={classnames({
                    [classes.div]: true,
                    [classes.inactive]: inactiveCondition(elem),
                  })}
                ></div> */}
              </Value>
            ))}
        </Grid>
        {showBubble() && <Bubble text={handleText()} />}
      </div>
    </Root>
  );
};

VariantValues.propTypes = {
  data: PropTypes.any,
  param: PropTypes.string,
  handleChange: PropTypes.func,
  header: PropTypes.string,
  selectedValue: PropTypes.string,
  activeArray: PropTypes.array,
  selections: PropTypes.object,
  component: PropTypes.string,
};

export default React.memo(VariantValues);

// "& $inactive": {
//   borderBottom: "1px solid #6E3564",
// },

// inactive: {
//   width: 64,
//   height: 20,
//   // borderBottom: `1px solid ${theme.palette.darkGray.light}`,
//   borderBottom: "1px solid black",

//   " -webkit-transform": "translateY(-9px) translateX(3px) rotate(20deg)",
//   position: "absolute",
// },
