import { addressConstants } from "../../Utils/Constants";

const initialState = {
  addresses: [],
  loading: false,
  newAddressId: null,
};

const addresses = (state = initialState, action) => {
  switch (action.type) {
    case addressConstants.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case addressConstants.GET_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case addressConstants.GET_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case addressConstants.CLEAR_ADDRESSES:
      return {
        ...state,
        addresses: [],
        loading: false,
      };
    case addressConstants.TOGGLE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case addressConstants.TOGGLE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case addressConstants.TOGGLE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case addressConstants.TOGGLE_ADDRESS_ID:
      let id = action.id ? action.id : null;
      return {
        ...state,
        newAddressId: id,
      };
    default:
      return state;
  }
};

export { addresses };
